import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';
import { GetSavingAccountResponseModel } from '../models/SavingsAccountModel';
import { addAccount, SavingAccountType } from '../patrimonySlice';
import { saveSavingAccountRequest } from '../requests/saveSavingAccountRequest';

export function useAddAccountCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function addAccountCommand(type: SavingAccountType, account: GetSavingAccountResponseModel) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update Account list localy
      const response = await saveSavingAccountRequest(account, customerid);
      dispatch(addAccount({ type, account: response }));
    }, {key: "useAddAccountCommand", value:`Delay before dispatch AddAccount on addAccount.ts file`});
  }

  return { addAccountCommand };
}
