import { useState, useEffect } from "react";
import { useAnswerCommands } from "../../commands/answerCommand";
import { BackgroundImage } from "../../component/BackgroundImage/BackgroundImage";
import { i18n } from "../../i18n";
import styles from "./TaxRatePage.module.scss";
import {
  Button,
  ButtonStyles,
  Container,
  QuestionTitle,
  useModal,
} from "../../../../shared";
import { ReactComponent as AlertCircleIcon } from "../../../../shared/images/AlertCircle.svg";
import classNames from "classnames";
import { ModuleStyles } from "../../../legal-pension/styles/ModuleStyles";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { useAppSelector } from "store";
import { percentage } from "../../../../shared/utilities/intl";
import { TaxationModal } from "../../component/TaxationModal/TaxationModal";

export function TaxRatePage() {
  const tmi = useAppSelector((s) => s.taxation.answers.selectedTmi);
  const tmiConfigs = useAppSelector((s) => s.taxation.tmiConfigs);

  const { showModal } = useModal();
  const { answerTaxRate } = useAnswerCommands();
  const { answerTaxationQuestionEvent } = useAnalytics();
  const [answerList, setAnswerList] = useState<
    { label: string; value: number }[]
  >([]);
  const [tmiSelected, setTmiSelected] =
    useState<number>(tmi);

  async function openModal() {
    await showModal(<TaxationModal title={i18n.taxRate.modal.title} content={i18n.taxRate.modal.content} 
      isDeclarativeTaxationInformation={false} />);
  }

  function selectAnswer(value: number) {
    setTmiSelected(value);
    answerTaxationQuestionEvent(
      value,
      "2/3",
      i18n.taxRate.question,
      "tax-rate"
    );
    answerTaxRate(value);
  }

  function createAnswerList() {
    const answers = [];
    // tmiConfig is a resolve Taxation module property
    for (const tmi of tmiConfigs!) {
      const value = tmi.tmi;
      answers.push({ label: percentage(value), value });
    }
    setAnswerList(answers);
  }

  useEffect(() => {
    createAnswerList();
  }, []);

  return (
    <>
      <Container className={ModuleStyles.PageGrid}>
        <div className={styles.DescriptionSection}>
          <AlertCircleIcon className={styles.Icon} />
          <span className={styles.DescriptionWarning}>
            {i18n.taxRate.descriptionWarning}
          </span>
        </div>
        <QuestionTitle>{i18n.taxRate.question}</QuestionTitle>

        <div className={styles.AnswerGrid}>
          <div className={styles.AnswerGrid}>
            {answerList.map((a, i) => (
              <Button
                className={ButtonStyles.Answer}
                key={a.value}
                onClick={() => selectAnswer(a.value)}
                selected={a.value === tmiSelected}
              >
                {a.label}
              </Button>
            ))}
          </div>
        </div>
        <Button
          onClick={openModal}
          className={classNames(ButtonStyles.Link, styles.ModalLink)}
        >
          {i18n.taxRate.link}
        </Button>
      </Container>
      <BackgroundImage />
    </>
  );
}
