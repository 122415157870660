import classNames, { Argument } from 'classnames';
import { ReactNode } from 'react';
import styles from './InputGroup.module.scss';

interface InputGroupProps {
  isInvalid?: boolean;
  isValid?: boolean;
  className?: Argument;
  children?: ReactNode;
}

export function InputGroup(props: InputGroupProps) {
  const classes = classNames(
    {
      invalid: props.isInvalid,
      valid: props.isValid
    },
    styles.Group
  );

  return <div className={classNames(classes, props.className)} >{props.children}</div>;
}
