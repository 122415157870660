import classNames from "classnames";
import { i18n } from "../../../../modules/recommendation/i18n";
import {
  ButtonStyles,
  Button,
  ModalOutcome,
  useModal,
} from "../../../../shared";
import {
  keppel,
  sage,
  stormcloud,
  wintergreen,
} from "../../../../styles/colors";
import { ReactComponent as ArrowIcon } from "./images/ArrowIcon.svg";
import styles from "./MainCard.module.scss";
import { useNavigate, useParams } from "react-router-dom";

import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { RecommendationFooter } from "../Footer/Footer";
import { ProductsTable } from "../ProductsTable/ProductsTable";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { InfoIcon } from "../../../../shared/images";
import { amount as formatAmount } from "../../../../shared/utilities/intl";
import { useAppSelector } from "store";
import { OtherStatusModal } from "./component/OtherStatusModal/OtherStatusModal";
import { globali18n } from "../../../../i18n";
import { assets as Assets } from '../../Assets';
import { UserType } from "../../../../shared/requests/getUserReferenceRequest";
import { ProductTypeIdentifier } from "../../../../modules/recommendation/models/product-type-identifier.enum";

export const ChartColors = {
  [ProductTypeIdentifier.PEA]: stormcloud,
  [ProductTypeIdentifier.PERIndividuel]: keppel,
  [ProductTypeIdentifier.AssuranceVie]: sage
};

export interface MainCardProps {
  goToAppointment: () => void;
  goToReport: () => void;
}

export function MainCard(props: MainCardProps) {
  const t = i18n.recommendationPage;
  const { showModal } = useModal();
  const { customerid } = useParams();
  const appUrls = useAppUrls();
  const navigate = useNavigate();
  const model = useAppSelector((s) => s.recommendation.model)!;
  const lastValidateLegalPension = useAppSelector((s) => s.legalPension.lastValidateRequest)!;
  const userType = useAppSelector((s) => s.authentication.userType);
  const isAdvisorUserType = userType === UserType.Advisor;

  function getYearOfLeave() {
    const retirementDateSelected = lastValidateLegalPension.result!.retirementDate;
    return retirementDateSelected ? new Date(retirementDateSelected).getFullYear(): 0;
  }

  const yearOfLeave = getYearOfLeave();

  const capitalAtRetirement =
    model.additionalRetirementIncome?.neutral.capitalAmountAtRetirement ?? 0;

  async function displayModal() {
    const result = await showModal(
      <OtherStatusModal model={model.additionalRetirementIncome} />
    );

    if (result.outcome === ModalOutcome.Cancel) {
      // The user cancelled the modal, stay on the current page
      focusButton();
      return;
    }
  }

  function focusButton() {
    document.getElementById("more-info-modal-recommendation")!.focus();
  }

  return (
    <section className={styles.Container}>
      <div className={styles.BodyContainer}>
        <div className={styles.AmountInformationContainer}>
          <div className={styles.AmountContainer}>
            <div className={styles.SubContainter}>
              <div className={styles.FirstContainer}>
                <span className={styles.AmountInfo}>
                  {t.summary.youPay(yearOfLeave)}
                </span>
                <span className={styles.AmountBolder}>
                  {formatAmount(model.transactions.totalTransactionAmountAtRetirement)}
                </span>
              </div>
              <div className={styles.ArrowIcon}>
                <SvgWrapperComponent ariaHidden={true}>
                  <ArrowIcon />
                </SvgWrapperComponent>
              </div>
              <div className={styles.SecondContainer}>
                <div className={styles.FutureFirst}>
                  <span className={styles.AmountInfo}>
                    {t.summary.youWillObtain}
                  </span>
                  <span className={styles.AmountBigger}>
                    {formatAmount(capitalAtRetirement)}
                  </span>
                </div>
                <div className={styles.FutureSecond}>
                  <span className={styles.Label}>{t.summary.income}</span>
                  <div className={styles.AmountValue}>
                    <span className={styles.AmountMontly}>
                      {model.additionalRetirementIncome.neutral.monthlyAmount}
                      {globali18n.labels.eurosPerMonth}
                    </span>
                  </div>
                </div>
                <div className={styles.NeutralPrevision}>
                  <span>{t.summary.neutralPrevision}</span>
                  <button
                    onClick={displayModal}
                    className={styles.InfoIcon}
                    id="more-info-modal-recommendation"
                  >
                    <SvgWrapperComponent
                      ariaLabel={globali18n.ariaLabel.furtherInformation}
                    >
                      <InfoIcon className={styles.InfoSvg} />
                    </SvgWrapperComponent>
                  </button>
                </div>
              </div>
            </div>
            <span className={styles.AmountContainerFooter}>
              {t.allScenario}
            </span>
            <div className={styles.Trophy}>
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.Trophy />
              </SvgWrapperComponent>
            </div>

            <div className={styles.Building}>
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.Building />
              </SvgWrapperComponent>
            </div>
          </div>
        </div>
        <div className={styles.ProductsTable}>
          <ProductsTable model={model} isFullSize={false} />
        </div>
        <div className={styles.ButtonContainer}>
          {!isAdvisorUserType && <Button
            className={classNames(ButtonStyles.Unfill, styles.Subscribe)}
            onClick={!customerid ? props.goToAppointment : props.goToReport}
          >
            {t.ctas.subscribe}
          </Button>}

          <Button
            className={classNames(
              styles.GoToAdvanced,
              ButtonStyles.PrimaryAction
            )}
            onClick={() => {
              navigate(appUrls.advancedRecommendation);
            }}
          >
            {t.ctas.personalize}
          </Button>
        </div>
      </div>


      <div>
        <RecommendationFooter
          buttonStyleUnfill={false}
          goToReport={props.goToReport}
        ></RecommendationFooter>
        <div className={styles.FootnoteContainer}>
          <div className={styles.Footnote}>{t.footnote}</div>
        </div>
      </div>
    </section>
  );
}
