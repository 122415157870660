import styles from './ZipCodesCombobox.module.scss';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxCloseEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import { cloneElement, ReactElement, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { getZipCodeSorted } from './getZipCodeSorted';

const zipcodes = getZipCodeSorted();
const total = zipcodes.length;
const pageSize = 12;
const allZipCodeDatas: ZipCode[] = [];

for (let i = 0; i < total; i++) {
  const tempZip: ZipCode = zipcodes[i];
  tempZip.zipPlusLabel = `${zipcodes[i].zipCode}-${zipcodes[i].label}`;
  allZipCodeDatas.push(tempZip);
}
export function ZipCodesCombobox(props: ZipCodesComboboxProps) {
  const filteredZipCodeDatas = useRef(allZipCodeDatas.slice());
  const [comboboxOpen, setComboboxOpen] = useState(false);
  const [state, setState] = useState({
    skip: 0,
    total: total,
    subsetData: allZipCodeDatas.slice(0, pageSize)
  });

  function filterChange(value: ComboBoxFilterChangeEvent): void {
    const data = zipcodes.slice();
    const filterValue = value.filter.value;
    const zipCodeCheck = filterValue.match(/[0-9]+/) ? filterValue.match(/[0-9]+/)[0] : null;
    const localityCheck = filterValue.match(/[^0-9]+/)
      ? filterValue.match(/[^0-9]+/)[0].trim()
      : null;

    // Only filter by zipCode if zipCodeCheck is defined, same logic for locality filter (localityCheck must be defined)
    filteredZipCodeDatas.current = data.filter(
      ({ zipCode, label }) =>
        (zipCodeCheck
          ? zipCode?.toString().toLowerCase().startsWith(zipCodeCheck.toLowerCase())
          : true) &&
        (localityCheck ? label.toLowerCase().startsWith(localityCheck.toLowerCase()) : true)
    );
    // If filter value contains only numbers need to sort by zip code then by name
    const needSortZipCodeValue = filterValue.match(/^([0-9]{0,})$/);
    filteredZipCodeDatas.current.sort((a, b) => {
      if (needSortZipCodeValue) {
        return a.zipCode === b.zipCode
          ? a.label === b.label
            ? 0
            : a.label < b.label
            ? -1
            : 1
          : a.zipCode < b.zipCode
          ? -1
          : 1;
      }
      return a.label === b.label ? 0 : a.label < b.label ? -1 : 1;
    });

    const filterSubsetData = filteredZipCodeDatas.current.slice(0, pageSize);
    setState({
      subsetData: filterSubsetData,
      skip: 0,
      total: filteredZipCodeDatas.current.length
    });
    setComboboxOpen(true);
  }

  function pageChange(event: any): void {
    const skip = event.page.skip;
    const take = event.page.take;
    const newSubsetData = filteredZipCodeDatas.current.slice(skip, skip + take);
    setState({ ...state, subsetData: newSubsetData, skip: skip });
  }

  function onChangeSelectValue(event: ComboBoxChangeEvent): void {
    props.zipCodeChange(event.value);
  }

  function comboboxItemRender(
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ): ReactNode {
    const itemChildren = (
      <span>
        {li.props.children} ({itemProps.dataItem.zipCode})
      </span>
    );
    return cloneElement(li, li.props, itemChildren);
  }

  function toggleComboboxOpen() {
    setComboboxOpen(!comboboxOpen);
  }

  function onCloseChange(_: ComboBoxCloseEvent): void {
    setComboboxOpen(false);
  }

  return (
    <div className={styles.Combobox} onClick={toggleComboboxOpen}>
      <StyledComboBox
      className={styles.InsideBox}
        ariaLabel="Code postal"
        name={props.name}
        clearButton={false}
        data={state.subsetData}
        filterable={true}
        onFilterChange={filterChange}
        textField="label"
        dataItemKey="zipPlusLabel"
        value={props.value}
        itemRender={comboboxItemRender}
        iconClassName=""
        popupSettings={{
          popupClass: styles.ComboboxPopUp,
          animate: false
        }}
        onClose={onCloseChange}
        opened={comboboxOpen}
        onChange={onChangeSelectValue}
        virtual={{
          total: state.total,
          pageSize: pageSize,
          skip: state.skip
        }}
        placeholder="Code postal"
        onPageChange={pageChange}
      />
    </div>
  );
}

const StyledComboBox = styled<any>(ComboBox)`
  .k-input-button {
    display: none;
  }
`;

interface ZipCodesComboboxProps {
  value: any;
  zipCodeChange: (value: ZipCode) => any;
  name:string;
}

export interface ZipCode {
  zipCode: string;
  label: string;
  zipPlusLabel?: string;
}
