import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecommendationOutputModel, ParametersInputModel } from './models/RecommendationModel';

export interface RecommendationState {
  model: RecommendationOutputModel | null;
  parameters: ParametersInputModel | null;
}

const initialState: RecommendationState = {
  model: null,
  parameters: null
};

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState: initialState,
  reducers: {
    setModel: (state, action: PayloadAction<RecommendationOutputModel>) => {
      state.model = action.payload;
    },
    setParameters: (state, action: PayloadAction<ParametersInputModel>) => {
      state.parameters = action.payload;
    },
    resetRecommendation: () => {
      return { ...initialState };
    }
  }
});

export const { setModel, setParameters, resetRecommendation } = recommendationSlice.actions;
export const recommendationReducer = recommendationSlice.reducer;
