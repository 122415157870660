import styles from "./BackgroundImage.module.scss";
import { assets as Assets } from "../../Assets";
import { SvgWrapperComponent } from "@ifs/libs";

export function BackgroundImage() {
  return (
    <div className={styles.Padding}>
      <SvgWrapperComponent ariaHidden={true}><Assets.BackgroundImage className={styles.Image} /></SvgWrapperComponent>
    </div>
  );
}
