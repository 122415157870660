import { useAppUrls } from "@ifs/libs/src/shared/utilities/urls";
import { createEmptyCustomerRequest } from "modules/profile/requests/createEmptyCustomerRequest";
import { getAccountPreferenceRequest } from "modules/profile/requests/getAccountPreferenceRequest";
import { useNavigate } from "react-router-dom";

export function useCustomerCreationCommand() {
    const navigate = useNavigate();    
    const appUrls = useAppUrls();

    async function checkCustomerCreation() {        
        const customer = await getAccountPreferenceRequest();
        if(customer === null) {
            // Need to force creation of an empty user
            await createEmptyCustomerRequest();
        }
        navigate(appUrls.dashboard)
    }
    
    return {
        checkCustomerCreation
    }
}