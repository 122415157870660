import { green_sheen, sage } from "../../../../../styles/colors";

type PatrimonyBarChartPerformanceProps = {
  maximumTotalValue: number;
  scenarioRealEstateValue: number;
  scenarioTotalValue: number;
  className?: string;
}

export function PatrimonyBarChartPerformance(props: PatrimonyBarChartPerformanceProps) {
  const svgWidth = 300;
  const svgHeight = 15;
  const svgViewBox = `0 0 ${svgWidth} ${svgHeight}`;

  // To create width ratio
  // We divide section by maximun value and multiplicate by the svg raiot width
  const scenarioRealEstateWidth =
    (Math.round((props.scenarioRealEstateValue / props.maximumTotalValue + Number.EPSILON) * 100) /
      100) *
      svgWidth;
  const scenarioTotalWidth =
    (Math.round((props.scenarioTotalValue / props.maximumTotalValue + Number.EPSILON) * 100) / 100) * svgWidth;

  // First rect element corresponds to maximum total value
  // Second rect element corresponds to total scenario value
  // Third rect element corresponds to estate scenario value
  return (
    <svg className={props.className}  viewBox={svgViewBox} fill="none" preserveAspectRatio="none">
      <g clip-path="url(#clip0_9680_137773)">
        <rect width={svgWidth} height={svgHeight} fill="#DCDFE0" /> 
        <rect width={scenarioTotalWidth} height={svgHeight} fill={sage} /> 
        <rect width={scenarioRealEstateWidth} height={svgHeight} fill={green_sheen} /> 
      </g>
      <defs>
        <clipPath id="clip0_9680_137773">
          <rect width={svgWidth} height={svgHeight} rx="7" fill="white"  />
        </clipPath>
      </defs>
    </svg>
  );
}
