import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { globali18n } from "../../../../../../i18n";
import {
  amount,
  dateTimeFormat,
} from "../../../../../../shared/utilities/intl";
import { useAppUrls } from "../../../../../../shared/utilities/urls";
import { i18n } from "../../../../i18n";
import { ComputationInputModel } from "../../../../models/ComputationInputModel";
import styles from "./NaturalText.module.scss";

interface NaturalTextProps {
  answers: ComputationInputModel;
}

function SummaryLink(
  props: PropsWithChildren<{
    to: string;
    linkTitle?: string;
  }>
) {
  return (
    <Link
      title={props.linkTitle}
      to={{ pathname: props.to, search: "summary=true" }}
    >
      {props.children}
    </Link>
  );
}

export function NaturalText(props: NaturalTextProps) {
  const {
    gender,
    birthDate,
    startDateOfActivity,
    numberOfChildren,
    professionalStatus,
    salary: {
      amount: salaryAmount,
      evolutionAfterSimulation,
      evolutionBeforeSimulation,
      frequency,
    },
  } = props.answers;
  const translations = i18n.summaryPage.naturalText;
  const appUrls = useAppUrls();

  return (
    <section className={styles.Card}>
      Vous êtes{" "}
      <SummaryLink
        to={appUrls.legalPension.gender}
        linkTitle="Votre genre"
      >
        {translations.gender[gender]}
      </SummaryLink>
      , vous êtes {translations.born[gender]} le{" "}
      <SummaryLink
        to={appUrls.legalPension.birthDate}
        linkTitle="Date de naissance"
      >
        {dateTimeFormat(new Date(birthDate))}
      </SummaryLink>
      . Vous avez commencé à travailler le{" "}
      <SummaryLink
        to={appUrls.legalPension.careerStart}
        linkTitle="Année de début de carriere"
      >
        {dateTimeFormat(new Date(startDateOfActivity))}
      </SummaryLink>
      . Vous{" "}
      {numberOfChildren === 0 && (
        <>
          n'avez{" "}
          <SummaryLink
            to={appUrls.legalPension.children}
            linkTitle="Combien d'enfant"
          >
            pas d'enfant
          </SummaryLink>
        </>
      )}
      {numberOfChildren === 1 && (
        <>
          avez{" "}
          <SummaryLink
            to={appUrls.legalPension.children}
            linkTitle="Combien d'enfant"
          >
            1 enfant
          </SummaryLink>
        </>
      )}
      {numberOfChildren > 1 && (
        <>
          avez{" "}
          <SummaryLink
            to={appUrls.legalPension.children}
            linkTitle="Combien d'enfant"
          >
            {numberOfChildren} enfants
          </SummaryLink>
        </>
      )}{" "}
      et vous êtes {translations.affiliated[gender]} à{" "}
      <SummaryLink
        to={appUrls.legalPension.status}
        linkTitle="Status de la retraite légale"
      >
        {translations.status[professionalStatus]}
      </SummaryLink>
      . Vous gagnez{" "}
      <SummaryLink
        to={appUrls.legalPension.currentRevenue}
        linkTitle="Vos revenus actuel"
      >
        {amount(salaryAmount)}/{globali18n.labels.salaryFrequency[frequency]}
      </SummaryLink>
      . Votre progression de revenu était{" "}
      <SummaryLink
        to={appUrls.legalPension.currentProgression}
        linkTitle="Votre progression de revenus"
      >
        {translations.salaryEvolution[evolutionBeforeSimulation]}
      </SummaryLink>{" "}
      et{" "}
      {evolutionBeforeSimulation === evolutionAfterSimulation
        ? translations.change.same
        : translations.change.willBe}{" "}
      <SummaryLink
        to={appUrls.legalPension.futureProgression}
        linkTitle="Votre progression de revenus futur"
      >
        {translations.salaryEvolution[evolutionAfterSimulation]}
      </SummaryLink>{" "}
      dans le futur.
    </section>
  );
}
