import { ChartData } from "../../../../../modules/dashboard/utilities/dashboard";
import { Module } from "../../../../../shared/models/Module";
import { i18n } from "../../../i18n";

export function useFutureCardHelperService() {
  function chooseTexts(
    chartData: ChartData,
    hasNoRemainingModules: boolean,
    hasAppointment: boolean,
    investorProfileExpired: boolean,
    recommendationAllowed: boolean,
    remainingModules: Module[],
    isRecommendationStatic: boolean
  ) {
    const t = i18n.dashboardPage.future;
    const wordingToReturn = {
      button: "",
      adversingNote: "",
      text: <></>,
    };
    const hasSurplus = chartData.hasSurplus;
    const hasDeficit = chartData.hasDeficit;

    if (investorProfileExpired) {
      if (hasDeficit) {
        wordingToReturn.button = t.buttons.weGoNext;
        wordingToReturn.text = t.texts.deficitExpired;
      }

      if (hasSurplus) {
        wordingToReturn.button = t.buttons.weGoNext;
        wordingToReturn.adversingNote = t.buttons.advertisingNature.weGoNext;
        wordingToReturn.text = t.texts.surplusExpired;
      }
    }

    if (hasAppointment && !hasNoRemainingModules) {
      wordingToReturn.button = t.buttons.continueJourney;
      wordingToReturn.text = t.texts.hasAppointment;
    }

    if (!hasAppointment && !hasNoRemainingModules) {
      wordingToReturn.button = t.buttons.continueJourney;
      wordingToReturn.text = t.texts.rightWay;
    }

    if (
      !hasAppointment &&
      hasDeficit &&
      remainingModules[0] === Module.InvestorProfile
    ) {
      wordingToReturn.button = t.buttons.weGoNext;
      wordingToReturn.adversingNote = t.buttons.advertisingNature.weGoNext;
      wordingToReturn.text = t.texts.deficit;
    }

    if (
      !hasAppointment &&
      hasSurplus &&
      remainingModules[0] === Module.InvestorProfile
    ) {
      wordingToReturn.button = t.buttons.weGoNext;
      wordingToReturn.adversingNote = t.buttons.advertisingNature.weGoNext;
      wordingToReturn.text = t.texts.surplus;
    }

    if (!hasAppointment && recommendationAllowed && !investorProfileExpired) {
      // For recommendation static, need to render specific wording for surplus and deficit case,
      // the investorProfile cannot be expired because the InvestorProfile module is not completed on Recommendation static mode
      if (isRecommendationStatic && (hasSurplus || hasDeficit)) {
        wordingToReturn.button = t.buttons.weGoNext;
        wordingToReturn.adversingNote = t.buttons.advertisingNature.weGoNext;
        wordingToReturn.text = hasSurplus ? t.texts.surplus : t.texts.deficit;
      } else {
        wordingToReturn.button = t.buttons.accessToRecommendation;
        wordingToReturn.adversingNote = t.buttons.advertisingNature.accessToRecommendation;
        wordingToReturn.text = t.texts.completed;
      }
    }

    if (hasAppointment && recommendationAllowed && !investorProfileExpired) {
      wordingToReturn.button = t.buttons.accessToRecommendation;
      wordingToReturn.adversingNote = t.buttons.advertisingNature.accessToRecommendation;
      wordingToReturn.text = t.texts.hasAppointment;
    }
    return wordingToReturn;
  }

  return { chooseTexts };
}
