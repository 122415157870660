import { globali18n } from '../../../i18n';
import { useNavigate } from 'react-router';
import { useLoader } from '../../../shared';
import { setAmount } from '../../../shared/layouts/ProgressLayout/progressSlice';
import { useAppUrls } from '../../../shared/utilities/urls';
import { useAppDispatch } from '../../../store';
import { resetAnswers } from '../pensionNeedSlice';

export function useRestartCommand() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showLoaderWhile } = useLoader();
  const appUrls = useAppUrls();

  async function restartCommand() {
    await showLoaderWhile(async () => {
      dispatch(resetAnswers());

      navigate(appUrls.pensionNeed.persona);
      
      dispatch(setAmount({ amount: 0, unit: globali18n.labels.eurosPerMonth }));;
    }, {key: "pensionNeed_useRestartCommand", value:`Reset module answers on file restartModuleCommand.ts`});
  }

  return restartCommand;
}
