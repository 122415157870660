import { PropsWithChildren, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import classNames, { Argument } from "classnames";
import { focusTrap } from "../FocusTrap/default";

interface KendoModalProps extends PropsWithChildren<{}> {
  title: string;
  onClose: () => void;
  width?: string;
  height?: string;
  className?: Argument;
  closeIcon?: boolean;
  contentStyle?: string;
}

export function StyledKendoModal(props: KendoModalProps) {
  const StyledDialog = styled(Dialog)`
    .k-dialog {
      max-width: ${props.width};
      width: 100%;
      max-height: ${props.height};
    }
      .k-dialog-content {
    ${props.contentStyle}
  }
  `;

  useEffect(() => {
    focusTrap();
  }, []);

  return (
    <StyledDialog
    closeIcon={props.closeIcon}
      id="kendo-modal"
      title={
        props.title && 
        <>
          <a tabIndex={0}></a>
          {props.title}
        </>
      }
      onClose={props.onClose}
      className={classNames(props.className)}
      autoFocus={true}
    >
      <div>{props.children}</div>
    </StyledDialog>
  );
}
