import classNames from "classnames";
import { globali18n } from "../../../../i18n";
import { BackgroundImage } from "../../../../modules/patrimony/components/BackgroundImage/BackgroundImage";
import { i18n } from "../../../../modules/patrimony/i18n";
import { retirementAge } from "../../../../modules/recommendation/utilities/format";
import { useNavigate } from "react-router";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import {
  ButtonStyles,
  Container,
  useModal,
  Button,
  ModalOutcome,
  Module,
} from "../../../../shared";
import { useInfoModal } from "../../../../shared/components/InfoModal/InfoModal";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { useAppSelector } from "../../../../store";
import styles from "./SummaryPage.module.scss";
import { amount, amountPerMonth } from "../../../../shared/utilities/intl";
import { useEffect, useState } from "react";
import { PatrimonyEvolutionModal } from "../../../../modules/patrimony/modals/PatrimonyEvolution/PatrimonyEvolutionModal";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";
import {
  ScenarioProjectionType,
  SimulationPatrimonyModel,
} from "../../../../modules/patrimony/models/SynthesisModel";
import { PatrimonyChart } from "./components/PatrimonyChart/PatrimonyChart";

type PatrimonyDescription = {
  description: JSX.Element;
  linkLabel: string;
};

export function SummaryPage() {
  const model = useAppSelector((s) => s.patrimony.synthesis)!;
  const navigate = useNavigate();
  const { showInfoModal } = useInfoModal();
  const { showModal } = useModal();
  const [descriptions, setDescriptions] = useState<PatrimonyDescription[]>([]);
  const { patrimonyLoadEvent, restartEvent, keepDoing } = useAnalytics();
  const { redirectAfterValidateCommand } = useGlobalCommand();

  const appUrls = useAppUrls();
  useEffect(() => {
    patrimonyLoadEvent(model);
    getPatrimonyDescription();
  }, []);

  const t = i18n.summaryPage;
  const neutralProjection = getSimulationProjection(
    ScenarioProjectionType.Neutral
  );

  function getSimulationProjection(
    type: ScenarioProjectionType
  ): SimulationPatrimonyModel | null {
    return model.simulations.find((sim) => sim.scenario === type) || null;
  }

  function getPatrimonyDescription(): void {
    const desc: PatrimonyDescription[] = [];
    // Add estimation description
    desc.push({
      linkLabel: t.knowMoreLink,
      description: t.estimateDesc(model.retirementAge),
    });
    // Add scenario description
    desc.push({
      linkLabel: amount(
        neutralProjection?.capitalAmountAtRetirement.totalPatrimonyValue || 0
      ),
      description: t.scenarioDesc(model.retirementAge),
    });
    setDescriptions(desc);
  }

  async function evolutionModal(origin: string) {
    const result = await showModal(<PatrimonyEvolutionModal data={model} />);
    if (result.outcome === ModalOutcome.Cancel) {
      document.getElementById(origin)!.focus();
      return;
    }
  }

  function howCalculatedModal(origin: string) {
    showInfoModal(
      t.howCalculatedModal.title,
      t.howCalculatedModal.content,
      false,
      origin
    );
  }

  async function validate() {
    keepDoing("patrimony");
    await redirectAfterValidateCommand(Module.Patrimony);
  }

  function editDeclaration() {
    restartEvent("patrimony");
    navigate(appUrls.patrimony.declaration);
  }

  return (
    <>
      <Container className={styles.Container}>
        <div className={styles.CardContainer}>
          <div className={styles.TitleGroup}>
            <div className={styles.Title}>
              {t.title} {retirementAge(model.retirementAge)}
            </div>
          </div>
          <hr />

          <div className={styles.EstimationValueBox}>
            {descriptions.map((element: PatrimonyDescription, index) => (
              <div key={`patrimony-descrption-${index}`} className={styles.EstimationDescriptionBox}>
                {element.description}{" "}
                <button
                  role="link"
                  aria-label="Plus d'informations sur l'estimation'"
                  className={styles.EstimationLink}
                  id={`patrimony-value${index}`}
                  onClick={() => evolutionModal(`patrimony-value${index}`)}
                >
                  {element.linkLabel}
                </button>
              </div>
            ))}
          </div>

          <div>
            <div className={styles.LegendsContainer}>
              <div className={styles.Legend}>
                <div className={styles.CubeFinancial}></div>
                <span>{t.financialProdile}</span>
              </div>
              <div className={styles.Legend}>
                <div
                  className={classNames(
                    styles.CubeFinancial,
                    styles.CubeRealEstate
                  )}
                ></div>
                <span>{t.realEstate}</span>
              </div>
            </div>
            <div>
              <PatrimonyChart />
            </div>
          </div>
        </div>

        <div className={styles.RightBlock}>
          <div className={styles.CardContainer}>
            <div className={styles.TitleGroup}>
              <div className={styles.Title}>
                {t.additionalIncome} {retirementAge(model.retirementAge)}
              </div>
            </div>
            <hr />

            <div className={styles.EstimationValueBox}>
              <button
                role="link"
                aria-label="Comment le patrimoine est calculé"
                className={styles.EstimationValue}
                id="how-patrimony-calculated"
                onClick={() => howCalculatedModal("how-patrimony-calculated")}
              >
                {amountPerMonth(
                  neutralProjection?.monthlyAmountAtRetirement
                    .totalPatrimonyValue || 0
                )}
              </button>
            </div>

            <span className={styles.EstimationValueDescription}>
              {t.mainResidenceText}
            </span>
            <div className={styles.AttentionBox}>
              <span className={styles.Title}> {t.attention} </span>
              <div> {t.attentionContent} </div>
            </div>
          </div>
        </div>
        <div className={styles.ButtonGroup}>
          <Button
            className={classNames(ButtonStyles.Unfill, styles.ValidateButton)}
            onClick={() => editDeclaration()}
          >
            {t.editDeclaration}
          </Button>
          <Button
            className={classNames(
              ButtonStyles.PrimaryAction,
              styles.ValidateButton
            )}
            onClick={validate}
          >
            {globali18n.labels.validateModule}
          </Button>
        </div>
      </Container>

      <BackgroundImage isSummaryPage={true} />
    </>
  );
}
