import styles from './BackgroundImage.module.scss';
import sapiendo from './images/sapiendo.png';
import { assets as Assets } from "../../../../Assets";
import { SvgWrapperComponent } from '../../../../../../shared/components/SvgWrapper/SvgWrapperComponent';

export function BackgroundImage() {
  return (
    <>
      <SvgWrapperComponent ariaHidden={true}><Assets.SummaryBackgroundImage className={styles.Books} /></SvgWrapperComponent>
      <img alt="" aria-hidden="true" src={sapiendo} className={styles.Sapiendo} />
    </>
  );
}
