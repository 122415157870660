import classNames, { Argument } from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./Interlayer.module.scss";
import { useGlobalCommand } from "../../../shared/commands/useGlobalCommand";

import { i18n } from "../../../shared/i18n";
import { SvgWrapperComponent } from "../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { getModuleCompletionRequest } from "../../../shared/requests/getModuleCompletionRequest";
import { AllModules, Module } from "../../../shared/models/Module";

import { ReactComponent as CheckMarkIcon } from "./images/CheckmarkIcon.svg";
import { ReactComponent as DiagnosticIcon } from "./images/diagnostic.svg";
import { ReactComponent as SolutionIcon } from "./images/solution.svg";
import { useParams } from "react-router-dom";
import { Container } from "../Container/Container";
import { ModuleStyles } from "../../../modules/legal-pension/styles/ModuleStyles";
import { globali18n } from "../../../i18n";

export const SubCardStyles = {
  XsHide: styles.XsHide,
  MdHide: styles.MdHide,
};

interface InterlayerProps extends PropsWithChildren<any> {
  className?: Argument;
  active?: Module;
  hideBottomObjective?: boolean;
  noGoal?: boolean;
}

interface InterlayerModule {
  id: Module | string,
  label: string,
  done: boolean
}

const modules: InterlayerModule[]  = [
  {
    id: Module.PensionNeed,
    label: i18n.moduleNamesInterlayer.PensionNeed,
    done: false,
  },
  {
    id: Module.LegalPension,
    label: i18n.moduleNamesInterlayer.LegalPension,
    done: false,
  },
  {
    id: Module.Taxation,
    label: i18n.moduleNamesInterlayer.Taxation,
    done: false,
  },
  {
    id: Module.Patrimony,
    label: i18n.moduleNamesInterlayer.Patrimony,
    done: false,
  },
  {
    id: Module.LifeProjects,
    label: i18n.moduleNamesInterlayer.LifeProjects,
    done: false,
  },
  {
    id: "Diagnostic",
    label: i18n.moduleNamesInterlayer.Diagnostic,
    done: false,
  },
  {
    id: Module.InvestorProfile,
    label: i18n.moduleNamesInterlayer.InvestorProfile,
    done: false,
  },
  {
    id: "Recommendation",
    label: i18n.moduleNamesInterlayer.Recommendation,
    done: false,
  },
];

export function Interlayer(props: InterlayerProps) {
  const { isCompleted, getDashboardModulesAvailable, isTenantRecommendationModeStatic } = useGlobalCommand();
  const [appModules, setModules] = useState<InterlayerModule[] >(modules);
  const [isAppModulesUpdated, setIsAppModulesUpdated] = useState<boolean>(false);
  const { customerid } = useParams();

  async function getModuleCompletion() {
    const dashboardModuleAvailable = getDashboardModulesAvailable();
    const modulesCompletion = await getModuleCompletionRequest(
      customerid,
      ...AllModules
    );
    const updatedModules: InterlayerModule[]  = [];

    // For Recommendation mode static, dont need to render recommendation page
    const noDashboardModule =  isTenantRecommendationModeStatic() ? ["Diagnostic"] : ["Diagnostic", "Recommendation"];

    appModules.forEach((aM) => {
      if(noDashboardModule.includes(aM.id) || dashboardModuleAvailable.find((module) => module === aM.id)) {
        updatedModules.push({ ...aM, done: isCompleted(modulesCompletion, aM.id) });
      }
    });
    
    setModules(updatedModules);
    setIsAppModulesUpdated(true);
  }

  useEffect(() => {
    getModuleCompletion();
  }, []);

  return (
    <Container className={classNames(styles.Container, ModuleStyles.PageGrid)}>
      <div className={styles.Banner}>
        <ul className={styles.List}>
          {isAppModulesUpdated && appModules.map((appModule) => (
            <li
              className={classNames(
                styles.Item,
                props.active === appModule.id && styles.Active
              )}
              key={appModule.label}
            >
              {appModule.label}
              {appModule.done ? (
                <SvgWrapperComponent
                  ariaLabel={`${globali18n.ariaLabel.module} ${appModule.label} ${globali18n.ariaLabel.isCompleted}`}
                >
                  <CheckMarkIcon className={styles.Icon} />
                </SvgWrapperComponent>
              ) : (
                <div style={{ width: "15px" }} />
              )}
            </li>
          ))}
        </ul>
      </div>

      {!props.noGoal && (
        <div className={classNames(styles.Interlayer)}>
          <div className={styles.Conclusion}>
            <h1 className={styles.GoalTitle}>
              {props.active === Module.InvestorProfile
                ? i18n.interlayer.lastOneTitle
                : i18n.interlayer.title}
            </h1>
            <div className={styles.Goal}>
              {props.hideBottomObjective === (false || undefined) && (
                <div>
                  <div>
                    <SvgWrapperComponent ariaHidden={true}>
                      <DiagnosticIcon />
                    </SvgWrapperComponent>
                    <div>{i18n.interlayer.diagnostic}</div>
                  </div>
                  <div className={styles.Divider}></div>
                </div>
              )}

              <div>
                <SvgWrapperComponent ariaHidden={true}>
                  <SolutionIcon />
                </SvgWrapperComponent>
                <div>{i18n.interlayer.solution}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.Content}>{props.children}</div>
    </Container>
  );
}
