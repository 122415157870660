import { PayloadAction } from '@reduxjs/toolkit';
import { QuestionnaireModel } from './models/computation/QuestionnaireModel';
import { DefaultScale } from './models/DefaultScale';
import { HealthType } from './models/health/health-type.enum';
import { HousingLocationType } from './models/housing/housing-location-type.enum';
import { HousingLocation } from './models/housing/housing-location.enum';
import { HousingResponseModel } from './models/housing/housing-reponse-model';
import { HousingSize } from './models/housing/housing-size.enum';
import { HousingTransactionType } from './models/housing/housing-transaction-type.enum';
import { HousingType } from './models/housing/housing-type.enum';
import { OtherType } from './models/other/other-type.enum';
import { AdvancedQuestionnaireCategoryInputModel } from './models/request/computationRequest.model';
import { SupplyType } from './models/supply/supply-type.enum';
import { TransportType } from './models/transport/transport-type.enum';
import { PensionNeedState, PensionNeedStateInitialized } from './pensionNeedSlice';
import { EComputeCategoryId } from './models/computation/ComputeCategory.enum';

function ensureQuestionnaire(model: HousingResponseModel) {
  delete model.definedValue;

  if (model.questionnaire == null) {
    model.questionnaire = {};
  }

  return model.questionnaire;
}

// Special case 1: locationType/location

export const housingLocationTypeReducer = (
  state: PensionNeedState,
  action: PayloadAction<HousingLocationType>
) => {
  const initializedState = state as unknown as PensionNeedStateInitialized;
  const questionnaire = ensureQuestionnaire(initializedState.answers.housing);
  questionnaire.locationType = action.payload;

  // The three types CountrySide, SeaSide and Mountain set both the locationType and the location
  if (questionnaire.locationType !== HousingLocationType.City) {
    questionnaire.location = action.payload as unknown as HousingLocation;
  } else {
    delete questionnaire.location;
  }
};

// Special case 2: transactionType/hasCredit

export const housingTransactionTypeReducer = (
  state: PensionNeedState,
  action: PayloadAction<{ transactionType: HousingTransactionType; hasCredit: boolean }>
) => {
  const questionnaire = ensureQuestionnaire(state.answers!.housing);
  questionnaire.transactionType = action.payload.transactionType;
  questionnaire.hasMortgage = action.payload.hasCredit;
};

// Special case 3: definedValue

export const housingDefinedValueReducer = (
  state: PensionNeedState,
  action: PayloadAction<number>
) => {
  delete state.answers!.housing.questionnaire;
  state.answers!.housing.definedValue = action.payload;
};

// Standard housing reducers

export const housingCitySizeReducer = (
  state: PensionNeedState,
  action: PayloadAction<HousingLocation>
) => {
  const questionnaire = ensureQuestionnaire(state.answers!.housing);
  questionnaire.location = action.payload;
};

export const housingSizeReducer = (state: PensionNeedState, action: PayloadAction<HousingSize>) => {
  const questionnaire = ensureQuestionnaire(state.answers!.housing);
  questionnaire.size = action.payload;
};

export const housingTypeReducer = (state: PensionNeedState, action: PayloadAction<HousingType>) => {
  const questionnaire = ensureQuestionnaire(state.answers!.housing);
  questionnaire.type = action.payload;
};

export enum NonHousingCategories {
  transport = 'transport',
  other = 'other',
  health = 'health',
  supply = 'supply'
}

// Standard reducers

export function createDefinedValueReducer<TType>(
  categoryFn: (s: PensionNeedStateInitialized) => AdvancedQuestionnaireCategoryInputModel[],
  type: TType,
  categoryId: EComputeCategoryId
) {
  return (state: PensionNeedState, action: PayloadAction<number>) => {
    const initializedState = state as unknown as PensionNeedStateInitialized;
    const answer = categoryFn(initializedState).find((a) => a.typeId === type);

    if (answer) {
      answer.scale = null;
      answer.definedValue = action.payload;
      return;
    }

    categoryFn(initializedState).push({ categoryId, typeId: type as string, definedValue: action.payload });
  };
}

export const supplyHomeDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.supply,
  SupplyType.HomeMeals,
  EComputeCategoryId.Supply
);
export const supplyRestaurantDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.supply,
  SupplyType.RestaurantAndDelivery,
  EComputeCategoryId.Supply
);

export const transportCarDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.transport,
  TransportType.Car,
  EComputeCategoryId.Transport
);
export const transportMotorbikeDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.transport,
  TransportType.Motorbike,
  EComputeCategoryId.Transport
);
export const transportPublicDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.transport,
  TransportType.PublicTransportation,
  EComputeCategoryId.Transport
);
export const transportBikeDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.transport,
  TransportType.Bike,
  EComputeCategoryId.Transport
);

export const otherTravelsDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.other,
  OtherType.Travels,
  EComputeCategoryId.Other
);
export const otherSportsDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.other,
  OtherType.Sports,
  EComputeCategoryId.Other
);
export const otherHobbiesDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.other,
  OtherType.Hobbies,
  EComputeCategoryId.Other
);

export const healthMedicalDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.health,
  HealthType.Medical,
  EComputeCategoryId.Health
);
export const healthUnexpectedDefinedValueReducer = createDefinedValueReducer(
  (s) => s.answers.health,
  HealthType.Unexpected,
  EComputeCategoryId.Health
);

// type Contains<T> = { value: T };

// type ExtractContains<Type> = Type extends Contains<infer T> ? T : never;
// type Obj = { a: Contains<string>; b: Contains<number>; c: string };
// type OObj = Omit<Obj, 'c'>;
// function setProperty<TProp extends keyof OObj, TValue extends ExtractContains<OObj[TProp]>>(
//   pn: TProp,
//   value: TValue
// ) {
//   let current = ini[pn];
//   current.value = value;
// }

// const ini = { a: { value: 'abc' }, b: { value: 123 }, c: 'efg' };

// setProperty('a', 123);
// setProperty('a', 'abc');
// setProperty('b', 123);
// setProperty('b', 'abc');

type QQuestionnaireModel = Omit<QuestionnaireModel, 'housing'>;

export function scaleValueReducer<TProp extends keyof QQuestionnaireModel>
(
  state: PensionNeedState,
  action: PayloadAction<{ categoryName: TProp; categoryId: EComputeCategoryId; type: string; scale: DefaultScale }>
) {
  const initializedState = state as PensionNeedStateInitialized;
  const {categoryId,categoryName, type, scale } = action.payload;

  const category = initializedState.answers[categoryName];
  const answer = category.find((a) => a.typeId === type as string);

  if (answer) {
    answer.definedValue = null;
    answer.scale = scale;
    return;
  }

  category.push({ categoryId, typeId: type as string, scale: action.payload.scale });
}

export function createScaleValueReducer<TType>(
  categoryFn: (s: PensionNeedStateInitialized) => AdvancedQuestionnaireCategoryInputModel[],
  type: TType,
  categoryId: EComputeCategoryId
) {
  return (state: PensionNeedState, action: PayloadAction<DefaultScale>) => {
    const initializedState = state as unknown as PensionNeedStateInitialized;
    const answer = categoryFn(initializedState).find((a) => a.typeId === type);

    if (answer) {
      delete answer.definedValue;
      answer.scale = action.payload;
      return;
    }

    categoryFn(initializedState).push({ categoryId, typeId: type as string, scale: action.payload });
  };
}

export const supplyHomeScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.supply,
  SupplyType.HomeMeals,
  EComputeCategoryId.Supply
);
export const supplyRestaurantScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.supply,
  SupplyType.RestaurantAndDelivery,
  EComputeCategoryId.Supply
);

export const transportCarScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.transport,
  TransportType.Car,
  EComputeCategoryId.Transport
);
export const transportMotorbikeScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.transport,
  TransportType.Motorbike,
  EComputeCategoryId.Transport
);
export const transportPublicScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.transport,
  TransportType.PublicTransportation,
  EComputeCategoryId.Transport
);
export const transportBikeScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.transport,
  TransportType.Bike,
  EComputeCategoryId.Transport
);

export const otherTravelsScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.other,
  OtherType.Travels,
  EComputeCategoryId.Other
);
export const otherSportsScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.other,
  OtherType.Sports,
  EComputeCategoryId.Other
);
export const otherHobbiesScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.other,
  OtherType.Hobbies,
  EComputeCategoryId.Other
);

export const healthMedicalScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.health,
  HealthType.Medical,
  EComputeCategoryId.Health
);
export const healthUnexpectedScaleValueReducer = createScaleValueReducer(
  (s) => s.answers.health,
  HealthType.Unexpected,
EComputeCategoryId.Health
);
