import { FunctionComponent, useState } from "react";
import { ModuleStyles } from "../../modules/legal-pension/styles/ModuleStyles";
import { RootState, useAppSelector } from "../../store";
import { ButtonStyles, Button, Container, QuestionTitle } from "../";
import styles from "./createEnumPage.module.scss";
import { useAnalytics } from "../../shared/commands/useAnalytics";

export function createEnumPage<TValue extends string>(config: {
  selector: (state: RootState) => TValue;
  commandInjector: () => (value: TValue) => void;
  values: { label: string; value: TValue }[];
  question: string;
  backgroundImage: FunctionComponent<any>;
  hasConfirmButton?: boolean;
  buttonLabel?: string;
}) {
  return function EnumPage() {
    const { answerTaxationQuestionEvent} = useAnalytics()
    const command = config.commandInjector();
    const currentValue = useAppSelector(config.selector);
    const BackgroundImage = config.backgroundImage;

    const [selectedValue, setSelectedValue] = useState<TValue>(currentValue);

    function selectAnswer(value: TValue) {
      setSelectedValue(selectedValue);
      if(config.question.includes("marital")) {
        answerTaxationQuestionEvent(
          value,
          "2/4",
          config.question,
          "marital-status"
        );
      }
      command(value);
    }

    return (
      <Container className={ModuleStyles.PageGrid}>
        <QuestionTitle>{config.question}</QuestionTitle>
        <div className={styles.AnswerGrid}>
          {config.values.map((a, i) => (
            <Button
              className={ButtonStyles.Answer}
              key={a.value}
              onClick={() =>
                !config.hasConfirmButton
                  ? selectAnswer(a.value)
                  : setSelectedValue(a.value)
              }
              selected={a.value === selectedValue}
              autoFocus={a.value === selectedValue}
            >
              {a.label}
            </Button>
          ))}
        </div>
        {config.hasConfirmButton && (
          <div className={styles.ButtonBloc}>
            <Button
              className={ButtonStyles.PrimaryAction}
              onClick={() => selectAnswer(selectedValue)}
            >
              {config.buttonLabel}
            </Button>
          </div>
        )}
        <BackgroundImage />
      </Container>
    );
  };
}
