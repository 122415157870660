import { createContext, ReactElement, useContext } from 'react';
import { ModalOutcome, ModalResult } from './ModalResult';

interface ModalContextReturn {
  showModal<T>(content: ReactElement): Promise<ModalResult<T>>;
  hideModal<T>(outcome: ModalOutcome, result?: T): void;

  show: boolean;
  content: ReactElement;
}

export const ModalContext = createContext<ModalContextReturn>({
  showModal: () => Promise.resolve({ outcome: ModalOutcome.Cancel, value: null }),
  hideModal: () => {},
  show: false,
  content: <></>
});

export function useModal() {
  return useContext(ModalContext);
}
