import { AppointmentInputModel } from "../../modules/appointment/models/AppointmentInputModel";
import { DashboardModel } from "../../modules/dashboard/models/DashboardModel";
import {
  AgeTypeResult,
  ComputationOutputModel,
} from "../../modules/legal-pension/models/ComputationOutputModel";
import { LifeProjectsModel } from "../../modules/life-projects/models/LifeProjectsModel";
import { PatrimonyModel } from "../../modules/patrimony/models/PatrimonyModel";
import { ScenarioProjectionType, SynthesisModel } from "../../modules/patrimony/models/SynthesisModel";
import { EComputeCategoryId } from "../../modules/pension-need/models/computation/ComputeCategory.enum";
import {
  ParametersInputModel,
  RecommendationOutputModel,
} from "../../modules/recommendation/models/RecommendationModel";
import { useLocation } from "react-router";
import { SavingsAccountProductType } from "../../modules/patrimony/models/ProductType";
import { PageViewModel } from "../../shared/models/PageView";
import { useAppSelector } from "../../store";
import { UserType } from "../../shared/requests/getUserReferenceRequest";
import { GamifiedDetailedValue } from "../../modules/pension-need/models/request/validationRequest.model";
import { GetSavingAccountResponseModel } from "../../modules/patrimony/models/SavingsAccountModel";

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

const debug = window.localStorage.getItem("debug-datalayer") === "true";

export function useAnalytics() {
  const userTypeStore = useAppSelector((s) => s.authentication.userType);

  const userIDStore = useAppSelector((s) => s.authentication.customerId);
  const { pathname } = useLocation();

  // Make sure that the analytics never crash the program
  function noThrow(fn: Function) {
    try {
      fn();
    } catch (err) {
      console.error(err);
    }
  }

  // Push a new event into the GTM data layer
  function pushOld(name: string, value?: any) {
    const event: any = {
      event: name,
      url: pathname,
    };

    if (value) {
      event.data = value;
    }

    window.dataLayer?.push(event);

    if (debug) {
      console.log("Event added", event);
      console.log("DataLayer : ", window.dataLayer);
    }
  }

  function push(value: any) {
    if (userTypeStore === UserType.User) {
      value["user_id"] = userIDStore;
    } else {
      value["user_id"] = "";
    }

    window.dataLayer?.push(value);

    if (debug) {
      console.log("Event added", value);
      console.log("DataLayer : ", window.dataLayer);
    }
  }

  function answerLegalEvent(
    data: any,
    step: string,
    questionTitle: string,
    origin: string,
    simulatorScreen: string
  ) {
    noThrow(() => {
      const formatedData = {
        event: "questionnaire",
        question: questionTitle,
        answer: data,
        simulator_step: step,
        simulator_type:
          origin === "legal_pension"
            ? "Votre retraite légale"
            : "Vos besoins à la retraite",
        simulator_screen: simulatorScreen,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function answerNeedsEvent(data: any, questionTitle?: string) {
    noThrow(() => {
      let step = "";
      let screen = "";

      if (pathname.includes("housing")) {
        step = "1/6";
        screen = "housing";
      }
      if (pathname.includes("transport")) {
        step = "2/6";
        screen = "transport";
      }
      if (pathname.includes("supply")) {
        step = "3/6";
        screen = "supply";
      }
      if (pathname.includes("others")) {
        step = "4/6";
        screen = "others";
      }
      if (pathname.includes("health")) {
        step = "5/6";
        screen = "health";
      }

      if (pathname.includes("summary")) {
        screen = "summary";
      }

      const formatedData = {
        event: "questionnaire",
        question: questionTitle,
        answer: data,
        simulator_step: step,
        simulator_screen: screen,
        simulator_type: "Vos besoins à la retraite",
        url: pathname,
      };
      push(formatedData);
    });
  }

  function answerTaxationEvent(data: any) {
    noThrow(() => {
      const formatedData = {
        event: "simulation_taxation",
        deductible_amount: data.availableFiscalDeductionAmount,
        investment_rate: data.investmentRate,
        investment: data.investment,
        saving_income_perin: data.savingIncomePerin,
        net_saving: data.netSaving,
        tmi_rate: data.tmiRate,
        taxation_default: data.taxationDefault,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function answerTaxationQuestionEvent(
    data: any,
    step: string,
    questionTitle: string,
    simulatorScreen: string
  ) {
    noThrow(() => {
      const formatedData = {
        event: "questionnaire",
        question: questionTitle,
        answer: data,
        simulator_step: step,
        simulator_type: "Votre fiscalité",
        simulator_screen: simulatorScreen,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function addFinancialPatrimony() {
    noThrow(() => {
      const formatedData = {
        event: "patrimony_edit",
        patrimony_thematic: "placements_financiers",
        url: pathname,
      };
      push(formatedData);
    });
  }

  function addRealEstatePatrimony() {
    noThrow(() => {
      const formatedData = {
        event: "patrimony_edit",
        patrimony_thematic: "cessions_immobilieres",
        url: pathname,
      };
      push(formatedData);
    });
  }

  function registerEvent() {
    noThrow(() => {
      push({
        event: "sign_up",
      });
    });
  }

  function registerModal(target: string) {
    noThrow(() => {
      const formatedData = {
        event: "diagnostic_access_option",
        cta_name: target,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function loginEvent() {
    noThrow(() => {
      push({
        event: "login",
      });
    });
  }

  function logoutEvent() {
    noThrow(() => {
      push({
        event: "logout",
      });
    });
  }

  function dashboardEvent(
    model: DashboardModel,
    objectiveAmount: number | null,
    objectiveCompletion: number | null,
    needsRevenues: any,
    availableFiscalDeductionAmount: number | null
  ) {
    const data = {
      event: "diagnostic",
      pension_need: model?.pensionNeedMonthlyAmount,
      legal_pension: model?.legalPensionMonthlyAmount,
      life_projects: model?.lifeProjectsMonthlyAmount,
      patrimony: model?.patrimonyMonthlyAmount,
      income: needsRevenues.revenues,
      cost: needsRevenues.needs,
      deductible_amount: availableFiscalDeductionAmount,
      coverage_positive: 0,
      coverage_rate_positive: 0,
      coverage_negative: 0,
      coverage_rate_negative: 0,
      url: pathname,
    };

    if (objectiveAmount) {
      if (objectiveAmount < 0) {
        data.coverage_negative = objectiveAmount;
        if (objectiveCompletion) {
          data.coverage_rate_negative = objectiveCompletion;
        }
      } else {
        data.coverage_positive = objectiveAmount;
        if (objectiveCompletion) {
          data.coverage_rate_positive = objectiveCompletion;
        }
      }
    }

    noThrow(() => {
      push(data);
    });
  }

  function dashboardValidate() {
    noThrow(() => {
      push({
        event: "saving_solution_access",
        url: pathname,
      });
    });
  }

  function headerCTAEvent(type: string) {
    noThrow(() => {
      push({
        event: "header_menu_simulator",
        category: type,
      });
    });
  }

  function landingCTAEvent(simulatorType: string) {
    noThrow(() => {
      push({
        event: "start_a_simulation",
        category: simulatorType,
      });
    });
  }

  function needsValidateEvent(model: GamifiedDetailedValue[]) {
    noThrow(() => {

      const sum = model.reduce((accumulator, value) => {
        return accumulator + value.value;}, 0);

      const findCategoryGamifyValue = (categoryId: EComputeCategoryId): number => {
        const category = model.find((val)=> val.categoryId === categoryId);
        return category ? category.value : 0
      }

      push({
        event: "simulation_pension_need",
        housing: findCategoryGamifyValue(EComputeCategoryId.Housing),
        transport: findCategoryGamifyValue(EComputeCategoryId.Transport),
        food: findCategoryGamifyValue(EComputeCategoryId.Supply),
        leisure: findCategoryGamifyValue(EComputeCategoryId.Other),
        imponderable: findCategoryGamifyValue(EComputeCategoryId.Health),
        pension_need: sum,
      });
    });
  }

  function legalValidateEvent(
    model: ComputationOutputModel,
    chosenScenario: string
  ) {
    let index = "legalAgeResult";
    switch (chosenScenario) {
      case "LegalAge":
        index = "legalAgeResult";
        break;
      case "FullRateAge":
        index = "fullRateAgeResult";
        break;
      case "MaxAge":
        index = "maxAgeResult";
        break;
    }
    const datas = model.results[index as keyof typeof model.results] as AgeTypeResult;

    const ageFormater = () => {
      if (datas.age.month !== 0) {
        return `${datas.age.year} ans et ${datas.age.month} mois`;
      }
      return `${datas.age.year} ans`;
    };

    const gap = datas.lastSalaryAmount - datas.pensionAmount;

    push({
      event: "simulation_legal_pension",
      age: ageFormater(),
      last_income: datas.lastSalaryAmount,
      gap_income: gap - gap * 2,
      legal_pension: datas.pensionAmount,
    });
  }

  function projectsValidateEvent(model: LifeProjectsModel) {
    const event = {
      event: "simulation_life_projects",
      real_estate: model.realEstateAssets,
      pleasure_buying: model.pleasurePurchases,
      family: model.family,
      autonomy: model.anticipation,
      philanthropy: model.philanthropy,
      other: model.other,
    };

    noThrow(() => {
      push(event);
    });
  }

  function lifeProjectEdit(selected: string) {
    noThrow(() => {
      push({
        event: "life_projects_edit",
        life_projects_thematic: selected,
      });
    });
  }

  function pageView(pageInformation: PageViewModel) {
    noThrow(() => {
      push({
        event: "page_view",
        page_title: pageInformation.page_title,
        page_path: pageInformation.page_path,
        page_type: pageInformation.page_type,
        simulator_screen: pageInformation.simulator_screen,
        simulator_type: pageInformation.simulator_type,
        simulator_step: pageInformation.simulator_step,
        login: pageInformation.login,
        user_id: pageInformation.user_id,
      });
    });
  }

  function patrimonyDeclarationsEvent(
    model: PatrimonyModel,
    monthlyData: number
  ) {
    let eventToSend = {
      event: "patrimony_see_projection",
      current_account_amount: 0,
      current_account_month_save: 0,
      current_account_number: 0,
      saving_account_amount: 0,
      saving_account_month_save: 0,
      saving_account_number: 0,
      real_estate_assets: 0,
      pel_amount: 0,
      pel_month_save: 0,
      pel_number: 0,
      life_insur_amount: 0,
      life_insur_month_save: 0,
      life_insur_number: 0,
      secur_account_amount: 0,
      secur_account_month_save: 0,
      secur_account_number: 0,
      pea_amount: 0,
      pea_month_save: 0,
      pea_number: 0,
      per_ind_amount: 0,
      per_ind_month_save: 0,
      per_ind_number: 0,
      perp_amount: 0,
      perp_month_save: 0,
      perp_number: 0,
      madelin_amount: 0,
      madelin_month_save: 0,
      madelin_number: 0,
      per_company_amount: 0,
      per_company_month_save: 0,
      per_company_number: 0,
      pero_amount: 0,
      pero_month_save: 0,
      pero_number: 0,
      pee_amount: 0,
      pee_month_save: 0,
      pee_number: 0,
      rental_income: monthlyData,
    };
    if (model.realEstates.length !== 0) {
      const sum = model.realEstates.reduce((acc, curr) => {
        return (acc = acc + curr.currentAmount);
      }, 0);
      eventToSend = {
        ...eventToSend,
        real_estate_assets: sum,
      };
    }

    if (model.savingsAccounts.length !== 0) {
      const countNumberOfElement = (identifier: SavingsAccountProductType) => {
        return model.savingsAccounts.filter(
          (element: GetSavingAccountResponseModel) =>
            element.productTypeId === identifier
        ).length;
      };

      model.savingsAccounts.map((item: GetSavingAccountResponseModel) => {
        switch (item.productTypeId) {
          case SavingsAccountProductType.AssuranceVie:
            eventToSend["saving_account_amount"] = item.currentAmount;
            eventToSend["saving_account_month_save"] = item.monthlySavings;
            eventToSend["saving_account_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.CompteCourant:
            eventToSend["current_account_amount"] = item.currentAmount;
            eventToSend["current_account_month_save"] = item.monthlySavings;
            eventToSend["current_account_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.AssuranceVie:
            eventToSend["life_insur_amount"] = item.currentAmount;
            eventToSend["life_insur_month_save"] = item.monthlySavings;
            eventToSend["life_insur_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PEL:
            eventToSend["pel_amount"] = item.currentAmount;
            eventToSend["pel_month_save"] = item.monthlySavings;
            eventToSend["pel_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PEA:
            eventToSend["pea_amount"] = item.currentAmount;
            eventToSend["pea_month_save"] = item.monthlySavings;
            eventToSend["pea_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PERIndividuel:
            eventToSend["per_ind_amount"] = item.currentAmount;
            eventToSend["per_ind_month_save"] = item.monthlySavings;
            eventToSend["per_ind_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PERP:
            eventToSend["perp_amount"] = item.currentAmount;
            eventToSend["perp_month_save"] = item.monthlySavings;
            eventToSend["perp_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.Madelin:
            eventToSend["madelin_amount"] = item.currentAmount;
            eventToSend["madelin_month_save"] = item.monthlySavings;
            eventToSend["madelin_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PEREntrepriseOrPERCO:
            eventToSend["per_company_amount"] = item.currentAmount;
            eventToSend["per_company_month_save"] = item.monthlySavings;
            eventToSend["per_company_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PEROEntrepriseOrArticle83:
            eventToSend["pero_amount"] = item.currentAmount;
            eventToSend["pero_month_save"] = item.monthlySavings;
            eventToSend["pero_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.PEE:
            eventToSend["pee_amount"] = item.currentAmount;
            eventToSend["pee_month_save"] = item.monthlySavings;
            eventToSend["pee_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          case SavingsAccountProductType.CompteTitres:
            eventToSend["secur_account_amount"] = item.currentAmount;
            eventToSend["secur_account_month_save"] = item.monthlySavings;
            eventToSend["secur_account_number"] = countNumberOfElement(
              item.productTypeId
            );
            break;
          default:
            break;
        }
      });
    }
    console.log("Saving account model retur data", eventToSend)
    push(eventToSend);
  }

  function patrimonyLoadEvent(model: SynthesisModel) {
    const age = `${model.retirementAge.year} ans${
      model.retirementAge.month !== 0
        ? ` ${model.retirementAge.month} mois`
        : ""
    }`;
    const neutralProjection = model.simulations.find((sim) => sim.scenario === ScenarioProjectionType.Neutral)
    push({
      event: "patrimony_projection",
      age: age,
      actual_global_patrimony: model.currentPatrimony.totalPatrimonyValue,
      actual_fin_patrimony: model.currentPatrimony.savingsAccountValue,
      actual_real_estate_patrimony: model.currentPatrimony.realEstateValue,

      futur_global_patrimony:
      neutralProjection?.capitalAmountAtRetirement.totalPatrimonyValue,
      futur_fin_patrimony: neutralProjection?.capitalAmountAtRetirement.savingsAccountValue,
      futur_real_estate_patrimony:
      neutralProjection?.capitalAmountAtRetirement.realEstateValue,

      addition_income_month:
        neutralProjection?.monthlyAmountAtRetirement.totalPatrimonyValue,
    });
  }

  function restartEvent(target: string) {
    noThrow(() => {
      push({
        event: "restart",
        simulator_type: target,
        url: pathname,
      });
    });
  }

  function keepDoing(target: string) {
    noThrow(() => {
      push({
        event: "keep_going",
        simulator_type: target,
        url: pathname,
      });
    });
  }

  function resetRecommendationEvent() {
    noThrow(() => {
      push({
        event: "saving_solution_reset",
        url: pathname,
      });
    });
  }

  function recommendationEvent(
    parameters: ParametersInputModel,
    output: RecommendationOutputModel,
    eventType: string
  ) {
    enum ProductTypeIdentifier {
      PEA = "PEA",
      PERIndividuel = "PERIndividuel",
      AssuranceVie = "AssuranceVie",
      Immobilier = "Immobilier",
    }

    const neutral = output.additionalRetirementIncome.neutral;

    const getValue = (identifier: ProductTypeIdentifier) => {
      const data = output.transactions.productTypeTransactions.filter(
        (e) => e.productTypeId === identifier
      );

      if (data.length > 0) {
        return {
          monthlyTransactionAmount: data[0].monthlyTransactionAmount,
          transactionAmount: data[0].transactionAmount,
        };
      } else {
        return {
          monthlyTransactionAmount: 0,
          transactionAmount: 0,
        };
      }
    };
    const data = {
      event: eventType,
      addition_income_month: neutral.monthlyAmount,
      pension_capital: neutral.capitalAmountAtRetirement,
      first_payment: output.transactions.totalTransactionAmount,
      first_pea_amount: getValue(ProductTypeIdentifier.PEA).transactionAmount,
      first_scpr_amount: getValue(ProductTypeIdentifier.Immobilier)
        .transactionAmount,
      first_per_amount: getValue(ProductTypeIdentifier.PERIndividuel)
        .transactionAmount,
      first_life_insurance_amount: getValue(ProductTypeIdentifier.AssuranceVie)
        .transactionAmount,
      monthly_payment: output.transactions.totalMonthlyTransactionAmount,
      monthly_pea_amount: getValue(ProductTypeIdentifier.PEA).monthlyTransactionAmount,
      monthly_scpr_amount: getValue(ProductTypeIdentifier.Immobilier)
        .monthlyTransactionAmount,
      monthly_per_amount: getValue(ProductTypeIdentifier.PERIndividuel)
        .monthlyTransactionAmount,
      monthly_life_insurance_amount: getValue(ProductTypeIdentifier.AssuranceVie)
        .monthlyTransactionAmount,
      age: parameters.retirementScenario,
      url: pathname,
    };

    noThrow(() => {
      push(data);
    });
  }

  function dashboardCardEvent(module: string) {
    noThrow(() => {
      push({
        event: "diagnostic_simulator_content",
        cta_name: module,
        url: pathname,
      });
    });
  }

  function downloadReport() {
    noThrow(() => {
      push({
        event: "file_download",
        file_name: "report",
        url: pathname,
      });
    });
  }

  function makeAppointment() {
    noThrow(() => {
      push({
        event: "make_appointment",
        url: pathname,
      });
    });
  }

  function contactConfirm() {
    noThrow(() => {
      push({
        event: "contact_confirmation",
        url: pathname,
      });
    });
  }

  function personaType(personae: string) {
    noThrow(() => {
      push({
        event: "personae",
        personae_type: personae,
        url: pathname,
      });
    });
  }

  function appointmentEvent(model: AppointmentInputModel) {
    noThrow(() => {
      // Do not send personal data (phone number)
      pushOld("appointment", model);
    });
  }

  function accountDeletionEvent() {
    noThrow(() => {
      pushOld("delete_account");
    });
  }

  function activationEvent(ctaName: string) {
    noThrow(() => {
      push({
        event: "account_activation",
        cta_name: ctaName,
        url: pathname,
      });
    });
  }

  function eventErrorPage() {
    noThrow(() => {
      push({
        event: "error",
        error_type: "erreur_technique",
      });
    });
  }

  function answerInvestorProfileEvent(
    data: any,
    step?: string,
    questionTitle?: string,
    type?: string
  ) {
    noThrow(() => {
      const formatedData = {
        event: "questionnaire",
        question: questionTitle,
        answer: data,
        simulator_step: step,
        simulator_type: type,
        simulator_screen: "Votre profile",
        url: pathname,
      };
      push(formatedData);
    });
  }

  function continueJourneyEvent() {
    noThrow(() => {
      const formatedData = {
        event: "continue_your_journey",
        url: pathname,
      };
      push(formatedData);
    });
  }

  function financialSummaryEvent(
    riskLevel: string,
    scenario: string,
    additionalIncome: number,
    eventName: string
  ) {
    noThrow(() => {
      const formatedData = {
        event: eventName,
        addition_income_month: additionalIncome,
        risk_score: riskLevel,
        risk_profil: scenario,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function taxSavingInfoModalEvent(data: any) {
    noThrow(() => {
      const formatedData = {
        event: "tax_savings",
        monthly_tax_savings_amount: data.taxSavingAmountMonthly,
        first_tax_savings_amount: data.taxSavingAmount,
        monthly_per_ind_amount: data.amountPERAmountMonthly,
        first_per_ind_amount: data.amountPERAmount,
        fiscal_threshold_available: data.tot,
        tmi_rate: data.tmiPercentage,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function productPageEvent(ctaName: string) {
    noThrow(() => {
      const formatedData = {
        event: "product_information",
        cta_name: ctaName,
        url: pathname,
      };
      push(formatedData);
    });
  }

  function productDetailsEvent() {
    noThrow(() => {
      const formatedData = {
        event: "product_details",
        url: pathname,
      };
      push(formatedData);
    });
  }

  return {
    productDetailsEvent,
    financialSummaryEvent,
    taxSavingInfoModalEvent,
    productPageEvent,
    // Global
    restartEvent,
    contactConfirm,
    personaType,
    dashboardCardEvent,
    eventErrorPage,
    continueJourneyEvent,

    //activation
    activationEvent,

    // Authentication
    loginEvent,
    logoutEvent,
    // Dashboard
    dashboardEvent,
    dashboardValidate,
    registerModal,
    // Needs
    answerNeedsEvent,
    needsValidateEvent,
    // Legal
    answerLegalEvent,
    legalValidateEvent,
    // Taxation
    answerTaxationEvent,
    // Life projects
    projectsValidateEvent,
    lifeProjectEdit,
    // Patrimony
    addFinancialPatrimony,
    addRealEstatePatrimony,
    patrimonyDeclarationsEvent,
    patrimonyLoadEvent,
    // Registration
    registerEvent,
    // Profile
    accountDeletionEvent,
    // Recommendation
    recommendationEvent,
    downloadReport,
    makeAppointment,
    resetRecommendationEvent,

    //CTA
    landingCTAEvent,
    headerCTAEvent,
    keepDoing,
    appointmentEvent,

    answerInvestorProfileEvent,
    answerTaxationQuestionEvent,
    //Page View
    pageView,
  };
}
