import { globali18n } from "../../../../../i18n";
import {
  Card,
  Container,
  ModalOutcome,
  useModal,
  Button,
} from "../../../../../shared";
import { number } from "../../../../../shared/utilities/intl";
import { useAppSelector } from "../../../../../store";
import { i18n } from "../../../i18n";
import styles from "./SummaryPage.module.scss";
import * as SummaryIcons from "./images";
import {
  CategoryCard,
  CategoryCardProps,
} from "./components/CategoryCard/CategoryCard";
import { ButtonStyles } from "../../../../../shared/components/Button/ButtonStyles";
import classNames from "classnames";
import { Styles } from "../../../styles/Styles";
import { Footer } from "../../../components/Footer/Footer";
import { useRestartCommand } from "../../../commands/restartModuleCommand";
import { useGamifyValueCommand } from "../../../commands/gamifyValueCommand";
import { useValidateCommand } from "../../../commands/validateCommand";
import { InfoModal } from "./components/InfoModal/InfoModal";
import { sumTotals } from "../../../utilities/computeTotal";
import { useParams } from "react-router-dom";
import { assets as Assets } from "../../../Assets";
import { useAnalytics } from "../../../../../shared/commands/useAnalytics";
import { useEffect } from "react";
import { EComputeCategoryId } from "../../../models/computation/ComputeCategory.enum";

export function SummaryPage() {
  const gamifiedValues = useAppSelector((s) => s.pensionNeed.gamifiedValues)!;
  const { customerid } = useParams();
  const { showModal, hideModal } = useModal();

  const { validateCommand } = useValidateCommand();
  const restartCommand = useRestartCommand();
  const { gamifyValueCommand } = useGamifyValueCommand();
  const { restartEvent, keepDoing, needsValidateEvent } = useAnalytics();

  useEffect(() => {
    needsValidateEvent(gamifiedValues);
  }, []);

  const totals = sumTotals(gamifiedValues);

  async function displayModal(selected: string, id: string) {
    const rootTarget = document.getElementById("root");
    rootTarget?.addEventListener("keydown", (event) => {
      if (event.code === "Escape") {
        hideModal(ModalOutcome.Cancel);
        document.getElementById(id + "-info");
      }
    });
    const result = await showModal(<InfoModal model={selected} />);

    if (result.outcome === ModalOutcome.Cancel) {
      document.getElementById(id + "-info")!.focus();
      // The user cancelled the modal, stay on the current page
      return;
    }
  }

  const cardData: CategoryCardProps[] = [
    {
      icon: SummaryIcons.Housing,
      amount: findCategoryGamifyValue(EComputeCategoryId.Housing),
      label: i18n.categories.housing,
      onClick: () =>
        edit(EComputeCategoryId.Housing, i18n.categories.housing),
      showInfoModal: () => displayModal("housing", i18n.categories.housing),
    },
    {
      icon: SummaryIcons.Transport,
      amount: findCategoryGamifyValue(EComputeCategoryId.Transport),
      label: i18n.categories.transport,
      onClick: () =>
        edit(EComputeCategoryId.Transport, i18n.categories.transport),
      showInfoModal: () => displayModal("transport", i18n.categories.transport),
    },
    {
      icon: SummaryIcons.Supply,
      amount: findCategoryGamifyValue(EComputeCategoryId.Supply),
      label: i18n.categories.supply,
      onClick: () =>
        edit(EComputeCategoryId.Supply, i18n.categories.supply),
      showInfoModal: () => displayModal("supply", i18n.categories.supply),
      id: EComputeCategoryId.Supply,
    },
    {
      icon: SummaryIcons.Other,
      amount: findCategoryGamifyValue(EComputeCategoryId.Other),
      label: i18n.categories.other,
      onClick: () =>
        edit(
          EComputeCategoryId.Other,
          i18n.categories.other
        ),
      showInfoModal: () => displayModal("hobbies", i18n.categories.other),
      id: EComputeCategoryId.Other,
    },
    {
      icon: SummaryIcons.Health,
      amount: findCategoryGamifyValue(EComputeCategoryId.Health),
      label: i18n.categories.health,
      onClick: () =>
        edit(EComputeCategoryId.Health, i18n.categories.health),
      showInfoModal: () => displayModal("health", i18n.categories.health),
      id: EComputeCategoryId.Health,
    },
  ];

  

  function findCategoryGamifyValue(categoryId: EComputeCategoryId): number {
    const category = gamifiedValues.find((val)=> val.categoryId === categoryId);
    return category ? category.value : 0
  }

  function validate() {
    keepDoing("pension_need");
    validateCommand(customerid);
  }

  function restart() {
    restartEvent("pension_need");
    restartCommand();
  }

  async function edit(propertyName: EComputeCategoryId, label: string) {
    gamifyValueCommand(propertyName, label);
  }

  return (
    <>
      <Container className={classNames(styles.Grid, Styles.Background)}>
        <Card className={styles.TitleGrid}>
          <div className={styles.Title}>{i18n.summaryPage.title}</div>
          <div className={styles.AmountGroup}>
            <span className={styles.Amount}>{number(totals)}</span>{" "}
            <span className={styles.Unit}>
              {globali18n.labels.eurosPerMonth}
            </span>
          </div>
        </Card>
        <div className={styles.CategoryGrid}>
          {cardData.map((cd, i) => (
            <CategoryCard key={i} {...cd} id={cd.label} />
          ))}
        </div>
        <div className={styles.Information}>{i18n.summaryPage.information}</div>
        <div className={styles.ButtonGrid}>
        <Button
            className={classNames(ButtonStyles.Unfill, styles.RestartButton)}
            onClick={restart}
          >
            {i18n.summaryPage.editChoise}
          </Button>
          <Button
            className={classNames(
              ButtonStyles.PrimaryAction,
              styles.ValidateButton
            )}
            onClick={validate}
          >
            {globali18n.labels.validateModule}
          </Button>
        </div>
      </Container>
      <Assets.BackgroundImage className={styles.BackgroundImage} />
      <Footer>{i18n.summaryPage.footer}</Footer>
    </>
  );
}
