import { Controller, useForm } from 'react-hook-form';

import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import {
  Container,
  PageTitle,
  ValidationMessages,
  ButtonStyles,
  Button,
  InputGroup,
  useGlobalCommand
} from '@ifs/libs';
import styles from './AppointmentPage.module.scss';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { i18n } from 'modules/appointment/i18n';
import { useAppSelector } from 'store';
import { dateLong } from '@ifs/libs/src/shared/utilities/intl';
import { TimeSlot } from 'modules/appointment/models/TimeSlot';
import { getFormConfig } from './formConfig';
import { useNavigate } from 'react-router-dom';
import { useSetAppointmentCommand } from 'modules/appointment/commands/setAppointmentCommands';
import { ZipCodesCombobox } from 'modules/appointment/components/ZipCodesCombobox/ZipCodesCombobox';
import { condition as DisplayedBlock } from '../../Displayed';
import { useAnalytics } from '@ifs/libs/src/shared/commands/useAnalytics';
import { formRule } from 'modules/appointment/FormRules';
import { useExternalUrls } from '@ifs/libs/src/shared/utilities/externalUrls';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import { globali18n } from '@ifs/libs/src/i18n';
import classNames from 'classnames';
import { BackgroundImage } from '@ifs/libs/src/shared/components/BackgroundImage/BackgroundImage';
import { useSetAccountPreference } from 'modules/profile/commands/setAccountPreference';
import { useResolver } from 'modules/profile/resolver/resolver';
import { UserInformationFormComponent } from 'modules/appointment/components/UserInformationForm/UserInformationFormComponent';

export interface FormData {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  desiredDate?: string;
  selectedTimeSlotIdentifier?: TimeSlot | '';
  zipCode?: any;
}

const defaultValues: FormData = {
  phoneNumber: '+33'
};
const formConfig = getFormConfig();

export function AppointmentPage() {
  const { preferences } = useResolver();
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormData>({
    defaultValues: defaultValues,
    criteriaMode: 'all'
  });
  const navigate = useNavigate();
  const available = useAppSelector((s) => s.appointment.available)!;
  const { setAppointmentCommand } = useSetAppointmentCommand();
  const { setAccountPreferenceCommand } = useSetAccountPreference();
  const { isAuthenticationModeSSO } = useGlobalCommand();
  const { contactConfirm } = useAnalytics();
  const { externalUrls } = useExternalUrls();

  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [dates] = useState<string[]>(available.map((a) => a.date));
  const desiredDate = watch().desiredDate;
  const authenticationSSO = isAuthenticationModeSSO();

  useEffect(() => {
    if (!formConfig) return;
    formConfig.desiredDate.rules.required = formRule.desiredDateRequired;
    formConfig.desiredTimeSlot.rules.required = formRule.desiredTimeSlotRequired;
    formConfig.lastName.rules.required = formRule.lastNameRequired;
    formConfig.firstName.rules.required = formRule.firstNameRequired;
    formConfig.email.rules.required = formRule.emailRequired;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formConfig]);

  useEffect(() => {
    if (preferences) {
      setValue('firstName', preferences.firstName || undefined);
      setValue('lastName', preferences.lastName || undefined);
      setValue('email', preferences.email || undefined);
    }
  }, [preferences]);

  // Always reinitialize the timeslot when the date changes
  useEffect(() => {
    const timeSlots = available.find((a) => a.date === desiredDate)?.timeSlots ?? [];
    const oldSlotsVar = timeSlots.map((e) => e.id).sort(sortTimeSlotIdentifier);
    setTimeSlots(oldSlotsVar as TimeSlot[]);
    setValue('selectedTimeSlotIdentifier', '');
    getLink();
  }, [desiredDate]);

  function sortTimeSlotIdentifier(a: string, b: string): number {
    let aElementToSort: any = a;
    let bElementToSort: any = b;
    // First catch only number from id
    const aNumber = a.match(/[0-9]/g);
    const bNumber = b.match(/[0-9]/g);

    // If catch number on two sort element, join the array and convert it to number
    if (aNumber && bNumber) {
      aElementToSort = parseInt(aNumber.join(''));
      bElementToSort = parseInt(bNumber.join(''));
    }
    return aElementToSort === bElementToSort ? 0 : aElementToSort < bElementToSort ? -1 : 1;
  }

  async function validate(value: FormData) {
    contactConfirm();
    // First Step Save or Patch User Information
    await saveUserInformation(value);

    const isAppointmentDefine = value.desiredDate && value.selectedTimeSlotIdentifier;

    const appointmentInput = {
      appointment: !isAppointmentDefine
        ? null
        : {
            desiredDate: value.desiredDate!,
            selectedTimeSlotId: value.selectedTimeSlotIdentifier as TimeSlot
          }
    };
    await setAppointmentCommand(appointmentInput);
  }

  async function saveUserInformation(value: FormData): Promise<any> {
    if (!preferences) return;
    // For Authentification mode, and if form is display, before register the appointment we need to set customer information
    if (
      DisplayedBlock.displayUserInformationForm &&
      value.email &&
      value.firstName &&
      value.lastName
    ) {
      // Specific case: make non sense
      // Client dont want to allow user to update the firstname and lastname is already defined
      // Due to the proximity of delivery, no functional discussion seems possible (seen with the BA's project)
      if(!preferences.firstName && !preferences.lastName) {
        // Only save entered name if is not defined
        preferences.firstName = value.firstName;
        preferences.lastName = value.lastName;
      }
      preferences.email = value.email;
    }
    return setAccountPreferenceCommand(preferences.id, {
      ...preferences,
      phoneNumber: value.phoneNumber || null,
      location:
        value.zipCode?.zipCode.toString() && value.zipCode?.label
          ? { postalCode: value.zipCode?.zipCode.toString(), locality: value.zipCode?.label }
          : null
    });
  }
  function back() {
    navigate(-1);
  }

  const [privacyLink, setPrivacyLink] = useState<string>('');

  async function getLink() {
    const urls = await externalUrls();
    setPrivacyLink(urls.PrivacyPage);
  }

  const t = i18n.appointmentPage;

  return (
    <>
      <Container className={styles.Container}>
        <PageTitle>{t.title}</PageTitle>
        {DisplayedBlock.displayUserInformationForm && (
          <>
            <UserInformationFormComponent
              control={control}
              formConfig={formConfig}
              errors={errors}
            />
          </>
        )}
        <InputGroup className={styles.InputGroup}>
          <label className={styles.Label}>{t.phoneNumber.label}</label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={formConfig.phoneNumber.rules}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                international
                className={styles.PhoneInput}
                flags={flags}
                aria-label="Numéro de téléphone"
                defaultCountry="FR"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ValidationMessages
            messages={getValidationMessages(
              'phoneNumber',
              errors,
              formConfig.phoneNumber.errorMessages
            )}
          />
        </InputGroup>
        {DisplayedBlock.displayElement && (
          <>
            <InputGroup className={classNames(styles.InputGroup, authenticationSSO && styles.InputGroupReordered)}>
              <label className={styles.Label} htmlFor="desiredDate">
                {t.preferenceLabel}
              </label>
              <select
                className={styles.Select}
                aria-labelledby="desiredDate"
                id="desiredDate"
                {...register('desiredDate', formConfig.desiredDate.errorMessages)}
              >
                <option></option>
                {dates.map((d) => (
                  <option value={d} key={d}>
                    {dateLong(new Date(d))}
                  </option>
                ))}
              </select>
              <ValidationMessages
                messages={getValidationMessages(
                  'desiredDate',
                  errors,
                  formConfig.desiredDate.errorMessages
                )}
              />

              <select
                className={styles.Select}
                aria-label="desiredDate"
                {...register(
                  'selectedTimeSlotIdentifier',
                  formConfig.desiredTimeSlot.errorMessages
                )}
              >
                <option></option>
                {timeSlots.map((ts) => (
                  <option value={ts} key={ts}>
                    {globali18n.labels.timeSlots[ts]}
                  </option>
                ))}
              </select>
              <ValidationMessages
                messages={getValidationMessages(
                  'desiredTimeSlot',
                  errors,
                  formConfig.desiredTimeSlot.errorMessages
                )}
              />
            </InputGroup>
          </>
        )}
        <InputGroup className={styles.InputGroup}>
          <label className={styles.Label} htmlFor="zipCode">
            {t.zipCode.label}
          </label>
          <Controller
            control={control}
            name="zipCode"
            rules={formConfig.zipCode.rules}
            render={({ field: { onChange, value } }) => (
              <div className={styles.Combobox}>
                <ZipCodesCombobox
                  value={value}
                  zipCodeChange={onChange}
                  aria-label="Code postal"
                  name="zipCode"
                />
              </div>
            )}
          />
          <ValidationMessages
            messages={getValidationMessages('zipCode', errors, formConfig.zipCode.errorMessages)}
          />
        </InputGroup>
        <div className={classNames(styles.Disclaimer , authenticationSSO && styles.InputGroupReordered)}>{t.disclaimer(privacyLink)}</div>
        <div className={classNames(styles.ButtonGroup, authenticationSSO && styles.InputGroupReordered)}>
          <Button className={classNames(ButtonStyles.Unfill, styles.Button)} onClick={back}>
            {globali18n.labels.back}
          </Button>
          <Button
            className={classNames(ButtonStyles.PrimaryAction, styles.Button)}
            onClick={handleSubmit(validate)}
          >
            {globali18n.labels.validate}
          </Button>
        </div>
      </Container>
      <BackgroundImage />
    </>
  );
}
