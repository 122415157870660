import { ChosenScenario } from '../../shared/models/ChosenScenario';
import { Gender } from './models/Gender';
import { ProfessionalStatus } from './models/ProfessionalStatus';
import { SalaryEvolution } from './models/salary/SalaryEvolution';

export const i18n = {
  module: 'Votre retraite légale',

  categories: {
    synthesis: 'Synthèse'
  },
  status: {
    Salarie: 'Salariés du privé',
    Artisan: 'Artisans',
    Commercant: 'Commerçants',
    CIPAV: 'CIPAV',
    Fonctionnaire: 'Fonctionnaires',
    CARPIMKO: 'CARPIMKO',
    MSANonSalarie: 'MSA - Non salariés agricoles',
    Other: 'Autres caisses de retraite'
  },

  genderPage: {
    pageTitle: 'Homme/Femme',
    question: 'Vous êtes',
    answer1: 'Un homme',
    answer2: 'Une femme'
  },

  gender: {
    [Gender.Male]: "Un homme",
    [Gender.Female]: "Une femme"
  },

  birthdatePage: {
    pageTitle: 'Date de naissance',
    question: 'Quelle est votre date de naissance\u00A0?',
    subtitle: "Nous avons besoin de cette information pour estimer le nombre d'années restant jusqu'à votre retraite.\u00A0Nous appliquerons ensuite l'inflation sur cet horizon.",
    errors: {
      ageBetween: 'Vous devez avoir entre 18 et 67 ans pour pouvoir effectuer cette simulation.',
      validDate: 'Veuillez saisir une date valide.'
    }
  },
  childrenPage: {
    pageTitle: 'Enfants',
    question: 'Combien d’enfants avez-vous\u00A0?',
    errors: {
      max: 'Vous devez avoir un maximum de 20 enfants pour pouvoir effectuer cette simulation.'
    }
  },
  careerStartPage: {
    pageTitle: 'Début de carrière',
    question:
      'À quelle date avez-vous commencé à travailler\u00A0? (hors emplois saisonniers)',
    errors: {
      ageBetweenSince:
        'Vous devez avoir débuté votre activité à un âge entre 16 et 67 ans pour pouvoir effectuer cette simulation.',
      max: "La valeur doit être inférieure ou égale à la date d'aujourd'hui."
    }
  },
  professionalStatusPage: {
    pageTitle: 'Statut',
    question: "Quelle est votre caisse de retraite d'affiliation\u00A0?",
    otherStatusModal: {
      title: 'Vous ne trouvez pas votre caisse d’affiliation pour la retraite\u00A0?',
      content: (
        <>
          <p>
            Rassurez-vous&nbsp;! Votre estimation peut tout de même se poursuivre. Dans ce cas,
            notre simulation se base sur le statut «&nbsp;salarié&nbsp;» afin de vous donner une
            approximation du montant.
          </p>         
        </>
      )
    }
  },
  currentRevenuePage: {
    pageTitle: 'Revenu actuel',
    question: 'Actuellement, quel est votre revenu net (avant impôts)\u00A0?',
    errors: {
      max:
        'Le salaire maximal pour cette simulation est de 30\u202F000 € mensuellement, ou 360\u202F000 € annuellement.'
    },
    civilServant: 'Vous êtes fonctionnaire ?',
    tellMeMore: 'En savoir plus',
    civilServantModal: {
      title: 'Fonctionnaire',
      content: (
        <>
          <p>
            Si vous êtes fonctionnaire, indiquez le «&nbsp;traitement indiciaire&nbsp;» de votre
            rémunération.
          </p>
          <p>
            Ce montant correspond à la somme que vous touchez à la fin du mois, hors primes et
            indemnités.
          </p>
        </>
      )
    }
  },
  salaryEvolution: {
    [SalaryEvolution.Small]: 'Stable (0% par an)',
    [SalaryEvolution.Medium]: 'Moyenne (+1,5% par an)',
    [SalaryEvolution.Large]: 'Forte (+3% par an)'
  },
  currentProgressionPage: {
    pageTitle: 'Progression actuelle',
    question:
      'Quelle a été la progression de vos revenus annuels depuis que vous avez commencé à travailler\u00A0?'
  },
  futureProgressionPage: {
    pageTitle: 'Progression future',
    question: 'Quelle évolution de vos revenus annuels envisagez-vous dans le futur\u00A0?'
  },
  summaryPage: {
    pageTitle: 'Retraite légale',
    cardTitle: {
      [ChosenScenario.LegalAge]: 'Âge légal du départ à la retraite',
      [ChosenScenario.FullRateAge]: 'Âge de votre taux plein',
      [ChosenScenario.MaxAge]: 'Âge du taux plein automatique'
    },
    title: 'Sélectionnez votre âge de départ',
    subTitle: 'Estimation de votre retraite légale en montant net mensuel',
    naturalText: {
      gender: { [Gender.Male]: 'un homme', [Gender.Female]: 'une femme' },
      born: { [Gender.Male]: 'né', [Gender.Female]: 'née' },
      affiliated: { [Gender.Male]: 'affilié', [Gender.Female]: 'affiliée' },
      salaryEvolution: {
        [SalaryEvolution.Small]: 'stable',
        [SalaryEvolution.Medium]: 'moyenne',
        [SalaryEvolution.Large]: 'forte'
      },
      change: {
        same: 'restera',
        willBe: 'sera'
      },
      status: {
        [ProfessionalStatus.Salarie]: 'la Caisse des salariés du privé',
        [ProfessionalStatus.Artisan]: 'la Caisse des artisans',
        [ProfessionalStatus.Commercant]: 'la Caisse des commerçants',
        [ProfessionalStatus.CIPAV]: 'la CIPAV',
        [ProfessionalStatus.Fonctionnaire]: 'la Caisse des fonctionnaires',
        [ProfessionalStatus.CARPIMKO]: 'la CARPIMKO',
        [ProfessionalStatus.MSANonSalarie]: 'la MSA',
        [ProfessionalStatus.Other]: 'une autre Caisse de retraite'
      }
    },
    summaryCard: {
      estimate: 'Estimation de votre retraite',
      lastSalary: 'Dernier revenu estimé avant la retraite',
      salaryLoss: 'Perte de revenu associée',
      thatIs: 'soit',
      selected: 'Votre sélection'
    },
    disclaimer:
      'Les montants de rente mensuelle sont indiqués nets de prélèvements sociaux et avant impôt sur le revenu. Simulation simplifiée et non engageante.',
    knowMore: 'En savoir plus sur notre estimation',

    knowMoreModal: {
      title: 'En savoir plus sur notre estimation',
      content: (
        <>
          <p>
            L'objectif du calcul de votre retraite légale est de vous fournir une évaluation rapide
            et simplifiée du montant de votre retraite obligatoire. Il s'agit de vous apporter une
            première vision simple et non exhaustive du montant potentiel de votre future retraite.
          </p>
          <p>
            Le nombre d'informations demandées a été limité et les résultats obtenus ne prennent pas
            en compte certains cas complexes (progression de carrière à revenus non linéaires par
            exemple).
          </p>
          <p>Ces résultats n'ont pas de valeur engageante. Cette simulation n'est pas opposable aux caisses de retraite.</p>
        </>
      )
    }
  },
  landingPage: {
    pageTitle: 'Retraite légale',
    title: 'Vous allez tout savoir sur votre retraite\u00A0!',
    content: (
      <div>
        Calculons ensemble :<br />
        <ul>
          <li>Votre future pension</li>
          <li>Votre âge de départ à la retraite</li>
          <li>L’écart avec votre dernier revenu estimé</li>
        </ul>
      </div>
    )
  }
};
