import { createEnumPage } from '../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../commands/answerCommands';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../i18n';
import { SalaryEvolution } from '../../models/salary/SalaryEvolution';

export const FutureProgressionPage = createEnumPage<SalaryEvolution>({
  selector: (s) => s.legalPension.answers.salary.evolutionAfterSimulation,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerEvolutionAfterSimulation,
  values: Object.values(SalaryEvolution).map((evo) => ({
    value: evo,
    label: i18n.salaryEvolution[evo]
  })),
  question: i18n.futureProgressionPage.question,
  backgroundImage: BackgroundImage
});
