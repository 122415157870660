import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { OtherType } from '../../../models/other/other-type.enum';
import { setOtherHobbiesDefinedValue, setOtherHobbiesScaleValue } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const OtherHobbiesPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) => q.other.find((c) => c.typeId === OtherType.Hobbies)?.scale ?? null,
  definedValueSelector: (q) =>
    q.other.find((c) => c.typeId === OtherType.Hobbies)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.otherHobbiesPage,
  scaleValueReducer: setOtherHobbiesScaleValue,
  definedValueReducer: setOtherHobbiesDefinedValue,
  answers: [
    { icon: moneyIcon.Money1, label: i18n.otherHobbiesPage.answer1, value: DefaultScale.NotAtAll },
    { icon: moneyIcon.Money2, label: i18n.otherHobbiesPage.answer2, value: DefaultScale.ALittle },
    { icon: moneyIcon.Money3, label: i18n.otherHobbiesPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: () => ({ route: '../health/medical' })
});
