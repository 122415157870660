import { i18n } from '../../../i18n';
import { housingIcon } from '../../../images';
import { QuestionnaireModel } from '../../../models/computation/QuestionnaireModel';
import { HousingTransactionType } from '../../../models/housing/housing-transaction-type.enum';
import { setHousingTransactionType } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

interface HousingTransactionInfo {
  transactionType: HousingTransactionType;
  hasCredit: boolean;
}

function housingTransactionTypeAccessor(
  questionnaire: QuestionnaireModel
): HousingTransactionInfo | null {
  if (
    questionnaire.housing == null ||
    questionnaire.housing.questionnaire?.hasMortgage == null ||
    questionnaire.housing.questionnaire?.transactionType == null
  ) {
    return null;
  }

  return {
    transactionType: questionnaire.housing.questionnaire.transactionType,
    hasCredit: questionnaire.housing.questionnaire.hasMortgage
  };
}

export const HousingTransactionTypePage = createPensionNeedPage<HousingTransactionInfo>({
  scaleValueSelector: (q) => housingTransactionTypeAccessor(q),
  i18nFn: (i18n) => i18n.housingTransactionTypePage,
  scaleValueReducer: setHousingTransactionType,
  answers: [
    {
      icon: housingIcon.House,
      label: i18n.housingTransactionTypePage.answer1,
      value: { transactionType: HousingTransactionType.Purchase, hasCredit: false }
    },
    {
      icon: housingIcon.Credit,
      label: i18n.housingTransactionTypePage.answer2,
      value: { transactionType: HousingTransactionType.Purchase, hasCredit: true }
    },
    {
      icon: housingIcon.Key,
      label: i18n.housingTransactionTypePage.answer3,
      value: { transactionType: HousingTransactionType.Rental, hasCredit: false }
    }
  ],
  nextPage: () => ({ route: '../housing/type' })
});
