import styles from './ModuleStyles.module.scss';

export const ModuleStyles = {
  Grid: styles.Grid,
  FormGrid: styles.FormGrid,
  FormGridResponsive: styles.FormGridResponsive,
  FormGridCentered: styles.FormGridCentered,
  Subtitle: styles.Subtitle,
  Mailbox: styles.Mailbox,
  Divider: styles.Divider,
  FormGridCenteredResponsive: styles.FormGridCenteredResponsive
};
