import styles from "./BackgroundImage.module.scss";
import { assets as Assets } from "../../Assets";
import classNames from "classnames";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";

export function BackgroundImage(props: {isSummaryPage?: boolean}) {
  return (
    <div className={classNames(styles.Padding, {[styles.SummaryPadding]: props.isSummaryPage})}>
      <SvgWrapperComponent ariaHidden={true}><Assets.BackgroundImage className={styles.Image} /></SvgWrapperComponent>
    </div>
  );
}
