import { useLoader } from '../../../shared';
import { useAppDispatch } from 'store';
import {  RealEstateType, editRealEstate } from '../patrimonySlice';
import { GetRealEstateResponseModel } from '../models/RealEstateModel';
import { updateRealEstateRequest } from '../requests/updateRealEstateRequest';
import { useParams } from 'react-router-dom';

export function useEditRealEstateCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();
  
  async function editRealEstateCommand(
    type: RealEstateType,
    position: number,
    realEstate: GetRealEstateResponseModel
  ) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update RealState list localy
      await updateRealEstateRequest(realEstate.id, realEstate, customerid);
      dispatch(editRealEstate({ type, position, realEstate }));
    }, {key: "useEditRealEstateCommand", value:`Delay before dispatch editRealEstateCommand on editRealEstate.ts file`});
  }

  return { editRealEstateCommand };
}
