import axios from "axios";
import { Pagination } from "../../../shared/models/Pagination";
import { apiUrls } from "../../../shared/apiUrls";

interface ProductsPaginationResponse {
  pagination: Pagination;
  results: ProductInterface[];
}

export interface ProductInterface {
  productIdentifier: string;
  name: string;
  productTypeIdentifier: string;
  urls: urlsInterface[];
}

interface urlsInterface {
  type: FileTypeEnum;
  path: string;
}

export enum FileTypeEnum {
    Subscription= "Subscription",
    Documentation = "Documentation",
    Fees = "Fees"
  }

export async function getProducts(
  customerid?: string
): Promise<ProductInterface[]> {
  const apiUrl = apiUrls(customerid).products;
  const response = await axios.get<ProductsPaginationResponse>(apiUrl);
  return response.data!.results;
}
