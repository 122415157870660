export function scrollTopCommand() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  });
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 150);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 300);
}
