import { i18n } from '../../../i18n';
import { housingIcon } from '../../../images';
import { HousingLocationType } from '../../../models/housing/housing-location-type.enum';
import { setHousingDefinedValue, setHousingLocationType } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const HousingLocationTypePage = createPensionNeedPage<HousingLocationType>({
  scaleValueSelector: (q) => q.housing.questionnaire?.locationType ?? null,
  definedValueSelector: (q) => q.housing.definedValue ?? null,
  i18nFn: (i18n) => i18n.housingLocationTypePage,
  scaleValueReducer: setHousingLocationType,
  definedValueReducer: setHousingDefinedValue,
  answers: [
    {
      icon: housingIcon.House,
      label: i18n.housingLocationTypePage.answer1,
      value: HousingLocationType.CountrySide
    },
    {
      icon: housingIcon.Building,
      label: i18n.housingLocationTypePage.answer2,
      value: HousingLocationType.City
    },
    {
      icon: housingIcon.Mountain,
      label: i18n.housingLocationTypePage.answer3,
      value: HousingLocationType.Mountain
    },
    {
      icon: housingIcon.Ship,
      label: i18n.housingLocationTypePage.answer4,
      value: HousingLocationType.SeaSide
    }
  ],
  nextPage: (scaleValue, definedValue) => {
    if (definedValue != null) {
      return ({  route: '../transport/types' });
    }

    if (scaleValue === HousingLocationType.City) {
      return { route: '../housing/city-size' };
    }

    return { route: '../housing/transaction-type' };
  }
});
