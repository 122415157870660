import classNames from "classnames";
import { AppointmentModel } from "../../../../modules/appointment/models/AppointmentModel";
import {
  ButtonStyles,
  Button,
  useModal,
  ModalOutcome,
} from "../../../../shared";
import { DownloadIcon } from "../../../../shared/images";
import { Module } from "../../../../shared/models/Module";
import { dateLong } from "../../../../shared/utilities/intl";
import { internalUrls, useAppUrls } from "../../../../shared/utilities/urls";
import { i18n } from "../../i18n";
import styles from "./Future.module.scss";
import { condition } from "../../Condition";
import { ChartData } from "../../../../modules/dashboard/utilities/dashboard";
import { useRenderExpiredInvestorProfileModal } from "../../../../modules/dashboard/commands/renderExpiredInvestorProfileModal";
import { useAppStore } from "../../../../store";
import {
  SaveYourInfoModal,
  SaveYourInfoResult,
} from "../SaveYourInfoModal/SaveYourInfoModal";
import { UserType } from "../../../../shared/requests/getUserReferenceRequest";
import { useNavigate } from "react-router-dom";
import { DashboardModel } from "../../../../modules/dashboard/models/DashboardModel";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { useFutureCardHelperService } from "./services/FutureCardHelper.service";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";

interface FutureCardProps {
  remainingModules: Module[];
  recommendationAllowed: boolean;
  appointment: AppointmentModel | null;
  isReportAvailable?: boolean;
  investorProfileExpired: boolean;
  onGoToNext: () => void;
  onGoToRecommendation: () => void;
  chartData: ChartData;
  objectiveAmount: number;
  model: DashboardModel;
}

const t = i18n.dashboardPage.project;

export function FutureCard(props: FutureCardProps) { 
  const appUrl = useAppUrls();
  const { showModal } = useModal();
  const { continueJourneyEvent } = useAnalytics();
  const { chooseTexts } = useFutureCardHelperService();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const store = useAppStore();
  const navigate = useNavigate();
  const { renderExpiredInvestorProfileModal } =
    useRenderExpiredInvestorProfileModal();

  const hasAppointment =
    props.appointment != null && condition.displayMeetingElement;
  const hasNoRemainingModules = props.remainingModules.length === 0;

  const displayDeficitSurplus =
    hasNoRemainingModules ||
    props.remainingModules[0] === Module.InvestorProfile;

  const { text, button, adversingNote } = chooseTexts(
    props.chartData,
    hasNoRemainingModules,
    hasAppointment,
    props.investorProfileExpired,
    props.recommendationAllowed,
    props.remainingModules,
    isTenantRecommendationModeStatic()
  );

  const userType = store.getState().authentication.userType;
  const isRecommendationModeStatic = isTenantRecommendationModeStatic();

  function isRecommendationButtonAvailable() : boolean {
    // For Recommendation Static (== tenant BCEF), the recommendation button is available, if all the mandatories modules are completed
    // For Recommendation Personalized (== tenant Internaute), the recommendation button is available, if backend dashboard model return isReportAvailable at true
    // toDo check with backend if the isReportAvailable value could be conditionnal to the recommendation mode
    return isRecommendationModeStatic ? props.recommendationAllowed : !!props.isReportAvailable;
  }

  async function displayNonLoggedModal() {
    const result = await showModal<SaveYourInfoResult>(<SaveYourInfoModal />);

    if (result.outcome === ModalOutcome.Cancel) {
      return;
    }

    if (result.value === SaveYourInfoResult.Register) {
      navigate(internalUrls.registration.registration);
    } else {
      return;
    }
  }

  async function click() {
    continueJourneyEvent();
    //If profiling never completed continue the journey & the user is connected
    if (
      props.model.profiling === null &&
      userType === UserType.Prospect &&
      props.remainingModules[0] === Module.InvestorProfile
    ) {
      return displayNonLoggedModal();
    }

    if(!isRecommendationModeStatic) {
      // We dont need to fill the profiling module for recommendation static mode
      // So this condition only apply on Recommendation Personalized (Tenant Internaute)
      if (props.model.profiling === null && userType !== UserType.Prospect) {
        return props.onGoToNext();
      }
    }

    //If Can see the Recommentation & the profiling is not expired
    if (props.recommendationAllowed && !props.investorProfileExpired) {
      return props.onGoToRecommendation();
    }

    //If the profiling is expired
    if (props.investorProfileExpired) {
      return await renderExpiredInvestorProfileModal();
    }

    //If the user is not connected
    if (userType === UserType.Prospect && hasNoRemainingModules) {
      return displayNonLoggedModal();
    }

    // Otherwise we continue the journey
    return props.onGoToNext();
  }

  function goToReport() {
    window.location.href = appUrl.reportUrl;
  }

  function buildTimeSlotString() {
    const { startTime, endTime } = props.appointment!.desiredTimeSlot;
    return `${startTime.split(":")[0]}h - ${endTime.split(":")[0]}h`;
  }

  return (
    <div className={styles.Root}>
      {displayDeficitSurplus && props.chartData.hasSurplus && (
        <div className={styles.Deficit}>
          {t.surplusDeficit.surplus(props.objectiveAmount)}
        </div>
      )}
      {displayDeficitSurplus && props.chartData.hasDeficit && (
        <div className={styles.Deficit}>
          {t.surplusDeficit.deficit(props.objectiveAmount)}
        </div>
      )}{" "}
      {props.appointment && condition.displayMeetingElement && (
        <div className={classNames(styles.Appointment, isRecommendationModeStatic && styles.AppointmentDashboardStatic)}>
          <div className={styles.AppointmentTitle}>
            {hasAppointment && i18n.dashboardPage.future.texts.hasAppointment}
          </div>
          <div className={styles.AppointmentText}>
            {i18n.dashboardPage.future.nextAppointment}
          </div>
          <div className={styles.AppointmentValueGroup}>
            <div className={styles.AppointmentValue}>
              {dateLong(new Date(props.appointment.desiredDate))}
            </div>
            <div className={styles.AppointmentValue}>
              {props.appointment &&
                props.appointment!.desiredTimeSlot &&
                buildTimeSlotString()}
            </div>
          </div>
        </div>
      )}
      <div className={classNames(styles.Progression, isRecommendationModeStatic && styles.ProgressionDashboardStatic)}>{!hasAppointment && text}</div>
      <div className={styles.RecommendationAndAppointment}>
        <Button
          className={classNames(
            ButtonStyles.PrimaryAction,
            styles.RecommendationButton
          )}
          onClick={click}
        >
          {button}
          {adversingNote && (<p className={styles.FutureButtonAdvertisingNote}>{adversingNote}</p>)}
        </Button>
        {isRecommendationButtonAvailable() && (
          <Button
            onClick={goToReport}
            className={classNames(
              isRecommendationModeStatic? ButtonStyles.Unfill: ButtonStyles.Link,
              styles.DownloadLink
            )}
          >
            <DownloadIcon className={styles.DowloadIcon} />{" "}
            {i18n.dashboardPage.future.download}
          </Button>
        )}
      </div>
    </div>
  );
}
