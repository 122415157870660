import { useNavigate, useParams } from "react-router";
import { useLoader } from "../../../shared";
import { internalUrls } from "../../../shared/utilities/urls";

export function useGoToAppointmentCommand() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();
  
  async function goToAppointmentCommand() {
    await showLoaderWhile(async () => {
        await navigate(internalUrls.appointment);
    }, {key: "useGoToAppointmentCommand", value:`Handle navigation to appointment page on file goToAppointmentCommand.ts`});
  }

  return { goToAppointmentCommand };
}
