import styles from "./BackgroundImage.module.scss";
import { assets as Assets } from "../../../../Assets";
import classNames, { Argument } from "classnames";
import { SvgWrapperComponent } from "../../../../../../shared/components/SvgWrapper/SvgWrapperComponent";

export function BackgroundImage(props: {className?: Argument}) {
  return (
    <div className={classNames(styles.Image, styles.ImageContainer, props.className)}>
      <SvgWrapperComponent ariaHidden={true}><Assets.BackgroundImage className={styles.Image} /></SvgWrapperComponent>
    </div>
  );
}
