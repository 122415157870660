import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { TransportType } from '../../../models/transport/transport-type.enum';
import {
  setTransportBikeDefinedValue,
  setTransportBikeScaleValue
} from '../../../pensionNeedSlice';
import { nextTransportAction } from '../../../routing/nextTransportRoute';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const TransportBikePage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) => q.transport.find((c) => c.typeId === TransportType.Bike)?.scale ?? null,
  definedValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.Bike)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.transportBikePage,
  scaleValueReducer: setTransportBikeScaleValue,
  definedValueReducer: setTransportBikeDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.transportBikePage.answer1,
      value: DefaultScale.NotAtAll
    },
    {
      icon: moneyIcon.Money2,
      label: i18n.transportBikePage.answer2,
      value: DefaultScale.ALittle
    },
    { icon: moneyIcon.Money3, label: i18n.transportBikePage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: (_, __, questionnaire) =>
    nextTransportAction(TransportType.Bike, questionnaire.transportTypes!)
});
