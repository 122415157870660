import classNames from "classnames";
import { FunctionComponent, SVGProps } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { globali18n } from "../../../../../i18n";
import {
  ButtonStyles,
  Container,
  ValidationMessages,
  Button
} from "../../../../../shared";
import { getValidationMessages } from "../../../../../shared/utilities/getValidationMessages";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { AnswerToggle } from "../../../components/AnswerToggle/AnswerToggle";
import { i18n } from "../../../i18n";
import { transportIcon } from "../../../images";
import {
  TransportType,
  transportTypes,
} from "../../../models/transport/transport-type.enum";
import { setTransportTypes } from "../../../pensionNeedSlice";
import { nextTransportAction } from "../../../routing/nextTransportRoute";
import { Styles } from "../../../styles/Styles";
import styles from "./TransportTypesPage.module.scss";
import { assets as Assets } from '../../../Assets'
import { SvgWrapperComponent } from "../../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { useAnalytics } from "../../../../../shared/commands/useAnalytics";

interface TransportTypeOption {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  type: TransportType;
}

export function TransportTypesPage() {
  const selected = useAppSelector((s) => s.pensionNeed.transportTypes!);
  const defaultValues = Object.fromEntries(
    transportTypes.map((tt) => [tt, selected.includes(tt)])
  );

  const { control, handleSubmit, watch, formState } = useForm({
    criteriaMode: "all",
    defaultValues: defaultValues,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { answerNeedsEvent } = useAnalytics();

  function onSubmit(data: typeof defaultValues) {
    // Keep only the keys of the checked transport types
    const selected = Object.entries(data)
      .filter(([k, v]) => v)
      .map(([k, v]) => k as TransportType);

    dispatch(setTransportTypes(selected));
    const toEvent = selected.map((e) => i18n.transportType[e]);
    answerNeedsEvent(toEvent.toString().replace(',', " | "), i18n.transportTypesPage.question);

    const nextAction = nextTransportAction(null, selected);
    navigate(nextAction.route!);
  }

  function atLeastOne(): boolean {
    const allValues = watch();
    const result = Object.values(allValues).some((tt) => tt);

    return result;
  }

  const rules = {
    validate: { atLeastOne: () => atLeastOne() },
  };

  const options: TransportTypeOption[] = [
    { icon: transportIcon.Car, type: TransportType.Car },
    {
      icon: transportIcon.PublicTransportation,
      type: TransportType.PublicTransportation,
    },
    { icon: transportIcon.Motorbike, type: TransportType.Motorbike },
    { icon: transportIcon.Bike, type: TransportType.Bike },
  ];

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Container
          className={classNames(Styles.Background, styles.Container)}
        >
          <div className={styles.QuestionTitle}>
            {i18n.transportTypesPage.question}
          </div>
          <div className={styles.Description}>
            {i18n.transportTypesPage.description}
          </div>
          <div className={styles.AnswerGrid}>
            {options.map((o, i) => {
              const Icon = o.icon;
              return (
                <Controller
                  key={o.type}
                  control={control}
                  name={o.type}
                  rules={rules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <AnswerToggle
                      childrenClassName={styles.AnswerToggleChildren}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    >
                      <SvgWrapperComponent ariaHidden={true}>
                        <Icon />
                      </SvgWrapperComponent>
                      {i18n.transportType[o.type]}
                    </AnswerToggle>
                  )}
                />
              );
            })}
            <ValidationMessages
              messages={getValidationMessages(
                TransportType.Car,
                formState.errors,
                {
                  atLeastOne: i18n.transportTypesPage.atLeastOne,
                }
              )}
            />
            <Button
              className={classNames(
                ButtonStyles.PrimaryAction,
                styles.ValidateButton
              )}
            >
              {globali18n.labels.validate}
            </Button>
          </div>
        </Container>
      </form>
      <div className={styles.Padding}>
      <Assets.BackgroundImage className={styles.Image}/>
      </div>
    </>
  );
}
