export enum ModalOutcome {
  Ok,
  Cancel,
  Other
}

export interface ModalResult<T> {
  outcome: ModalOutcome;
  value: T | null;
}
