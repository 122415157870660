import classNames, { Argument } from "classnames";
import { PropsWithChildren } from "react";
import { useTheme } from "../Theme/ThemeContext";
import styles from "./QuestionTitle.module.scss";

interface QuestionTitleProps {
  className?: Argument;
}

export function QuestionTitle(props: PropsWithChildren<QuestionTitleProps>) {
  const { backgroundColor } = useTheme();

  return (
    <div
      className={classNames(styles.Question, props.className)}
      style={{ backgroundColor }}
    >
      {props.children}
    </div>
  );
}
