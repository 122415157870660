import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  ButtonStyles,
  ModalOutcome,
  StyledKendoModal,
  SvgWrapperComponent,
  useModal,
} from "../../../../shared";
import { i18n } from "../../i18n";
import styles from "./ProductSheetModal.module.scss";
import classNames from "classnames";
import { assets as Assets } from "../../Assets";
import { CloseIcon } from "../../../../shared/images";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { useGoToAppointmentCommand } from "../../../../modules/recommendation/commands/goToAppointmentCommand";
import { FileTypeEnum } from "../../../../modules/recommendation/requests/getProducts";
import { ProductTypeIdentifier } from "../../../../modules/recommendation/models/product-type-identifier.enum";
import { useAppSelector } from "store";
import { UserType } from "../../../../shared/requests/getUserReferenceRequest";

type Props = {
  productTypeIdentifier: ProductTypeIdentifier;
  productTypeInfo: ProductTypeInfo;
  hideCommercialContent: boolean;
};

export type ProductTypeInfo = {
  isProductTypeExclude: boolean;
  name: string;
  files: { type: FileTypeEnum; url: string }[];
  link?: string | null; // link to second cta need for Immobilier case
};

export function ProductSheetModal(props: Props) {
  const { hideModal } = useModal();
  const { makeAppointment, productPageEvent } = useAnalytics();
  const { goToAppointmentCommand } = useGoToAppointmentCommand();
  const userType = useAppSelector((s) => s.authentication.userType);

  const [productInformation, setProductInformation] =
    useState<ProductInformation>();
  const productSheeti18n = i18n.productSheetModal;
  const hideCommercialContent = props.hideCommercialContent;

  useEffect(() => {
    // Creation of ProductInfo object
    const i18nProduct =
      i18n.productSheetModal.product[props.productTypeIdentifier];
    const extraButtonWording = i18nProduct.button;
    const extraImageURl = "";
    const externalLink = props.productTypeInfo.link;

    setProductInformation({
      title: i18nProduct.title,
      subtitle: props.productTypeInfo.name,
      advertisingCommunication: i18nProduct.advertisingCommunication,
      description: i18nProduct.description,
      disclaimer: i18nProduct.disclaimerDescription,
      picturesSource: i18nProduct.pictures,
      extraButtonWording,
      extraImageURl,
      externalLink,
    });
  }, [props.productTypeIdentifier]);

  function canMakeAnAppointment(): boolean {
    return userType !== UserType.Advisor;
  }

  function getFileLink(fileType: FileTypeEnum): string {
    return (
      props.productTypeInfo.files.find((file) => file.type === fileType)?.url ||
      ""
    );
  }

  async function goToAppointment() {
    hideModal(ModalOutcome.Cancel, null);
    makeAppointment();
    await goToAppointmentCommand();
  }

  async function goToExternalLink(externalLink: string) {
    window.open(externalLink);
  }

  return (
    <>
      <StyledKendoModal
        title=""
        width="1009px"
        height="90vh"
        contentStyle="padding: 30px; overflow-x:hidden;"
        className={styles.Dialog}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
      >
        <div className={styles.Container}>
          <button
            className={styles.CloseIcon}
            onClick={() => hideModal(ModalOutcome.Cancel, null)}
          >
            <SvgWrapperComponent
              ariaHidden={false}
              ariaLabel={"Modal close icon"}
            >
              <CloseIcon />
            </SvgWrapperComponent>
          </button>
          <div className={styles.ProductSheetContainer}>
            <div className={styles.Header}>
              <div className={styles.HeaderTitle}>
                <div className={styles.TitleContainer}>
                  <p className={styles.Title}> {productInformation?.title}</p>
                  {!hideCommercialContent && (
                    <p className={styles.Subtitle}>
                      {" "}
                      {productInformation?.subtitle}{" "}
                    </p>
                  )}
                </div>
                {!hideCommercialContent && (
                  <div className={styles.LinksContainer}>
                    <a
                      href={getFileLink(FileTypeEnum.Fees)}
                      className={styles.ExternalLink}
                      onClick={() => productPageEvent("Frais")}
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      {productSheeti18n.common.links.cost}
                    </a>
                    <a
                      href={getFileLink(FileTypeEnum.Documentation)}
                      onClick={() => productPageEvent("Documentation")}
                      className={styles.ExternalLink}
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      {productSheeti18n.common.links.document}
                    </a>
                  </div>
                )}
              </div>
              <div className={styles.HeaderCTA}>
                {canMakeAnAppointment() && (
                  <Button
                    className={classNames(
                      ButtonStyles.PrimaryAction,
                      styles.MakeAnAppointment
                    )}
                    onClick={() => {
                      productPageEvent(
                        productSheeti18n.common.ctas.makeAnAppointment
                      );
                      goToAppointment();
                    }}
                  >
                    {productSheeti18n.common.ctas.makeAnAppointment}
                  </Button>
                )}
                {productInformation?.extraButtonWording && (
                  <Button
                    disabled={props.productTypeInfo.isProductTypeExclude}
                    className={classNames(
                      ButtonStyles.SecondaryAction,
                      styles.ExtraButton
                    )}
                    onClick={() => {
                      productPageEvent(productInformation!.extraButtonWording!);
                      goToExternalLink(productInformation.externalLink || "");
                    }}
                  >
                    {productInformation?.extraButtonWording}
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.Separator}></div>
            <div className={styles.DescriptionContainer}>
              <div className={styles.AdvertisingDescription}>
                <p className={styles.Label}>
                  {productSheeti18n.common.labels.advertisingNature}
                </p>
                <p className={styles.Content}>
                  {productInformation?.advertisingCommunication}
                </p>
              </div>
              <div className={styles.ProductDescription}>
                {productInformation?.description &&
                  productInformation.description.map((desc, index) => (
                    <div
                      key={`product-desc-${index}`}
                      className={styles.ProductInformation}
                    >
                      <p className={styles.Label}> {desc.title} </p>
                      <p className={styles.Content}> {desc.content} </p>
                    </div>
                  ))}
              </div>
            </div>
            {productInformation?.disclaimer && (
              <div className={styles.Disclaimer}>
                <p className={styles.Title}>
                  {productSheeti18n.common.labels.disclaimer}
                </p>
                <div>{productInformation.disclaimer}</div>
              </div>
            )}
            {productInformation?.picturesSource && (
              <div>{productInformation.picturesSource}</div>
            )}
            {productInformation?.extraImageURl && (
              <img
                src={productInformation?.extraImageURl}
                alt="award"
                aria-hidden="true"
                className={styles.ProductImage}
              />
            )}

            <div className={styles.Footer}>
              <span className={styles.FooterContent}>
                {
                  i18n.productSheetModal.product[props.productTypeIdentifier]
                    .footer
                }
              </span>
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.ProductSheetModalFooterImage
                  className={classNames(styles.ProductSheetModalFooterImage)}
                />
              </SvgWrapperComponent>
            </div>
          </div>
        </div>
      </StyledKendoModal>
    </>
  );
}

type ProductInformation = {
  title: string;
  subtitle: string;
  advertisingCommunication: string | JSX.Element;
  description: { title: string; content: ReactElement }[];
  disclaimer?: JSX.Element;
  picturesSource?: JSX.Element;
  extraButtonWording?: string;
  extraImageURl?: string;
  externalLink?: any | null;
};
