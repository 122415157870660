import { useLoader } from '../../../shared';
import { globali18n } from '../../../i18n';
import { setAmount } from '../../../shared/layouts/ProgressLayout/progressSlice';
import { useAppDispatch, useAppStore } from '../../../store';
import { setComputationId, setGamifiedValues } from '../pensionNeedSlice';
import { computePersonaRequest } from '../requests/computePersonaRequest';
import { sumTotals } from '../utilities/computeTotal';
import { apiUrls } from '../../../shared/apiUrls';
import { EComputationType } from '../enum';
import { PensionNeedPersonaBody } from '../models/request/computationRequest.model';

export function usePersonaBackend() {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { showLoaderWhile } = useLoader();

  async function computePersonaBackendCommand(personaId: string, customerid?: string) {
    return showLoaderWhile(async () => {
      const birthDate = store.getState().legalPension.answers.birthDate;
      const payload : PensionNeedPersonaBody = {
        $type: EComputationType.Persona,
        birthDate,
        personaId,
      }
      const response = await computePersonaRequest(payload, customerid);

      // The calculation results are discarded, we trust the client-side calculations
      // But we need a computation ID to validate
      dispatch(setComputationId(response.id));
      // The computation results are set as default for the gamified values
      const total = response.result.pensionNeed;
      dispatch(setAmount({ amount: total, unit: globali18n.labels.eurosPerMonth }));
      dispatch(setGamifiedValues(response.result.categoriesDetails));
    }, {key: "usePersonaBackend", value:`API GET - ${apiUrls(customerid).pensionNeed.computations}`});
  }

  return { computePersonaBackendCommand };
}
