import { createEnumPage } from '../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../commands/answerCommands';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../i18n';
import { Gender } from '../../models/Gender';

export const GenderPage = createEnumPage<Gender>({
  selector: (s) => s.legalPension.answers.gender,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerGender,
  values: [
    {
      label: i18n.genderPage.answer1,
      value: Gender.Male
    },
    {
      label: i18n.genderPage.answer2,
      value: Gender.Female
    }
  ],
  question: i18n.genderPage.question,
  backgroundImage: BackgroundImage
});
