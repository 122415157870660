import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { ProfileScale } from "../models/ProfileScaleModel";
import { EProfileType } from "../models/ProfileTypeEnum";

export async function getRiskProfileScale(
  customerId: string | undefined,
  version: number
): Promise<ProfileScale> {
  const apiUrl = apiUrls(customerId).investorProfile.profileScale;
  const response = await axios.get<ProfileScale>(apiUrl, {
    params: { profileType: EProfileType.Risk, version: version },
  });

  const data = response.data;

  return data;
}
