import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { GetRealEstateResponseModel, RealEstateModel } from '../models/RealEstateModel';

export async function saveRealEstateRequest(payload: RealEstateModel,customerid?: string): Promise<GetRealEstateResponseModel> {
  const apiUrl = apiUrls(customerid).patrimony.postRealEstate;
  
  const response = await axios.post<GetRealEstateResponseModel>(apiUrl, payload);
  return response.data;
}
