import { RealEstateCard } from "../../../../modules/patrimony/components/RealEstateCard/RealEstateCard";
import { SavingsCard } from "../../../../modules/patrimony/components/SavingsCard/SavingsCard";
import { i18n } from "../../../../modules/patrimony/i18n";
import {
  RealEstateType,
  SavingAccountType,
  setTransferAmount,
} from "../../../../modules/patrimony/patrimonySlice";
import { ButtonStyles, Container, Button } from "../../../../shared";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { PiggyBankIcon } from "./images";
import styles from "./DeclarationPage.module.scss";
import classNames from "classnames";
import { useValidateCommand } from "../../../../modules/patrimony/commands/validate";
import { BackgroundImage } from "../../../../modules/patrimony/components/BackgroundImage/BackgroundImage";
import { useEffect } from "react";
import { amount } from "../../../../shared/utilities/intl";

export function DeclarationPage() {
  const patrimony = useAppSelector((s) => s.patrimony);
  const savingsAccountType = patrimony!.productTypes;
  const { savingsAccounts, realEstates } = patrimony.answers;
  const dispatch = useAppDispatch();

  const { validateCommand } = useValidateCommand();

  useEffect(() => {
    dispatch(setTransferAmount());
  }, []);

  const t = i18n.declarationPage;

  async function validate() {
    await validateCommand(patrimony.answers);
  }

  return (
    <>
      <Container className={styles.Container}>
        <div className={styles.TotalAmont}>
          {t.yourPatrimony} {amount(patrimony.totalAmount)}
        </div>
        <div className={styles.CardGrid}>
          <SavingsCard
            icon={PiggyBankIcon}
            title={t.financialSavingsCard.title}
            subTitle={t.financialSavingsCard.subTitle}
            className="FinancialSavingsCard"
            items={savingsAccounts}
            productTypes={savingsAccountType}
            savingAccountType={SavingAccountType.SavingsAccounts}
          />
          <RealEstateCard
            className="RealEstateCard"
            realEstateType={RealEstateType.RealEstates}
            items={realEstates}
          />
        </div>
        <Button
          className={classNames(
            ButtonStyles.PrimaryAction,
            styles.ValidateButton
          )}
          onClick={validate}
        >
          {t.continueButton}
        </Button>
      </Container>
      <div style={{ marginBottom: "35px" }}>
        <BackgroundImage />
      </div>
    </>
  );
}
