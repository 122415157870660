import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { customerid } = useParams()

  useEffect(() => {
    showLoaderWhile(async () => {
      setLoading(false);
    }, {key: "investorProfileResolver_getLastValidatedRequest", value:`API GET - `});
  }, []);

  return { loading };
}
