import { globali18n } from "@ifs/libs/src/i18n"
import { UseFormRegister } from "react-hook-form"
import { nameRegex } from "./utilities/validation"

export const sharedRules = (register:  UseFormRegister<any>) => {
    return {
        firstName: {
            field: register('firstName', { required: true, pattern: nameRegex }),
            errors: {
              required: globali18n.errors.required,
              pattern: globali18n.errors.pattern
            }
          },
          lastName: {
            field: register('lastName', { required: true, pattern: nameRegex }),
            errors: {
              required: globali18n.errors.required,
              pattern: globali18n.errors.pattern
            }
          },
    }
}