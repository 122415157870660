export function apiUrls(customerid?: string | null) {
  const customersPart = customerid ? `/customers/${customerid}` : "";

  const customerCompletionPart = customerid ? `/${customerid}` : "";
  const urls = {
    appointments: {
      post: '/api/appointments',
      available: '/api/available-appointments'
    },    
    customers: {
      get: '/api/customers',
      put: `/api/customers`,
      anonymizedCustomer: (customerId: string) => `/api/customers/${customerId}/anonymize`,
      deleteCustomerData: `/api/customer-data`,
    },
    lifeProjects: {
      get: `/api${customersPart}/life-projects`,
      put: `/api${customersPart}/life-projects`,
      simulation: `/api${customersPart}/life-projects-simulations`
    },
    patrimony: {
      synthesis: `/api${customersPart}/patrimony-simulations`,
      getSavingsAccounts: `/api${customersPart}/savings-accounts`,
      postSavingAccount: `/api${customersPart}/savings-accounts`,
      updateSavingAccount: (savingAccountId: string) => `/api${customersPart}/savings-accounts/${savingAccountId}`,
      deleteSavingAccount:(savingAccountId: string) => `/api${customersPart}/savings-accounts/${savingAccountId}`,

      getRealsEstates: `/api${customersPart}/real-estates`,
      postRealEstate: `/api${customersPart}/real-estates`,
      updateRealEstate: (realEstateId: string) => `/api${customersPart}/real-estates/${realEstateId}`,
      deleteRealEstate: (realEstateId: string) => `/api${customersPart}/real-estates/${realEstateId}`
    },
    legalPension: {
      computations: `/api/legal-pension${customersPart}/computations`,
      validations: `/api/legal-pension${customersPart}/validations`,
      validationComputation: (validationId: string) => `/api/legal-pension${customersPart}/validations/${validationId}/computation`
    },
    pensionNeed: {
      computations: `/api/pension-need${customersPart}/computations`,
      validations: `/api/pension-need${customersPart}/validations`,      
      customer: `/api/customers${customerCompletionPart}`,
    },
    completion: {      
      get: `/api/completions${customerCompletionPart}`,
      getSteps: `/api${customersPart}/steps`,
      put: (stepName: string) => `/api${customersPart}/steps/${stepName}`,
    },
    taxation: {
      putTMI: `/api${customersPart}/taxation/selected-tmi`,
      get: `/api${customersPart}/taxation`,
      put: `/api${customersPart}/taxation`,
    },
    dashboard: `/api/dashboard${customerCompletionPart}`,
    recommendation: {
      parameters: `/api/recommendation${customersPart}/gamification-parameters`,
      compute: `/api/recommendation${customersPart}/compute`,
    },
    products: "/api/products",
    productTypes: "/api/product-types",
    tenants: "/api/tenant",
    tmiConfigs: "/api/tmi-configs",
    report: `/api/report${customersPart}/generate`,
    investorProfile: {
      questionnaireSession: {
        startQuestionnaireSession: `/api${customersPart}/questionnaire-sessions`,
        setQuestionnaireSessionAnswers: (
          questionnaireSessionIdentifier: string
        ) =>
          `/api${customersPart}/questionnaire-sessions/${questionnaireSessionIdentifier}/answers`,
        getQuestionnaireSessionRiskProfile: (
          questionnaireSessionIdentifier: string
        ) =>
          `/api${customersPart}/questionnaire-sessions/${questionnaireSessionIdentifier}/risk-profile`,
        getQuestionnaireSessionFinancialProfile: (
          questionnaireSessionIdentifier: string
        ) =>
          `/api${customersPart}/questionnaire-sessions/${questionnaireSessionIdentifier}/financial-profile`,
      },
      riskProfile: {
        setPreferedRiskProfile: (riskProfileId: string) =>
          `/api${customersPart}/risk-profiles/${riskProfileId}/preferred`,
        validateRiskProfile: (riskProfileId: string) =>
          `/api${customersPart}/risk-profiles/${riskProfileId}/validate`,
        searchRiskProfile: `/api${customersPart}/risk-profiles`,
      },
      financialProfile: {
        validateFinancialProfile: (financialProfileId: string) =>
          `/api${customersPart}/financial-profiles/${financialProfileId}/validate`,
        searchFinancialProfile: `/api${customersPart}/financial-profiles`,
      },
      profileScale : `/api/profile-scales`,
      
    },
  };

  return urls;
}
