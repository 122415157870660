import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux';
import { DashboardState } from './modules/dashboard/dashboardSlice';
import { PatrimonyState } from './modules/patrimony/patrimonySlice';
import { LegalPensionState } from './modules/legal-pension/legalPensionSlice';
import { LifeProjectsState } from './modules/life-projects/lifeProjectsSlice';
import { PensionNeedState } from './modules/pension-need/pensionNeedSlice';
import { TaxationState } from './modules/taxation/taxationSlice';
import { LoaderState } from './shared/components/Loader/loaderSlice';
import { ProgressState } from './shared/layouts/ProgressLayout/progressSlice';
import { RecommendationState } from './modules/recommendation/recommendationSlice';
import { UserType } from './shared/requests/getUserReferenceRequest';
import { TenantState } from './shared/slices/tenantSlice';
import { InvestorProfileState } from './modules/investor-profile/investorProfileSlice';


export interface AuthenticationState {
  userType: UserType | null;
  customerId: string | null,
  isAuthenticated: boolean,
  renderTenantSelectionPage?: boolean,
  bffLogoutUrl?: string
}

// Explicitly define the root state to not depend upon the type inference over store.getState
export interface RootState {
  loader: LoaderState,
  progress: ProgressState,
  pensionNeed: PensionNeedState,
  legalPension: LegalPensionState,
  taxation: TaxationState,
  patrimony: PatrimonyState,
  lifeProjects: LifeProjectsState,
  recommendation: RecommendationState,
  dashboard: DashboardState,
  authentication: AuthenticationState,
  investorProfile: InvestorProfileState,
  tenant: TenantState
}

type AppDispatch = Dispatch<AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore = () => useStore<RootState>();