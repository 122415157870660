import { useAnalytics } from "./useAnalytics";

export function useAuthEvent() {
    const { loginEvent, registerEvent } = useAnalytics();

    function authEvent(type: string){
        if(type === "login") {
            return loginEvent()
        }

        if(type === "register") {
            return registerEvent()
        }
    }   
    
    return { authEvent };
}