import { createEnumPage } from '../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../commands/answerCommands';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../i18n';
import { ProfessionalStatus } from '../../models/ProfessionalStatus';

export const ProfessionalStatusPage = createEnumPage<ProfessionalStatus>({
  selector: (s) => s.legalPension.answers.professionalStatus,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerProfessionalStatus,
  values: Object.values(ProfessionalStatus).map((status) => ({
    value: status,
    label: i18n.status[status]
  })),
  question: i18n.professionalStatusPage.question,
  backgroundImage: BackgroundImage
});
