import { TrueFalseDontKnow } from '../../../models/RiskProfileEnum';
import { createEnumPage } from '../../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../../commands/answerCommands';
import { BackgroundImage } from '../../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../../i18n';

export const OpciOrScpiPage = createEnumPage<TrueFalseDontKnow>({
  selector: (s) => s.investorProfile.financialProfile.opciOrScpi,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerOpciOrScpi,
  values: [
    {
      label: i18n.financialProfile.opciOrScpiPage.answer1,
      value: TrueFalseDontKnow.True
    },
    {
      label: i18n.financialProfile.opciOrScpiPage.answer2,
      value: TrueFalseDontKnow.False
    },
    {
      label: i18n.financialProfile.opciOrScpiPage.answer3,
      value: TrueFalseDontKnow.IDontKnow
    }
  ],
  question: i18n.financialProfile.opciOrScpiPage.question,
  backgroundImage: BackgroundImage
});