import { globali18n } from '../../../i18n';
import { AgeModel } from '../models/RecommendationModel';

export function retirementAge(model: AgeModel) {
  const gl = globali18n.labels;

  const name =
    `${model.year} ${gl.timePeriodPlural.years}` +
    (model.month === 0 ? '' : ` ${gl.and} ${model.month} ${gl.timePeriodPlural.months}`);
  return name;
}
