import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import axios from 'axios';
import { CustomerInformationModel } from '../models/AccountPreference';
import { axiosRequestValidateStatusConfig } from '@ifs/libs/src/shared/axiosValidateStatusConfig';

export async function getAccountPreferenceRequest(): Promise<CustomerInformationModel | null> {
  const result = await axios.get<CustomerInformationModel>(apiUrls().customers.get, {...axiosRequestValidateStatusConfig});  

  if ((result.status === 204 || result.status === 404)) {
    return null;
  }

  return result?.data;
}
