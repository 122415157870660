import { matmut } from '@ifs/libs/src/multi-tenant/Matmut/Matmut';
import { bcef } from '@ifs/libs/src/multi-tenant/BCEF/BCEF';
import { bcefPrivateBank } from '@ifs/libs/src/multi-tenant/BCEFPrivateBank/BCEFPrivateBank';
import { bcefRetail } from '@ifs/libs/src/multi-tenant/BCEFRetail/BCEFRetail';
import { assets as registrationAssets } from './modules/registration/Assets';
import { assets as appointmentAssets } from './modules/appointment/Assets';
import { condition as appointmentCondition } from './modules/appointment/Displayed';
import { condition as registerCondition } from './modules/registration/Displayed';
import { formRule as AppointmentFormRule } from './modules/appointment/FormRules';
import { i18n as registerI18n } from './modules/registration/i18n';
import { i18n as appointmentI18n } from './modules/appointment/i18n';
import { i18n as profileI18n } from './modules/profile/i18n';
import { globali18n } from '@ifs/libs/src/i18n';
import { ExternalUrlsType } from '@ifs/libs/src/multi-tenant/Models/ExternalUrls';
import { cssVar } from '@ifs/libs/src/cssVar';
import { Tenant } from '@ifs/libs/src/shared/models/TenantModel';

export function TenantLoader(tenantIdentifier: Tenant, tenantUrls: ExternalUrlsType) {
  const devTenant = sessionStorage.getItem('tenant');
  let favIcon = document.getElementById('favicon') as HTMLAnchorElement | null;

  if (tenantIdentifier !== Tenant.Internaute) {
    let tenant = matmut;
    if (devTenant) {
      if (devTenant === 'matmut') {
        tenant = matmut;
        favIcon!.href = process.env.PUBLIC_URL + '/matmut.ico';
      } else if (devTenant === 'bcef') {
        tenant = bcef;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      } else if (devTenant === Tenant.BCEFPrivateBank) {
        tenant = bcefPrivateBank;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      } else if (devTenant === Tenant.BCEFRetail) {
        tenant = bcefRetail;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      }
    } else {
      if (tenantIdentifier === Tenant.Matmut) {
        tenant = matmut;
        favIcon!.href = process.env.PUBLIC_URL + '/matmut.ico';
      } else if (tenantIdentifier === Tenant.BCEF) {
        tenant = bcef;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      } else if (tenantIdentifier === Tenant.BCEFPrivateBank) {
        tenant = bcefPrivateBank;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      } else if (tenantIdentifier === Tenant.BCEFRetail) {
        tenant = bcefRetail;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
      }
    }

    Object.keys(tenant.cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (tenant.cssVar as any)[e])
    );

    tenant.assets.registration(registrationAssets);
    tenant.assets.appointment(appointmentAssets);

    tenant.formRule.appointment(AppointmentFormRule);

    tenant.displayed.appointment(appointmentCondition);
    tenant.displayed.register(registerCondition);
    tenant.i18n.registration(registerI18n, tenantUrls.GDPRPage);
    tenant.i18n.appointment(appointmentI18n);
    tenant.i18n.profile(profileI18n);

    tenant.i18n.global(globali18n);
    document.title = globali18n.title;
  } else {
    favIcon!.href = process.env.PUBLIC_URL + '/internaute.ico';
    Object.keys(cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (cssVar as any)[e])
    );
  }
}
