import { FieldErrors, UseFormRegister, UseFormWatch, UseFormTrigger } from 'react-hook-form';
import { ValidationMessages, InputGroup, TextInputPassword } from '@ifs/libs';
import { ValidationMessagesPassword } from './ValidationMessagesPassword';
import { i18n } from '../../../modules/registration/i18n';
import { hasErrors } from '../../utilities/hasErrors';
import { passwordMaxLength } from '../../utilities/validation';
import { getFormConfig } from './formConfig';
import { useEffect, useState } from 'react';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';

interface EmailInputGroupProps {
  passwordName: string;
  passwordConfirmName: string;
  isSubmitted: boolean;
  errors?: FieldErrors<{ password: string; passwordConfirm: string }>;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  customPasswordNameWording?: string;
  customPasswordConfirmNameWording?: string;
}

export function PasswordConfirmInputGroup(props: EmailInputGroupProps) {
  const [passwordFirstFocus, setPasswordFirstFocus] = useState<boolean>(false);
  const [hasFirstChar, setHasFirstChar] = useState<boolean>(false);
  const [hasFirstCharConfirmPassword, setHasFirstCharConfirmPassword] = useState<boolean>(false);

  const formConfig = getFormConfig(
    props.passwordName,
    props.passwordConfirmName,
    props.register,
    props.watch,
    props.trigger
  );
  useEffect(() => {
    const watcher = props.watch();

    if (watcher.password) {
      setHasFirstChar(true);
    }

    if (watcher.passwordConfirm) {
      setHasFirstCharConfirmPassword(true);
    }
  }, [props]);

  function validatePassword() {
    if (!props.errors || !props.errors.password || !props.errors.password.types) {
      return [];
    }

    return Object.keys(props.errors.password.types);
  }

  return (
    <>
      <InputGroup
        isInvalid={hasErrors(props.passwordName, props.errors)}
        isValid={props.isSubmitted && !hasErrors(props.passwordName, props.errors)}
      >
        <label htmlFor={props.passwordName}>
          {props.customPasswordNameWording
            ? props.customPasswordNameWording
            : i18n.registrationPage.labels.password}
        </label>

        <TextInputPassword
          inputError={hasErrors(props.passwordName, props.errors)}
          maxLength={passwordMaxLength}
          id={props.passwordName}
          register={formConfig.password.field}
          onFocus={() => setPasswordFirstFocus(true)}
        />

        {hasFirstChar && passwordFirstFocus && (
          <ValidationMessagesPassword errors={validatePassword()} />
        )}
      </InputGroup>

      <InputGroup
        isInvalid={hasErrors(props.passwordConfirmName, props.errors)}
        isValid={props.isSubmitted && !hasErrors(props.passwordConfirmName, props.errors)}
      >
        <label htmlFor={props.passwordConfirmName}>
          {props.customPasswordConfirmNameWording
            ? props.customPasswordConfirmNameWording
            : i18n.registrationPage.labels.passwordConfirm}
        </label>
        <TextInputPassword
          inputError={hasErrors(props.passwordConfirmName, props.errors)}
          maxLength={passwordMaxLength}
          id={props.passwordConfirmName}
          register={formConfig.passwordConfirm.field}
        />

        {hasFirstCharConfirmPassword && (
          <ValidationMessages
            messages={getValidationMessages(
              props.passwordConfirmName,
              props.errors,
              formConfig.passwordConfirm.errors
            )}
          />
        )}
      </InputGroup>
    </>
  );
}
