import { PasswordErrors } from "../shared/models/PasswordErrors";
import { Module } from "../shared/models/Module";
import { FooterLink, Tenant } from "./models/TenantModel";

export const i18n = {
  moduleNames: {
    [Module.LegalPension]: "Votre retraite légale",
    [Module.PensionNeed]: "Vos besoins à la retraite",
    [Module.Patrimony]: "Votre patrimoine",
    [Module.LifeProjects]: "Vos projets de vie",
    [Module.Taxation]: "Votre avantage fiscal",
    [Module.InvestorProfile]: "Profil investisseur"
  },
  moduleNamesInterlayer: {
    [Module.LegalPension]: "Retraite légale",
    [Module.PensionNeed]: "Besoins à la retraite",
    [Module.Patrimony]: "Patrimoine",
    [Module.LifeProjects]: "Projets de vie",
    [Module.Taxation]: "Fiscalité",
    ['Diagnostic']: 'Diagnostic retraite',
    [Module.InvestorProfile]: "Profil investisseur",
    ['Recommendation']: 'Solution personnalisée'
  },
  skipLink: {
    caption: "Aller au contenu principal",
  },
  header: {
    logOut: "Déconnexion",
    logIn: "Connexion",
    register: "Inscription",
    dashboard: "Tableau de bord",
    profileTitle: "Mon profil",
    setting: "Paramètres de mon compte",
    rendezVous: "Prendre un rendez-vous"
  },
  footerLink: {
    [FooterLink.AboutUsPage]: "À propos",
    [FooterLink.FaqPage]: "FAQ",
    [FooterLink.PartnersPage]: "Nos partenaires",
    [FooterLink.TermsOfUsePage]: "CGU",
    [FooterLink.CookiesPage]: "Politique d'utilisation des cookies",
    [FooterLink.PrivacyPage]: "Protection des données personnelles",
    [FooterLink.A11yPage]: "Accessibilité\xa0: partiellement conforme"
  },
  footer: {
    aboutUs: "À propos",
    faq: "FAQ",
    partners: "Nos partenaires",
    termsOfUse: "CGU",
    cookies: "Politique d'utilisation des cookies",
    privacy: "Politique de protection des données",
    a11y: "Accessibilité\xa0: partiellement conforme",
    logo: "BNP Paribas logo",
  },
  interlayer:{
    title: 'Complétez ces étapes pour accéder à',
    lastOneTitle: 'Complétez cette étape pour accéder à',
    diagnostic: 'Votre diagnostic retraite',
    solution: 'Votre solution personnalisée'
  },
  pageNotFound: {
    title: "Page introuvable",
  },
  emailInputGroup: {
    label: "Adresse e-mail",
  },
  passwordConfirmInputGroup: {
    errors: {
      mismatch: "Les mots de passe ne sont pas identiques.",
      [PasswordErrors.minUppercase]: "Au moins 1 majuscule",
      [PasswordErrors.minLowercase]: "Au moins 1 minuscule",
      [PasswordErrors.minNumber]: "Au moins 1 nombre",
      [PasswordErrors.minSymbol]: "Au moins 1 caractère spécial",
      [PasswordErrors.minLength]: "Au moins 8 caractères",
      [PasswordErrors.noOther]: "Pas d'autre type de caractères",
    },
  },
  landingPage: {
    start: "C'est parti\u00A0!",
    goToDiagnostic: "Découvrez votre diagnostic"
  },
  internalErrorModal: {
    title: "Erreur technique",
    description:
      "Suite à un problème technique inattendu, le service peut être interrompu ou perturbé. Nous vous invitons à relancer la page en cours ou renouveler votre visite dans quelques instants. Veuillez-nous excuser.",
    continue: "Continuer",
  },
};
