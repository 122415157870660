import classNames from 'classnames';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { useForm } from 'react-hook-form';
import { PasswordConfirmInputGroup } from 'shared/components';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { useCommands } from '../../commands/commands';
import { i18n } from '../../i18n';
import styles from './PasswordResetResetPage.module.scss';
import { Container, PageTitle, Button, ButtonStyles, SvgWrapperComponent } from '@ifs/libs';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';

interface FormData {
  password: string;
  passwordConfirm: string;
}

export function PasswordResetResetPage() {
  const { register, handleSubmit, watch, trigger, formState } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {},
    mode: 'all'
  });
  const { resetPasswordCommand } = useCommands();
  const searchParams = useSearchParams();

  const email = searchParams.get('email')!;
  const errors = formState.errors;
  const sessionId = searchParams.get('sessionId')!;

  async function onSubmit(data: FormData) {
    const model = {
      password: data.password,
      email,
      sessionId
    };

    await resetPasswordCommand(model);
  }

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.passwordResetResetPage.title}</PageTitle>
      <h2 className={ModuleStyles.Subtitle}>{i18n.passwordResetResetPage.subtitle}</h2>

      <form
        className={ModuleStyles.FormGridResponsive}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordConfirmInputGroup
          passwordName={'password'}
          passwordConfirmName={'passwordConfirm'}
          isSubmitted={formState.isSubmitted}
          register={register}
          watch={watch}
          trigger={trigger}
          errors={errors}
        />
        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
        >
          {i18n.passwordResetResetPage.labels.reset}
        </Button>
      </form>
      <BackgroundImage />
    </Container>
  );
}
