import axios, { AxiosError } from 'axios';

export enum RegisterOutcome {
  Success,
  LoginAlreadyExists
}

export async function registerRequest(
  model: RegisterModel
): Promise<RegisterOutcome> {
  try {
    await axios.post(`/api/auth/register`, model);
    return RegisterOutcome.Success;
  } catch (err) {
    const error = err as AxiosError;
    const body = error.response?.data as any;

    if (error.response?.status === 400 && body.error === 'LoginAlreadyExists') {
      return RegisterOutcome.LoginAlreadyExists;
    }

    throw err;
  }
}

export interface RegisterModel {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isBankClient: boolean;
  canReceiveCommercialContent: boolean;
}
