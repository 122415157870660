import { useNavigate } from 'react-router-dom';
import { LandingCard } from '../../../../shared';
import { useAppUrls } from '../../../../shared/utilities/urls';
import { i18n } from '../../i18n';
import styles from '../../../../styles/shared/LandingPage.module.scss';
import { Interlayer } from '../../../../shared/components/Interlayer/Interlayer';
import { Module } from '../../../../shared/models/Module';
import { ModuleName } from '../../../../shared/components/LandingCard/ModuleName';
import { useAnalytics } from '../../../../shared/commands/useAnalytics';
import { useGlobalCommand } from '../../../../shared/commands/useGlobalCommand';

export function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { landingCTAEvent } = useAnalytics();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();

  function onStart() {
    landingCTAEvent("taxation");

    navigate(appUrls.taxation.taxAvailable);
  }

  return (
    <Interlayer className={styles.Interlayer} active={Module.Taxation} noGoal={isTenantRecommendationModeStatic()}>
      <LandingCard
        gridClassName={styles.Grid}
        module={ModuleName.Taxation}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content}
        onStart={onStart}
      />
    </Interlayer>
  );
}
