import { useEffect } from "react";
import { usePageViewEvent } from "../../../shared/commands/usePageViewEvent";
import { AnalyticsComponentProps, PageViewModel } from "../../../shared/models/PageView";

export function AnalyticsComponent(props: AnalyticsComponentProps) {
    const { pageViewEvent } = usePageViewEvent();
    useEffect(() => {
      const datas: PageViewModel = {
        page_title: props.page_title,
        page_path: props.page_path,
        page_type: props.page_type,
        simulator_screen: props.simulator_screen,
        simulator_type: props.simulator_type,
        simulator_step: props.simulator_step,
        login: false,
        user_id: "",
      };
  
      pageViewEvent(datas);
    }, [props.children]);
  
    return props.children;
  }