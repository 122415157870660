import { getLastValidatedRequest } from "../../../modules/legal-pension/requests/getLastValidatedRequest";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../shared";
import { useAppDispatch } from "../../../store";
import { useComputeRecommendationCommand } from "../commands/computeRecommendationCommand";
import {
  resetRecommendation,
  setModel,
  setParameters,
} from "../recommendationSlice";
import { getParametersRequest } from "../requests/getParametersRequest";
import { outputToInputParameters } from "../utilities/functions";
import {
  setAnswers,
  setLastValidatedResult,
  setResults,
} from "../../../modules/legal-pension/legalPensionSlice";
import { getComputationFromValidateRequest } from "../../../modules/legal-pension/requests/getComputationFromValidateRequest";
import { useGlobalCommand } from "../../../shared/commands/useGlobalCommand";
import { useGetDashboardModel } from "../../../shared/commands/useGetDashboardModel.command";

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { getDashboardModelCommand } = useGetDashboardModel();
  const { computeRecommendationCommand } = useComputeRecommendationCommand();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const { customerid } = useParams();

  async function setup() {
    showLoaderWhile(
      async () => {
        if (isTenantRecommendationModeStatic()) {
          // Recommendation Static dont need to compute a recommendation but need get dashboard information          
          // ToDo if dashboard resolver cleanup not necessary change params true to false
          await getDashboardModelCommand(true, customerid);
          setLoading(false);
          return;
        } else {
          const lastValidated = await getLastValidatedRequest(customerid);
          const computationResponse = await getComputationFromValidateRequest(
            lastValidated!.id,
            customerid
          );
          dispatch(setLastValidatedResult(lastValidated!));
          dispatch(setAnswers(computationResponse!.inputs));
          dispatch(setResults(computationResponse!));

          // Get the current parameters (in case something was previously saved)
          const initialParameters = await getParametersRequest(customerid);

          // Compute the current recommendation based on these parameters
          const computeOutput = await computeRecommendationCommand({
            initialInvestmentAmount: null,
            recurringInvestmentAmount: null,
            ...initialParameters,
          });

          // Strangely, if nothing was previously saved, the 'current' parameters will be mostly null and not default values
          // We need to extract the default values from the result of the initial computation

          const inputParameters = outputToInputParameters(
            computeOutput.parameters,
            lastValidated!.chosenScenario
          );
          dispatch(setParameters(inputParameters));
          dispatch(setModel(computeOutput));

          setLoading(false);
        }
      },
      {
        key: "recommendationResolver_setup",
        value: `Handle recommendation parameters setup on file resolver.ts`,
      }
    );
  }

  async function cleanup() {
    dispatch(resetRecommendation());
  }

  useEffect(() => {
    setup();
    return () => {
      cleanup();
    };
  }, []);

  return { loading };
}
