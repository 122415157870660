import { PropsWithChildren } from "react";
import { globali18n } from "../../../i18n";
import { Card, Button } from "../../";
import { ButtonStyles } from "../Button/ButtonStyles";
import styles from "./QuestionCard.module.scss";
import classNames from "classnames";

interface QuestionCardProps {
  childrenClassName?: string;
  autoFocusValidate?: boolean;
}

export function QuestionCard(props: PropsWithChildren<QuestionCardProps>) {
  return (
    <Card className={styles.Card}>
      <div className={props.childrenClassName}>{props.children}</div>
      <Button
        className={classNames(ButtonStyles.PrimaryAction, styles.ButtonBase)}
        autoFocus={props.autoFocusValidate}
      >
        {globali18n.labels.validate}
      </Button>
    </Card>
  );
}
