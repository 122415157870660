import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { i18n } from "../../../../modules/dashboard/i18n";
import {
  ButtonStyles,
  ModalOutcome,
  useModal,
  Button,
  StyledKendoModal,
} from "../../../../shared";
import styles from "./SaveYourInfoModal.module.scss";
import { scrollTopCommand } from "../../../../shared/commands/scrollTop";

export enum SaveYourInfoResult {
  Register,
  Cancel,
}

export function SaveYourInfoModal() {
  const { hideModal } = useModal();

  function register() {
    hideModal(ModalOutcome.Ok, SaveYourInfoResult.Register);
    scrollTopCommand()
  }

  return (
    <>
      <StyledKendoModal
        title={i18n.saveYourInfoModal.labels.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={"700px"}
      >
        <div id={"modal-content"} tabIndex={-1}>
          <div className={styles.ContentGrid}>
            {i18n.saveYourInfoModal.labels.description}
          </div>
          <DialogActionsBar layout="end">
            <section className={styles.DialogActionsBar}>
              <Button className={ButtonStyles.PrimaryAction} onClick={register}>
                {i18n.saveYourInfoModal.labels.create}
              </Button>
            </section>
          </DialogActionsBar>
        </div>
      </StyledKendoModal>
    </>
  );
}
