import { UserType } from '@ifs/libs/src/shared/requests/getUserReferenceRequest';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationState {
  userType: UserType | null;
  customerId: string | null,
  isAuthenticated: boolean,
  renderTenantSelectionPage?: boolean,
  bffLogoutUrl?: string
}

const initialState: AuthenticationState = {
  userType: null,
  customerId: null,
  isAuthenticated: false,
  renderTenantSelectionPage: false,
  bffLogoutUrl: ""
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<AuthenticationState>) => {
      return { ...action.payload }
    },
    resetAuthentication: () => {
      return { ...initialState };
    }
  }
});

export const { resetAuthentication, setAuthentication } = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
