import { useDeleteAccount } from 'modules/profile/commands/deleteAccount';
import { i18n } from 'modules/profile/i18n';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonGroupOption,
  ButtonStyles,
  Container,
  ModalOutcome,
  PageTitle,
  useGlobalCommand,
  useInfoModal,
  useLogoutCommand
} from '@ifs/libs';
import styles from './ProfilePage.module.scss';

import { useConfirmModal } from '@ifs/libs';
import { useState } from 'react';
import { globali18n } from '@ifs/libs/src/i18n';
import classNames from 'classnames';
import { SetCustomerInformationForm } from 'modules/profile/components/SetCustomerInformationForm/SetCustomerInformationForm';
import { SetCustomerPasswordForm } from 'modules/profile/components/SetCustomerPasswordForm/SetCustomerPasswordForm';
import { useResolver } from 'modules/profile/resolver/resolver';

enum MenuOption {
  Information = 'Information',
  Password = 'Password'
}

export function ProfilePage() {
  const { showConfirm } = useConfirmModal();
  const { showInfoModal } = useInfoModal();
  const { preferences } = useResolver();
  const { deleteAccountCommand } = useDeleteAccount();
  const { handleLogoutCommand } = useLogoutCommand();
  const { isAuthenticationModeSSO } = useGlobalCommand();
  const navigate = useNavigate();
  const [menuSelected, setMenuSelected] = useState<MenuOption>(MenuOption.Information);  

  const t = i18n.profilePage;

  const menuOptions: ButtonGroupOption[] = [
    { label: t.menu.information, value: MenuOption.Information },
    { label: t.menu.password, value: MenuOption.Password }
  ];

  function back() {
    navigate(-1);
  }

  function renderContent(menu: MenuOption): boolean {
    return menuSelected === menu;
  }

  async function deleteAccount() {
    const result = await showConfirm(
      i18n.accountDeletionModal.title,
      i18n.accountDeletionModal.body,
      i18n.accountDeletionModal.okText,
      i18n.accountDeletionModal.cancelText
    );

    if (result.outcome !== ModalOutcome.Ok) {
      return;
    }

    await deleteAccountCommand();
    await showInfoModal(
      i18n.accountDeletedModal.title,
      i18n.accountDeletedModal.body,
      false,
      'delete-account'
    );

    await handleLogoutCommand();
  }

  return (
    <Container className={styles.Container}>
      <Button onClick={back} className={classNames(styles.Back, ButtonStyles.Back)}>
        &lsaquo; {globali18n.labels.back}
      </Button>
      <PageTitle className={styles.Title}>{t.title}</PageTitle>

      {!isAuthenticationModeSSO() && (
        <ButtonGroup
          className={styles.MenuPage}
          options={menuOptions}
          value={menuSelected}
          onChange={setMenuSelected}
        />
      )}
      <div className={styles.Content}>
        {renderContent(MenuOption.Information) && (
          <SetCustomerInformationForm deleteAccount={deleteAccount} />
        )}
        {renderContent(MenuOption.Password) && (
          <SetCustomerPasswordForm
            switchTab={() => {
              setMenuSelected(MenuOption.Information);
            }}
          />
        )}
      </div>
    </Container>
  );
}
