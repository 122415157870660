// These routes throw 'legitimate' 401 responses that need to be be processed by the user interface

import axios, { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { useGlobalCommand } from '../../shared/commands/useGlobalCommand';
import { useErrorHandler } from 'react-error-boundary';
import { useAnalytics } from '../../shared/commands/useAnalytics';
import { useLogoutCommand } from '../../shared/commands/logout';
import { internalUrls } from '../../shared/utilities/urls';

// They must not trigger a redirection towards the login page
const excludedUrls = [
  '/api/auth/request_reset_password',
  '/api/auth/user_reference',
  '/api/auth/reset_password',
  '/api/auth/accounts/enable',
  '/api/auth/accounts/disable',
  "/api/auth/accepted_email_domains"
];

// Specific Errors not trigger render TechnicalErrorPage
// key property of the map equal on api request
const specificErrors = new Map<string, string[]>([
  ["/api/auth/login", ["invalid_grant"]],
  ["/api/auth/resend_validation_email", ["TechnicalError"]],
  ["/api/auth/register", ["LoginAlreadyExists"]],
  ["/api/auth/upgrade_prospect", ["LoginAlreadyExists"]],
  ["/api/auth/accounts/enable", ['UserNotFound']],
  ["/api/auth/accounts/disable", ['UserNotFound']],
  ["/api/auth/user_reference", ['TechnicalError']]  
]);

function errorMustBeHandleWithErrorBoundary(error: AxiosError | any): boolean {
  const reponseError = error?.response?.data?.error;
  const requestUrl = error?.config?.url;
  if(!requestUrl) return true;
  const errors = specificErrors.get(requestUrl);
  if(!errors) return true;
  return !errors.includes(reponseError);
}

// For that particular error, we need to define a specific function.
// We cant use the mtehod errorMustBeHandleWithErrorBoundary, because 
// the error return doesn't have the same backend error structure.
function isSpecialTenantServiceError(error: AxiosError | any): boolean {
  const dataError = error?.response?.data;
  const requestUrl = error?.config?.url;
  return requestUrl === "/api/tenant" && dataError === "No cookie with name 'IfsTenant' was found.";
}

export function use401Interceptor() {
  const interceptorRef = useRef<number>();
  const { resetAllCommand, isAuthenticationModeSSO } = useGlobalCommand();
  const { bffLogoutCommand } = useLogoutCommand();
  const handleError = useErrorHandler(); 
  const { eventErrorPage } = useAnalytics();  

  
  useEffect(() => {

    const id = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        // This interceptor only handles 401s, ignore others
        if (error.response!.status !== 401) {
          if(errorMustBeHandleWithErrorBoundary(error) && !isSpecialTenantServiceError(error)){
            // handleError = custom hook that reject the error
            // The error rejected will be handle by the ErrorBoundary flow
            handleError(error);
          }
          eventErrorPage();
          throw error;
        }

        // 401 STATUS ERROR HANDLE

        // It's called responseURL but it is really the request URL (+ redirects)
        const responseURL = (error.request as XMLHttpRequest)?.responseURL;
        const path = new URL(responseURL).pathname;
                    
        // If it is one of the legitimate 401s, ignore it
        if (excludedUrls.includes(path)) {
          throw error;
        }
        
        // Reset all state
        resetAllCommand();
        
        // Disconnection is handle differently depending on SSO/standard mode
        
        // SSO mode
        if(isAuthenticationModeSSO()) {
          // Cleanup SSO cookies then handle the redirection (parameter or login page
          bffLogoutCommand();
          return;
        }

        // Standard mode
        // Always redirect to the login page
        window.location.href = internalUrls.authentication.login;

        // TODO: Check if necessary
        throw error;
      }
    );

    interceptorRef.current = id;

    return () => {
      if (interceptorRef.current != null) {
        axios.interceptors.response.eject(interceptorRef.current);
      }
    };
  }, []);
}
