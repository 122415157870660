import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoaderState {
  pendingRequest: number;
  loaderRequestQueue: string, // JSON string OBJECT
  isActive: boolean;
}

const initialState: LoaderState = {
  pendingRequest: 0,
  loaderRequestQueue: "", // JSON string OBJECT
  isActive: false
};

// Cannot save Map Object on redux state (redux only want serializable)
// Need use this constant to save and delete the loarder queue
const loaderRequestQueuetMap: Map<string,string> =  new Map(); 

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: initialState,
  reducers: {
    showLoader: (state, action: PayloadAction<{ key: string; value: string;}>) => {
      const {key, value} = action.payload;
      state.pendingRequest++;
      if(key && value) {
       try {
         // Update Queue Map Object      
         loaderRequestQueuetMap.set(key, value);
         // Transform Map Object on JSON String and save it on state property
         state.loaderRequestQueue = JSON.stringify(Object.fromEntries(loaderRequestQueuetMap));
       } catch (error) {
        return
       }
      }
      state.isActive = state.pendingRequest > 0 && !!loaderRequestQueuetMap.size;
    },
    hideLoader: (state, action: PayloadAction<{ key: string; value: string;}>) => {
      const {key} = action.payload;

      if(key){
        try {
          // Delete request ended (= loader should disapear for the request) on Map Object
          loaderRequestQueuetMap.delete(key);
          // Transform Map Object on JSON String and save it on state property
          state.loaderRequestQueue = JSON.stringify(Object.fromEntries(loaderRequestQueuetMap));
          
        } catch (error) {
          return
        }
      }
      
      state.pendingRequest = state.pendingRequest > 0 ? state.pendingRequest - 1 : 0;
      state.isActive = state.pendingRequest > 0 && !!loaderRequestQueuetMap.size;
    },
    resetLoader: () => {
      loaderRequestQueuetMap.clear();
      return { ...initialState };
    }
  }
});

export const { resetLoader, showLoader, hideLoader } = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;
