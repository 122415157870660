import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant, TenantModelResponse } from '../../shared/models/TenantModel';


export type TenantState = TenantModelResponse;

const initialState: TenantState = {
  Urls: {},
  TenantIdentifier: '' as Tenant,
  ModuleSettings: []
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantState: (state, action: PayloadAction<TenantModelResponse>) => {
      return { ...action.payload };
    },
    setModuleSettings: (state, action: PayloadAction<{Key: string, Value: string}>) => {
      const settingToUpdate = action.payload;
      const moduleSettings = state.ModuleSettings;
      moduleSettings.map((setting) => {
        if(setting.Key === settingToUpdate.Key) {
          setting.Value = settingToUpdate.Value;
        }
        return setting;
      })

    },
    resetTenant: () => {
      return { ...initialState };
    }
  }
});

export const { resetTenant, setTenantState, setModuleSettings } = tenantSlice.actions;
export const tenantReducer = tenantSlice.reducer;