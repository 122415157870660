import { AgeModel } from "../../modules/recommendation/models/RecommendationModel";
import { retirementAge } from "../../modules/recommendation/utilities/format";
import { amountPerMonth } from "../../shared/utilities/intl";
import { SavingsAccountProductType } from "./models/ProductType";
import { SavingAccountType } from "./patrimonySlice";

export const i18n = {
  module: "Votre patrimoine",
  categories: {
    synthesis: "Synthèse",
  },
  realEstateModal: {
    modalTitle: "Ajouter un bien",
    description: "Description",
    placeholder: "Appartement Grenoble",
    isMainResidenceSwitch:
      "Ce bien sera utilisé comme résidence principale au moment de la retraite.",
    isMainResidenceText:
      "La résidence principale au moment de la retraite n’est pas prise en compte comme complément de revenus à la retraite.",
    estimation: "Estimation",
    alertMainResidence:
      " Il faut obligatoirement une résidence principale au moment de la retraite.",
  },

  landingPage: {
    pageTitle: "Patrimoine",
    title:
      "Grâce à vos placements, vous avez commencé à préparer votre retraite\u00A0!",
    content: (
      <p>
        Regardons ensemble le complément de revenus que procurera votre
        patrimoine.
      </p>
    ),
  },

  savingsProductType: {
    [SavingsAccountProductType.CompteCourant]: 'Compte courant',
    [SavingsAccountProductType.LivretEpargne]: 'Livret d’épargne',
    [SavingsAccountProductType.PEL]: 'Plan d’Epargne Logement (PEL)',
    [SavingsAccountProductType.AssuranceVie]: 'Assurance vie',
    [SavingsAccountProductType.CompteTitres]: 'Comptes titres',
    [SavingsAccountProductType.PEA]: 'PEA',
    [SavingsAccountProductType.PERIndividuel]: 'PER individuel',
    [SavingsAccountProductType.PERP]: 'PERP',
    [SavingsAccountProductType.Madelin]: 'Madelin',
    [SavingsAccountProductType.PEREntrepriseOrPERCO]: 'PER d’Entreprise Collectif / PERCO',
    [SavingsAccountProductType.PEROEntrepriseOrArticle83]:
      "PERO d’Entreprise obligatoire / Article 83",
    [SavingsAccountProductType.PEE]: "PEE",
    [SavingsAccountProductType.Immobilier]: "Immobilier",
  },

  declarationPage: {
    pageTitle: "Déclaration du patrimoine",
    subTitle: "Déclaration",
    yourPatrimony: "Votre patrimoine",

    financialSavingsCard: {
      title: 'Patrimoine financier',
      subTitle: 'Livret A, Assurance vie, PERIN...'
    },

    employeeSavingsCard: {
      title: "Votre épargne d’entreprise",
      subTitle: "PEE, Perco...",
    },

    savingsCard: {
      add: {
        [SavingAccountType.SavingsAccounts]: "+ Ajouter un avoir financier",
      },
      total: "Total",
    },

    savingsItem: {
      currentAmount: "Montant actuel déclaré\u00A0:",
      monthlySavings: "Epargne mensuelle estimée\u00A0:",
    },

    realEstateCard: {
      title: "Patrimoine immobilier",
      subTitle: "Résidence principale, résidence secondaire...",
      estimateAmount: "Montant estimé :",
      mainResidence: "Résidence principale au moment de la retraite :",
      information:
        "Mes biens immobiliers acquis ou en cours d'acquisition, que je pourrais vendre et utiliser pour ma retraite.",
      transferAmountTitle: "Cessions immobilières",
      transferAmountLabel: "Valorisation",
      rentalIncomeTitle: "Revenus locatifs",
      rentalIncomeLabel: "Mensualités",
      addRealEstate: "+ Ajouter un bien immobilier",
      total: "Total",
      alert:
        "La résidence principale au moment de la retraite n’est pas prise en compte comme complément de revenus à la retraite",
    },
    continueButton: "Projetons-nous dans le futur\u00A0!",
  },

  savingsModal: {
    title: "Ajouter votre épargne financière",
    productTypeIdentifier: {
      label: "Choisissez le type d’épargne ou placement financier",
      placeholder: "Sélectionner un type",
    },
    amounts: "Renseignez le montant de votre épargne ou placement",
    currentAmount: {
      label: "Montant actuel",
    },
    monthlySavings: {
      label: "Epargne mensuelle estimative",
    },
  },

  projectionPage: {
    pageTitle: "Projection",
    title: "Projection de votre patrimoine à la retraite",
    subTitle: "Projection",
    continueButton: "En synthèse\u00A0: votre complément de revenus",

    currentFinancialValueCard: {
      title: "Votre patrimoine financier actuel",
      description: "Vos placements financiers et votre épargne d’entreprise",
    },

    financialValueAtRetirementCard: {
      title: "Votre patrimoine financier à ",
      description:
        "Estimation du rendement de vos placements et des versements futurs",
      separatorText: "Equivaut en rente à\u00A0:",
    },

    rentalIncomeAtRetirementCard: {
      title: "Vos revenus immobiliers à ",
      description:
        "Estimation de la rente issue de vos cessions immobilières ajoutée à vos revenus locatifs",
    },
    pointInTime: {
      current: "Aujourd'hui",
      atRetirement: "A la retraite",
    },
  },

  summaryPage: {
    pageTitle: "Synthèse",
    title: "Estimation de votre patrimoine global à ",
    estimateDesc: (age: AgeModel) => (
      <>
        Voici une estimation de votre patrimoine à votre retraite à l'âge de {retirementAge(age)} selon 3 scénarios d'évolution des marchés : défavorable, neutre,
        favorable.
      </>
    ),
    scenarioDesc: (age: AgeModel) => (
      <>
        Le scénario que nous estimons le plus probable est le scénario neutre : votre patrimoine global est estimé à {retirementAge(age)} à
      </>
    ),
    knowMoreLink: "En savoir plus",
    subTitle: "Synthèse",
    estimation: "Estimation",
    today: "Aujourd'hui",
    at: "à",
    scenario: {
      pessimistic: "Scénario défavorable",
      neutral: "Scénario neutre",
      optimistic: "Scénario favorable",
    },
    financialProdile: "Patrimoine financier",
    realEstate: "Patrimoine immobilier",

    additionalIncome: "Estimation de votre patrimoine converti en revenus mensuels à",
    mainResidenceText:
      "Votre résidence principale au moment de la retraite n’est pas prise en compte comme complément de revenus à la retraite.",
    editDeclaration: "Modifier votre déclaration",
    introduction:
      "Voici une estimation du complément de revenus lié à votre patrimoine pour votre retraite selon 3 scénarios d’évolution des marchés financiers\u00A0: défavorable, favorable et neutre.",
    attention: "Points d'attention",
    attentionContent: (
      <>
        <p>
          Tous les scénarios sont présentés hors frais, fiscalité et
          prélèvements sociaux. <br/>
          Les montants investis sur les supports en unités de compte ne sont pas
          garantis mais sont sujets à des fluctuations à la hausse comme à la
          baisse dépendant en particulier de l’évolution des marchés financiers
          et/ou immobiliers. <br/>
          Les simulations n’ont aucune valeur contractuelle
          et ne nous engagent dans la mesure où elles ne constituent pas une
          offre ferme de conclure un acte d’achat ou de vente.
        </p>
      </>
    ),
    scenarios: {
      pessimistic: "défavorable",
      neutral: "neutre",
      optimistic: "favorable",
    },

    info: (scenario: string, amount: number, age: AgeModel) => (
      <>
        Selon des hypothèses d’évolution {scenario} de marché, nous estimons que
        vous pourriez avoir <b>{amountPerMonth(amount)}</b> à{" "}
        {retirementAge(age)}.
      </>
    ),

    pessimisticCard: {
      title: "Scénario défavorable",
    },

    neutralCard: {
      title: "Scénario neutre",
    },

    optimisticCard: {
      title: "Scénario favorable",
    },

    moreInfoLink:
      "Comment a été calculé votre patrimoine financier à la retraite\u00A0?",

    evolutionInfo: {
      title: "Scénarios d’évolution des marchés",
      content: {
        title:
          "Voici une estimation de votre patrimoine à votre retraite selon 3 scénarios d’évolution des marchés : défavorable, favorable et neutre.",
        table: {
          headers: {
            worst: "Scénario défavorable",
            neutral: "Scénario neutre",
            good: "Scénario favorable",
          },
          labels: {
            financial: "Patrimoine financier",
            realEstate: "Patrimoine immobilier",
            total: "Total",
          },
        },
        panelBar: {
          title: "Comment a été projeté votre patrimoine à la retraite ?",
          content: (
            <>
              <p>
                Afin d’estimer la valeur de votre patrimoine lors de votre
                départ à la retraite, nous utilisons des méthodes probabilistes
                de valorisation ou dévalorisation des différents actifs
                composant votre patrimoine le jour où vous faites la simulation.
              </p>
              <p>
                Ces méthodes tiennent compte de l’évolution passée des actifs au
                sein de votre patrimoine pour calculer les futures variations
                possibles.
              </p>
              <p>
                Dans des conditions normales de marché et selon nos calculs,
                nous sommes capables d’estimer la valeur future de votre
                patrimoine selon les 3 scénarios suivants :
              </p>
              <p>
                Un scénario « neutre » : le scénario que nous estimons le plus probable dans des conditions de marché habituelles. La valorisation de votre patrimoine a ainsi, selon nos calculs, 50 % de chances de se trouver au-dessus du montant indiqué et 50 % de chances de se retrouver en dessous du montant indiqué.
                Un scénario « défavorable » : ce scénario correspond à des mouvements défavorables de marché. La valorisation de votre patrimoine a ainsi selon nos calculs 95% de chances de se trouver au-dessus du montant indiqué ; 5% de risques de se retrouver en dessous du montant indiqué.
                Un scenario « favorable » : ce scénario correspond à des mouvements favorables de marché. La valorisation de votre patrimoine a ainsi selon nos calculs 5% de chances de se trouver au-dessus du montant indiqué ; 95% de risques de se retrouver en dessous du montant indiqué.
                Une analyse est réalisée pour déterminer les risques de perte et de rendement, pour chaque enveloppe fiscale (Plan Epargne Retraite, Assurance vie, etc.) et chaque bien immobilier identifié dans le parcours de simulation.
              </p>
              <p>
                Cette projection des plus ou moins-values potentielles provient
                des données agrégées des supports d’investissement qui composent
                l’enveloppe fiscale (organisme de placement collectif en valeurs
                mobilières, fonds négocié en bourse, etc.). Le rendement des
                supports provient de 5 facteurs : la performance du marché
                monétaire, la performance provenant de l’indice de référence du
                fonds, la performance du marché immobilier, la gestion active et
                les frais.
              </p>
              <p>
                Tous les scénarios sont présentés hors frais, fiscalité et
                prélèvements sociaux.
              </p>
              <p>
                Les montants investis ne sont garantis ni à la hausse ni à la
                baisse car ils sont sujets aux fluctuations des marchés
                financiers et/ou du marché immobilier.
              </p>
              <p>
                Les simulations n’ont aucune valeur contractuelle et ne nous
                engagent aucunement dans la mesure où elles ne constituent pas
                une offre ferme de conclure un acte d’achat ou de vente.
              </p>
            </>
          ),
        },
      },
    },

    howCalculatedModal: {
      title: "Comment a été calculé votre complément de revenu ?",
      content: (
        <>
          <p>
            Nous avons appliqué les espérances de vie actuelles moyennes pour
            convertir votre patrimoine en revenus mensuels.
          </p>
          <p>
            Votre résidence principale au moment de la retraite n’est pas prise
            en compte comme complément de revenus à la retraite.
          </p>

          <p style={{ color: "#BC5127" }}>
            <h6>Exemple : </h6>
            Un patrimoine hors résidence principale à la retraite à 67 ans de
            150 000€ pour une femme avec une espérance de vie de 86 ans :
            Estimation des revenus mensuels = 150 000€ / ((86 ans - 67 ans) * 12
            mois) = 658€ / mois
          </p>
        </>
      ),
    },

    moreInfoModal: {
      title:
        "Comment a été calculé votre patrimoine financier à la retraite\u00A0?",
      content: (
        <>
          <p>
            Le premier montant affiché avec la mention «&nbsp;aujourd’hui&nbsp;»
            correspond au capital que vous souhaitez allouer pour la retraite.
            Par ailleurs les trois scénarii de rendement décrits ci-dessous
            tiennent compte des versements que vous avez déclaré vouloir dédier
            à votre retraite.
          </p>
          <p>
            Afin d’estimer votre patrimoine à horizon nous utilisons des
            méthodes probabilistes de valorisation des différents actifs (plans
            épargne, compte courant, fonds, actions, obligations…) composant
            votre patrimoine à date. Ces méthodes de calcul nous permettent
            d’identifier des probabilités de croissance ou décroissance de la
            valorisation des différents actifs. Ces méthodes tiennent compte de
            l’évolution passée des actifs au sein de votre patrimoine, pour
            calculer les variations possibles de leur évolution future.
          </p>
          <p>
            Dans des conditions normales de marché et selon nos calculs, nous
            sommes capables de faire apparaitre les 3 scénarii suivants
            d’estimation de votre patrimoine au moment de votre départ à la
            retraite&nbsp;:
          </p>
          <ul>
            <li>
              Un scénario «&nbsp;neutre&nbsp;»&nbsp;: le scénario que nous
              estimons le plus probable dans des conditions de marché
              habituelles. La valorisation de votre patrimoine a ainsi selon nos
              calculs 50% de chance de se trouver au-dessus de ce montant&nbsp;;
              50% de chances de se retrouver en dessous du montant indiqué.
            </li>
            <li>
              Un scénario «&nbsp;favorable&nbsp;»&nbsp;: ce scénario correspond
              à un scénario favorable de mouvements de marché. La valorisation
              de votre patrimoine a ainsi selon nos calculs 5% de chance de se
              trouver au-dessus du montant indiqué&nbsp;; 95% de chances de se
              retrouver en dessous du montant indiqué.
            </li>
            <li>
              Un scenario «&nbsp;défavorable&nbsp;»&nbsp;: ce scénario
              correspond à un scénario défavorable de mouvements de marché. La
              valorisation de votre patrimoine a ainsi selon nos calculs 95% de
              chance de se trouver au-dessus du montant indiqué&nbsp;; 5% de
              chances de se retrouver en dessous du montant indiqué.
            </li>
          </ul>
          <p>
            Pour chaque enveloppe fiscale (Plan Epargne Retraite, Assurance vie…) proposée dans le
            parcours, une analyse est réalisée afin de déterminer ses caractéristiques en termes de
            risque et de rendement. Les projections futures des enveloppes sont ensuite effectuées,
            basées sur ces éléments. Pour chaque enveloppe, le risque et le rendement proviennent
            des données agrégées de chacun des supports d’investissement aussi appelés Unités de
            compte, qui composent l’enveloppe fiscale (organisme de placement collectif en valeurs
            mobilières, fonds négocié en bourse, …). Le rendement des supports provient de quatre
            facteurs&nbsp;: la performance du marché monétaire, la performance provenant de l’indice
            de référence du fonds, la gestion active et les frais.
          </p>
          <p>
            Tous les scénarios présentés sont établis hors frais, fiscalité et
            prélèvements sociaux applicables au cadre d’investissement. Les
            montants investis ne sont pas garantis mais sont sujets à des
            fluctuations à la hausse ou à la baisse dépendant en particulier de
            l’évolution des marchés financiers et/ou immobiliers. Vous pouvez
            subir un risque de perte en capital pouvant être partielle ou totale
            sur ces supports. Les performances passées ne préjugent pas des
            performances futures des supports utilisés dans les différents
            dispositifs proposés. Les simulations n’ont aucune valeur
            contractuelle et ne nous engagent aucunement dans la mesure où elles
            ne constituent pas une offre ferme de conclure un acte d’achat ou de
            vente.
          </p>
        </>
      ),
    },
  },
};
