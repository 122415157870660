import { useLoader } from '@ifs/libs';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import { delay } from 'shared/utilities/delay';
import { SetCustomerInformationModel } from '../models/AccountPreference';
import { setAccountPreferenceRequest } from '../requests/setAccountPreferenceRequest';

export function useSetAccountPreference() {
  const { showLoaderWhile } = useLoader();

  async function setAccountPreferenceCommand(
    customerId: string,
    model: SetCustomerInformationModel
  ) {
    await showLoaderWhile(
      async () => {
        await delay(50);
        await setAccountPreferenceRequest(customerId, model);
      },
      { key: 'useSetAccountPreference', value: `API POST - ${apiUrls().customers.put}` }
    );
  }

  return { setAccountPreferenceCommand };
}
