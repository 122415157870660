import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { TransportType } from '../../../models/transport/transport-type.enum';
import { setTransportCarDefinedValue, setTransportCarScaleValue } from '../../../pensionNeedSlice';
import { nextTransportAction } from '../../../routing/nextTransportRoute';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const TransportCarPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) => q.transport.find((c) => c.typeId === TransportType.Car)?.scale ?? null,
  definedValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.Car)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.transportCarPage,
  scaleValueReducer: setTransportCarScaleValue,
  definedValueReducer: setTransportCarDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.transportCarPage.answer1,
      value: DefaultScale.NotAtAll
    },
    { icon: moneyIcon.Money2, label: i18n.transportCarPage.answer2, value: DefaultScale.ALittle },
    { icon: moneyIcon.Money3, label: i18n.transportCarPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: (_, __, questionnaire) =>
    nextTransportAction(TransportType.Car, questionnaire.transportTypes!)
});
