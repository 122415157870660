import { TenantColors } from "./multi-tenant/Models/Tenant";

export const cssVar : TenantColors = {
    "--primary-wintergreen": "#2E6B5D",
    "--primary-hubble": "#667399",
    "--primary-keppel": "#3C805E",
    "--primary-green_sheen": "#2F7EA0",
    "--primary-sage": "#1B5049",
    "--primary-lemon_meringue": "#F1ECC3",
    "--primary-burlywood": "#446983",
    "--danger-red": "#DD2F58",
    "--primary-green_70": "#26736b",
    "--secondary-ruddy_brown": "#007BBD",
    "--secondary-copper": "#EDF8F8",
    "--background-floral_white": "#fffaed",
    "--light-old_lace": "#fff2e0",
    "--header-background_lotion": "#fffdf8",
    "--secondary-main": "#51687B",
    "--themeBackgroundColor": "#46A59E",
    "--secondary-CTA": "#51687B",
    "--landing-interlayer": "#fbedd0",
    "--legal-pension-summary-last-salary-legend": "rgb(87, 131, 123)", // need rbg format to allow chart to define the stroke color
    "--legal-pension-summary-pension-amount-legend": "#6DB794",
    "--legal-pension-summary-loss-amount-legend": "#51687B",
    "--app-font-family": "Open Sans",
    "--fiscality-color": "#33689A",
  };