import { useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { globali18n } from '../../i18n';

export interface PageRouteProps extends RouteProps {
  pageTitle: string;
  pageTitlePropsExclusive?: boolean,
  element: JSX.Element;
}

export function PageRoute(props: PageRouteProps): JSX.Element {
  useEffect(() => {
    document.title = props.pageTitlePropsExclusive ? props.pageTitle : [props.pageTitle, globali18n.title].join(' | ');
  }, [props.pageTitle]);

  return props.element;
}
