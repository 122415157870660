import axios from "axios";
import { TaxationOutputModel } from "../models/TaxationOutputModel";
import { apiUrls } from "../../../shared/apiUrls";
import { axiosRequestValidateStatusConfig } from "../../../shared/axiosValidateStatusConfig";

export async function getTaxationRequest(customerid?: string): Promise<TaxationOutputModel | null> {
  const apiUrl = apiUrls(customerid).taxation.get;
  
  const response = await axios.get<TaxationOutputModel>(apiUrl, {...axiosRequestValidateStatusConfig});

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  const data = response.data;

  return data;
}
