import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";

export async function validateFinancialProfile(customerId: string | undefined, financialProfileIdentifier: string) {
  const apiUrl =
    apiUrls(customerId).investorProfile.financialProfile.validateFinancialProfile(financialProfileIdentifier);
  const response = await axios.put(apiUrl);

  const data = response.data;

  return data;
}
