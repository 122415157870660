import { Container, PageTitle } from '../../';
import { i18n } from '../../i18n';
import styles from './PageNotFound.module.scss';

export function PageNotFound() {
  return (
    <Container className={styles.Container}>
      <PageTitle>{i18n.pageNotFound.title}</PageTitle>
      404
    </Container>
  );
}
