import classNames from "classnames";
import { ModalOutcome, StyledKendoModal, useModal } from "../../";
import styles from "./InfoModal.module.scss";

interface MoreInfoModalProps {
  title: string;
  content: JSX.Element;
  contentBoldClass: boolean;
  textContentLargeClass?: boolean;
}

function MoreInfoModal(props: MoreInfoModalProps) {
  const { hideModal } = useModal();

  return (
    <>
      <StyledKendoModal
        className={styles.Dialog}
        title={props.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={"500px"}
      >
        <div
          className={classNames(styles.Content, {
            [styles.ContenBold]: props.contentBoldClass,
            [styles.TextContentLarge]: props.textContentLargeClass,
          })}
          id={"modal-content"}
          tabIndex={-1}
        >
          {props.content}
        </div>
      </StyledKendoModal>
    </>
  );
}

export function useInfoModal() {
  const { showModal, hideModal } = useModal();

  async function showInfoModal(
    title: string,
    content: JSX.Element,
    contentBoldClass: boolean = false,
    origin: string,
    contentNormalClass: boolean = false,
  ) {
    const result = await showModal(
      <MoreInfoModal
        title={title}
        content={content}
        contentBoldClass={contentBoldClass}
        textContentLargeClass={contentNormalClass}
      />
    );
    if (result.outcome === ModalOutcome.Cancel) {  
      document.getElementById(origin)!.focus();
      return;
    }

  }

  return { showInfoModal, hideModal };
}
