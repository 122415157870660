import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { HealthType } from '../../../models/health/health-type.enum';
import {
  setHealthMedicalDefinedValue,
  setHealthMedicalScaleValue
} from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const HealthMedicalPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) => q.health.find((c) => c.typeId === HealthType.Medical)?.scale ?? null,
  definedValueSelector: (q) =>
    q.health.find((c) => c.typeId === HealthType.Medical)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.healthMedicalPage,
  scaleValueReducer: setHealthMedicalScaleValue,
  definedValueReducer: setHealthMedicalDefinedValue,
  answers: [
    { icon: moneyIcon.Money1, label: i18n.healthMedicalPage.answer1, value: DefaultScale.NotAtAll },
    { icon: moneyIcon.Money2, label: i18n.healthMedicalPage.answer2, value: DefaultScale.ALittle },
    { icon: moneyIcon.Money3, label: i18n.healthMedicalPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: () => ({ route: '../health/unexpected' })
});
