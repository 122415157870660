import { createFocusTrap } from "focus-trap";

export const focusTrap = () => {
  setTimeout(() => {
    const container = document.getElementById("kendo-modal");

    //A problem happen when the modal had no action like button or input
    //To fix that we force the focus on the close icon button
    const modalContent = document.getElementById("modal-content");
    if (modalContent) {
      const closeIcon = document.getElementsByClassName("k-dialog-action")[0];

      //Focus trap on close icon for the tax saving modal in recommendation
      if (!closeIcon) {
        const closeIcon = document.getElementById("close-Icon");
        closeIcon.focus();
      }
      closeIcon.focus();
    }

    const focusTrap = createFocusTrap("#kendo-modal", {
      onActivate: () => container.classList.add("is-active"),
      onDeactivate: () => container.classList.remove("is-active"),
    });

    const activateTrap = document.getElementById("kendo-modal");
    const deactivateTrap = document.getElementById("kendo-modal");
    activateTrap.addEventListener("click", focusTrap.activate);
    deactivateTrap.addEventListener("click", focusTrap.deactivate);
  }, 100);
};

export const drawerFocusTrap = (action) => {
  setTimeout(() => {
    const preContainer = document.getElementsByClassName(
      "k-widget k-drawer k-drawer-end"
    )[0];
    const preSubContainer = document.getElementsByClassName(
      "k-drawer-items"
    )[0];
    preContainer.setAttribute("id", "kendo-drawer");
    preSubContainer.setAttribute('id', "activate-kendo-drawer")
    preContainer.setAttribute('role', "toolbar")

    const container = document.getElementById("kendo-drawer");

    const focusTrap = createFocusTrap("#kendo-drawer", {
      onActivate: () => container.classList.add("is-active"),
      onDeactivate: () => container.classList.remove("is-active"),
      clickOutsideDeactivates: true,
    });

    if(action === "activate") {
      focusTrap.activate()
    } else {
      focusTrap.deactivate()
    }
  }, 100);
};
