export const i18n = {
  loginPage: {
    pageTitle: 'Connexion',
    labels: {
      password: 'Mot de passe',
      connect: 'Se connecter',
      passwordReset: 'Mot de passe oublié\u00A0?',
      accountActivated: 'Félicitations, votre compte est activé.',
      passwordChanged: 'Votre mot de passe a été modifié.'
    },
    logout: "Déconnexion",
    errors: {
      incorrectCredentials:
        "Il n'existe aucun utilisateur qui coïncide avec les e-mail et mot de passe fournis.",
      userLockedOut:
        "Ce compte est temporairement suspendu à cause d'un nombre trop important de tentatives de connexion."
    },
    title: 'Connectez-vous',
    subtitle: 'Identifiez-vous afin d’accéder à votre espace personnel'
  },
  passwordResetPage: {
    pageTitle: 'Mot de passe oublié',
    labels: {
      emailAddress: 'Adresse e-mail',
      explanation:
        'Si cette adresse mail existe, vous recevrez sous peu un email de réinitialisation de mot de passe.',
      reset: 'Réinitialiser votre mot de passe',
      expiredReset: 'Renvoyer un email de réinitialisation de mot de passe'
    },
    errors: {
      unknownEmailAddress: 'Cette adresse mail n’est associé à aucun compte'
    },
    title: 'Vous avez oublié votre mot de passe\u00A0?',
    subtitle: 'Pas de panique, il suffit de nous indiquer votre adresse mail',
    expiredTitle: 'Votre lien de réinitialisation de mot de passe a expiré',
    expiredSubtitle:
      'Cliquez-ci dessous pour recevoir un nouvel email de réinitialisation de mot de passe'
  },
  passwordResetEmailPage: {
    pageTitle: 'Email envoyé',
    labels: {
      goToLogin: 'Se connecter'
    },
    title: 'Un lien de réinitialisation de mot de passe vous a été envoyé (valable 48h)',
    subtitle: ''
  },
  passwordResetResetPage: {
    pageTitle: 'Réinitialisation du mot de passe',
    labels: {
      reset: 'Confirmer'
    },
    title: 'Réinitialisez votre mot de passe',
    subtitle: 'Saisissez votre nouveau mot de passe et confirmez'
  }
};
