import { useNavigate } from "react-router-dom";
import { ModuleName } from "../../../../shared/components/LandingCard/ModuleName";
import { LandingCard, Module, Interlayer } from "../../../../shared";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { i18n } from "../../i18n";
import styles from "./LandingPage.module.scss";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

export function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { landingCTAEvent } = useAnalytics();

  function onStart() {
    landingCTAEvent("life_projects");

    navigate(appUrls.lifeProjects.projects);
  }

  return (
    <Interlayer className={styles.Interlayer} active={Module.LifeProjects}>
      <LandingCard
        module={ModuleName.LifeProject}
        gridClassName={styles.Grid}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content}
        onStart={onStart}
      />
    </Interlayer>
  );
}
