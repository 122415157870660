import { useLoader } from "../../../shared";
import { useAppUrls } from "../../../shared/utilities/urls";

export function useViewReportCommand() {
  const { showLoaderWhile } = useLoader();
  // const { reportEvent } = useAnalytics();
  const appUrls = useAppUrls()

  async function viewReportCommand() {
    return await showLoaderWhile(async () => {
      // reportEvent();
      window.location.href = appUrls.reportUrl;
    });
  }

  return { viewReportCommand };
}
