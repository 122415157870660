
import { useLoader } from "@ifs/libs";
import { apiUrls } from "@ifs/libs/src/shared/apiUrls";
import { useEffect, useState } from "react";
import { useLoadTenant } from "../../shared/commands/loadTenant.command";

export function useAppResolver() {
    const { showLoaderWhile } = useLoader();
    const { loadTenantCommand } = useLoadTenant()
    const [loading, setLoading] = useState(true);

    function setup() {
        showLoaderWhile(async () => {
            await loadTenantCommand();
            setLoading(false);
        }, {key: "WebAppAppResolver_setup", value:`API GET - ${apiUrls().tenants}`});
    }

    useEffect(() => {
        setup();
    }, []);

    return { loading };
}
