import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { i18n } from "../../../../modules/dashboard/i18n";
import {
  ButtonStyles,
  ModalOutcome,
  useModal,
  Button,
  StyledKendoModal,
} from "../../../../shared";
import { useEffect } from "react";

export enum RegisterOrLoginResult {
  Register,
  Login,
}

type RegisterOrLoginModal = {
  title?: string;
};

export function RegisterOrLoginModal(props: RegisterOrLoginModal) {
  const { hideModal } = useModal();
  const { registerModal } = useAnalytics();
  

  function register() {
    registerModal(i18n.registerOrLoginModal.create);
    hideModal(ModalOutcome.Ok, RegisterOrLoginResult.Register);
  }

  function login() {
    registerModal(i18n.registerOrLoginModal.login);
    hideModal(ModalOutcome.Ok, RegisterOrLoginResult.Login);
  }
  

  return (
    <>
      <StyledKendoModal
        title={props.title ? props.title : i18n.registerOrLoginModal.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={"650px"}
        
        >
        {i18n.registerOrLoginModal.already}
        <DialogActionsBar layout="end">
      
          <Button className={ButtonStyles.PrimaryAction} onClick={register} id={"modal-content"}>
            {i18n.registerOrLoginModal.create}
          </Button>
          <Button className={ButtonStyles.SecondaryAction} onClick={login}>
            {i18n.registerOrLoginModal.login}
          </Button>
        </DialogActionsBar>
      </StyledKendoModal>
    </>
  );
}
