export enum EComputationType {
    Declared = "Declared",
    Incomes = "Incomes",
    Persona = "persona",
    Questionnaire = "questionnaire",
}

export enum EGamificationType {
    Simple = "simple",
    Detailed = "detailed",
}