import React from 'react';
import '@ifs/libs/src/styles/main.scss';
import './index.scss';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import  reportWebVitals  from '@ifs/libs';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
