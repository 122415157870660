import classNames from 'classnames';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { useNavigate } from 'react-router';
import { i18n } from '../../i18n';
import styles from './PasswordResetEmailPage.module.scss';
import {
  Container, PageTitle,
  ButtonStyles,
  Button,
  SvgWrapperComponent
} from '@ifs/libs';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { globali18n } from '@ifs/libs/src/i18n';

export function PasswordResetEmailPage() {
  const navigate = useNavigate();

  function goToLogin() {
    navigate(internalUrls.authentication.login);
  }

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.passwordResetEmailPage.title}</PageTitle>
      <SvgWrapperComponent ariaHidden={true}><SharedAssets.Mailbox className={ModuleStyles.Mailbox} /></SvgWrapperComponent>

      <div className={ModuleStyles.FormGridCenteredResponsive}>
        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
          onClick={goToLogin}
        >
          {i18n.passwordResetEmailPage.labels.goToLogin}
        </Button>
        <p className={styles.SmallText}>{globali18n.labels.spamWarning}</p>
      </div>
      <BackgroundImage />
    </Container>
  );
}
