import { FunctionComponent, SVGProps } from "react";
import { Card, EditIcon } from "../../../../../../shared";
import { amount } from "../../../../../../shared/utilities/intl";
import { stormcloud } from "../../../../../../styles/colors";
import styles from "./ProjectCard.module.scss";

export interface ProjectCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;

  amount: number;
  onEdit: () => void;
  id: string;
}

export function ProjectCard(props: ProjectCardProps) {
  const Icon = props.icon;

  return (
    <Card className={styles.Grid}>
      <Icon className={styles.Icon} />
      <h1 className={styles.Title}>{props.title}</h1>
      <button className={styles.Edit} onClick={props.onEdit}>
        <span>
          <svg
            style={{ cursor: "pointer" }}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.222 1.53585C16.2593 2.57324 16.2593 4.25518 15.222 5.29256L5.41128 15.1032C4.98573 15.5288 4.45252 15.8307 3.86867 15.9767L0.825771 16.7374C0.339398 16.859 -0.101161 16.4184 0.0204327 15.932L0.781158 12.8891C0.927121 12.3053 1.22902 11.7721 1.65457 11.3465L11.4652 1.53585C12.5026 0.498466 14.1846 0.498466 15.222 1.53585ZM10.526 4.35324L2.59374 12.2857C2.33841 12.541 2.15728 12.861 2.0697 13.2113L1.57742 15.1804L3.54654 14.6881C3.89685 14.6005 4.21677 14.4194 4.4721 14.1641L12.404 6.23132L10.526 4.35324ZM12.4044 2.47503L11.465 3.41421L13.3431 5.29228L14.2828 4.35339C14.8015 3.83469 14.8015 2.99372 14.2828 2.47503C13.7641 1.95634 12.9231 1.95634 12.4044 2.47503Z"
              fill={stormcloud}
            />
          </svg>
        </span>
      </button>
      <div className={styles.Amount}>{amount(props.amount)}</div>
      <div className={styles.Description}>{props.description}</div>
    </Card>
  );
}
