import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { AdvisorCustomerDetail, CustomerDetail } from '../models/request/getBirthdateRequest.model';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';

export async function getBirthdateRequest(customerid?: string): Promise<CustomerDetail | AdvisorCustomerDetail | null> {
  const apiUrl = apiUrls(customerid).pensionNeed.customer;

  const response = await axios.get<any>(apiUrl, {...axiosRequestValidateStatusConfig});

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  const data = response.data;

  return data;
}
