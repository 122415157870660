import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ComputationInputModel,
  FinancialProfileModel,
  RiskProfileModel,
} from "./models/ComputationInputModel";
import {
  LossOfValue,
  Fluctuation,
  Horizon,
  LossReaction,
  Scenario,
  FinancialInstrumentsOrMarkets,
  TrueFalseDontKnow,
} from "./models/RiskProfileEnum";

export interface InvestorProfileState {
  riskProfile: RiskProfileModel;
  financialProfile: FinancialProfileModel;
  results: null;
  isSummaryPageRender: boolean;
}

const initialState: InvestorProfileState = {
  riskProfile: {
    riskProfileIdentifier: null
  } as unknown as RiskProfileModel,
  financialProfile: {
    financialInstrumentsOrMarkets: [],
  } as unknown as FinancialProfileModel,
  results: null,
  isSummaryPageRender: false,
};

export const investorProfileSlice = createSlice({
  name: "investorProfile",
  initialState,
  reducers: {
    setRenderSummaryPage: (state, action: PayloadAction<boolean>) => {
      state.isSummaryPageRender = action.payload;
    },
    setRiskProfileIdentifier: (state, action: PayloadAction<string>) => {
      state.riskProfile.riskProfileIdentifier = action.payload;
    },
    setHorizon: (state, action: PayloadAction<Horizon>) => {
      state.riskProfile.horizon = action.payload;
    },
    setFluctuation: (state, action: PayloadAction<Fluctuation>) => {
      state.riskProfile.fluctuation = action.payload;
    },
    setLossOfValue: (state, action: PayloadAction<LossOfValue>) => {
      state.riskProfile.lossOfValue = action.payload;
    },
    setLossReaction: (state, action: PayloadAction<LossReaction>) => {
      state.riskProfile.lossReaction = action.payload;
    },
    setScenario: (state, action: PayloadAction<Scenario>) => {
      state.riskProfile.scenario = action.payload;
    },

    setFinancialProfileIdentifier: (state, action: PayloadAction<string>) => {
      state.financialProfile.financialProfileIdentifier = action.payload;
    },
    setMarketFluctuation: (state, action: PayloadAction<TrueFalseDontKnow>) => {
      state.financialProfile.marketFluctuation = action.payload;
    },
    setObligation: (state, action: PayloadAction<TrueFalseDontKnow>) => {
      state.financialProfile.obligation = action.payload;
    },
    setOpc: (state, action: PayloadAction<TrueFalseDontKnow>) => {
      state.financialProfile.opc = action.payload;
    },
    setOpciOrScpi: (state, action: PayloadAction<TrueFalseDontKnow>) => {
      state.financialProfile.opciOrScpi = action.payload;
    },
    setFinancialSupport: (state, action: PayloadAction<TrueFalseDontKnow>) => {
      state.financialProfile.financialSupport = action.payload;
    },
    setFinancialInstrumentsOrMarkets: (
      state,
      action: PayloadAction<FinancialInstrumentsOrMarkets[]>
    ) => {
      state.financialProfile.financialInstrumentsOrMarkets = action.payload;
      state.financialProfile!.financialInstrumentsOrMarkets =
        state.financialProfile!.financialInstrumentsOrMarkets.filter((tt) =>
          action.payload.includes(tt)
        );
    },
    resetInvestorProfile: () => {
      return { ...initialState };
    },
  },
});

export const {
  setHorizon,
  setFluctuation,
  setLossOfValue,
  setLossReaction,
  resetInvestorProfile,
  setScenario,
  setMarketFluctuation,
  setObligation,
  setOpc,
  setOpciOrScpi,
  setFinancialSupport,
  setFinancialInstrumentsOrMarkets,
  setRiskProfileIdentifier,
  setRenderSummaryPage,
  setFinancialProfileIdentifier
} = investorProfileSlice.actions;

export const investorProfileReducer = investorProfileSlice.reducer;
