import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Container, QuestionTitle, ValidationMessages } from '../../../../shared/';
import { i18n } from '../../i18n';
import { globali18n } from '../../../../i18n';
import { useAppSelector, useAppStore } from '../../../../store';
import { selectBirthDate } from '../../legalPensionSlice';
import { ageBetween, isValidDate, matchesCareerStartAge } from '../../utilities/dateValidators';
import { parse, isValid } from "date-fns";
import { fr } from 'date-fns/locale';
import { getValidationMessages } from '../../../../shared/utilities/getValidationMessages';
import { QuestionCard } from '../../../../shared/components/QuestionCard/QuestionCard';
import {
  maxAge,
  maxAgeAtCareerStart,
  minAge,
  minAgeAtCareerStart
} from '../../utilities/constants';
import { ModuleStyles } from '../../styles/ModuleStyles';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { useAnswerCommands } from '../../commands/answerCommands';
import InputMask from 'react-input-mask';
import { useNextCommand } from '../../../../modules/pension-need/commands/nextCommand';
import { usePersonaBackend } from '../../../../modules/pension-need/commands/computePersonaBackend';
import { useAppUrls } from '../../../../shared/utilities/urls';
import { useEffect } from 'react';
import { birthdateRequirement } from '../../../../modules/pension-need/commands/birthdateRequirementCommand';
import { AdvisorCustomerDetail, CustomerDetail } from '../../../../modules/pension-need/models/request/getBirthdateRequest.model';
import { dateTimeFormat } from '../../../../shared/utilities/intl';
import { EComputeCategoryId } from '../../../../modules/pension-need/models/computation/ComputeCategory.enum';

interface FormData {
  birthDate: string;
}

interface BirthdatePageProps {
  moduleName: string;
  analyticStep: string;
}

export function BirthdatePage(props: BirthdatePageProps) {
  const { answerBirthdate } = useAnswerCommands();
  const { birthdateRequirementCommand } = birthdateRequirement();
  const currentValue = useAppSelector(selectBirthDate);
  const startYearOfActivity = useAppSelector((s) => s.legalPension.answers.startDateOfActivity);

    const { nextCommand } = useNextCommand();
  const { computePersonaBackendCommand } = usePersonaBackend();
  const { customerid } = useParams();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const store = useAppStore();
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      birthDate: (currentValue && dateTimeFormat(currentValue)) || ''
    }
  });

  useEffect(()=>   {
    verifyAndGetBirthdateValue();
  },[])

  // new Date obj from "dd/mm/yyyy"
  const createDateObjectFromStringDateFormated = (origin: string) : Date => {
    let dateString = "";

    if(origin === "birthdate") {
      dateString = control._formValues.birthDate
    } else {
      dateString = startYearOfActivity
    }

    const parsedDate = parse(dateString, 'P', new Date(), { locale: fr });
    const isValidDate = isValid(parsedDate);

    if(isValidDate) {
      return new Date(
        +dateString.split('/')[2],
        +dateString.split('/')[1] - 1,
        +dateString.split('/')[0]
      );
    } else {
      return new Date()
    }

   
  }

  const verifyAndGetBirthdateValue = async () => {
    if(currentValue) return;
    const result = await birthdateRequirementCommand(customerid);
    let birthDate = null
    if(result) {
      birthDate = (result as CustomerDetail).birthDate || (result as AdvisorCustomerDetail)?.information?.birthDate;
    }

    if(!birthDate)return;
    // Formt birthdate property are saved on string date with this format : dd/MM/yyyy
    setValue("birthDate", dateTimeFormat(new Date(birthDate)));
  }
    
  async function onSubmit() {
    const birthdateValidationType = store.getState().pensionNeed.birthdateValidationType;
    // createDateObjectFromStringDateFormated function allow to transform date dd/MM/YYYY (here = form property) to a Date Object
    const unformatedDate = createDateObjectFromStringDateFormated("birthdate");
    await answerBirthdate(unformatedDate, birthdateValidationType != 'default' &&  props.moduleName !== "legalPension", props.analyticStep);
    
    // By default birthDate page is from legal-pension module
    if (birthdateValidationType === 'default' || props.moduleName === "legalPension") return;

    // Coming from pension-need custom profile
    if (birthdateValidationType === 'advanced')
      return await nextCommand({ compute: true });

    // Coming from pension-need persona
    if (birthdateValidationType === 'intermediate') {
      const type = store.getState().pensionNeed.persona;
      await computePersonaBackendCommand(type as string, customerid);
      navigate(appUrls.pensionNeed.summary);
    }
  }

  const rules = {
    required: true,
    validate: {
      validDate: () => isValidDate(createDateObjectFromStringDateFormated("birthdate")),      
      ageBetween: () => ageBetween(minAge, maxAge, createDateObjectFromStringDateFormated("birthdate")),
      matchesCareerStartAge: () => matchesCareerStartAge(
        minAgeAtCareerStart,
        maxAgeAtCareerStart,
        new Date(startYearOfActivity).getFullYear(),
        createDateObjectFromStringDateFormated("birthdate")
      )
    }
  };

  const errorMessages = {
    required: globali18n.errors.required,
    validDate: i18n.birthdatePage.errors.validDate,
    ageBetween: i18n.birthdatePage.errors.ageBetween,
    matchesCareerStartAge: i18n.careerStartPage.errors.ageBetweenSince
  };

  return (
    <Container className={ModuleStyles.PageGrid}>
      <QuestionTitle>{i18n.birthdatePage.question}</QuestionTitle>
      {props.moduleName === "pensionNeed" && i18n.birthdatePage.subtitle}

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <QuestionCard
          childrenClassName={ModuleStyles.CardChildrenGrid}
          autoFocusValidate={currentValue != null}
        >
          <Controller
            control={control}
            name="birthDate"
            rules={rules}
            render={({ field: { onChange, value, ref } }) => (
              <InputMask
                style={{borderWidth: "1.5px"}}
                ref={ref}
                mask="99/99/9999"
                value={value}
                onChange={onChange}
                placeholder={dateTimeFormat(new Date())}
              />
            )}
          />
          <ValidationMessages
            messages={getValidationMessages('birthDate', errors, errorMessages)}
          />
        </QuestionCard>
      </form>
      <BackgroundImage />
    </Container>
  );
}
