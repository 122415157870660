import { useNavigate } from 'react-router';
import { useLoader } from '@ifs/libs';
import { requestPasswordResetRequest } from '../requests/requestPasswordResetRequest';
import {
  ResetPasswordModel,
  ResetPasswordOutcome,
  resetPasswordRequest
} from '../requests/resetPasswordRequest';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';

export function useCommands() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();

  async function requestPasswordResetCommand(email: string) {
    await showLoaderWhile(async () => {
      await requestPasswordResetRequest(email);

      navigate(internalUrls.authentication.resetEmail);
    }, {key: "requestPasswordResetCommand", value:`API POST - /api/auth/request_reset_password}`});
  }

  async function resetPasswordCommand(model: ResetPasswordModel) {
    await showLoaderWhile(async () => {
      const result = await resetPasswordRequest(model);
      if (result === ResetPasswordOutcome.LinkExpired) {
        navigate({
          pathname: internalUrls.authentication.reset,
          search: new URLSearchParams({ expired: 'true' }).toString()
        });
      }

      if (result === ResetPasswordOutcome.Success) {
        navigate({
          pathname: internalUrls.authentication.login,
          search: new URLSearchParams({ passwordChanged: 'true' }).toString()
        });
      }
    }, {key: "resetPasswordCommand", value:`API POST - /api/auth/reset_password}`});
  }

  return { requestPasswordResetCommand, resetPasswordCommand };
}
