import { useMediaQuery } from "react-responsive";

export function useResponsive() {
  const isDownMd = useMediaQuery({ query: "(max-width: 767px)" });
  const isUpMd = useMediaQuery({ query: "(min-width: 768px)" });
  const onlyMd = useMediaQuery({
    query: "(min-width: 769px) and (max-width: 1199px)",
  });
  const isUpXl = useMediaQuery({ query: "(min-width: 1200px)" });

  return { isDownMd, isUpMd, onlyMd, isUpXl };
}
