export function getValidationMessages(
  name: string,
  errors?: { [key: string]: any },
  messages?: { [key: string]: string }
): string[] {
  if (!errors || !errors[name]) {
    return [];
  }

  const fieldErrors = errors[name].types!;
  const errorMessages = Object.entries(fieldErrors).map(
    ([key, value]) => messages?.[key] || key
  );

  return errorMessages;
}
