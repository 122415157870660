import { ModalOutcome, StyledKendoModal, useModal } from '../../../../../../shared';
import { i18n } from '../../../../i18n';

export function InfoModal() {
  const { hideModal } = useModal();

  return (
      <StyledKendoModal
        title={i18n.recommendationPage.infoModal.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'500px'} 
      >
        <div id={"modal-content"}>
        {i18n.recommendationPage.infoModal.content()}

        </div>
      </StyledKendoModal>
  );
}
