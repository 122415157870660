import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { PatrimonyModel } from '../models/PatrimonyModel';
import { RealEstatesPaginatedResponseModel } from '../models/RealEstateModel';
import { SavingsAccountsPaginated } from '../models/SavingsAccountModel';

export async function getPatrimonyRequest(customerid?: string): Promise<PatrimonyModel | null> {  
  const responseRealEstates = await axios.get<RealEstatesPaginatedResponseModel>(apiUrls(customerid).patrimony.getRealsEstates);
  const responseSavingsAccounts = await axios.get<SavingsAccountsPaginated>(apiUrls(customerid).patrimony.getSavingsAccounts);   

  return {savingsAccounts: responseSavingsAccounts.data!.results || [], realEstates: responseRealEstates.data!.results || []};
}
