import { AnalyticsComponent } from '@ifs/libs/src/shared/components/datalayer/AnalyticsComponent';
import { PageRoute } from '@ifs/libs/src/shared/routing/PageRoute';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { i18n } from './i18n';
import { AppointmentPage } from './pages/AppointmentPage/AppointmentPage';
import { useResolver } from './routing/resolver';

export function AppointmentModule() {
  const { loading } = useResolver();

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <PageRoute
            pageTitle={i18n.appointmentPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={i18n.appointmentPage.pageTitle}
                page_path={'/appointment'}
                page_type={'appointment'}
                simulator_screen={'appointment'}
                simulator_step={''}
                simulator_type={""}
              >
                <AppointmentPage />
              </AnalyticsComponent>
            }
          />
        }
      />
    </Routes>
  );
}
