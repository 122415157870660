import { useExternalUrls } from "../../utilities/externalUrls";
import { i18n } from "../../i18n";
import styles from "./Footer.module.scss";
import classNames from "classnames";
import { Container } from "../Container/Container";
import { condition as FooterCondition } from "./Condition";
import { useEffect, useState } from "react";
import { assets as SharedAssets } from '../../../shared/Assets';
import {
  FooterLink,
  footerLinkOrderMap,
} from "../../../shared/models/TenantModel";

export function Footer() {
  const [footerLinks, setFooterLinks] = useState<
    { i18n: string; link: string; id: FooterLink }[]
  >([]);

  const [homePage, setHomePage] = useState<string>("");
  const { externalUrls } = useExternalUrls();

  useEffect(() => {
    generateFooterLinkArray();
  }, []);

  async function generateFooterLinkArray() {
    const urls = await externalUrls();
    const toReturn: { i18n: string; link: string; id: FooterLink }[] = [];
    setHomePage(urls.HomePage);

    Object.keys(urls).map((e) => {
      if (
        e !== FooterLink.HomePage &&
        e !== FooterLink.LogoutRedirectUrl &&
        e !== FooterLink.AdvisorTutorialPage &&
        e !== FooterLink.ProjectionExplanationPage
      ) {
        if (
          urls[e as FooterLink] !== null &&
          urls[e as FooterLink] !== "" &&
          !FooterCondition.footerLinkNotBeDisplayed.includes(e)
        ) {
          toReturn.push({
            i18n: i18n.footerLink[e as keyof typeof i18n.footerLink],
            link: urls[e as FooterLink],
            id: e as FooterLink,
          });
        }
      }
    });

    toReturn.push({
      i18n: i18n.footerLink.CookiesPage,
      link: "/cookies",
      id: FooterLink.CookiesPage,
    });

    toReturn.sort((a, b) => {
      const valueA = footerLinkOrderMap.get(a.id) || 100;
      const valueB = footerLinkOrderMap.get(b.id) || 100;
      return valueA - valueB;
    });

    setFooterLinks(toReturn);
  }

  return (
    <footer
      className={classNames(styles.Footer, {
        [styles.NoIcons]: !FooterCondition.displayElement,
      })}
    >
      <Container className={styles.Container}>
        <nav>
          {footerLinks.map((e, i) => {
            return (
              <a
                className={styles.Link}
                href={e.link}
                key={`${i}-${e.i18n}`}
                title={`${e.i18n}`}
              >
                <span>{e.i18n}</span>
              </a>
            );
          })}
          {FooterCondition.displayElement && (
            <div className={classNames(styles.Link, styles.Logo)}>
              <SharedAssets.FooterLogo/>
            </div>
          )}
        </nav>
      </Container>
    </footer>
  );
}
