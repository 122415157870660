import axios from 'axios';

export async function validateInitializedAccountRequest(
  params: ValidateInitializedAccountParams,
  payload: ValidateInitializedAccountPayload
): Promise<void> {

  const response = await axios.post<void>(`/api/auth/validate_initialized_account`, payload , {
    params: {...params},
  });

  const data = response.data;

  return data;
}


export interface ValidateInitializedAccountParams {
    email: string; 
    sessionId: string;
}

export interface ValidateInitializedAccountPayload {
  canReceiveCommercialContent:boolean;
  password:string;
}