import { useEffect, useRef, useState } from "react";
import { UserMenuIcon } from "./images";
import { useMenuItems } from "../../../shared/utilities/useMenuItems";
import { i18n } from "../../i18n";
import { useAnalytics } from "../../../shared/commands/useAnalytics";
import { useLogoutCommand } from "../../../shared/commands/logout";
import { internalUrls } from "../../../shared/utilities/urls";
import { Link, useNavigate } from "react-router-dom";
import styles from "./DropDownUserMenu.module.scss";
import classNames from "classnames";
import { SvgWrapperComponent } from "../SvgWrapper/SvgWrapperComponent";
import { globali18n } from "../../../i18n";

type DropDownSettingType = {
  text: string;
  isItemClickable: boolean;
  itemType: DropDownItemType;
  redirectUri?: string;
  onClickEvent?: () => any;
};

enum DropDownItemType {
  Item = "item",
  Title = "title",
}

export function DropDownUserMenu() {
  const navigate = useNavigate();
  const menuItems = useMenuItems();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { headerCTAEvent } = useAnalytics();
  const { handleLogoutCommand } = useLogoutCommand();
  const [dropDownSettings, setDropDownSettings] = useState<
    DropDownSettingType[]
  >([]);

  const componentRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);

    function handleClick(e: any) {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setMenuIsOpen(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    const settings = [];
    menuItems.canGoToProfile &&
      settings.push({
        text: i18n.header.profileTitle,
        isItemClickable: false,
        itemType: DropDownItemType.Title,
      },{
        text: i18n.header.setting,
        isItemClickable: true,
        itemType: DropDownItemType.Item,
        redirectUri: internalUrls.profile.profile,
      });
    menuItems.canLogOut &&
      settings.push({
        text: i18n.header.logOut,
        isItemClickable: true,
        itemType: DropDownItemType.Item,
        onClickEvent: logOut,
      });
    setDropDownSettings(settings);
  }, [menuItems.canGoToProfile]);

  function changeRoute(path: string | undefined) {
    if (path) {
      navigate(path);
      changeOpenState();
    }
  }

  function logOut() {
    headerCTAEvent("deconnexion");
    handleLogoutCommand();
  }

  const dropDownItemRender = (props: {
    item: DropDownSettingType;
    itemIndex: number;
  }) => {
    return (
      <div
        key={props.itemIndex + Math.random()}
        className={classNames([styles.DropDownItem], {
          [styles.LastDropDownItem]:
            props.itemIndex + 1 === dropDownSettings.length,
        })}
      >
        {!!props.item.redirectUri && (
          <button
            aria-label={props.item.text}
            onClick={() => changeRoute(props.item.redirectUri)}
            className={classNames(styles.Text, styles.ClickableItem)}
          >
            {props.item.text}
          </button>
        )}
        {!props.item.redirectUri &&
          (props.item.itemType === DropDownItemType.Title ? (
            <div className={classNames([styles.Text, styles.Title])}>
              {props.item.text}
            </div>
          ) : (
            <button
              aria-label={props.item.text}
              className={classNames([styles.Text], {
                [styles.ClickableItem]: props.item.isItemClickable,
              })}
              onClick={() => {
                props.item.onClickEvent && props.item.onClickEvent();
                changeOpenState();
              }}
            >
              {props.item.text}
            </button>
          ))}
      </div>
    );
  };

  function changeOpenState() {
    setMenuIsOpen(!menuIsOpen);
  }

  return (
    <>
      {!!dropDownSettings?.length && (
        <div className={styles.Wrapper} ref={componentRef as any}>
          <button onClick={changeOpenState} className={styles.ProfileButton}>
            <SvgWrapperComponent
              ariaLabel={globali18n.ariaLabel.applicationMenu}
            >
              <UserMenuIcon />
            </SvgWrapperComponent>
          </button>
          {menuIsOpen && (
            <div className={styles.DropDownPopUp}>
              {dropDownSettings.map((e, i) =>
                dropDownItemRender({ item: e, itemIndex: i })
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
