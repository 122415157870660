import { NextActionPN } from '../commands/nextCommand';
import { TransportType } from '../models/transport/transport-type.enum';

export const transportRoutes = {
  [TransportType.Car]: '../transport/car',
  [TransportType.PublicTransportation]: '../transport/public',
  [TransportType.Motorbike]: '../transport/motorbike',
  [TransportType.Bike]: '../transport/bike'
};

export function nextTransportAction(current: TransportType | null, selected: TransportType[]): NextActionPN {
  // From the transport types page, go to the first selected type
  if (current == null) {
    return { route: transportRoutes[selected[0]] };
  }

  // From the last selected type page, recompute and go to the next category 
  const currentIndex = selected.indexOf(current);
  if (currentIndex === selected.length - 1) {
    return { route: '../supply/restaurant' };
  }

  // From a (non-last) selected type page, go to the next one
  const nextRoute = transportRoutes[selected[currentIndex + 1]];
  return { route: nextRoute };
}
