import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { QuestionnaireSessionFinancialProfile } from "../models/QuestionnaireSessionModel";

export async function getQuestionnaireSessionFinancialProfile(customerId: string | undefined, financialProfileIdentifier: string): Promise<QuestionnaireSessionFinancialProfile> {
  const apiUrl =
    apiUrls(customerId).investorProfile.questionnaireSession.getQuestionnaireSessionFinancialProfile(financialProfileIdentifier);
  const response = await axios.get<QuestionnaireSessionFinancialProfile>(apiUrl);

  const data = response.data;

  return data;
}
