import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { PensionNeedQuestionnaireBody, PensionNeedQuestionnaireResponse } from '../models/request/computationRequest.model';

export async function computeRequest(
  payload: PensionNeedQuestionnaireBody,
  customerid?: string
): Promise<PensionNeedQuestionnaireResponse> {
  const apiUrl = apiUrls(customerid).pensionNeed.computations;


  const response = await axios.post<PensionNeedQuestionnaireResponse>(
    apiUrl,
    payload
  );
  const data = response.data;

  return data;
}
