import classNames from "classnames";
import { useResponsive } from "../../../../../../shared/utilities/responsive";
import styles from "./ButtonCard.module.scss";
import {
  CheckBoxCheckedIcon,
  CheckBoxUncheckedIcon,
  LockedIcon,
} from "./images";

interface ButtonCardProps {
  plus: number;
  minus: number;
  isLocked: boolean;
  isChecked: boolean;
  onClick: () => void;
  index: number;
}

export function ButtonCard(props: ButtonCardProps) {
  const { isDownMd } = useResponsive();

  const stylePlus = isDownMd
    ? { width: `${props.plus/2}%`, height: `20px` }
    : { width: `80px`, height: `${props.plus/2}%` };
  const styleMinus = isDownMd
    ? { width: `${props.minus/2}%`, height: `20px` }
    : { width: `80px`, height: `${props.minus/2}%` };

  return (
    <button
      role="button"
      onClick={props.onClick}
      disabled={props.isLocked}
      className={classNames(styles.BaseContainer, {
        [styles.ButtonContainer]: !props.isLocked,
        [styles.ButtonContainerLocked]: props.isLocked,
      })}
    >
      <span className={styles.Container}>
        {props.isLocked && <LockedIcon className={styles.LockedIcon} />}
        <span className={styles.Plus}>+{props.plus}%</span>
        <span className={styles.Graph}>
          <span className={styles.GraphPlus} style={stylePlus}></span>
          <span className={styles.GraphMinus} style={styleMinus}></span>
        </span>
        <span className={styles.Minus}>-{props.minus}%</span>
      </span>
      <span>
        {props.isChecked ? (
          <CheckBoxCheckedIcon className={styles.CheckboxIcon} />
        ) : (
          <CheckBoxUncheckedIcon className={styles.CheckboxIcon} />
        )}
      </span>
    </button>
  );
}
