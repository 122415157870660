import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { SupplyType } from '../../../models/supply/supply-type.enum';
import {
  setSupplyRestaurantDefinedValue,
  setSupplyRestaurantScaleValue
} from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const SupplyRestaurantPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) =>
    q.supply.find((c) => c.typeId === SupplyType.RestaurantAndDelivery)?.scale ?? null,
  definedValueSelector: (q) =>
    q.supply.find((c) => c.typeId === SupplyType.RestaurantAndDelivery)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.supplyRestaurantPage,
  scaleValueReducer: setSupplyRestaurantScaleValue,
  definedValueReducer: setSupplyRestaurantDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.supplyRestaurantPage.answer1,
      value: DefaultScale.NotAtAll
    },
    {
      icon: moneyIcon.Money2,
      label: i18n.supplyRestaurantPage.answer2,
      value: DefaultScale.ALittle
    },
    { icon: moneyIcon.Money3, label: i18n.supplyRestaurantPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: () => ({ route: '../supply/home' })
});
