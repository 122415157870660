import { useNavigate, useParams } from "react-router";
import { useLoader } from "../../shared/components/Loader/useLoader";
import { internalUrls } from "../../shared/utilities/urls";
import { logoutRequest } from "../requests/logoutRequest";
import { useExternalUrls } from "../utilities/externalUrls";
import { useAnalytics } from "./useAnalytics";
import { useGlobalCommand } from "./useGlobalCommand";
import { bffUserRequest } from "../../shared/requests/bffUserRequest";
import { useAppSelector } from "store";

export function useLogoutCommand() {
  const { logoutEvent } = useAnalytics();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();
  const navigate = useNavigate();
  const { resetAllCommand, isAuthenticationModeSSO } = useGlobalCommand();
  const { externalUrls } = useExternalUrls();

  async function handleLogoutCommand(reason: string = "user") {    
    if(isAuthenticationModeSSO()) {
      bffLogoutCommand();
    } else {
      // Logout decide by the user
      logOutCommand(reason);
    }
  }

  async function logOutCommand(reason: string = "user") {
    await showLoaderWhile(
      async () => {
        await logoutRequest(reason);

        // Navigate to a logout page during the time required for the CMS to load
        // This is to prevent the user from being redirected to the login page during
        // a few seconds, because he is not connected anymore
        navigate(internalUrls.authentication.logOut);

        logoutEvent();

        await resetAllCommand();

        // Sends the user to the CMS
        const urls = await externalUrls();
        window.location.href = urls.LogoutRedirectUrl;
      },
      {
        key: "useLogoutCommand ",
        value: `API POST - /api/auth/logout_session}`,
      }
    );
  }

  // SSO Logout
  async function bffLogoutCommand() {
    const urls = await externalUrls();
    const bffUserResponse = await bffUserRequest(customerid);

    // The backend can specify a post-logout return URL
    const logoutRedirectUrl = urls.LogoutRedirectUrl;    

    if(bffUserResponse && bffUserResponse.bffUserLogout) {
      const [ logoutUrl, paramsString ] = bffUserResponse.bffUserLogout.split("?");
      const params = new URLSearchParams(paramsString);

      // If specified add the return URL
      logoutRedirectUrl && params.append("returnUrl",  logoutRedirectUrl)
      window.location.href = `${logoutUrl}?${params}`;
    } else {
      // Otherwise redirect to the login
      window.location.href = internalUrls.authentication.login;
    }
  }

  return { handleLogoutCommand, logOutCommand, bffLogoutCommand };
}
