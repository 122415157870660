import { useNavigate } from "react-router";
import { LandingCard, useLoader } from "../../../../shared";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { i18n } from "../../i18n";
import styles from "../../../../styles/shared//LandingPage.module.scss";
import { Interlayer } from "../../../../shared/components/Interlayer/Interlayer";
import { Module } from "../../../../shared/models/Module";
import { ModuleName } from "../../../../shared/components/LandingCard/ModuleName";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

export function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { landingCTAEvent } = useAnalytics();

  function onStart() {
    navigate(appUrls.investorProfile.riskProfile.horizon);
    landingCTAEvent("investor_profile")

  }

  return (
    <Interlayer
      className={styles.Interlayer}
      active={Module.InvestorProfile}
      hideBottomObjective={true}
    >
      <LandingCard
        module={ModuleName.InvestorProfile}
        gridClassName={styles.Grid}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content}
        onStart={onStart}
      />
    </Interlayer>
  );
}
