import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';
import { GetRealEstateResponseModel } from '../models/RealEstateModel';
import { addRealEstate, RealEstateType } from '../patrimonySlice';
import { saveRealEstateRequest } from '../requests/saveRealEstateRequest';

export function useAddRealEstateCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function addRealEstateCommand(type: RealEstateType, realEstate: GetRealEstateResponseModel) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update RealState list localy
      const response = await saveRealEstateRequest(realEstate, customerid);
      dispatch(addRealEstate({type, realEstate: response}));
    }, {key: "addRealEstateCommand", value:`Delay before dispatch addRealEstateCommand on addRealEstate.ts file`});
  }

  return { addRealEstateCommand };
}
