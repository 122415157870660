import { Route, Routes, useLocation } from "react-router-dom";
import { i18n } from "./i18n";
import * as pages from "./pages";
import { ThemeProvider } from "../../shared/components/Theme/ThemeProvider";
import { ModuleColor } from "../../styles/colors";
import { ProgressRoute } from "../../shared/routing/ProgressRoute";
import { useResolver } from "./routing/resolver";
import { globali18n } from "../../i18n";
import { useEffect } from "react";
import { parse } from "query-string";
import { useAppDispatch } from "../../store";
import {
  setBirthdate,
  setProfessionalStatus,
  setSalary,
  setSimulationKey,
} from "./legalPensionSlice";
import { SalaryPeriodicity } from "../../shared/models/SalaryPeriodicity";
import { ProfessionalStatus } from "./models/ProfessionalStatus";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { format } from "date-fns";
import { PageRoute } from "../../shared/routing/PageRoute";


export function LegalPensionModule() {
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const parsedParams = parse(search);

    if (parsedParams.sapiendoSimulationKey) {
      dispatch(
        setSimulationKey(parsedParams.sapiendoSimulationKey as string)
      );
    }

    if (typeof parsedParams.yearOfBirth === "string") {
      const startDate = format(new Date(parsedParams.yearOfBirth), 'yyyy-MM-dd');
      dispatch(setBirthdate(startDate));
    }

    if (parsedParams.monthlyNetSalary) {
      dispatch(
        setSalary({
          amount: parsedParams.monthlyNetSalary as unknown as number,
          frequency: SalaryPeriodicity.Monthly,
        })
      );
    }

    if (parsedParams.professionalStatus) {
      dispatch(
        setProfessionalStatus(
          parsedParams.professionalStatus as ProfessionalStatus
        )
      );
    }
    return;
  }, []);

  const { loading } = useResolver();

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 9,
  };

  const summaryProps = {
    ...moduleProps,
    step: 9,
    subtitle: i18n.categories.synthesis,
  };

  if (loading) {
    return null;
  }

  const {
    LandingPage,
    GenderPage,
    BirthdatePage,
    ChildrenPage,
    CareerStartPage,
    ProfessionalStatusPage,
    CurrentRevenuePage,
    CurrentProgressionPage,
    FutureProgressionPage,
    SummaryPage,
  } = pages;

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.LegalPension,
        backgroundColorLigther: ModuleColor.LegalPensionLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <AnalyticsComponent
              page_title={i18n.landingPage.pageTitle}
              page_path={"/legal-pension"}
              page_type={"homepage"}
              simulator_screen={"Landing Page"}
              simulator_step={""}
                simulator_type={i18n.module}
            >
              <PageRoute
                pageTitle={i18n.landingPage.pageTitle}
                element={<LandingPage />}
              />
            </AnalyticsComponent>
          }
        />
        <Route
          path={`/gender`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={1}
              pageTitle={i18n.genderPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.genderPage.pageTitle}
                  page_path={"/legal-pension/gender"}
                  page_type={"simulateur"}
                  simulator_screen={"gender"}
                  simulator_step={"1/9"}
                    simulator_type={i18n.module}
                >
                  <GenderPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/birthdate`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={2}
              pageTitle={i18n.birthdatePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.birthdatePage.pageTitle}
                  page_path={"/legal-pension/birthdate"}
                  page_type={"simulateur"}
                  simulator_screen={"birthdate"}
                  simulator_step={"2/9"}
                    simulator_type={i18n.module}
                >
                  <BirthdatePage moduleName="legalPension"  analyticStep={"2/9"}/>
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/children`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={3}
              pageTitle={i18n.childrenPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.childrenPage.pageTitle}
                  page_path={"/legal-pension/children"}
                  page_type={"simulateur"}
                  simulator_screen={"children"}
                  simulator_step={"3/9"}
                    simulator_type={i18n.module}
                >
                  <ChildrenPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/career-start`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={4}
              pageTitle={i18n.careerStartPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.careerStartPage.pageTitle}
                  page_path={"/legal-pension/career-start"}
                  page_type={"simulateur"}
                  simulator_screen={"career-start"}
                  simulator_step={"4/9"}
                    simulator_type={i18n.module}
                >
                  <CareerStartPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/status`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={5}
              pageTitle={i18n.professionalStatusPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.professionalStatusPage.pageTitle}
                  page_path={"/legal-pension/status"}
                  page_type={"simulateur"}
                  simulator_screen={"status"}
                  simulator_step={"5/9"}
                    simulator_type={i18n.module}
                >
                  <ProfessionalStatusPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/current-revenue`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={6}
              pageTitle={i18n.currentRevenuePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.currentRevenuePage.pageTitle}
                  page_path={"/legal-pension/current-revenue"}
                  page_type={"simulateur"}
                  simulator_screen={"current-revenue"}
                  simulator_step={"6/9"}
                    simulator_type={i18n.module}
                >
                  <CurrentRevenuePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/current-progression`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={7}
              pageTitle={i18n.currentProgressionPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.currentProgressionPage.pageTitle}
                  page_path={"/legal-pension/current-progression"}
                  page_type={"simulateur"}
                  simulator_screen={"current-progression"}
                  simulator_step={"7/9"}
                    simulator_type={i18n.module}
                >
                  <CurrentProgressionPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/future-progression`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={8}
              pageTitle={i18n.futureProgressionPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.futureProgressionPage.pageTitle}
                  page_path={"/legal-pension/future-progression"}
                  page_type={"simulateur"}
                  simulator_screen={"future-progression"}
                  simulator_step={"8/9"}
                    simulator_type={i18n.module}
                >
                  <FutureProgressionPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/summary`}
          element={
            <ProgressRoute
              showSteps={false}
              {...summaryProps}
              step={9}
              pageTitle={i18n.summaryPage.pageTitle}
              subtitle={globali18n.labels.summary}
              element={
                <AnalyticsComponent
                  page_title={i18n.summaryPage.pageTitle}
                  page_path={"/legal-pension/summary"}
                  page_type={"summary"}
                  simulator_screen={"summary"}
                  simulator_step={"9/9"}
                    simulator_type={i18n.module}
                >
                  <SummaryPage />
                </AnalyticsComponent>
              }
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
