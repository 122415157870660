import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatrimonyModel } from "./models/PatrimonyModel";
import { ProductTypeModel } from "./models/ProductTypeModel";
import { GetSavingAccountResponseModel } from "./models/SavingsAccountModel";
import { SynthesisModel } from "./models/SynthesisModel";
import { GetRealEstateResponseModel } from "./models/RealEstateModel";

export interface PatrimonyState {
  productTypes: ProductTypeModel[];
  answers: PatrimonyModel;
  synthesis: SynthesisModel | null;
  totalAmount: number;
}

export enum SavingAccountType {
  SavingsAccounts = "savingsAccounts",
}

export enum RealEstateType {
  RealEstates = "realEstates",
}

const initialState: PatrimonyState = {
  productTypes: [],
  answers: {
    savingsAccounts: [],
    realEstates: [],
  },
  synthesis: null,
  totalAmount: 0,
};

export const patrimonySlice = createSlice({
  name: "patrimony",
  initialState: initialState,
  reducers: {
    setProductTypes(state, action: PayloadAction<ProductTypeModel[]>) {
      state.productTypes = action.payload;
    },
    setAnswers(state, action: PayloadAction<PatrimonyModel>) {
      state.answers = action.payload;
    },
    addAccount(
      state,
      action: PayloadAction<{
        type: SavingAccountType;
        account: Partial<GetSavingAccountResponseModel>;
      }>
    ) {
      const account = action.payload.account as GetSavingAccountResponseModel;

      state.answers[action.payload.type].push(account);
    },
    editAccount(
      state,
      action: PayloadAction<{
        type: SavingAccountType;
        position: number;
        account: GetSavingAccountResponseModel;
      }>
    ) {
      const { type, account, position } = action.payload;

      // We are using the position here because it is the simplest solution that works whether
      // the objects have been saved or not
      // A comparison based on identifiers would require generating temporary identifiers since
      // they don't exist on unsaved objects
      const existing = state.answers[type][position];

      existing.productTypeId = account.productTypeId;
      existing.currentAmount = account.currentAmount;
      existing.monthlySavings = account.monthlySavings;
    },
    deleteAccount(
      state,
      action: PayloadAction<{ type: SavingAccountType; position: number }>
    ) {
      const { type, position } = action.payload;

      // We are using the position here because it is the simplest solution that works whether
      // the objects have been saved or not
      // A comparison based on identifiers would require generating temporary identifiers since
      // they don't exist on unsaved objects
      state.answers[type].splice(position, 1);
    },
    addRealEstate(
      state,
      action: PayloadAction<{
        type: RealEstateType;
        realEstate: GetRealEstateResponseModel;
      }>
    ) {
      const { type, realEstate } = action.payload;
      realEstate.isMainResidence === undefined ??
        (action.payload.realEstate.isMainResidence = false);

      if (realEstate.isMainResidence === true) {
        state.answers[type].map((e) => (e.isMainResidence = false));
        state.answers[type].push(realEstate);
      } else {
        state.answers[type].push(realEstate);
      }
    },
    editRealEstate(
      state,
      action: PayloadAction<{
        type: RealEstateType;
        position: number;
        realEstate: GetRealEstateResponseModel;
      }>
    ) {
      const { type, realEstate, position } = action.payload;

      // We are using the position here because it is the simplest solution that works
      // whether the objects have been saved or not
      // A comparison based on identifiers would require generating temporary identifiers
      // since they don't exist on unsaved objects
      const existing = state.answers[type][position];

      existing.isMainResidence = realEstate.isMainResidence;
      existing.currentAmount = realEstate.currentAmount;
      existing.name = realEstate.name;

      if (existing.isMainResidence === true) {
        state.answers[type].map((e, i) => {
          if (i !== position) {
            e.isMainResidence = false;
          }
        });

        existing.currentAmount = realEstate.currentAmount;
        existing.name = realEstate.name;
      }
    },
    deleteRealEstate(
      state,
      action: PayloadAction<{ type: RealEstateType; position: number }>
    ) {
      const { type, position } = action.payload;

      // We are using the position here because it is the simplest solution that works whether
      // the objects have been saved or not
      // A comparison based on identifiers would require generating temporary identifiers since
      // they don't exist on unsaved objects
      state.answers[type].splice(position, 1);
    },

    setSynthesis(state, action: PayloadAction<SynthesisModel>) {
      state.synthesis = action.payload;
    },
    resetPatrimony: () => {
      return { ...initialState };
    },
    setTransferAmount(state) {
      let sumRealEstate = state.answers.realEstates.reduce(function (
        accumulator,
        curValue
      ) {
        return accumulator + curValue.currentAmount;
      },
      0);

      let sumFinancial = state.answers.savingsAccounts.reduce(function (
        accumulator,
        curValue
      ) {
        return accumulator + curValue.currentAmount;
      },
      0);

      state.totalAmount = sumFinancial + sumRealEstate;
    },
  },
});

export const {
  resetPatrimony,
  setAnswers,
  setProductTypes,
  addAccount,
  editAccount,
  deleteAccount,
  setSynthesis,
  addRealEstate,
  editRealEstate,
  deleteRealEstate,
  setTransferAmount,
} = patrimonySlice.actions;
export const patrimonyReducer = patrimonySlice.reducer;
