import { globali18n } from "../../../../i18n";
import { CardTitle } from "../../../../shared/components/Card/Card";
import { ModuleColor } from "../../../../styles/colors";
import { i18n } from "../../i18n";
import { ModuleCard } from "../ModuleCard/ModuleCard";
import styles from "./InformationCard.module.scss";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { Module } from "../../../../shared/models/Module";
import { useGoToModule } from "../../../../modules/dashboard/commands/goToModule";
import { assets as Assets } from "../../../../modules/dashboard/Assets";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import classNames from "classnames";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";

interface InformationProps {
  legalPensionAmount: number | null;
  legalPensionStatus: ModuleStatus;
  patrimonyAmount: number | null;
  patrimonyStatus: ModuleStatus;
}

export function InformationCard(props: InformationProps) {
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const { goToModuleCommand } = useGoToModule();
  const { dashboardCardEvent } = useAnalytics();
  const isDashboardStatic = isTenantRecommendationModeStatic();

  function goToLegalPension() {
    goToModuleCommand(Module.LegalPension);
    dashboardCardEvent("Retraite legal");
  }

  function goToPatrimony() {
    goToModuleCommand(Module.Patrimony);
    dashboardCardEvent("Patrimoine");
  }

  return (
    <div className={styles.Container}>
      <CardTitle>{i18n.dashboardPage.income.title}</CardTitle>
      <div className={classNames(styles.CardGrid, isDashboardStatic && styles.CardGridDashboardStatic)}>
        <ModuleCard
          icon={Assets.LegalPensionIcon}
          unCompletedIcon={Assets.LegalPensionIconUncompleted}
          lockIcon={Assets.LegalPensionLock}
          color={ModuleColor.LegalPension}
          title={i18n.moduleNames[Module.LegalPension]}
          amount={props.legalPensionAmount}
          status={props.legalPensionStatus}
          unit={globali18n.labels.eurosPerMonth}
          onClick={goToLegalPension}
        />
        <ModuleCard
          icon={Assets.PatrimonyIcon}
          unCompletedIcon={Assets.PatrimonyIconUncompleted}
          lockIcon={Assets.PatrimonyIconLock}
          color={ModuleColor.Patrimony}
          title={i18n.moduleNames[Module.Patrimony]}
          amount={props.patrimonyAmount}
          status={props.patrimonyStatus}
          unit={globali18n.labels.eurosPerMonth}
          onClick={goToPatrimony}
        />
      </div>
    </div>
  );
}
