import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { globali18n } from "../../../../i18n";
import { i18n } from "../../../../modules/patrimony/i18n";
import { ModalMode } from "../../../../modules/patrimony/models/ModalMode";
import { SavingsAccountProductType } from "../../../../modules/patrimony/models/ProductType";
import { Controller, useForm } from "react-hook-form";
import {
  ButtonStyles,
  ModalOutcome,
  StyledKendoModal,
  useModal,
  ValidationMessages,
  Button,
} from "../../../../shared";
import { getValidationMessages } from "../../../../shared/utilities/getValidationMessages";
import { getFormConfig } from "./formConfig";
import styles from "./SavingModal.module.scss";
import { useRef } from "react";
import {
  NumericTextBox,
  NumericTextBoxHandle,
} from "@progress/kendo-react-inputs";
import { ProductTypeModel } from "../../../../modules/patrimony/models/ProductTypeModel";
import { GetSavingAccountResponseModel } from "../../../../modules/patrimony/models/SavingsAccountModel";

interface FormData {
  identifier: number;
  productTypeId: string;
  currentAmount: number;
  monthlySavings: number;
}

export interface SavingsModalProps {
  mode: ModalMode;
  title: string;
  account: Partial<GetSavingAccountResponseModel>;
  productTypes: ProductTypeModel[];
}

export function SavingsModal(props: SavingsModalProps) {
  const { hideModal } = useModal();
  const currentAmountInput = useRef<NumericTextBoxHandle>(null);
  const monthlySavingsInput = useRef<NumericTextBoxHandle>(null);

  const {
    control,
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: props.account,
  });

  function submit() {
    hideModal(ModalOutcome.Ok, getValues());
    focusButton();
  }

  const t = i18n.savingsModal;

  const config = getFormConfig();

  function focusButton() {
    document.getElementById("add-saving")!.focus();
  }

  const focusTrap = (target: any) => {
    // Need this ugly tricky to fix unfocus bug
    // When form state change after inputChange made by the user
    // the form field loose the focus.
    // toDo Check later way to enhance the solution

    setTimeout(() => {
      target?.current?.focus();
    }, 0);
  };

  return (
    <StyledKendoModal
      title=""
      onClose={() => {
        hideModal(ModalOutcome.Cancel, null);
        focusButton();
      }}
      width="700px"
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <div className={styles.ContentGrid}>
          <div className={styles.ModalTitle}>{t.title}</div>
          <hr />
          <div className={styles.InputGroup}>
            <label className={styles.TitleStyle}>
              {t.productTypeIdentifier.label}
            </label>
            <select
              id={"modal-content"}
              {...register(
                "productTypeId",
                config.productTypeIdentifier.rules
              )}
              className={styles.Select}
            >
              <option key={null}></option>
              {props.productTypes.map((pt) => (
                <option key={pt.identifier} value={pt.identifier}>
                  {i18n.savingsProductType[(pt.identifier as SavingsAccountProductType)]}
                </option>
              ))}
            </select>
            <ValidationMessages
              messages={getValidationMessages(
                "productTypeId",
                errors,
                config.productTypeIdentifier.errorMessages
              )}
            />
          </div>
          <div className={styles.InputGroup}>
            <label>{t.currentAmount.label}</label>
            <span>
              <Controller
                control={control}
                name="currentAmount"
                rules={config.currentAmount.rules}
                render={({ field: { onChange, onBlur, value } }) => (
                  <NumericTextBox
                    ref={currentAmountInput}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      focusTrap(currentAmountInput);
                    }}
                    value={value}
                    spinners={false}
                    format={{ maximumFractionDigits: 0 }}
                  />
                )}
              />{" "}
              {globali18n.labels.euros}
            </span>
            <ValidationMessages
              messages={getValidationMessages(
                "currentAmount",
                errors,
                config.currentAmount.errorMessages
              )}
            />
          </div>
          <div className={styles.InputGroup}>
            <label>{t.monthlySavings.label}</label>
            <span>
              <Controller
                control={control}
                name="monthlySavings"
                rules={config.monthlySavings.rules}
                render={({ field: { onChange, onBlur, value } }) => (
                  <NumericTextBox
                    ref={monthlySavingsInput}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      focusTrap(monthlySavingsInput);
                    }}
                    value={value}
                    spinners={false}
                    format={{ maximumFractionDigits: 0 }}
                  />
                )}
              />{" "}
              {globali18n.labels.euros}
            </span>
            <ValidationMessages
              messages={getValidationMessages(
                "monthlySavings",
                errors,
                config.monthlySavings.errorMessages
              )}
            />
          </div>
        </div>
        <DialogActionsBar>
          <div className={styles.ButtonBox}>
            {props.mode === ModalMode.Edit && (
              <Button
                className={ButtonStyles.Unfill}
                onClick={() => {
                  hideModal(ModalOutcome.Other);
                  focusButton();
                }}
              >
                {globali18n.labels.delete}
              </Button>
            )}
            <Button
              className={ButtonStyles.Unfill}
              onClick={() => {
                hideModal(ModalOutcome.Cancel);
                focusButton();
              }}
            >
              {globali18n.labels.cancel}
            </Button>
            <Button className={ButtonStyles.PrimaryAction} onClick={() => {}}>
              {props.mode === ModalMode.Edit
                ? globali18n.labels.validate
                : globali18n.labels.add}
            </Button>
          </div>
        </DialogActionsBar>
      </form>
    </StyledKendoModal>
  );
}
