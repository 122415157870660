import { Routes, Route } from "react-router-dom";
import { i18n } from "./i18n";
import { RegistrationEmailPage } from "./pages/RegistrationEmailPage/RegistrationEmailPage";
import { RegistrationPage } from "./pages/RegistrationPage/RegistrationPage";
import { RegistrationResendPage } from "./pages/RegistrationResendPage/RegistrationResendPage";
import { ModuleColor } from "@ifs/libs/src/styles/colors";
import { AccountActivationPage } from "./pages/AccountActivationPage/AccountActivationPage";
import { AccoutActivationResendEmailPage } from "./pages/AccoutActivationResendEmailPage/AccoutActivationResendEmailPage";
import { AnalyticsComponent } from "@ifs/libs/src/shared/components/datalayer/AnalyticsComponent";
import { ThemeProvider } from "@ifs/libs/src/shared";
import { PageRoute } from "@ifs/libs/src/shared/routing/PageRoute";


export function RegistrationModule() {
  
  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.LegalPension,
        backgroundColorLigther: ModuleColor.LegalPensionLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <PageRoute
              pageTitle={i18n.registrationPage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.registrationPage.pageTitle}
                page_path={'/register'}
                page_type={'register'}
                simulator_screen={'register'}
                simulator_step={''}
                simulator_type={"register"}
              >
                <RegistrationPage />
                </AnalyticsComponent>}
            />
          }
        />
        <Route
          path={`/email`}
          element={
            <PageRoute
              pageTitle={i18n.registrationEmailPage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.registrationEmailPage.pageTitle}
                page_path={'/register/email'}
                page_type={'register'}
                simulator_screen={'register'}
                simulator_step={''}
                simulator_type={"register"}
              >
                <RegistrationEmailPage />
                </AnalyticsComponent>}
            />
          }
        />
        <Route
          path={`/email/resend`}
          element={
            <PageRoute
              pageTitle={i18n.registrationResendPage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.registrationResendPage.pageTitle}
                page_path={'/register/email/resend'}
                page_type={'register'}
                simulator_screen={'register'}
                simulator_step={''}
                simulator_type={"register"}
              >
                <RegistrationResendPage />
                </AnalyticsComponent>}
            />
          }
        />
        <Route
          path={`/validate_initialized_account`}
          element={
            <PageRoute
              pageTitle={i18n.registrationEmailPage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.registrationEmailPage.pageTitle}
                page_path={'/register/validate_initialized_account'}
                page_type={'register'}
                simulator_screen={'register'}
                simulator_step={''}
                simulator_type={"register"}
                
              >
                <AccountActivationPage />
                </AnalyticsComponent>}
            />
          }
        />
        <Route
          path={`/email/resend_initialized_account_validation_email`}
          element={
            <PageRoute
              pageTitle={i18n.registrationResendPage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.registrationResendPage.pageTitle}
                page_path={'/register/email/resend_initialized_account_validation_email'}
                page_type={'register'}
                simulator_screen={'register'}
                simulator_step={''}
                simulator_type={"register"}
                
              >
                <AccoutActivationResendEmailPage /></AnalyticsComponent>}
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
