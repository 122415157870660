import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  NumericTextBox,
  NumericTextBoxFocusEvent,
  NumericTextBoxHandle
} from '@progress/kendo-react-inputs';
import { Controller, useForm } from 'react-hook-form';
import { globali18n } from '../../../i18n';
import { ButtonStyles, ModalOutcome, useModal, ValidationMessages, Button } from '../../';
import { getValidationMessages } from '../../utilities/getValidationMessages';
import styles from './EditModal.module.scss';
import { useRef } from 'react';

interface FormData {
  value: number | null;
}

interface EditModalProps {
  title: string;
  value: number | null;

  min: number;
  minErrorMessage?: string;
  max: number;
  maxErrorMessage?: string;
}

export function EditModal(props: EditModalProps) {
  const numericInput = useRef<NumericTextBoxHandle>(null);
  const { hideModal } = useModal();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      value: props.value
    }
  });

  const rules = {
    min: props.min,
    max: props.max
  };

  const errorMessages = {
    min: props.minErrorMessage ?? globali18n.errors.min(props.min),
    max: props.maxErrorMessage ?? globali18n.errors.max(props.max)
  };

  function submit() {
    const value = getValues().value;

    // -0 would pass a >= 0 test, since -0 === 0
    // So we prevent this situation by returning the absolute value
    const safe = value === 0 ? Math.abs(value) : value;

    hideModal(ModalOutcome.Ok, safe);
    document.getElementById(props.title + '-amount')!.focus()
  }

  // Try to focus the numeric input when the modal is opened
  // Then add keyup even to submit on "Enter" key
  setTimeout(() => {
    numericInput?.current?.focus();
    if (!numericInput?.current?.element || numericInput.current.element.onkeyup) return;
    numericInput.current.element.onkeyup = (e: KeyboardEvent) => {
      e.key === 'Enter' && handleSubmit(submit)();
    };
  }, 100);

  return (
      <Dialog title={props.title} onClose={() => hideModal(ModalOutcome.Cancel, null)}>
        <div className={styles.ContentGrid}>
          <span>
            <Controller
              control={control}
              name="value"
              rules={rules}
              render={({ field: { onChange, onBlur, value } }) => (
                <NumericTextBox
                  ref={numericInput}
                  value={value}
                  spinners={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={globali18n.labels.amount}
                  format={{ maximumFractionDigits: 0 }}
                  // On focus, select all the text to allow fast typing
                  onFocus={(event: NumericTextBoxFocusEvent) => event?.target?.element?.select()}
                />
              )}
            />{' '}
            {globali18n.labels.euros}
          </span>
          <div className={styles.validationWrapper}>
            <ValidationMessages messages={getValidationMessages('value', errors, errorMessages)} />
          </div>
        </div>
        <DialogActionsBar>
          <Button
            className={ButtonStyles.Unfill}
            onClick={() => hideModal(ModalOutcome.Cancel)}
          >
            {globali18n.labels.cancel}
          </Button>
          <Button className={ButtonStyles.PrimaryAction} onClick={handleSubmit(submit)}>
            Ok
          </Button>
        </DialogActionsBar>
      </Dialog>
  );
}
