import classNames, { Argument } from 'classnames';
import { CSSProperties } from 'react';
import styles from './Container.module.scss';

interface ContainerProps {
  className?: Argument;
  style?: CSSProperties;
}

export function Container(props: React.PropsWithChildren<ContainerProps>) {
  return (
    <div style={props.style} className={classNames('container-md', styles.Container, props.className)}>
      {props.children}
    </div>
  );
}
