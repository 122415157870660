import styles from "./SummaryCard.module.scss";
import { i18n } from "../../../../i18n";
import { ChosenScenario } from "../../../../../../shared/models/ChosenScenario";
import { AgeTypeResult } from "../../../../models/ComputationOutputModel";
import { Button, ButtonStyles, Card } from "../../../../../../shared";
import { globali18n } from "../../../../../../i18n";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartArea,
} from "@progress/kendo-react-charts";
import classNames from "classnames";
import { amount, percentage } from "../../../../../../shared/utilities/intl";
import {
  wintergreen,
  danger_red,
  keppel,
} from "../../../../../../styles/colors";
import { enterKey } from "../../../../../../shared/commands/keyboardActionKey";

interface SummaryCardProps {
  scenario: ChosenScenario;
  value: AgeTypeResult;
  selected: boolean;
  onSelect: () => void;
  remainingColor: string;
}

export function SummaryCard(props: SummaryCardProps) {
  const {
    lastSalaryAmount,
    pensionAmount,
    lossPercentage,
    age: { year, month },
  } = props.value;

  const pensionLossAmount = lastSalaryAmount - pensionAmount;

  let label = `Perte de revenu (${percentage(lossPercentage)})`;

  if (pensionAmount > lastSalaryAmount) {
    label = "Revenus complémentaires";
  }

  const chartData = [
    {
      name: "data-series",
      min: 0,
      max: lastSalaryAmount,
      label: "Revenu de fin de carrière",
      color: wintergreen,
    },
    {
      colorField: keppel,
      min: 0,
      max: pensionAmount,
      label: "Pension légale",
      color: keppel,
    },
    {
      border: { color: danger_red },
      min: lastSalaryAmount,
      max: pensionAmount,
      label: label,
      color: danger_red,
    },
  ];

  const colorLabels = [wintergreen, keppel, danger_red];

  const selectedAge = (
    <>
      {" "}
      {globali18n.labels.and} <span className={styles.AgeFigure}>{month}</span>{" "}
      {globali18n.labels.timePeriodPlural.months}
    </>
  );

  return (
    <Card
      className={styles.Card}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        enterKey(e, props.onSelect);
      }}
      onClick={() => {
        props.onSelect();
      }}
      selected={props.selected}
    >
        <div className={styles.CTA}>
          <Button
            className={classNames(
              ButtonStyles.LegalPensionAge,
              styles.ValidateButton,
              props.selected ? styles.ButtonSelected : ""
            )}
          >
            Je sélectionne {year} {globali18n.labels.timePeriodPlural.years}
            {props.value.age.month !== 0 && selectedAge}
          </Button>
        </div>
        <div className={styles.Details}>
          <div className={styles.TitleGroup}>
            <h6 className={styles.Title}>
              {i18n.summaryPage.cardTitle[props.scenario]}
            </h6>
            <div className={styles.Age}>
              <span className={styles.AgeFigure}>{year}</span>{" "}
              {globali18n.labels.timePeriodPlural.years}
              {props.value.age.month !== 0 && selectedAge}
            </div>
          </div>
          <div className={styles.Chart}>
            <Chart
              ref={(chart) => {
                const svg =
                  chart && chart.element && chart.element.querySelector("svg");
                if (!svg) return;
                svg.setAttribute("role", "img");
                svg.setAttribute("aria-hidden", "true");
              }}
              style={{ height: "100px" }}
              transitions={false}
            >
              <ChartArea background={"transparent"} />
              <ChartSeries>
                <ChartSeriesItem
                  type="rangeBar"
                  data={chartData}
                  fromField="min"
                  toField="max"
                  gap={0.5}
                ></ChartSeriesItem>
              </ChartSeries>
              <ChartAxisDefaults
                line={{ visible: false }}
                majorGridLines={{ visible: false }}
                labels={{ visible: false }}
              ></ChartAxisDefaults>
            </Chart>
            <div className={styles.ChartLegend}>
              {chartData.map((item, index) => (
                <div key={index} className={styles.Labels}>
                  <div
                    style={{ backgroundColor: colorLabels[index] }}
                    className={styles.LabelCube}
                  ></div>
                  <span className={styles.Amount}>
                    {index === 2 && pensionAmount < lastSalaryAmount && "-"}
                    {index === 2
                      ? amount(Math.abs(pensionLossAmount))
                      : amount(item.max)}
                  </span>{" "}
                  : <span className={styles.AmountLabel}>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
    </Card>
  );
}
