export enum ModuleStatus {
  Locked = "Locked",
  Available = "Available",
  Completed = "Completed",
  CompletedAndLocked = "CompletedAndLocked", // The Profilling module can be completed but blocked because the dependent modules must be completed again (for example the update of the Taxation questionnaire)
  DashboardUnavailable = "DashboardUnavailable", // Some Module could now be unavailable on dashboard but could be seen by other page
  DashboardUnavailableAndRouteGuardLocked = "DashboardUnavailableAndRouteGuardLocked" // Some Module could now be unavailable on dashboard and also shiould be locked and unaccesible from tapping uri on broswer
}

export enum RiskProfile {
  Conservateur = "Conservateur",
  Prudent = "Prudent",
  Equilibre = "Equilibre",
  Dynamique = "Dynamique",
  Offensif = "Offensif"
}



export interface InvestorProfileStatus {
  status: ModuleStatus,
  riskProfile: RiskProfile
}
