import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';
import { setAnswers, setResults } from '../legalPensionSlice';
import { getLastValidatedRequest } from '../requests/getLastValidatedRequest';
import { getComputationFromValidateRequest } from '../requests/getComputationFromValidateRequest';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { customerid } = useParams()

  useEffect(() => {
    showLoaderWhile(async () => {
      // If no housing question has been answered we can hypothesize that the whole questionnaire is empty
      const lastValidated = await getLastValidatedRequest(customerid);

      if (lastValidated === null) {
        // The user has never validated anything, there is nothing to restore
        setLoading(false);
        return;
      }

      const validationId = lastValidated.id;

      await restoreAnswerAndResultStore(validationId);

      setLoading(false);
    }, {key: "legalPensionResolver_getLastValidatedRequest", value:`API GET - ${apiUrls(customerid).legalPension.validations}`});
  }, []);

  async function restoreAnswerAndResultStore(validationId: string) {
    const results = await getComputationFromValidateRequest(validationId, customerid);
    if(results) {
      dispatch(setResults(results));
      dispatch(setAnswers(results.inputs))
    }
  }

  return { loading };
}
