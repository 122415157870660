import { amount } from "../../../../shared/utilities/intl";
import { globali18n } from "../../../../i18n";

const minAmount = 0;
const maxAmount = 9999999;

const rules = {
  rules: { required: true, min: minAmount, max: maxAmount },
  errorMessages: {
    required: globali18n.errors.required,
    min: globali18n.errors.min(amount(minAmount)),
    max: globali18n.errors.max(amount(maxAmount)),
  },
};

export function getFormConfig() {
  const formConfig = {
    productTypeIdentifier: {
      rules: { required: true },
      errorMessages: {
        required: globali18n.errors.required,
      },
    },
    name: {
      rules: { required: true },
      errorMessages: {
        required: globali18n.errors.required
      }
    },
    currentAmount: {
      ...rules,
    },
    monthlySavings: {
      ...rules,
    },
  };

  return formConfig;
}
