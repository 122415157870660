import classNames from "classnames";
import { useComputeRecommendationCommand } from "../../../../modules/recommendation/commands/computeRecommendationCommand";
import { i18n } from "../../../../modules/recommendation/i18n";
import {
  AgeSelectionModel,
  ParametersInputModel
} from "../../../../modules/recommendation/models/RecommendationModel";
import {
  setModel,
  setParameters,
} from "../../../../modules/recommendation/recommendationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ButtonStyles,
  Card,
  Button,
  SvgWrapperComponent,
} from "../../../../shared";
import { useAppSelector } from "../../../../store";
import { ParamsGroup } from "../ParamsGroup/ParamsGroup";
import styles from "./ParametersCard.module.scss";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

import { ReactComponent as UpdateIcon } from "../../pages/RecommendationPage/advanced/images/Update.svg";
import { ReactComponent as ValidateIcon } from "../../pages/RecommendationPage/advanced/images/Validate.svg";
import { assets as Assets } from "../../Assets";
import { useNavigate } from "react-router-dom";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { useResetParametersCommand } from "../../../../modules/recommendation/commands/resetParametersCommand";
import { ChosenScenario } from "../../../../shared/models/ChosenScenario";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";

interface ParametersCardProps {
  onReset: () => void;
}

export function ParametersCard(props: ParametersCardProps) {
  const model = useAppSelector((s) => s.recommendation.model)!;
  const parameters = useAppSelector((s) => s.recommendation.parameters)!;
  const legalPensionComputation = useAppSelector((s) => s.legalPension)!;
  const dispatch = useDispatch();
  const { computeRecommendationCommand } = useComputeRecommendationCommand();
  const { resetParametersCommand } = useResetParametersCommand();
  const { getRightRecommendationPageUri } = useGlobalCommand();
  const { recommendationEvent, resetRecommendationEvent } = useAnalytics();
  const navigate = useNavigate();
  const appUrls = useAppUrls();

  const [params, setParams] = useState(parameters);

  const [updateDisabled, setUpdateDisabled] = useState<boolean>(false);
  const [retirementScenarios, setRetirementScenarios] = useState<AgeSelectionModel[]>([]);

  const [valueParams, setValueParams] = useState(model);

  useEffect(() => {
    (async () => {

      const lastLegalPensionValidation = legalPensionComputation.lastValidateRequest;
      const { fullRateAgeResult, legalAgeResult, maxAgeResult } = legalPensionComputation.results!.results;
      let tempAge : AgeSelectionModel[] = [];

      if (fullRateAgeResult !== null) {
        tempAge.push({
          isSelected:
          legalPensionComputation.lastValidateRequest!.chosenScenario ===
          ChosenScenario.FullRateAge,
          retirementScenario: ChosenScenario.FullRateAge,
          age: {
            year: fullRateAgeResult.age.year,
            month: fullRateAgeResult.age.month,
          },
        });
      }

      if (legalAgeResult !== null) {
        tempAge.push({
          isSelected:
            lastLegalPensionValidation!.chosenScenario ===
            ChosenScenario.LegalAge,
          retirementScenario: ChosenScenario.LegalAge,
          age: {
            year: legalAgeResult.age.year,
            month: legalAgeResult.age.month,
          },
        });
      }

      if (maxAgeResult !== null) {
        tempAge.push({
          isSelected:
          lastLegalPensionValidation!.chosenScenario === ChosenScenario.MaxAge,
          retirementScenario: ChosenScenario.MaxAge,
          age: {
            year: maxAgeResult.age.year,
            month: maxAgeResult.age.month,
          },
        });
      }
      
      setRetirementScenarios(tempAge);
    })();
  }, [parameters]);

  useEffect(() => {
    setParams(parameters);
  }, [parameters]);

  const t1 = i18n.recommendationPage.advancedSummary;

  function change(params: ParametersInputModel) {
    setParams(params);
  }

  async function update() {
    let tempOb = params;
    if (
      tempOb.initialInvestmentAmount !== parameters.initialInvestmentAmount ||
      tempOb.recurringInvestmentAmount !==
        parameters.recurringInvestmentAmount ||
      tempOb.retirementScenario !== parameters.retirementScenario
    ) {
      tempOb.overrideGamificationParameters = true;
    }

    const output = await computeRecommendationCommand(tempOb);
    dispatch(setParameters(params));
    dispatch(setModel(output));

    recommendationEvent(params, output, "saving_solution_update");
    navigate(getRightRecommendationPageUri());
  }

  async function reset() {
    resetRecommendationEvent();

    await resetParametersCommand();
    setValueParams(model);
    navigate(getRightRecommendationPageUri())
  }

  function changeButton(status: boolean) {
    setUpdateDisabled(status);
  }

  const t = i18n.recommendationPage;

  return (
    <Card className={styles.Card}>
      <ParamsGroup
        value={params}
        onChange={change}
        retirementScenarios={retirementScenarios}
        parameters={valueParams.parameters}
        onReset={props.onReset}
        changeButtonStatus={changeButton}
      />

      <SvgWrapperComponent ariaHidden={true}>
        <Assets.MiddleImage
          className={classNames(styles.XlOnly, styles.SummaryMiddelImage)}
        />
      </SvgWrapperComponent>

      <hr className={styles.Separator} />

      <div className={styles.ButtonContainer}>
        <Button
          className={classNames(styles.ResetLink, ButtonStyles.Unfill)}
          onClick={reset}
        >
          <SvgWrapperComponent>
            <UpdateIcon className={styles.ResetButtonIcon} />
          </SvgWrapperComponent>

          <span className={styles.Label}>
            {t1.gamifyComponent.ctas.resetLink}
          </span>
        </Button>

        <Button
          className={classNames(ButtonStyles.PrimaryAction)}
          onClick={update}
          disabled={updateDisabled}
        >
          <span className={styles.ResetButton}>
            <SvgWrapperComponent>
              <ValidateIcon className={styles.UpdateIcon} />
            </SvgWrapperComponent>

            <span className={styles.Label}>
              {t1.gamifyComponent.ctas.updateButton}
            </span>
          </span>
        </Button>
      </div>
      <div className={styles.Footnote}> {t.footnote} </div>
    </Card>
  );
}
