import { useNavigate } from 'react-router-dom';
import { useLoader } from '@ifs/libs';
import { resendValidationEmailRequest } from '../requests/resendValidationEmailRequest';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';

export function useResendValidationEmail() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();

  function resendValidationEmailCommand(email: string) {
    showLoaderWhile(async () => {
      await resendValidationEmailRequest(email);
      navigate({pathname: internalUrls.registration.email,search: new URLSearchParams({ email }).toString()});
    }, {key: "useResendValidationEmail", value:`API POST - /api/auth/resend_validation_email}`});
  }

  return { resendValidationEmailCommand };
}
