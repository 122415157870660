import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardModel } from "./models/DashboardModel";

export interface DashboardState {
  model: DashboardModel | null;
  extraMetadata: DashboardExtraMetadata;
  presented: number;
  initialized: boolean;
}

interface DashboardExtraMetadata {
  householdMaximumTaxDeduction: number | null;
}

const initialState: DashboardState = {
  initialized: false,
  presented: 0,
  model: {
    appointment: null,
    pensionNeedMonthlyAmount: 0,
    legalPensionMonthlyAmount: 0,
    patrimonyMonthlyAmount: 0,
    lifeProjectsMonthlyAmount: 0,
    isReportAvailable: false,
    isTaxationCompleted: false,
    isInvestorProfileCompleted: true,
    profiling: {
      isExpired: false,
      riskProfileTextId: null,
    },
  },
  extraMetadata: { householdMaximumTaxDeduction: null },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboardPresented(state, action: PayloadAction<number>) {
      state.presented = action.payload + 1;
    },
    setModel(state, action: PayloadAction<DashboardModel>) {
      state.model = action.payload;
      state.initialized = true;
      if (state.model.profiling) {
        state.model.profiling = {
          isExpired: action.payload.profiling!.isExpired,
          riskProfileTextId: action.payload.profiling!.riskProfileTextId,
        };
      } else {
        state.model.profiling = null;
      }
    },
    setExtraMetadata(state, action: PayloadAction<DashboardExtraMetadata> ) {
      state.extraMetadata = action.payload;
    },
    resetDashboard: () => {
      return { ...initialState };
    },
  },
});

export const { setModel, setExtraMetadata, resetDashboard, setDashboardPresented } =
  dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
