import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { Scenario } from "../models/RiskProfileEnum";

export async function setPreferedRiskProfile(
  customerId: string | undefined,
  globalRiskProfileIdentifier: string,
  scenario: Scenario
) {

  const apiUrl = apiUrls(
    customerId
  ).investorProfile.riskProfile.setPreferedRiskProfile(
    globalRiskProfileIdentifier
  );

  const response = await axios.put(apiUrl, {
    "preferredProfileTextId": scenario
  });

  const data = response.data;

  return data;
}
