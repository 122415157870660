import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalCommand } from "../../../shared/commands/useGlobalCommand";
import { useAppUrls } from "../../../shared/utilities/urls";

export function RecommendationGuardedRoute({ children }: any) {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { pathname } = useLocation();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();

  const [renderChildren, setRenderChildren] = useState(false);

  async function checkCanAccessRoute() {
    const isRecommendationStatic = isTenantRecommendationModeStatic();

    if (isRecommendationStatic) {
      if (pathname.includes("/products") || pathname.includes("/report")) {
        // Route is accessible
        setRenderChildren(true);
        return;
      }
      // Route not accessible
      navigate(appUrls.recommendationProducts);
      return;
    } else {
      // Recommendation is personalized mode
      // Cannot access to the product table page
      if (pathname.includes("/products")) {
        navigate(appUrls.recommendation);
        return;
      }
      // Route is accessible
      setRenderChildren(true);
      return;
    }
  }

  useEffect(() => {
    checkCanAccessRoute();
  }, [pathname]);

  if (renderChildren) {
    return children;
  }

  return null;
}
