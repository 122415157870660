import { useAppDispatch } from "../../../store";
import { setGamifiedValues } from "../pensionNeedSlice";
import { getLastValidatedRequest } from "../requests/getLastValidatedRequest";

export function useRestoreData() {
  const dispatch = useAppDispatch();
  
  async function restoreData(customerid?: string) {
    const data = await getLastValidatedRequest(customerid);

    if(data) {
      dispatch(setGamifiedValues(data.categoriesDetails));
    }
   
  }

  return { restoreData };
}
