import classNames from "classnames";
import { i18n } from "../../../../../modules/recommendation/i18n";
import { RecommendationProductInformation } from "../ProductsTable";
import styles from "../ProductsTable.module.scss";
import { amount as formatAmount } from "../../../../../shared/utilities/intl";
import {
  ExcludedScopes,
  RecommendationOutputModel,
  Transaction,
} from "../../../../../modules/recommendation/models/RecommendationModel";
import { ProductTypeIdentifier } from "../../../../../modules/recommendation/models/product-type-identifier.enum";

const t = i18n.recommendationPage.productsTableComponent;
const productsI18n = i18n.products;

interface AmountContainerProps {
  product: RecommendationProductInformation;
  title: string;
  model: RecommendationOutputModel;
  type: string;
  minimum: ExcludedScopes | undefined;
}

export function AmountContainer(props: AmountContainerProps) {
  function finData(identifier: ProductTypeIdentifier) {
    const item = props.model.transactions.productTypeTransactions.find(
      (e: Transaction) => e.productTypeId === identifier
    );

    if (!item) {
      return {
        transactionAmount: 0,
        monthlyTransactionAmount: 0,
      };
    }
    return item;
  }

  let lock =
    props.type === "initial"
      ? props.product.parcialExclude.initialLock
      : props.product.parcialExclude.monthlyLock;
  const finDataProps =
    props.type === "initial"
      ? finData(props.product.productTypeIdentifier)!.transactionAmount
      : finData(props.product.productTypeIdentifier)!.monthlyTransactionAmount;

  return (
    <div className={styles.AmountContainer}>
      <div className={classNames(styles.Label, styles.MobileOnly)}>
        {props.type === "initial" ? t.headers.openInvestment : t.headers.monthlyInvestment}
      </div>
      <div
        className={classNames(
          styles.ProductLineInformations,
          styles.AmountInformation
        )}
      >
        <div className={styles.ValueContainer}>
          {lock.isExclude && !props.product.fullExclude.isExclude ? (
            <div className={styles.AmountWarning}>
              {productsI18n.excludeReason[lock.reason!]}
            </div>
          ) : (
            <div className={styles.Amount}>
              {formatAmount(finDataProps || 0)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
