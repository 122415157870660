import { useNavigate } from 'react-router-dom';

import { Container, PageTitle, SvgWrapperComponent, useAnalytics } from '@ifs/libs';
import { useSearchParams } from 'shared/utilities/useSearchParams';

import { i18n } from '../../i18n';
import { useResendValidationEmail } from '../../../registration/commands/resendValidationEmailCommand';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import styles from './RegistrationEmailPage.module.scss';
import classNames from 'classnames';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { Button, ButtonStyles } from '@ifs/libs';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { globali18n } from '@ifs/libs/src/i18n';

export function RegistrationEmailPage() {
  const email = useSearchParams().get('email')!;
  const { resendValidationEmailCommand } = useResendValidationEmail();
  const navigate = useNavigate();
  const { activationEvent } = useAnalytics();

  function resend() {
    activationEvent(i18n.registrationEmailPage.resend);
    resendValidationEmailCommand(email);
  }

  function goToLogin() {
    activationEvent(i18n.registrationEmailPage.goToLogin);
    navigate(internalUrls.authentication.login);
  }

  function cancel() {
    activationEvent(globali18n.labels.back);
    navigate(-1);
  }

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.registrationEmailPage.title}</PageTitle>
      <SvgWrapperComponent ariaHidden={true}>
        <SharedAssets.Mailbox className={ModuleStyles.Mailbox} />
      </SvgWrapperComponent>
      <Button
        className={classNames(ButtonStyles.PrimaryAction, styles.Button)}
        onClick={goToLogin}
      >
        {i18n.registrationEmailPage.goToLogin}
      </Button>
      <Button
        className={classNames(ButtonStyles.Unfill, styles.Button)}
        onClick={cancel}
      >
        {globali18n.labels.back}
      </Button>
      <div className={styles.ResendGroup}>
        <button
          className={styles.LoginLink}
          onClick={resend}
        >
          {i18n.registrationEmailPage.resend}
        </button>
        <p className={styles.SmallText}>{globali18n.labels.spamWarning}</p>
      </div>
      <BackgroundImage />
    </Container>
  );
}
