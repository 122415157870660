import { UseFormRegisterReturn } from 'react-hook-form';
import commonStyles from './TextInput.module.scss';
import specificStyles from './TextInputPassword.module.scss';
import { ReactComponent as Checkmark } from './images/checkmark.svg';
import { ReactComponent as EyeIcon } from './images/eye-icon.svg';
import { ReactComponent as EyeInvisbleIcon } from './images/eye-invisible-icon.svg';
import classNames from 'classnames';
import { useState } from 'react';
import { SvgWrapperComponent } from '../SvgWrapper/SvgWrapperComponent';
import { globali18n } from '../../../i18n';
import { enterKey } from '../../../shared/commands/keyboardActionKey';

interface TextInputPasswordProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputError?: boolean;
  register?: UseFormRegisterReturn;
}

export function TextInputPassword({
  className,
  inputError,
  register,
  ...rest
}: TextInputPasswordProps) {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  function togglePasswordShow(): void {
    setIsPasswordShow(!isPasswordShow);
  }
  
  return (
    <div className={commonStyles.Grid}>
      <input
        type={isPasswordShow ? 'text' : 'password'}
        className={classNames(commonStyles.Input, `${inputError ? specificStyles.InputError : ''}`, className)}
        {...rest}
        {...register}
        required
        aria-required={'true'}
      />
      <span
        className={specificStyles.Icon}
        onClick={togglePasswordShow}
        onKeyDown={(e: any) => {
          enterKey(e,  togglePasswordShow);
        }}
      >
        {!isPasswordShow && (
          <SvgWrapperComponent ariaLabel={globali18n.ariaLabel.passwordShow}>
            <EyeIcon />
          </SvgWrapperComponent>
        )}
        {isPasswordShow && (
          <SvgWrapperComponent ariaLabel={globali18n.ariaLabel.passwordHide}>
            <EyeInvisbleIcon />
          </SvgWrapperComponent>
        )}
      </span>
      <SvgWrapperComponent ariaHidden={true}>
        <Checkmark className={commonStyles.Checkmark} />
      </SvgWrapperComponent>
    </div>
  );
}
