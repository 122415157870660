import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { ChosenScenario } from '../../../shared/models/ChosenScenario';
import { ValidatedLegalPensionModel } from '../models/validation/ValidatedLegalPensionModel';

export async function validateRequest(
  computationId: string,
  chosenScenario: ChosenScenario,
  customerid?: string
): Promise<ValidatedLegalPensionModel> {
  const model: ValidationInputModel = {
    computationId,
    chosenScenario
  };
  const apiUrl = apiUrls(customerid).legalPension.validations;
  const response = await axios.post<ValidatedLegalPensionModel>(apiUrl, model);
  return response.data;
}

interface ValidationInputModel {
  computationId: string;
  chosenScenario: ChosenScenario;
}
