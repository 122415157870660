import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';
import { useUpgradeAmountCommand } from '../commands/upgradeAmountCommand';
import { resetLifeProjects, setAnswers } from '../lifeProjectsSlice';
import { getProjectsRequest } from '../requests/getProjectsRequest';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { upgradeAmountCommand } = useUpgradeAmountCommand();
  const { customerid } = useParams()

  async function setup() {
    showLoaderWhile(async () => {
      const model = await getProjectsRequest(customerid);

      if (model !== null) {
        dispatch(setAnswers(model));
      }

      upgradeAmountCommand();
    }, {key: "lifeProjectsResolver_getProjectsRequest", value:`API GET : ${apiUrls(customerid).lifeProjects.get}`});
  }

  async function cleanup() {
    dispatch(resetLifeProjects());
  }

  useEffect(() => {
    setup();
    setLoading(false);

    return () => {
      cleanup();
      setLoading(true);
    };
  }, []);

  return { loading };
}
