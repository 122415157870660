import { useLoader } from '../../../shared';
import { useAppDispatch } from 'store';
import { editAccount, SavingAccountType } from '../patrimonySlice';
import { GetSavingAccountResponseModel } from '../models/SavingsAccountModel';
import { updateSavingAccountRequest } from '../requests/updateSavingAccountRequest';
import { useParams } from 'react-router-dom';

export function useEditAccountCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function editAccountCommand(
    type: SavingAccountType,
    position: number,
    account: GetSavingAccountResponseModel
  ) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update Account list localy
      await updateSavingAccountRequest(account.id, account, customerid)
      dispatch(editAccount({ type, position, account }));
    }, {key: "useEditAccountCommand", value:`Delay before dispatch editAccountCommand on editAccount.ts file`});
  }

  return { editAccountCommand };
}
