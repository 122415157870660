import styles from './ValidationMessagesPassword.module.scss';
import { i18n } from '../../../shared/i18n';
import { PasswordErrors } from '../../../shared/models/PasswordErrors';

interface ValidationMessagesProps {
  errors: string[];
}

export function ValidationMessagesPassword(props: ValidationMessagesProps) {
  return (
    <ul>
      {Object.values(PasswordErrors).map((item, index) => (
        <li key={index} className={props.errors.includes(item) ? styles.NotOk : styles.Ok}>
          {i18n.passwordConfirmInputGroup.errors[item]}
        </li>
      ))}
    </ul>
  );
}
