import {
  AdvisorCustomerDetail,
  CustomerDetail,
} from "../../../../../modules/pension-need/models/request/getBirthdateRequest.model";
import { birthdateRequirement } from "../../../../../modules/pension-need/commands/birthdateRequirementCommand";
import { i18n } from "../../../i18n";
import { moneyIcon } from "../../../images";
import { DefaultScale } from "../../../models/DefaultScale";
import { HealthType } from "../../../models/health/health-type.enum";
import {
  setHealthUnexpectedDefinedValue,
  setHealthUnexpectedScaleValue,
} from "../../../pensionNeedSlice";
import { createPensionNeedPage } from "../../createPensionNeedPage";
import { EComputeCategoryId } from "../../../../../modules/pension-need/models/computation/ComputeCategory.enum";

export const HealthUnexpectedPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) =>
    q.health.find((c) => c.typeId === HealthType.Unexpected)?.scale ?? null,
  definedValueSelector: (q) =>
    q.health.find((c) => c.typeId === HealthType.Unexpected)?.definedValue ??
    null,
  i18nFn: (i18n) => i18n.healthUnexpectedPage,
  scaleValueReducer: setHealthUnexpectedScaleValue,
  definedValueReducer: setHealthUnexpectedDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.healthUnexpectedPage.answer1,
      value: DefaultScale.NotAtAll,
    },
    {
      icon: moneyIcon.Money2,
      label: i18n.healthUnexpectedPage.answer2,
      value: DefaultScale.ALittle,
    },
    {
      icon: moneyIcon.Money3,
      label: i18n.healthUnexpectedPage.answer3,
      value: DefaultScale.ALot,
    },
  ],
  nextPage: async (
    scaleValue,
    definedValue,
    state,
    customerid,
    answerBirthdate
  ) => {
    const { birthdateRequirementCommand } = birthdateRequirement();
    const results = await birthdateRequirementCommand(customerid);
    const birthDate =
      (results as CustomerDetail)?.birthDate ||
      (results as AdvisorCustomerDetail)?.information?.birthDate;

    if (!birthDate) return { route: "../birthdate" };
    if (!!answerBirthdate) {
      await answerBirthdate(new Date(birthDate), true, true);
    }
    return { compute: true };
  },
});
