export enum TransportType {
  Car = 'Car',
  PublicTransportation = 'PublicTransportation',
  Motorbike = 'Motorbike',
  Bike = 'Bike'
}

export const transportTypes = [
  TransportType.Car,
  TransportType.PublicTransportation,
  TransportType.Motorbike,
  TransportType.Bike
];
