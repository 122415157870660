import classNames, { Argument } from "classnames";
import { ReactComponent as ChevronIcon } from "../images/chevron.svg";

import {
  houseImage,
  hyperactiveImage,
  globetrotterImage,
} from "../images/index";

import styles from "./PersonaCard.module.scss";
import { i18n } from "../../../i18n";
import { Persona } from "../PersonaEnum";
import { SvgWrapperComponent } from "../../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { globali18n } from "../../../../../i18n";

interface PersonaCardProps {
  persona: Persona;
  className: Argument;
  onClick: () => void;
}

const topImages = {
  [Persona.Family]: (
    <img
      src={houseImage}
      aria-hidden="true"
      alt={i18n.personaPage.alts[Persona.Family]}
      className={styles.RoundImage}
    />
  ),
  [Persona.Hyperactive]: (
    <img
      src={hyperactiveImage}
      aria-hidden="true"
      alt={i18n.personaPage.alts[Persona.Hyperactive]}
      className={styles.RoundImage}
    />
  ),
  [Persona.Globetrotter]: (
    <img
      src={globetrotterImage}
      aria-hidden="true"
      alt={i18n.personaPage.alts[Persona.Globetrotter]}
      className={styles.RoundImage}
    />
  ),
};

const chipList = {
  [Persona.Family]: ["Nature", "Cocooning", "Détente"],
  [Persona.Hyperactive]: ["Sorties", "Sport", "Culture"],
  [Persona.Globetrotter]: ["Découverte", "Voyage", "Rêve"],
};

export function PersonaCard(props: PersonaCardProps) {
  return (
    <button
      className={classNames(styles.Container, props.className)}
      onClick={props.onClick}
    >
      <span className={styles.RoundImageContainer}>
        {topImages[props.persona]}
      </span>
      <span className={styles.Title}>
        {i18n.personaPage.title[props.persona]}
      </span>
      <span className={styles.ChipList}>
        {chipList[props.persona].map((chip: string, index) => {
          return (
            <span key={`key-chip-item-${index}`} className={styles.ChipItem}>
              {chip}
            </span>
          );
        })}
      </span>
      <SvgWrapperComponent
        ariaLabel={`${globali18n.ariaLabel.lifeStyleSelection} ${
          i18n.personaPage.title[props.persona]
        }`}
      >
        <ChevronIcon className={styles.ButtonChevron} />
      </SvgWrapperComponent>
    </button>
  );
}
