import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { EmailInputGroup } from 'shared/components';

import {
  Container,
  PageTitle,
  ValidationMessages,
  ButtonStyles,
  Button,
  InputGroup,
  TextInputPassword
} from '@ifs/libs';
import { hasErrors } from 'shared/utilities/hasErrors';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { passwordMaxLength } from 'shared/utilities/validation';
import { i18n } from '../../i18n';
import { LoginOutcome } from '../../requests/loginRequest';
import { getFormConfig } from './formConfig';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import classNames from 'classnames';
import styles from './LoginPage.module.scss';
import { useLoginCommand } from 'modules/authentication/commands/login';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { globali18n } from '@ifs/libs/src/i18n';

interface FormData {
  email: string;
  password: string;
}

const defaultValues = {};

export function LoginPage() {
  const { register, handleSubmit, watch, formState, reset } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: defaultValues
  });
  const { loginCommand } = useLoginCommand();
  const searchParams = useSearchParams();
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [userLockedOut, setUserLockedOut] = useState(false);

  const showAccountActivated = searchParams.get('accountActivated') === 'true';
  const showPasswordChanged = searchParams.get('passwordChanged') === 'true';

  async function onSubmit(data: FormData) {
    const model = {
      email: data.email,
      password: data.password
    };

    const outcome = await loginCommand(model);

    setIncorrectCredentials(outcome === LoginOutcome.IncorrectCredentials);
    setUserLockedOut(outcome === LoginOutcome.UserLockedOut);
  }

  function onSubmitFailed() {
    setIncorrectCredentials(false);
    setUserLockedOut(false);
  }

  const formConfig = getFormConfig(register);

  function cancel() {
    reset({
      email: "",
      password: ""
    })
  }

  const errors = formState.errors;

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.loginPage.title}</PageTitle>
      <h2 className={ModuleStyles.Subtitle}>{i18n.loginPage.subtitle}</h2>

      {showAccountActivated && <div>{i18n.loginPage.labels.accountActivated}</div>}
      {showPasswordChanged && <div>{i18n.loginPage.labels.passwordChanged}</div>}

      <form
        className={ModuleStyles.FormGridResponsive}
        noValidate
        onSubmit={handleSubmit(onSubmit, onSubmitFailed)}
      >
        <EmailInputGroup name={'email'} register={register} errors={errors} />
        <InputGroup isInvalid={hasErrors('password', errors)}>
          <label htmlFor={'password'}>{i18n.loginPage.labels.password}</label>
          <TextInputPassword
            inputError={hasErrors('password', errors)}
            maxLength={passwordMaxLength}
            id="password"
            register={formConfig.password.field}
            required
            aria-required={"true"}
          />
          <ValidationMessages
            messages={getValidationMessages('password', errors, formConfig.password.errors)}
          />
        </InputGroup>

        {incorrectCredentials && (
          <span className={styles.ErrorMessage}>{i18n.loginPage.errors.incorrectCredentials}</span>
        )}
        {userLockedOut && (
          <span className={styles.ErrorMessage}>{i18n.loginPage.errors.userLockedOut}</span>
        )}

        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
        >
          {i18n.loginPage.labels.connect}
        </Button>
        <Button
          className={classNames(ButtonStyles.Unfill, styles.Button)}
          onClick={cancel}
        >
          {globali18n.labels.cancel}
        </Button>

        <Link className={styles.ResetLink} to={internalUrls.authentication.reset}>
          {i18n.loginPage.labels.passwordReset}
        </Link>
      </form>
      <BackgroundImage />
    </Container>
  );
}
