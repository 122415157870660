import { ProductTypeIdentifier } from "../../modules/recommendation/models/product-type-identifier.enum";

const perFooter = (
  <>
    <section>
      <b>BNP Paribas</b>, S.A. au capital de 2 294 954 818 €. Siège social : 16
      boulevard des Italiens, 75009 Paris. Immatriculée sous le n° 662042449 RCS
      Paris - Identifiant CE FR 76662042449 – ORIAS n° 07 022 735
    </section>
    <section>
      <b>Cardif Retraite</b>, Fonds de Retraite Professionnelle Supplémentaire -
      Régi par le Code des assurances - S.A. au capital de 260 000 000 euros -
      903 364 321 R.C.S. Paris - Siège social : 1, boulevard Haussmann - 75009
      Paris - Bureaux : 8, rue du Port - 92728 Nanterre CEDEX.
    </section>
  </>
);

export const Products = {
  [ProductTypeIdentifier.PERIndividuel]: {
    title: "Plan d‘Epargne Retraite (PER)",
    subtitle: "",
    button: "",
    advertisingCommunication: (
      <div>
        Préparer sa retraite tout en réduisant ses impôts dès aujourd’hui avec
        le Plan d’Epargne Retraite (PER).
        <br />
        <br />
        Le PER est le nouveau produit créé en 2019 par la loi Pacte pour
        remplacer les anciens dispositifs d’épargne retraite (Madelin, PERCO,
        PERP par exemple) dont les règles étaient jugées trop complexes et
        sélectives par les épargnants.
      </div>
    ),
    description: [
      {
        title: "Se constituer une épargne sur le long terme…",
        content: (
          <div>
            Le PER permet de se constituer un capital tout au long de la vie
            active au travers de versements réguliers et/ou ponctuels et de
            débloquer ce capital au moment de la retraite, soit sous forme de
            rente, soit via une sortie en capital, pour tout ou partie du
            montant du contrat.
            <br />
            <br />
            Le PER permet en outre d’opter pour une gestion libre ou de
            bénéficier d’une gestion pilotée. Dans ce dernier cas, la
            répartition de l’épargne retraite investie sur le PER est adaptée en
            fonction du profil et des objectifs de l’épargnant pour être ensuite
            progressivement sécurisée à l’approche de sa retraite.
            <br />
            <br />
            En outre, les montants investis sur les supports en unités de compte
            ne sont pas garantis et peuvent varier à la hausse ou à la baisse en
            fonction de l'évolution des marchés financiers et/ou immobiliers. Ce
            type d’investissement présente donc un risque de perte en capital
            pouvant être partielle ou totale.
          </div>
        ),
      },
      {
        title: "… et bénéficier d’un levier fiscal…",
        content: (
          <div>
            Pendant la phase d’épargne, les versements volontaires effectués sur
            un PER peuvent être déduits du revenu imposable dans certaines
            limites, ce qui permet de réduire l'impôt sur le revenu. Selon que
            l’épargnant est "salarié" ou "Travailleurs Non-Salariés", les
            plafonds de déductions fiscales seront différents. Cette possibilité
            est d’autant plus intéressante que le Taux Marginal d’Imposition
            (TMI) du foyer fiscal de l’épargnant est élevé.
            <br />
            <br />
            En revanche, si les versements volontaires peuvent bénéficier d’une
            déduction fiscale pendant la phase d’épargne, lors de la phase de
            liquidation, c’est-à-dire au moment de la retraite, les retraits des
            sommes accumulées dans le PER converties en capital ou en rente
            (revenus réguliers) sont en principe taxés au titre de l’impôt sur
            le revenu.
            <br />
            <br />
            Il est important de noter que la fiscalité du PER peut varier en
            fonction de la législation fiscale en vigueur et des choix de sortie
            effectués par le souscripteur. Il est donc recommandé de se
            renseigner auprès d'un professionnel pour obtenir des informations
            précises et à jour.
          </div>
        ),
      },
      {
        title: "… non sans conditions",
        content: (
          <div>
            A l’inverse d’autres produits comme le livret A ou le compte
            épargne, le PER ne permet pas de se constituer de l’épargne
            disponible et accessible immédiatement, les sommes épargnées étant
            bloquées jusqu’au moment de la retraite (sauf dans les cas de
            déblocages anticipés).
            <br />
            <br />
            En outre, bien que l’objectif final du PER soit d’obtenir un
            complément de revenu à la retraite, ce type de placement n’est pas
            sans risques et les sommes épargnées sur les marchés boursiers ne
            garantissent pas le capital à terme.
            <br />
            <br />
            Par ailleurs, si des frais s’appliquent à chaque versement effectué
            sur le PER, ce dernier supporte également des frais de gestion
            annuels et des frais d’arbitrage.
          </div>
        ),
      },
    ],
    footer: perFooter,
  },
  [ProductTypeIdentifier.PEA]: {
    title: "Plan d’Epargne en Actions (PEA)",
    subtitle: "",
    button: "",
    advertisingCommunication: (
      <div>
        Dynamiser son portefeuille en investissant dans des valeurs mobilières
        françaises et européennes tout en bénéficiant d'un cadre fiscal
        avantageux.
        <br />
        <br />
        Si le premier avantage mis en avant au sujet du PEA est généralement
        l’exonération fiscale, il convient toutefois de considérer les nombreux
        autres atouts dont il dispose.
      </div>
    ),
    description: [
      {
        title: "Se constituer un capital diversifié en actions…",
        content: (
          <div>
            Le PEA est accessible à toute personne physique majeure, domiciliée
            fiscalement en France, dans la limite d’un PEA par personne et d’un
            plafond de versement de 150 000€ (300 000€ pour un couple marié ou
            pacsé) et jusqu'à 225 000€ en cas de double détention d'un PEA et
            d'un PEA-PME.
            <br />
            Les enfants majeurs rattachés au foyer fiscal de leurs parents
            peuvent également ouvrir un PEA (on parle alors de PEA jeunes) dont
            le plafond est limité à 20 000 € jusqu'à la fin du rattachement de
            l'enfant à charge.
            <br />
            <br />
            Le PEA permet en outre de se constituer un portefeuille investi en
            actions (en direct ou en supports d’investissement collectifs) de
            sociétés ayant leur siège dans l’Union Européenne.
          </div>
        ),
      },
      {
        title: "… et profiter d’une fiscalité avantageuse…",
        content: (
          <div>
            Le détenteur d’un PEA bénéficie d’une fiscalité avantageuse, les
            plus-values et dividendes étant exonérées d'impôts (hors
            contributions sociales) si le PEA est détenu pendant 5 ans minimum.
            <br />
            <br />
            Au-delà de cette période, des retraits partiels sont possibles sans
            entraîner la clôture du plan, avec la possibilité d‘effectuer des
            versements complémentaires jusqu‘au plafond autorisé. Le capital
            détenu sur le PEA peut également être transformé en rente viagère
            défiscalisée (hors contributions sociales sur une fraction de la
            rente).
          </div>
        ),
      },
      {
        title: "… en intégrant les frais et les risques liés au PEA",
        content: (
          <div>
            Le PEA n'offre aucune garantie en capital. À ce titre, il est
            indispensable que le souscripteur soit prêt à accepter les
            fluctuations des marchés boursiers et la possibilité de perdre tout
            ou partie de l'épargne investie.
            <br />
            <br />
            Ainsi, les investissements réalisés au sein d’un PEA doivent
            correspondre aux objectifs fixés et aux moyens financiers du
            souscripteur. Par ailleurs, bien que les fonds restent en principe
            disponibles, le PEA est un placement qui doit s’envisager sur le
            long terme.
            <br />
            <br />
            S’agissant enfin des frais, le PEA est soumis à des droits de garde,
            des frais de gestion annuels et des frais de négociation,
            conformément aux plafonds en vigueur depuis le 1er juillet 2020.
          </div>
        ),
      },
    ],
    footer: (
      <section>
        <b>BNP Paribas</b>, S.A. au capital de 2 294 954 818 €. Siège social :
        16 boulevard des Italiens, 75009 Paris. Immatriculée sous le n°
        662042449 RCS Paris - Identifiant CE FR 76662042449 – ORIAS n° 07 022
        735
      </section>
    ),
  },
  [ProductTypeIdentifier.AssuranceVie]: {
    title: "Assurance vie",
    subtitle: "",
    button: "",
    advertisingCommunication:
      "Préparer et mener à bien ses projets d’avenir, compléter ses revenus à la retraite ou bien encore transmettre un capital à ses proches grâce à l’assurance vie.",
    description: [
      {
        title: "Se constituer une épargne diversifiée…",
        content: (
          <div>
            La souscription d’un contrat d’assurance vie nécessite simplement le
            versement d’un montant minimum. Par la suite, le souscripteur est
            libre d’effectuer des versements complémentaires quand il le
            souhaite, à son rythme, et pour le montant adapté à son budget.
            <br />
            <br />
            L’assurance vie constitue en outre un outil de valorisation et de
            diversification de l’épargne. Majoritairement investie au travers de
            contrats multi-supports, cette épargne peut être répartie entre
            différents supports : un support en euro qui vous assure la sécurité
            du capital investi, et/ou des supports en unités de compte, qui
            permettent d’investir sur les marchés financiers et/ou immobiliers
            sans offrir de sécurité en capital, les montants investis sur ce
            type de support pouvant varier à la hausse ou à la baisse en
            fonction de l'évolution de ces marchés. Ce type d’investissement
            présente donc un risque de perte en capital pouvant être partielle
            ou totale, mais qui constitue un potentiel de rendement plus élevé.
          </div>
        ),
      },
      {
        title: "… disponible à tout moment…",
        content: (
          <div>
            L’épargne investie en assurance vie reste disponible : si un besoin
            est identifié, le souscripteur peut demander à effectuer un rachat
            partiel ou total.
          </div>
        ),
      },
      {
        title:
          "… et dans un cadre fiscal spécifique, y compris en matière de transmission…",
        content: (
          <div>
            En cas de rachat partiel ou total, le montant racheté comprend une
            partie en capital et une part d’éventuelles plus-values : une
            fiscalité particulière s’applique sur le montant des plus-values, le
            capital n’étant pas soumis à fiscalité.
            <br />
            <br />
            Avant 8 ans, les gains réalisés dans le cadre d'une assurance vie
            sont soumis au prélèvement forfaitaire unique (PFU) de 30% ou, sur
            option à l'impôt sur le revenu. Après 8 ans, ils bénéficient d'un
            régime fiscal plus avantageux avec un taux de 7,5% pour les produits
            des primes inférieures à 150 000 euros, puis un taux de 12,8% pour
            le produit des primes au-delà de 150 000 euros) ou, sur option, au
            barème progressif de l’impôt sur le revenu. De plus, un abattement
            annuel de 4 600 euros pour une personne seule et de 9 200 euros pour
            un couple s’applique (fiscalité applicable pour les versements
            effectués à compter du 27/09/2017).
            <br />
            <br />
            En cas de décès, l’assurance vie se révèle également être un outil
            de transmission. En effet, le contrat est hors succession et le
            capital est versé aux bénéficiaires désignés par le souscripteur.
            Les montants transmis aux bénéficiaires désignés obéissent à des
            règles fiscales particulières définies en fonction notamment de la
            date de souscription du contrat, de l’âge du souscripteur au moment
            du versement des primes et de la date de versement de ces primes.
          </div>
        ),
      },
      {
        title: "… en intégrant les frais et les risques liés à l’assurance vie",
        content: (
          <div>
            L’assurance vie est soumise à divers frais parmi lesquels des frais
            sur versement ou droits d’entrée qui sont prélevés directement sur
            le montant des sommes versées sur le contrat. A ces frais s’ajoutent
            des frais de gestion annuels, prélevés sur l’épargne constituée, et
            des frais d’arbitrage en cas de modification de l’allocation du
            contrat.
            <br />
            <br />
            En outre, les montants investis sur les supports en unités de compte
            ne sont pas garantis et peuvent varier à la hausse ou à la baisse en
            fonction de l'évolution des marchés financiers et/ou immobiliers. Ce
            type d’investissement présente donc un risque de perte en capital
            pouvant être partielle ou totale.
          </div>
        ),
      },
    ],
    footer: (
      <>
        <section>
          <b>BNP Paribas</b>, S.A. au capital de 2 294 954 818 €. Siège social :
          16 boulevard des Italiens, 75009 Paris. Immatriculée sous le n°
          662042449 RCS Paris - Identifiant CE FR 76662042449 – ORIAS n° 07 022
          735
        </section>
        <section>
          <b>Cardif Assurance vie</b>, SA au capital de 719 167 488 €,
          entreprise régie par le Code des assurances. Siège social : 1 bd
          Haussmann, TSA 93000, 75318 Paris cedex 09. Immatriculée sous le n°
          732 028 154 au RCS de Paris. Identifiant CE n° FR12732028154. Société
          soumise au contrôle de l’Autorité de contrôle prudentiel et de
          résolution (ACPR), 4 place de Budapest, 75436 Paris cedex 09.
        </section>
      </>
    ),
  },
};
