export const i18n = {
  registrationPage: {
    pageTitle: 'Nouveau compte',
    labels: {
      title: 'Créez votre espace retraite',
      subtitle: 'Initiez votre espace afin d’accéder à votre solution personnalisée',

      lastName: 'Votre nom',
      firstName: 'Votre prénom',
      password: 'Mot de passe',
      passwordConfirm: 'Confirmer son mot de passe',
      acceptPolicies: [
        "En cochant cette case, vous déclarez avoir pris connaissance des ",
        "conditions générales d'utilisation",
        " de la plateforme et les accepter sans réserve."
      ],
      acceptReceiveCommercialInformation: 'J’accepte de recevoir des offres et des informations commerciales de la part de BNP PARIBAS et de ses entités.',
      checkboxDisclaimer: 'Vous pourrez changer d’avis à tout moment dans votre interface de gestion de compte.',
      isBnppClientTitle: 'Client BNP Paribas',
      isBnppClient:
        'Disposez-vous d\'un compte-courant, d\'un livret ou d\'une assurance vie chez BNP Paribas ?',
      disclaimerFinalizeAccountCreation:
        'Vous pourrez changer d’avis à tout moment dans votre interface de gestion de compte.',

      createAccount: 'Créer mon compte',
      alreadyHaveAnAccount: 'Vous avez déjà un compte\u00A0?'
    },
    errors: {
      loginAlreadyExists: 'L’e-mail est déjà associé à un compte.'
    }
  },
  registrationEmailPage: {
    pageTitle: 'Email envoyé',
    title: 'Un lien d’activation vous a été envoyé (valable 48h)',
    subtitle: '',
    resend: 'Renvoyer un email d’activation',
    goToLogin: 'Se connecter'
  },
  registrationResendPage: {
    pageTitle: 'Lien expiré',
    title: "Votre lien d'activation a expiré",
    subtitle: 'Cliquez ci-dessous pour recevoir un nouvel email de validation',
    resend: 'Renvoyer un email d’activation'
  },
  accountActivationPage: {
    accountActivationForm: {
      subtitle: "Finalisez la création de votre espace afin d'accéder à votre solution personnalisée"
    },
    linkExpired: {
      title: "Activez votre compte",
      subtitle: "Malheureusement, votre lien a expiré",
      cta: "Relancer ma création de compte"
    }
  }
};
