import styles from "./RealEstateItem.module.scss";
import { i18n } from "../../../../modules/patrimony/i18n";
import { amount } from "../../../../shared/utilities/intl";
import { Switch } from "@progress/kendo-react-inputs";
import { EditIcon } from "../../../../shared";
import { stormcloud } from "../../../../styles/colors";
import { Argument } from "classnames";
import { useState, useEffect } from "react";
import { GetRealEstateResponseModel } from "../../../../modules/patrimony/models/RealEstateModel";

interface RealEstateItemProps {
  className?: Argument;
  onEdit: () => void;
  realEstate: GetRealEstateResponseModel;
  toggleSwitch: (a: any, b: any) => void;
  position: number;
}

export function RealEstateItem(props: RealEstateItemProps) {
  let { realEstate } = props;
  const [valueSwitch, setValueSwitch] = useState(realEstate.isMainResidence);
  const t = i18n.declarationPage.realEstateCard;

  useEffect(() => {
    setValueSwitch(realEstate.isMainResidence);
  }, [realEstate]);

  const switchControl = {
    onLabel: "",
    offLabel: "",
  };

  function handleChange(e: any) {
    let newVal = { ...props.realEstate };
    setValueSwitch(e.target.value);
    newVal.isMainResidence = e.target.value;
    props.toggleSwitch(props.position, newVal);
  }

  return (
    <div className={styles.Grid}>
      <div className={styles.ResidenceName}>{realEstate.name}</div>
      <button className={styles.Button} onClick={props.onEdit}>
        <span>
        <EditIcon
        className={styles.EditIcon}
        color={stormcloud}
      />
        </span>
      </button>
      <div className={styles.Title}>{t.estimateAmount}</div>
      <div className={styles.Value}>{amount(realEstate.currentAmount)}</div>
      <div className={styles.Label}>{t.mainResidence}</div>
      <Switch
        {...switchControl}
        className={styles.Value}
        onChange={(e) => {
          handleChange(e);
        }}
        checked={valueSwitch}
      />

      {realEstate.isMainResidence && (
        <div className={styles.AlertMessage}>{t.alert}</div>
      )}
    </div>
  );
}
