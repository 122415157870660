import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { FinancialInstrumentsOrMarkets } from "../models/RiskProfileEnum";

export async function setQuestionnaireFinancialProfileSessionAnswers(
  customerId: string | undefined,
  financialProfileIdentifier: string,
  answers: any
) {
  const apiUrl = apiUrls(
    customerId
  ).investorProfile.questionnaireSession.setQuestionnaireSessionAnswers(
    financialProfileIdentifier
  );

  const allAnswers = answers.financialInstrumentsOrMarkets;

  const answersTemps = [
    {
      questionTextId: "QF_KnowledgeOnAction",
      answerTextId: answers.marketFluctuation,
    },
    {
      questionTextId: "QF_KnowledgeOnObligation",
      answerTextId: answers.obligation,
    },
    {
      questionTextId: "QF_KnowledgeOnOPC",
      answerTextId: answers.opc,
    },
    {
      questionTextId: "QF_KnowledgeOnOPCI",
      answerTextId: answers.opciOrScpi,
    },
    {
      questionTextId: "QF_KnowledgeOnFondsEuro",
      answerTextId: answers.financialSupport,
    },
    {
      questionTextId: "QF_RecentExperienceOnObligation",
      answerTextId: allAnswers.includes(
        FinancialInstrumentsOrMarkets.Obligations
      )
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnOPC",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.OPC)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnASVFondsEuro",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.ASVInEuro)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnASVUnitesCompte",
      answerTextId: allAnswers.includes(
        FinancialInstrumentsOrMarkets.ASVUnitAccount
      )
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnOPCI",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.OPCI)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnSCPI",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.SCPI)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_RecentExperienceOnAction",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.Actions)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_NoRecentExperience",
      answerTextId: allAnswers.includes(FinancialInstrumentsOrMarkets.IDontKnow)
        ? "Yes"
        : "No",
    },
    {
      questionTextId: "QF_DontKnowIfRecentExperience",
      answerTextId: allAnswers.includes(
        FinancialInstrumentsOrMarkets.NoneOfTheseAnswers
      )
        ? "Yes"
        : "No",
    },
  ];

  const response = await axios.put(apiUrl, answersTemps);

  const data = response.data;

  return data;
}
