import axios from 'axios';
import { Module } from '@ifs/libs';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';

export interface ModuleCompletionModel {
  module: Module;
  isCompleted: boolean;
}

export async function getModuleCompletionRequest(
  ...modules: Module[]
): Promise<ModuleCompletionModel[]> {
  // Build the query string manually here instead of delegating it to axios
  // We need module=PensionNeed&module=LegalPension
  // While axios would generate module[]=PensionNeed&module[]=LegalPension
  var params = new URLSearchParams();
  for (const module of modules) {
    params.append('modules', module);
  }

  const response = await axios.get<ModuleCompletionModel[]>(apiUrls().completion.get, {
    params
  });

  const data = response.data;

  return data;
}
