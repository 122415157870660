import { CardTitle } from "../../../../shared/components/Card/Card";
import { ModuleColor } from "../../../../styles/colors";
import { i18n } from "../../i18n";
import { ModuleCard } from "../ModuleCard/ModuleCard";
import styles from "./TaxSavings.module.scss";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { Module } from "../../../../shared/models/Module";
import { assets as Assets } from "../../../../modules/dashboard/Assets";
import { useGoToModule } from "../../../../modules/dashboard/commands/goToModule";
import { InvestorProfileCard } from "../InvestorProfileCard/InvestorProfileCard";
import { Scenario } from "../../../../modules/investor-profile/models/RiskProfileEnum";
import { ModalOutcome, useModal } from "../../../../shared";
import { useNavigate } from "react-router-dom";
import { useRenderExpiredInvestorProfileModal } from "../../../../modules/dashboard/commands/renderExpiredInvestorProfileModal";
import { internalUrls, useAppUrls } from "../../../../shared/utilities/urls";
import { useAppStore } from "../../../../store";
import { UserType } from "../../../../shared/requests/getUserReferenceRequest";
import { SaveYourInfoModal, SaveYourInfoResult } from "../SaveYourInfoModal/SaveYourInfoModal";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

interface TaxSavingsProps {
  isTaxationCompleted: boolean;
  taxationStatus: ModuleStatus;
  isInvestorProfileCompleted: boolean;
  investorProfileStatus: ModuleStatus;
  investorProfileExpired: boolean | null | undefined;
  riskProfile: Scenario | null | undefined;
}

export function TaxSavings(props: TaxSavingsProps) {
  const { goToModuleCommand } = useGoToModule();
  const { renderExpiredInvestorProfileModal } = useRenderExpiredInvestorProfileModal();
  const { dashboardCardEvent } = useAnalytics()

  const { showModal } = useModal();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const store = useAppStore();
  const userType = store.getState().authentication.userType;

  function goToTaxation() {
    goToModuleCommand(Module.Taxation);
    dashboardCardEvent("Taxation")
  }

  async function goToInvestorProfile() {
    // The user is still a prospect, give him the choice between registering and Continue without saving data
    if (userType === UserType.Prospect) {
      const result = await showModal<SaveYourInfoResult>(<SaveYourInfoModal />);

      if (result.outcome === ModalOutcome.Cancel) {
        return;
      }

      if (result.value === SaveYourInfoResult.Register) {
        navigate(internalUrls.registration.registration);
      } else {
        return;
      }
    } else {
      // User has to be connected to have access to the investor module
      if (props.investorProfileExpired) {
        await renderExpiredInvestorProfileModal();
      } else {
        goToModuleCommand(Module.InvestorProfile);
        dashboardCardEvent("Profil investisseur")
      }
    }
  }

  return (
    <div className={styles.Container}>
      <CardTitle className={styles.Title}>
        {i18n.dashboardPage.taxSavings.title}
      </CardTitle>
      <div className={styles.ModuleGrid}>
        <ModuleCard
          icon={Assets.TaxationIcon}
          unCompletedIcon={Assets.TaxationIconUncompleted}
          lockIcon={Assets.TaxationIconLock}
          color={ModuleColor[Module.Taxation]}
          prefix={!props.isTaxationCompleted ? "A compléter" : ""}
          title={i18n.moduleNames[Module.Taxation]}
          status={props.taxationStatus}
          onClick={goToTaxation}
          hideAmount={true}
        />
        <InvestorProfileCard
          icon={Assets.InvestorProfileIcon}
          unCompletedIcon={Assets.InvestorProfileIconUncompleted}
          lockIcon={Assets.InvestorProfileIconLock}
          color={ModuleColor[Module.InvestorProfile]}
          prefix={!props.isInvestorProfileCompleted ? "A compléter" : ""}
          title={i18n.moduleNames[Module.InvestorProfile]}
          status={props.investorProfileStatus}
          isExpired={props.investorProfileExpired}
          riskProfile={props.riskProfile}
          onClick={goToInvestorProfile}
        />
      </div>
    </div>
  );
}
