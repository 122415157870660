import { useNavigate } from "react-router";
import { LandingCard } from "../../../../shared";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { i18n } from "../../i18n";
import styles from "../../../../styles/shared/LandingPage.module.scss";
import { Interlayer } from "../../../../shared/components/Interlayer/Interlayer";
import { Module } from "../../../../shared/models/Module";
import { ModuleName } from "../../../../shared/components/LandingCard/ModuleName";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

export function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { landingCTAEvent } = useAnalytics();
  
  function onStart() {
    landingCTAEvent("legal_pension");
    navigate(appUrls.legalPension.gender);
  }



  return (
    <Interlayer className={styles.Interlayer} active={Module.LegalPension}>
      <LandingCard
        module={ModuleName.LegalPension}
        gridClassName={styles.Grid}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content}
        onStart={onStart}
      />
    </Interlayer>
  );
}
