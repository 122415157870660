import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Controller, useForm } from 'react-hook-form';
import { globali18n } from '../../../../i18n';
import {
  Container,
  QuestionTitle,
  ValidationMessages,
  ButtonStyles,
  Button
} from '../../../../shared';
import { getValidationMessages } from '../../../../shared/utilities/getValidationMessages';
import { useAppSelector } from '../../../../store';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { QuestionCard } from '../../../../shared/components/QuestionCard/QuestionCard';
import { i18n } from '../../i18n';
import { ModuleStyles } from '../../styles/ModuleStyles';
import { maxNumberOfChildren, minNumberOfChildren } from '../../utilities/constants';
import { useAnswerCommands } from '../../commands/answerCommands';
interface FormData {
  numberOfChildren: number | null;
}

export function ChildrenPage() {
  const { answerNumberOfChildren } = useAnswerCommands();

  const currentValue = useAppSelector((s) => s.legalPension.answers.numberOfChildren);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    formState,
    resetField,
    control
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      numberOfChildren: currentValue
    }
  });

  function onSubmit(value: FormData) {
    answerNumberOfChildren(value.numberOfChildren!);
  }

  const errorMessages = {
    required: globali18n.errors.required,
    max: i18n.childrenPage.errors.max,
    min: globali18n.errors.min(minNumberOfChildren)
  };

  function increase() {
    const current = getValues().numberOfChildren ?? 0;

    if (current < maxNumberOfChildren) {
      setValue('numberOfChildren', current + 1, {shouldValidate: true});
    }
  }

  function decrease() {
    const current = getValues().numberOfChildren ?? 0;
    if (current > minNumberOfChildren) {
      setValue('numberOfChildren', current - 1, {shouldValidate: true});      
    }
  }
  
  return (
    <Container className={ModuleStyles.PageGrid}>
      <QuestionTitle>
        {i18n.childrenPage.question}
      </QuestionTitle>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <QuestionCard childrenClassName={ModuleStyles.CardChildrenGrid} autoFocusValidate={currentValue != null} >
          <div className={ModuleStyles.InputRow}>
            <Button className={ButtonStyles.Round} onClick={decrease} type="button">
              -
            </Button>
            <Controller
              control={control}
              name="numberOfChildren"
              rules={{
                required: true,
                min: minNumberOfChildren,
                max: maxNumberOfChildren
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <NumericTextBox
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  spinners={false}
                  format={{ maximumFractionDigits: 0 }}
                />
              )}
            />
            <Button className={ButtonStyles.Round} onClick={increase} type="button">
              +
            </Button>
          </div>
          <ValidationMessages
            messages={getValidationMessages('numberOfChildren', errors, errorMessages)}
          />
        </QuestionCard>
      </form>
      <BackgroundImage />
    </Container>
  );
}
