import { RadioGroup } from "@progress/kendo-react-inputs";
import styled from "styled-components";

export const StyledRadioGroup = styled<any>(RadioGroup)`
  ul {
    padding-left: 0;
  }
  .k-radio-item {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background: #edf8f8;
    cursor: pointer;
    margin: auto;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 5.5px;
    height: 5.5px;
    margin-top: 6px;
    margin-left: 5px;
    border-radius: 9px;
  }

  .k-radio-label {
    cursor: pointer;
  }

  input[type="radio"]:checked:before {
    background: var(--secondary-ruddy_brown);
  }

  input[type="radio"]:checked {
    border: 2px solid var(--secondary-ruddy_brown);
  }
`;
