import { danger_red, ModuleColor, valid } from "../../../styles/colors";

export interface ChartData {
  dataPoints: DashboardChartDataPoint[];
  hasSurplus: boolean;
  hasDeficit: boolean;
}

export interface DashboardChartDataPoint {
  category: string;
  value: number;
  completed: boolean;
  color: string;
  border?: string;
  labelColor: string;
}

export interface DashboardSegment {
  completed: boolean;
  value: number;
}

export interface DashboardSegments {
  pensionNeed?: DashboardSegment;
  legalPension?: DashboardSegment;
  patrimony?: DashboardSegment;
  lifeProjects?: DashboardSegment;
  deficit?: DashboardSegment;
  surplus?: DashboardSegment;
}

export function sum(values: (number | null | undefined)[]) {
  return values.reduce((prev: number, curr) => prev + (curr ?? 0), 0);
}

export function createChartData(
  pensionNeed: number | null,
  patrimony: number | null,
  legalPension: number | null,
  lifeProjects: number | null
): ChartData {
  // Creates the segments with their size
  const segments = computeChartSegments(
    pensionNeed,
    patrimony,
    legalPension,
    lifeProjects
  );

  const hasSurplus = segments.surplus != null && segments.surplus.value !== 0;
  const hasDeficit = segments.deficit != null && segments.deficit.value !== 0;

  // Transforms the segments into chart data points
  // They need to be in clockwise order
  const tentativeDataPoints = [
    segmentToResultDataPoint("deficit", segments.deficit),
    segmentToDataPoint(
      "legalPension",
      segments.legalPension,
      ModuleColor.LegalPension
    ),
    segmentToDataPoint("patrimony", segments.patrimony, ModuleColor.Patrimony),
    segmentToDataPoint(
      "lifeProjects",
      segments.lifeProjects,
      ModuleColor.LifeProjects
    ),
    segmentToDataPoint(
      "pensionNeed",
      segments.pensionNeed,
      ModuleColor.PensionNeed
    ),
    segmentToResultDataPoint("surplus", segments.surplus),
  ];

  // Remove null data points (empty segments)
  const dataPoints = tentativeDataPoints.filter(
    (dp) => dp
  ) as DashboardChartDataPoint[];

  return {
    hasSurplus,
    hasDeficit,
    dataPoints,
  };
}

function computeChartSegments(
  pensionNeed: number | null,
  patrimony: number | null,
  legalPension: number | null,
  lifeProjects: number | null
): DashboardSegments {
  if (legalPension == null && patrimony != null) {
    throw new Error("Cannot compute dashboard segments: Missing legal pension");
  }

  if (pensionNeed == null && lifeProjects != null) {
    throw new Error("Cannot compute dashboard segments: Missing pension need");
  }

  const revenues = [legalPension, patrimony];
  const expenses = [pensionNeed, lifeProjects];

  const hasOnlyLegalPension = legalPension != null && patrimony == null;
  const hasOnlyPensionNeed = pensionNeed != null && lifeProjects == null;

  const hasNoRevenues = legalPension == null && patrimony == null;
  const hasNoExpenses = pensionNeed == null && lifeProjects == null;

  const hasAllRevenues = legalPension != null && patrimony != null;
  const hasAllExpenses = pensionNeed != null && lifeProjects != null;

  const sumRevenues = sum(revenues);
  const sumExpenses = sum(expenses);

  // No value, we split the graph into 4 empty quarters
  if (hasNoExpenses && hasNoRevenues) {
    const fourth = 1;

    return {
      pensionNeed: { completed: false, value: fourth },
      lifeProjects: { completed: false, value: fourth },
      legalPension: { completed: false, value: fourth },
      patrimony: { completed: false, value: fourth },
    };
  }

  // Pension need only, it takes 1/4
  if (hasOnlyPensionNeed && hasNoRevenues) {
    const fourth = pensionNeed;

    return {
      pensionNeed: { completed: true, value: fourth },
      lifeProjects: { completed: false, value: fourth },
      legalPension: { completed: false, value: fourth },
      patrimony: { completed: false, value: fourth },
    };
  }

  // Legal pension only, it takes 1/4
  if (hasNoExpenses && hasOnlyLegalPension) {
    const fourth = legalPension;

    return {
      pensionNeed: { completed: false, value: fourth },
      lifeProjects: { completed: false, value: fourth },
      legalPension: { completed: true, value: fourth },
      patrimony: { completed: false, value: fourth },
    };
  }

  // Pension need and legal pension, the largest takes 1/4
  // The smallest cedes the difference to its incomplete neighbor
  if (hasOnlyPensionNeed && hasOnlyLegalPension) {
    const fourth = Math.max(pensionNeed, legalPension);
    const half = fourth * 2;

    return {
      pensionNeed: { completed: true, value: pensionNeed },
      lifeProjects: { completed: false, value: half - pensionNeed },
      legalPension: { completed: true, value: legalPension },
      patrimony: { completed: false, value: half - legalPension },
    };
  }

  // Pension need and all revenues
  // The largest takes 1/2 and the smallest cedes the difference either to its incomplete neighbor (if pension need) or to deficit (if revenues)
  if (hasOnlyPensionNeed && hasAllRevenues) {
    const half = Math.max(pensionNeed, sumRevenues);

    return {
      pensionNeed: { completed: true, value: pensionNeed },
      lifeProjects: { completed: false, value: half - pensionNeed },
      legalPension: { completed: true, value: legalPension },
      patrimony: { completed: true, value: patrimony },
      deficit: { completed: true, value: half - sumRevenues }, // In case pensionNeed > sumRevenues
    };
  }

  // All expenses and legal pension
  // The largest takes 1/2 and the smallest cedes the difference either to its incomplete neighbor (if legal pension) or to surplus (if expenses)
  if (hasAllExpenses && hasOnlyLegalPension) {
    const half = Math.max(sumExpenses, legalPension);

    return {
      pensionNeed: { completed: true, value: pensionNeed },
      lifeProjects: { completed: true, value: lifeProjects },
      legalPension: { completed: true, value: legalPension },
      patrimony: { completed: false, value: half - legalPension },
      surplus: { completed: true, value: half - sumExpenses }, // In case legalPension > sumExpenses
    };
  }

  // All complete
  // The largest takes 1/2 and the smallest cedes the difference either to surplus (if expenses) or to deficit (if revenues)
  if (hasAllExpenses && hasAllRevenues) {
    const half = Math.max(sumExpenses, sumRevenues);

    return {
      pensionNeed: { completed: true, value: pensionNeed },
      lifeProjects: { completed: true, value: lifeProjects },
      legalPension: { completed: true, value: legalPension },
      patrimony: { completed: true, value: patrimony },
      surplus: { completed: true, value: half - sumExpenses },
      deficit: { completed: true, value: half - sumRevenues },
    };
  }

  // Required for TS return type inferrence, should never happen
  throw new Error("Cannot compute dashboard segments: Unhandled case");
}

function segmentToDataPoint(
  category: string,
  segment: DashboardSegment | undefined,
  color: string
): DashboardChartDataPoint | null {
  // Missing or 0-sized segments will not be shown
  if (segment == null || segment.value === 0) {
    return null;
  }

  // Incomplete segment, show a data point with a colored 'To complete' label on top of a white background color
  if (!segment.completed) {
    return {
      category,
      value: segment.value,
      completed: false,
      color: "white",
      border: color,
      labelColor: color,
    };
  }

  // Completed segment, show a data point with a white amount label on top of a colored background
  return {
    category,
    value: segment.value,
    completed: true,
    color,
    labelColor: "white",
  };
}

function segmentToResultDataPoint(
  category: string,
  segment: DashboardSegment | undefined
): DashboardChartDataPoint | null {
  // Missing or 0-sized segments will not be shown
  if (segment == null || segment.value === 0) {
    return null;
  }

  // The completed/non-completed distinction is not relevant here
  // These segments only ever appear in a completed state

  return {
    category,
    value: segment.value,
    completed: true,
    color: "white",
    border: category === "surplus" ? valid : danger_red,
    labelColor: category === "surplus" ? valid : danger_red,
  };
}
