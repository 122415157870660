import { i18n } from '../../../i18n';
import { housingIcon } from '../../../images';
import { HousingLocation } from '../../../models/housing/housing-location.enum';
import { setHousingCitySize } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const HousingCitySizePage = createPensionNeedPage<HousingLocation>({
  scaleValueSelector: (q) => q.housing.questionnaire?.location ?? null,
  i18nFn: (i18n) => i18n.housingCitySizePage,
  scaleValueReducer: setHousingCitySize,
  answers: [
    {
      icon: housingIcon.SmallCity,
      label: i18n.housingCitySizePage.answer1,
      value: HousingLocation.CitySmall
    },
    {
      icon: housingIcon.MediumCity,
      label: i18n.housingCitySizePage.answer2,
      value: HousingLocation.CityMedium
    },
    {
      icon: housingIcon.LargeCity,
      label: i18n.housingCitySizePage.answer3,
      value: HousingLocation.CityLarge
    },
    {
      icon: housingIcon.IleDeFrance,
      label: i18n.housingCitySizePage.answer4,
      value: HousingLocation.CityIleDeFrance
    }
  ],
  nextPage: () => ({ route: '../housing/transaction-type' })
});
