import { useEffect } from 'react';
import { useCustomerCreationCommand } from 'shared/commands/customerCreationCommand';

export function CheckCustomerPage() {
  const { checkCustomerCreation } = useCustomerCreationCommand();

  useEffect(() => {
    (async () => {
      await checkCustomerCreation();
    })();
  }, []);

  return <></>;
}
