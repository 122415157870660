import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { ProductTypeModel } from '../models/ProductTypeModel';

export async function getProductTypesRequest(): Promise<ProductTypeModel[]> {
  const response = await axios.get<ProductTypesResponse>(apiUrls().productTypes);
  const data = response.data;

  return data.results;
}

//toDo test if correct
interface ProductTypesResponse {
  results: ProductTypeModel[];
}
