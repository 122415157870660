import { useLocation } from "react-router-dom";
import { UserType } from "../../shared/requests/getUserReferenceRequest";
import { useAppSelector } from "../../store";
import { useGlobalCommand } from "../../shared/commands/useGlobalCommand";

enum RendezVousLinkUrlNotAllowedEnum {
  Appointment = "/appointment",
  Recommendation = "/recommendation",
}

export function useMenuItems() {
  const { userType, isAuthenticated } = useAppSelector(
    (s) => s.authentication
  )!;
  const { isAuthenticationModeSSO } = useGlobalCommand();
  const location = useLocation();

  const canGoToDashboard = isAuthenticated;

  let canRegister = userType === UserType.Prospect;
  let canLogIn = userType === UserType.Prospect;

  if (userType === null) {
    canRegister = true;
    canLogIn = true;
  }

  if (userType === UserType.Advisor) {
    canRegister = false;
    canLogIn = false;
  }

  const canLogOut = isAuthenticated && userType === UserType.User;
  const canGoToProfile = isAuthenticated && userType === UserType.User;
  const canClickOnMeetingLink =
    !(
      [
        RendezVousLinkUrlNotAllowedEnum.Appointment,
        RendezVousLinkUrlNotAllowedEnum.Recommendation,
      ] as string[]
    ).some((url) => location.pathname.includes(url)) &&
    userType !== UserType.Advisor;

  return {
    canGoToDashboard,
    canRegister,
    canLogIn,
    canLogOut,
    canGoToProfile,
    canClickOnMeetingLink,
  };
}
