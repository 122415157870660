import React, { PropsWithChildren, ReactSVGElement } from 'react';

export function SvgWrapperComponent(props: PropsWithChildren<SvgWrapperComponentProps>) {
  const { ariaLabel, ariaHidden } = props;
  const ariaRole = props.role || "img";

  return (
    <>
      {
        props.children && React.cloneElement(props.children as ReactSVGElement, {
          role: ariaRole,
          'aria-hidden': ariaHidden,
          'aria-label': ariaLabel,
        })
      }
    </>
  );
}

type SvgWrapperComponentProps = {
  ariaLabel?: string;
  ariaHidden?: boolean;
  role?: React.AriaRole;
}