export enum Tenant {
  BCEF = "BCEF",
  Internaute = "Internaute",
  Matmut = "Matmut",
  BCEFPrivateBank = "BCEFPrivateBank",
  BCEFRetail = "BCEFRetail"
}

export type TenantModelResponse = {
  TenantIdentifier: Tenant,
  Urls: {},
  ModuleSettings: { Key: string; Value: string }[]
}

export enum FooterLink {
  A11yPage = "A11yPage",
  AboutUsPage = "AboutUsPage",
  CookiesPage = "CookiesPage",
  FaqPage = "FaqPage",
  HomePage = "HomePage",
  LogoutRedirectUrl = "LogoutRedirectUrl",
  PartnersPage = "PartnersPage",
  PrivacyPage = "PrivacyPage",
  ProjectionExplanationPage = "ProjectionExplanationPage",
  TermsOfUsePage = "TermsOfUsePage",
  AdvisorTutorialPage = "AdvisorTutorialPage",
  AdvisorPrivacyPage = 'AdvisorPrivacyPage'
}

export const footerLinkOrderMap = new Map<string, number>([
  [FooterLink.AboutUsPage,1],
  [FooterLink.FaqPage,2],
  [FooterLink.PartnersPage,3],
  [FooterLink.TermsOfUsePage,4],
  [FooterLink.CookiesPage,5],
  [FooterLink.PrivacyPage,6],
  [FooterLink.A11yPage,7],
])

export interface FooterLinkInterface {
  A11yPage: string;
  AboutUsPage: string;
  CookiesPage: string;
  FaqPage: string;
  HomePage: string;
  LogoutRedirectUrl: string;
  PartnersPage: string;
  PrivacyPage: string;
  ProjectionExplanationPage: string;
  TermsOfUsePage: string;
  AdvisorTutorialPage: string;
  AdvisorPrivacyPage: string;
}
