import { useLoader } from "../../../shared";
import { getProducts } from "../requests/getProducts";
import { apiUrls } from "../../../shared/apiUrls";

export function useGetProductsCommand() {
  const { showLoaderWhile } = useLoader();

  async function getProductsCommand(customerid?: string) {
    return await showLoaderWhile(
      async () => {
        return await getProducts();
      },
      {
        key: "useGetProductsCommand",
        value: `API POST - ${apiUrls(customerid).products}`,
      }
    );
  }

  return { getProductsCommand };
}
