import axios from 'axios';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';
import { apiUrls } from '../../../shared/apiUrls';
import { ComputationOutputModel } from '../models/ComputationOutputModel';

export async function getComputationFromValidateRequest(validationId: string, customerid?: string): Promise<ComputationOutputModel | null> {
  const apiUrl = apiUrls(customerid).legalPension.validationComputation(validationId);
  
  const response = await axios.get<ComputationOutputModel>(
    apiUrl, {
      ...axiosRequestValidateStatusConfig
    }
  );

  if (response.status === 204, response.status === 404) {
    return null;
  }
  const data = response.data;

  return data;
}
