import styles from "./Header.module.scss";
import { i18n } from "../../i18n";
import classNames from "classnames";
import { internalUrls, useAppUrls } from "../../utilities/urls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BurgerIcon, CalendarIcon } from "./images";
import { useMenuItems } from "../../../shared/utilities/useMenuItems";
import { assets as Assets } from "./Assets";
import { Container } from "../Container/Container";
import { Button } from "../Button/Button";
import { ButtonStyles } from "../Button/ButtonStyles";
import { useAppSelector } from "../../../store";
import { ModalOutcome, useModal } from "../Modal";
import { i18n as dashboardI18n } from "../../../modules/dashboard/i18n";
import {
  RegisterOrLoginModal,
  RegisterOrLoginResult,
} from "../../../modules/dashboard/components/RegisterOrLoginModal/RegisterOrLoginModal";
import { UserType } from "../../../shared/requests/getUserReferenceRequest";
import { useAnalytics } from "../../../shared/commands/useAnalytics";
import { SvgWrapperComponent } from "../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { DropDownUserMenu } from "../DropDownUserMenu/DropDownUserMenu";
import { globali18n } from "../../../i18n";
interface HeaderProps {
  onMenuClick?: () => void;
}

enum RendezVousLinkUrlNotAllowed {
  Appointment = "/appointment",
  Recommendation = "/recommendation",
}

export function Header(props: HeaderProps) {
  const menuItems = useMenuItems();
  const appUrls = useAppUrls();
  const { headerCTAEvent } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal } = useModal();
  const isAuthenticated = useAppSelector(
    (s) => s.authentication.isAuthenticated
  );
  const userType = useAppSelector((s) => s.authentication.userType);

  function logIn() {
    headerCTAEvent("connexion");
    navigate(internalUrls.authentication.login);
  }

  function register() {
    headerCTAEvent("inscription");
    navigate(internalUrls.registration.registration);
  }

  function renderRendezVousLink(): boolean {
    return (
      !(
        [
          RendezVousLinkUrlNotAllowed.Appointment,
          RendezVousLinkUrlNotAllowed.Recommendation,
        ] as string[]
      ).some((url) => location.pathname.includes(url)) &&
      userType !== UserType.Advisor
    );
  }

  async function goToMeeting() {
    headerCTAEvent("rendez-vous");
    if (isAuthenticated && userType === UserType.User) {
      navigate(internalUrls.appointment);
    } else {
      const result = await showModal<number>(
        <RegisterOrLoginModal
          title={dashboardI18n.registerOrLoginModal.titleMeeting}
        />
      );
      if (result.outcome === ModalOutcome.Ok) {
        navigate(
          result.value === RegisterOrLoginResult.Register
            ? internalUrls.registration.registration
            : internalUrls.authentication.login
        );
      }
    }
  }

  return (
    <header className={styles.Header}>
      <Container className={styles.Container}>
        <Link
          className={styles.DashboardLink}
          to={appUrls.dashboard}
        >
          <SvgWrapperComponent ariaLabel={globali18n.ariaLabel.homeRedirect}>
            <Assets.HeaderAppIcon className={styles.Logo} role={"img"} />
          </SvgWrapperComponent>
        </Link>
        <div className={styles.ActionArea}>
          {renderRendezVousLink() && (
            <a
              onClick={goToMeeting}
              className={styles.RendezVousLink}
              href="#"
              role="link"
              aria-label="Prendre un rendez-vous"
              id={"rendezVousLink"}
            >
              <SvgWrapperComponent ariaHidden={true}>
                <CalendarIcon title={i18n.header.rendezVous} />
              </SvgWrapperComponent>

              {i18n.header.rendezVous}
            </a>
          )}
          {menuItems.canLogIn && (
            <Button
              id={"loginButton"}
              onClick={logIn}
              className={classNames(
                ButtonStyles.SecondaryAction,
                ButtonStyles.Small
              )}
            >
              {i18n.header.logIn}
            </Button>
          )}
          {menuItems.canRegister && (
            <Button
              id={"registerButton"}
              onClick={register}
              className={classNames(
                ButtonStyles.PrimaryAction,
                ButtonStyles.Small
              )}
            >
              {i18n.header.register}
            </Button>
          )}
          <DropDownUserMenu />
        </div>
        <button
          onClick={props.onMenuClick}
          className={styles.BurgerIcon}
        >
          <SvgWrapperComponent ariaLabel={globali18n.ariaLabel.applicationMenu}>
            <BurgerIcon className={styles.BurgerIcon} id="burgerMenu"/>
          </SvgWrapperComponent>
        </button>
      </Container>
    </header>
  );
}
