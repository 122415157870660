import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { EmailInputGroup } from 'shared/components/EmailInputGroup/EmailInputGroup';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { useCommands } from '../../commands/commands';
import { i18n } from '../../i18n';
import styles from './PasswordResetPage.module.scss';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import classNames from 'classnames';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import {
  Container, PageTitle,
  ButtonStyles,
  Button,
  SvgWrapperComponent
} from '@ifs/libs'
import { globali18n } from '@ifs/libs/src/i18n';


interface FormData {
  email: string;
}

const defaultValues = {
};

export function PasswordResetPage() {
  const { register, handleSubmit, formState } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: defaultValues
  });
  const navigate = useNavigate();
  const { requestPasswordResetCommand } = useCommands();
  const searchParams = useSearchParams();

  const expired = searchParams.get('expired') === 'true';

  async function onSubmit(data: FormData) {
    await requestPasswordResetCommand(data.email);
  }

  function cancel() {
    navigate(-1);
  }

  const errors = formState.errors;
  const isSubmitted = formState.isSubmitted;

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>
        {expired ? i18n.passwordResetPage.expiredTitle : i18n.passwordResetPage.title}
      </PageTitle>
      <h2 className={ModuleStyles.Subtitle}>
        {expired ? i18n.passwordResetPage.expiredSubtitle : i18n.passwordResetPage.subtitle}
      </h2>

      <form className={ModuleStyles.FormGridCenteredResponsive} noValidate onSubmit={handleSubmit(onSubmit)}>
        <SvgWrapperComponent ariaHidden={true}><SharedAssets.Mailbox className={ModuleStyles.Mailbox} /></SvgWrapperComponent>
        <EmailInputGroup
          name="email"
          isSubmitted={isSubmitted}
          register={register}
          errors={errors}
        />
        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
        >
          {expired
            ? i18n.passwordResetPage.labels.expiredReset
            : i18n.passwordResetPage.labels.reset}
        </Button>
        {!expired && (
          <Button
            className={classNames(ButtonStyles.Unfill, styles.Button)}
            onClick={cancel}
          >
            {globali18n.labels.cancel}
          </Button>
        )}
      </form>
      <BackgroundImage />
    </Container>
  );
}
