import { Argument } from "classnames";
import { i18n } from "../../../../modules/patrimony/i18n";
import { PropsWithChildren } from "react";
import { EditIcon } from "../../../../shared";
import { amount, amountPerMonth } from "../../../../shared/utilities/intl";
import { stormcloud } from "../../../../styles/colors";
import styles from "./SavingsItem.module.scss";
import { GetSavingAccountResponseModel } from "../../../../modules/patrimony/models/SavingsAccountModel";
import { SavingsAccountProductType } from "../../../../modules/patrimony/models/ProductType";

interface SavingsItemProps extends PropsWithChildren<{}> {
  className?: Argument;
  onEdit: () => void;
  account: GetSavingAccountResponseModel;
}

export function SavingsItem(props: SavingsItemProps) {
  const { account } = props;
  const t = i18n.declarationPage.savingsItem;

  return (
    <div className={styles.Grid}>
      <div className={styles.Title}>
        {i18n.savingsProductType[account.productTypeId as SavingsAccountProductType]}
      </div>
      <button className={styles.Button}  onClick={props.onEdit}>
        <span>
          <EditIcon
            className={styles.EditIcon}
            color={stormcloud}
            onClick={props.onEdit}
          />
        </span>
      </button>
      <div className={styles.Label}>{t.currentAmount}</div>
      <div className={styles.Value}>{amount(account.currentAmount)}</div>
      <div className={styles.Label}>{t.monthlySavings}</div>
      <div className={styles.Value}>
        {amountPerMonth(account.monthlySavings)}
      </div>
    </div>
  );
}
