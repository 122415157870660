import {
  NumericTextBox,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { i18n } from "../../i18n";
import {
  AgeSelectionModel,
  ParametersInputModel,
  ParametersOutputModel,
} from "../../models/RecommendationModel";
import { retirementAge } from "../../utilities/format";
import { ButtonGroupOption } from "../../../../shared/components/ButtonGroup/ButtonGroup";
import { amount } from "../../../../shared/utilities/intl";
import styles from "./ParamsGroup.module.scss";
import { Button, ButtonStyles } from "../../../../shared";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useAppSelector } from "../../../../store";
import { StyledRadioGroup } from "../../../../shared/components/RadioGroup/StyledRadioGroup";
import { PlusAndMinusInput } from "./components/PlusAndMinusInput";

export interface ParamsGroupProps {
  value: ParametersInputModel;
  parameters: ParametersOutputModel;
  retirementScenarios: AgeSelectionModel[];
  onChange: (value: ParametersInputModel) => void;
  onReset: () => void;
  changeButtonStatus: (buttonStatus: boolean) => void;
}

export function ParamsGroup(props: ParamsGroupProps) {
  const { value, onChange, retirementScenarios, parameters } = props;
  const model = useAppSelector((s) => s.recommendation.model)!;
  const t = i18n.recommendationPage.parametersCard;
  const recuring = model.parameters.recurringInvestmentAmount!;
  const initial = model.parameters.initialInvestmentAmount!;
  const defaultInitial = model.parameters.initialInvestmentAmount!.defaultValue;
  const defaultMonthly =
    model.parameters.recurringInvestmentAmount!.defaultValue;

  const [initalInvestment, setInitialInvestment] = useState<number>(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState<number>(0);

  const [errorInitialMessage, setErrorInitialMessage] = useState<string | null>(
    ""
  );
  const [errorMonthlyMessage, setErrorMonthlyMessage] = useState<string | null>(
    ""
  );

  const sortedRetirement = retirementScenarios.sort((a, b) => {
    if (a.age.year === b.age.year) {
      return a.age.month < b.age.month ? -1 : 1;
    } else {
      return a.age.year < b.age.year ? -1 : 1;
    }
  });

  const retirementAgeOptions = sortedRetirement.map<ButtonGroupOption>(
    (rs, idx) => ({
      value: rs.retirementScenario,
      label: retirementAge(rs.age),
    })
  );

  function retirementAgeChanged(n: RadioGroupChangeEvent) {
    onChange({
      ...value,
      retirementScenario: n.value,
    });
  }

  function setInitialValues() {
    setMonthlyInvestment(recuring.investmentAmount! || 0);
    setInitialInvestment(initial.investmentAmount! || 0);
  }

  useEffect(() => {
    setInitialValues();
  }, [model.parameters]);

  useEffect(() => {
    setErrorInitialMessage(handleErrorInitalDisplay());

    onChange({
      ...value,
      initialInvestmentAmount:
        initalInvestment === defaultInitial ? null : initalInvestment,
      recurringInvestmentAmount:
        monthlyInvestment === defaultMonthly ? null : monthlyInvestment,
    });
    return () => {
      setErrorInitialMessage("");
    };
  }, [initalInvestment]);

  useEffect(() => {
    setErrorMonthlyMessage(handleErrorMonthlyDisplay());

    onChange({
      ...value,
      initialInvestmentAmount:
        initalInvestment === defaultInitial ? null : initalInvestment,
      recurringInvestmentAmount:
        monthlyInvestment === defaultMonthly ? null : monthlyInvestment,
    });
    return () => {
      setErrorMonthlyMessage("");
    };
  }, [monthlyInvestment]);

  function handleErrorInitalDisplay(): string | null {
    if (initalInvestment === null) {
      props.changeButtonStatus(true);
      return t.errorsMessage.tooHigh(initial!.minValue);
    } else {
      if (initalInvestment < 0 || initalInvestment.toString().includes("-")) {
        props.changeButtonStatus(true);
        return t.errorsMessage.tooHigh(initial!.minValue);
      }
    }
    if (initalInvestment === 0) {
      props.changeButtonStatus(true);
      return t.errorsMessage.tooHigh(initial!.minValue);
    }

    if (initalInvestment > 0 && initalInvestment < initial!.minValue) {
      props.changeButtonStatus(true);
      return t.errorsMessage.tooHigh(initial!.minValue);
    }

    if (initalInvestment > initial!.maxValue) {
      props.changeButtonStatus(true);
      return t.errorsMessage.tooLow(initial!.maxValue);
    }

    props.changeButtonStatus(false);
    return null;
  }

  function handleErrorMonthlyDisplay(): string | null {
    if (monthlyInvestment === null) {
      props.changeButtonStatus(true);
      return t.errorsMessage.monthlyTooHigh(recuring!.minValue);
    } else {
      if (
        (monthlyInvestment === -0 ||
          monthlyInvestment.toString().includes("-")) &&
        monthlyInvestment !== 0
      ) {
        props.changeButtonStatus(true);
        return t.errorsMessage.monthlyTooHigh(recuring!.minValue);
      }
    }

    if (monthlyInvestment > 0 && monthlyInvestment < recuring.minValue) {
      props.changeButtonStatus(true);
      return t.errorsMessage.monthlyTooLow(recuring.minValue);
    }

    if (monthlyInvestment > recuring.maxValue) {
      props.changeButtonStatus(true);
      return t.errorsMessage.monthlyTooHigh(recuring.maxValue);
    }
    props.changeButtonStatus(false);
    return null;
  }

  function closest50(number: number, secondValue: number) {
    const closest = Math.round(number / secondValue) * secondValue;
    return closest;
  }

  function onChangeInitialInvestment(event: any) {
    setInitialInvestment(event.value);
  }

  function onChangeMonthlyInvestment(event: any) {
    setMonthlyInvestment(event.value);
  }

  function handlePlusMonthly() {
    const closest = closest50(monthlyInvestment, recuring.minValue);
    setMonthlyInvestment(closest + recuring.minValue);
  }

  function handleMinusMonthly() {
    if (monthlyInvestment - recuring.minValue > 0) {
      const closest = closest50(monthlyInvestment, recuring.minValue);
      if (closest === recuring.minValue) {
        setMonthlyInvestment(recuring.minValue);
      } else {
        setMonthlyInvestment(closest - recuring.minValue);
      }
    } else {
      setMonthlyInvestment(0);
    }
  }

  function handlePlusInitial() {
    const closest = closest50(initalInvestment, initial.minValue);
    setInitialInvestment(closest + initial.minValue);
    return;
  }

  function handleMinusInitial() {
    if (initalInvestment - initial.minValue > 0) {
      const closest = closest50(initalInvestment, initial.minValue);
      if (closest === initial.minValue) {
        setInitialInvestment(initial.minValue);
      } else {
        setInitialInvestment(closest - initial.minValue);
      }
    } else {
      setInitialInvestment(0);
    }
  }

  return (
    <div className={styles.Grid}>
      <section className={styles.AmountSliderSection}>
        <div className={styles.SectionTitle}>{t.atOpenning}</div>
        <div className={styles.FlexSlidereXl}>
          <div className={styles.SliderContainer}>
            <span className={styles.ErrorMessage}>{errorInitialMessage}</span>
            <PlusAndMinusInput
              minusHandle={handleMinusInitial}
              onChangeHandler={(e) => onChangeInitialInvestment(e)}
              initialValue={initalInvestment}
              plusHandler={handlePlusInitial}
            />

            <div className={styles.LiquidityAmountRecommendedAmount}>
              {t.otherGroup.defaultValue}{" "}
              {amount(parameters.initialInvestmentAmount?.defaultValue!)}
            </div>
          </div>

          <div className={styles.SliderContainer}>
            <div className={styles.SectionTitle}>{t.mensual}</div>
            <span className={styles.ErrorMessage}>{errorMonthlyMessage}</span>
            <PlusAndMinusInput
              minusHandle={handleMinusMonthly}
              onChangeHandler={(e) => onChangeMonthlyInvestment(e)}
              initialValue={monthlyInvestment}
              plusHandler={handlePlusMonthly}
            />
            <div className={styles.LiquidityAmountRecommendedAmount}>
              {t.otherGroup.defaultValue}{" "}
              {amount(parameters.recurringInvestmentAmount?.defaultValue!)}
            </div>
          </div>
        </div>
      </section>

      <hr className={styles.Divider} />

      <section className={styles.RetirementAgeSection}>
        <div className={styles.SectionTitle}>{t.retirementAge}</div>
        <div className={styles.AgeCardGroup}>
          <StyledRadioGroup
            data={retirementAgeOptions}
            defaultValue={value.retirementScenario}
            className={styles.RadioGroup}
            onChange={retirementAgeChanged}
          />
        </div>
      </section>
    </div>
  );
}
