import { globali18n } from "../../i18n";

const langage = "fr-FR";
const unitSeparator = "\u202f"; // NARROW NO-BREAK SPACE (U+202F)

export function number(n: number | null | undefined) {
  if (n == null) return "";
  return new Intl.NumberFormat(langage).format(n);
}

export function numberSplitter(n: number | null | undefined) {
  if (n == null) return "";
  return new Intl.NumberFormat(langage).format(n) + unitSeparator;
}

export function amount(n: number | null) {
  if (n == null) return "";
  return (
    new Intl.NumberFormat(langage).format(n) +
    unitSeparator +
    globali18n.labels.euros
  );
}

export function amountPerMonth(n: number | null) {
  if (n == null) return "";
  return (
    new Intl.NumberFormat(langage).format(n) +
    unitSeparator +
    globali18n.labels.eurosPerMonth
  );
}

export function percentage(n: number | null) {
  if (n == null) return "";
  return (
    new Intl.NumberFormat(langage).format(n * 100) +
    unitSeparator +
    globali18n.labels.percent
  );
}

export function dateTimeFormat(d: Date | null) {
  if (d == null) return "";
  return new Intl.DateTimeFormat(langage).format(d);
}

export function dateLong(d: Date | null) {
  if (d == null) return "";
  return new Intl.DateTimeFormat(langage, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(d);
}
