import { TimeSlot } from "./modules/appointment/models/TimeSlot";
import { SalaryType } from "./modules/legal-pension/models/salary/SalaryType";
import { SalaryPeriodicity } from "./shared/models/SalaryPeriodicity";

export const globali18n = {
  title: 'Mondemain by BNP Paribas',
  labels: {
    finalize: "Finaliser votre questionnaire",
    add: 'Ajouter',
    validate: 'Valider',
    cancel: 'Annuler',
    back: 'Retour',
    save: 'Sauvegarder',
    previous: 'Précédent',
    delete: 'Supprimer',
    perMonth: '/mois',
    amount: 'Montant',
    and: 'et',
    inTheBox: 'C\'est dans la boîte\u00A0!',
    continue: 'Continuer',
    close: 'Fermer',
    euros: '€',
    eurosPerMonth: '€/mois',
    eurosPerYear: '€/an',
    percent: '%',
    monthly: 'Mensuel',
    yearly: 'Annuel',
    validateModule: 'On passe à la suite\u00A0?',
    timePeriodPlural: {
      years: 'ans',
      months: 'mois'
    },
    summary: 'Synthèse',
    salaryType: {
      [SalaryType.Gross]: 'brut',
      [SalaryType.Net]: 'net',
    },
    salaryFrequency: {
      [SalaryPeriodicity.Monthly]: 'mois',
      [SalaryPeriodicity.Yearly]: 'an'
    },
    estimatedTime: (m: number) => `Durée estimée\u00A0: ${m} minutes`,
    spamWarning: 'Si vous ne recevez pas cet email\u00A0: pensez à vérifier vos spams',
    
    timeSlots: {
      [TimeSlot.Ts9To11]: '9h - 11h',
      [TimeSlot.Ts11To13]: '11h - 13h',
      [TimeSlot.Ts13To15]: '13h - 15h',
      [TimeSlot.Ts15To17]: '15h - 17h',
      [TimeSlot.Ts17To19]: '17h - 19h',
      [TimeSlot.Ts9To12]: '9h - 12h',
      [TimeSlot.Ts12To14]: '12h - 14h',
      [TimeSlot.Ts14To16]: '14h - 16h'
    },
    privacy: 'En savoir plus sur le traitement de vos données personnelles'
  },
  errors: {
    email: "Cette adresse email n'est pas valide.",
    pattern: 'Le format est incorrect.',
    required: 'Cette information est obligatoire.',
    max: (value: number | string) => `La valeur doit être inférieure ou égale à ${value}.`,
    min: (value: number | string) => `La valeur doit être supérieure ou égale à ${value}.`
  },
  ariaLabel : {
    toggleMenu: "Basculer le menu",
    passwordShow: "Rendre le mot de passe lisible",
    passwordHide: "Rendre le mot de passe illisible",
    homeRedirect: "Redirection vers la page d'accueil",
    checkedElement: "Element coché",
    lifeStyleSelection: "Sélectionner le style de vie",
    furtherInformation: "Informations complémenataires",
    applicationMenu: "Menu de l'application",
    formFieldValid: "Champ du formulaire valide",
    formFieldInvalid: "Champ du formulaire invalide",
    module: "Le module",
    isCompleted: "est complété"
  }
};