import { useNavigate } from "react-router";
import { i18n } from "../../../i18n";
import styles from "../../../../../styles/shared/LandingPage.module.scss";
import { Interlayer } from "../../../../../shared/components/Interlayer/Interlayer";
import { Module } from "../../../../../shared/models/Module";
import { SummaryCard } from "./Components/SummaryCard/SummaryCard";
import { Scenario } from "../../../../../modules/investor-profile/models/RiskProfileEnum";
import { useEffect } from "react";
import { setRenderSummaryPage } from "../../../../../modules/investor-profile/investorProfileSlice";
import { useAppDispatch } from "../../../../../store";
import { useGlobalCommand } from "../../../../../shared/commands/useGlobalCommand";

export function FinancialProfileSummary() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getRightRecommendationPageUri } = useGlobalCommand();

  useEffect(()=> {   
    dispatch(setRenderSummaryPage(true));
  }, [])
  
  function onStart() {
    dispatch(setRenderSummaryPage(false));
    navigate(getRightRecommendationPageUri());
  }



  return (
    <Interlayer className={styles.Interlayer} active={Module.InvestorProfile} noGoal={true}>
      <SummaryCard
      scenario={Scenario.Prudent}
        gridClassName={styles.Grid}
        title={i18n.financialProfileSummaryPage.title}
        content={<div></div>}
        onStart={onStart}
      />
    </Interlayer>
  );
}
