import { useLoader } from '../../../shared/components/Loader/useLoader';
import { useAppStore } from '../../../store';
import { validateRequest } from '../requests/validateRequest';
import { ChosenScenario } from '../../../shared//models/ChosenScenario';
import { Module } from '../../../shared/models/Module';
import { useGlobalCommand } from '../../../shared/commands/useGlobalCommand';
import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';

export function useValidate() {
  const { showLoaderWhile } = useLoader();
  const { redirectAfterValidateCommand } = useGlobalCommand();
  const { legalValidateEvent } = useAnalytics();
  const store = useAppStore();
  const { customerid } = useParams();
  async function validateCommand(scenario: ChosenScenario) {
    const model = store.getState().legalPension.results!;

    await showLoaderWhile(async () => {
      await validateRequest(model.id, scenario, customerid);
      legalValidateEvent(model, scenario);
      await redirectAfterValidateCommand(Module.LegalPension);
    }, {key: "legalPension_validateCommand", value:`API POST - ${apiUrls(customerid).legalPension.validations}`});
  }

  return { validateCommand };
}
