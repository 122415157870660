import classNames, { Argument } from "classnames";
import { FunctionComponent, MouseEventHandler, SVGProps } from "react";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import styles from "./AnswerButton.module.scss";

interface AnswerButtonProps {
  className?: Argument;
  label: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: MouseEventHandler;
  selected?: boolean;
  autoFocus?: boolean;
}

export function AnswerButton(props: AnswerButtonProps) {
  const Icon = props.icon;

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        styles.Button,
        {
          [styles.Selected]: props.selected,
          [styles.HasIcon]: Boolean(props.icon),
          [styles.NoIcon]: !props.icon
        },
        props.className
      )}
      autoFocus={props.autoFocus}
    >
      {Icon && (
        <span>
          <SvgWrapperComponent ariaHidden={true}>
            <Icon className={styles.Icon} />
          </SvgWrapperComponent>
        </span>
      )}
      <span className={styles.Label}>{props.label}</span>
    </button>
  );
}
