import {
  RecommendationOutputModel,
} from "../../../../../../modules/recommendation/models/RecommendationModel";
import {
  Button,
  ModalOutcome,
  StyledKendoModal,
  useModal,
} from "../../../../../../shared";
import { i18n } from "../../../../i18n";
import styles from "./TaxSavingMoreInfoModal.module.scss";
import classNames from "classnames";
import {
  amount as formatAmount,
  percentage as formatPercentage,
} from "../../../../../../shared/utilities/intl";
import { CloseIcon } from "../../../../../../shared/images";
import { useAnalytics } from "../../../../../../shared/commands/useAnalytics";
import { useEffect } from "react";
import { ProductTypeIdentifier } from "../../../../../../modules/recommendation/models/product-type-identifier.enum";

interface Props {
  model: RecommendationOutputModel;
}

export function TaxSavingMoreInfoModal(props: Props) {
  const { hideModal } = useModal();

  const { taxSavingInfoModalEvent } = useAnalytics();
  const t = i18n.recommendationPage.taxSavingMoreInfoModal;

  const perData = props.model.transactions.productTypeTransactions.filter(
    (e) => e.productTypeId === ProductTypeIdentifier.PERIndividuel
  )[0];
  const taxSavingData = props.model!.taxSavings;

  const taxSavingAmount: number = taxSavingData.taxSavingsOnCapitalInvestment;
  const taxSavingAmountMonthly: number =
    taxSavingData.taxSavingsOnMonthlyInvestment;
  const amountPERAmount: number = perData.transactionAmount || 0;
  const amountPERAmountMonthly: number = perData.monthlyTransactionAmount || 0;
  const tot: number = taxSavingData.availableFiscalDeductionAmount;
  const tmiPercentage: number = taxSavingData.tmi;

  const fullData = {
    taxSavingAmount,
    taxSavingAmountMonthly,
    amountPERAmount,
    amountPERAmountMonthly,
    tot,
    tmiPercentage,
  };


  useEffect(() => {
    taxSavingInfoModalEvent(fullData)
  }, [])

  const taxSavingAmountTable: TaxSavingAmountTable[] = [
    {
      label: t.content.labels.amountPER,
      amount: amountPERAmount,
      amountMonthly: amountPERAmountMonthly,
    },
    {
      label: t.content.labels.taxSaving,
      amount: taxSavingAmount,
      amountMonthly: taxSavingAmountMonthly,
    },
  ];
  function closeModal() {
    hideModal(ModalOutcome.Cancel, null);
  }

  return (
    <StyledKendoModal
      title=""
      closeIcon={true}
      onClose={() => hideModal(ModalOutcome.Cancel, null)}
      width={"65%"}
      height={"95%"}
    >
      <div id={"modal-content"}>
        <Button
          className={styles.CloseIcon}
          onClick={closeModal}
          id="close-Icon"
        >
          <CloseIcon />
        </Button>

        <h1 className={styles.Title}> {t.title} </h1>
        <p className={styles.Desc}> {t.desc} </p>
        <section className={styles.Container}>
          <div>
            {taxSavingAmountTable.map((e, i) => (
              <div className={styles.Flex} key={i}>
                <div className={styles.NestedFlex}>
                  <span className={styles.LineInformation}>{e.label}</span>
                </div>

                <div className={styles.AmountWithLabel}>
                  <span className={classNames(styles.Label)}>
                    {t.content.labels.monthly}
                  </span>
                  <span className={classNames(styles.AmountValue)}>
                    {formatAmount(e.amountMonthly)}
                  </span>
                </div>

                <div className={styles.AmountWithLabel}>
                  <span className={classNames(styles.Label, styles.First)}>
                    {t.content.labels.open}
                  </span>
                  <span className={classNames(styles.AmountValue)}>
                    {formatAmount(e.amount)}
                  </span>
                </div>

                <div className={styles.Divider}></div>
              </div>
            ))}
          </div>

          <div className={styles.Footer}>
            <div className={styles.FooterContent}>
              <span className={classNames(styles.FooterLabel)}>
                {t.content.labels.taxAvailableCeiling}
              </span>
              <span className={classNames(styles.AmountFooter)}>
                {formatAmount(tot)}
              </span>
            </div>

            <div className={styles.FooterContent}>
              <span className={classNames(styles.FooterLabel)}>
                {t.content.labels.tmi}
              </span>
              <span className={classNames(styles.AmountFooter)}>
                {formatPercentage(tmiPercentage)}
              </span>
            </div>
          </div>
        </section>
        <div className={styles.Footnote}> {t.content.footnote} </div>
      </div>
    </StyledKendoModal>
  );
}

type TaxSavingAmountTable = {
  label: string;
  amount: number;
  amountMonthly: number;
};
