import { useAppStore, useAppDispatch } from '../../../store';
import { EComputationType } from '../enum';
import { PensionNeedQuestionnaireBody } from '../models/request/computationRequest.model';
import { setComputationId, setGamifiedValues } from '../pensionNeedSlice';
import { computeRequest } from '../requests/computeRequest';

export function useComputeBackend() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  async function computeBackendCommand(customerid?: string) {
    const answers = store.getState().pensionNeed.answers;
    const birthDate = store.getState().legalPension.answers.birthDate;
    if (answers == null) {
      return;
    }

    const payload: PensionNeedQuestionnaireBody = {
      $type: EComputationType.Questionnaire,
      birthDate,
      housing: answers.housing,
      categories: [...answers.health, ...answers.other, ...answers.transport, ...answers.supply]      
    }

    const response = await computeRequest(payload, customerid);

    dispatch(setComputationId(response.id));

    // The computation results are set as default for the gamified values
    dispatch(setGamifiedValues(response.result.categoriesDetails));
  }

  return { computeBackendCommand };
}
