import {
  Card,
  EditIcon,
  Button,
  ButtonStyles,
} from "../../../../../../../shared";
import { amountPerMonth } from "../../../../../../../shared/utilities/intl";
import styles from "./CategoryCard.module.scss";
import classNames from "classnames";
import { white } from "../../../../../../../styles/colors";
import { InfoIcon } from "../../../../../../../shared/images/index";
import { SvgWrapperComponent } from "../../../../../../../shared/components/SvgWrapper/SvgWrapperComponent";

export interface CategoryCardProps {
  icon: any;
  amount: number;
  label: string;
  onClick?: () => void;
  showInfoModal: () => void;
  id?: string;
}

export function CategoryCard(props: CategoryCardProps) {
  const Icon = props.icon;

  return (
    <Card className={styles.Card}>
      <SvgWrapperComponent ariaHidden={true}>
        <Icon className={styles.Icon} />
      </SvgWrapperComponent>
      <Button
        className={classNames(ButtonStyles.PensionNeedCategory, styles.Button)}
        onClick={props.onClick}
        id={props.id + "-amount"}
      >
        <span className={styles.ButtonChildren}>
          {amountPerMonth(props.amount)}
          <EditIcon color={white} />
        </span>
      </Button>
      <button
        className={styles.LabelBox}
        onClick={props.showInfoModal}
        id={props.id + "-info"}
      >
        <span className={styles.Label}>{props.label}</span>
        <span
          aria-label={`Informations complémentaires`}
          className={styles.InfoIcon}
          onClick={props.showInfoModal}
        >
          <SvgWrapperComponent ariaHidden={true}>
            <InfoIcon />
          </SvgWrapperComponent>
        </span>
      </button>
    </Card>
  );
}
