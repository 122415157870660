export enum ProfessionalStatus {
  Artisan = 'Artisan',
  CARPIMKO = 'CARPIMKO',
  CIPAV = 'CIPAV',
  Commercant = 'Commercant',
  Fonctionnaire = 'Fonctionnaire',
  MSANonSalarie = 'MSANonSalarie',
  Salarie = 'Salarie',
  Other = 'Other'
}
