import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  appointmentReducer,
  AppointmentState,
} from "./modules/appointment/appointmentSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  authenticationReducer,
  AuthenticationState,
} from "./modules/authentication/authenticationSlice";
import {
  taxationReducer,
  legalPensionReducer,
  pensionNeedReducer,
  dashboardReducer,
  progressReducer,
  patrimonyReducer,
  lifeProjectsReducer,
  recommendationReducer,
  loaderReducer,
  tenantReducer,
  investorProfileReducer
} from "@ifs/libs";

import { RootState } from "@ifs/libs/src/store";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {
    console.log(error)
  }
};

export interface AppRootState extends RootState {
  authentication: AuthenticationState;
  appointment: AppointmentState;
}

export const store = configureStore<AppRootState>({
  reducer: (state, action) => {
    return combineReducers({
      authentication: authenticationReducer,
      appointment: appointmentReducer,
      loader: loaderReducer,
      progress: progressReducer,
      pensionNeed: pensionNeedReducer,
      legalPension: legalPensionReducer,
      taxation: taxationReducer,
      patrimony: patrimonyReducer,
      lifeProjects: lifeProjectsReducer,
      recommendation: recommendationReducer,
      dashboard: dashboardReducer,
      investorProfile: investorProfileReducer,
      tenant: tenantReducer
    })(state, action);
  },
  preloadedState: loadState(),
});

store.subscribe(() => saveState(store.getState()));

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
