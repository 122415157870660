import { i18n } from "../../i18n";
import {
  Button,
  ButtonStyles,
  Container,
  PageTitle,
  SvgWrapperComponent,
} from "../../../../shared";
import styles from "./RecommendationProductsPage.module.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { globali18n } from "../../../../i18n";
import { ProductInterface } from "../../requests/getProducts";
import { assets as Assets } from "../../Assets";
import {
  ProductCard,
  ProductCardProps,
} from "../../components/ProductCard/ProductCard";
import { ProductTypeIdentifier } from "../../models/product-type-identifier.enum";
import { useGetProductsCommand } from "../../commands/getProductsCommand";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { useGoToAppointmentCommand } from "../../commands/goToAppointmentCommand";
import { useAppSelector } from "store";
import { UserType } from "../../../../shared/requests/getUserReferenceRequest";
import { useGoToModule } from "../../../../modules/dashboard/commands/goToModule";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { RecommendationFooter } from "../../../../modules/recommendation/components/Footer/Footer";
import { useViewReportCommand } from "../../../../modules/recommendation/commands/viewReportCommand";

export function RecommendationProductsPage() {
  const navigate = useNavigate();
  const { customerid } = useParams();

  const { getProductsCommand } = useGetProductsCommand();
  const { moduleRoutes } = useGoToModule();
  const { downloadReport, makeAppointment } = useAnalytics();
  const { goToAppointmentCommand } = useGoToAppointmentCommand();
  const { viewReportCommand } = useViewReportCommand();
  const [productCardProps, setProductCardProps] =
    useState<ProductCardProps[]>();
  const [dataProductLoaded, setDataProductLoaded] = useState<boolean>(false);
  const userType = useAppSelector((s) => s.authentication.userType);
  const dashboard = useAppSelector((s) => s.dashboard);

  useEffect(() => {
    getProductsLink();
  }, []);

  function canMakeAnAppointment(): boolean {
    return userType !== UserType.Advisor;
  }

  async function getProductsLink() {
    const products = await getProductsCommand(customerid);
    products.sort((a, b) => {
      if (a.productTypeIdentifier === b.productTypeIdentifier) return 0;
      if (b.productTypeIdentifier === ProductTypeIdentifier.PEA) return -1; // PEA last position
      return a.productTypeIdentifier <= b.productTypeIdentifier ? -1 : 1;
    });
    setProductCardProps(createProductCardProps(products));
    setDataProductLoaded(true);
  }

  function createProductCardProps(
    products: ProductInterface[]
  ): ProductCardProps[] {
    return products.map((product) => {
      let extraPrimaryAction;
      let extraPrimaryActionWording;
      // PER product have an extra action on their card
      if (
        product.productTypeIdentifier === ProductTypeIdentifier.PERIndividuel
      ) {
        extraPrimaryAction = goToTaxation;
        extraPrimaryActionWording = t.ctas.goToTaxation;
      }

      return {
        product,
        extraPrimaryAction,
        extraPrimaryActionWording,
      };
    });
  }

  async function goToReport() {
    downloadReport();
    await viewReportCommand();
  }

  function back(): void {
    navigate(-1);
  }

  async function goToTaxation(): Promise<void> {
    const status = dashboard.model?.isTaxationCompleted
      ? ModuleStatus.Completed
      : ModuleStatus.Available;
    await navigate(moduleRoutes.Taxation[status]);
  }

  async function goToAppointment(): Promise<void> {
    makeAppointment();
    await goToAppointmentCommand();
  }

  const t = i18n.recommendationProductsPage;

  if (!dataProductLoaded) return <></>;

  return (
    <>
      <Container className={styles.Container}>
        <Button
          onClick={back}
          className={classNames(styles.Back, ButtonStyles.Back)}
        >
          &lsaquo; {globali18n.labels.back}
        </Button>
        <PageTitle>{t.title}</PageTitle>
        <div className={styles.CardGrid}>
          <div className={styles.HeaderContainer}>
            <div className={classNames(styles.Icon, styles.IconLeft)}>
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.RecoStaticLeftImage />
              </SvgWrapperComponent>
            </div>
            <div>
              <p className={styles.Subtitle}> {t.subTitle} </p>
              {canMakeAnAppointment() && (
                <Button
                  className={classNames(
                    ButtonStyles.PrimaryAction,
                    styles.Subscribe
                  )}
                  onClick={goToAppointment}
                >
                  {t.ctas.rendezVous}
                </Button>
              )}
            </div>

            <div className={styles.Icon}>
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.RecoStaticRightImage />
              </SvgWrapperComponent>
            </div>
          </div>

          <div className={styles.ProductCardContainer}>
            {productCardProps &&
              productCardProps.map((e, i) => (
                <ProductCard
                  key={e.product.productIdentifier}
                  product={e.product}
                  extraPrimaryAction={e.extraPrimaryAction}
                  extraPrimaryActionWording={e.extraPrimaryActionWording}
                />
              ))}
          </div>
          <div className={styles.FooterActionContainer}>
            <div className={styles.FooterAction}>
              <RecommendationFooter
                buttonStyleUnfill={true}
                buttonCustomWithClass={styles.ReportButton}
                goToReport={goToReport}
              ></RecommendationFooter>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
