import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";

export async function validateRiskProfile(customerId: string | undefined, riskProfileIdentifier: string) {
  const apiUrl =
    apiUrls(customerId).investorProfile.riskProfile.validateRiskProfile(riskProfileIdentifier);
  const response = await axios.put(apiUrl);

  const data = response.data;

  return data;
}
