import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableAppointment } from './models/AppointmentOutputModel';

export interface AppointmentState {
  available: AvailableAppointment[] | null;
}

const initialState: AppointmentState = {
  available: null
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: initialState,
  reducers: {
    setAvailable(state, action: PayloadAction<AvailableAppointment[]>) {
      state.available = action.payload;
    },
    resetAppointment: () => {
      return { ...initialState };
    }
  }
});

export const { setAvailable, resetAppointment } = appointmentSlice.actions;
export const appointmentReducer = appointmentSlice.reducer;
