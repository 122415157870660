import { globali18n } from "../../../../i18n";
import { CardTitle } from "../../../../shared/components/Card/Card";
import { ModuleColor } from "../../../../styles/colors";
import { i18n } from "../../i18n";
import { ModuleCard } from "../ModuleCard/ModuleCard";
import styles from "./Information.module.scss";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { Module } from "../../../../shared/models/Module";
import { useGoToModule } from "../../../../modules/dashboard/commands/goToModule";
import { assets as Assets } from "../../../../modules/dashboard/Assets";
import { useRestoreData } from "../../../../modules/pension-need/commands/restoreSummary";
import { useParams } from "react-router-dom";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import classNames from "classnames";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";

interface InformationProps {
  pensionNeedAmount: number | null;
  pensionNeedStatus: ModuleStatus;
  lifeProjectsAmount: number | null;
  lifeProjectsStatus: ModuleStatus;
}

export function Information(props: InformationProps) {
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const { goToModuleCommand } = useGoToModule();
  const { dashboardCardEvent } = useAnalytics()
  const { restoreData } = useRestoreData();
  const { customerid } = useParams(); 
  const isDashboardStatic = isTenantRecommendationModeStatic();


  function goToPensionNeed() {
    restoreData(customerid);
    goToModuleCommand(Module.PensionNeed);
    dashboardCardEvent("Besoin retraite")
  }
  function goToLifeProjects() {
    goToModuleCommand(Module.LifeProjects);
    dashboardCardEvent("Projets de vie")
  }

  return (
    <div className={styles.Container}>
      <CardTitle>{i18n.dashboardPage.information.title}</CardTitle>
      <div className={classNames(styles.CardGrid, isDashboardStatic && styles.CardGridDashboardStatic)}>
        <ModuleCard
          icon={Assets.PensionNeedIcon}
          unCompletedIcon={Assets.PensionNeedIconUncompleted}
          lockIcon={Assets.PensionNeedIconLock}
          color={ModuleColor.PensionNeed}
          title={i18n.moduleNames[Module.PensionNeed]}
          amount={props.pensionNeedAmount}
          status={props.pensionNeedStatus}
          unit={globali18n.labels.eurosPerMonth}
          onClick={goToPensionNeed}
        />

        <ModuleCard
          icon={Assets.LifeProjectsIcon}
          unCompletedIcon={Assets.LifeProjectsIconUncompleted}
          lockIcon={Assets.LifeProjectsIconLock}
          color={ModuleColor.LifeProjects}
          title={i18n.moduleNames[Module.LifeProjects]}
          amount={props.lifeProjectsAmount}
          status={props.lifeProjectsStatus}
          unit={globali18n.labels.eurosPerMonth}
          onClick={goToLifeProjects}
        />
      </div>
    </div>
  );
}
