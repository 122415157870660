import { auro_metal_saurus, transparent } from '../../../styles/colors';
import { ModuleStatus } from '../models/ModuleStatus';

export function determineModuleBackgroundColor(status: ModuleStatus, moduleColor: string) {

  if (status === ModuleStatus.Locked) {
    return {
      backgroundColor: transparent,
      border: `1px solid ${auro_metal_saurus}`,
      color: auro_metal_saurus
    };
  }

  if (status === ModuleStatus.Available) {
    return {
      backgroundColor: transparent,
      border: `1px solid ${moduleColor}`,
      color: moduleColor
    };
  }

  if(status === ModuleStatus.CompletedAndLocked) {
    return {
      color: moduleColor,
      border: `2px solid ${moduleColor}`,
      backgroundColor: "#DCDFE0",
      opacity: 0.3
    }
  }

  return {
    backgroundColor: moduleColor
  };
}
