import { useLoader } from "@ifs/libs";
import { changePasswordRequest } from "../requests/changePasswordRequest";
import { ChangePasswordModel } from "../models/ChangePassword.model";

export function useChangePassword() {
  const { showLoaderWhile } = useLoader();
  
  async function changePasswordCommand(model: ChangePasswordModel): Promise<any> {
    return await showLoaderWhile(async () => {
        return changePasswordRequest(model)
    }, {key: "useChangePassword", value:`API PUT - /api/auth/change_password}`});
  }

  return { changePasswordCommand };
}
