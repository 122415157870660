import { useGlobalCommand, useLoader } from '@ifs/libs';
import { useAnalytics } from '@ifs/libs/src/shared/commands/useAnalytics';
import { deleteAccountRequest } from '../requests/deleteAccountRequest';
import { deleteCustomerDataRequest } from '../requests/deleteCustomerDataRequest';

export function useDeleteAccount() {
  const { isAuthenticationModeSSO } = useGlobalCommand();
  const { showLoaderWhile } = useLoader();
  const { accountDeletionEvent } = useAnalytics();

  async function deleteAccountCommand() {
    await showLoaderWhile(async () => {
      if(isAuthenticationModeSSO()) {
        // On SSO Authentication, we only clean customer data
        await deleteCustomerDataRequest();
      } else {
        await deleteAccountRequest();
      }
      accountDeletionEvent();
    }, {key: "useDeleteAccount", value:`API DELETE Customer`});
  }

  return { deleteAccountCommand };
}
