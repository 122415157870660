import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { DashboardModel } from "../models/DashboardModel";

export async function getDashboardRequest(customerid?: string) {
    const apiUrl = apiUrls(customerid).dashboard
    const response = await axios.get<DashboardModel>(apiUrl);
    const data = response.data;

    return data;
}