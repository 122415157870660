import { NumericTextBox } from "@progress/kendo-react-inputs";
import classNames from "classnames";
import { Button, ButtonStyles } from "../../../../../shared";
import styles from "./PlusAndMinusInput.module.scss";

interface PlusAndMinusInputProps {
    minusHandle: () => void;
    onChangeHandler: (arg0: any) => void;
    initialValue: number;
    plusHandler: () => void;
}

export function PlusAndMinusInput(props: PlusAndMinusInputProps) {
  return (
    <div className={styles.AmountContainer}>
    <Button
      className={classNames(styles.ActionButton, ButtonStyles.Round)}
      onClick={props.minusHandle}
      type="button"
    >
      -
    </Button>
    <NumericTextBox
      onChange={props.onChangeHandler}
      value={props.initialValue}
      spinners={false}
      format={{ maximumFractionDigits: 0 }}
      width={"150px"}
    />
    <Button
      className={classNames(styles.ActionButton, ButtonStyles.Round)}
      onClick={props.plusHandler}
      type="button"
    >
      +
    </Button>
  </div>
  )
}
