import { Container } from "../../../shared";
import { Title } from "../components/Title";
import { assets as Assets } from "../Assets";
import { i18n } from "../i18n";
import styles from "./Cookies.module.scss";
import { useEffect, useState } from "react";
import { useExternalUrls } from "../../../shared/utilities/externalUrls";

export function CookiesPage() {
  const { externalUrls } = useExternalUrls();
  
  const [state, setState] = useState<string>("oui");
  
  const [privacyPage, setPrivacyPage] = useState<string>("");
  
  function throwEvent() {
    window.dataLayer?.push({
      event: "at_consent",
      visitor_mode: "optout",
    });
  }

  function changeAtInternet(e: any) {
    if (e.target.value === "oui") {
      sessionStorage.setItem("atInternet", "true");
      throwEvent();
      setState("oui")
    } else {
      sessionStorage.setItem("atInternet", "false");
      throwEvent();
      setState("non")
    }
  }

  useEffect(() => {
    (async () => {
      const urls = await externalUrls();
      setPrivacyPage(urls.PrivacyPage);
      const store = sessionStorage.getItem("atInternet");
      if (!store) {
        sessionStorage.setItem("atInternet", "true");
      } else {
        if (store === "true") {
          setState("oui")
        } else {
          setState("non")

        }
      }
    })();
  }, []);

  return (
    <div className={styles.Body}>
      <div className={styles.ImageBox}>
        <Assets.TopImage className={styles.Image} />
      </div>
      <Container>
        <h1 className={styles.PageTitle}>
          Politique d’utilisation des cookies
        </h1>
        <div className={styles.Container}>
          {i18n.topBloc}
          <Title>1. Qu’est-ce qu’un cookie ?</Title>         
          <div>{i18n.whatIsACookie}</div>
          <Title>
            2. Quels types d’information peuvent être stockés dans un cookie ?
          </Title>
          <div>{i18n.typeOfInformationStored}</div>       
          <p>
            Les cookies peuvent contenir des données à caractère personnel
            couvertes par notre{" "}
            <a
              href={privacyPage}
              className={styles.ExternalLink}
              target={"_blank"}
              rel={"nofollow"}
            >
              Notice de Protection des Données
            </a>
            .
          </p>
          <Title>
            3. Quel type de cookies utilisons nous et à quelles fins ?
          </Title>
          <div>{i18n.typeOfUsage}</div>

          <Title>        
           {i18n.cookiesTitle}
          </Title>
          {i18n.cookiesExplain}
          <p>
            <strong>4.1 Cookies strictement nécessaires</strong>
          </p>
          <p>
            <strong>OneTrust</strong>
          </p>
          <p>Ces Cookies sont utilisés par OneTrust pour :</p>
          <p>
            <ul>
              <li>des raisons de sécurité informatique</li>
              <li>
                conserver un enregistrement lorsque l'utilisateur ferme l'avis
                de cookie
              </li>
              <li>
                collecter et maintenir la traçabilité du consentement donné par
                l'utilisateur
              </li>
              <p>
                Vous pouvez obtenir de plus amples informations sur le site Web
                de OneTrust en suivant ce lien :&nbsp;
                <a
                  className={styles.ExternalLink}
                  href="https://www.onetrust.fr/"
                  target={"_blank"}
                  rel={"nofollow"}
                >
                  https://www.onetrust.fr/
                </a>
              </p>
            </ul>
          </p>
          <p>
            Liste des Cookies du partenaire utilisés sur le Site et durée de
            conservation de ces cookies par le partenaire :<br />
            __cfduid : 13 mois
            <br />
            OptanonAlertBoxClosed : 13 mois
            <br />
            OptanonConsent : 13 mois
          </p>
          {i18n.cookiesIAdvize}
          <p>
            <strong>4.2 Autres cookies</strong>
          </p>
          <p>
            <strong>Cookies de mesure d'audience</strong>
          </p>
          <p>
            <strong>ABTasty</strong>
          </p>
          <p>
            Ces cookies sont utilisés par l’opérateur du site web dans le cadre
            de tests multi-variables pour améliorer l'ergonomie et l'expérience
            utilisateur.
          </p>
          <p>
            Vous pouvez obtenir de plus amples informations sur le site Web de
            AbTasty en suivant ce lien :&nbsp;
            <a
              className={styles.ExternalLink}
              href="https://www.abtasty.com/fr/politique-protection-vie-privee/"
              target={"_blank"}
              rel={"nofollow"}
            >
              https://www.abtasty.com/fr/politique-protection-vie-privee/
            </a>
          </p>
          <p>
            Liste des Cookies du partenaire utilisés sur le Site et durée de
            conservation de ces cookies par le partenaire :<br />
            ABTasty : 13 mois
            <br />
            ABTastyDomainTest : Session
            <br />
            ABTastySession : Quelques secondes
          </p>
          <p>
            <strong>AT Internet</strong>
          </p>
          <p>
            Permet la réalisation de statistiques de fréquentation et d’analyser
            le comportement des utilisateurs sur le site.
          </p>
          <p>
            Vous pouvez obtenir de plus amples informations sur le site Web de
            AT Internet en suivant ce lien :&nbsp;
            <a
              className={styles.ExternalLink}
              href="https://www.atinternet.com/rgpd-et-vie-privee/"
              target={"_blank"}
              rel={"nofollow"}
            >
              https://www.atinternet.com/rgpd-et-vie-privee/
            </a>
          </p>
          <p>
            Liste des Cookies du partenaire utilisés sur le Site et durée de
            conservation de ces cookies par le partenaire :<br />
            pa_vid : expire après 13 mois
            <br />
            pa_user : expire après 13 mois
            <br />
            pa_privacy : expire après 13 mois
          </p>          
          <p>
            Nous utilisons des outils de mesure d’audience qui permettent de
            bénéficier de l’exemption de votre consentement afin d’évaluer la
            performance de contenu du Site conformément aux conditions précisées
            par la CNIL. Vous avez la possibilité de vous opposer au dépôt de
            ces cookies ci-après :
          </p>

          <p className={styles.InputGroup}>
            <div>
              <input
                type="radio"
                value="oui"
                checked={state === "oui"}
                onChange={changeAtInternet}
              />
              <label htmlFor="oui" className={styles.Labels}>
                Oui
              </label>
            </div>
            <div>
              <input
                type="radio"
                value="non"
                checked={state === "non"}
                onChange={changeAtInternet}
              />
              <label htmlFor="non" className={styles.Labels}>
                Non
              </label>
            </div>
            <div className={styles.ATDisclaimert}>
              Je comprends qu’après l’activation de cette option, je serai
              comptabilisé comme un internaute refusant d’être audité et que mes
              données de navigation seront exclues des données restituées par AT
              Internet.
            </div>
          </p>
          <p>
            <strong>
              Cookies de publicité personnalisée et de partage de contenu
            </strong>
          </p>            
          {i18n.cookiesYoutube}
          {i18n.cookiesYahoo}
          {i18n.cookiesDoubleClick}
          {i18n.cookiesGoogle}

          <Title>5. Comment gérer les cookies ?</Title>

          {i18n.howHandleCookies}
        </div>
      </Container>
    </div>
  );
}