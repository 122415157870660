import { globali18n } from '@ifs/libs/src/i18n';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { i18n } from '../../i18n';

export function getFormConfig(register: UseFormRegister<any>, watch: UseFormWatch<any>) {
  const formConfig = {
    currentPassword: {
      field: register('currentPassword', { required: true}),
      errors: {
        required: globali18n.errors.required,
        incorrect: i18n.setCustomerPasswordForm.labels.currentPasswordError
      }
    },
  };

  return formConfig;
}
