import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { i18n } from '../../../../modules/appointment/i18n';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { globali18n } from '@ifs/libs/src/i18n';
import { sharedRules } from '../../../../shared/sharedRules';

export function getFormConfig(register: UseFormRegister<any>, watch: UseFormWatch<any>) {
  const rules = sharedRules(register);

  const formConfig = {
    ...rules,
    phoneNumber: {
      rules: {
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : isValidPhoneNumber(v))
        }
      },
      errorMessages: {
        format: i18n.appointmentPage.phoneNumber.errors.format
      }
    },
    postalCode: {
      field: register('postalCode', { required: true }),
      errors: {
        required: globali18n.errors.required,
        pattern: globali18n.errors.pattern
      }
    }
  };

  return formConfig;
}
