import { setModel } from "../../modules/dashboard/dashboardSlice";
import { DashboardModel } from "../../modules/dashboard/models/DashboardModel";
import { getDashboardRequest } from "../../modules/dashboard/requests/getDashboardRequest";
import { useAppDispatch, useAppSelector } from "store";

export function useGetDashboardModel() {
    const dashboard = useAppSelector((s) => s.dashboard);
    const dispatch = useAppDispatch();

    // For some case, we need to force the new api call
    // For example when we finished the mandatory module, we need to catch new data for dashboard service to apply right redirection
    async function getDashboardModelCommand(forceUpdateDashboardModel: boolean, customerid?: string): Promise<DashboardModel> {
        // Data not define need get it from api call service
        // And check if dashboard has be shown one time at least
        if(!dashboard.initialized || forceUpdateDashboardModel) {
            const model = await getDashboardRequest(customerid);
            dispatch(setModel(model));
            return model;
        } else {
            return dashboard.model!;        
        }
    }

    return { getDashboardModelCommand }
}