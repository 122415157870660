import { Module } from "../../../../shared";
import { BarChartItem } from "./components/BarChartIem";

interface DashboardChartData {
  legalPension: number;
  patrimony: number;
  pensionNeeds: number;
  lifeProjects: number;
}

interface DashboardChartProps {
  className?: string;
  data: DashboardChartData;
}

const percentageCalculator = (value: number, hundred: number) =>
  Math.round((hundred / value) * 100);

const svgWidth = 100;
const svgHeight = 200;

export function DashboardChart(props: DashboardChartProps) {
  const { legalPension, pensionNeeds, patrimony, lifeProjects } = props.data;

  const needsTotal = pensionNeeds + lifeProjects;
  const incomTotal = legalPension + patrimony;

  const chartBarSize = (total: number, anyVal: number) => {
    const percentage = percentageCalculator(total, anyVal);
    return {
      y: svgHeight - percentage * 2,
      height: percentage - 1,
    };
  };

  const lifeProjectsChartData = () => {
    if (needsTotal < incomTotal) {
      let height = Math.round((100 * lifeProjects) / incomTotal);
      return {
        y: svgHeight - height * 2 + 1,
        height: height,
        x: 0,
      };
    }

    return {
      y: chartBarSize(needsTotal, lifeProjects).y + 1,
      height: chartBarSize(needsTotal, lifeProjects).height + 1,
      x: 0,
    };
  };
  const pensionNeedsChartData = () => {
    if (needsTotal < incomTotal) {
      let height = Math.round((100 * pensionNeeds) / incomTotal);
      return {
        y: lifeProjectsChartData().y - height * 2 - 2,
        height: height,
        x: 0,
      };
    }

    return {
      y:
        chartBarSize(needsTotal, pensionNeeds).y -
        chartBarSize(needsTotal, pensionNeeds).y +
        1,
      height: chartBarSize(needsTotal, pensionNeeds).height,
      x: 0,
    };
  };

  const patrimonyChartData = () => {
    if (needsTotal > incomTotal) {
      let height = Math.round((100 * patrimony) / needsTotal);
      return {
        y: svgHeight - height * 2,
        height: height,
        x: 60,
      };
    }

    return {
      y: chartBarSize(incomTotal, patrimony).y + 1,
      height: chartBarSize(incomTotal, patrimony).height + 1,
      x: 60,
    };
  };

  const legalPensionChartData = () => {
    if (needsTotal > incomTotal) {
      let height = Math.round((100 * legalPension) / needsTotal);
      return {
        y: patrimonyChartData().y - height * 2 - 2,
        height: height,
        x: 60,
      };
    }

    return {
      y: chartBarSize(incomTotal, patrimony).y - patrimonyChartData().y,
      height: chartBarSize(incomTotal, legalPension).height + 1,
      x: 60,
    };
  };

  const barChart = [
    {
      module: Module.PensionNeed,
      chartData: pensionNeedsChartData(),
    },
    {
      module: Module.LifeProjects,
      chartData: lifeProjectsChartData(),
    },

    {
      module: Module.LegalPension,
      chartData: legalPensionChartData(),
    },

    {
      module: Module.Patrimony,
      chartData: patrimonyChartData(),
    },
  ];

  return (
    <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} className={props.className}>
      {barChart.map((e) => (
        <BarChartItem {...e} key={e.module} />
      ))}
    </svg>
  );
}
