import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CloseIcon } from "../../../../../../shared/images";
import styled from "styled-components";
import { i18n } from "../../../../../../modules/dashboard/i18n";
import {
  ButtonStyles,
  ModalOutcome,
  useModal,
  Button,
  SvgWrapperComponent,
  StyledKendoModal,
} from "../../../../../../shared";
import styles from "./ExpiredProfileInfoModal.module.scss";
import { GirlAndBoyIcon } from "./assets";

export enum ExpiredInvestorProfile {
  GoToInvestorProfile,
  Cancel,
}

export function ExpiredProfileInfoModal() {
  const { hideModal } = useModal();

  function goToProfile() {
    hideModal(ModalOutcome.Ok, ExpiredInvestorProfile.GoToInvestorProfile);
  }

  return (
    <>
      <StyledKendoModal
        contentStyle={"padding:30px 70px;"}
        title=""
        closeIcon={true}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={"80%"}
      >
        <CloseIcon
          className={styles.CloseIcon}
          onClick={() => hideModal(ModalOutcome.Cancel, null)}
        />
        <h1 className={styles.Title}>
          {" "}
          {i18n.expiredInvestorProfileModal.title}{" "}
        </h1>
        <div className={styles.Body}>
          <SvgWrapperComponent ariaHidden={true}>
            <GirlAndBoyIcon />
          </SvgWrapperComponent>
          <p>{i18n.expiredInvestorProfileModal.content}</p>
          <Button className={ButtonStyles.PrimaryAction} onClick={goToProfile}>
            {i18n.expiredInvestorProfileModal.goToInvestorProfile}
          </Button>
        </div>
      </StyledKendoModal>
    </>
  );
}
