import { useLoader } from '../../../shared';
import { useAppDispatch } from 'store';
import {  RealEstateType, deleteRealEstate } from '../patrimonySlice';
import { useParams } from 'react-router-dom';
import { deleteRealEstateRequest } from '../requests/deleteRealEstateRequest';

export function useDeleteRealEstateCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function deleteRealEstateCommand(
    type: RealEstateType,
    position: number,
    realEstateId: string
  ) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update RealState list localy
      await deleteRealEstateRequest(realEstateId, customerid);
      dispatch(deleteRealEstate({ type, position }));
    }, {key: "useDeleteRealEstateCommand", value:`Delay before dispatch deleteRealEstateCommand on deleteRealEstate.ts file`});
  }

  return { deleteRealEstateCommand };
}
