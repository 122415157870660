import { Fluctuation } from '../../../models/RiskProfileEnum';
import { createEnumPage } from '../../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../../commands/answerCommands';
import { BackgroundImage } from '../../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../../i18n';

export const InvestmentFluctuation = createEnumPage<Fluctuation>({
  selector: (s) => s.investorProfile.riskProfile.fluctuation,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerFluctuation,
  values: [
    {
      label: i18n.riskProfile.fluctuationPage.answer1,
      value: Fluctuation.Limited
    },
    {
      label: i18n.riskProfile.fluctuationPage.answer2,
      value: Fluctuation.Important
    }
  ],
  question: i18n.riskProfile.fluctuationPage.question,
  backgroundImage: BackgroundImage
});