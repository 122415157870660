import { useEffect, useState } from "react";
import { useAppStore } from "../../store";
import { useAppUrls } from "../../shared/utilities/urls";
import { useNavigate } from "react-router-dom";

export function InvestorProfileQuestionnaireGuardRoute({ children }: any) {
    const appUrls = useAppUrls();
    const store = useAppStore();
    const navigate = useNavigate();

    const [redirectTo, setRedirectTo] = useState<string | null>(null);

    useEffect(() => {
        const isInvestorProfileAlreadyRender = store.getState().investorProfile.isSummaryPageRender;
        if (isInvestorProfileAlreadyRender) {
            // Cant reanswer questionnaire because Summary page already generate and render
            // Need return and complete the questionnaire by click on FinancialProfileSummary page
            setRedirectTo(appUrls.investorProfile.financialProfile.summary);
        }
    });

    if (redirectTo) {
        navigate(redirectTo);
        return;
    } else {
        return children;
    }
}
