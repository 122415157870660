import { ExcludedProductInvestmentTransaction } from "../models/RecommendationModel";
import { ExclusionReason } from "../models/exclusion-reason.enum";
import { InvestmentScope } from "../models/investment-scope.enum";
import { ProductTypeIdentifier } from "../models/product-type-identifier.enum";

export function getFullLockCell(
  identifier: ProductTypeIdentifier,
  excludedProductInvestment: ExcludedProductInvestmentTransaction
) {
  const isFullExclude = excludedProductInvestment.excludedProductTypes.find(
    (e) => e.productTypeId === identifier
  );

  if (isFullExclude)
    return {
      reason: isFullExclude.exclusionReason,
      isExclude: true,
    };
  return {
    reason: null,
    isExclude: false,
  };
}

export function getPartialLock(
  identifier: ProductTypeIdentifier,
  excludedProductInvestment: ExcludedProductInvestmentTransaction
) {
  const hasInvesmentCellExclude = excludedProductInvestment.excludedScopes.find(
    (e) => e.investmentScope === InvestmentScope.InitialInvestment
  );
  const hasMonthlyCellExclude = excludedProductInvestment.excludedScopes.find(
    (e) => e.investmentScope === InvestmentScope.PeriodicInvestment
  );

  const isExclude = excludedProductInvestment.excludedScopedInvestments.filter(
    (e) => e.productTypeId === identifier
  );

  let finalObject: FinalObject = {
    initialLock: {
      reason: null,
      isExclude: false,
    },
    monthlyLock: {
      reason: null,
      isExclude: false,
    },
  };

  if (isExclude.length > 0) {
    isExclude.map((e: any) => {
      if (finalObject.initialLock.isExclude) {
        if (
          finalObject.initialLock.reason ===
            ExclusionReason.NoInitialInvestment ||
          finalObject.initialLock.reason ===
            ExclusionReason.MinimumInitialInvestmentAmount
        ) {
          finalObject.monthlyLock = {
            reason: ExclusionReason.NoInitialInvestment,
            isExclude: true,
          };
        }
      }

      if (
        hasInvesmentCellExclude?.exclusionReason ===
        ExclusionReason.MinimumInitialInvestmentAmount
      ) {
        finalObject.initialLock = {
          reason: e.exclusionReason,
          isExclude: true,
        };
      } else {
        if (
          e.exclusionReason === ExclusionReason.MinimumInitialInvestmentAmount
        ) {
          finalObject.initialLock = {
            reason: e.exclusionReason,
            isExclude: true,
          };
        }
      }

      if (
        hasMonthlyCellExclude?.exclusionReason ===
        ExclusionReason.MinimumGlobalPeriodicInvestmentAmount
      ) {
        finalObject.monthlyLock = {
          reason: null,
          isExclude: false,
        };
      } else {
        finalObject.monthlyLock = {
          reason: e.exclusionReason,
          isExclude: true,
        };
      }
    });
  }

  return finalObject;
}

interface FinalObject {
  initialLock: { reason: ExclusionReason | null; isExclude: boolean };
  monthlyLock: { reason: ExclusionReason | null; isExclude: boolean };
}
