import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "../../shared/components/Theme/ThemeProvider";
import { ModuleColor } from "../../styles/colors";
import { RecommendationPage } from "./pages/RecommendationPage/RecommendationPage";
import { useResolver } from "./routing/resolver";
import { i18n } from "./i18n";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { AdvancedRecommendationPage } from "./pages/RecommendationPage/advanced/AdvancedRecommendationPage";
import { PageRoute } from "../../shared/routing/PageRoute";
import { RecommendationProductsPage } from "./pages/RecommendationProductsPage/RecommendationProductsPage";
import { RecommendationGuardedRoute } from "./guard/RecommendationGuardedRoute";

export function RecommendationModule() {
  const { loading } = useResolver();

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.Recommendation,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <RecommendationGuardedRoute>
              <AnalyticsComponent
                page_title={i18n.recommendationPage.title}
                page_path={"/recommendation"}
                page_type={"recommendation"}
                simulator_screen={"recommendation"}
                simulator_step={""}
                simulator_type={""}
              >
                <PageRoute
                  pageTitle={i18n.recommendationPage.title}
                  element={<RecommendationPage />}
                />
              </AnalyticsComponent>
            </RecommendationGuardedRoute>
          }
        />
        <Route
          path={`/products`}
          element={
            <RecommendationGuardedRoute>
              <AnalyticsComponent
                page_title={i18n.recommendationProductsPage.title}
                page_path={"/recommendation/products"}
                page_type={"recommendation"}
                simulator_screen={"recommendation"}
                simulator_step={""}
                simulator_type={""}
              >
                <PageRoute
                  pageTitle={i18n.recommendationProductsPage.title}
                  element={<RecommendationProductsPage />}
                />
              </AnalyticsComponent>
            </RecommendationGuardedRoute>
          }
        />
        <Route
          path={`/advanced`}
          element={
            <RecommendationGuardedRoute>
              <AnalyticsComponent
                page_title={i18n.recommendationPage.title}
                page_path={"/recommendation/advanced"}
                page_type={"recommendation"}
                simulator_screen={"recommendation"}
                simulator_step={""}
                simulator_type={""}
              >
                <PageRoute
                  pageTitle={i18n.recommendationPage.title}
                  element={<AdvancedRecommendationPage />}
                />
              </AnalyticsComponent>
            </RecommendationGuardedRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
