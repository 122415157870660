import styles from "./BackgroundImage.module.scss";
import { assets as Assets } from "../../../modules/dashboard/Assets";
import classNames, { Argument } from "classnames";
import { useEffect, useState } from "react";
import { SvgWrapperComponent } from "../SvgWrapper/SvgWrapperComponent";

export function BackgroundImage(props: {className?: Argument}) {
  const [isAdvisorFlow, setAdvisorFlow] = useState(false);

  useEffect(()=> {
    setAdvisorFlow(window.location.pathname.includes("flow"))
  }, [])
  return (
    <div className={classNames(styles.Padding, props.className, {[styles.AdvisorFlow]:isAdvisorFlow})}>
      <SvgWrapperComponent ariaHidden={true}><Assets.BackgroundImage className={styles.Image} /></SvgWrapperComponent>
    </div>
  );
}
