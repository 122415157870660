import classNames from "classnames";
import { globali18n } from "../../../../i18n";
import {
  ButtonStyles,
  Container,
  ModalOutcome,
  useModal,
  Button,
  SvgWrapperComponent,
  useInfoModal,
} from "../../../../shared";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useUpgradeAmountCommand } from "../../commands/upgradeAmountCommand";
import { useValidateCommand } from "../../commands/validateCommand";
import { i18n } from "../../i18n";
import { setAnswers } from "../../lifeProjectsSlice";
import { LifeProjectsModel } from "../../models/LifeProjectsModel";
import { BackgroundImage } from "./components/BackgroundImage/BackgroundImage";
import { EditModal } from "../../../../shared/components/EditModal/EditModal";
import { ProjectCard } from "./components/ProjectCard/ProjectCard";
import { ProjectIcons } from "./images";
import styles from "./ProjectsPage.module.scss";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { useEffect } from "react";
import { userestoreLifeProjects } from "../../../../modules/life-projects/commands/restoreLifeProjects";
import { useParams } from "react-router-dom";
import { setAmount } from "../../../../shared/layouts/ProgressLayout/progressSlice";
import { InfoIcon } from "../../../../shared/images";

const minValue = 0;
const maxValue = 9999999;

export function ProjectsPage() {
  const answers = useAppSelector((s) => s.lifeProjects.answers);
  const { upgradeAmountCommand } = useUpgradeAmountCommand();
  const { validateCommand } = useValidateCommand();
  const { showModal } = useModal();
  const { showInfoModal } = useInfoModal();
  const { lifeProjectEdit } = useAnalytics();
  const dispatcher = useAppDispatch();
  const { restoreLifeProjects } = userestoreLifeProjects();
  const { customerid } = useParams();

  useEffect(() => {
    restoreLifeProjects(customerid);
  }, []);

  async function edit(propertyName: keyof LifeProjectsModel) {
    const title = i18n.projectsPage[propertyName].title;
    lifeProjectEdit(title);
    const currentValue = answers[propertyName];

    const result = await showModal<number>(
      <EditModal
        title={title}
        value={currentValue}
        min={minValue}
        max={maxValue}
        minErrorMessage={i18n.projectsPage.editModal.errors.min}
        maxErrorMessage={i18n.projectsPage.editModal.errors.max}
      />
    );
    const newValue = result.value ?? 0;
    if (result.outcome === ModalOutcome.Ok) {
      dispatcher(setAnswers({ ...answers, [propertyName]: newValue }));

      //Update the value of the stepbar
      const total =  Object.values({ ...answers, [propertyName]: newValue }).reduce((a, b) => a + b, 0);
      dispatcher(setAmount({ amount: total, unit: globali18n.labels.euros }));
    }

    //Force focus on the last targeted element after closing modal
    const target = document.getElementById(propertyName);
    target!.focus();

    upgradeAmountCommand();
  }

  async function showLifeProjectsInfoModal() {
    await showInfoModal(i18n.projectsPage.instructionsModal.title, i18n.projectsPage.instructionsModal.desc, false, "", true);
  }

  async function validate() {
    await validateCommand();
  }

  const cardList = [
    {
      icon: ProjectIcons.realEstateAssets,
      title: i18n.projectsPage.realEstateAssets.title,
      description: i18n.projectsPage.realEstateAssets.description,
      amount: answers.realEstateAssets,
      onEdit: () => edit("realEstateAssets"),
      id: "realEstateAssets",
    },
    {
      icon: ProjectIcons.pleasurePurchases,
      title: i18n.projectsPage.pleasurePurchases.title,
      description: i18n.projectsPage.pleasurePurchases.description,
      amount: answers.pleasurePurchases,
      onEdit: () => edit("pleasurePurchases"),
      id: "pleasurePurchases",
    },
    {
      icon: ProjectIcons.family,
      title: i18n.projectsPage.family.title,
      description: i18n.projectsPage.family.description,
      amount: answers.family,
      onEdit: () => edit("family"),
      id: "family",
    },
    {
      icon: ProjectIcons.anticipation,
      title: i18n.projectsPage.anticipation.title,
      description: i18n.projectsPage.anticipation.description,
      amount: answers.anticipation,
      onEdit: () => edit("anticipation"),
      id: "anticipation",
    },
    {
      icon: ProjectIcons.philanthropy,
      title: i18n.projectsPage.philanthropy.title,
      description: i18n.projectsPage.philanthropy.description,
      amount: answers.philanthropy,
      onEdit: () => edit("philanthropy"),
      id: "philanthropy",
    },
    {
      icon: ProjectIcons.other,
      title: i18n.projectsPage.other.title,
      description: i18n.projectsPage.other.description,
      amount: answers.other,
      onEdit: () => edit("other"),
      id: "other",
    },
  ];

  return (
    <>
      <Container className={classNames(styles.Grid, styles.MarginBottom)}>
        <div className={styles.Instructions}>
        <button
        className={styles.LabelBox}
        onClick={showLifeProjectsInfoModal}
      >
        <span className={styles.Label}>{i18n.projectsPage.instructions}</span>
        <span
          aria-label={`Informations complémentaires`}
          className={styles.InfoIcon}
          onClick={showLifeProjectsInfoModal}
        >
          <SvgWrapperComponent ariaHidden={true}>
            <InfoIcon />
          </SvgWrapperComponent>
        </span>
      </button>
          
        </div>
        <div className={styles.CardGrid}>
          {cardList.map((e, index: number) => (
            <ProjectCard {...e} key={e.id + index} />
          ))}
        </div>
        <Button
          className={classNames(
            ButtonStyles.PrimaryAction,
            styles.ValidateButton
          )}
          onClick={validate}
        >
          {globali18n.labels.inTheBox}
        </Button>
      </Container>
      <BackgroundImage className={styles.BackgroundImageContainer} />
    </>
  );
}
