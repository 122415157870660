import { resetDashboard } from "../../modules/dashboard/dashboardSlice";
import { resetLifeProjects } from "../../modules/life-projects/lifeProjectsSlice";
import { resetPatrimony } from "../../modules/patrimony/patrimonySlice";
import { resetRecommendation } from "../../modules/recommendation/recommendationSlice";
import { useNavigate, useParams } from "react-router-dom";
import { resetLegalPension } from "../../modules/legal-pension/legalPensionSlice";
import { resetPensionNeed } from "../../modules/pension-need/pensionNeedSlice";
import { resetTaxation } from "../../modules/taxation/taxationSlice";
import { useAppDispatch, useAppSelector, useAppStore } from "../../store";
import { resetLoader } from "../components/Loader/loaderSlice";
import { useLoader } from "../components/Loader/useLoader";
import { resetProgress } from "../layouts/ProgressLayout/progressSlice";
import { Module } from "../models/Module";
import {
  getModuleCompletionRequest,
  ModuleCompletionModel,
} from "../requests/getModuleCompletionRequest";
import { useAppUrls } from "../utilities/urls";
import { resetInvestorProfile } from "../../modules/investor-profile/investorProfileSlice";
import { useGetDashboardModel } from "./useGetDashboardModel.command";

export function useGlobalCommand() {
  const appUrls = useAppUrls();
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();
  const { customerid } = useParams();
  const { getDashboardModelCommand } = useGetDashboardModel();   
  const store = useAppStore();
  const dashboardPrevented = useAppSelector((s) => s.dashboard.presented)!;
  const moduleSettings = useAppSelector((s) => s.tenant.ModuleSettings)!;

  async function resetAllCommand() {
    dispatch(resetLoader());
    dispatch(resetProgress());

    dispatch(resetPensionNeed());
    dispatch(resetLegalPension());
    dispatch(resetTaxation());
    dispatch(resetPatrimony());
    dispatch(resetLifeProjects());

    dispatch(resetDashboard());
    dispatch(resetRecommendation());
    dispatch(resetInvestorProfile())
  }

  async function generateProspectCommand() {
    await showLoaderWhile(
      async () => {
        await resetAllCommand();
      },
      {
        key: "generateProspectCommand",
        value: `Handle generate prospect user on reset the session storage}`,
      }
    );
  }

  // We ve got two mode for the recommendation
  // One Personalized mode that equal to the current implementation of the Recommendation (tenant Internaute & Matmut)
  // One Static mode for the new implementation need by BCEF tenant
  function isTenantRecommendationModeStatic(): boolean {
   const settingsFound =  moduleSettings.find((settings) => settings.Key === "RecommendationMode")
   return settingsFound ? settingsFound.Value === "Static" : false;
  } 

  function isAuthenticationModeSSO(): boolean {
    const settingsFound =  moduleSettings.find((settings) => settings.Key === "AuthenticationMode");
    return settingsFound ? settingsFound.Value === "OIDCAuthCode" : false;
  }

  function getRightRecommendationPageUri(): string {
    return isTenantRecommendationModeStatic() ? appUrls.recommendationProducts : appUrls.recommendation;
  }

  function getDashboardModulesAvailable(): Module[] {
    const commonModule = [Module.LegalPension, Module.PensionNeed, Module.LifeProjects, Module.Patrimony];
    // For Static Recommendation, we dont have access to the module InvestorProfile & Taxation module is render on 
    if(isTenantRecommendationModeStatic()) {
      return commonModule;
    }

    return [...commonModule, Module.Taxation, Module.InvestorProfile];
  }

 function isCompleted(
    completed: ModuleCompletionModel[],
    module: Module | string
  ): boolean {
    return completed.some((cm) => cm.module === module && cm.isCompleted);
  }

  async function redirectAfterValidateCommand(source: Module) {
    const modulesAvailable = getDashboardModulesAvailable();
    const isTaxationModuleAvailable = modulesAvailable.find((m) => m === Module.Taxation);
    const dashboardModel = store.getState().dashboard.model!;
    if (source === Module.PensionNeed) {
      const completed = await getModuleCompletionRequest(
        customerid,
        Module.LegalPension,
        Module.Taxation,
        Module.Patrimony,
        Module.LifeProjects
      );
      await checkNeedCatchNewDashboardData(dashboardModel.pensionNeedMonthlyAmount, customerid);

      if (!isCompleted(completed, Module.LegalPension)) {
        navigate(appUrls.legalPension.landing);
        return;
      }

      if (isTaxationModuleAvailable && !isCompleted(completed, Module.Taxation)) {
        navigate(appUrls.taxation.landing);
        return;
      }

      if (!isCompleted(completed, Module.Patrimony)) {
        navigate(appUrls.patrimony.landing);
        return;
      }

      if (!isCompleted(completed, Module.LifeProjects)) {
        navigate(appUrls.lifeProjects.landing);
        return;
      }
      navigate(appUrls.dashboard);
    }

    if (source === Module.LegalPension) {
      const completed = await getModuleCompletionRequest(
        customerid,
        Module.PensionNeed,
        Module.Taxation,
        Module.Patrimony,
        Module.LifeProjects
      );
      await checkNeedCatchNewDashboardData(dashboardModel.legalPensionMonthlyAmount, customerid);

      if (!isCompleted(completed, Module.PensionNeed)) {
        navigate(appUrls.pensionNeed.landing);
        return;
      }

      if (isTaxationModuleAvailable && !isCompleted(completed, Module.Taxation)) {
        navigate(appUrls.taxation.landing);
        return;
      }

      if (!isCompleted(completed, Module.Patrimony)) {
        navigate(appUrls.patrimony.landing);
        return;
      }

      if (!isCompleted(completed, Module.LifeProjects)) {
        navigate(appUrls.lifeProjects.landing);
        return;
      }

      navigate(appUrls.dashboard);
    }

    if (source === Module.Patrimony) {
      const completed = await getModuleCompletionRequest(
        customerid,
        Module.LifeProjects
      );
      await checkNeedCatchNewDashboardData(dashboardModel.patrimonyMonthlyAmount, customerid);

      if (!isCompleted(completed, Module.LifeProjects)) {
        navigate(appUrls.lifeProjects.landing);
        return;
      }
      navigate(appUrls.dashboard);
    }

    if (source === Module.Taxation) {
      const completed = await getModuleCompletionRequest(
        customerid,
        Module.PensionNeed,
        Module.LegalPension,
        Module.LifeProjects,
        Module.Patrimony
      );
      await checkNeedCatchNewDashboardData(dashboardModel.isTaxationCompleted, customerid);

      if (!isCompleted(completed, Module.PensionNeed)) {
        navigate(appUrls.pensionNeed.landing);
        return;
      }

      if (!isCompleted(completed, Module.LegalPension)) {
        navigate(appUrls.legalPension.landing);
        return;
      }

      if (!isCompleted(completed, Module.Patrimony)) {
        navigate(appUrls.patrimony.landing);
        return;
      }

      // When user complete taxation moudle, if we've got a static recommendation page
      // User must be redirected to the recommendation page
      if (isTenantRecommendationModeStatic()) {
        navigate(appUrls.recommendationProducts);
        return;
      }
      navigate(appUrls.dashboard);
    }

    if (source === Module.LifeProjects) {
      const completed = await getModuleCompletionRequest(
        customerid,
        Module.PensionNeed,
        Module.LegalPension,
        Module.Patrimony
      );
      await checkNeedCatchNewDashboardData(dashboardModel.lifeProjectsMonthlyAmount, customerid);

      if (!isCompleted(completed, Module.PensionNeed)) {
        navigate(appUrls.pensionNeed.landing);
        return;
      }

      if (!isCompleted(completed, Module.LegalPension)) {
        navigate(appUrls.legalPension.landing);
        return;
      }

      if (!isCompleted(completed, Module.Patrimony)) {
        navigate(appUrls.patrimony.landing);
        return;
      }

      if(customerid) {
        // If we come from the advisor flow we do not display the dashboard landing page
        navigate(appUrls.dashboard);
      } else {        
        // If the user already seen the diagnostic
        // we are not displaying the landing page
        if (dashboardPrevented >= 2) {
          navigate(appUrls.dashboard);
        } else {
          navigate(appUrls.dashboardLanding);
        }
      }
    }
  }

  async function checkNeedCatchNewDashboardData(dataToCheck: number | boolean, customerid?: string) {
    // If datato check is null, we need to re call /dashboard service to have the update model
    // And to correctly redirect user after module validation
    if(!dataToCheck &&  dataToCheck !== 0) {
      await  getDashboardModelCommand(true, customerid);
    }
  }

  return {
    isAuthenticationModeSSO,
    isTenantRecommendationModeStatic,
    getRightRecommendationPageUri,
    resetAllCommand,
    generateProspectCommand,
    getDashboardModulesAvailable,
    redirectAfterValidateCommand,
    isCompleted,
  };
}
