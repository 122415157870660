import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../../../shared/components/Loader/useLoader';
import { useAppUrls } from '../../../shared/utilities/urls';
import { useComputeBackend } from './computeBackendCommand';

export function useNextCommand() {
  const navigate = useNavigate();
  const { showLoaderWhile } = useLoader();
  const { computeBackendCommand } = useComputeBackend();
  const { customerid } = useParams();
  const appUrls = useAppUrls()

  async function nextCommand(next: NextActionPN) {
    await showLoaderWhile(async () => {
      if (next.compute) {
        await computeBackendCommand(customerid);
        navigate(appUrls.pensionNeed.summary);
      }

      if (next.route) {
        navigate(next.route!);
      }
    }, {key: "pensionNeed_useNextCommand", value:`Handle compute frontend or backend on file nextCommand.ts`});
  }

  return { nextCommand };
}

export interface NextActionPN  {
  compute?: boolean;
  route?: string;
  customerid?: string
}
