import { i18n } from '../../../i18n';
import { housingIcon } from '../../../images';
import { HousingType } from '../../../models/housing/housing-type.enum';
import { setHousingType } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const HousingTypePage = createPensionNeedPage<HousingType>({
  scaleValueSelector: (q) => q.housing.questionnaire?.type ?? null,
  i18nFn: (i18n) => i18n.housingTypePage,
  scaleValueReducer: setHousingType,
  answers: [
    { icon: housingIcon.House, label: i18n.housingTypePage.answer1, value: HousingType.House },
    {
      icon: housingIcon.Building,
      label: i18n.housingTypePage.answer2,
      value: HousingType.Apartment
    }
  ],
  nextPage: () => ({ route: '../housing/size' })
});
