import { setBirthdateValidationType } from "../../../modules/pension-need/pensionNeedSlice";
import { parse } from "query-string";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAnalytics } from "../../../shared/commands/useAnalytics";
import { ModalOutcome, useLoader, useModal } from "../../../shared";
import { SalaryPeriodicity } from "../../../shared/models/SalaryPeriodicity";
import { useAppUrls } from "../../../shared/utilities/urls";
import { useAppDispatch, useAppStore } from "../../../store";
import {
  setAnswers,
  setBirthdate,
  setEvolutionAfterSimulation,
  setEvolutionBeforeSimulation,
  setGender,
  setNumberOfChildren,
  setProfessionalStatus,
  setResults,
  setSalary,
  setStartDateOfActivity,
} from "../legalPensionSlice";
import { Gender } from "../models/Gender";
import { ProfessionalStatus } from "../models/ProfessionalStatus";
import { SalaryEvolution } from "../models/salary/SalaryEvolution";
import { OtherStatusModal } from "../pages/ProfessionalStatusPage/components/OtherStatusModal/OtherStatusModal";
import { computeRequest } from "../requests/computeRequest";
import { i18n } from "../i18n";
import { format } from "date-fns";
import { apiUrls } from "../../../shared/apiUrls";

export function useAnswerCommands() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { showLoaderWhile } = useLoader();
  const { showModal } = useModal();
  const { answerLegalEvent } = useAnalytics();
  const store = useAppStore();
  const appUrls = useAppUrls();
  const { customerid } = useParams();

  async function computeCommand() {
    await showLoaderWhile(async () => {
      const answers = store.getState().legalPension.answers;
      const results = await computeRequest(answers, customerid);
      dispatch(setResults(results));
      dispatch(setAnswers(results.inputs));

      navigate(appUrls.legalPension.summary);
    }, {key: "legalPension_computeCommand", value:`API POST - ${apiUrls(customerid).legalPension.computations}`});
  }

  async function next(nextRoute: string) {
    // We are coming from the summary and going back to it
    if (parse(search).summary === "true") {
      await computeCommand();
      return;
    }

    // Otherwise, go to next step
    await showLoaderWhile(async () => {
      navigate(nextRoute);
    }, {key: "legalPension_next", value:`Delay before navigate next app route ${nextRoute}`});
  }

  async function answerGender(value: Gender) {
    dispatch(setGender(value));
    dispatch(setBirthdateValidationType("default"));
    answerLegalEvent(i18n.gender[value], "1/9", i18n.genderPage.question, "legal_pension", "gender");
    await next(appUrls.legalPension.birthDate);
  }

  async function answerBirthdate(value: Date, skipRedirect: boolean = false, step: string, skipEvent?: boolean) {
    dispatch(setBirthdate(format(value, 'yyyy-MM-dd')));
    let formatedDate = new Date(value).getFullYear();

    if(skipEvent === undefined || skipEvent !== true) {
      answerLegalEvent(formatedDate, step, i18n.birthdatePage.question, step === "6/6" ? "pension_need": "legal_pension", "birthdate");
    }
    
    if (skipRedirect) return;
    await next(appUrls.legalPension.children);
  }

  async function answerNumberOfChildren(value: number) {
    dispatch(setNumberOfChildren(value));
    answerLegalEvent(value, "3/9", i18n.childrenPage.question, "legal_pension", "children");
    await next(appUrls.legalPension.careerStart);
  }

  async function answerStartDateOfActivity(value: Date) {
    dispatch(setStartDateOfActivity(format(value, 'yyyy-MM-dd')));
    answerLegalEvent(value, "4/9", i18n.careerStartPage.question, "legal_pension", "career-start");
    await next(appUrls.legalPension.status);
  }

  async function answerProfessionalStatus(value: ProfessionalStatus) {
    if (value === ProfessionalStatus.Other) {
      const result = await showModal(<OtherStatusModal hasValidate={true} />);

      if (result.outcome === ModalOutcome.Cancel) {
        // The user cancelled the modal, stay on the current page
        return;
      }
    }

    dispatch(setProfessionalStatus(value));
    answerLegalEvent(value, "5/9", i18n.professionalStatusPage.question, "legal_pension", "status");
    await next(appUrls.legalPension.currentRevenue);
  }

  async function answerSalary(value: {
    amount: number;
    frequency: SalaryPeriodicity;
  }) {
    dispatch(setSalary(value));
    answerLegalEvent(value, "6/9", i18n.currentRevenuePage.question, "legal_pension", "current-revenue");
    await next(appUrls.legalPension.currentProgression);
  }

  async function answerEvolutionBeforeSimulation(value: SalaryEvolution) {
    dispatch(setEvolutionBeforeSimulation(value));
    answerLegalEvent(value, "7/9", i18n.currentProgressionPage.question, "legal_pension", "current-progression");
    await next(appUrls.legalPension.futureProgression);
  }

  async function answerEvolutionAfterSimulation(value: SalaryEvolution) {
    dispatch(setEvolutionAfterSimulation(value));
    answerLegalEvent(value, "8/9", i18n.futureProgressionPage.question, "legal_pension", "future-progression");
    await computeCommand();
  }

  return {
    answerGender,
    answerBirthdate,
    answerNumberOfChildren,
    answerStartDateOfActivity,
    answerProfessionalStatus,
    answerSalary,
    answerEvolutionBeforeSimulation,
    answerEvolutionAfterSimulation,
  };
}
