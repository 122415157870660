import axios, { AxiosError } from 'axios';
import { UserReferenceModel } from '../models/UserReferenceModel';

export enum UserType {
  Prospect = 'Prospect',
  User = 'User',
  Advisor = "Advisor",
  Admin = "Admin"
}

export async function getUserReferenceRequest(): Promise<UserReferenceModel | null> {
  try {
    const response = await axios.get(`/api/auth/user_reference`);
    const data = response.data as UserReferenceModel;

    return data;
  } catch (err) {
    const error = err as AxiosError;
    
    // HACK: 500 because of deleted user are temporarily treated as 401
    if (error.response?.status === 401 || error.response?.status === 500) {
      return null;
    }

    throw err;
  }
}
