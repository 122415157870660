import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared';
import { useAppDispatch, useAppSelector } from '../../../store';
import { initialize, restore} from '../pensionNeedSlice';
import { getLastValidatedRequest } from '../requests/getLastValidatedRequest';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const initialized = useAppSelector((s) => s.pensionNeed.initialized);
  const { customerid } = useParams();

  async function restoreComputation() {
    // Case 1: F5 reload, URL reentry
    // There is data in session storage and we should preserve it
    if (initialized) {
      return;
    }

    // Case 2: Navigation entry
    // There is no data and we need to setup the state

    // Retrieve the last validated data
    const lastValidated = await getLastValidatedRequest(customerid);

    // No validation has happened, there is nothing we can restore
    if (!lastValidated) {
      dispatch(initialize());
      return;
    }

    // Retrieve all the values
    const computationId = lastValidated.computationId;
    const gamifiedValues = lastValidated.categoriesDetails;
    // Update the store
    dispatch(restore({ computationId, gamifiedValues }));
  }

  function setup() {
    showLoaderWhile(async () => {
      await restoreComputation();

      setLoading(false);
    }, {key: "pensionNeed_setup", value:`Restore pension-need computation on file resolver.ts`});
  }

  useEffect(() => {
    setup();
  }, []);

  return { loading };
}
