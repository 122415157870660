import { TaxationComputationInputModel } from '../models/TaxationComputationInputModel';
import { TaxationOutputModel } from '../models/TaxationOutputModel';

// This module has only one endpoint to retrieve both the original user answers and the results for the summary page
// In the resolver, the answer input object is extracted from the result output one
export function answersToResults(results: TaxationOutputModel): TaxationComputationInputModel {
  return {
    firstIndividualMaximumTaxDeduction: results.firstIndividualMaximumTaxDeduction,
    secondIndividualMaximumTaxDeduction: results.secondIndividualMaximumTaxDeduction,
    selectedTmi: results.selectedTmi
  };
}
