import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '../../shared/components/Theme/ThemeProvider';
import { ProgressRoute } from '../../shared/routing/ProgressRoute';
import { ModuleColor } from '../../styles/colors';
import { i18n } from './i18n';
import { useResolver } from './routing/resolver';
import { DeclarationPage, LandingPage, SummaryPage } from './pages';
import { AnalyticsComponent } from '../../shared/components/datalayer/AnalyticsComponent';
import { PageRoute } from '../../shared/routing/PageRoute';

export function PatrimonyModule() {
  const { loading } = useResolver();

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 2
  };

  const summaryProps = {
    ...moduleProps,
    showStep: false,
    step: 2,
    subtitle: i18n.categories.synthesis
  };

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.Patrimony,
        backgroundColorLigther: ModuleColor.PatrimonyLighter
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <AnalyticsComponent
              page_title={i18n.landingPage.pageTitle}
              page_path={"/patrimony"}
              page_type={"homepage"}
              simulator_screen={"Landing Page"}
              simulator_step={"0/2"}
              simulator_type={i18n.module}
            >
                 <PageRoute
                pageTitle={i18n.landingPage.pageTitle}
                element={<LandingPage />}
              />
            </AnalyticsComponent>
          }
        />
        <Route
          path={`/declaration`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={1}
              pageTitle={i18n.declarationPage.pageTitle}
              subtitle={i18n.declarationPage.subTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.declarationPage.pageTitle}
                  page_path={"/patrimony/declaration"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.declarationPage.subTitle}
                  simulator_step={"1/2"}
                  simulator_type={i18n.module}
                >
                  <DeclarationPage />
                </AnalyticsComponent>}
              showAmount={true}
            />
          }
        />
        <Route
          path={`/summary`}
          element={
            <ProgressRoute
              {...summaryProps}
              pageTitle={i18n.summaryPage.pageTitle}
              subtitle={i18n.summaryPage.subTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.summaryPage.pageTitle}
                  page_path={"/patrimony/summary"}
                  page_type={"summary"}
                  simulator_screen={"summary"}
                  simulator_step={"2/2"}
                  simulator_type={i18n.module}
                >
                  <SummaryPage />
                </AnalyticsComponent>}
              showAmount={true}
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
