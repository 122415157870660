import classNames from "classnames";
import { useEffect, useState } from "react";
import { globali18n } from "../../../../i18n";
import { useAppSelector } from "../../../../store";
import { useValidate } from "../../commands/validateCommand";
import { i18n } from "../../i18n";
import { ChosenScenario } from "../../../../shared/models/ChosenScenario";
import { BackgroundImage } from "./components/BackgroundImage/BackgroundImage";
import { NaturalText } from "./components/NaturalText/NaturalText";
import { SummaryCard } from "./components/SummaryCard/SummaryCard";
import styles from "./SummaryPage.module.scss";
import { MouseEvent } from "react";
import {
  useInfoModal,
  Button,
  Container,
  ButtonStyles,
  ModalOutcome,
} from "../../../../shared";
import { useExternalUrls } from "../../../../shared/utilities/externalUrls";
import { ruddy_brown, copper, burlywood } from "../../../../styles/colors";

export function SummaryPage() {
  const [selected, setSelected] = useState<ChosenScenario>();
  const [privacyLink, setPrivacyLink] = useState<string>("");
  const answers = useAppSelector((s) => s.legalPension.answers!);
  const results = useAppSelector((s) => s.legalPension.results!).results;

  const { showInfoModal, hideModal } = useInfoModal();
  const { validateCommand } = useValidate();
  const { externalUrls } = useExternalUrls();

  useEffect(() => {
    getLink();
  }, []);
  const t = i18n.summaryPage;

  function onSelect(scenario: ChosenScenario) {
    setSelected(scenario);
  }

  async function onValidate() {
    if (selected) {
      await validateCommand(selected);
    }
  }

  async function knowMore(event: MouseEvent) {
    event.preventDefault();

    const rootTarget = document.getElementById("root");
    rootTarget?.addEventListener("keydown", (event) => {
      if (event.code === "Escape") {
        hideModal(ModalOutcome.Cancel);
      }
    });

    await showInfoModal(
      t.knowMoreModal.title,
      t.knowMoreModal.content,
      false,
      "know-more-legal"
    );
  }

  async function getLink() {
    const urls = await externalUrls();
    setPrivacyLink(urls.PrivacyPage);
  }

  return (
    <Container className={styles.Grid}>
      <div className={styles.Title}>{t.title}</div>
      <div className={styles.CardGrid}>
        {results?.legalAgeResult !== null && (
          <SummaryCard
            scenario={ChosenScenario.LegalAge}
            value={results.legalAgeResult}
            selected={selected === ChosenScenario.LegalAge}
            onSelect={() => onSelect(ChosenScenario.LegalAge)}
            remainingColor={burlywood}
          />
        )}
        {results?.fullRateAgeResult != null && (
          <SummaryCard
            scenario={ChosenScenario.FullRateAge}
            value={results.fullRateAgeResult}
            selected={selected === ChosenScenario.FullRateAge}
            onSelect={() => onSelect(ChosenScenario.FullRateAge)}
            remainingColor={copper}
          />
        )}
        {results?.maxAgeResult != null && (
          <SummaryCard
            scenario={ChosenScenario.MaxAge}
            value={results.maxAgeResult}
            selected={selected === ChosenScenario.MaxAge}
            onSelect={() => onSelect(ChosenScenario.MaxAge)}
            remainingColor={ruddy_brown}
          />
        )}
      </div>
      <div className={styles.Disclaimer}>{t.disclaimer}</div>
      <NaturalText answers={answers} />
      <div className={styles.ButtonBloc}>
        <Button
          onClick={onValidate}
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, {
            [styles.Hidden]: !selected,
          })}
        >
          {globali18n.labels.validate}
        </Button>
      </div>
      <Button
        onClick={knowMore}
        className={classNames(ButtonStyles.Link, styles.KnowMore)}
      >
        {t.knowMore}
      </Button>
      <a className={styles.Privacy} href={privacyLink}>
        {globali18n.labels.privacy}
      </a>
      <BackgroundImage />
    </Container>
  );
}
