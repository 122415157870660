import { i18n } from "../../../i18n";
import { Container, QuestionTitle, useLoader } from "../../../../../shared";
import styles from "./InvestmentScenario.module.scss";
import classNames from "classnames";
import { ButtonCard } from "./components/ButtonCard";
import { Scenario } from "../../../models/RiskProfileEnum";
import { useAnswerCommands } from "../../../commands/answerCommands";
import { useEffect, useState } from "react";
import { getQuestionnaireSessionRiskProfile } from "../../../../../modules/investor-profile/requests/getQuestionnaireSessionRiskProfile";
import { useAppSelector, useAppStore } from "../../../../../store";
import { useParams } from "react-router-dom";
import { apiUrls } from "../../../../../shared/apiUrls";
import { Tenant } from "../../../../../shared/models/TenantModel";
import { QuestionnaireSessionRiskProfile } from "../../../../../modules/investor-profile/models/QuestionnaireSessionModel";

const t = i18n.riskProfile.scenarioPage;

interface ButtonCardInterface {
  plus: number;
  minus: number;
  isLocked: boolean;
  isChecked: boolean;
  onClick: () => void;
  identifier: Scenario;
}

export function InvestmentScenario() {
  const { answerScenario } = useAnswerCommands();
  const store = useAppStore();
  const { customerid } = useParams();
  const { showLoaderWhile } = useLoader();
  const tenant = useAppSelector((s) => s.tenant).TenantIdentifier;

  let listOfEnvelop = [];
  tenant === Tenant.Matmut
    ? (listOfEnvelop = [
        {
          plus: 15,
          minus: 15,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Prudent, ""),
          identifier: Scenario.Prudent,
        },
        {
          plus: 30,
          minus: 30,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Equilibre, ""),
          identifier: Scenario.Equilibre,
        },
        {
          plus: 50,
          minus: 50,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Dynamique, ""),
          identifier: Scenario.Dynamique,
        },
      ])
    : (listOfEnvelop = [
        {
          plus: 5,
          minus: 5,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Conservateur, ""),
          identifier: Scenario.Conservateur,
        },
        {
          plus: 15,
          minus: 15,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Prudent, ""),
          identifier: Scenario.Prudent,
        },
        {
          plus: 30,
          minus: 30,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Equilibre, ""),
          identifier: Scenario.Equilibre,
        },
        {
          plus: 50,
          minus: 50,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Dynamique, ""),
          identifier: Scenario.Dynamique,
        },
        {
          plus: 100,
          minus: 100,
          isLocked: true,
          isChecked: false,
          onClick: () => answerScenario(Scenario.Offensif, ""),
          identifier: Scenario.Offensif,
        },
      ]);

  const [listOfCards, setListOfCards] = useState<ButtonCardInterface[]>(listOfEnvelop);

  const [isDone, setIsDone] = useState<boolean>(false);

  const riskProfileId =
    store.getState().investorProfile.riskProfile.riskProfileIdentifier;

  async function generateRiskProfiles(scenario: QuestionnaireSessionRiskProfile) {
    const indexOfMax = listOfCards.findIndex(
      (e) => e.identifier === scenario.computedProfileId
    );

    let tempsListOfCards = listOfCards;

    for (let index = 0; index < indexOfMax + 1; index++) {
      tempsListOfCards[index].isLocked = false;
      tempsListOfCards[index].onClick = () =>
        answerScenario(tempsListOfCards[index].identifier, scenario.id);
    }

    setListOfCards(tempsListOfCards);

    setIsDone(true);
  }

  async function getSession() {
    await showLoaderWhile(
      async () => {
        const response = await getQuestionnaireSessionRiskProfile(
          customerid,
          riskProfileId!
        );
        generateRiskProfiles(response);
      },
      {
        key: "investorProfile_RiskProfile_getQuestionnaireSessionRiskProfile",
        value: `API POST - ${apiUrls(
          customerid
        ).investorProfile.questionnaireSession.getQuestionnaireSessionRiskProfile(
          riskProfileId!
        )}`,
      }
    );
  }
  useEffect(() => {
    getSession();
  }, []);

  return (
    <Container>
      <QuestionTitle>{t.question}</QuestionTitle>
      <div className={styles.SubTitle}>{t.subTitle}</div>
      <div className={styles.GainAndLoss}>
        <div className={styles.CubeAndLabel}>
          <div className={classNames(styles.Cube, styles.GainCube)}></div>
          <div className={styles.Gain}>{t.potentialGain}</div>
        </div>
        <div className={styles.CubeAndLabel}>
          <div className={classNames(styles.Cube, styles.LossCube)}></div>
          <div className={styles.Loss}>{t.potentialLoss}</div>
        </div>
      </div>

      <div className={styles.CardsList}>
        {isDone &&
          listOfCards.map((e: ButtonCardInterface, index: number) => (
            <ButtonCard {...e} index={index} key={index} />
          ))}
      </div>

      <div className={styles.Legend}>{t.legend}</div>
    </Container>
  );
}
