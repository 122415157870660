import { EProfileType } from "./ProfileTypeEnum";

export enum EProfileStatus {
    Computed = "Computed",
    Validated = "Validated"
}

enum EQuestionnaireSessionStatus {
    InProgress = "InProgress ",
    Closed = "Closed "
}


export interface StartQuestionnaireSession {
    id: string;
    createdAt: string;
    customerId: string;
    profileType: EProfileType;
    version: number;
    status: EQuestionnaireSessionStatus;
    selectedAnswers: QuestionnaireAnswer[],
    profileId: string | null;
}

interface QuestionnaireAnswer {
    questionTextId: string;
    answerTextId: string;
}

interface CommonQuestionnaireSession {
    id: string;
    createdAt: string;
    customerId: string;
    questionnaireSessionId: string;
    profileScaleVersion: number;
    configurationVersion: number;
    status: EProfileStatus;
    validatedAt: string | null;
    expirationDate: string | null;
    isExpired: boolean | null;

}

export interface QuestionnaireSessionRiskProfile extends CommonQuestionnaireSession {
    computedProfileId: string;
    preferredProfileId: string | null;
}

export interface QuestionnaireSessionFinancialProfile extends CommonQuestionnaireSession {
    computedProfileId: string | null;
    productTypeProfiles: ProductTypeProfile[];
}

interface ProductTypeProfile {
    productTypeId: string;
    hasKAndE: boolean
}