import { RecommendationI18nType } from "../../modules/recommendation/i18n";
import { AppointmentFormRuleType } from "../Models/FormRule/AppointmentFormRule";
import { TenantModel } from "../Models/Tenant";
import {
  DashboardFooterImage,
  LegalPensionFooterImage,
  LegalPensionSummaryFooterImage,
  PensionNeedFooterImage,
  TaxationFooterImage,
  LifeProjectFooterImage,
  PatrimonyFooterImage,
  RegistrationFooterImage,
  RecommendationTopLeftImage,
  RecommendationBottomLeftImage,
  RecommendationMiddleImage,
  SharedMailbox,
  BackgroundLegalPensionLandingImage,
  BackgroundLifeProjectLandingImage,
  BackgroundPatrimonyLandingImage,
  BackgroundPensionNeedLandingImage,
  BackgroundTaxationLandingImage,
  BackgroundDiagnosticLandingImage,
  LockIcon,
  PatrimonyIconUncompleted,
  PatrimonyIconLock,
  PatrimonyIcon,
  TaxationIconUncompleted,
  TaxationIconLock,
  TaxationIcon,
  LifeProjectsIconUncompleted,
  LifeProjectsIconLock,
  LifeProjectsIcon,
  LegalPensionIconUncompleted,
  LegalPensionLock,
  LegalPensionIcon,
  PensionNeedIconLock,
  PensionNeedIconUncompleted,
  PensionNeedIcon,
  HeaderAppIcon,
  CheckedIcon,
  UncheckedIcon,
  TechnicalErrorBackground,
  TopImage,
  BackgroundInvestorProfileLandingImage,
  InvestorProfileFooterImage,
  MeetingImage,
  ReaderIcon,
  ReaderCutIcon,
  RecommendationProductSheetModalFooterImage,
  Trophy,
  Building,
  FooterLogo,
} from "./assets";
import { Products } from "./Products";

export const matmut: TenantModel = {
  name: "Matmut",
  cssVar: {
    "--primary-wintergreen": "#214A6E",
    "--primary-hubble": "#66735E",
    "--primary-keppel": "#00539A",
    "--primary-green_sheen": "#4972C5",
    "--primary-sage": "#387F8F",
    "--primary-lemon_meringue": "#F9D07F",
    "--primary-burlywood": "#476866",
    "--danger-red": "#B3003C",
    "--primary-green_70": "#468230",
    "--secondary-ruddy_brown": "#468230",
    "--secondary-copper": "#D2F0C7",
    "--background-floral_white": "white",
    "--light-old_lace": "#F4F8FB",
    "--header-background_lotion": "#FFFFFF",
    "--secondary-main": "#51687B",
    //themeBackgroundColor is the same as Keppel
    "--themeBackgroundColor": "#00539A",
    "--secondary-CTA": "#51687B",
    "--landing-interlayer": "#D2F0C7",
    "--legal-pension-summary-last-salary-legend": "#00539A",
    "--legal-pension-summary-pension-amount-legend": "#4972C5",
    "--legal-pension-summary-loss-amount-legend": "#468230",
    "--app-font-family": "Roboto",
    "--fiscality-color": "#2A3F74",
  },
  i18n: {
    dashboard: (i18n: any) => i18n,
    profile: (i18n: any) => i18n,
    recommendation: (i18n: RecommendationI18nType) => {
      return (i18n.productSheetModal.product = Products);
    },
    global: (i18n: any) => {
      return (i18n.title = "Ma vie Ma retraite");
    },
    shared: (i18n: any) => {
      return (
        (i18n.landingPage.start = "Commencer"),
        (i18n.header.register = "Inscription espace retraite"),
        (i18n.header.logIn = "Mon espace retraite")
      );
    },
    appointment: (i18n: any) => {
      return (i18n.appointmentPage.disclaimer = (urlLink: string) => (
        <>
          En renseignant votre numéro de téléphone et en cliquant sur “valider”,
          vous acceptez que le Groupe Matmut vous appelle au sujet de cette
          simulation. Et que les données collectées dans le cadre du parcours
          (dont vos données personnelles) lui soient transmises.
          <br /> Vous pouvez retirer votre consentement à tout moment dans les
          conditions prévues <a href={urlLink}>ici</a>.
        </>
      ));
    },
    cookies: (i18n: any) => {
      return (
        (i18n.cookiesYahoo = <></>),
        (i18n.cookiesDoubleClick = <></>),
        (i18n.cookiesGoogle = <></>),
        (i18n.topBloc = (
          <div>
            <p>
              MATMUT fait appel à son partenaire BNP Paribas CARDIF pour mettre
              à disposition des internautes un portail digital autour de la
              retraite qui a pour vocation d’aider chaque utilisateur à se
              projeter dans sa retraite, en cohérence avec ses désirs et projets
              (ci-après le « <strong>Site</strong> »).
            </p>

            <p>
              Lors de la navigation sur ce Site, des cookies sont déposés sur le
              terminal (ordinateur, téléphone mobile, tablette) avec lequel vous
              effectuez la navigation.
            </p>
          </div>
        )),
        (i18n.cookiesTitle =
          "4. Dans ce cas, qui place les cookies sur votre appareil ?")
      );
    },

    registration: (i18n: any, gdprLink?: string) => {
      return (
        (i18n.registrationPage.labels.acceptReceiveCommercialInformation =
          "J'accepte de recevoir des offres et informations commerciales de la part des entités du Groupe Matmut"),
        (i18n.registrationPage.labels.checkboxDisclaimer = (
          <>
            <p>
              Vous pourrez changer d’avis à tout moment dans votre interface de
              gestion de compte.
            </p>{" "}
            Vos données personnelles sont traitées par Matmut Patrimoine et les
            autres destinataires dans le cadre de la passation, gestion et
            exécution de la prestation de conseil en gestion de patrimoine,
            relation commerciale, lutte contre la fraude, lutte contre le
            blanchiment de capitaux et le financement du terrorisme. Pour en
            savoir plus sur l'usage de vos données et vos droits, consultez la
            rubrique{" "}
            <a href={gdprLink}>
              {" "}
              Protection des données personnelles de ce site internet.
            </a>
          </>
        ))
      );
    },
  },
  displayed: {
    dashboard: (condition: any) => {
      return (condition.displayMeetingElement = false);
    },
    footer: (condition: any) => {
      return (
        (condition.displayElement = true),
        (condition.footerLinkNotBeDisplayed = [
          "ProjectionExplanationPage",
          "GDPRPage",
        ])
      );
    },
    appointment: (condition: any) => {
      return (
        (condition.displayElement = false),
        (condition.displayRdvConfirmModal = true),
        (condition.displayUserInformationForm = false)
      );
    },
    register: (condition: any) => {
      return (condition.displayElement = false);
    },
  },
  formRule: {
    appointment: (formRule: AppointmentFormRuleType) => {
      return (
        (formRule.desiredDateRequired = false),
        (formRule.desiredTimeSlotRequired = false),
        (formRule.emailRequired = false),
        (formRule.firstNameRequired = false),
        (formRule.lastNameRequired = false)
      );
    },
  },
  assets: {
    cookies: (assets: any) => {
      return (assets.TopImage = TopImage);
    },
    appointment: (assets: any) => {
      return (assets.BackgroundImage = DashboardFooterImage);
    },
    dashboard: (assets: any) => {
      return (
        (assets.BackgroundImage = DashboardFooterImage),
        (assets.LockIcon = LockIcon),
        (assets.PatrimonyIconUncompleted = PatrimonyIconUncompleted),
        (assets.PatrimonyIconLock = PatrimonyIconLock),
        (assets.PatrimonyIcon = PatrimonyIcon),
        (assets.TaxationIconUncompleted = TaxationIconUncompleted),
        (assets.TaxationIconLock = TaxationIconLock),
        (assets.TaxationIcon = TaxationIcon),
        (assets.LifeProjectsIconUncompleted = LifeProjectsIconUncompleted),
        (assets.LifeProjectsIconLock = LifeProjectsIconLock),
        (assets.LifeProjectsIcon = LifeProjectsIcon),
        (assets.LegalPensionIconUncompleted = LegalPensionIconUncompleted),
        (assets.LegalPensionLock = LegalPensionLock),
        (assets.LegalPensionIcon = LegalPensionIcon),
        (assets.PensionNeedIconLock = PensionNeedIconLock),
        (assets.PensionNeedIconUncompleted = PensionNeedIconUncompleted),
        (assets.PensionNeedIcon = PensionNeedIcon),
        (assets.MeetingImage = MeetingImage)
      );
    },
    legalPension: (assets: any) => {
      return (
        (assets.BackgroundImage = LegalPensionFooterImage),
        (assets.SummaryBackgroundImage = LegalPensionSummaryFooterImage)
      );
    },
    pensionNeed: (assets: any) => {
      return (assets.BackgroundImage = PensionNeedFooterImage);
    },
    taxation: (assets: any) => {
      return (assets.BackgroundImage = TaxationFooterImage);
    },
    lifeProjects: (assets: any) => {
      return (assets.BackgroundImage = LifeProjectFooterImage);
    },
    patrimony: (assets: any) => {
      return (assets.BackgroundImage = PatrimonyFooterImage);
    },
    registration: (assets: any) => {
      return (assets.BackgroundImage = RegistrationFooterImage);
    },
    recommendation: (assets: any) => {
      return (
        (assets.TopLeftImage = RecommendationTopLeftImage),
        (assets.BottomLeftImage = RecommendationBottomLeftImage),
        (assets.MiddleImage = RecommendationMiddleImage),
        (assets.RecoStaticRightImage = MeetingImage),
        (assets.RecoStaticLeftImage = LifeProjectFooterImage),
        (assets.ProductSheetModalFooterImage =
          RecommendationProductSheetModalFooterImage),
        (assets.BackgroundImage = DashboardFooterImage),
        (assets.Trophy = Trophy),
        (assets.Building = Building)
      );
    },
    shared: (assets: any) => {
      return (
        (assets.FooterLogo = FooterLogo),
        (assets.Mailbox = SharedMailbox),
        (assets.CheckedIcon = CheckedIcon),
        (assets.UncheckedIcon = UncheckedIcon),
        (assets.TechnicalErrorBackground = TechnicalErrorBackground)
      );
    },
    landingPages: (assets: any) => {
      return (
        (assets.BackgroundLegalPensionLandingImage =
          BackgroundLegalPensionLandingImage),
        (assets.BackgroundTaxationLandingImage =
          BackgroundTaxationLandingImage),
        (assets.BackgroundPatrimonyLandingImage =
          BackgroundPatrimonyLandingImage),
        (assets.BackgroundPensionNeedLandingImage =
          BackgroundPensionNeedLandingImage),
        (assets.BackgroundLifeProjectLandingImage =
          BackgroundLifeProjectLandingImage),
        (assets.BackgroundInvestorProfileLandingImage =
          BackgroundInvestorProfileLandingImage),
        (assets.BackgroundDiagnosticLandingImage =
          BackgroundDiagnosticLandingImage)
      );
    },
    investorProfile: (assets: any) => {
      return (
        (assets.BackgroundImage = InvestorProfileFooterImage),
        (assets.ReaderIcon = ReaderIcon),
        (assets.ReaderCutIcon = ReaderCutIcon)
      );
    },
    header: (assets: any) => {
      return (assets.HeaderAppIcon = HeaderAppIcon);
    },
  },
  envelops: {
    productSheet: (product: any) => {
      return (product = Products);
    },
  },
};
