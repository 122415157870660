import styles from "./pages/Cookies.module.scss";

export const i18n = {
  whatIsACookie: (
    <section>
      <p>
        Les cookies sont de petits fichiers texte, image ou logiciel qui peuvent
        être déposés et/ou lus sur votre appareil lorsque vous accédez à notre
        Site. Le mot « <strong>appareil</strong> », lorsqu'il est utilisé dans
        la présente Politique Cookies, désigne notamment les ordinateurs, les
        téléphones intelligents, les tablettes et tout autre appareil utilisé
        pour accéder à Internet.
      </p>
      <p>
        Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont
        supprimés de votre terminal une fois la session et le navigateur fermés
        ; ou (ii) persistants, ce qui signifie qu’ils seront stockés sur votre
        terminal jusqu’à leur suppression.
      </p>
      <p>
        Les cookies peuvent avoir de nombreuses fonctions et permettent
        notamment de :
      </p>
      <p>
        <ul>
          <li>
            <strong>vous authentifier et vous identifier</strong> sur notre Site
            afin de vous fournir les services que vous avez demandés ;
          </li>
          <li>
            <strong>améliorer la sécurité du Site</strong> y compris la
            prévention de l’usage frauduleux d’identifiants de connexion et la
            protection des données utilisateurs de l’accès par des tiers non
            autorisés ; vous adresser des publicités personnalisées notamment
            basées sur vos historiques de navigation et préférences en ligne ;
          </li>
          <li>
            <strong>
              suivre votre manière d’utiliser notre Site afin de l’améliorer
            </strong>{" "}
            ;
          </li>
          <li>
            <strong>améliorer votre expérience utilisateur</strong> en adaptant
            le contenu de notre Site à vos centres d’intérêt et en vous
            proposant des publicités et contenus BNP Paribas plus pertinents sur
            notre Site ou des sites tiers. ;
          </li>
          <li>
            <strong>se souvenir des informations</strong> que vous nous avez
            fournies (par ex. pour remplir automatiquement les formulaires avec
            les informations que vous nous avez précédemment fournies afin de
            vous connecter plus rapidement) ;
          </li>
          <li>
            <strong>enregistrer vos préférences</strong> et vos paramètres
            d'utilisation de notre Site (par exemple langue, fuseau horaire,
            etc.)
          </li>
        </ul>
      </p>
    </section>
  ),
  typeOfInformationStored: (
    <section>
      <p>
        Les informations stockées par les cookies déposés sur votre appareil
        peuvent avoir trait aux éléments suivants, dans la limite de leur durée
        de conservation :
      </p>
      <p>
        <ul>
          <li>
            les pages web que vous avez visitées en utilisant cet appareil ;
          </li>
          <li>les publicités sur lesquelles vous avez cliqué ;</li>
          <li>le type de navigateur que vous utilisez ;</li>
          <li>votre adresse IP ;</li>
          <li>
            et toute autre information que vous avez fournie sur notre Site.
          </li>
        </ul>
      </p>
    </section>
  ),
  typeOfUsage: (
    <section>
      <p>
        Les cookies que nous utilisons sur notre Site sont classés en
        différentes catégories :
      </p>
      <p>
        <strong>3.1 Cookies strictement nécessaires (obligatoires)</strong>
      </p>
      <p>
        Ces cookies sont indispensables afin de permettre au Site de fonctionner
        correctement. Ils peuvent comprendre, par exemple, des cookies qui
        collectent les identifiants de session et données d’identification, des
        cookies qui permettent de personnaliser l’interface du Site (par
        exemple, pour le choix de la langue ou de la présentation d’un service)
        ou encore certains cookies de mesure d’audience. Cette catégorie
        comprend également les cookies qui nous permettent de nous conformer à
        nos obligations légales, notamment en assurant un environnement en ligne
        sûr (par exemple en détectant les échecs répétitifs de connexion afin
        d’empêcher des personnes non autorisées d’accéder à votre compte).
      </p>
      <p>
        <strong>
          3.2 Cookies de mesure d’audience (soumis au consentement, sauf sous
          certaines conditions)
        </strong>
      </p>
      <p>
        Ces cookies nous permettent ainsi qu’à nos partenaires de comprendre
        comment les utilisateurs arrivent sur notre Site, de mesurer le nombre
        de visiteurs sur notre Site mais également d’analyser la manière dont
        les visiteurs naviguent sur le Site ou de reconstituer leur parcours.
        Cela nous aide à améliorer la manière dont notre Site fonctionne en nous
        assurant, par exemple, que les utilisateurs trouvent facilement ce
        qu’ils cherchent.
      </p>
      <p>
        Ces cookies peuvent être exemptés du consentement quand ils sont
        strictement nécessaires au fonctionnement du Site conformément à
        l’article 3.1. Dans le cas contraire, ils sont soumis au consentement
        conformément au présent article 3.2.
      </p>
      <p>
        <strong>
          3.3 Cookies publicitaires et cookies de suivi (soumis à votre
          consentement)
        </strong>
      </p>
      <p>
        Il existe différentes catégories de cookies permettant de vous proposer
        de la publicité.
      </p>
      <p>
        Les informations obtenues via des cookies publicitaires sont fréquemment
        partagées avec nos partenaires via l’utilisation de cookies tiers. Vous
        pouvez retrouver plus d’informations sur ces partenaires dans la section
        4 de la présente Politique Cookies.
      </p>
      <p>
        <h5>
          <strong>Cookies publicité personnalisée</strong>
        </h5>
      </p>
      <p>
        Ces cookies sont utilisés afin de vous proposer des publicités
        personnalisées, plus adaptées à vos centres d’intérêt. Ils enregistrent
        votre visite sur notre Site, les pages que vous avez consultées ainsi
        que les liens que vous avez suivis. Nous utilisons ainsi que nos
        partenaires ces informations afin de personnaliser notre Site et les
        publicités qui y figurent, le cas échéant, en fonction de vos centres
        d’intérêt.
      </p>
      <p>
        Ils peuvent également servir à afficher des publicités personnalisées
        sur nos produits sur d’autres sites, à mesurer l'efficacité d'une
        campagne publicitaire, à limiter la fréquence de diffusion (c'est-à-dire
        pour limiter le nombre de fois que vous voyez une publicité),
        l'affiliation publicitaire, la détection de la fraude au clic, les
        études de marché, l'amélioration des produits, le débogage.
      </p>
      <p>
        <strong>
          3.4 Réseaux sociaux et cookies de partage de contenu (soumis à votre
          consentement)
        </strong>
      </p>
      <p>
        Ces cookies nous permettent, ainsi qu’à nos partenaires, de vous
        proposer la visualisation sur notre Site de contenu hébergé sur un site
        externe.
      </p>
      <p>
        La visualisation de ce type de contenu sur nos Sites et/ou Applications
        implique de partager avec le site d’hébergement des informations à
        propos de votre navigation sur nos Sites.
      </p>
      <p>
        Vous trouverez dans la section 4 de la présente Politique Cookies de
        plus amples informations sur les partenaires ainsi que des explications
        sur la manière dont vous pouvez configurer vos préférences.
      </p>
    </section>
  ),
  howHandleCookies: (
    <section>
      <p>
        Pour consulter les différentes catégories de cookies que nous
        utilisons sur le Site et paramétrer vos choix, vous pouvez consulter
        le module de gestion des cookies accessibles en bas de page. Vous
        pouvez modifier vos préférences à tout moment (retirer ou donner à
        nouveau votre consentement).
      </p>
      <p>
        Veuillez noter que l’utilisation des cookies strictement nécessaires
        au bon fonctionnement du Site ne requiert pas votre consentement.
        C’est pourquoi l’option « cookies strictement nécessaires » est pré
        cochée dans notre outil de gestion des cookies et n’est pas
        optionnelle.
      </p>
      <p>
        En refusant certains types de cookies (les cookies de préférence par
        exemple), nous ne serons pas en mesure d’optimiser votre expérience
        utilisateur sur notre Site et certaines parties pourraient ne pas
        fonctionner correctement.
      </p>
      <p>
        Par défaut, nous enregistrons vos choix en matière de cookies sur un
        appareil donné pour une durée maximale de 6 mois. Si vous changez
        d’avis sur les préférences que vous avez exprimées concernant les
        cookies, vous pouvez mettre à jour vos choix à tout moment en
        suivant ce lien.
      </p>
      <p>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Paramètres des cookies
        </button>
      </p>
      <p>
        Nous vous demanderons à nouveau de faire un choix tous les 6 mois.
      </p>
    </section>
  ),
  cookiesExplain: (
    <section>
       <p>
            Lorsque vous sélectionnez les types de cookies que vous autorisez
            sur votre appareil, ces cookies peuvent être directement déposés par
            nous ou par un de nos partenaires.
          </p>
          <p>
            D’un point de vue pratique, cela signifie que lorsque vous autorisez
            l’installation de certains cookies dits « tiers » sur votre
            appareil, nos partenaires pourront également accéder aux
            informations qu’ils contiennent (comme, par exemple, vos
            statistiques de navigation lorsque vous autorisez les cookies
            d'analyse tiers) dans le respect de notre Notice de Protection des
            Données et de celles de nos partenaires.
          </p>
    </section>
  ),
  cookiesIAdvize: (
    <>
    <p>
            <strong>iAdvize</strong>
          </p>
          <p>
            Ces cookies permettent à notre prestataire d’outil de conversation
            (iAdvize) de vous proposer une fenêtre de livechat sur notre Site,
            vous permettant d’échanger directement avec nos équipes
          </p>
          <p>
            Vous pouvez obtenir de plus amples informations sur le site Web de
            iAdvize en suivant ce lien :&nbsp;
            <a
              className={styles.ExternalLink}
              href="https://help.iadvize.com/hc/fr/articles/360003267991-Le-RGPD-et-iAdvize-"
              target={"_blank"}
              rel={"nofollow"}
            >
              https://help.iadvize.com/hc/fr/articles/360003267991-Le-RGPD-et-iAdvize-
            </a>
          </p>
          <p>
            Liste des Cookies du partenaire utilisés sur le Site et durée de
            conservation de ces cookies par le partenaire :<br />
            isStorageAvailable : 13 mois
            <br />
            iadvize-7565-conversionBundle.7565.conversion : 13 mois
            <br />
            iadvize-7565-conversionBundle.7565.startDate : 13 mois
            <br />
            iadvize-7565s-vuid : 13 mois
          </p>
    </>
  ),
  cookiesYoutube: (
    <>  
    <p>
      <strong>Youtube</strong>
    </p>
    <p>
      Le site utilise des applications informatiques de Google Youtube,
      qui vous permettent d’accéder et partager des contenus interactifs
      du site avec d’autres personnes ou de faire connaître à ces autres
      personnes votre consultation ou votre opinion.
    </p>
    <p>
      Vous pouvez obtenir de plus amples informations sur ces cookies sur
      le site Web de Google en suivant ce lien :&nbsp;
      <a
        className={styles.ExternalLink}
        href="https://policies.google.com/privacy"
        target={"_blank"}
        rel={"nofollow"}
      >
        https://policies.google.com/privacy
      </a>
    </p>
    <p>
      Liste des Cookies du partenaire utilisés sur le Site et durée de
      conservation de ces cookies par le partenaire :<br />
      YSC expire après la session
      <br />
      VISITOR_INFO1_LIVE expire après 179 jours
      <br />
      CONSENT expire après 2 ans
    </p>
    </>
  ),
  cookiesYahoo: (
    <>
      <p>
        <strong>Yahoo</strong>
      </p>
      <p>
        Personnalise et mesure l'efficacité des publicités Yahoo qui vous ont
        amenés jusqu’à nous.
      </p>
      <p>
        Vous pouvez obtenir de plus amples informations sur le site Web de Yahoo
        en suivant ce lien :&nbsp;
        <a
          className={styles.ExternalLink}
          href="https://legal.yahoo.com/ca/fr/rogers/privacy/index.html"
          target={"_blank"}
          rel={"nofollow"}
        >
          https://legal.yahoo.com/ca/fr/rogers/privacy/index.html
        </a>
      </p>
      <p>
        Liste des Cookies du partenaire utilisés sur le Site et durée de
        conservation de ces cookies par le partenaire :
      </p>
      <p>A3 : 365 jours</p>
    </>
  ),
  cookiesDoubleClick: (
    <>
      <p>
        <strong>DoubleClick</strong>
      </p>
      <p>
        Ces cookies permettent de présenter des offres et informations adaptées
        à vos centres d’intérêts lors de votre navigation sur Internet.
      </p>
      <p>
        Vous pouvez obtenir de plus amples informations sur le site Web de
        Google en suivant ce lien :&nbsp;
        <a
          className={styles.ExternalLink}
          href="https://policies.google.com/privacy"
          target={"_blank"}
          rel={"nofollow"}
        >
          https://policies.google.com/privacy
        </a>
      </p>
      <p>
        Liste des Cookies du partenaire utilisés sur le Site et durée de
        conservation de ces cookies par le partenaire :
      </p>
      <p>
        IDE : 730 jours
        <br />
        test_cookie : quelques secondes
      </p>
    </>
  ),
  cookiesGoogle: (
    <>
      <p>
        <strong>Google</strong>
      </p>
      <p>
        Personnalise et mesure l'efficacité des publicités Google qui vous ont
        amenés jusqu’à nous.
      </p>
      <p>
        Vous pouvez obtenir de plus amples informations sur le site Web de
        Google en suivant ce lien :&nbsp;
        <a
          className={styles.ExternalLink}
          href="https://policies.google.com/privacy"
          target={"_blank"}
          rel={"nofollow"}
        >
          https://policies.google.com/privacy
        </a>
      </p>
      <p>
        Liste des Cookies du partenaire utilisés sur le Site et durée de
        conservation de ces cookies par le partenaire :
      </p>
      <p>
        _dlt : quelques secondes
        <br />
        __troSYNC : 1 jours
        <br />
        __troRUID : 395 jours
        <br />
        __trossion : 395 jours
        <br />
        _gclxxxx : 90 jours
      </p>
    </>
  ),
  topBloc: (
    <div>
      <p>
        BNP PARIBAS CARDIF est particulièrement attentif à vous délivrer le
        meilleur service possible et nous souhaitons préserver la confiance que
        vous nous témoignez.
      </p>
      <p>
        Dans ce cadre, nous avons adopté des principes forts afin d’assurer la
        protection de vos données.
      </p>
      <p>
        C'est pourquoi nous souhaitons vous fournir des informations
        transparentes sur la manière dont nous plaçons, utilisons et stockons
        des cookies sur votre appareil lorsque vous consultez notre site
        (ci-après le « <strong>Site</strong> ») et vous présenter les options
        qui vous permettent de les gérer et les supprimer.
      </p>
    </div>
  ),
  cookiesTitle:
    " 4. Dans ce cadre, qui place les cookies sur votre appareil (par exemple les sociétés du Groupe BNP Paribas ou un partenaire) ?",
};
