export enum SavingsAccountProductType {
  CompteCourant = 'CompteCourant',
  LivretEpargne = 'LivretEpargne',
  PEL = 'PEL',
  AssuranceVie = 'AssuranceVie',
  CompteTitres = 'CompteTitres',
  PEA = 'PEA',
  PERIndividuel = 'PERIndividuel',
  PERP = 'PERP',
  Madelin = 'Madelin',
  PEREntrepriseOrPERCO = 'PEREntrepriseOrPERCO',
  PEROEntrepriseOrArticle83 = 'PEROEntrepriseOrArticle83',
  PEE = 'PEE',
  Immobilier = 'Immobilier',
}