import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { PensionNeedPersonaBody, PensionNeedPersonaResponse } from '../models/request/computationRequest.model';

export async function computePersonaRequest(
  payload: PensionNeedPersonaBody,
  customerid?: string
): Promise<PensionNeedPersonaResponse> {

  const apiUrl = apiUrls(customerid).pensionNeed.computations;

  const response = await axios.post<PensionNeedPersonaResponse>(
    apiUrl,
    payload
  );

  const data = response.data;

  return data;
}
