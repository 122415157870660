import { AgeModel } from "../../../modules/recommendation/models/RecommendationModel";

export interface SynthesisModel {
  currentPatrimony: CurrentPatrimonyModel;
  simulations: SimulationPatrimonyModel[];
  retirementAge: AgeModel;
}

interface CurrentPatrimonyModel {
  savingsAccountValue: number;
  realEstateValue: number;
  totalPatrimonyValue: number;
}

export interface SimulationPatrimonyModel {
  scenario: ScenarioProjectionType;
  capitalAmountAtRetirement: GenericProjectionModel;
  monthlyAmountAtRetirement: GenericProjectionModel;
}

interface GenericProjectionModel {
  savingsAccountValue: number;
  realEstateValue: number;
  totalPatrimonyValue: number;
}

export enum ScenarioProjectionType {
  Pessimistic = "Pessimistic",
  Neutral = "Neutral",
  Optimistic = "Optimistic"
}
