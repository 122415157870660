import { globali18n } from '@ifs/libs/src/i18n';
import { UseFormRegister, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { i18n } from '../../i18n';

export function getFormConfig(
  passwordName: string,
  passwordConfirmName: string,
  register: UseFormRegister<any>,
  watch: UseFormWatch<any>,
  trigger: UseFormTrigger<any>
) {
  const passwordMinUppercase = 1;
  const passwordMinLowercase = 1;
  const passwordMinNumber = 1;
  const passwordMinSymbol = 1;

  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const symbolRegex = /[ !"#$%&'()*+,-./:;<=>?@\\\[\]^_`{|}~]/;
  const allAllowed = [uppercaseRegex, lowercaseRegex, numberRegex, symbolRegex];

  const formConfig = {
    password: {
      field: register(passwordName, {
        // Need to re-validate passwordConfirmName after passwordName input blur
        // Timeout is necessary, because the watch function use to validte or not passwordConfirmName field
        // doesnt containt lastest value defined on passwordName field
        onBlur: () => {
          setTimeout(() => trigger(passwordConfirmName));
        },
        required: true,
        validate: {
          minLength: (v) => minLenght(v),
          minUppercase: (v) => atLeast(v, passwordMinUppercase, uppercaseRegex),
          minLowercase: (v) => atLeast(v, passwordMinLowercase, lowercaseRegex),
          minNumber: (v) => atLeast(v, passwordMinNumber, numberRegex),
          minSymbol: (v) => atLeast(v, passwordMinSymbol, symbolRegex),
          noOther: (v) => noOther(v, allAllowed)
        }
      }),
      errors: {
        required: globali18n.errors.required,
        minLength: i18n.passwordConfirmInputGroup.errors.minLength,
        minUppercase: i18n.passwordConfirmInputGroup.errors.minUppercase,
        minLowercase: i18n.passwordConfirmInputGroup.errors.minLowercase,
        minNumber: i18n.passwordConfirmInputGroup.errors.minNumber,
        minSymbol: i18n.passwordConfirmInputGroup.errors.minSymbol,
        noOther: i18n.passwordConfirmInputGroup.errors.noOther
      }
    },
    passwordConfirm: {
      field: register(passwordConfirmName, {
        required: true,
        validate: { mismatch: (v) => v === watch(passwordName) }
      }),
      errors: {
        required: globali18n.errors.required,
        mismatch: i18n.passwordConfirmInputGroup.errors.mismatch
      }
    }
  };

  return formConfig;
}
function minLenght(value: string): boolean {
  if (value.length >= 8) {
    return true;
  }

  return false;
}

function atLeast(value: string, min: number, regex: RegExp): boolean {
  const chars = Array.from(value);
  const matches = chars.filter((char) => regex.test(char)).length;

  if (matches < min) {
    return false;
  }

  return true;
}

function noOther(value: string, regexes: RegExp[]): boolean {
  const chars = Array.from(value);

  // Are there caracters such that no regex matches them?
  const hasOthers = chars.some((c) => !regexes.some((r) => r.test(c)));

  return !hasOthers;
}
