import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { EProfileStatus } from "../models/QuestionnaireSessionModel";
import { GetRiskProfiles } from "../models/RiskProfileModel";

export async function getSearchRiskProfile(customerId: string | undefined): Promise<GetRiskProfiles> {
  const apiUrl =
    apiUrls(customerId).investorProfile.riskProfile.searchRiskProfile;

  //Get only validated status to avoid uncalculated Risk Profile
  const response = await axios.get<GetRiskProfiles>(apiUrl, {
    params: { limit: 1, sortBy: "ValidatedAt Desc, CreatedAt Desc", statuse: EProfileStatus.Validated  },
  });

  const data = response.data;

  return data;
}
