import { i18n } from '../../../modules/registration/i18n';
import { UseFormRegister } from 'react-hook-form';
import { emailRegex } from '../../../shared/utilities/validation';
import { globali18n } from '@ifs/libs/src/i18n';

export function getEmailConfig(register: UseFormRegister<any>) {
  const formConfig = {
    email: {
      field: register('email', { required: true, pattern: emailRegex , maxLength: 50}),
      errors: {
        required: globali18n.errors.required,
        pattern: globali18n.errors.email,
        maxLength: globali18n.errors.email,
        loginAlreadyExists: i18n.registrationPage.errors.loginAlreadyExists
      }
    }
  };

  return formConfig;
}
