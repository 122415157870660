import { useGlobalCommand } from '../../../shared/commands/useGlobalCommand';
import { useLoader } from '../../../shared';
import { Module } from '../../../shared/models/Module';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';

export function useValidateCommand() {
  const { showLoaderWhile } = useLoader();
  const { redirectAfterValidateCommand } = useGlobalCommand();
  const { customerid } = useParams();

  return async function validateCommand() {
    await showLoaderWhile(async () => {
      await redirectAfterValidateCommand(Module.Taxation);
    }, {key: "useValidateCommand_setTmiRequest ", value:`API PUT - ${apiUrls(customerid).taxation.putTMI}`});
  };
}
