export const i18n = {
  appointmentPage: {
    pageTitle: 'Demande de rendez-vous',
    title: 'Pour aller plus loin, prenez un rendez-vous avec un conseiller',
    phoneNumber: {
      label: 'Votre n° de téléphone',
      errors: {
        format: "Le format du numéro de téléphone n'est pas correct."
      }
    },
    lastName: {
      label: "Votre nom"
    },
    firstName: {
      label: "Votre prénom"
    },
    email: {
      label: "Votre adresse e-mail"
    },
    zipCode: {
      label: 'Votre code postal (résident français uniquement) :'
    },
    preferenceLabel:
      'Vos préférences pour un rendez-vous téléphonique sous 72h minimum (jours ouvrés) :',
    disclaimer: (urlLink: string) => (
      <>
        En cliquant sur “valider” vous acceptez que les données collectées dans le cadre du parcours
        (dont vos données personnelles) soient transmises à BNP PARIBAS S.A. Vous pouvez retirer
        votre consentement à tout moment dans les conditions prévues{' '}
        <a title="Data privacy politics" href={urlLink}>ici</a>.
      </>
    )
  },
  confirmMeetingModal: {
    title: "Demande de rappel",
    body: <>Votre demande de rappel par un conseiller a bien été prise en compte. Vous allez recevoir un e-mail de confirmation.</>
  }
};
