import { useGlobalCommand } from "@ifs/libs";
import { internalUrls } from "@ifs/libs/src/shared/utilities/urls";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function PublicGuardedRoute({ children }: any) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticationModeSSO } = useGlobalCommand();
  
  const ssoLoginRoutes = [internalUrls.authentication.ssoLogin];

  async function checkCanAccessRoute() {
    const isASSOAuthenticationRoute = ssoLoginRoutes.includes(pathname); 
    const isAuthenticationSSO = isAuthenticationModeSSO();

      // CASE 1: IF Tenant NOT handle SSO login, then he cannot access to ssoLoginRoutes
      if(isASSOAuthenticationRoute && !isAuthenticationSSO) {      
          navigate({
            pathname: internalUrls.authentication.login
          });
          return;
      }

      // CASE 2: Check IF tenant handle SSO, if true he cannot accees to the pathaname enter, because if not a loginSSORoutes
      if(!isASSOAuthenticationRoute && isAuthenticationSSO) {      
        navigate({
          pathname: internalUrls.authentication.ssoLogin
        });
        return;
      }
  }

  useEffect(() => {
    checkCanAccessRoute();
  }, [pathname]);

  return children;
}
