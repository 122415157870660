import { createEnumPage } from '../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../commands/answerCommands';
import { BackgroundImage } from '../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../i18n';
import { SalaryEvolution } from '../../models/salary/SalaryEvolution';

export const CurrentProgressionPage = createEnumPage<SalaryEvolution>({
  selector: (s) => s.legalPension.answers.salary.evolutionBeforeSimulation,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerEvolutionBeforeSimulation,
  values: Object.values(SalaryEvolution).map((status) => ({
    value: status,
    label: i18n.salaryEvolution[status]
  })),
  question: i18n.currentProgressionPage.question,
  backgroundImage: BackgroundImage
});
