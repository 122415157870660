import { useEffect, useState } from 'react';
import { useLoader } from '@ifs/libs';
import { store, useAppDispatch } from '../../../store';
import { resetAppointment, setAvailable } from '../appointmentSlice';
import { getAppointmentRequest } from '../requests/getAppointmentRequest';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  function setup() {
    showLoaderWhile(async () => {
      // Module initialized, do nothing
      if (store.getState().appointment.available) {
        setLoading(false);
        return;
      }

      const available = await getAppointmentRequest();
      dispatch(setAvailable(available));

      setLoading(false);
    }, {key: "appointmentResolver_setup", value:`Handle appointment module setutp on file resolver.ts}`});
  }

  function cleanup() {
    dispatch(resetAppointment());
  }

  useEffect(() => {
    setup();
    return () => {
      cleanup();
      setLoading(true);
    };
  }, []);

  return { loading };
}
