import {
  Card,
  CardTitle,
  Container,
  PageTitle,
  SvgWrapperComponent,
} from "../../../../shared";
import { i18n } from "../../i18n";
import styles from "./DashboardPage.module.scss";
import { Information } from "../../components/Information/Information";
import { TaxSavings } from "../../components/TaxSavings/TaxSavings";
import { FutureCard } from "../../components/Future/Future";
import classNames from "classnames";
import { useAppSelector } from "../../../../store";
import { createChartData } from "../../../../modules/dashboard/utilities/dashboard";
import { computeCompletion, computerNeedsRevenues } from "./calculations";
import { useDetermineModuleStatus } from "../../../../modules/dashboard/utilities/determineModuleStatuses";
import { InformationCard } from "../../components/InformationCard/InformationCard";
import { sanitizeAmounts } from "../../../../modules/dashboard/utilities/sanitizeAmounts";
import { Module } from "../../../../shared/models/Module";
import { useGoToModule } from "../../../../modules/dashboard/commands/goToModule";
import { useGoToRecommendation } from "../../../../modules/dashboard/commands/goToRecommendation";
import { useEffect } from "react";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";

import { assets as Assets } from "../../Assets";
import { DashboardChart } from "../../../../modules/dashboard/components/DashboardChart/DashboardChart";
import { amountPerMonth } from "../../../../shared/utilities/intl";
import { NoChartIcon } from "./assets";
import { BackgroundImage } from "../../../../shared/components/BackgroundImage/BackgroundImage";
import { useResponsive } from "../../../../shared/utilities/responsive";
import { useGlobalCommand } from "../../../../shared/commands/useGlobalCommand";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { InfoIcon } from "../../../../modules/dashboard/components/InvestorProfileCard/assets";

export function DashboardPage() {
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const {
    determineModuleStatus,
    determineRemainingModules,
    isRecommendationAllowed,
  } = useDetermineModuleStatus();
  const model = useAppSelector((s) => s.dashboard.model)!;
  const dashboardExtraMetadata = useAppSelector((s) => s.dashboard.extraMetadata);
  const { isDownMd } = useResponsive();

  const isRecommendationModeStatic = isTenantRecommendationModeStatic();

  const amounts = sanitizeAmounts(model);
  const chartData = createChartData(
    amounts.pensionNeed,
    amounts.patrimony,
    amounts.legalPension,
    amounts.lifeProjects
  );
  const completion = computeCompletion(model);
  const needsRevenues = computerNeedsRevenues(model);
  const statuses = determineModuleStatus(model);
  const remainingModules = determineRemainingModules(statuses);
  const recommendationAllowed = isRecommendationAllowed(statuses);

  const { goToModuleCommand } = useGoToModule();
  const { goToRecommendationCommand } = useGoToRecommendation();

  const { dashboardEvent, dashboardValidate } = useAnalytics();

  function isProfilingStateCompletedAndLocked(): boolean {
    return statuses[Module.InvestorProfile] === ModuleStatus.CompletedAndLocked;
  }

  function goToNext() {
    const nextModule = remainingModules[0];
    goToModuleCommand(nextModule);
  }

  function goToRecommendation() {
    dashboardValidate();
    goToRecommendationCommand();
  }

  useEffect(() => {
    dashboardEvent(
      model,
      needsRevenues.revenues - needsRevenues.needs,
      completion,
      needsRevenues,
      dashboardExtraMetadata.householdMaximumTaxDeduction
    );
  }, []);

  const barChartData = {
    legalPension: model.legalPensionMonthlyAmount || 0,
    patrimony: model.patrimonyMonthlyAmount || 0,
    pensionNeeds: model.pensionNeedMonthlyAmount || 0,
    lifeProjects: model.lifeProjectsMonthlyAmount || 0,
  };

  const sumOfAll = Object.values(barChartData).reduce((a, b) => a + b, 0);

  return (
    <>
      <Container className={styles.Grid}>
        <PageTitle>{i18n.dashboardPage.pageTitle}</PageTitle>
        <Card className={styles.CardGrid}>
          <div className={classNames(styles.ProjectCard, styles.MergeCard)}>
            <div>
              <CardTitle>{i18n.dashboardPage.project.title}</CardTitle>
              <div className={styles.Chart}>
                {sumOfAll === 0 ? (
                  <NoChartIcon />
                ) : !isDownMd ? (
                  <>
                    <div className={styles.LeftLegend}>
                      <span>{i18n.dashboardPage.chart.yourExpense}</span>
                      <span className={styles.Amount}>
                        {amountPerMonth(
                          barChartData.pensionNeeds + barChartData.lifeProjects
                        )}
                      </span>
                    </div>
                    <DashboardChart
                      className={styles.SvgChart}
                      data={barChartData}
                    />
                    <div className={styles.RightLegend}>
                      <span>{i18n.dashboardPage.chart.yourIncome}</span>
                      <span className={styles.Amount}>
                        {amountPerMonth(
                          barChartData.legalPension + barChartData.patrimony
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <DashboardChart
                      className={styles.SvgChart}
                      data={barChartData}
                    />
                    <div className={styles.MobileLegend}>
                      <div className={styles.LeftLegend}>
                        <span>{i18n.dashboardPage.chart.yourExpense}</span>
                        <span className={styles.Amount}>
                          {amountPerMonth(
                            barChartData.pensionNeeds +
                              barChartData.lifeProjects
                          )}
                        </span>
                      </div>
                      <div className={styles.RightLegend}>
                        <span>{i18n.dashboardPage.chart.yourIncome}</span>
                        <span className={styles.Amount}>
                          {amountPerMonth(
                            barChartData.legalPension + barChartData.patrimony
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {isProfilingStateCompletedAndLocked() && (
              <div className={styles.Disclaimer}>
                <SvgWrapperComponent ariaHidden={true}>
                  <InfoIcon className={styles.InfoImage}/>
                </SvgWrapperComponent>
                <span> {i18n.dashboardPage.disclaimer} </span>
              </div>
            )}
            <div
              className={classNames(
                isRecommendationModeStatic && styles.ProjectCardContainerDasboardStatic
              )}
            >
              <Information
                pensionNeedAmount={amounts.pensionNeed}
                pensionNeedStatus={statuses[Module.PensionNeed]}
                lifeProjectsAmount={amounts.lifeProjects}
                lifeProjectsStatus={statuses[Module.LifeProjects]}
              />
              <InformationCard
                legalPensionAmount={amounts.legalPension}
                legalPensionStatus={statuses[Module.LegalPension]}
                patrimonyAmount={amounts.patrimony}
                patrimonyStatus={statuses[Module.Patrimony]}
              />
              {!isRecommendationModeStatic && (
                <TaxSavings
                  isTaxationCompleted={model.isTaxationCompleted}
                  taxationStatus={statuses[Module.Taxation]}
                  isInvestorProfileCompleted={model.isInvestorProfileCompleted}
                  investorProfileStatus={statuses[Module.InvestorProfile]}
                  investorProfileExpired={model.profiling?.isExpired}
                  riskProfile={model.profiling?.riskProfileTextId}
                />
              )}
            </div>
          </div>

          <div className={classNames(styles.InformationCard, styles.MergeCard)}>
            <FutureCard
              remainingModules={remainingModules}
              recommendationAllowed={recommendationAllowed}
              appointment={model.appointment}
              model={model}
              isReportAvailable={model.isReportAvailable}
              investorProfileExpired={model.profiling?.isExpired || false}
              onGoToNext={goToNext}
              onGoToRecommendation={goToRecommendation}
              chartData={chartData}
              objectiveAmount={needsRevenues.revenues - needsRevenues.needs}
            />
            <div
              className={classNames(
                styles.ImageContainer,
                isRecommendationModeStatic && styles.ImageContainerDashboardStatic
              )}
            >
              <SvgWrapperComponent ariaHidden={true}>
                <Assets.MeetingImage className={styles.Image} />
              </SvgWrapperComponent>
            </div>
          </div>
        </Card>
      </Container>
      <BackgroundImage />
    </>
  );
}
