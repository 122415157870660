import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { globali18n } from '../../../../../../i18n';
import {  ModalOutcome, StyledKendoModal, useModal, Button } from '../../../../../../shared';
import { ButtonStyles } from '../../../../../../shared/components/Button/ButtonStyles';
import { i18n } from '../../../../i18n';

export function OtherStatusModal(props : {hasValidate?: boolean}) {
  const { hideModal } = useModal();

  return (
    <>
      <StyledKendoModal
        title={i18n.professionalStatusPage.otherStatusModal.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'500px'}
      >
        <div id={"modal-content"}>
          {i18n.professionalStatusPage.otherStatusModal.content}
        </div>
        <DialogActionsBar layout="end">
          {props.hasValidate &&  <Button className={ButtonStyles.PrimaryAction} onClick={() => hideModal(ModalOutcome.Ok)}>
            {globali18n.labels.validate}
          </Button>}
        </DialogActionsBar>
      </StyledKendoModal>
    </>
  );
}
