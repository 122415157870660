import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { EProfileType } from "../models/ProfileTypeEnum";
import { StartQuestionnaireSession } from "../models/QuestionnaireSessionModel";

export async function startQuestionnaireSession(customerId: string | undefined, profiletype: EProfileType): Promise<StartQuestionnaireSession> {
  const apiUrl =
    apiUrls(customerId).investorProfile.questionnaireSession.startQuestionnaireSession;
  const response = await axios.post<StartQuestionnaireSession>(apiUrl, {
    profiletype: profiletype,
  });

  const data = response.data;

  return data;
}
