import classNames, { Argument } from "classnames";
import { useAddAccountCommand } from "../../../../modules/patrimony/commands/addAccount";
import { useDeleteAccountCommand } from "../../../../modules/patrimony/commands/deleteAccount";
import { useEditAccountCommand } from "../../../../modules/patrimony/commands/editAccount";
import { i18n } from "../../../../modules/patrimony/i18n";
import { ModalMode } from "../../../../modules/patrimony/models/ModalMode";
import { GetSavingAccountResponseModel } from "../../../../modules/patrimony/models/SavingsAccountModel";
import {
  SavingAccountType,
  setTransferAmount,
} from "../../../../modules/patrimony/patrimonySlice";
import { sumCurrentAmounts } from "../../../../modules/patrimony/utilities/sumCurrentAmounts";
import { FunctionComponent, SVGProps } from "react";
import {
  ButtonStyles,
  ModalOutcome,
  useModal,
  Button,
} from "../../../../shared";
import { amount } from "../../../../shared/utilities/intl";
import { useAppDispatch } from "../../../../store";
import { DeclarationCard } from "../DeclarationCard/DeclarationCard";
import { SavingsItem } from "../SavingsItem/SavingsItem";
import { SavingsModal } from "../SavingsModal/SavingModal";
import styles from "./SavingsCard.module.scss";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { ProductTypeModel } from "../../../../modules/patrimony/models/ProductTypeModel";

interface SavingsCardProps {
  title: string;
  subTitle: string;
  items: GetSavingAccountResponseModel[];
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  productTypes: ProductTypeModel[];
  savingAccountType: SavingAccountType;

  className?: Argument;
}

export function SavingsCard(props: SavingsCardProps) {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const { addAccountCommand } = useAddAccountCommand();
  const { editAccountCommand } = useEditAccountCommand();
  const { deleteAccountCommand } = useDeleteAccountCommand();
  const { addFinancialPatrimony } = useAnalytics();

  const t = i18n.declarationPage;

  const sum = sumCurrentAmounts(props.items);

  async function edit(position: number, account: GetSavingAccountResponseModel) {
    const result = await showModal<GetSavingAccountResponseModel>(
      <SavingsModal
        mode={ModalMode.Edit}
        title={""}
        account={account}
        productTypes={props.productTypes}
      />
    );

    if (result.outcome === ModalOutcome.Ok) {
      await editAccountCommand(
        props.savingAccountType,
        position,
        result.value!
      );
      dispatch(setTransferAmount());
    }

    // Delete button
    if (result.outcome === ModalOutcome.Other) {
      await deleteAccountCommand(props.savingAccountType, position, account.id);
      dispatch(setTransferAmount());
    }
  }

  async function add() {
    addFinancialPatrimony();
    const newAccount: Partial<GetSavingAccountResponseModel> = {};
    const result = await showModal<GetSavingAccountResponseModel>(
      <SavingsModal
        mode={ModalMode.New}
        title={""}
        account={newAccount}
        productTypes={props.productTypes}
      />
    );

    if (result.outcome === ModalOutcome.Ok) {
      await addAccountCommand(props.savingAccountType, result.value!);
      dispatch(setTransferAmount());
    }
  }

  return (
    <DeclarationCard
      title={props.title}
      subTitle={props.subTitle}
      icon={props.icon}
    >
      {props.items.map((acc, i) => (
        <div key={i}>
          <SavingsItem account={acc} onEdit={() => edit(i, acc)} />
          <hr className={styles.Separator} />
        </div>
      ))}
      <Button
        id="add-saving"
        className={classNames(ButtonStyles.PatrimonyAdd, styles.AddButton)}
        onClick={add}
      >
        {t.savingsCard.add[props.savingAccountType]}
      </Button>
      <div className={styles.TotalGroup}>
        <div className={styles.TotalLabel}>{t.savingsCard.total}</div>
        <div className={styles.TotalAmount}>{amount(sum)}</div>
      </div>
    </DeclarationCard>
  );
}
