import classNames, { Argument } from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './CheckboxGroup.module.scss';

interface CheckboxGroupProps {
  isInvalid?: boolean;
  className?: Argument;
}

export function CheckboxGroup(props: PropsWithChildren<CheckboxGroupProps>) {
  return (
    <div className={classNames(styles.Group, { [styles.Invalid]: props.isInvalid }, props.className)}>
      {props.children}
    </div>
  );
}
