import { PropsWithChildren, ReactElement, useState } from 'react';
import { Modal } from './Modal';
import { ModalContext } from './ModalContext';
import { ModalOutcome } from './ModalResult';

export function ModalProvider(props: PropsWithChildren<{}>) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<ReactElement>(<></>);
  const [resolve, setResolve] = useState<(result: any) => void>(() => {});

  function showModal<T>(content: ReactElement): Promise<T> {
    setShow(true);
    setContent(content);

    // We need to wrap the promise resolve function into a lambda
    // React will call the 'outer' function to get the state value
    const promise = new Promise<T>((res) => setResolve(() => res));
    return promise;
  }

  function hideModal<T>(outcome: ModalOutcome, value: T) {
    setShow(false);
    resolve({ outcome, value });
  }

  return (
    <ModalContext.Provider value={{ show, content, showModal, hideModal }}>
      <Modal />
      {props.children}
    </ModalContext.Provider>
  );
}
