import { useGlobalCommand } from "../../../shared/commands/useGlobalCommand";
import { Module } from "../../../shared/models/Module";
import { DashboardModel } from "../models/DashboardModel";
import { ModuleStatus } from "../models/ModuleStatus";

export interface ModuleStatuses {
  [Module.PensionNeed]: ModuleStatus;
  [Module.LegalPension]: ModuleStatus;
  [Module.Patrimony]: ModuleStatus;
  [Module.LifeProjects]: ModuleStatus;
  [Module.Taxation]: ModuleStatus;
  [Module.InvestorProfile]: ModuleStatus;
}

export function useDetermineModuleStatus() {
  const { getDashboardModulesAvailable } = useGlobalCommand();

  function isRecommendationAllowed(statuses: ModuleStatuses): boolean {
    // All modules have to be completed to allow access to the recommendation
    const requiredModules = getDashboardModulesAvailable().map((module)=> statuses[module]);
    return requiredModules.every((m) => m === ModuleStatus.Completed);
  }

  // Use the dashboard model to determine the status of the modules
  // This is more efficient than calling the completion API since we already have this information for display
  function determineModuleStatus(model: DashboardModel): ModuleStatuses {
    const dashboardModuleAvailable = getDashboardModulesAvailable();
    // All these modules are required to acces to the investor profile  module
    const completedAllModules =
    model.legalPensionMonthlyAmount !== null &&
    model.pensionNeedMonthlyAmount !== null &&
    model.isTaxationCompleted != false &&
    model.lifeProjectsMonthlyAmount !== null &&
    model.patrimonyMonthlyAmount !== null;
    
    // Two modules are mandatory to continue the flow : legalPension, pensionNeed  and the taxation module is conditionnaly required 
    // For recommendation static mode, the taxation module doesnt appear on the dashboard so it could not be a required module 
    const completedMandatoryModules =
      model.legalPensionMonthlyAmount !== null &&
      model.pensionNeedMonthlyAmount !== null &&
      // if taxation not available then return true because user doesnt need to complete it
      (isModuleAvailable(Module.Taxation) ? model.isTaxationCompleted !== false : true); 
    
    const completeModuleRequiredOnRecommendation =  
    model.legalPensionMonthlyAmount !== null &&
    model.pensionNeedMonthlyAmount !== null &&
    model.lifeProjectsMonthlyAmount !== null &&
    model.patrimonyMonthlyAmount !== null;

    const profilingModuleCompleted =  model.profiling !== null && model.profiling.riskProfileTextId !== null;

    return {
      // Pension need is always enabled
      [Module.PensionNeed]:
        model.pensionNeedMonthlyAmount !== null
          ? ModuleStatus.Completed
          : ModuleStatus.Available,
      // Legal pension is always enabled
      [Module.LegalPension]:
        model.legalPensionMonthlyAmount !== null
          ? ModuleStatus.Completed
          : ModuleStatus.Available,
      // Taxation is always enabled
      [Module.Taxation]:
      isModuleAvailable(Module.Taxation) ?
        model.isTaxationCompleted !== false
          ? ModuleStatus.Completed
          : ModuleStatus.Available
        : completeModuleRequiredOnRecommendation ? ModuleStatus.DashboardUnavailable : ModuleStatus.DashboardUnavailableAndRouteGuardLocked,
      // Patrimony is enabled once pension need, legal pension and taxation have been completed
      [Module.Patrimony]:
        model.patrimonyMonthlyAmount !== null
          ? ModuleStatus.Completed
          : completedMandatoryModules
          ? ModuleStatus.Available
          : ModuleStatus.Locked,
      // Life projects is enabled once pension need, legal pension and taxation have been completed
      [Module.LifeProjects]:
        model.lifeProjectsMonthlyAmount != null
          ? ModuleStatus.Completed
          : completedMandatoryModules
          ? ModuleStatus.Available
          : ModuleStatus.Locked,

      // Cause of the update of the taxation flow, we could get a special case with an a InvestorProfile complete but with the mandatory taxation module to be retake 
      // So We have to consider and give access to the profilling module only if the user has completedAllMandatoriesModules
      [Module.InvestorProfile]:      
      isModuleAvailable(Module.InvestorProfile) ?
        profilingModuleCompleted && completedAllModules
          ? ModuleStatus.Completed
          : profilingModuleCompleted
          ? ModuleStatus.CompletedAndLocked
          : completedAllModules
          ? ModuleStatus.Available
          : ModuleStatus.Locked
      : ModuleStatus.Locked
    };

    function isModuleAvailable(module: Module): boolean {
     return !!dashboardModuleAvailable.find((m) => m === module);
    }
  }
  
  function determineRemainingModules(statuses: ModuleStatuses): Module[] {
    // Return the modules in either 'Available' or 'Locked' status
    return Object.entries(statuses)
      .filter(([k, v]) => v !== ModuleStatus.Completed && v !== ModuleStatus.CompletedAndLocked && v !== ModuleStatus.DashboardUnavailable && v !== ModuleStatus.DashboardUnavailableAndRouteGuardLocked)
      .map(([k, v]) => k as Module);
  }


  return {
    isRecommendationAllowed,
    determineModuleStatus,
    determineRemainingModules
  }

}