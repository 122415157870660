import { PropsWithChildren, useState } from 'react';
import { Theme } from './Theme';
import { ThemeContext } from './ThemeContext';

export function ThemeProvider(props: PropsWithChildren<{ theme: Theme }>) {
  const [theme] = useState<Theme>(props.theme);

  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  );
}
