import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { ModalOutcome, useModal } from "../components/Modal";
import { UserType } from "../requests/getUserReferenceRequest";
import { internalUrls } from "../utilities/urls";
import { RegisterOrLoginModal, RegisterOrLoginResult } from '../../modules/dashboard/components/RegisterOrLoginModal/RegisterOrLoginModal';
import { i18n as dashboardI18n } from '../../modules/dashboard/i18n';

export function useHandleMeetingLinkClickedCommand() {
    const { showModal } = useModal();
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector((s) => s.authentication.isAuthenticated);
    const userType = useAppSelector(
      (s) => s.authentication.userType
    ); 

    async function handleMeetingLinkClickedCommand(){
      if(isAuthenticated && userType === UserType.User) {
        navigate(internalUrls.appointment);
      } else {
        const result = await showModal<number>(
          <RegisterOrLoginModal title={dashboardI18n.registerOrLoginModal.titleMeeting}/>
        );
        if (result.outcome === ModalOutcome.Ok) {
          navigate(result.value === RegisterOrLoginResult.Register 
            ? internalUrls.registration.registration 
            : internalUrls.authentication.login);
          return;
        }
        return;
      }  
    }   
    
    return { handleMeetingLinkClickedCommand };
}