import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { EmailInputGroup } from 'shared/components/EmailInputGroup/EmailInputGroup';
import { useExternalUrls } from '@ifs/libs/src/shared/utilities/externalUrls';
import { hasErrors } from 'shared/utilities/hasErrors';
import { inputMaxLength } from 'shared/utilities/validation';

import { i18n } from '../../i18n';
import { getFormConfig } from './formConfig';
import styles from './RegistrationPage.module.scss';
import { useRegister } from '../../commands/registerCommand';
import { RegisterOutcome } from '../../requests/registerRequest';
import classNames from 'classnames';
import { BackgroundImage } from '../../components/BackgroundImage/BackgroundImage';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonGroupOption,
  ButtonStyles,
  CheckboxGroup,
  Container,
  PageTitle,
  InputGroup,
  ValidationMessages,
  useAuthEvent
} from '@ifs/libs';
import { StyledKendoCheckbox, TextInput } from '@ifs/libs/src/shared';
import { condition as Displayed } from '../../Displayed';
import { useEffect, useState } from 'react';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { PasswordConfirmInputGroup } from '../../../../shared/components';
import { globali18n } from '@ifs/libs/src/i18n';

const yesNoOptions: ButtonGroupOption[] = [
  { label: 'Oui', value: 1 },
  { label: 'Non', value: 0 }
];

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  acceptPolicies: boolean;
  acceptCommercialInformation: boolean;
  isBankClient: number;
}

const defaultValues = {};

export function RegistrationPage() {
  const { register, trigger, handleSubmit, watch, formState, setError, control } =
    useForm<FormData>({
      criteriaMode: 'all',
      defaultValues: defaultValues,
      mode: 'all'
    });
  const navigate = useNavigate();
  const { registerCommand } = useRegister();
  const { externalUrls } = useExternalUrls();
  const { authEvent } = useAuthEvent();

  async function onSubmit(data: FormData) {
    const model = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      isBankClient: data.isBankClient === 1,
      canReceiveCommercialContent: !!data.acceptCommercialInformation
    };

    const result = await registerCommand(model);

    if (result === RegisterOutcome.LoginAlreadyExists) {
      setError('email', { types: { loginAlreadyExists: true } });
    }
  }

  const formConfig = getFormConfig(register, watch);

  function cancel() {
    navigate(-1);
  }

  const errors = formState.errors;
  const isSubmitted = formState.isSubmitted;

  const [termsOfUseLink, setTermsOfUseLink] = useState<string>('');

  async function getLink() {
    const urls = await externalUrls();
    setTermsOfUseLink(urls.TermsOfUsePage);
  }

  useEffect(() => {
    getLink();
  }, []);

  if (!Displayed.displayElement) {
    formConfig.isBankClient.field = register('isBankClient', { required: false });
  }

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.registrationPage.labels.title}</PageTitle>
      <h2 className={ModuleStyles.Subtitle}>{i18n.registrationPage.labels.subtitle}</h2>

      <form
        className={ModuleStyles.FormGridResponsive}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <EmailInputGroup
          name="email"
          isSubmitted={isSubmitted}
          register={register}
          errors={errors}
        />

        <InputGroup
          isInvalid={hasErrors('firstName', errors)}
          isValid={isSubmitted && !hasErrors('firstName', errors)}
        >
          <label htmlFor={'firstName'}>{i18n.registrationPage.labels.firstName}</label>
          <TextInput
            required
            aria-required={'true'}
            type="text"
            id="firstName"
            maxLength={inputMaxLength}
            register={formConfig.firstName.field}
          />
          <ValidationMessages
            messages={getValidationMessages('firstName', errors, formConfig.firstName.errors)}
          />
        </InputGroup>

        <InputGroup
          isInvalid={hasErrors('lastName', errors)}
          isValid={isSubmitted && !hasErrors('lastName', errors)}
        >
          <label htmlFor={'lastName'}>{i18n.registrationPage.labels.lastName}</label>
          <TextInput
            required
            aria-required={'true'}
            type="text"
            id="lastName"
            maxLength={inputMaxLength}
            register={formConfig.lastName.field}
          />{' '}
          <ValidationMessages
            messages={getValidationMessages('lastName', errors, formConfig.lastName.errors)}
          />
        </InputGroup>

        <PasswordConfirmInputGroup
          passwordName="password"
          passwordConfirmName="passwordConfirm"
          isSubmitted={isSubmitted}
          errors={errors}
          register={register}
          trigger={trigger}
          watch={watch}
        />

        {Displayed.displayElement && (
          <>
            <hr className={ModuleStyles.Divider} />

            <label>{i18n.registrationPage.labels.isBnppClientTitle}</label>
            <div>
              <label htmlFor={'isBankClient'}>{i18n.registrationPage.labels.isBnppClient}</label>
            </div>
            <CheckboxGroup isInvalid={hasErrors('isBankClient', errors)}>
              <Controller
                control={control}
                name="isBankClient"
                render={({ field: { onChange, value } }) => (
                  <ButtonGroup
                    className={styles.ButtonGroup}
                    options={yesNoOptions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </CheckboxGroup>
            <ValidationMessages
              messages={getValidationMessages(
                'isBankClient',
                errors,
                formConfig.isBankClient.errors
              )}
            />
          </>
        )}

        <hr className={ModuleStyles.Divider} />

        <CheckboxGroup isInvalid={hasErrors('acceptPolicies', errors)}>
          <Controller
            control={control}
            name="acceptPolicies"
            rules={formConfig.acceptPolicies.rules}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <StyledKendoCheckbox
                  id="acceptPolicies"
                  onBlur={onBlur}
                  onChange={onChange}
                  checked={value}
                  name="acceptPolicies"
                />
                <label htmlFor={'acceptPolicies'}>
                  {i18n.registrationPage.labels.acceptPolicies[0]}
                  <a className={styles.Link} href={termsOfUseLink}>
                    {i18n.registrationPage.labels.acceptPolicies[1]}
                  </a>
                  {i18n.registrationPage.labels.acceptPolicies[2]}
                </label>
                <div className={styles.AcceptPoliciesRequiredMessage}>
                  <ValidationMessages
                    messages={getValidationMessages(
                      'acceptPolicies',
                      errors,
                      formConfig.acceptPolicies.errors
                    )}
                  />
                </div>
                
              </>
            )}
          />
        </CheckboxGroup>

        <CheckboxGroup>
          <Controller
            control={control}
            name="acceptCommercialInformation"
            aria-label="acceptCommercialInformation"
            render={({ field: { onChange, onBlur, value } }) => (
              <StyledKendoCheckbox
                id="acceptCommercialInformation"
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
                name="acceptCommercialInformation"
              />
            )}
          />

          <>
            <label htmlFor={'acceptCommercialInformation'}>
              {i18n.registrationPage.labels.acceptReceiveCommercialInformation}
            </label>
          </>
        </CheckboxGroup>

        <p className={styles.Contact}>{i18n.registrationPage.labels.checkboxDisclaimer}</p>

        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
          onClick={() => authEvent('register')}
        >
          {i18n.registrationPage.labels.createAccount}
        </Button>
        <Button
          type="button"
          className={classNames(ButtonStyles.Unfill, styles.Button)}
          onClick={cancel}
        >
          {globali18n.labels.previous}
        </Button>
        <Link className={styles.LoginLink} to={internalUrls.authentication.login}>
          {i18n.registrationPage.labels.alreadyHaveAnAccount}
        </Link>
      </form>
      <BackgroundImage />
    </Container>
  );
}
