import { AppointmentFormRuleType } from "../Models/FormRule/AppointmentFormRule";
import styles from "../../modules/dashboard/components/Future/Future.module.scss";
import { TenantModel } from "../Models/Tenant";
import {
  DashboardFooterImage,
  LegalPensionFooterImage,
  LegalPensionSummaryFooterImage,
  PensionNeedFooterImage,
  TaxationFooterImage,
  LifeProjectFooterImage,
  PatrimonyFooterImage,
  RegistrationFooterImage,
  RecommendationTopLeftImage,
  RecommendationMiddleImage,
  RecommendationBottomLeftImage,
  SharedMailbox,
  BackgroundLegalPensionLandingImage,
  BackgroundLifeProjectLandingImage,
  BackgroundPatrimonyLandingImage,
  BackgroundPensionNeedLandingImage,
  BackgroundTaxationLandingImage,
  BackgroundDiagnosticLandingImage,
  LockIcon,
  PatrimonyIconUncompleted,
  PatrimonyIconLock,
  PatrimonyIcon,
  TaxationIconUncompleted,
  TaxationIconLock,
  TaxationIcon,
  LifeProjectsIconUncompleted,
  LifeProjectsIconLock,
  LifeProjectsIcon,
  LegalPensionIconUncompleted,
  LegalPensionLock,
  LegalPensionIcon,
  PensionNeedIconLock,
  PensionNeedIconUncompleted,
  PensionNeedIcon,
  HeaderAppIcon,
  CheckedIcon,
  UncheckedIcon,
  TechnicalErrorBackground,
  TopImage,
  BackgroundInvestorProfileLandingImage,
  InvestorProfileFooterImage,
  MeetingImage,
  ReaderIcon,
  ReaderCutIcon,
  RecommendationProductSheetModalFooterImage,
  Building,
  Trophy,
} from "./assets";
import { Products } from "./Products";
import { RecommendationI18nType } from "../../modules/recommendation/i18n";

export const bcef: TenantModel = {
  name: "BCEF",
  cssVar: {
    "--primary-wintergreen": "#005736",
    "--primary-hubble": "#4E5E98",
    "--primary-keppel": "#0F8759",
    "--primary-green_sheen": "#4E8136",
    "--primary-sage": "#2E7175",
    "--primary-lemon_meringue": "#F1ECC3",
    "--primary-burlywood": "#2A3F74",
    "--danger-red": "#D03E3E",
    "--secondary-ruddy_brown": "#004BCC",
    "--primary-green_70": "#004BCC",
    "--secondary-copper": "#DEEDFD",
    "--background-floral_white": "#FCF9ED",
    "--light-old_lace": "#FCF9ED",
    "--header-background_lotion": "white",
    "--secondary-main": "#51687B",
    //themeBackgroundColor is the same as Keppel
    "--themeBackgroundColor": "#00965D",
    "--secondary-CTA": "#51687B",
    "--landing-interlayer": "#DEEDFD",
    "--legal-pension-summary-last-salary-legend": "#00965D",
    "--legal-pension-summary-pension-amount-legend": "#00B7A1",
    "--legal-pension-summary-loss-amount-legend": "#004BCC",
    "--app-font-family": "Open Sans",
    "--fiscality-color": "#476866",
  },
  i18n: {
    appointment: (i18n: any) => {
      return i18n;
    },
    profile: (i18n: any) => {
      return i18n;
    },
    dashboard: (i18n: any) => {
      return (
        (i18n.dashboardPage.future.buttons.accessToRecommendation =
          "Découvrez les produits d’épargne retraite"),
        (i18n.dashboardPage.future.buttons.weGoNext =
          "Découvrez les produits d’épargne retraite"),
        (i18n.dashboardPage.future.buttons.continueJourney = "Continuez votre parcours"),
        (i18n.dashboardPage.future.hasAppointmentNoModuleDone = "Continuez votre parcours"),
        (i18n.dashboardPage.future.download = "Votre rapport complet"),
        (i18n.dashboardPage.future.texts.rightWay = (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Vous êtes sur la bonne voie !
            </p>
            <p>Continuez votre parcours pour affiner votre diagnostic.</p>
          </div>
        )),
        (i18n.dashboardPage.future.texts.deficit = (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Passez maintenant à l’action pour optimiser vos revenus&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Votre futur sera fier de vous.
            </p>
          </div>
        )),
        (i18n.dashboardPage.future.texts.surplus = (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Félicitations, vos besoins sont couverts&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Nous pouvons vous accompagner dans l’optimisation de vos
              investissements et de votre fiscalité.
            </p>
          </div>
        ))
      );
    },
    shared: (i18n: any) => {
      return (
        (i18n.landingPage.start = "Commencer"),
        (i18n.header.register = "Inscription espace retraite"),
        (i18n.header.logIn = "Mon espace retraite")
      );
    },
    recommendation: (i18n: RecommendationI18nType) => {
      return (
        i18n.productSheetModal.product = Products        
      );
    },
    registration: (i18n: any) => {
      return (i18n.registrationPage.labels.checkboxDisclaimer =
        "Nous pourrons vous recontacter pour vous fournir du contenu informatif. Vous pourrez changer d’avis à tout moment dans votre interface de gestion de compte.");
    },
    global: (i18n: any) => i18n,
    cookies: (i18n: any) => {
      return (
        (i18n.cookiesYahoo = <></>),
        (i18n.cookiesDoubleClick = <></>),
        (i18n.cookiesGoogle = <></>),
        (i18n.topBloc = (
          <div>
            <p>
              Le Groupe BNP Paribas est particulièrement attentif à vous délivrer le meilleur service possible et nous souhaitons préserver la confiance que vous nous témoignez.
            </p>
            <p>
              Dans ce cadre, nous avons adopté des principes forts afin d’assurer la protection de vos données.
            </p>
            <p>
              C'est pourquoi nous souhaitons vous fournir des informations transparentes sur la 
              manière dont nous plaçons, utilisons et stockons des cookies sur votre appareil lorsque 
              vous consultez nos sites ou applications (ci-après le « <strong>MonDemain</strong> ») et vous 
              présenter les options qui vous permettent de les gérer et les supprimer.
            </p>
          </div>
        )),
        (i18n.whatIsACookie= (
            <section>
                <p>
                    Les cookies sont de petits fichiers texte, image ou logiciel qui
                    peuvent être déposés et/ou lus sur votre appareil lorsque vous
                    accédez à notre Site et/ou à l’Application. Le mot « <strong>appareil</strong> »,
                    lorsqu'il est utilisé dans la présente Politique Cookies, désigne
                    notamment les ordinateurs, les téléphones intelligents, les
                    tablettes et tout autre appareil utilisé pour accéder à Internet.
                  </p>
                  <p>
                    Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont
                    supprimés de votre terminal une fois la session et le navigateur
                    fermés ; ou (ii) persistants, ce qui signifie qu’ils seront stockés
                    sur votre terminal jusqu’à leur suppression.
                  </p>
                  <p>
                    Les cookies peuvent avoir de nombreuses fonctions et permettent
                    notamment de :
                  </p>
                  <p>
                    <ul>
                      <li>
                        <strong>vous authentifier et vous identifier</strong> sur notre
                        Site et/ou l’Application afin de vous fournir les services que vous avez demandés ;
                      </li>
                      <li>
                        <strong>améliorer la sécurité du Site et/ou de l’Application</strong> y compris la
                        prévention de l’usage frauduleux d’identifiants de connexion et
                        la protection des données utilisateurs de l’accès par des tiers
                        non autorisés ;
                      </li>
                      <li>
                        <strong>
                        vous adresser des publicités personnalisées notamment basées sur vos historiques de navigation et préférences en ligne ;
                        </strong>
                      </li>
                      <li>
                        <strong>
                        suivre votre manière d’utiliser notre Site et/ou Application afin de l’améliorer ;
                        </strong>
                      </li>
                      <li>
                        <strong>améliorer votre expérience utilisateur</strong> en
                        adaptant le contenu de notre Site ou de notre Application à vos centres d’intérêt et en
                        vous proposant des publicités et contenus BNP Paribas plus
                        pertinents sur notre Site et/ou Application ou des sites tiers. ;
                      </li>
                      <li>
                        <strong>se souvenir des informations</strong> que vous nous avez
                        fournies (par ex. pour remplir automatiquement les formulaires
                        avec les informations que vous nous avez précédemment fournies
                        afin de vous connecter plus rapidement) ;
                      </li>
                      <li>
                        <strong>enregistrer vos préférences</strong> et vos paramètres
                        d'utilisation de notre Site et/ou Application (par exemple langue, fuseau horaire,
                        etc.)
                      </li>
                    </ul>
                  </p>      
            </section>
        )),
        (i18n.typeOfInformationStored = (
            <section>
                 <p>
                    Les informations stockées par les cookies déposés sur votre appareil
                    peuvent avoir trait aux éléments suivants, dans la limite de leur
                    durée de conservation :
                  </p>
                  <p>
                    <ul>
                      <li>
                        les pages web que vous avez visitées en utilisant cet appareil ;
                      </li>
                      <li>les publicités sur lesquelles vous avez cliqué ;</li>
                      <li>le type de navigateur que vous utilisez ;</li>
                      <li>votre adresse IP ;</li>
                      <li>
                        et toute autre information que vous avez fournie sur notre Site/Application.
                      </li>
                    </ul>
                    </p>
            </section>
        )),
        (i18n.typeOfUsage = (
            <section>
              <p>
                Les cookies que nous utilisons sur nos Sites et/ou Applications sont classés en
                différentes catégories :
              </p>
              <p>
                <strong>3.1 Cookies strictement nécessaires (obligatoires)</strong>
              </p>
              <p>
                Ces cookies sont indispensables afin de permettre au Site/à l’Application de fonctionner correctement. Ils peuvent comprendre, par exemple, des cookies qui collectent les identifiants de session et données d’identification, des cookies qui permettent de personnaliser l’interface d’un Site et/ou d’une Application (par exemple, pour le choix de la langue ou de la présentation d’un service) ou encore certains cookies de mesure d’audience. Cette catégorie comprend également les cookies qui nous permettent de nous conformer à nos obligations légales, notamment en assurant un environnement en ligne sûr (par exemple en détectant les échecs répétitifs de connexion afin d’empêcher des personnes non autorisées d’accéder à votre compte).
              </p>
              <p>
                <strong>
                  3.2 Cookies de préférence (soumis à votre consentement)
                </strong>
              </p>
              <p>
                Ces cookies nous permettent ainsi qu’à nos partenaires, de personnaliser et proposer les contenus et fonctionnalités des Sites et/ou de l’Application (et notamment l’affichage de nos produits et services). Ils sont utilisés pour améliorer notre Site et/ou Application et votre expérience utilisateur.
              </p>
              <p>
                <strong>
                  3.3 Cookies de mesure d’audience (soumis au consentement, sauf sous certaines conditions)
                </strong>
              </p>
              <p>
                Ces cookies nous permettent ainsi qu’à nos partenaires, de comprendre comment les utilisateurs arrivent sur notre Site et/ou Application, de mesurer le nombre de visiteurs sur notre Site et/ou Application mais également d’analyser la manière dont les visiteurs naviguent sur le Site/l’Application ou de reconstituer leur parcours.
                Cela nous aide à améliorer la manière dont nos Sites et/ou Applications fonctionnent en nous assurant, par exemple, que les utilisateurs trouvent facilement ce qu’ils cherchent.<br/>
                Ces cookies peuvent être exemptés du consentement quand ils sont strictement nécessaires au fonctionnement du Site et/de l’Application conformément à l’article 3.1.
                Dans le cas contraire, ils sont soumis au consentement conformément au présent article 3.3.
              </p>
              <p>
                <strong>
                  3.4 Cookies publicitaires et cookies de suivi (soumis à votre consentement)
                </strong>
              </p>
              <p>
                Il existe différentes catégories de cookies permettant de vous proposer de la publicité.
                Les informations obtenues via des cookies publicitaires sont fréquemment partagées avec nos partenaires via l’utilisation de cookies tiers. Vous pouvez retrouver plus d’informations sur ces partenaires dans la section 4 de la présente Politique Cookies.
              </p>
              <p>
                <strong>Cookies publicité personnalisée</strong>
              </p>
              <p>
                Ces cookies sont utilisés afin de vous proposer des publicités personnalisées, plus adaptées à vos centres d’intérêt. Ils enregistrent votre visite sur notre Site et/ou Application, les pages que vous avez consultées ainsi que les liens que vous avez suivis. Nous utilisons ainsi que nos partenaires ces informations afin de personnaliser notre Site et/ou Application et les publicités qui y figurent, le cas échéant, en fonction de vos centres d’intérêt.
              </p>
              <p>
                Ils peuvent également servir à afficher des publicités personnalisées sur nos produits sur d’autres sites, à mesurer l'efficacité d'une campagne publicitaire, à limiter la fréquence de diffusion (c'est-à-dire pour limiter le nombre de fois que vous voyez une publicité), l'affiliation publicitaire, la détection de la fraude au clic, les études de marché, l'amélioration des produits, le débogage.
              </p>
              <p>
                <strong>
                  3.5 Réseaux sociaux et cookies de partage de contenu (soumis à votre consentement)
                </strong>
              </p>
              <p>
                <strong>
                  Cookies de réseaux sociaux :
                </strong>
              </p>
              <p>
                Ces cookies nous permettent, ainsi qu’à nos partenaires, de partager des informations avec les réseaux sociaux utilisés sur nos Sites et/ou Applications.
                L’affichage de ce type de contenu sur nos Sites et/ou Applications partage des informations à propos de votre navigation sur nos Sites/Applications avec les réseaux sociaux utilisés.
              </p>
              <p>
                <strong>
                  Cookies de partage de contenu :
                </strong>
              </p>
              <p>
                Ces cookies nous permettent, ainsi qu’à nos partenaires, de vous proposer la visualisation sur notre Site/ Application de contenu hébergé sur un site externe.
                La visualisation de ce type de contenu sur nos Sites et/ou Applications implique de partager avec le site d’hébergement des informations à propos de votre navigation sur nos Sites et/ou Applications.
              </p>
              <p>
                Vous trouverez dans la section 4 de la présente Politique Cookies de plus amples informations sur les partenaires ainsi que des explications sur la manière dont vous pouvez configurer vos préférences.
              </p>
              
            </section>
        )),
        (i18n.howHandleCookies = (
          <section>
            <p>
              Pour consulter les différentes catégories de cookies que nous utilisons sur le Site et/ou l’Application et paramétrer vos choix, vous pouvez consulter le module de gestion des cookies accessibles en bas de page. Vous pouvez modifier vos préférences à tout moment (retirer ou donner à nouveau votre consentement).
            </p>
            <p>
              Veuillez noter que l’utilisation des cookies strictement nécessaires au bon fonctionnement du Site/de l’Application ne requiert pas votre consentement. C’est pourquoi l’option « cookies strictement nécessaires » est pré cochée dans notre outil de gestion des cookies et n’est pas optionnelle.
            </p>
            <p>
              En refusant certains types de cookies (les cookies de préférence par exemple), nous ne serons pas en mesure d’optimiser votre expérience utilisateur sur notre Site et certaines parties pourraient ne pas fonctionner correctement.
            </p>
            <p>
              Par défaut, nous enregistrons vos choix en matière de cookies sur un appareil donné pour une durée maximale de 6 mois. Si vous changez d’avis sur les préférences que vous avez exprimées concernant les cookies, vous pouvez mettre à jour vos choix à tout moment, en suivant ce lien. Nous vous demanderons à nouveau de faire un choix tous les 6 mois.
            </p>
            <p>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                Paramètres des cookies
              </button>
            </p>
          </section>
        )),
        (i18n.cookiesTitle =
          " 4. Dans ce cadre, qui place les cookies sur votre appareil (par exemple les sociétés du Groupe BNP Paribas ou un partenaire) ?")
      );
    },
  },
  displayed: {
    dashboard: (condition: any) => {
      return (condition.displayMeetingElement = true);
    },
    footer: (condition: any) => {
      return (
        (condition.displayElement = true),
        (condition.footerLinkNotBeDisplayed = ["ProjectionExplanationPage"])
      );
    },
    appointment: (condition: any) => {
      return (
        (condition.displayElement = true),
        (condition.displayRdvConfirmModal = false)
      );
    },
    register: (condition: any) => {
      return (condition.displayElement = false);
    },
  },
  formRule: {
    appointment: (formRule: AppointmentFormRuleType) => {
      return (
        (formRule.desiredDateRequired = true),
        (formRule.desiredTimeSlotRequired = true)
      );
    },
  },
  assets: {
    cookies: (assets: any) => {
      return (assets.TopImage = TopImage);
    },
    appointment: (assets: any) => {
      return (assets.BackgroundImage = DashboardFooterImage);
    },
    dashboard: (assets: any) => {
      return (
        (assets.BackgroundImage = DashboardFooterImage),
        (assets.LockIcon = LockIcon),
        (assets.PatrimonyIconUncompleted = PatrimonyIconUncompleted),
        (assets.PatrimonyIconLock = PatrimonyIconLock),
        (assets.PatrimonyIcon = PatrimonyIcon),
        (assets.TaxationIconUncompleted = TaxationIconUncompleted),
        (assets.TaxationIconLock = TaxationIconLock),
        (assets.TaxationIcon = TaxationIcon),
        (assets.LifeProjectsIconUncompleted = LifeProjectsIconUncompleted),
        (assets.LifeProjectsIconLock = LifeProjectsIconLock),
        (assets.LifeProjectsIcon = LifeProjectsIcon),
        (assets.LegalPensionIconUncompleted = LegalPensionIconUncompleted),
        (assets.LegalPensionLock = LegalPensionLock),
        (assets.LegalPensionIcon = LegalPensionIcon),
        (assets.PensionNeedIconLock = PensionNeedIconLock),
        (assets.PensionNeedIconUncompleted = PensionNeedIconUncompleted),
        (assets.PensionNeedIcon = PensionNeedIcon),
        (assets.MeetingImage = MeetingImage)
      );
    },
    legalPension: (assets: any) => {
      return (
        (assets.BackgroundImage = LegalPensionFooterImage),
        (assets.SummaryBackgroundImage = LegalPensionSummaryFooterImage)
      );
    },
    pensionNeed: (assets: any) => {
      return (assets.BackgroundImage = PensionNeedFooterImage);
    },
    taxation: (assets: any) => {
      return (assets.BackgroundImage = TaxationFooterImage);
    },
    lifeProjects: (assets: any) => {
      return (assets.BackgroundImage = LifeProjectFooterImage);
    },
    patrimony: (assets: any) => {
      return (
        (assets.BackgroundImage = PatrimonyFooterImage)
      );
    },
    registration: (assets: any) => {
      return (assets.BackgroundImage = RegistrationFooterImage);
    },
    recommendation: (assets: any) => {
      return (
        (assets.TopLeftImage = RecommendationTopLeftImage),
        (assets.MiddleImage = RecommendationMiddleImage),
        (assets.RecoStaticRightImage = MeetingImage),
        (assets.RecoStaticLeftImage = LifeProjectFooterImage),
        (assets.ProductSheetModalFooterImage =
          RecommendationProductSheetModalFooterImage),
        (assets.BottomLeftImage = RecommendationBottomLeftImage),
        (assets.BackgroundImage = DashboardFooterImage),
        (assets.Trophy = Trophy),
        (assets.Building = Building)
      );
    },
    shared: (assets: any) => {
      return (
        (assets.Mailbox = SharedMailbox),
        (assets.CheckedIcon = CheckedIcon),
        (assets.UncheckedIcon = UncheckedIcon),
        (assets.TechnicalErrorBackground = TechnicalErrorBackground)
      );
    },
    landingPages: (assets: any) => {
      return (
        (assets.BackgroundLegalPensionLandingImage =
          BackgroundLegalPensionLandingImage),
        (assets.BackgroundTaxationLandingImage =
          BackgroundTaxationLandingImage),
        (assets.BackgroundPatrimonyLandingImage =
          BackgroundPatrimonyLandingImage),
        (assets.BackgroundPensionNeedLandingImage =
          BackgroundPensionNeedLandingImage),
        (assets.BackgroundLifeProjectLandingImage =
          BackgroundLifeProjectLandingImage),
        (assets.BackgroundInvestorProfileLandingImage =
          BackgroundInvestorProfileLandingImage),
        (assets.BackgroundDiagnosticLandingImage =
          BackgroundDiagnosticLandingImage)
      );
    },
    investorProfile: (assets: any) => {
      return (
        (assets.BackgroundImage = InvestorProfileFooterImage),
        (assets.ReaderIcon = ReaderIcon),
        (assets.ReaderCutIcon = ReaderCutIcon)
      );
    },
    header: (assets: any) => {
      return (assets.HeaderAppIcon = HeaderAppIcon);
    },
  },
  envelops: {
    productSheet: (product: any) => {
      return (product = { ...Products });
    },
  },
};
