import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared';
import { resetProgress } from '../../../shared/layouts/ProgressLayout/progressSlice';
import { useAppDispatch, useAppStore } from '../../../store';
import { resetPatrimony, setAnswers, setProductTypes, setSynthesis } from '../patrimonySlice';
import { getPatrimonyRequest } from '../requests/getPatrimonyRequest';
import { getProductTypesRequest } from '../requests/getProductTypesRequest';
import { getSynthesisRequest } from '../requests/getSynthesisRequest';
import { getStepsCompletedRequest } from '../../../shared/requests/getStepsCompletedRequest';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { customerid } = useParams()

  function setup() {
    showLoaderWhile(async () => {
      // Module initialized, do nothing
      if (store.getState().patrimony.productTypes.length) {
        setLoading(false);
        return;
      }

      dispatch(resetProgress());

      const productTypes = await getProductTypesRequest();
      dispatch(setProductTypes(productTypes));

      const answers = await getPatrimonyRequest(customerid);
      // Check if Patrimony completed, if not cannt get patrimony synthesis
      const stepsCompleted = await getStepsCompletedRequest(customerid);
      const isPatrimonyStepCompleted = stepsCompleted.length ? stepsCompleted.findIndex((step)=> step.stepName === 'Patrimony') !== -1 : false;

      if (!answers?.realEstates.length && !answers?.savingsAccounts.length ) {
        setLoading(false);
        return;
      }

      dispatch(setAnswers(answers));

      // IF Patrimony not completed, we should'nt be able to call patrimony synthesis service
      if(!isPatrimonyStepCompleted) {        
        setLoading(false);
        return;
      }
      
      const synthesis = await getSynthesisRequest(customerid);

      if (synthesis) {
        dispatch(setSynthesis(synthesis));
      }

      setLoading(false);
    }, {key: "patrimonyResolver_getPatrimonyRequest", value:`Resolve patrimony data`});
  }

  async function cleanup() {
    await dispatch(resetPatrimony());
  }

  useEffect(() => {
    setup();
    return () => {
      cleanup();
    };
  }, []);

  return { loading };
}
