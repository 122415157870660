import styles from "./TechnicalErrorPage.module.scss";
import { i18n } from "../../i18n";
import classNames from "classnames";
import { Button, ButtonStyles } from "../../";
import { useNavigate } from "react-router-dom";
import { assets as SharedAssets } from "../../../shared/Assets";
import { useEffect } from "react";
import { useAnalytics } from "../../../shared/commands/useAnalytics";

export function TechnicalErrorPage(props: TechnicalErrorPageProps) {
  const navigate = useNavigate();
  const { eventErrorPage } = useAnalytics();
  let asset = {
    error: SharedAssets.TechnicalErrorBackground,
  };
  const inLineStyles = {
    background: `url(${asset["error"]}) no-repeat center center`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    eventErrorPage();
  }, []);

  function quit() {
    navigate(-1);
  }

  return (
    <>
      <section style={inLineStyles} className={styles.Container}>
        <div className={styles.ErrorContainer}>
          <h1 className={styles.Title}>{i18n.internalErrorModal.title} </h1>
          <p className={styles.Description}>
            {" "}
            {i18n.internalErrorModal.description}{" "}
          </p>
          <Button
            className={classNames(ButtonStyles.PrimaryAction, styles.Button)}
            onClick={quit}
          >
            {i18n.internalErrorModal.continue}
          </Button>
        </div>
      </section>
    </>
  );
}

type TechnicalErrorPageProps = {
  error: Error;
};
