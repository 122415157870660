import { globali18n } from '@ifs/libs/src/i18n';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { sharedRules } from '../../../../shared/sharedRules';

export function getFormConfig(register: UseFormRegister<any>, watch: UseFormWatch<any>) {
  const rules = sharedRules(register);

  const formConfig = {
    ...rules,
    acceptPolicies: {
      rules: { validate: { requiredTrue: (v: boolean) => v === true } },
      field: register('acceptPolicies'),
      errors: {
        requiredTrue: globali18n.errors.required
      }
    },
    isBankClient: {
      field: register('isBankClient', { required: true }),
      errors: { required: globali18n.errors.required }
    }
  };

  return formConfig;
}
