import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "../../shared/components/Theme/ThemeProvider";
import { ProgressRoute } from "../../shared/routing/ProgressRoute";
import { ModuleColor } from "../../styles/colors";
import { i18n } from "./i18n";
import { useResolver } from "./routing/resolver";
import { LandingPage, ProjectsPage } from "./pages";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { PageRoute } from "../../shared/routing/PageRoute";


export function LifeProjectsModule() {
  const { loading } = useResolver();

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 1,
  };

  const declarationProps = {
    ...moduleProps,
    step: 1,
    subtitle: i18n.categories.declaration,
  };

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.LifeProjects,
        backgroundColorLigther: ModuleColor.LifeProjectsLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <AnalyticsComponent
              page_title={i18n.landingPage.pageTitle}
              page_path={"/life-projects"}
              page_type={"homepage"}
              simulator_screen={"Landing Page"}
              simulator_step={""}
              simulator_type={i18n.module}
            >
             <PageRoute
                pageTitle={i18n.landingPage.pageTitle}
                element={<LandingPage />}
              />
            </AnalyticsComponent>
          }
        />
        <Route
          path={`/projects`}
          element={
            <ProgressRoute
              {...declarationProps}
              step={1}
              showAmount={true}
              pageTitle={i18n.projectsPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.projectsPage.pageTitle}
                  page_path={"/life-projects/projects"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.projectsPage.pageTitle}
                  simulator_step={"1/1"}
                  simulator_type={i18n.module}
                >
                  <ProjectsPage />
                </AnalyticsComponent>}
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
