import { CheckedIcon, DownloadIcon, SharedMailbox,  UncheckedIcon } from "./images";
import TechnicalErrorBackground from "./images/TechnicalErrorBackground.svg"
import { FooterLogo } from "./images/footerLogo";

export const assets = {
  FooterLogo: FooterLogo,
  DownloadIcon: DownloadIcon,
  Mailbox: SharedMailbox,
  CheckedIcon: CheckedIcon,
  UncheckedIcon: UncheckedIcon,
  TechnicalErrorBackground: TechnicalErrorBackground,
};
