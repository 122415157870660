import { ReactComponent as realEstateAssets } from './realEstateAssets.svg';
import { ReactComponent as pleasurePurchases } from './pleasurePurchases.svg';
import { ReactComponent as family } from './family.svg';
import { ReactComponent as anticipation } from './anticipation.svg';
import { ReactComponent as philanthropy } from './philanthropy.svg';
import { ReactComponent as other } from './other.svg';

export const ProjectIcons = {
  realEstateAssets,
  pleasurePurchases,
  family,
  anticipation,
  philanthropy,
  other
};
