import { globali18n } from '@ifs/libs/src/i18n';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';

export function getFormConfig(register: UseFormRegister<any>, watch: UseFormWatch<any>) {
  const formConfig = {
    acceptPolicies: {
      rules: { validate: { requiredTrue: (v: boolean) => v === true } },
      field: register('acceptPolicies'),
      errors: {
        requiredTrue: globali18n.errors.required
      }
    },
  };

  return formConfig;
}
