import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SalaryPeriodicity } from "../../shared/models/SalaryPeriodicity";
import { ComputationInputModel } from "./models/ComputationInputModel";
import { ComputationOutputModel } from "./models/ComputationOutputModel";
import { Gender } from "./models/Gender";
import { SalaryModel } from "./models/salary/SalaryModel";
import { RootState } from "../../store";
import { ProfessionalStatus } from "./models/ProfessionalStatus";
import { SalaryEvolution } from "./models/salary/SalaryEvolution";
import { ValidatedLegalPensionModel } from "./models/validation/ValidatedLegalPensionModel";

export function selectBirthDate(state: RootState): Date | null {
  return state.legalPension.answers.birthDate
    ? new Date(state.legalPension.answers.birthDate)
    : null;
}

export interface LegalPensionState {
  answers: ComputationInputModel;
  lastValidateRequest: ValidatedLegalPensionModel | null;
  results: ComputationOutputModel | null;
}

const initialState: LegalPensionState = {
  answers: {
    numberOfChildren: 0,
    simulationKey: null,
    salary: {
      frequency: SalaryPeriodicity.Monthly,
    } as SalaryModel,
  }  as ComputationInputModel,
  lastValidateRequest: null,
  results: null,
};

export const legalPensionSlice = createSlice({
  name: "legalPension",
  initialState,
  reducers: {
    setGender: (state, action: PayloadAction<Gender>) => {
      state.answers.gender = action.payload;
    },
    setBirthdate: (state, action: PayloadAction<string>) => {
      state.answers.birthDate = action.payload;
    },
    setNumberOfChildren: (state, action: PayloadAction<number>) => {
      state.answers.numberOfChildren = action.payload;
    },
    setStartDateOfActivity: (state, action: PayloadAction<string>) => {
      state.answers.startDateOfActivity = action.payload;
    },
    setProfessionalStatus: (
      state,
      action: PayloadAction<ProfessionalStatus>
    ) => {
      state.answers.professionalStatus = action.payload;
    },
    setSalary: (
      state,
      action: PayloadAction<{ amount: number; frequency: SalaryPeriodicity }>
    ) => {
      state.answers.salary.amount = action.payload.amount;
      state.answers.salary.frequency = action.payload.frequency;
    },
    setSimulationKey: (state, action: PayloadAction<string>) => {
      state.answers.simulationKey = action.payload;
    },
    setEvolutionBeforeSimulation: (
      state,
      action: PayloadAction<SalaryEvolution>
    ) => {
      state.answers.salary.evolutionBeforeSimulation = action.payload;
    },
    setEvolutionAfterSimulation: (
      state,
      action: PayloadAction<SalaryEvolution>
    ) => {
      state.answers.salary.evolutionAfterSimulation = action.payload;
    },

    setAnswers: (state, action: PayloadAction<ComputationInputModel>) => {
      state.answers = action.payload;
    },
    setResults: (state, action: PayloadAction<ComputationOutputModel>) => {
      state.results = action.payload;
    },

    setLastValidatedResult: (
      state,
      action: PayloadAction<ValidatedLegalPensionModel>
    ) => {
      state.lastValidateRequest! = {
        ...action.payload,
      };
    },

    resetLegalPension: () => {
      return { ...initialState };
    },
  },
});

export const {
  setGender,
  setBirthdate,
  setNumberOfChildren,
  setStartDateOfActivity,
  setProfessionalStatus,
  setSalary,
  setEvolutionBeforeSimulation,
  setEvolutionAfterSimulation,
  setSimulationKey,
  setAnswers,
  setResults,
  setLastValidatedResult,
  resetLegalPension,
} = legalPensionSlice.actions;
export const legalPensionReducer = legalPensionSlice.reducer;
