import { LossOfValue } from '../../../../../modules/investor-profile/models/RiskProfileEnum';
import { createEnumPage } from '../../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../../commands/answerCommands';
import { BackgroundImage } from '../../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../../i18n';

export const InvestmentLossOfValue = createEnumPage<LossOfValue>({
  selector: (s) => s.investorProfile.riskProfile.lossOfValue,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerLossOfValue,
  values: [
    {
      label: i18n.riskProfile.lossOfValuePage.answer1,
      value: LossOfValue.NoLoss
    },
    {
      label: i18n.riskProfile.lossOfValuePage.answer2,
      value: LossOfValue.LessThanTenPercent
    },
    {
      label: i18n.riskProfile.lossOfValuePage.answer3,
      value: LossOfValue.BetweenTenAndTwentyPercent
    },
    {
      label: i18n.riskProfile.lossOfValuePage.answer4,
      value: LossOfValue.BetweenTwentyAndThirtyPercent
    },
    {
      label: i18n.riskProfile.lossOfValuePage.answer5,
      value: LossOfValue.MoreThanThirtyPercent
    }
  ],
  question: i18n.riskProfile.lossOfValuePage.question,
  backgroundImage: BackgroundImage
});