import { Route, Routes, useParams } from "react-router-dom";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { PageRoute } from "../../shared/routing/PageRoute";
import { i18n } from "./i18n";
import { DashboardPage } from "./pages/DashboardPage/DashboardPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import { useResolver } from "./routing/resolver";

export function DashboardModule() {
  const { customerid } = useParams();
  const { loading } = useResolver(customerid);

  if (loading) {
    return <></>;
  }

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <PageRoute
            pageTitle={i18n.dashboardPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={i18n.dashboardPage.pageTitle}
                page_path={"/"}
                page_type={"dashboard"}
                simulator_screen={"dashboard"}
                simulator_step={""}
                simulator_type={""}
              >
                <DashboardPage />
              </AnalyticsComponent>
            }
          />
        }
      />

      <Route
        path={`/landing`}
        element={
          <PageRoute
            pageTitle={i18n.landingPage.title}
            element={
              <AnalyticsComponent
                page_title={i18n.landingPage.title}
                page_path={"/landing"}
                page_type={"dashboard"}
                simulator_screen={"dashboard"}
                simulator_step={""}
                simulator_type={""}
              >
                <LandingPage />
              </AnalyticsComponent>
            }
          />
        }
      />
    </Routes>
  );
}
