import { ModalOutcome, useModal } from "../../../shared";
import {
  ProductSheetModal,
  ProductTypeInfo,
} from "../components/ProductSheetModal/ProductSheetModal";
import { RecommendationProductInformation } from "../components/ProductsTable/ProductsTable";
import { ProductTypeIdentifier } from "../models/product-type-identifier.enum";
import { FileTypeEnum, ProductInterface } from "../requests/getProducts";

export function useProductHelperService() {
  const { showModal } = useModal();

  async function openProductSheetModal(
    product: ProductInterface,
    isProductTypeExclude?: RecommendationProductInformation,
    hideCommercialContent?: boolean // workaround, we be simplify after MEP v4.2
  ) {
    const productTypeIdentifier = product.productTypeIdentifier as ProductTypeIdentifier;

    const data: ProductTypeInfo = {
      isProductTypeExclude:
        !!isProductTypeExclude && isProductTypeExclude?.fullExclude.isExclude,
      name: product.name,
      files: product.urls.map((e) => ({ url: e.path, type: e.type })),
      link: null,
    };
    const result = await showModal(
      <ProductSheetModal
        productTypeIdentifier={productTypeIdentifier}
        productTypeInfo={data}
        hideCommercialContent={!!hideCommercialContent}
      />
    );

    if (result.outcome === ModalOutcome.Cancel) {
      // The user cancelled the modal, stay on the current page
      document.getElementById(productTypeIdentifier + "-modal")!.focus();
      return;
    }
  }

  return { openProductSheetModal };
}
