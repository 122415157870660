import { i18n } from '../../../../modules/patrimony/i18n';
import { ScenarioProjectionType, SimulationPatrimonyModel, SynthesisModel } from '../../../../modules/patrimony/models/SynthesisModel';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ModalOutcome, useModal, StyledKendoModal } from '../../../../shared';
import { amount } from '../../../../shared/utilities/intl';
import styles from './PatrimonyEvolutionModal.module.scss';

export function PatrimonyEvolutionModal(props: PatrimonyEvolutionModalProps) {
  const { hideModal } = useModal();
  let t = i18n.summaryPage.evolutionInfo;
  const pessimisticProjection = getSimulationProjection(ScenarioProjectionType.Pessimistic);
  const neutralProjection = getSimulationProjection(ScenarioProjectionType.Neutral);
  const optimisticProjection = getSimulationProjection(ScenarioProjectionType.Optimistic);

  function getSimulationProjection(type: ScenarioProjectionType): SimulationPatrimonyModel | null {
    return props.data.simulations.find((sim) => sim.scenario === type) || null;
  }

  return (
      <StyledKendoModal
        className={styles.Dialog} title={t.title} onClose={() => hideModal(ModalOutcome.Cancel, null)} width={"500px"}>
        <div className={styles.Content} id={"modal-content"}>
          <div>
            {t.content.title}
          </div>
          <hr />
          <table width={'100%'}>
            <tr>
              <th></th>
              <th>{t.content.table.headers.worst}</th>
              <th>{t.content.table.headers.neutral}</th>
              <th>{t.content.table.headers.good}</th>
            </tr>

            <tr>
              <td>{t.content.table.labels.financial}</td>
              <td>{amount(pessimisticProjection?.capitalAmountAtRetirement.savingsAccountValue || 0)}</td>
              <td>{amount(neutralProjection?.capitalAmountAtRetirement.savingsAccountValue || 0)}</td>
              <td>{amount(optimisticProjection?.capitalAmountAtRetirement.savingsAccountValue || 0)}</td>
            </tr>

            <tr>
              <td>{t.content.table.labels.realEstate}</td>
              <td>{amount(pessimisticProjection?.capitalAmountAtRetirement.realEstateValue || 0)}</td>
              <td>{amount(neutralProjection?.capitalAmountAtRetirement.realEstateValue || 0)}</td>
              <td>{amount(optimisticProjection?.capitalAmountAtRetirement.realEstateValue || 0)}</td>
            </tr>

            <br />
            <tr style={{ fontWeight: 'bold' }}>
              <td>{t.content.table.labels.total}</td>
              <td>{amount(pessimisticProjection?.capitalAmountAtRetirement.totalPatrimonyValue || 0)}</td>
              <td>{amount(neutralProjection?.capitalAmountAtRetirement.totalPatrimonyValue || 0)}</td>
              <td>{amount(optimisticProjection?.capitalAmountAtRetirement.totalPatrimonyValue || 0)}</td>
            </tr>
          </table>

          <br />
          <br />
          <PanelBar className={styles.PatrimonyPanelBarModal}>
            <PanelBarItem title={t.content.panelBar.title}>
              {t.content.panelBar.content}
            </PanelBarItem>
          </PanelBar>
        </div>
      </StyledKendoModal>
  );
}

// const StyledPanelBar = styled(PanelBar)`
// .k-link {
//   display: flex;
//   justify-content: space-between;
// }
// .k-animation-container{
//   border: unset;      
//   margin-top: 15px;
// }
// .k-panelbar-group{
//   border: unset;    
//   padding-left: unset; 
// }
// `;

type PatrimonyEvolutionModalProps = {
  data: SynthesisModel
}
