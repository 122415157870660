import classNames from "classnames";
import { useAppSelector } from "../../../store";
import { SvgWrapperComponent } from "../SvgWrapper/SvgWrapperComponent";
import { ReactComponent as Animation } from "./images/loader.svg";
import styles from "./Loader.module.scss";

export function Loader() {
  const isActive = useAppSelector((s) => s.loader.isActive);
  return (
      <div
        role={"img"}
        aria-busy="true" aria-label="Chargement" title="Chargement"
        className={classNames(styles.Overlay, { [styles.Active]: isActive })}
      >

          <Animation className={styles.Loader}/>
      </div>
  );
}
