import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { OtherType } from '../../../models/other/other-type.enum';
import { setOtherTravelsDefinedValue, setOtherTravelsScaleValue } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const OtherTravelsPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) => q.other.find((c) => c.typeId === OtherType.Travels)?.scale ?? null,
  definedValueSelector: (q) =>
    q.other.find((c) => c.typeId === OtherType.Travels)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.otherTravelsPage,
  scaleValueReducer: setOtherTravelsScaleValue,
  definedValueReducer: setOtherTravelsDefinedValue,
  answers: [
    { icon: moneyIcon.Money1, label: i18n.otherTravelsPage.answer1, value: DefaultScale.NotAtAll },
    { icon: moneyIcon.Money2, label: i18n.otherTravelsPage.answer2, value: DefaultScale.ALittle },
    { icon: moneyIcon.Money3, label: i18n.otherTravelsPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: () => ({ route: '../others/sport' })
});
