import styles from './Button.module.scss';

export class ButtonStyles {
  // Generic

  static PrimaryAction = styles.PrimaryAction;
  static SecondaryAction = styles.SecondaryAction;
  static Back = styles.Back;
  static Small = styles.Small;
  static Round = styles.Round;
  static Answer = styles.Answer;
  static Link = styles.Link;
  static Unfill = styles.Unfill;

  // Module specific

  static PensionNeedCategory = styles.PensionNeedCategory;
  static LegalPensionAge = styles.LegalPensionAge;
  static PatrimonyAdd = styles.PatrimonyAdd;
  static PersonaCard = styles.PersonaCard;
  static PersonaCustom = styles.PersonaCustom;
}
