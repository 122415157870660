import axios, { AxiosError } from 'axios';
import { RegisterModel, RegisterOutcome } from './registerRequest';

export async function upgradeProspectRequest(
  model: RegisterModel
): Promise<RegisterOutcome> {
  try {
    await axios.post(`/api/auth/upgrade_prospect`, model);

    return RegisterOutcome.Success;
  } catch (err) {
    const error = err as AxiosError;
    const body = error.response?.data as any;

    if (
        error.response?.status === 400 &&
        body.error === 'LoginAlreadyExists'
    ) {
      return RegisterOutcome.LoginAlreadyExists;
    }

    throw err;
  }
}
