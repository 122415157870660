import { useNavigate, useParams } from "react-router-dom";
import {
  resetAuthentication,
  setAuthentication,
} from "../../modules/authentication/authenticationSlice";
import { generateProspectRequest } from "../../modules/authentication/requests/generateProspectRequest";
import {
  resetTaxation,
  resetLegalPension,
  resetPensionNeed,
  resetDashboard,
  resetLifeProjects,
  resetPatrimony,
  resetRecommendation,
  resetProgress,
  resetLoader,
  useLoader,
  Module
} from "@ifs/libs";
import { useGlobalCommand  as globalCommandLibs} from '@ifs/libs';

import { useAppDispatch } from "../../store";
import {
  getModuleCompletionRequest,
  ModuleCompletionModel,
} from "../requests/getModuleCompletionRequest";
import { getUserReferenceRequest } from "@ifs/libs/src/shared/requests/getUserReferenceRequest";
import { internalUrls } from "@ifs/libs/src/shared/utilities/urls";
import { checkBFFUserConnectedRequest } from "../requests/checkBFFUserConnectedRequest";

export function useGlobalCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { isAuthenticationModeSSO } = globalCommandLibs();
  const { customerid } = useParams();
  const navigate = useNavigate();

  async function resetAllCommand() {
    dispatch(resetLoader());
    dispatch(resetProgress());

    dispatch(resetAuthentication());

    dispatch(resetPensionNeed());
    dispatch(resetLegalPension());
    dispatch(resetTaxation());
    dispatch(resetPatrimony());
    dispatch(resetLifeProjects());

    dispatch(resetDashboard());
    dispatch(resetRecommendation());
  }

  async function checkAuthenticationCommand() {
    await showLoaderWhile(async () => {
      // For SSO Tenant, we lost the notion of user
      // So userReference service could not be use to check if user is connected
      // Instead we will use the /bff/user service
      const userReference = isAuthenticationModeSSO() ? await checkBFFUserConnectedRequest(customerid) : await getUserReferenceRequest();

      if (userReference === null) {
        dispatch(
          setAuthentication({
            isAuthenticated: false,
            customerId: null,
            userType: null,
          })
        );
        return;
      }
      dispatch(
        setAuthentication({
          isAuthenticated: true,
          customerId: userReference.identifier,
          userType: userReference.userType,
          bffLogoutUrl: userReference.bffUserLogout
        })
      );
    }, {key: "checkAuthenticationCommand", value:`API GET - /api/auth/user_reference}`});
  }

  async function generateProspectCommand() {
    await showLoaderWhile(async () => {
      await resetAllCommand();
      await generateProspectRequest();
      await checkAuthenticationCommand();
    }, {key: "generateProspectCommand", value:`Handle generateProspectCommand}`});
  }

  function isCompleted(
    completed: ModuleCompletionModel[],
    module: Module
  ): boolean {
    return completed.some((cm) => cm.module === module && cm.isCompleted);
  }

  async function redirectAfterValidateCommand(source: Module) {
    // If the user comes from the needs module...
    if (source === Module.PensionNeed) {
      const completed = await getModuleCompletionRequest(
        Module.LegalPension,
        Module.Taxation
      );

      // ... and hasn't completed the legal module, go to legal
      if (!isCompleted(completed, Module.LegalPension)) {
        navigate(internalUrls.legalPension.landing);
        return;
      }

      // ... and hasn't completed the taxation module, go to taxation
      if (!isCompleted(completed, Module.Taxation)) {
        navigate(internalUrls.taxation.landing);
        return;
      }
    }

    // If the user comes from the legal module...
    if (source === Module.LegalPension) {
      const completed = await getModuleCompletionRequest(
        Module.PensionNeed,
        Module.Taxation
      );

      // ... and hasn't completed the needs module, go to needs
      if (!isCompleted(completed, Module.PensionNeed)) {
        navigate(internalUrls.pensionNeed.landing);
        return;
      }

      // ... and hasn't completed the taxation module, go to taxation
      if (!isCompleted(completed, Module.Taxation)) {
        navigate(internalUrls.taxation.landing);
        return;
      }
    }

    // If the user comes from the patrimony module...
    if (source === Module.Patrimony) {
      const completed = await getModuleCompletionRequest(Module.LifeProjects);

      // ... and hasn't completed the projects module, go to projects
      if (!isCompleted(completed, Module.LifeProjects)) {
        navigate(internalUrls.lifeProjects.landing);
        return;
      }
    }

    // If the user comes from the taxation module...
    if (source === Module.Taxation) {
      const completed = await getModuleCompletionRequest(
        Module.PensionNeed,
        Module.LegalPension
      );

      // ... and hasn't completed the needs module, go to needs
      if (!isCompleted(completed, Module.PensionNeed)) {
        navigate(internalUrls.pensionNeed.landing);
        return;
      }

      // ... and hasn't completed the legal module, go to legal
      if (!isCompleted(completed, Module.LegalPension)) {
        navigate(internalUrls.legalPension.landing);
        return;
      }
    }

    // Default to the dashboard otherwise
    navigate(internalUrls.dashboard);
  }

  return {
    resetAllCommand,
    checkAuthenticationCommand,
    generateProspectCommand,
    redirectAfterValidateCommand,
    isCompleted,
  };
}
