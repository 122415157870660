import classNames, { Argument } from "classnames";
import { i18n } from "../../../../../i18n";
import styles from "./SummaryCard.module.scss";
import { assets as LandingAssets } from "./Assets";
import {
  Button,
  ButtonStyles,
  ModalOutcome,
  SvgWrapperComponent,
  useLoader,
  useModal,
} from "../../../../../../../shared";
import { Scenario } from "../../../../../../../modules/investor-profile/models/RiskProfileEnum";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { useAppUrls } from "../../../../../../../shared/utilities/urls";
import {
  resetInvestorProfile,
  setFinancialProfileIdentifier,
  setRiskProfileIdentifier,
} from "../../../../../../../modules/investor-profile/investorProfileSlice";
import { OtherStatusModal } from "../../../../../../recommendation/components/MainCard/component/OtherStatusModal/OtherStatusModal";
import { amount } from "../../../../../../../shared/utilities/intl";
import { globali18n } from "../../../../../../../i18n";
import { InfoIcon } from "../../../../../../../shared/images";
import { getParametersRequest } from "../../../../../../../modules/recommendation/requests/getParametersRequest";
import { useComputeRecommendationCommand } from "../../../../../../../modules/recommendation/commands/computeRecommendationCommand";
import { getSearchFinancialProfile } from "../../../../../../../modules/investor-profile/requests/getSearchFinancialProfile";
import { getSearchRiskProfile } from "../../../../../../../modules/investor-profile/requests/getSearchRiskProfile";
import {
  setModel,
  setParameters,
} from "../../../../../../../modules/recommendation/recommendationSlice";
import { getRiskProfileScale } from "../../../../../../../modules/investor-profile/requests/getRiskProfileScale";
import { useAnalytics } from "../../../../../../../shared/commands/useAnalytics";

export interface LandingCardProps {
  title: string;
  content: JSX.Element;
  onStart: () => void;
  gridClassName: Argument;
  info?: string;
  scenario: Scenario;
}

const t = i18n.financialProfileSummaryPage;

export function SummaryCard(props: LandingCardProps) {
  const [riskProfileScenarioIdentifier, setRiskProfileScenarioIdentifier] =
    useState<Scenario | string>(Scenario.Conservateur);
  const [scale, setScale] = useState<number>(0);

  const inLineStyles = {
    background: `url(${LandingAssets.BackgroundInvestorProfileLandingImage}) no-repeat center center`,
    backgroundSize: "cover",
  };
  const { financialSummaryEvent } = useAnalytics();
  const { computeRecommendationCommand } = useComputeRecommendationCommand();
  const { customerid } = useParams();
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const model = useAppSelector((s) => s.recommendation.model)!;
  const [loading, setLoading] = useState<boolean>(true);

  async function getSession() {
    await showLoaderWhile(async () => {
      const financialProfile = await getSearchFinancialProfile(customerid);
      const riskProfile = await getSearchRiskProfile(customerid);

      //Backend will send an array of multiple risk profile & financial profile, we use only the first one
      const riskProfileResult = riskProfile.results[0];
      const financialProfileResult = financialProfile.results[0];

      const profileScale = await getRiskProfileScale(
        customerid,
        riskProfileResult.profileScaleVersion
      );
      dispatch(
        setFinancialProfileIdentifier(
          financialProfileResult.questionnaireSessionId
        )
      );
      dispatch(
        setRiskProfileIdentifier(riskProfileResult.questionnaireSessionId)
      );
      setRiskProfileScenarioIdentifier(
        riskProfileResult.preferredProfileId || ""
      );

      const scaleFound = profileScale.scale.find(
        (e: any) => e.textId === (riskProfileResult.preferredProfileId || "")
      )?.srri;
      if (scaleFound && scaleFound >= 0) {
        setScale(scaleFound);
      }
      const initialParameters = await getParametersRequest(customerid);
      const computeParams = {
        initialInvestmentAmount: null,
        recurringInvestmentAmount: null,
        retirementScenario: null,
        overrideGamificationParameters: initialParameters?.initialInvestmentAmount || initialParameters?.recurringInvestmentAmount ? true : null,
        ...initialParameters
      };
      const computeOutput = await computeRecommendationCommand(computeParams);
      dispatch(setParameters(computeParams));
      dispatch(setModel(computeOutput));
      setLoading(false);
      runEvent(
        "risk_profile",
        scale,
        riskProfileResult.preferredProfileId,
        computeOutput.additionalRetirementIncome.neutral.monthlyAmount
      );
      return;
    });
  }

  function runEvent(
    eventName: string,
    scale: number,
    scenarioIdentifier: string | null,
    amount: number
  ) {
    financialSummaryEvent(
      `${scale.toString()}/7`,
      scenarioIdentifier ? t.scenario[scenarioIdentifier as Scenario] : "",
      amount,
      eventName
    );
  }

  useEffect(() => {
    getSession();
  }, []);

  function restart() {
    dispatch(resetInvestorProfile());
    navigate(appUrls.investorProfile.riskProfile.horizon);
  }

  async function displayModal() {
    const result = await showModal(
      <OtherStatusModal model={model.additionalRetirementIncome} />
    );

    if (result.outcome === ModalOutcome.Cancel) {
      // The user cancelled the modal, stay on the current page
      return;
    }
  }

  return (
    <>
      <div
        className={classNames(styles.Grid, props.gridClassName)}
        style={inLineStyles}
      >
        <section className={styles.Card}>
          <div className={styles.LeftBloc}>
            <h1 className={styles.CardTitle}>{props.title}</h1>
            <div>
              <div>
                <h3>{t.subTitle}</h3>
                <h2 className={styles.Scenario}>
                  {t.scenario[riskProfileScenarioIdentifier as Scenario]}
                </h2>
                <div className={styles.RiskLevel}>
                  <SvgWrapperComponent ariaHidden={true}>
                    <LandingAssets.GraduationIcon />
                  </SvgWrapperComponent>
                  <div className={styles.RiskLeveltext}>
                    <span>{t.riskLevel}</span>
                    <span className={styles.RiskLevelRating}>
                      {t.riskLevelRating(scale.toString(), "7")}
                    </span>
                  </div>
                </div>
              </div>

              {t.description}
              <div>
                <Button
                  onClick={restart}
                  className={classNames(ButtonStyles.Link, styles.Restart)}
                >
                  {t.restart}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.Divider}></div>
          <div className={styles.RightBloc}>
            <div>{t.rightSection.discover}</div>
            <div className={styles.MonthlyAmountContainer}>
              <div className={styles.AmountMonthly}>
                {(!loading &&
                  amount(
                    model.additionalRetirementIncome.neutral.monthlyAmount
                  )) ||
                  0}
                {t.rightSection.month}
              </div>
              <button onClick={displayModal} className={styles.InfoIcon}>
                <SvgWrapperComponent
                  ariaLabel={globali18n.ariaLabel.furtherInformation}
                >
                  <InfoIcon className={styles.InfoSvg} />
                </SvgWrapperComponent>
              </button>
            </div>

            <Button
              onClick={() => {
                props.onStart();
                runEvent(
                  "saving_solution_access",
                  scale,
                  riskProfileScenarioIdentifier,
                  model.additionalRetirementIncome.neutral.monthlyAmount
                );
              }}
              className={classNames(ButtonStyles.PrimaryAction, styles.Button)}
            >
              {t.rightSection.seeRecommendation}
            </Button>
          </div>
        </section>
      </div>
    </>
  );
}
