import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  ButtonStyles,
  CheckboxGroup,
  Container,
  PageTitle,
  ValidationMessages
} from '@ifs/libs';
import { useExternalUrls } from '@ifs/libs/src/shared/utilities/externalUrls';
import { hasErrors } from 'shared/utilities/hasErrors';
import { i18n } from '../../i18n';
import styles from './AccountActivationForm.module.scss';
import classNames from 'classnames';
import { BackgroundImage } from '../BackgroundImage/BackgroundImage';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { getFormConfig } from './formConfig';
import { PasswordConfirmInputGroup } from 'shared/components';
import { ValidateInitializedAccountParams, ValidateInitializedAccountPayload } from 'modules/registration/requests/validateInitializedAccount.request';
import { useValidateInitializedAccount } from 'modules/registration/commands/validateInitializedAccount.command';
import { StyledKendoCheckbox } from '@ifs/libs/src/shared';
import { useEffect, useState } from 'react';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';

interface FormData {
  acceptPolicies: boolean;
  acceptCommercialInformation: boolean;
  password: string;
  passwordConfirm: string;
}

const defaultValues = {};

export function AccountActivationForm(props: ActivationAccountFormProps) {
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    formState,
    control
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: defaultValues,
    mode: 'all'
  });
  const {validateInitializedAccountCommand} = useValidateInitializedAccount();
  const { externalUrls } = useExternalUrls();

  const [termsOfUseLink, setTermsOfUseLink]= useState<string>("")

  async function getLink (){
    const urls = await externalUrls();
    setTermsOfUseLink(urls.TermsOfUsePage)
  }
  
  useEffect(() => {
    getLink()
  }, []);

  async function onSubmit(data: FormData) {
    const params: ValidateInitializedAccountParams = {
      email: props.email,
      sessionId: props.sessionId
    };
    const payload: ValidateInitializedAccountPayload = {
      canReceiveCommercialContent: !!data.acceptCommercialInformation,
      password: data.password
    };
    validateInitializedAccountCommand(params, payload);
  }

  const formConfig = getFormConfig(register, watch);

  const errors = formState.errors;
  const isSubmitted = formState.isSubmitted;

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.registrationPage.labels.title}</PageTitle>
      <h2 className={ModuleStyles.Subtitle}>{i18n.accountActivationPage.accountActivationForm.subtitle}</h2>

      <form
        className={ModuleStyles.FormGridResponsive}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >      

        <PasswordConfirmInputGroup
          passwordName="password"
          passwordConfirmName="passwordConfirm"
          isSubmitted={isSubmitted}
          errors={errors}
          register={register}
          trigger={trigger}
          watch={watch}
        />

        <br className={ModuleStyles.Divider} />

        <CheckboxGroup isInvalid={hasErrors('acceptPolicies', errors)}>
          <Controller
            control={control}
            name="acceptPolicies"
            rules={formConfig.acceptPolicies.rules}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledKendoCheckbox
                id="acceptPolicies"
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
              />
            )}
          />

          <div>
            <label htmlFor={'acceptPolicies'}>
              {i18n.registrationPage.labels.acceptPolicies[0]}
              <a className={styles.Link} href={termsOfUseLink} >
                {i18n.registrationPage.labels.acceptPolicies[1]}
              </a>
              {i18n.registrationPage.labels.acceptPolicies[2]}
            </label>
            <ValidationMessages
              messages={getValidationMessages(
                'acceptPolicies',
                errors,
                formConfig.acceptPolicies.errors
              )}
            />
          </div>
          
        </CheckboxGroup>
        <CheckboxGroup>
          <Controller
            control={control}
            name="acceptCommercialInformation"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledKendoCheckbox
                id="acceptCommercialInformation"
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
              />
            )}
          />

          <div>
            <label htmlFor={'acceptCommercialInformation'}>
              {i18n.registrationPage.labels.acceptReceiveCommercialInformation}
            </label>
          </div>
        </CheckboxGroup>

        <p className={styles.Contact}>
          {i18n.registrationPage.labels.disclaimerFinalizeAccountCreation}
        </p>

        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
        >
          {i18n.registrationPage.labels.createAccount}
        </Button>      
      </form>
      <BackgroundImage />
    </Container>
  );
}


type ActivationAccountFormProps = {
  email: string,
  sessionId: string,
}