import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import {
  RecommendationOutputModel,
  ParametersInputModel
} from '../models/RecommendationModel';

export async function computeRecommendationRequest(
  model?: ParametersInputModel,
  customerid?: string
): Promise<RecommendationOutputModel> {
  const apiUrl = apiUrls(customerid).recommendation.compute
  const response = await axios.post<RecommendationOutputModel>(
    apiUrl,
    model ?? {}
  );

  return response.data;
}
