import axios from 'axios';
import { apiUrls } from '../../shared/apiUrls';
import { Module } from '../models/Module';

export interface ModuleCompletionModel {
  module: Module;
  isCompleted: boolean;
}

export async function getModuleCompletionRequest(
  customerid?: string,
  ...modules: Module[]
): Promise<ModuleCompletionModel[]> {
  // Build the query string manually here instead of delegating it to axios
  // We need module=PensionNeed&module=LegalPension
  // While axios would generate module[]=PensionNeed&module[]=LegalPension
  var params = new URLSearchParams();
  for (const module of modules) {
    params.append('modules', module);
  }

  const apiUrl = apiUrls(customerid).completion.get;

  const response = await axios.get<ModuleCompletionModel[]>(apiUrl, {
    params
  });

  const data = response.data;

  return data;
}
