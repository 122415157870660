import classNames, { Argument } from 'classnames';
import styles from './Card.module.scss';

interface CardProps {
  className?: Argument;
  title?: string;
  selected?: boolean;
  onClick?: () => void;
  onKeyDown?: any;
}

export function Card(props: React.PropsWithChildren<CardProps>) {
  const classes = classNames(styles.Card, { [styles.Selected]: props.selected }, props.className);

  return (
    <section className={classes} onClick={props.onClick} onKeyDown={props.onKeyDown}>
      {props.children}
    </section>
  );
}

export function CardCorner() {
  return (
    <svg
      className={styles.Corner}
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="62" cy="62" r="62" fill="#F4AD23" />
    </svg>
  );
}

interface CardTitleProps {
  className?: Argument;
}

export function CardTitle(props: React.PropsWithChildren<CardTitleProps>) {
  return <h1 className={classNames(styles.CardTitle, props.className)}>{props.children}</h1>;
}
