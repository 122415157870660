export function enterKey(
  event: any,
  action: (arg?: any) => void | any | undefined
): Function {
  if (
    event.code === "Enter" ||
    event.code === "13" ||
    event.code === "NumpadEnter"
  ) {
    return action();
  }

  return () => null;
}