import { ThemeProvider } from "../../shared";
import { ProgressRoute } from "../../shared/routing/ProgressRoute";
import { ModuleColor } from "../../styles/colors";

import { i18n } from "./i18n";
import * as pages from "./pages";
import { useResolver } from "./routing/resolver";
import { Route, Routes } from "react-router-dom";
import { InvestmentHorizon } from "./pages/risk-profile/InvestmentHorizon/InvestmentHorizon";
import { InvestmentFluctuation } from "./pages/risk-profile/InvestmentFluctuation/InvestmentFluctuation";
import { InvestmentLossOfValue } from "./pages/risk-profile/InvestmentLossOfValue/InvestmentLossOfValue";
import { InvestmentLossReaction } from "./pages/risk-profile/InvestmentLossReaction/InvestmentLossReaction";
import { InvestmentScenario } from "./pages/risk-profile/InvestmentScenario/InvestmentScenario";
import { Summary } from "./pages/risk-profile/summary/Summary";
import { MarketFluctuationPage } from "./pages/financial-profile/MarketFluctuation/MarketFluctuation";
import { ObligationPage } from "./pages/financial-profile/Obligation/Obligation";
import { OpcPage } from "./pages/financial-profile/Opc/Opc";
import { OpciOrScpiPage } from "./pages/financial-profile/OpciOrScpi/OpciOrScpi";
import { FinancialSupportPage } from "./pages/financial-profile/FinancialSupport/FinancialSupport";
import { FinancialInstrumentsOrMarketsPage } from "./pages/financial-profile/FinancialInstrumentsOrMarkets/FinancialInstrumentsOrMarkets";
import { PageRoute } from "../../shared/routing/PageRoute";
import { FinancialProfileSummary } from "./pages/financial-profile/FinancialProfileSummary/FinancialProfileSummary";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { InvestorProfileQuestionnaireGuardRoute } from "./InvestorProfileQuestionnaireGuard";

export function InvestorProfileModule() {
  const { loading } = useResolver();

  if (loading) {
    return null;
  }

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 11,
  };

  const { LandingPage } = pages;

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.InvestorProfile,
        backgroundColorLigther: ModuleColor.InvestorProfileLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <PageRoute
              pageTitle={i18n.landingPage.title}
              element={
                <AnalyticsComponent
                  page_title={i18n.landingPage.title}
                  page_path={"/investor-profile"}
                  page_type={"homepage"}
                  simulator_screen={"Landing Page"}
                  simulator_step={"0/11"}
                  simulator_type={i18n.module}
                >
                  <LandingPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        {/* Risk profile routes */}
        <Route
          path="/risk-profile/horizon"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={1}
              pageTitle={i18n.riskProfile.horizonPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.riskProfile.horizonPage.question}
                  page_path={"/investor-profile/risk-profile/horizon"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.riskProfile.horizonPage.question}
                  simulator_step={"1/11"}
                  simulator_type={i18n.module}
                >
                  <InvestmentHorizon />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/risk-profile/fluctuation"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={2}
              pageTitle={i18n.riskProfile.fluctuationPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.riskProfile.fluctuationPage.question}
                  page_path={"/investor-profile/risk-profile/fluctuation"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.riskProfile.fluctuationPage.question}
                  simulator_step={"2/11"}
                  simulator_type={i18n.module}
                >
                  <InvestmentFluctuation />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/risk-profile/loss-of-value"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={3}
              pageTitle={i18n.riskProfile.lossOfValuePage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.riskProfile.lossOfValuePage.question}
                  page_path={"/investor-profile/risk-profile/loss-of-value"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.riskProfile.lossOfValuePage.question}
                  simulator_step={"3/11"}
                  simulator_type={i18n.module}
                >
                  <InvestmentLossOfValue />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/risk-profile/loss-reaction"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={4}
              pageTitle={i18n.riskProfile.lossReactionPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.riskProfile.lossReactionPage.question}
                  page_path={"/investor-profile/risk-profile/loss-reaction"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.riskProfile.lossReactionPage.question}
                  simulator_step={"4/11"}
                  simulator_type={i18n.module}
                >
                  <InvestmentLossReaction />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />

        <Route
          path="/risk-profile/scenario"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={5}
              pageTitle={i18n.riskProfile.scenarioPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.riskProfile.scenarioPage.question}
                  page_path={"/investor-profile/risk-profile/scenario"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.riskProfile.scenarioPage.question}
                  simulator_step={"5/11"}
                  simulator_type={i18n.module}
                >
                  <InvestmentScenario />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />

        <Route
          path="/risk-profile/summary"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <AnalyticsComponent
              page_title={i18n.riskProfile.summaryPage.title}
              page_path={"/investor-profile/risk-profile/summary"}
              page_type={"simulateur"}
              simulator_screen={
                i18n.riskProfile.summaryPage.title
              }
              simulator_step={""}
              simulator_type={i18n.module}
            >
              <Summary />

            </AnalyticsComponent>
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />

        {/* Financial Profile */}
        <Route
          path="/financial-profile/market-fluctuation"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={6}
              pageTitle={i18n.financialProfile.marketFluctuationPage.question}
              element={
                <AnalyticsComponent
                  page_title={
                    i18n.financialProfile.marketFluctuationPage.question
                  }
                  page_path={
                    "/investor-profile/financial-profile/market-fluctuation"
                  }
                  page_type={"simulateur"}
                  simulator_screen={
                    i18n.financialProfile.marketFluctuationPage.question
                  }
                  simulator_step={"6/11"}
                  simulator_type={i18n.module}
                >
                  <MarketFluctuationPage />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/financial-profile/obligation"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={7}
              pageTitle={i18n.financialProfile.obligationPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.financialProfile.obligationPage.question}
                  page_path={"/investor-profile/financial-profile/obligation"}
                  page_type={"simulateur"}
                  simulator_screen={
                    i18n.financialProfile.obligationPage.question
                  }
                  simulator_step={"7/11"}
                  simulator_type={i18n.module}
                >
                  <ObligationPage />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/financial-profile/opc"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={8}
              pageTitle={i18n.financialProfile.opcPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.financialProfile.opcPage.question}
                  page_path={"/investor-profile/financial-profile/obligation"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.financialProfile.opcPage.question}
                  simulator_step={"8/11"}
                  simulator_type={i18n.module}
                >
                  <OpcPage />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/financial-profile/opci-or-scpi"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={9}
              pageTitle={i18n.financialProfile.opciOrScpiPage.question}
              element={
                <AnalyticsComponent
                  page_title={i18n.financialProfile.opciOrScpiPage.question}
                  page_path={"/investor-profile/financial-profile/opci-or-scpi"}
                  page_type={"simulateur"}
                  simulator_screen={
                    i18n.financialProfile.opciOrScpiPage.question
                  }
                  simulator_step={"9/11"}
                  simulator_type={i18n.module}
                >
                  <OpciOrScpiPage />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/financial-profile/financial-support"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
            <ProgressRoute
              {...moduleProps}
              step={10}
              pageTitle={i18n.financialProfile.financialSupportPage.question}
              element={
                <AnalyticsComponent
                  page_title={
                    i18n.financialProfile.financialSupportPage.question
                  }
                  page_path={
                    "/investor-profile/financial-profile/financial-support"
                  }
                  page_type={"simulateur"}
                  simulator_screen={
                    i18n.financialProfile.financialSupportPage.question
                  }
                  simulator_step={"10/11"}
                  simulator_type={i18n.module}
                >
                  <FinancialSupportPage />
                </AnalyticsComponent>
              }
            />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />
        <Route
          path="/financial-profile/financial-instruments-or-markets"
          element={
            <InvestorProfileQuestionnaireGuardRoute>
              <ProgressRoute
                {...moduleProps}
                step={11}
                pageTitle={
                  i18n.financialProfile.financialInstrumentsOrMarketsPage.question
                }
                element={
                  <AnalyticsComponent
                    page_title={
                      i18n.financialProfile.financialInstrumentsOrMarketsPage
                        .question
                    }
                    page_path={
                      "/investor-profile/financial-profile/financial-instruments-or-markets"
                    }
                    page_type={"simulateur"}
                    simulator_screen={
                      i18n.financialProfile.financialInstrumentsOrMarketsPage
                        .question
                    }
                    simulator_step={"11/11"}
                    simulator_type={i18n.module}
                  >
                    <FinancialInstrumentsOrMarketsPage />
                  </AnalyticsComponent>
                }
              />
            </InvestorProfileQuestionnaireGuardRoute>
          }
        />

        <Route
          path={`/financial-profile/summary`}
          element={
            <PageRoute
              pageTitle={i18n.financialProfileSummaryPage.title}
              element={
                <AnalyticsComponent
                  page_title={i18n.financialProfileSummaryPage.title}
                  page_path={"/investor-profile/financial-profile/summary"}
                  page_type={"simulateur"}
                  simulator_screen={i18n.financialProfileSummaryPage.title}
                  simulator_step={"11/11"}
                  simulator_type={i18n.module}
                >
                  <FinancialProfileSummary />
                </AnalyticsComponent>
              }
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
