import { ModalOutcome, StyledKendoModal, useModal } from '../../../../shared';
import { ReactComponent as TaxDeclaration } from "../../images/tax-declaration.svg";
import { ReactComponent as TauxMarginal } from "../../images/taux-marginal.svg";
import styles from "./TaxationModal.module.scss";  

interface TaxationModalProps {
  title: string;
  content: string;
  isDeclarativeTaxationInformation: boolean;
}

export function TaxationModal(props: TaxationModalProps) {
  const { hideModal } = useModal();

  return (
    <>
      <StyledKendoModal
        title={props.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'625px'}
      >
        <div id={"modal-content"} tabIndex={-1}>
          {props.content}
        </div>
        {props.isDeclarativeTaxationInformation && <TaxDeclaration className={styles.Icon}/>}
        {!props.isDeclarativeTaxationInformation && <TauxMarginal className={styles.Icon}/>}
      </StyledKendoModal>
    </>
  );
}
