import { i18n } from '../../../../modules/recommendation/i18n';
import { MainCard } from '../../../../modules/recommendation/components/MainCard/MainCard';
import { Button, ButtonStyles, Container, PageTitle } from '../../../../shared';
import { useAppSelector } from '../../../../store';
import { useGoToAppointmentCommand } from '../../../../modules/recommendation/commands/goToAppointmentCommand';
import { useViewReportCommand } from '../../../../modules/recommendation/commands/viewReportCommand';
  import styles from './RecommendationPage.module.scss';
import { useEffect } from 'react';
import { useAnalytics } from '../../../../shared/commands/useAnalytics';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { globali18n } from '../../../../i18n';

export function RecommendationPage() {
  const navigate = useNavigate();
  const model = useAppSelector((s) => s.recommendation.model)!;
  const parameters = useAppSelector((s) => s.recommendation.parameters)!;
  const { recommendationEvent, downloadReport, makeAppointment } = useAnalytics();
  const { goToAppointmentCommand } = useGoToAppointmentCommand();
  const { viewReportCommand } = useViewReportCommand();

  
  useEffect(() => {
    recommendationEvent(parameters, model, "saving_solution");
  },[]);

  function back() {
    navigate(-1);
  }

  async function goToAppointment() {
    makeAppointment()
    await goToAppointmentCommand();
  }

  async function goToReport() {
    downloadReport()
    await viewReportCommand();
  }

  const t = i18n.recommendationPage;

  return (
    <>
      <Container className={styles.Container}>
        <Button onClick={back} className={classNames(styles.Back, ButtonStyles.Back)}>
          &lsaquo; {globali18n.labels.back}
        </Button>
        <PageTitle>{t.summary.title}</PageTitle>
        <div className={styles.CardGrid}>
          <MainCard goToAppointment={goToAppointment} goToReport={goToReport} />
        </div>
      </Container>
    </>
  );
}
