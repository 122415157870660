import { globali18n } from '../../../i18n';
import { Control, Controller } from 'react-hook-form';
import { SalaryPeriodicity } from '../../models/SalaryPeriodicity';
import { ButtonGroup, ButtonGroupOption } from '../ButtonGroup/ButtonGroup';
import styles from './FrequencySwitch.module.scss';

export interface FrequencySwitchProps {
  control: Control<any>;
  name: string;
}

const options: ButtonGroupOption[] = [
  { label: globali18n.labels.monthly, value: SalaryPeriodicity.Monthly },
  { label: globali18n.labels.yearly, value: SalaryPeriodicity.Yearly },
];

export function FrequencySwitch(props: FrequencySwitchProps) {
  return (
    <div className={styles.FrequencyRow}>

      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <ButtonGroup
            className={styles.ButtonGroup}
            options={options}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
}
