import { useLoader } from '@ifs/libs';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { useNavigate } from 'react-router-dom';
import { resendInitializedAccountValidationEmailRequest } from '../requests/resendInitializedAccountValidationEmail.request';

export function useResendInitializedAccountValidationEmail() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();

  function resendInitializedAccountValidationEmailCommand(email: string) {
    showLoaderWhile(async () => {
      await resendInitializedAccountValidationEmailRequest(email);
      navigate(internalUrls.authentication.login);
    }, {key: "useResendInitializedAccountValidationEmail", value:`API POST - /api/auth/resend_initialized_account_validation_email}`});
  }

  return { resendInitializedAccountValidationEmailCommand };
}
