export enum TimeSlot {
  Ts9To11 = 'Ts9To11',
  Ts11To13 = 'Ts11To13',
  Ts13To15 = 'Ts13To15',
  Ts15To17 = 'Ts15To17',
  Ts17To19 = 'Ts17To19',
  Ts9To12 = 'Ts9To12',
  Ts12To14 = 'Ts12To14',
  Ts14To16 = 'Ts14To16'
}
