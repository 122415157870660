import {
  Button,
  ButtonStyles,
  Container,
  SvgWrapperComponent,
  useLoader,
} from "../../../../../shared";
import styles from "./Summary.module.scss";
import { assets as Assets } from "../../../Assets";
import { i18n } from "../../../i18n";
import classNames from "classnames";
import { globali18n } from "../../../../../i18n";
import { useResponsive } from "../../.././../../shared/utilities/responsive";
import { useNavigate, useParams } from "react-router-dom";
import { useAppUrls } from "../../../../../shared/utilities/urls";
import { apiUrls } from "../../../../../shared/apiUrls";
import { startQuestionnaireSession } from "../../../../../modules/investor-profile/requests/startQuestionnaireSession";
import { useAppDispatch } from "../../../../../store";
import { setFinancialProfileIdentifier } from "../../../../../modules/investor-profile/investorProfileSlice";
import { EProfileType } from "../../../../../modules/investor-profile/models/ProfileTypeEnum";

const t = i18n.riskProfile.summaryPage;

export function Summary() {
  const { isUpXl } = useResponsive();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();
  const dispatch = useAppDispatch();

  const startFinancialProfile = async () => {
    await showLoaderWhile(
      async () => {
        const financialProfileId = await startQuestionnaireSession(
          customerid,
          EProfileType.Financial
        );
        dispatch(setFinancialProfileIdentifier(financialProfileId.id));

        navigate(appUrls.investorProfile.financialProfile.marketFluctuation);
      },
      {
        key: "investorProfile_riskProfile_startQuestionnaireSession",
        value: `API POST - ${
          apiUrls(customerid).investorProfile.questionnaireSession
            .startQuestionnaireSession
        }`,
      }
    );
  };

  return (
    <Container>
      <div className={styles.Container}>
        <div className={styles.Title}>{t.title}</div>
        {isUpXl ? (
          <SvgWrapperComponent ariaHidden={true}>
            <Assets.ReaderCutIcon className={styles.ReaderIcon} />
          </SvgWrapperComponent>
        ) : (
          <SvgWrapperComponent ariaHidden={true}>
            <Assets.ReaderIcon className={styles.ReaderIcon} />
          </SvgWrapperComponent>
        )}

        <div className={styles.DescriptionBox}>
          <p>{t.description}</p>
          <Button
            className={classNames(
              ButtonStyles.PrimaryAction,
              styles.ValidateButton
            )}
            onClick={startFinancialProfile}
          >
            {globali18n.labels.validateModule}
          </Button>
        </div>
      </div>
      <SvgWrapperComponent ariaHidden={true}>
        <Assets.BackgroundImage className={styles.Image} />
      </SvgWrapperComponent>
    </Container>
  );
}
