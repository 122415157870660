import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './TextInput.module.scss';
import { ReactComponent as Checkmark } from './images/checkmark.svg';
import classNames from 'classnames';

interface TextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  register?: UseFormRegisterReturn;
}

export function TextInput({ className, register, ...rest }: TextInputProps) {
  return (
    <div className={styles.Grid}>
      <input className={classNames(className, styles.Input)} {...rest} {...register}/>
      <Checkmark className={styles.Checkmark} />
    </div>
  );
}
