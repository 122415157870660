import { i18n } from "../../shared/i18n";
import logo from "../../shared/images/footerLogo.png";

export function FooterLogo() {
  return (
    <>
      <img src={logo} alt={i18n.footer.logo} />
    </>
  );
}
