import axios, { AxiosError } from 'axios';

export enum ResetPasswordOutcome {
  Success,
  LinkExpired
}

export async function resetPasswordRequest(
  model: ResetPasswordModel
): Promise<ResetPasswordOutcome> {
  try {
    await axios.post(
      `/api/auth/reset_password`,
      { password: model.password },
      {
        params: { email: model.email, sessionId: model.sessionId }
      }
    );
    
    return ResetPasswordOutcome.Success;
  } catch (err) {
    const error = err as AxiosError;
    const body = error.response?.data as any;

    if (error.response?.status === 401 && body.error === 'InvalidSession') {
      return ResetPasswordOutcome.LinkExpired;
    }

    throw error;
  }
}

export interface ResetPasswordModel {
  email: string;
  sessionId: string;
  password: string;
}
