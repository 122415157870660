import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  NumericTextBox,
  NumericTextBoxFocusEvent,
  NumericTextBoxHandle
} from '@progress/kendo-react-inputs';
import { Controller, useForm } from 'react-hook-form';
import { globali18n } from '../../../../i18n';
import {
  ButtonStyles,
  ModalOutcome,
  StyledKendoModal,
  useModal,
  ValidationMessages,
  Button
} from '../../../../shared';
import { getValidationMessages } from '../../../../shared/utilities/getValidationMessages';
import styles from './DefinedValueModal.module.scss';
import { useEffect, useRef } from 'react';

interface FormData {
  definedValue: number | null;
}

interface DefinedValueModalProps {
  value: number | null;
  title: string;
  content?: JSX.Element;
}

const minDefinedValue = 0;
const maxDefinedValue = 10000;

export function DefinedValueModal(props: DefinedValueModalProps) {
  const numericInput = useRef<NumericTextBoxHandle>(null);
  const { hideModal } = useModal();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      definedValue: props.value
    }
  });

  const rules = {
    required: true,
    max: maxDefinedValue,
    min: minDefinedValue
  };

  const errorMessages = {
    required: globali18n.errors.required,
    max: globali18n.errors.max(maxDefinedValue),
    min: globali18n.errors.min(minDefinedValue)
  };

  function submit() {
    const value = getValues().definedValue;

    // -0 would pass a >= 0 test, since -0 === 0
    // So we prevent this situation by returning the absolute value
    const safe = value === 0 ? Math.abs(value) : value;

    hideModal(ModalOutcome.Ok, safe);
  }

  useEffect(()=> {   
    numericInput?.current?.focus();
  }, []);

  // Try to focus the numeric input when the modal is opened
  // Then add keyup even to submit on "Enter" key
  setTimeout(() => {
    numericInput?.current?.focus();
    if (!numericInput?.current?.element || numericInput.current.element.onkeyup) return;
    numericInput.current.element.onkeyup = (e: KeyboardEvent) => {
      e.key === 'Enter' && handleSubmit(submit)();
    };
  }, 0);

  return (
    <>
      <StyledKendoModal
        title={props.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'500px'}
      >
        <div className={styles.ContentGrid} id={"modal-content"} tabIndex={-1}>
          <span>
            <Controller
              control={control}
              name="definedValue"
              rules={rules}
              render={({ field: { onChange, onBlur, value } }) => (
                <NumericTextBox
                  ref={numericInput}
                  value={value}
                  onChange={((e) => {  
                    // Need this ugly tricky to fix unfocus bug
                    // When form state change after inputChange made by the user
                    // the form field loose the focus.
                    // toDo Check later way to enhance the solution
                      onChange(e);  
                      setTimeout(() => {                    
                        numericInput?.current?.focus()
                      }, 0);
                  })}
                  onBlur={onBlur}
                  spinners={false}
                  placeholder={globali18n.labels.amount}
                  format={{ maximumFractionDigits: 0 }}
                />
              )}
            />{' '}
            {globali18n.labels.eurosPerMonth}
          </span>
          <ValidationMessages
            messages={getValidationMessages('definedValue', errors, errorMessages)}
          />
          {props.content && <div>{props.content}</div>}
        </div>
        <DialogActionsBar>
          <Button
            className={ButtonStyles.Unfill}
            onClick={() => hideModal(ModalOutcome.Cancel)}
          >
            {globali18n.labels.cancel}
          </Button>
          <Button className={ButtonStyles.PrimaryAction} onClick={handleSubmit(submit)}>
          {globali18n.labels.validate}
          </Button>
        </DialogActionsBar>
      </StyledKendoModal>
    </>
  );
}
