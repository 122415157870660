import { matmut } from "./multi-tenant/Matmut/Matmut";
import { assets as dashboardAsset } from "./modules/dashboard/Assets";
import { assets as legalPensionAsset } from "./modules/legal-pension/Assets";
import { assets as pensionNeedAsset } from "./modules/pension-need/Assets";
import { assets as taxationAsset } from "./modules/taxation/Assets";
import { assets as lifeProjectAsset } from "./modules/life-projects/Assets";
import { assets as patrimonyAssets } from "./modules/patrimony/Assets";
import { assets as recommendationAssets } from "./modules/recommendation/Assets";
import { assets as sharedAssets } from "./shared/Assets";
import { assets as landingPagesAssets } from "./shared/components/LandingCard/Assets";
import { assets as headerAssets } from "./shared/components/Header/Assets";
import { assets as cookiesAssets } from "./modules/cookies/Assets";
import { i18n as cookiesI18n } from "./modules/cookies/i18n";
import { condition as footerCondition } from "./shared/components/Footer/Condition";
import { i18n as sharedI18n } from "./shared/i18n";
import { globali18n } from "./i18n";
import { Tenant } from "./shared/models/TenantModel";
import { bcef } from "./multi-tenant/BCEF/BCEF";
import { bcefPrivateBank } from './multi-tenant/BCEFPrivateBank/BCEFPrivateBank';
import { condition as DashboardCondition } from "./modules/dashboard/Condition";
import { i18n as dashboardI18n } from "./modules/dashboard/i18n";
import { i18n as recommendationI18n } from "./modules/recommendation/i18n";
import { assets as investorProfileAssets } from "./modules/investor-profile/Assets";
import { cssVar } from "./cssVar";
import { Products } from "./modules/recommendation/Products";
import { bcefRetail } from "./multi-tenant/BCEFRetail/BCEFRetail";

export function TenantLoader(tenantIdentifier: Tenant) {
  let favIcon = document.getElementById("favicon") as HTMLAnchorElement | null;
  // toDelete when backend env are OK
  const devTenant = sessionStorage.getItem('tenant');

  if (tenantIdentifier !== Tenant.Internaute) {
    //Load tenant
    let tenant = matmut;
      // toDelete when backend env are OK
    if (devTenant && devTenant === Tenant.BCEFPrivateBank) {   
        tenant = bcefPrivateBank;
        favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';      
    } else if(devTenant && devTenant === Tenant.BCEFRetail) {
      tenant = bcefRetail;
      favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';  
    }
    else {
    if (tenantIdentifier === Tenant.Matmut) {
      tenant = matmut;
      favIcon!.href = process.env.PUBLIC_URL + "/matmut.ico";
    } else if(tenantIdentifier === Tenant.BCEF) {
      tenant = bcef;
      favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
    } else if(tenantIdentifier === Tenant.BCEFPrivateBank) {        
      tenant = bcefPrivateBank;
      favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';
    } else if(tenantIdentifier === Tenant.BCEFRetail) {
      tenant = bcefRetail;
      favIcon!.href = process.env.PUBLIC_URL + '/bcef.ico';  
    }
  }

    Object.keys(tenant.cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (tenant.cssVar as any)[e])
    );

    tenant.assets.shared(sharedAssets);
    tenant.assets.header(headerAssets);
    tenant.assets.dashboard(dashboardAsset);
    tenant.assets.legalPension(legalPensionAsset);
    tenant.assets.pensionNeed(pensionNeedAsset);
    tenant.assets.taxation(taxationAsset);
    tenant.assets.lifeProjects(lifeProjectAsset);
    tenant.assets.patrimony(patrimonyAssets);
    tenant.assets.recommendation(recommendationAssets);
    tenant.assets.landingPages(landingPagesAssets);
    tenant.assets.cookies(cookiesAssets);
    tenant.assets.investorProfile(investorProfileAssets);

    tenant.displayed.footer(footerCondition);
    tenant.displayed.dashboard(DashboardCondition);
    tenant.i18n.dashboard(dashboardI18n);
    tenant.i18n.global(globali18n);
    tenant.i18n.cookies(cookiesI18n);
    tenant.i18n.recommendation(recommendationI18n);
    tenant.envelops.productSheet(Products)
    return tenant.i18n.shared(sharedI18n);
  } else {
    favIcon!.href = process.env.PUBLIC_URL + "/internaute.ico";
    Object.keys(cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (cssVar as any)[e])
    );
  }
}
