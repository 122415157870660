export const i18n = {
  profilePage: {
    pageTitle: 'Profil',
    title: 'Gérer les paramètres du compte',
    menu: {
      information: 'Informations',
      password: 'Mot de passe'
    },
    items: {
      preferences: 'Mes préférences',
      accountDeletion: 'Suppression du compte'
    }
  },
  setCustomerInformationForm: {
    labels: {
      lastName: 'Votre nom',
      firstName: 'Votre prénom',
      phoneNumber: 'Votre n° de téléphone',
      zipCode: 'Votre code postal (résident français uniquement)',
      canReceiveInformativeContent: 'Je souhaite recevoir du contenu informationnel.',
      canReceiveCommercialContent: 'Je souhaite recevoir des offres et des informations commerciales.',
      submit: "Enregistrer les modifications",
      accountDeletion: 'Suppression du compte'
    }
  },
  setCustomerPasswordForm: {
    title: "Veuillez choisir un autre mot de passe",
    labels: {
      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmer le nouveau mot de passe",
      submit: "Enregistrer les modifications",
      currentPasswordError: "Mot de passe incorrect"
    }

  },
  accountDeletionModal: {
    title: 'Suppression de compte',
    body: <>
      <p>Attention, cette opération est définitive. Après suppression, nous pourrions conserver une partie de vos données préalablement anonymisées.</p>
      <p>Êtes-vous sûr de vouloir supprimer définitivement votre compte &nbsp;?</p>
    </>,
    okText: 'Oui, confirmer la suppression',
    cancelText: 'Non, annuler'
  },
  accountDeletedModal: {
    title: 'Suppression de compte',
    body: <>Votre compte à été supprimé avec succès&nbsp;!</>
  },
  passwordUpdateModal: {
    title: 'Modification de votre mot de passe',
    body: <>Votre mot de passe a été modifié avec succès&nbsp;!</>
  },

  accountDeletionPage: {
    pageTitle: 'Suppression de compte'
  }
};
