import { differenceInYears, isFuture } from "date-fns";
import { ValidateResult } from "react-hook-form";

export function ageBetween(
  minAge: number,
  maxAge: number,
  birthDate: Date | null
): boolean {
  if (birthDate == null) {
    return true;
  }

  const today = new Date();
  const years = differenceInYears(today, birthDate);

  if (years < minAge || years > maxAge) {
    return false;
  }

  return true;
}

export function isValidDate(birthDate: Date): boolean {
  const checkDate = isNaN(birthDate as any) ? "x" : birthDate;
  return birthDate === checkDate;
}

export function careerStartedBetweenAge(
  minAge: number,
  maxAge: number,
  birthDate: Date | null
) {
  return (careerStartYear: string | null): ValidateResult => {
    if (careerStartYear == null || birthDate == null) {
      return true;
    }

    const careerStartDate = new Date(
      +careerStartYear.split("/")[2],
      +careerStartYear.split("/")[1] - 1,
      +careerStartYear.split("/")[0]
    );

    return verifyAgeAtCareerStart(
      minAge,
      maxAge,
      birthDate,
      new Date(careerStartDate)
    );
  };
}

// Used on the birthdate page to check if the user is not choosing a new birthdate
// that would make the age at career start incorrect
export function matchesCareerStartAge(
  minAgeAtCareerStart: number,
  maxAgeAtCareerStart: number,
  careerStart: number | null,
  birthDate: Date | null
): boolean {
  if (careerStart == null || birthDate == null || isNaN(careerStart)) {
    return true;
  }

  let careerStartDate = new Date(careerStart);

  //If old version of creer start is stored we set a 1st of this year
  if(careerStart.toString().length === 4) {
    careerStartDate = new Date(careerStart, 1, 1)
  }

  // From simple year to ISO 8601 (yyyy-MM-dd)
  const birthDateDate = new Date(birthDate);

  return verifyAgeAtCareerStart(
    minAgeAtCareerStart,
    maxAgeAtCareerStart,
    birthDateDate,
    careerStartDate
  );
}

function verifyAgeAtCareerStart(
  minAgeAtCareerStart: number,
  maxAgeAtCareerStart: number,
  birthDate: Date | null,
  careerStart: Date | null
) {
  // We are likely on the birthdate page and there is no career start yet
  // Nothing to validate
  if (careerStart == null || birthDate == null) {
    return true;
  }

  const ageAtCareerStart = differenceInYears(careerStart, birthDate);

  // Check that the user started his career between min and max age
  if (
    ageAtCareerStart < minAgeAtCareerStart ||
    ageAtCareerStart > maxAgeAtCareerStart ||
    isNaN(ageAtCareerStart)
  ) {
    return false;
  }

  return true;
}
