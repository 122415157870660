import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { SavingAccountModel, GetSavingAccountResponseModel } from '../models/SavingsAccountModel';

export async function saveSavingAccountRequest( payload: SavingAccountModel,customerid?: string): Promise<GetSavingAccountResponseModel> {
  const apiUrl = apiUrls(customerid).patrimony.postSavingAccount;
  
  const response = await axios.post<GetSavingAccountResponseModel>(apiUrl, payload);
  return response.data;
}
