import { DashboardModel } from '../models/DashboardModel';

interface DashboardAmounts {
  pensionNeed: number | null;
  legalPension: number | null;
  patrimony: number | null;
  lifeProjects: number | null;
}

// Turns all amounts into nullable numbers regardless of the object structure
export function sanitizeAmounts(model: DashboardModel): DashboardAmounts {
  return {
    pensionNeed: model.pensionNeedMonthlyAmount ?? null,
    patrimony: model.patrimonyMonthlyAmount ?? null,
    legalPension: model.legalPensionMonthlyAmount ?? null,
    lifeProjects: model.lifeProjectsMonthlyAmount
  };
}
