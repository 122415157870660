import { ModalOutcome, StyledKendoModal, useModal } from '../../../../../../../shared';
import { i18n } from '../../../../../i18n';

interface Props {
  model: string;
}

export function InfoModal(props: Props) {
  const { hideModal } = useModal();
  let t = i18n.summaryPage.modal;
  let title = '';
  let content = <></>;

  switch (props.model) {
    case 'housing':
      title = t.housing.title;
      content = t.housing.content;
      break;
    case 'transport':
      title = t.transport.title;
      content = t.transport.content;
      break;
      case 'supply':
        title = t.supply.title;
        content = t.supply.content;
        break;
        case 'hobbies':
          title = t.hobbies.title;
          content = t.hobbies.content;
          break;  
    default:
      title = t.health.title;
      content = t.health.content;
      break;
  }

  return (
    <>
    <StyledKendoModal title={title} onClose={() => hideModal(ModalOutcome.Cancel, null)} width={"500px"}>
      <div id={"modal-content"}>
        {content}
      </div>
    </StyledKendoModal>
    </>
  );
}
