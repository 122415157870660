import {
  CheckboxGroup,
  ButtonStyles,
  Button,
  InputGroup,
  ValidationMessages,
  SvgWrapperComponent,
  TextInput
} from '@ifs/libs';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { i18n } from '../../i18n';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { Controller, useForm } from 'react-hook-form';
import { hasErrors } from '../../../../shared/utilities/hasErrors';
import { getFormConfig } from './formConfig';
import { inputMaxLength } from '../../../../shared/utilities/validation';
import styles from './SetCustomerInformationForm.module.scss';
import { ZipCodesCombobox } from 'modules/appointment/components/ZipCodesCombobox/ZipCodesCombobox';
import { StyledKendoCheckbox } from '@ifs/libs/src/shared';
import classNames from 'classnames';
import { DeleteIcon } from 'modules/profile/pages/ProfilePage/images';
import { useResolver } from 'modules/profile/resolver/resolver';
import { useEffect, useState } from 'react';
import { useSetAccountPreference } from 'modules/profile/commands/setAccountPreference';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  postalCode: any;
  informativeContent: boolean;
  commercialContent: boolean;
}

export function SetCustomerInformationForm(props: SetCustomerInformationFormProps) {
  const { preferences } = useResolver();
  const { setAccountPreferenceCommand } = useSetAccountPreference();
  const [formDataInitialValue, setformDataInitialValue] = useState<FormData>();
  const { register, handleSubmit, watch, formState, getValues, setValue, control } =
    useForm<FormData>({
      criteriaMode: 'all',
      defaultValues: {},
      mode: 'all'
    });

  const formConfig = getFormConfig(register, watch);
  const errors = formState.errors;
  const isSubmitted = formState.isSubmitted;

  useEffect(() => {
    if (preferences) {
      setValue('firstName', preferences.firstName);
      setValue('lastName', preferences.lastName);
      setValue('phoneNumber', preferences.phoneNumber || '');
      setValue(
        'postalCode',
        preferences.location && {
          zipCode: preferences.location.postalCode,
          label: preferences.location.locality
        }
      );
      setValue('informativeContent', preferences.canReceiveInformativeContent);
      setValue('commercialContent', preferences.canReceiveCommercialContent);
      setformDataInitialValue(getValues());
    }
  }, [preferences]);
  function deleteAccount(): void {
    props.deleteAccount();
  }
  async function onSubmit(formData: FormData) {
    const postal = formData.postalCode;
    await setAccountPreferenceCommand(
      preferences!.id,
      {
        lastName: formData.lastName,
        firstName: formData.firstName,
        email: preferences!.email,
        isBankClient: preferences!.isBankClient,
        canReceiveInformativeContent: !!formData.informativeContent,
        canReceiveCommercialContent: !!formData.commercialContent,
        location: postal?.zipCode
          ? { postalCode: postal?.zipCode, locality: postal?.label }
          : preferences?.location || null,
        phoneNumber: formData.phoneNumber || null
      }
    );
  }

  return (
    <>
      <form
        className={classNames(
          ModuleStyles.FormGridResponsive,
          styles.FormGridResponsive,
          styles.Container
        )}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputGroup
          isInvalid={hasErrors('firstName', errors)}
          isValid={isSubmitted && !hasErrors('firstName', errors)}
        >
          <label htmlFor={'firstName'}>{i18n.setCustomerInformationForm.labels.firstName}</label>
          <TextInput
            type="text"
            id="firstName"
            maxLength={inputMaxLength}
            register={formConfig.firstName.field}
          />
          <ValidationMessages
            messages={getValidationMessages('firstName', errors, formConfig.firstName.errors)}
          />
        </InputGroup>

        <InputGroup
          isInvalid={hasErrors('lastName', errors)}
          isValid={isSubmitted && !hasErrors('lastName', errors)}
        >
          <label htmlFor={'lastName'}>{i18n.setCustomerInformationForm.labels.lastName}</label>
          <TextInput
            type="text"
            id="lastName"
            maxLength={inputMaxLength}
            register={formConfig.lastName.field}
          />{' '}
          <ValidationMessages
            messages={getValidationMessages('lastName', errors, formConfig.lastName.errors)}
          />
        </InputGroup>

        <InputGroup className={styles.InputGroup}>
          <label className={styles.Label}>
            {i18n.setCustomerInformationForm.labels.phoneNumber}
          </label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={formConfig.phoneNumber.rules}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                id={'phoneInput'}
                international
                className={styles.PhoneInput}
                flags={flags}
                defaultCountry="FR"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ValidationMessages
            messages={getValidationMessages(
              'phoneNumber',
              errors,
              formConfig.phoneNumber.errorMessages
            )}
          />
        </InputGroup>

        <InputGroup className={styles.InputGroup}>
          <label className={styles.Label}>{i18n.setCustomerInformationForm.labels.zipCode}</label>
          <Controller
            control={control}
            name="postalCode"
            render={({ field: { onChange, value } }) => (
              <ZipCodesCombobox value={value} zipCodeChange={onChange} name={'postalCode'} />
            )}
          />
          <ValidationMessages
            messages={getValidationMessages('postalCode', errors, formConfig.postalCode.errors)}
          />
        </InputGroup>

        <CheckboxGroup className={styles.Checkbox}>
          <Controller
            control={control}
            name="informativeContent"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledKendoCheckbox
                id="informativeContent"
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
              />
            )}
          />
          <label>{i18n.setCustomerInformationForm.labels.canReceiveInformativeContent}</label>
        </CheckboxGroup>

        <CheckboxGroup className={classNames(styles.Checkbox, styles.noMarginTop)}>
          <Controller
            control={control}
            name="commercialContent"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledKendoCheckbox
                id="commercialContent"
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
              />
            )}
          />
          <label>{i18n.setCustomerInformationForm.labels.canReceiveCommercialContent}</label>
        </CheckboxGroup>

        <section className={styles.ButtonGroup}>
          <Button
            type="button"
            className={classNames(ButtonStyles.Unfill, styles.ButtonDeleteAccount)}
            onClick={deleteAccount}
            id="delete-account"
          >
            <div className={styles.ButtonText}>
              <SvgWrapperComponent ariaHidden={true}>
                <DeleteIcon />
              </SvgWrapperComponent>{' '}
              {i18n.setCustomerInformationForm.labels.accountDeletion}
            </div>
          </Button>
          <Button
            className={classNames(
              ButtonStyles.SecondaryAction,
              styles.ButtonSubmit,
              styles.ValidateButton
            )}
          >
            {i18n.setCustomerInformationForm.labels.submit}
          </Button>
        </section>
      </form>
    </>
  );
}

type SetCustomerInformationFormProps = {
  deleteAccount: () => void;
};
