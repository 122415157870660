import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { HousingSize } from '../../../models/housing/housing-size.enum';
import { setHousingSize } from '../../../pensionNeedSlice';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const HousingSizePage = createPensionNeedPage<HousingSize>({
  scaleValueSelector: (q) => q.housing.questionnaire?.size ?? null,
  i18nFn: (i18n) => i18n.housingSizePage,
  scaleValueReducer: setHousingSize,
  answers: [
    { icon: moneyIcon.Money1, label: i18n.housingSizePage.answer1, value: HousingSize.Small },
    { icon: moneyIcon.Money2, label: i18n.housingSizePage.answer2, value: HousingSize.Medium },
    { icon: moneyIcon.Money3, label: i18n.housingSizePage.answer3, value: HousingSize.Large }
  ],
  nextPage: () => ({ route: '../transport/types' })
});
