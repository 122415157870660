import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { TransportType } from '../../../models/transport/transport-type.enum';
import {
  setTransportMotorbikeDefinedValue,
  setTransportMotorbikeScaleValue
} from '../../../pensionNeedSlice';
import { nextTransportAction } from '../../../routing/nextTransportRoute';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const TransportMotorbikePage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.Motorbike)?.scale ?? null,
  definedValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.Motorbike)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.transportMotorbikePage,
  scaleValueReducer: setTransportMotorbikeScaleValue,
  definedValueReducer: setTransportMotorbikeDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.transportMotorbikePage.answer1,
      value: DefaultScale.NotAtAll
    },
    {
      icon: moneyIcon.Money2,
      label: i18n.transportMotorbikePage.answer2,
      value: DefaultScale.ALittle
    },
    {
      icon: moneyIcon.Money3,
      label: i18n.transportMotorbikePage.answer3,
      value: DefaultScale.ALot
    }
  ],
  nextPage: (_, __, questionnaire) =>
    nextTransportAction(TransportType.Motorbike, questionnaire.transportTypes!)
});
