import { globali18n } from "@ifs/libs/src/i18n";
import { UseFormRegister } from "react-hook-form";

export function getFormConfig(register: UseFormRegister<any>) {

  const formConfig = {
    password: {
      field: register('password', {
        required: true
      }),
      errors: {
        required: globali18n.errors.required
      }
    },
  };

  return formConfig;
}