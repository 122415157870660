import styles from "./BackgroundImage.module.scss";
import { assets as Assets } from "../../Assets";
import classNames from "classnames";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";

export function BackgroundImage(props: BackgroundImageProps) {
  return (
    <div className={styles.ImageContainer}>
      <SvgWrapperComponent ariaHidden={true}><Assets.BackgroundImage className={classNames(styles.Image, {[styles.SummaryPage] : props.isSummaryPage})} /></SvgWrapperComponent>
    </div>
  );
}

type BackgroundImageProps = {
  isSummaryPage?: boolean;
}
