import { useNavigate, useParams } from 'react-router';
import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { useLoader } from '../../../shared';
import { useAppUrls } from '../../../shared/utilities/urls';
import { useAppDispatch } from '../../../store';
import { PatrimonyModel } from '../models/PatrimonyModel';
import { setSynthesis } from '../patrimonySlice';
import { getSynthesisRequest } from '../requests/getSynthesisRequest';
import { ScenarioProjectionType } from '../models/SynthesisModel';
import { defineStepCompletionRequest } from '../../../shared/requests/defineStepCompletionRequest';

export function useValidateCommand() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patrimonyDeclarationsEvent } = useAnalytics();
  const { customerid } = useParams();
  const appUrls = useAppUrls()

  async function validateCommand(answers: PatrimonyModel) {
    await showLoaderWhile(async () => {
        // Before getting the patrimony synthesis, 
        // we should complete the patrimony step    
        await defineStepCompletionRequest('Patrimony', customerid)    
        const synthesis = await getSynthesisRequest(customerid);
        dispatch(setSynthesis(synthesis));
        const neutralProjection = synthesis.simulations.find((simu) => simu.scenario === ScenarioProjectionType.Neutral);

        neutralProjection && patrimonyDeclarationsEvent(answers, neutralProjection?.monthlyAmountAtRetirement?.realEstateValue);

        navigate(appUrls.patrimony.summary);
    }, {key: "patrimony_useValidateCommand", value:`Delay before navigate next app route ${appUrls.patrimony.summary}`});
  }

  return { validateCommand };
}
