import classNames from "classnames";
import styles from "./ProductCard.module.scss";
import {
  Button,
  ButtonStyles,
  Card,
  SvgWrapperComponent,
} from "../../../../shared";
import { EyeIcon } from "../../../../shared/images";
import { globali18n } from "../../../../i18n";
import { i18n } from "../../i18n";
import { ProductInterface } from "../../requests/getProducts";
import { ProductTypeIdentifier } from "../../models/product-type-identifier.enum";
import { useProductHelperService } from "../../services/useProductHelper.service";

export interface ProductCardProps {
  extraPrimaryActionWording?: string;
  extraPrimaryAction?: () => {};
  product: ProductInterface;
}

export function ProductCard(props: ProductCardProps) {
  const { openProductSheetModal } = useProductHelperService();

  const t = i18n.recommendationProductsPage;
  const productsI18n = i18n.products;

  return (
    <Card className={styles.ProductCard}>
      <div className={styles.ProductInformation}>
        <div className={styles.ProductName}>
          {
            productsI18n.name[props.product.productTypeIdentifier as ProductTypeIdentifier]
          }
        </div>
        <div className={styles.Information}>{productsI18n.advertisingNature}</div>
        <div className={styles.ProductDefinition}>
          {
            productsI18n.definition[props.product.productTypeIdentifier as ProductTypeIdentifier]
          }
        </div>
      </div>

      <div className={styles.ButtonContainer}>
        {props.extraPrimaryActionWording && (
          <Button
            className={classNames(ButtonStyles.PrimaryAction)}
            onClick={props.extraPrimaryAction}
          >
            {props.extraPrimaryActionWording}
          </Button>
        )}
        <Button
          className={classNames(styles.ButtonDetail, ButtonStyles.Unfill)}
          onClick={() => {
            openProductSheetModal(
              props.product,
              undefined, 
              true              
            );
          }}
        >
          <span className={styles.Icon}>
            <SvgWrapperComponent
              ariaLabel={globali18n.ariaLabel.furtherInformation}
            >
              <EyeIcon />
            </SvgWrapperComponent>
          </span>
          {t.ctas.detail}
        </Button>
      </div>
    </Card>
  );
}
