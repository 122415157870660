import { useNavigate } from 'react-router-dom';
import { useInfoModal, useLoader } from '@ifs/libs';
import { AppointmentInputModel } from '../models/AppointmentInputModel';
import { setAppointmentRequest } from '../requests/setAppointmentRequest';
import { i18n } from 'modules/appointment/i18n';
import { condition as DisplayedBlock } from '../Displayed';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';

export function useSetAppointmentCommand() {
  const { showLoaderWhile } = useLoader();
  const { showInfoModal } = useInfoModal();
  const navigate = useNavigate();

  async function setAppointmentCommand(model: AppointmentInputModel) {
    return await showLoaderWhile(async () => {
      await setAppointmentRequest(model);

      if(DisplayedBlock.displayRdvConfirmModal){
        showInfoModal(i18n.confirmMeetingModal.title, i18n.confirmMeetingModal.body, true, "");
      }

      navigate(internalUrls.dashboard);
    }, {key: "setAppointmentCommand", value:`API POST - ${apiUrls().appointments.post}`});
  }

  return { setAppointmentCommand };
}
