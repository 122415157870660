import { PayloadAction } from "@reduxjs/toolkit";
import {
  Container,
  QuestionTitle,
  ModalOutcome,
  useModal,
} from "../../../shared";
import { useAppDispatch, useAppSelector } from "../../../store";
import { AnswerButton } from "../components/AnswerButton/AnswerButton";
import { i18n, PensionNeedPageTranslation } from "../i18n";
import { QuestionnaireModel } from "../models/computation/QuestionnaireModel";
import { isEqual } from "lodash";
import styles from "./createPensionNeedPage.module.scss";
import { FunctionComponent, SVGProps } from "react";
import {
  PensionNeedState,
  setBirthdateValidationType,
} from "../pensionNeedSlice";
import classNames from "classnames";
import { Styles } from "../styles/Styles";
import { DefinedValueModal } from "../components/DefinedValueModal/DefinedValueModal";
import { NextActionPN, useNextCommand } from "../commands/nextCommand";
import { useAnalytics } from "../../../shared/commands/useAnalytics";
import { useParams } from "react-router-dom";
import { assets as Assets } from "../Assets";
import { useAnswerCommands } from "../../../modules/legal-pension/commands/answerCommands";

export function createPensionNeedPage<TScale>(config: {
  scaleValueSelector: (questionnaire: QuestionnaireModel) => TScale | null;
  definedValueSelector?:
    | ((questionnaire: QuestionnaireModel) => number | null)
    | null;
  i18nFn: (i: typeof i18n) => PensionNeedPageTranslation;
  scaleValueReducer: (s: TScale) => PayloadAction<TScale>;
  definedValueReducer?: ((v: number) => PayloadAction<number>) | null;
  answers: {
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    label: string;
    value: TScale;
  }[];
  nextPage: (
    scaleValue: TScale | null,
    definedValue: number | null,
    state: PensionNeedState,
    customerid?: string,
    extraCommand?: (...data: any) => Promise<void> | null
  ) => NextActionPN | Promise<NextActionPN>;
}) {
  return function () {
    const dispatch = useAppDispatch();
    const { showModal } = useModal();
    const { nextCommand } = useNextCommand();
    const { answerBirthdate } = useAnswerCommands();
    const { answerNeedsEvent } = useAnalytics();
    const { customerid } = useParams();

    const state = useAppSelector((s) => s.pensionNeed);
    const scaleValue = useAppSelector((s) =>
      config.scaleValueSelector(s.pensionNeed.answers!)
    );
    const definedValue = useAppSelector(
      (s) => config.definedValueSelector?.(s.pensionNeed.answers!) ?? null
    );
    const translation = config.i18nFn(i18n);

    function selectAnswer(answer: TScale) {
      dispatch(config.scaleValueReducer(answer));
      answerNeedsEvent(
        config.answers.filter((e) => e.value === answer)[0].label,
        translation.question
      );
      next(answer, null);
    }

    async function defineValue() {
      if (config.definedValueReducer == null) {
        return;
      }

      const result = await showModal<number>(
        <DefinedValueModal
          value={definedValue}
          title={translation.definedValueModal!.title}
          content={translation.definedValueModal!.content}
        />
      );

      if (result.outcome === ModalOutcome.Ok && result.value != null) {
        dispatch(config.definedValueReducer(result.value));
        await next(null, result.value);
      }
    }

    async function next(
      scaleValue: TScale | null,
      definedValue: number | null
    ) {
      const next = await config.nextPage(
        scaleValue,
        definedValue,
        state,
        customerid,
        answerBirthdate
      );
      next.route &&
        next.route.includes("birthdate") &&
        dispatch(setBirthdateValidationType("advanced"));
      await nextCommand(next);
    }

    return (
      <div>
        <Container
          className={classNames(styles.Grid, Styles.Background)}
          // style={{ backgroundImage: cssUrl(backgroundImage) }}
        >
          <QuestionTitle>{translation.question}</QuestionTitle>
          <div className={styles.AnswerGrid}>
            {config.answers.map((a, i) => {
              return (
                <AnswerButton
                  key={i}
                  icon={a.icon}
                  label={a.label}
                  selected={isEqual(scaleValue, a.value)}
                  onClick={() => selectAnswer(a.value)}
                  className={styles.AnswerButton}
                />
              );
            })}
          </div>
          {config.definedValueReducer && (
            <>
              <div className={styles.Or}>{i18n.createPensionNeedPage.or}</div>
              <AnswerButton
                label={translation.definedValue!}
                selected={definedValue != null}
                onClick={defineValue}
                className={styles.AnswerButton}
              />
            </>
          )}
        </Container>
        <div className={styles.Padding}>
          <Assets.BackgroundImage className={styles.Image} />
        </div>
      </div>
    );
  };
}
