import { useNavigate } from 'react-router';
import { useLoader } from '@ifs/libs';
import { useAppSelector } from 'store';
import { RegisterModel, RegisterOutcome } from '../requests/registerRequest';
import {
    registerRequest,
  } from '../requests/registerRequest';
  import { upgradeProspectRequest } from '../requests/upgradeProspectRequest';
import { UserType } from '@ifs/libs/src/shared/requests/getUserReferenceRequest';
  
export function useRegister() {
  const { showLoaderWhile } = useLoader();
  const isProspect = useAppSelector((s) => s.authentication.userType === UserType.Prospect);
  const navigate = useNavigate();

  async function registerCommand(model: RegisterModel): Promise<RegisterOutcome> {
    return await showLoaderWhile(async () => {
      const result = await registerOrUpgrade(isProspect, model);

      if (result === RegisterOutcome.Success) {
        navigate({
          pathname: '/register/email',
          search: new URLSearchParams({ email: model.email }).toString()
        });
      }

      return result;
    }, {key: "useRegister", value:`Handle registerOrUpgrade}`});
  }

  return { registerCommand };
}

async function registerOrUpgrade(isProspect: boolean, model: RegisterModel) {
    // If the user is a prospect we upgrade him
    if (isProspect) {
      return await upgradeProspectRequest(model);
    }
    // If the user is a new user, we register him
    else {
      return await registerRequest(model);
    }
  }
