import { Scenario } from "../../modules/investor-profile/models/RiskProfileEnum";
import { Module } from "../../shared/models/Module";
import { amountPerMonth } from "../../shared/utilities/intl";
import styles from "../dashboard/components/Future/Future.module.scss";

export const i18n = {
  moduleNames: {
    [Module.LegalPension]: "Votre retraite légale",
    [Module.PensionNeed]: "Vos besoins retraite",
    [Module.Patrimony]: "Votre patrimoine",
    [Module.LifeProjects]: "Vos projets de vie",
    [Module.Taxation]: "Votre avantage fiscal",
    [Module.InvestorProfile]: "Votre profil investisseur",
  },

  scenario: {
    [Scenario.Conservateur]: "Conservateur",
    [Scenario.Prudent]: "Prudent",
    [Scenario.Equilibre]: "Équilibré",
    [Scenario.Dynamique]: "Dynamique",
    [Scenario.Offensif]: "Offensif",
  },

  landingPage: {
    title: "Diagnostic retraite",
    content: (amount: number) => (
      <div>
        <div>
          Grâce à vos réponses nous pouvons estimer votre budget à la retraite
        </div>
        <div className={styles.LandingAmountBloc}>
          <span className={styles.LandingLabel}>
            {amount < 0
              ? "Il vous manquera"
              : "Vous disposerez d’un excédent de revenu de"}
          </span>
          <p className={styles.LandingAmount}>
            {amountPerMonth(Math.abs(amount))}
          </p>
        </div>
      </div>
    ),
  },

  dashboardPage: {
    pageTitle: "Votre tableau de bord",

    chart: {
      yourExpense: "Vos dépenses",
      yourIncome: "Vos revenus",
    },

    project: {
      title: "Votre diagnostic retraite",
      surplusDeficit: {
        surplus: (amount: number) => {
          return (
            <div className={styles.MainText}>
              <div>Vous disposerez d’un excédent de revenu de</div>
              <div className={styles.Amount}>
                {amountPerMonth(Math.abs(amount))}
              </div>
            </div>
          );
        },
        deficit: (amount: number) => {
          return (
            <div>
              <div className={styles.MainText}>Il vous manquera</div>
              <div className={styles.Amount}>
                {amountPerMonth(Math.abs(amount))}
              </div>
            </div>
          );
        },
      },
    },

    disclaimer: "Veuillez compléter l'ensemble des modules du parcours afin d'accéder à votre profil investisseur.",

    information: {
      title: "Vos dépenses",
      modulesLeft:
        "Voici une première estimation de votre budget à la retraite, complétez vos informations pour un diagnostic complet.",
    },

    income: {
      title: "Vos revenus",
    },

    taxSavings: {
      title: "Vos informations",
      description:
        "Montant maximum déductible des revenus nets imposables de votre foyer",
    },

    future: {
      buttons: {
        continueJourney: "Continuer votre parcours",
        weGoNext: "On passe à la suite ?",
        accessToRecommendation: "Accéder à votre solution personnalisée",
        advertisingNature: {
          weGoNext: "",
          accessToRecommendation: "Communication à caractère publicitaire"
        }
      },
      texts: {
        rightWay: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Vous êtes sur la bonne voie !
            </p>
            <p>Continuer votre parcours pour affiner votre diagnostic.</p>
          </div>
        ),
        hasAppointment: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Votre RDV avec nos experts est confirmé&nbsp;!
            </p>
            <p>À bientôt !</p>
          </div>
        ),
        deficit: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Votre diagnostic retraite est complet&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Découvrez notre solution d’épargne pour concrétiser votre projet
              retraite.
            </p>
            <p>Renseignez dès maintenant votre profil investisseur.</p>
          </div>
        ),
        deficitExpired: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Votre diagnostic retraite est complet&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Découvrez notre solution d’épargne pour concrétiser votre projet
              retraite.
            </p>
            <p>Mettez votre profil investisseur à jour dès maintenant.</p>
          </div>
        ),
        surplus: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Félicitations, vos besoins retraite sont couverts&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Optimisez vos investissements et votre fiscalité avec notre
              solution d’épargne.
            </p>
            <p>Renseignez dès maintenant votre profil investisseur.</p>
          </div>
        ),
        surplusExpired: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Félicitations, vos besoins retraite sont couverts&nbsp;!
            </p>
            <p className={styles.CongratulationTitle}>
              Optimisez vos investissements et votre fiscalité avec notre
              solution d’épargne.
            </p>

            <p>Mettez votre profil investisseur à jour dès maintenant.</p>
          </div>
        ),
        completed: (
          <div className={styles.Completed}>
            <p className={styles.CongratulationTitle}>
              Passez maintenant à l’action pour optimiser vos revenus !
            </p>
            <p className={styles.CongratulationTitle}>
              Votre futur sera fier de vous.
            </p>
          </div>
        ),
      },

      hasAppointmentNoModuleDone: "Continuer votre parcours",
      nextAppointment:
        "Votre prochain rendez-vous avec un conseiller se déroulera",
      download: "Visualiser votre rapport complet",
    },

    toComplete: "À compléter",

    labels: {
      retirementNeeds: "Vos besoins",
      retirementRevenue: "Vos revenus",

      gap: {
        completion: "Actuellement vos besoins sont couverts à ",
      },
    },
  },
  registerOrLoginModal: {
    title: "Créez un compte pour accéder à votre solution retraite",
    titleMeeting: "Créez un compte pour accéder à la prise de rendez-vous",
    create: "Créer un compte",
    already: "Déjà un compte\u00A0?",
    login: "Me connecter",
  },
  saveYourInfoModal: {
    labels: {
      title: "Créez votre compte en quelques clics",
      description:
        "Nous vous invitons à créer un compte afin de sauvegarder vos informations et d’accéder à l’étape suivante.",
      create: "Créer un compte",
    },
  },
  expiredInvestorProfileModal: {
    goToInvestorProfile: "Mettre à jour votre profil investisseur",
    title: "Votre profil investisseur a expiré",
    content:
      "Mettez à jour votre profil investisseur dès à présent afin de bénéficier d’une solution d’épargne adaptée à votre situation et vos projets.",
  },

  dashboardPieChart: {
    missing: "À compléter",
  },
};
