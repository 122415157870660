import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionnaireModel } from './models/computation/QuestionnaireModel';
import { TransportType } from './models/transport/transport-type.enum';
import {
  healthMedicalDefinedValueReducer,
  healthMedicalScaleValueReducer,
  healthUnexpectedDefinedValueReducer,
  healthUnexpectedScaleValueReducer,
  housingCitySizeReducer,
  housingDefinedValueReducer,
  housingLocationTypeReducer,
  housingSizeReducer,
  housingTransactionTypeReducer,
  housingTypeReducer,
  otherHobbiesDefinedValueReducer,
  otherHobbiesScaleValueReducer,
  otherSportsDefinedValueReducer,
  otherSportsScaleValueReducer,
  otherTravelsDefinedValueReducer,
  otherTravelsScaleValueReducer,
  supplyHomeDefinedValueReducer,
  supplyHomeScaleValueReducer,
  supplyRestaurantDefinedValueReducer,
  supplyRestaurantScaleValueReducer,
  transportBikeDefinedValueReducer,
  transportBikeScaleValueReducer,
  transportCarDefinedValueReducer,
  transportCarScaleValueReducer,
  transportMotorbikeDefinedValueReducer,
  transportMotorbikeScaleValueReducer,
  transportPublicDefinedValueReducer,
  transportPublicScaleValueReducer
} from './reducers';
import { Persona } from './pages/personas/PersonaEnum';
import { AdvancedComputationCategoryDetails } from './models/request/common';
import { EComputeCategoryId } from './models/computation/ComputeCategory.enum';

export interface PensionNeedState {
  initialized: boolean;
  birthdateValidationType: 'default' | 'intermediate' | 'advanced';
  persona: Persona | null;
  answers: QuestionnaireModel | null;
  transportTypes: TransportType[] | null;
  computationId: string | null;
  results: AdvancedComputationCategoryDetails[] | null;

  gamifiedValues: AdvancedComputationCategoryDetails[] | null;
}

export interface PensionNeedStateInitialized {
  answers: QuestionnaireModel;
  transportTypes: TransportType[];
  gamifiedValues: AdvancedComputationCategoryDetails[];

  computationId: string | null;
  results: AdvancedComputationCategoryDetails[] | null;
}

const initialAnswersState = {
  housing: {},
  transport: [],
  supply: [],
  other: [],
  health: []
};

const initialState: PensionNeedState = {
  initialized: false,

  // Configuration values
  birthdateValidationType: 'default',
  persona: null,
  // User answers
  answers: null,
  transportTypes: null,
  gamifiedValues: null,

  // Computation/backend results
  results: null,
  computationId: null
};

export const pensionNeedSlice = createSlice({
  name: 'pensionNeed',
  initialState,
  reducers: {
    initialize: (state) => {
      state.answers = initialAnswersState;
      state.transportTypes = [];
      state.gamifiedValues = [];
      state.initialized = true;
    },

    restore: (
      state,
      action: PayloadAction<{
        computationId: string;
        gamifiedValues: AdvancedComputationCategoryDetails[] | null;
      }>
    ) => {
      state.computationId = action.payload.computationId;
      state.gamifiedValues = action.payload.gamifiedValues ?? [];
      state.initialized = true;
    },

    resetAnswers: (state) => {
      state.answers = { ...initialAnswersState };
      state.transportTypes = [];
      state.gamifiedValues = [];
    },

    resetPensionNeed: () => {
      return { ...initialState };
    },

    // Housing
    setHousingLocationType: housingLocationTypeReducer,
    setHousingTransactionType: housingTransactionTypeReducer,
    setHousingSize: housingSizeReducer,
    setHousingType: housingTypeReducer,
    setHousingCitySize: housingCitySizeReducer,
    setHousingDefinedValue: housingDefinedValueReducer,

    // Supply
    setSupplyHomeDefinedValue: supplyHomeDefinedValueReducer,
    setSupplyRestaurantDefinedValue: supplyRestaurantDefinedValueReducer,
    setSupplyHomeScaleValue: supplyHomeScaleValueReducer,
    setSupplyRestaurantScaleValue: supplyRestaurantScaleValueReducer,

    // Transport
    setTransportCarDefinedValue: transportCarDefinedValueReducer,
    setTransportMotorbikeDefinedValue: transportMotorbikeDefinedValueReducer,
    setTransportPublicDefinedValue: transportPublicDefinedValueReducer,
    setTransportBikeDefinedValue: transportBikeDefinedValueReducer,
    setTransportCarScaleValue: transportCarScaleValueReducer,
    setTransportMotorbikeScaleValue: transportMotorbikeScaleValueReducer,
    setTransportPublicScaleValue: transportPublicScaleValueReducer,
    setTransportBikeScaleValue: transportBikeScaleValueReducer,

    setTransportTypes: (state, action: PayloadAction<TransportType[]>) => {
      state.transportTypes = action.payload;
      state.answers!.transport = state.answers!.transport.filter((tt) =>
        (action.payload as string[]).includes(tt.typeId)
      );
    },

    // Others
    setOtherTravelsDefinedValue: otherTravelsDefinedValueReducer,
    setOtherSportsDefinedValue: otherSportsDefinedValueReducer,
    setOtherHobbiesDefinedValue: otherHobbiesDefinedValueReducer,
    setOtherTravelsScaleValue: otherTravelsScaleValueReducer,
    setOtherSportsScaleValue: otherSportsScaleValueReducer,
    setOtherHobbiesScaleValue: otherHobbiesScaleValueReducer,

    // Health
    setHealthMedicalDefinedValue: healthMedicalDefinedValueReducer,
    setHealthUnexpectedDefinedValue: healthUnexpectedDefinedValueReducer,
    setHealthMedicalScaleValue: healthMedicalScaleValueReducer,
    setHealthUnexpectedScaleValue: healthUnexpectedScaleValueReducer,

    setResults: (state, action: PayloadAction<AdvancedComputationCategoryDetails[]>) => {
      state.results = action.payload;
    },
    setComputationId: (state, action: PayloadAction<string>) => {
      state.computationId = action.payload;
    },
    setPersona: (state, action: PayloadAction<Persona>) => {
      state.persona = action.payload;
    },
    setBirthdateValidationType: (
      state,
      action: PayloadAction<'default' | 'intermediate' | 'advanced'>
    ) => {
      state.birthdateValidationType = action.payload;
    },
    setGamifiedValues: (state, action: PayloadAction<AdvancedComputationCategoryDetails[]>) => {
      state.gamifiedValues = action.payload;
    },
    setGamifiedValue: (
      state,
      action: PayloadAction<{ propertyName: EComputeCategoryId; value: number }>
    ) => {
      const computeFieldFound = state.gamifiedValues!.find((category)=> category!.categoryId === action.payload.propertyName)
      if(!computeFieldFound) return;
      computeFieldFound.value = action.payload.value;
    }
  }
});

export const {
  initialize,
  resetAnswers,
  resetPensionNeed,
  restore,

  // Housing
  setHousingLocationType,
  setHousingTransactionType,
  setHousingSize,
  setHousingType,
  setHousingCitySize,
  setHousingDefinedValue,

  // Supply
  setSupplyHomeDefinedValue,
  setSupplyRestaurantDefinedValue,
  setSupplyHomeScaleValue,
  setSupplyRestaurantScaleValue,

  // Transport
  setTransportCarDefinedValue,
  setTransportMotorbikeDefinedValue,
  setTransportPublicDefinedValue,
  setTransportBikeDefinedValue,
  setTransportCarScaleValue,
  setTransportMotorbikeScaleValue,
  setTransportPublicScaleValue,
  setTransportBikeScaleValue,

  setTransportTypes,

  // Others
  setOtherTravelsDefinedValue,
  setOtherSportsDefinedValue,
  setOtherHobbiesDefinedValue,
  setOtherTravelsScaleValue,
  setOtherSportsScaleValue,
  setOtherHobbiesScaleValue,

  // Health
  setHealthMedicalDefinedValue,
  setHealthUnexpectedDefinedValue,
  setHealthMedicalScaleValue,
  setHealthUnexpectedScaleValue,

  setResults,
  setComputationId,
  setPersona,
  setBirthdateValidationType,
  setGamifiedValue,
  setGamifiedValues
} = pensionNeedSlice.actions;
export const pensionNeedReducer = pensionNeedSlice.reducer;
