import { Tenant } from "@ifs/libs/src/shared/models/TenantModel"
import { TenantLoader } from "@ifs/libs/src/TenantLoader"
import { ExternalUrlsType } from "@ifs/libs/src/multi-tenant/Models/ExternalUrls";
import { TenantLoader as NonSharedModuleTenantLoader } from "TenantLoader";
import { loadTenantRequest } from "shared/requests/loadTenant";
import { useDispatch } from "react-redux";
import { setModuleSettings, setTenantState } from "@ifs/libs/src/shared/slices/tenantSlice";

interface TenantModelResponse {
    TenantIdentifier: Tenant,
    Urls: ExternalUrlsType,
    ModuleSettings: { Key: string; Value: string }[]
}


export function useLoadTenant() {  
    const dispatch = useDispatch();

    async function loadTenantCommand(): Promise<TenantModelResponse | null> {
        const response = await loadTenantRequest();
        if (response.status === 204 || response.status === 200) {
            const data = response.data as TenantModelResponse;
            const identifier = data.TenantIdentifier;
            // TODO Delete when Back env OK
            const devTenant = sessionStorage.getItem('tenant');

            NonSharedModuleTenantLoader(identifier, data.Urls);
            TenantLoader(identifier);
            if (devTenant && (devTenant === Tenant.BCEFPrivateBank || devTenant === Tenant.BCEFRetail)) {
                // TODO Manually add config of Authentification mode to OIDCAuthCode value
                // Must be delete when backend env is OK
                dispatch(setModuleSettings({Key: "AuthenticationMode", Value: "OIDCAuthCode"}));  
            }
            else {               
                 dispatch(setTenantState(response.data));
            }
            return data;
        }
        return null;
    }
    return { loadTenantCommand }
}

