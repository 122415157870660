import { DashboardModel } from '../../models/DashboardModel';

export function computeCompletion(model: DashboardModel) {
  if (model == null) {
    return null;
  }

  // Completion is only pertinent once all modules are completed
  // Before that, the 'weather' is not displayed
  if (
    model.pensionNeedMonthlyAmount === null ||
    model.legalPensionMonthlyAmount === null ||
    model.lifeProjectsMonthlyAmount === null ||
    model.patrimonyMonthlyAmount === null
  ) {
    return null;
  }

  const completion = computeRevenues(model) / computeNeeds(model);

  // Flooring the percentage for consistency with the backend
  const floored = Math.floor(completion * 100) / 100;

  return floored;
}

export interface NeedsRevenues {
  needs: number;
  revenues: number;
}

export function computerNeedsRevenues(model: DashboardModel): NeedsRevenues {
  return {
    needs: computeNeeds(model),
    revenues: computeRevenues(model)
  };
}

function computeRevenues(model: DashboardModel) {
  return (model.legalPensionMonthlyAmount|| 0) + (model.patrimonyMonthlyAmount || 0);
}

function computeNeeds(model: DashboardModel) {
  return (model.pensionNeedMonthlyAmount || 0) + (model.lifeProjectsMonthlyAmount || 0);
}
