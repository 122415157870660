import classNames, { Argument } from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import { SvgWrapperComponent } from '../../../../shared/components/SvgWrapper/SvgWrapperComponent';
import styles from './AnswerToggle.module.scss';
import { ReactComponent as CheckmarkIcon } from './images/checkmark.svg';
import { globali18n } from '../../../../i18n';

interface AnswerToggleProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: () => void;

  className?: Argument;
  childrenClassName?: Argument;
}

export function AnswerToggle(props: PropsWithChildren<AnswerToggleProps>) {
  const [checked, setChecked] = useState<boolean>(props.value ?? false);

  useEffect(() => {
    setChecked(props.value ?? false);
  }, [props.value]);

  function clicked() {
    setChecked(!checked);
    props.onChange?.(!checked);
  }

  return (
    <>
      <button
        type="button"
        onClick={clicked}
        onBlur={props.onBlur}
        className={classNames(
          styles.Button,
          { [styles.Checked]: checked },
          props.className
        )}
      >
        <span className={classNames(styles.Childen, props.childrenClassName)}>{props.children}</span>
        <SvgWrapperComponent ariaLabel={globali18n.ariaLabel.passwordShow}><CheckmarkIcon className={styles.Icon} /></SvgWrapperComponent>
      </button>
    </>
  );
}
