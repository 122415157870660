import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaxationOutputModel } from './models/TaxationOutputModel';
import { TmiConfig } from './models/TmiConfig';
import { TaxationComputationInputModel, TaxAvailableInputModel } from './models/TaxationComputationInputModel';

export interface TaxationState {
  answers: TaxationComputationInputModel;
  results: TaxationOutputModel | null;
  tmiConfigs: TmiConfig[] | null;
}

// delete useless properties when backend is OK
const initialState: TaxationState = {
  answers: {
    taxAvailable: {
      declareAmount: null,
      optionalDeclareAmount: null
    } as unknown as TaxAvailableInputModel,
    taxRate: null
  } as unknown as TaxationComputationInputModel,
  results: null,
  tmiConfigs: null
};

export const taxationSlice = createSlice({
  name: 'taxation',
  initialState: initialState,
  reducers: {
    setAnswers: (state, action: PayloadAction<TaxationComputationInputModel>) => {
      state.answers = action.payload;
    },
    setComputed: (state, action: PayloadAction<TaxationOutputModel>) => {
      const payload = action.payload;
      state.answers = {
        firstIndividualMaximumTaxDeduction: payload.firstIndividualMaximumTaxDeduction,
        secondIndividualMaximumTaxDeduction: payload.secondIndividualMaximumTaxDeduction,
        selectedTmi: payload.selectedTmi,
      }
    },
    setTmiConfigs: (state, action: PayloadAction<TmiConfig[]>) => {
      state.tmiConfigs = action.payload;
    },    
    setTaxAvailable: (state, action: PayloadAction<TaxAvailableInputModel>) => {
      state.answers.firstIndividualMaximumTaxDeduction = action.payload.declareAmount;
      state.answers.secondIndividualMaximumTaxDeduction = action.payload.optionalDeclareAmount;
    },
    setTaxRate: (state, action: PayloadAction<number>) => {

      state.answers.selectedTmi = action.payload;
    },   

    setResults: (state, action: PayloadAction<TaxationOutputModel>) => {
      state.results = action.payload;
    },
    resetTaxation: () => {
      return { ...initialState };
    }
  }
});

export const {
  setAnswers,
  setComputed,
  setTmiConfigs,

  setTaxAvailable,
  setTaxRate,

  setResults,
  resetTaxation
} = taxationSlice.actions;
export const taxationReducer = taxationSlice.reducer;
