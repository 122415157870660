import { stormcloud } from "../../../../styles/colors";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { number } from "../../../../shared/utilities/intl";
import { i18n } from "../../i18n";
import styles from "./ModuleCard.module.scss";
import { EditIcon } from "../../../../shared";
import { ModuleStatus } from "../../../../modules/dashboard/models/ModuleStatus";
import { determineModuleBackgroundColor } from "../../../../modules/dashboard/utilities/determineModuleBackgroundColor";
import { assets as Assets } from "../../../../modules/dashboard/Assets";
import classNames from "classnames";
import { enterKey } from "../../../../shared/commands/keyboardActionKey";

interface ModuleCardProps {
  icon: any;
  unCompletedIcon: any;
  lockIcon: any;
  title: string;

  prefix?: string;
  amount?: number | null;
  status: ModuleStatus;

  unit?: string;
  color: string;

  hideAmount?: boolean;

  onClick?: () => void;
}

export function ModuleCard(props: ModuleCardProps) {
  const backgroundColor = determineModuleBackgroundColor(
    props.status,
    props.color
  );

  const isAvailable = props.status === ModuleStatus.Available;
  const isCompleted = props.status === ModuleStatus.Completed;
  const isLocked = props.status === ModuleStatus.Locked;

  function click() {
    if (props.status === ModuleStatus.Locked) {
      return;
    }

    props.onClick?.();
  }

  const Icon = props.icon;
  const UnCompletedIcon = props.unCompletedIcon;
  const ModuleLockIcon = props.lockIcon;

  return (
    <button
      className={classNames({
        [styles.Grid]: !isLocked,
        [styles.LockedStyle]: isLocked,
      })}
      style={{ ...backgroundColor }}
      onClick={click}
      onKeyDown={(e) => {
        enterKey(e, click);
      }}
    >
      <div className={styles.Icon}>
        {isLocked ? (
          <SvgWrapperComponent ariaHidden={true}>
            <ModuleLockIcon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        ) : isAvailable ? (
          <SvgWrapperComponent ariaHidden={true}>
            <UnCompletedIcon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        ) : (
          <SvgWrapperComponent ariaHidden={true}>
            <Icon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        )}
      </div>
      <div
        className={classNames(styles.TitleAndIcon, {
          [styles.noAmountData]: isCompleted && props.hideAmount,
        })}
      >
        <div className={styles.Title}>{props.title}</div>
        {isLocked && (
          <SvgWrapperComponent ariaHidden={true}>
            <Assets.LockIcon />
          </SvgWrapperComponent>
        )}
      </div>
      {isCompleted && !props.hideAmount && (
        <div>
          <span className={styles.Amount}>
            {props.prefix}
            {number(Math.floor(props.amount ?? 0))}
          </span>{" "}
          <span className={styles.Unit}>{props.unit}</span>
        </div>
      )}
      {isAvailable && (
        <div className={styles.ToComplete}>{i18n.dashboardPage.toComplete}</div>
      )}
      {!isLocked && (
        <div className={styles.Corner}>
          <SvgWrapperComponent ariaHidden={true}>
            <EditIcon
              className={styles.EditIcon}
              filled={true}
              color={stormcloud}
            />
          </SvgWrapperComponent>
        </div>
      )}
    </button>
  );
}
