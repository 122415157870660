import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { ValidationMessages, InputGroup, TextInput } from '@ifs/libs';
import { i18n } from '../../i18n';
import { hasErrors } from '../../utilities/hasErrors';
import { getEmailConfig } from './formConfig';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';

interface EmailInputGroupProps {
  name: string;
  isSubmitted?: boolean;
  errors: FieldErrors<{ email: string }>;
  register: UseFormRegister<any>;
}

export function EmailInputGroup(props: EmailInputGroupProps) {
  const formConfig = getEmailConfig(props.register);

  return (
    <InputGroup
      isInvalid={hasErrors(props.name, props.errors)}
      isValid={props.isSubmitted && !hasErrors(props.name, props.errors)}
    >
      <label htmlFor={props.name}>{i18n.emailInputGroup.label}</label>
      <TextInput
        type="email"
        id={props.name}
        register={formConfig.email.field}
        required
        aria-required={"true"}
      />
      <ValidationMessages
        messages={getValidationMessages(props.name, props.errors, formConfig.email.errors).filter(
          (value, index, array) => array.indexOf(value) === index
        )}
      />
    </InputGroup>
  );
}
