import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LifeProjectsModel } from './models/LifeProjectsModel';

export interface LifeProjectsState {
  answers: LifeProjectsModel;
}

const initialState: LifeProjectsState = {
  answers: {
    realEstateAssets: 0,
    pleasurePurchases: 0,
    family: 0,
    anticipation: 0,
    philanthropy: 0,
    other: 0
  }
};

export const lifeProjectsSlice = createSlice({
  name: 'lifeProjects',
  initialState,
  reducers: {
    setAnswers: (state, action: PayloadAction<LifeProjectsModel>) => {
      state.answers = action.payload;
    },
    resetLifeProjects: () => {
      return { ...initialState };
    }
  }
});

export const {
  setAnswers,

  resetLifeProjects
} = lifeProjectsSlice.actions;
export const lifeProjectsReducer = lifeProjectsSlice.reducer;
