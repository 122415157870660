import { useNavigate } from 'react-router';
import { ModalOutcome, useModal } from '../../../shared';
import { UserType } from '../../../shared/requests/getUserReferenceRequest';
import { internalUrls } from '../../../shared/utilities/urls';
import { useAppStore } from '../../../store';
import {
  SaveYourInfoModal,
  SaveYourInfoResult
} from '../components/SaveYourInfoModal/SaveYourInfoModal';
import { useGlobalCommand } from '../../../shared/commands/useGlobalCommand';

export function useGoToRecommendation() {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { getRightRecommendationPageUri } = useGlobalCommand();
  const store = useAppStore()

  async function goToRecommendationCommand() {
      const recommendationRightRoute = getRightRecommendationPageUri();

      // toDo add new condition to redirect to right reco page below the tenant id

      const userType = store.getState().authentication.userType;
      if (userType === UserType.Advisor) {
        navigate(recommendationRightRoute);
      }
  
      // The user is already registered, let him proceed to the recommendation
      if (userType === UserType.User) {
        navigate(recommendationRightRoute);
      }
  
      // The user is still a prospect, give him the choice between registering and Continue without saving data
      if (userType === UserType.Prospect) {
        const result = await showModal<SaveYourInfoResult>(<SaveYourInfoModal />);
  
        if (result.outcome === ModalOutcome.Cancel) {
          return;
        }
  
        if (result.value === SaveYourInfoResult.Register) {
          navigate(internalUrls.registration.registration);
        } else {
          return;
        }
      }
  }

  return { goToRecommendationCommand };
}
