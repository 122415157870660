import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { i18n } from '../../i18n';
import styles from './RegistrationResendPage.module.scss';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import classNames from 'classnames';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { useResendValidationEmail } from 'modules/registration/commands/resendValidationEmailCommand';
import { FormEvent, useEffect, useState } from 'react';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { Button, ButtonStyles, PageTitle, Container, SvgWrapperComponent, TextInput } from '@ifs/libs';

export function RegistrationResendPage() {
  const [email, setEmail] = useState<string>();
  const searchParams = useSearchParams();
  const { resendValidationEmailCommand } = useResendValidationEmail();

  useEffect(() => {
    setEmail(searchParams.get('email')!);
  }, []);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    resendValidationEmailCommand(email!);
  }

  return (
    <Container className={ModuleStyles.Grid}>
      <PageTitle>{i18n.registrationResendPage.title}</PageTitle>
      <h2 className={ModuleStyles.Subtitle}>{i18n.registrationResendPage.subtitle}</h2>

      <SvgWrapperComponent ariaHidden={true}>
        <SharedAssets.Mailbox className={ModuleStyles.Mailbox} />
      </SvgWrapperComponent>

      <form className={ModuleStyles.FormGridCentered} noValidate onSubmit={onSubmit}>
        <TextInput type="email" disabled={true} value={email} onChange={(e) => {}} />

        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
        >
          {i18n.registrationResendPage.resend}
        </Button>
      </form>

      <BackgroundImage />
    </Container>
  );
}
