import { i18n } from "../../../../../modules/recommendation/i18n";
import { useAppSelector } from "store";
import { useResetParametersCommand } from "../../../../../modules/recommendation/commands/resetParametersCommand";
import { ParametersCard } from "../../../../../modules/recommendation/components/ParametersCard/ParametersCard";
import {
  Button,
  ButtonStyles,
  Container,
  PageTitle,
} from "../../../../../shared";
import styles from "./AdvancedRecommendationPage.module.scss";
import { globali18n } from "../../../../../i18n";
import { useAnalytics } from "../../../../../shared/commands/useAnalytics";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function AdvancedRecommendationPage() {
  const navigate = useNavigate();
  const model = useAppSelector((s) => s.recommendation.model)!;
  const parameters = useAppSelector((s) => s.recommendation.parameters)!;
  const { resetParametersCommand } = useResetParametersCommand();
  const { recommendationEvent, resetRecommendationEvent } =
    useAnalytics();

  const t = i18n.recommendationPage;

  useEffect(()=> {    
    recommendationEvent(parameters, model, "saving_solution_personalization");
  },[])

  async function reset() {
    resetRecommendationEvent();
    await resetParametersCommand();
  }

  function back() {
    navigate(-1);
  }

  return (
    <Container className={styles.Container}>
      <Button
        onClick={back}
        className={classNames(styles.Back, ButtonStyles.Back)}
      >
        &lsaquo; {globali18n.labels.back}
      </Button>
      <PageTitle>{t.advancedSummary.title}</PageTitle>
      <span>Ajustez vos versements et votre âge de départ à la retraite afin d’atteindre votre objectif de capital complémentaire.</span>

      <section className={styles.ParametersCard}>
        <ParametersCard onReset={reset}></ParametersCard>
      </section>
    </Container>
  );
}
