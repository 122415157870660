import axios from 'axios';
import { apiUrls } from '../apiUrls';

export interface StepsCompletedResult {
    customerId: string;
    stepName: string;
}

export async function getStepsCompletedRequest(
  customerid?: string,
): Promise<StepsCompletedResult[]> {

  const apiUrl = apiUrls(customerid).completion.getSteps;
  const response = await axios.get<{results: StepsCompletedResult[]}>(apiUrl);
  const data = response.data;

  return data.results || [];
}
