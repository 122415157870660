import axios from 'axios';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';
import { apiUrls } from '../../../shared/apiUrls';
import { ValidatedLegalPensionModel, ValidatedLegalPensionPaginatedModel } from '../models/validation/ValidatedLegalPensionModel';

export async function getLastValidatedRequest(customerid?: string): Promise<ValidatedLegalPensionModel | null> {
  const apiUrl = apiUrls(customerid).legalPension.validations;
  
  const response = await axios.get<ValidatedLegalPensionPaginatedModel>(
    apiUrl, {
      params: { limit: 1, sortBy: "CreatedAt Desc" },
      ...axiosRequestValidateStatusConfig
    }
  );

  if (response.status === 204, response.status === 404) {
    return null;
  }
  const data = response.data;

  return data?.results[0] || null;
}
