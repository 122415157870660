import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { ComputationInputModel } from '../models/ComputationInputModel';
import { ComputationOutputModel } from '../models/ComputationOutputModel';

export async function computeRequest(
  model: ComputationInputModel,
  customerid?: string
): Promise<ComputationOutputModel> {
  const apiUrl = apiUrls(customerid).legalPension.computations
  const response = await axios.post<ComputationOutputModel>(
    apiUrl,
    model
  );

  const data = response.data;

  return data;
}
