import { useLogoutCommand } from '@ifs/libs';
import {
  Drawer as KendoDrawer,
  DrawerContent as KendoDrawerContent,
  DrawerItemProps as KendoDrawerItemProps
} from '@progress/kendo-react-layout';
import { PropsWithChildren, SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { i18n } from '../../../shared/i18n';
import styles from './Drawer.module.scss';
import { useHandleMeetingLinkClickedCommand } from '@ifs/libs/src/shared';
import { useMenuItems } from '@ifs/libs/src/shared/utilities/useMenuItems';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { createFocusTrap } from 'focus-trap';
import { drawerFocusTrap } from '@ifs/libs/src/shared/components/FocusTrap/default';

interface DrawerProps {
  open?: boolean;
  onOverlayClick: () => void;
}

enum MenuItem {
  Dashboard,
  LogIn,
  LogOut,
  Register,
  Profile,
  RendezVous
}

export function Drawer(props: PropsWithChildren<DrawerProps>) {


  const menuItems = useMenuItems();
  const navigate = useNavigate();
  const { handleLogoutCommand } = useLogoutCommand();
  const { handleMeetingLinkClickedCommand } = useHandleMeetingLinkClickedCommand();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listOfActions = [
    'dashboardAction',
    'loginAction',
    'logoutAction',
    'registerAction',
    'profileAction',
    'meetingAction'
  ];

  useEffect(() => {
    if (props.open) {
      drawerFocusTrap('activate');
      document.addEventListener('keydown', (e: any) => {
        if(e.key === "Enter") {
          const classtNames = e.target!.id;
          select(listOfActions.indexOf(classtNames));
        }
      });
    } else {
      drawerFocusTrap('deactivate');
    }
  }, [listOfActions, props.open, select]);

  const handlers = {
    [MenuItem.Dashboard]: () => navigate(internalUrls.dashboard),
    [MenuItem.LogIn]: () => navigate(internalUrls.authentication.login),
    [MenuItem.LogOut]: () => handleLogoutCommand(),
    [MenuItem.Register]: () => navigate(internalUrls.registration.registration),
    [MenuItem.Profile]: () => navigate(internalUrls.profile.profile),
    [MenuItem.RendezVous]: () => handleMeetingLinkClickedCommand()
  };
  const items: KendoDrawerItemProps[] = [];

  if (menuItems.canGoToDashboard) {
    items.push({
      text: i18n.header.dashboard,
      index: MenuItem.Dashboard,
      id: 'dashboardAction'
    });
  }

  if (menuItems.canLogIn) {
    items.push({
      text: i18n.header.logIn,
      index: MenuItem.LogIn,
      id: 'loginAction'
    });
  }

  if (menuItems.canRegister) {
    items.push({
      text: i18n.header.register,
      index: MenuItem.Register,
      id: 'registerAction'
    });
  }

  if (menuItems.canGoToProfile) {
    items.push({
      text: i18n.header.profile,
      index: MenuItem.Profile,
      id: 'profileAction'
    });
  }

  if (menuItems.canClickOnMeetingLink) {
    items.push({
      text: i18n.header.rendezVous,
      index: MenuItem.RendezVous,
      id: 'meetingAction'
    });
  }

  if (menuItems.canLogOut) {
    items.push({
      text: i18n.header.logOut,
      index: MenuItem.LogOut,
      id: 'logoutAction'
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function select(index: number) {
    const handler = handlers[index as MenuItem];
    handler();
    props.onOverlayClick();
  }

  return (
    <KendoDrawer
      expanded={props.open}
      position={'end'}
      mode={'overlay'}
      animation={{ duration: 200 }}
      items={props.open ? items : []}
      onOverlayClick={props.onOverlayClick}
      onSelect={(e) => select(e.itemIndex)}
      className={styles.Drawer}
    >
        <KendoDrawerContent className={styles.DrawerContent}>{props.children}</KendoDrawerContent>
  
    </KendoDrawer>
  );
}
