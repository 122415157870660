import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { globali18n } from '../../../i18n';
import { EditModal, ModalOutcome, useModal } from '../../../shared';
import { useAppDispatch, useAppStore } from '../../../store';
import { setGamifiedValue } from '../pensionNeedSlice';
import { EComputeCategoryId } from '../models/computation/ComputeCategory.enum';

const maxGamificationValue = 100000;

export function useGamifyValueCommand() {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { answerNeedsEvent } = useAnalytics()
  
  async function gamifyValueCommand(propertyName: EComputeCategoryId, label: string) {
    const gamifiedValues = store.getState().pensionNeed.gamifiedValues!;
    const gamifiedValueFind = gamifiedValues.find((cat)=> cat.categoryId === propertyName)

    const result = await showModal<number>(
      <EditModal
        title={label}
        value={gamifiedValueFind!.value ?? null}
        min={0}
        minErrorMessage={globali18n.errors.min(0)}
        max={maxGamificationValue}
        maxErrorMessage={globali18n.errors.max(maxGamificationValue)}
      />
    );

    if (result.outcome === ModalOutcome.Ok) {
      dispatch(setGamifiedValue({ propertyName, value: result.value! }));
      document.getElementById(label + '-amount')!.focus();
      const value = `${label}: ${result.value!}`
      answerNeedsEvent(value,
      `${label} new value`
      )

    }

    if(result.outcome === ModalOutcome.Cancel) {
      document.getElementById(label + '-amount')!.focus();
    }
  }

  return { gamifyValueCommand };
}
