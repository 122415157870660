export enum Horizon {
    LowEfficiency = "LowRiskAndReturn",
    LimitedRiskOfLoss = "MediumRiskAndReturn",
    HigherRiskOfLoss = "HighRiskAndReturn",
    SubstantialRiskOfLoss = "VeryHighRiskAndReturn"
}
export enum Fluctuation {
    Limited = "LowTolerance",
    Important = "HighTolerance"
}
export enum LossOfValue {
    NoLoss = "Never",
    LessThanTenPercent = "Below10Percent",
    BetweenTenAndTwentyPercent = "Between10And20Percent",
    BetweenTwentyAndThirtyPercent = "Between20And30Percent",
    MoreThanThirtyPercent = "Above30Percent"
}

export enum LossReaction {
    ReInvest = "MoreInvestments",
    SellAll = "SellAllAndReinvestWithLessRisk",
    SellPart = "SellAPartAndReinvestWithLessRisk",
    NoChange = "DoNothing"
}

export enum Scenario {
    Conservateur = "Conservateur",
    Prudent = "Prudent",
    Equilibre = "Equilibre",
    Dynamique = "Dynamique",
    Offensif = "Offensif"
}

export enum TrueFalseDontKnow {
    True = "True",
    False =  "False",
    IDontKnow = "DontKnow"
}

export enum FinancialInstrumentsOrMarkets {
    Actions = "Actions",
    Obligations = "Obligations",
    OPC = "OPC",
    ASVInEuro = "ASVInEuro",
    ASVUnitAccount = "ASVUnitAccount",
    OPCI = "OPCI",
    SCPI = "SCPI",
    IDontKnow = "DontKnow",
    NoneOfTheseAnswers = "NoneOfTheseAnswers"
}

export const financialInstrumentsOrMarkets = [
    FinancialInstrumentsOrMarkets.Actions,
    FinancialInstrumentsOrMarkets.Obligations,
    FinancialInstrumentsOrMarkets.OPC,
    FinancialInstrumentsOrMarkets.ASVInEuro,
    FinancialInstrumentsOrMarkets.ASVUnitAccount,
    FinancialInstrumentsOrMarkets.OPCI,
    FinancialInstrumentsOrMarkets.SCPI,
    FinancialInstrumentsOrMarkets.IDontKnow,
    FinancialInstrumentsOrMarkets.NoneOfTheseAnswers
]

