import { useLoader } from "../../../shared";
import { ParametersInputModel } from "../models/RecommendationModel";
import { computeRecommendationRequest } from "../requests/computeRecommendationRequest";
import { useParams } from "react-router-dom";
import { apiUrls } from "../../../shared/apiUrls";

export function useComputeRecommendationCommand() {
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function computeRecommendationCommand(model: ParametersInputModel) {
    return await showLoaderWhile(
      async () => {
        const result = await computeRecommendationRequest(model, customerid);
        return result;
      },
      {
        key: "useComputeRecommendationCommand",
        value: `API POST - ${apiUrls(customerid).recommendation.compute}`,
      }
    );
  }

  return { computeRecommendationCommand };
}
