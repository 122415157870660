import classNames, { Argument } from 'classnames';
import { CSSProperties, MouseEventHandler } from 'react';
import styles from './Button.module.scss';
import ChevronIcon from './images/chevron.svg';

interface ButtonProps {
  className?: Argument;
  onClick?: MouseEventHandler;
  type?: 'submit' | 'reset' | 'button';
  style?: CSSProperties;
  selected?: boolean;
  autoFocus?: boolean;
  chevronIcon?: boolean;
  id?: string;
  ariaLabel?: string;
  disabled?: boolean;
}

export function Button(props: React.PropsWithChildren<ButtonProps>) {

  return (
    <button
    disabled={props.disabled}
    id={props.id}
    aria-label={props.ariaLabel}
      onClick={props.onClick}
      className={classNames(styles.Button, { [styles.Selected]: props.selected }, props.className)}
      type={props.type}
      style={{ ...props.style }}
      autoFocus={props.autoFocus}
    >
      <span className={styles.ButtonContent}>
        {props.children}
        {props.chevronIcon && <img src={ChevronIcon} alt="chevronIcon" className={styles.ButtonChevron}/>}
      </span>
    </button>
  );
}
