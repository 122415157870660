import { ReactComponent as Building } from './housing/building.svg';
import { ReactComponent as House } from './housing/house.svg';
import { ReactComponent as IleDeFrance } from './housing/ile-de-france.svg';
import { ReactComponent as Key } from './housing/key.svg';
import { ReactComponent as LargeCity } from './housing/large-city.svg';
import { ReactComponent as MediumCity } from './housing/medium-city.svg';
import { ReactComponent as Mountain } from './housing/mountain.svg';
import { ReactComponent as Ship } from './housing/ship.svg';
import { ReactComponent as SmallCity } from './housing/small-city.svg';
import { ReactComponent as Credit } from './housing/credit.svg';

import { ReactComponent as Bike } from './transport/bike.svg';
import { ReactComponent as Car } from './transport/car.svg';
import { ReactComponent as Motorbike } from './transport/motorbike.svg';
import { ReactComponent as PublicTransportation } from './transport/public.svg';

import { ReactComponent as Money1 } from './money/money-1.svg';
import { ReactComponent as Money2 } from './money/money-2.svg';
import { ReactComponent as Money3 } from './money/money-3.svg';

import backgroundImage from './background.svg';

export { backgroundImage };

export const housingIcon = {
  Building,
  House,
  IleDeFrance,
  Key,
  LargeCity,
  MediumCity,
  Mountain,
  Ship,
  SmallCity,
  Credit
};

export const transportIcon = {
  Bike,
  Car,
  Motorbike,
  PublicTransportation
};

export const moneyIcon = { Money1, Money2, Money3 };