import { useParams } from "react-router-dom";

const authentication = "/auth";
const dashboard = "/";
const pensionNeed = "/pension-need";
const legalPension = "/legal-pension";
const taxation = "/taxation";
const lifeProjects = "/life-projects";
const registration = "/register";
const recommendation = "/recommendation";
const patrimony = "/patrimony";
const profile = "/profile";
const needsStartRoute = "/pension-need";
const legalStartRoute = "/legal-pension";
const taxationStartRoute = "/taxation";
const investorProfile = "/investor-profile";
const dashboardLanding = "/landing";

export function useAppUrls() {
  const { customerid } = useParams();
  return getAppUrls(customerid);
}

export function getAppUrls(customerid?: string) {
  const customerPart = customerid ? `/flow/${customerid}` : "";
  const dashboardPath = customerid ? `/flow/${customerid}/dashboard` : "/";
  const reportPath = customerid
    ? `/api/report/customer/${customerid}/generate`
    : "/api/report/generate";
  const urls = {
    register: `/register`,
    dashboardLanding: dashboardLanding,
    taxation: {
      landing: `${customerPart}${taxation}`,
      taxAvailable: `${customerPart}${taxation}/tax-available`,
      taxRate: `${customerPart}${taxation}/tax-rate`,
      summary: `${customerPart}${taxation}/summary`,
    },
    pensionNeed: {
      landing: `${customerPart}${pensionNeed}`,
      persona: `${customerPart}${pensionNeed}/personas`,
      birthdate: `${customerPart}${pensionNeed}/birthdate`,
      housing: {
        locationType: `${customerPart}${pensionNeed}/housing/location-type`,
      },
      summary: `${customerPart}${pensionNeed}/summary`,
    },
    dashboard: dashboardPath,
    legalPension: {
      landing: `${customerPart}${legalPension}`,
      birthDate: `${customerPart}${legalPension}/birthdate`,
      gender: `${customerPart}${legalPension}/gender`,
      children: `${customerPart}${legalPension}/children`,
      careerStart: `${customerPart}${legalPension}/career-start`,
      status: `${customerPart}${legalPension}/status`,
      currentRevenue: `${customerPart}${legalPension}/current-revenue`,
      currentProgression: `${customerPart}${legalPension}/current-progression`,
      futureProgression: `${customerPart}${legalPension}/future-progression`,
      summary: `${customerPart}${legalPension}/summary`,
    },
    patrimony: {
      landing: `${customerPart}${patrimony}`,
      declaration: `${customerPart}${patrimony}/declaration`,
      projection: `${customerPart}${patrimony}/projection`,
      summary: `${customerPart}${patrimony}/summary`,
    },
    lifeProjects: {
      landing: `${customerPart}${lifeProjects}`,
      projects: `${customerPart}${lifeProjects}/projects`,
    },
    investorProfile: {
      landing: `${customerPart}${investorProfile}`,
      riskProfile: {
        horizon: `${customerPart}${investorProfile}/risk-profile/horizon`,
        fluctuation: `${customerPart}${investorProfile}/risk-profile/fluctuation`,
        lossOfValue: `${customerPart}${investorProfile}/risk-profile/loss-of-value`,
        lossReaction: `${customerPart}${investorProfile}/risk-profile/loss-reaction`,
        scenario: `${customerPart}${investorProfile}/risk-profile/scenario`,
        summary: `${customerPart}${investorProfile}/risk-profile/summary`,
      },
      financialProfile: {
        marketFluctuation: `${customerPart}${investorProfile}/financial-profile/market-fluctuation`,
        obligation: `${customerPart}${investorProfile}/financial-profile/obligation`,
        opc: `${customerPart}${investorProfile}/financial-profile/opc`,
        opciOrScpi: `${customerPart}${investorProfile}/financial-profile/opci-or-scpi`,
        financialSupport: `${customerPart}${investorProfile}/financial-profile/financial-support`,
        financialInstrumentsOrMarkets: `${customerPart}${investorProfile}/financial-profile/financial-instruments-or-markets`,
        summary: `${customerPart}${investorProfile}/financial-profile/summary`,
      },
      summary: `${customerPart}${investorProfile}/summary`,
    },
    recommendation: `${customerPart}${recommendation}`,
    recommendationProducts: `${customerPart}${recommendation}/products`,
    advancedRecommendation: `${customerPart}${recommendation}/advanced`,
    reportUrl: reportPath,
  };

  return urls;
}

export const prospectEntryUrls = [
  needsStartRoute,
  legalStartRoute,
  taxationStartRoute,
];

export const reportEntryUrl = "/recommendation/report";
export const reportUrl = "/api/report/generate";

export const internalUrls = {
  dashboard,

  authentication: {
    login: `${authentication}/login`,
    reset: `${authentication}/password-reset`,
    resetEmail: `${authentication}/password-reset/email`,
    resetReset: `${authentication}/password-reset/reset`,
    logOut: `${authentication}/logout`,
    ssoLogin: `${authentication}/authenticate`,
  },
  tenantSelection: "/tenant-selection",
  checkCustomer: "/check-customer",
  registration: {
    registration: `${registration}`,
    email: `${registration}/email`,
    emailResend: `${registration}/email/resend`,
  },

  needsStartRoute,
  legalStartRoute,

  pensionNeed: {
    landing: `${pensionNeed}`,
    persona: `${pensionNeed}/personas`,
    housing: {
      locationType: `${pensionNeed}/housing/location-type`,
    },
    summary: `${pensionNeed}/summary`,
  },

  legalPension: {
    landing: `${legalPension}`,
    birthDate: `${legalPension}/birthdate`,
    gender: `${legalPension}/gender`,
    children: `${legalPension}/children`,
    careerStart: `${legalPension}/career-start`,
    status: `${legalPension}/status`,
    currentRevenue: `${legalPension}/current-revenue`,
    currentProgression: `${legalPension}/current-progression`,
    futureProgression: `${legalPension}/future-progression`,
    summary: `${legalPension}/summary`,
  },

  lifeProjects: {
    landing: `${lifeProjects}`,
    projects: `${lifeProjects}/projects`,
  },

  taxation: {
    landing: `${taxation}`,
    taxRate: `${taxation}/tax-rate`,
    taxAvailable: `${taxation}/tax-available`,
    summary: `${taxation}/summary`,
  },

  patrimony: {
    landing: `${patrimony}`,
    declaration: `${patrimony}/declaration`,
    projection: `${patrimony}/projection`,
    summary: `${patrimony}/summary`,
  },

  recommendation: {
    recommendation,
    products: `${recommendation}/products`
  },

  appointment: "/appointment",
  technicalError: "/technical-error",

  profile: {
    profile: `${profile}`,
  },

  reportEntryUrl,
  reportUrl,
};
