import { i18n } from '../../../i18n';
import { moneyIcon } from '../../../images';
import { DefaultScale } from '../../../models/DefaultScale';
import { TransportType } from '../../../models/transport/transport-type.enum';
import {
  setTransportPublicDefinedValue,
  setTransportPublicScaleValue
} from '../../../pensionNeedSlice';
import { nextTransportAction } from '../../../routing/nextTransportRoute';
import { createPensionNeedPage } from '../../createPensionNeedPage';

export const TransportPublicPage = createPensionNeedPage<DefaultScale>({
  scaleValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.PublicTransportation)?.scale ?? null,
  definedValueSelector: (q) =>
    q.transport.find((c) => c.typeId === TransportType.PublicTransportation)?.definedValue ?? null,
  i18nFn: (i18n) => i18n.transportPublicPage,
  scaleValueReducer: setTransportPublicScaleValue,
  definedValueReducer: setTransportPublicDefinedValue,
  answers: [
    {
      icon: moneyIcon.Money1,
      label: i18n.transportPublicPage.answer1,
      value: DefaultScale.NotAtAll
    },
    {
      icon: moneyIcon.Money2,
      label: i18n.transportPublicPage.answer2,
      value: DefaultScale.ALittle
    },
    { icon: moneyIcon.Money3, label: i18n.transportPublicPage.answer3, value: DefaultScale.ALot }
  ],
  nextPage: (_, __, questionnaire) =>
    nextTransportAction(TransportType.PublicTransportation, questionnaire.transportTypes!)
});
