import classNames from "classnames";
import { i18n } from "../../../../modules/recommendation/i18n";
import { Button, ButtonStyles, SvgWrapperComponent } from "../../../../shared";
import styles from "./Footer.module.scss";
import { assets as SharedAssets } from '../../../../shared/Assets';

type FooterProps = {
  buttonStyleUnfill: boolean;
  buttonCustomWithClass?: string;
  goToReport: () => void;
};

export function RecommendationFooter(props: FooterProps) {
  const t = i18n.recommendationPage;

  return (
    <section className={styles.Information}>
        <Button
          onClick={props.goToReport}
          className={classNames(props.buttonStyleUnfill ? ButtonStyles.Unfill : ButtonStyles.Link, !props.buttonStyleUnfill && styles.DownloadLink, props.buttonCustomWithClass)}
        >
          <SvgWrapperComponent ariaHidden={true}>
            <SharedAssets.DownloadIcon />
          </SvgWrapperComponent>{" "}
          {t.appointmentGroup.download}
        </Button>
    </section>
  );
}
