import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { SynthesisModel } from "../models/SynthesisModel";

export async function getSynthesisRequest(customerid?: string): Promise<SynthesisModel> {
    const apiUrl = apiUrls(customerid).patrimony.synthesis

    const response = await axios.post<SynthesisModel>(apiUrl);

    return response.data;
}