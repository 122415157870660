import { BackgroundImage } from "../../shared/components/BackgroundImage/images";
import { assets as Assets } from "../../shared/components/BackgroundImage/Assets";
import {  
    LockIcon,
    PatrimonyIconUncompleted,
    PatrimonyIconLock,
    PatrimonyIcon,
    TaxationIconUncompleted,
    TaxationIconLock,
    TaxationIcon,
    LifeProjectsIconUncompleted,
    LifeProjectsIconLock,
    LifeProjectsIcon,
    LegalPensionIconUncompleted,
    LegalPensionLock,
    LegalPensionIcon,
    PensionNeedIconLock,
    PensionNeedIconUncompleted,
    PensionNeedIcon,
    InvestorProfileIconLock,
    InvestorProfileIconUncompleted,
    InvestorProfileIcon
} from "./images/modules/ModuleIcons";

export const assets = {
    BackgroundImage : BackgroundImage,
    LockIcon: LockIcon,
    PatrimonyIconUncompleted: PatrimonyIconUncompleted,
    PatrimonyIconLock: PatrimonyIconLock,
    PatrimonyIcon: PatrimonyIcon,
    TaxationIconUncompleted: TaxationIconUncompleted,
    TaxationIconLock: TaxationIconLock,
    TaxationIcon: TaxationIcon,
    LifeProjectsIconUncompleted: LifeProjectsIconUncompleted,
    LifeProjectsIconLock: LifeProjectsIconLock,
    LifeProjectsIcon: LifeProjectsIcon,
    LegalPensionIconUncompleted: LegalPensionIconUncompleted,
    LegalPensionLock: LegalPensionLock,
    LegalPensionIcon: LegalPensionIcon,
    PensionNeedIconLock: PensionNeedIconLock,
    PensionNeedIconUncompleted: PensionNeedIconUncompleted,
    PensionNeedIcon: PensionNeedIcon,
    InvestorProfileIconLock: InvestorProfileIconLock,
    InvestorProfileIconUncompleted: InvestorProfileIconUncompleted,
    InvestorProfileIcon: InvestorProfileIcon,
    MeetingImage: Assets.MeetingImage
}