import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { QuestionnaireSessionRiskProfile } from "../models/QuestionnaireSessionModel";

export async function getQuestionnaireSessionRiskProfile(customerId: string | undefined, riskProfileIdentifier: string): Promise<QuestionnaireSessionRiskProfile> {
  const apiUrl =
    apiUrls(customerId).investorProfile.questionnaireSession.getQuestionnaireSessionRiskProfile(riskProfileIdentifier);
  const response = await axios.get<QuestionnaireSessionRiskProfile>(apiUrl);

  const data = response.data;

  return data;
}
