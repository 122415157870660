import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { LifeProjectsModel } from '../models/LifeProjectsModel';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';

export async function getProjectsRequest(customerid?: string): Promise<LifeProjectsModel | null> {
  const apiUrl = apiUrls(customerid).lifeProjects.get;
  
  const response = await axios.get<LifeProjectsModel>(apiUrl, {...axiosRequestValidateStatusConfig});

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  const data = response.data;

  return data;
}
