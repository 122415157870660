import { CustomerInformationModel, } from 'modules/profile/models/AccountPreference';
import { getAccountPreferenceRequest } from 'modules/profile/requests/getAccountPreferenceRequest';
import { useEffect, useState } from 'react';
import { useLoader } from '@ifs/libs';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';

export function useResolver() {
  const [preferences, setPreferences] = useState<CustomerInformationModel>();
  const { showLoaderWhile } = useLoader();

  useEffect(() => {
    showLoaderWhile(async () => {
      const preferences = await getAccountPreferenceRequest();
      if(preferences){
        setPreferences(preferences);
      }
    }, {key: "profileResolver", value:`API GET - ${apiUrls().customers.get}}`});
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { preferences };
}
