import styles from "./i18n.module.scss";
import {
  FinancialInstrumentsOrMarkets,
  Fluctuation,
  Horizon,
  LossOfValue,
  LossReaction,
  Scenario,
  TrueFalseDontKnow,
} from "./models/RiskProfileEnum";

export const i18n = {
  module: "Votre profil investisseur",
  landingPage: {
    title: "Votre profil investisseur",
    content: (
      <div>
        <p>
          Le questionnaire qui suit protège vos intérêts. Il est destiné à
          mesurer votre appétence en matière de risque et votre connaissance des
          produits financiers afin de vous proposer les produits les mieux
          adaptés à votre besoin.
        </p>
        <p>Répondez le plus naturellement possible.</p>
      </div>
    ),
  },
  financialProfileSummaryPage: {
    yourCapital: "Votre capital complémentaire estimé à",
    title: "Merci ! Votre profil est maintenant complet.",
    subTitle: "Profil investisseur",
    scenario: {
      [Scenario.Conservateur]: "Conservateur",
      [Scenario.Prudent]: "Prudent",
      [Scenario.Equilibre]: "Équilibré",
      [Scenario.Dynamique]: "Dynamique",
      [Scenario.Offensif]: "Offensif",
    },
    restart: "Recommencer le questionnaire",
    riskLevel: "Niveau de risque :",
    riskLevelRating: (level: string, maxLevel: string) => (
      <div>
        {level} sur {maxLevel}
      </div>
    ),
    description: (
      <div>
        <p>
          Vous pourrez rejouer ce questionnaire à tout moment depuis votre
          diagnostic retraite.
        </p>
        <p>
          Votre profil investisseur est amené à être affiné avec votre
          conseiller au moment de la contractualisation.
        </p>
      </div>
    ),

    rightSection: {
      discover: "Découvrez comment obtenir un revenu complémentaire estimé à",
      month: "/mois",
      seeRecommendation: "Voir notre recommandation",
    },
  },

  riskProfile: {
    title: "Votre profil investisseur",
    horizonPage: {
      question: "Au terme de l’horizon d’investissement envisagé :",
      answer1:
        "Vous privilégiez la préservation de votre capital et ne tolérez qu’un risque de perte très limité : vous acceptez un rendement faible",
      answer2:
        "Vous recherchez une valorisation modérée de votre capital et tolérez un risque de perte limité",
      answer3:
        "Vous recherchez une valorisation élevée de votre capital et acceptez un risque de perte plus importante",
      answer4:
        "Vous recherchez une valorisation très élevée de votre capital et acceptez un risque de perte substantielle",
    },
    fluctuationPage: {
      question: "Pendant la durée envisagée de votre investissement :",
      answer1:
        "Vous ne tolérez qu’une fluctuation très limitée de la valorisation de votre capital",
      answer2:
        "Vous tolérez une fluctuation plus importante de la valorisation de votre capital",
    },
    lossOfValuePage: {
      question:
        "Avez-vous déjà effectué un investissement qui a connu une baisse de valeur ?",
      answer1: "Non",
      answer2: "Oui, moins de 10%",
      answer3: "Oui, entre 10% et 20%",
      answer4: "Oui, entre 20% et 30%",
      answer5: "Oui, plus de 30%",
    },
    lossReactionPage: {
      question:
        "Quelle est votre réaction quand un de vos investissements subit une perte de valeur ?",
      answer1:
        "Vous investissez de nouveau pour profiter des opportunités de marché",
      answer2:
        "Vous vendez tout pour éventuellement réinvestir dans des supports moins risqués",
      answer3:
        "Vous vendez seulement une partie pour éventuellement réinvestir sur des supports moins risqués	",
      answer4: "Vous ne changez rien",
    },
    scenarioPage: {
      question:
        "Parmi les scénarios de gains et pertes potentielles ci-dessous, lequel êtes-vous prêt à supporter ?",
      subTitle:
        "Voici les scénarios possibles compte tenu de vos réponses précédentes :",
      potentialGain: "Gain potentiel",
      potentialLoss: "Perte potentielle",
      legend: (
        <div className={styles.Legend}>
          <p>Comment lire ce graphique ?</p>
          <p>
            Par exemple, dans le troisième scénario, vous recherchez{" "}
            <span className={styles.Plus}>
              une valorisation élevée de votre capital (+30%)
            </span>{" "}
            et{" "}
            <span className={styles.Minus}>
              acceptez un risque de perte plus importante (-30%)
            </span>
            .
          </p>
        </div>
      ),
    },
    summaryPage: {
      title: "Merci pour ces premières réponses",
      description: (
        <div>
          <p>
            Nous avons bien pris en compte vos préférences en matière de risque.
            Nous allons maintenant mesurer vos connaissances financières.
          </p>
          <p>
            Répondez le plus naturellement possible pour nous aider à
            sélectionner les produits les mieux adaptés à votre besoin.
          </p>
        </div>
      ),
    },
  },
  financialProfile: {
    marketFluctuationPage: {
      question:
        "Une action correspond à une part du capital d’une entreprise. Son cours est soumis aux fluctuations des marchés financiers. Il est donc possible de perdre tout ou partie du capital investi.",
      answer1: "Oui",
      answer2: "Non",
      answer3: "Je ne sais pas",
    },
    obligationPage: {
      question:
        "Une obligation correspond à une part d’un emprunt émis par une entreprise. Son cours est soumis à l’évolution des taux d’intérêt. Il est donc possible de perdre tout ou partie du capital investi notamment en cas de vente avant l’échéance.",
      answer1: "Oui",
      answer2: "Non",
      answer3: "Je ne sais pas",
    },
    opcPage: {
      question:
        "Un Organisme de Placement Collectif (OPC) permet d’investir dans un portefeuille de plusieurs instruments financiers. Son cours est soumis aux fluctuations des marchés financiers. Il est donc possible de perdre tout ou partie du capital investi.",
      answer1: "Oui",
      answer2: "Non",
      answer3: "Je ne sais pas",
    },
    opciOrScpiPage: {
      question:
        "La valeur d’une OPCI ou SCPI est soumise aux fluctuations des marchés immobiliers, il est donc possible de perdre tout ou partie du capital investi.",
      answer1: "Oui",
      answer2: "Non",
      answer3: "Je ne sais pas",
    },
    financialSupportPage: {
      question:
        "Le fonds en euros est un support financier qui présente peu de risque de perte en capital.",
      answer1: "Oui",
      answer2: "Non",
      answer3: "Je ne sais pas",
    },
    financialInstrumentsOrMarketsPage: {
      question:
        "Sur lesquels de ces instruments ou marchés financiers avez-vous effectué, au cours des 3 dernières années une ou plusieurs transactions pour un montant investi d’au moins 1000 euros ? Vous pouvez sélectionner plusieurs réponses.",
      validate: "Valider",
      answers: {
        [FinancialInstrumentsOrMarkets.Actions]: "Actions",
        [FinancialInstrumentsOrMarkets.Obligations]: "Obligations",
        [FinancialInstrumentsOrMarkets.OPC]:
          "Organismes de Placements Collectifs (OPC)",
        [FinancialInstrumentsOrMarkets.ASVInEuro]:
          "Assurance vie avec fonds en euros",
        [FinancialInstrumentsOrMarkets.ASVUnitAccount]:
          "Assurance vie avec unités de compte",
        [FinancialInstrumentsOrMarkets.OPCI]:
          "Organismes de Placements Collectifs Immobiliers (OPCI)",
        [FinancialInstrumentsOrMarkets.SCPI]:
          "Sociétés Civiles de Placements Immobiliers (SCPI)",
        [FinancialInstrumentsOrMarkets.IDontKnow]: "Je ne sais pas",
        [FinancialInstrumentsOrMarkets.NoneOfTheseAnswers]:
          "Aucune de ces réponses",
      },
      atLeastOne: "Veuillez choisir au moins",
    },
  },

  formatedValues: {
    horizon: {
      [Horizon.LowEfficiency]:
        "Vous privilégiez la préservation de votre capital et ne tolérez qu’un risque de perte très limité : vous acceptez un rendement faible",
      [Horizon.LimitedRiskOfLoss]:
        "Vous recherchez une valorisation modérée de votre capital et tolérez un risque de perte limité",
      [Horizon.HigherRiskOfLoss]:
        "Vous recherchez une valorisation élevée de votre capital et acceptez un risque de perte plus importante",
      [Horizon.SubstantialRiskOfLoss]:
        "Vous recherchez une valorisation très élevée de votre capital et acceptez un risque de perte substantielle",
    },
    fuctuation: {
      [Fluctuation.Limited]:
        "Vous ne tolérez qu’une fluctuation très limitée de la valorisation de votre capital",
      [Fluctuation.Important]:
        "Vous tolérez une fluctuation plus importante de la valorisation de votre capital",
    },
    lossOfValuePage: {
      [LossOfValue.NoLoss]: "Non",
      [LossOfValue.LessThanTenPercent]: "Oui, moins de 10%",
      [LossOfValue.BetweenTenAndTwentyPercent]: "Oui, entre 10% et 20%",
      [LossOfValue.BetweenTwentyAndThirtyPercent]: "Oui, entre 20% et 30%",
      [LossOfValue.MoreThanThirtyPercent]: "Oui, plus de 30%",
    },
    lossReactionPage: {
      [LossReaction.ReInvest]:
        "Vous investissez de nouveau pour profiter des opportunités de marché",
      [LossReaction.SellAll]:
        "Vous vendez tout pour éventuellement réinvestir dans des supports moins risqués",
      [LossReaction.SellPart]:
        "Vous vendez seulement une partie pour éventuellement réinvestir sur des supports moins risqués	",
      [LossReaction.NoChange]: "Vous ne changez rien",
    },
    obligation: {
      [TrueFalseDontKnow.True]: "Oui",
      [TrueFalseDontKnow.False]: "Non",
      [TrueFalseDontKnow.IDontKnow]: "Je ne sais pas",
    },
  },
};
