import axios from "axios";
import { useDispatch } from "react-redux";
import { setTenantState } from "../slices/tenantSlice";
import { useAppStore } from "../../store";
import { TenantModelResponse } from "../models/TenantModel";
import { apiUrls } from "../../shared/apiUrls";

export function useGetTenantUrlsCommand() {
  const dispatch = useDispatch();
  const store = useAppStore();

  async function getTenantUrlsCommand(): Promise<TenantModelResponse> {
    const stateTenant = store.getState().tenant;
    if (stateTenant && Object.entries(stateTenant.Urls).length) {
      return stateTenant;
    }
    const response = await axios.get(apiUrls().tenants);
    dispatch(setTenantState(response.data));
    return response.data as TenantModelResponse;
  }

  return { getTenantUrlsCommand };
}
