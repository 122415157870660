export const i18n = {
  module: 'Vos projets de vie',

  categories : {
    declaration : "Déclaration"
  },

  landingPage: {
    pageTitle: 'Projets',
    'are-you-ready': 'Vous êtes prêt\u00A0?',
    title: 'Intégrez vos rêves à votre projet de retraite',
    content: <p>
      Faites la liste de vos envies et donnez-vous les moyens de les concrétiser !
    </p>
  },

  projectsPage: {
    pageTitle: 'Projets',
    instructions: 'Anticipez toutes vos dépenses futures',
    instructionsModal: {
      title: "Anticipez toutes vos dépenses futures",
      desc: <p>Etablissez un budget réaliste, épargnez régulièrement et investissez judicieusement pour assurer des économies durables jusqu’à l’âge de la retraite et concrétiser vos projets de vie </p>,
    },
    realEstateAssets: {
      title: 'Immobilier',
      description:
        'Achat de résidences principale ou secondaire'
    },
    pleasurePurchases: {
      title: 'Achat plaisir',
      description:
        'Un bateau, un camping-car, une voiture de collection'
    },
    family: {
      title: 'Famille',
      description:
        'Transmettre à vos proches'
    },
    anticipation: {
      title: 'Bien vieillir',
      description: 'Faire face à une perte d\'autonomie'
    },
    philanthropy: {
      title: 'Philanthropie & Mécénat',
      description: 'Soutenir des actions sociales, culturelles ou environnementales'
    },
    other: {
      title: 'Autres projets',
      description: 'Aller sur la lune\u00A0?'
    },

    editModal: {
      errors: {
        min: 'Le montant doit être supérieur ou égal à 0€.',
        max: 'Le montant ne peut excéder 9.999.999€.'
      }
    }
  }
};
