import { useDispatch } from 'react-redux';
import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { useLoader } from '../../../shared';
import {
  ParametersInputModel
} from '../models/RecommendationModel';
import { setModel, setParameters } from '../recommendationSlice';
import { outputToInputParameters } from '../utilities/functions';
import { useComputeRecommendationCommand } from './computeRecommendationCommand';
import { useParams } from 'react-router-dom';
import { getLastValidatedRequest } from '../../../modules/legal-pension/requests/getLastValidatedRequest';

export function useResetParametersCommand() {
  const { showLoaderWhile } = useLoader();
  const { computeRecommendationCommand } = useComputeRecommendationCommand();
  const dispatch = useDispatch();
  const { customerid } = useParams();
  // const { resetRecommendationParametersEvent } = useAnalytics();

  async function resetParametersCommand(): Promise<void> {
    return await showLoaderWhile(async () => {
      // To reset, we need to:
      // 1. Pass null values to compute
      // 2. Save the output parameters
      // 3. Use the compute output to completely reset the view
      const lastValidatedResponse = await getLastValidatedRequest(customerid);
      
      const value: ParametersInputModel = {
        overrideGamificationParameters: true,
        initialInvestmentAmount: null,
        recurringInvestmentAmount: null,
        retirementScenario: lastValidatedResponse!.chosenScenario
      };

      const computed = await computeRecommendationCommand(value);
      dispatch(setModel(computed));
      const inputParameters = outputToInputParameters(computed.parameters, lastValidatedResponse!.chosenScenario);
      dispatch(setParameters(inputParameters));

      // resetRecommendationParametersEvent();
    }, {key: "recommendation_useResetParametersCommand", value:`Handle reset recommendation parameters on file resetParametersCommand.ts`});
  }

  return { resetParametersCommand };
}
