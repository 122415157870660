import { SVGProps } from "react";

export interface EditIconProps extends SVGProps<SVGSVGElement> {
  color: string;
  filled?: boolean;
}

export function EditIcon(props: EditIconProps) {
  const { color, filled, ...rest } = props;

  if (!filled) {
    return (
              <svg
        style={{ cursor: "pointer" }}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M15.222 1.53585C16.2593 2.57324 16.2593 4.25518 15.222 5.29256L5.41128 15.1032C4.98573 15.5288 4.45252 15.8307 3.86867 15.9767L0.825771 16.7374C0.339398 16.859 -0.101161 16.4184 0.0204327 15.932L0.781158 12.8891C0.927121 12.3053 1.22902 11.7721 1.65457 11.3465L11.4652 1.53585C12.5026 0.498466 14.1846 0.498466 15.222 1.53585ZM10.526 4.35324L2.59374 12.2857C2.33841 12.541 2.15728 12.861 2.0697 13.2113L1.57742 15.1804L3.54654 14.6881C3.89685 14.6005 4.21677 14.4194 4.4721 14.1641L12.404 6.23132L10.526 4.35324ZM12.4044 2.47503L11.465 3.41421L13.3431 5.29228L14.2828 4.35339C14.8015 3.83469 14.8015 2.99372 14.2828 2.47503C13.7641 1.95634 12.9231 1.95634 12.4044 2.47503Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.25874 9.66707L1.46708 13.0837C1.43977 13.2086 1.4407 13.3381 1.46982 13.4625C1.49893 13.587 1.55549 13.7034 1.63535 13.8033C1.71522 13.9031 1.81638 13.9838 1.93144 14.0396C2.0465 14.0953 2.17256 14.1246 2.30041 14.1254C2.35998 14.1314 2.42001 14.1314 2.47958 14.1254L5.91708 13.3337L12.5171 6.75874L8.83374 3.08374L2.25874 9.66707Z"
        fill={color}
      />
      <path
        d="M14.5925 3.46649L12.1341 1.00816C11.9725 0.847352 11.7538 0.75708 11.5258 0.75708C11.2978 0.75708 11.0791 0.847352 10.9174 1.00816L9.55078 2.37482L13.2299 6.05399L14.5966 4.68733C14.6766 4.60694 14.7399 4.51158 14.783 4.40668C14.8261 4.30179 14.8481 4.18944 14.8477 4.07604C14.8473 3.96265 14.8246 3.85045 14.7808 3.74585C14.737 3.64126 14.673 3.54633 14.5925 3.46649Z"
        fill={color}
      />
    </svg>
  );
}
