import { useEffect } from "react";
import {
  NumericTextBox
} from "@progress/kendo-react-inputs";
import { Controller, useForm } from "react-hook-form";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { globali18n } from "../../../../i18n";
import {
  ButtonStyles,
  Container,
  Button,
  QuestionTitle,
  ValidationMessages,
  Card,
  useModal,
} from "../../../../shared";
import { ReactComponent as AlertCircleIcon } from "../../../../shared/images/AlertCircle.svg";
import { getValidationMessages } from "../../../../shared/utilities/getValidationMessages";
import { useAppSelector } from "../../../../store";
import { ModuleStyles } from "../../../legal-pension/styles/ModuleStyles";
import { useAnswerCommands } from "../../commands/answerCommand";
import { BackgroundImage } from "../../component/BackgroundImage/BackgroundImage";
import { i18n } from "../../i18n";
import styles from "./TaxAvailablePage.module.scss";
import classNames from "classnames";
import { TaxationModal } from "../../component/TaxationModal/TaxationModal";

interface FormData {
  declareAmount: number;
  optionalDeclareAmount: number;
}

export function TaxAvailablePage() {
  const { showModal } = useModal();
  const { answerTaxAvailables } = useAnswerCommands();
  const { answerTaxationQuestionEvent } = useAnalytics();

  const declareAmount = useAppSelector((s) => s.taxation.answers.firstIndividualMaximumTaxDeduction);
  const optionalDeclareAmount = useAppSelector((s) => s.taxation.answers.secondIndividualMaximumTaxDeduction);

 const componentDidMount = () => {
    const elements = document.querySelectorAll(".no-negative-number");
    const handleKeyDown = (e: any) => {
      if ((e as KeyboardEvent).key === "-") {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    elements.forEach((el) => {
      el.addEventListener("keydown", handleKeyDown);
    });

    return () => {
      elements.forEach((el) => {
        el.removeEventListener("keydown", handleKeyDown);
      });
    };
  };

  useEffect(componentDidMount, []);

  const defaultValue = {
    declareAmount,
    optionalDeclareAmount,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: defaultValue,
  });

  function onSubmit(value: FormData) {
    answerTaxAvailables(value);
    answerTaxationQuestionEvent(
      value,
      "1/3",
      i18n.taxAvailablePage.question,
      "tax-available"
    );
  }

  async function openModal() {
    await showModal(
      <TaxationModal
        title={i18n.taxAvailablePage.modal.title}
        content={i18n.taxAvailablePage.modal.content}
        isDeclarativeTaxationInformation={true}
      />
    );
  }

  const errorMessages = {
    required: globali18n.errors.required,
    min: i18n.errors.minMaxAmount,
    max: i18n.errors.minMaxAmount
  };
  
  const commonRulesDeclareAmount = {
    min: 0,
    max: 1000000,
  };

  const rulesDeclareAmount = {
    required: true,
  };

  return (
    <>
      <Container className={ModuleStyles.PageGrid}>
        <div className={styles.DescriptionSection}>
          <AlertCircleIcon className={styles.Icon} />
          <span className={styles.DescriptionWarning}>
            {i18n.taxAvailablePage.descriptionWarning}
          </span>
        </div>
        <QuestionTitle>{i18n.taxAvailablePage.question}</QuestionTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={styles.Card}>
            <div
              className={classNames(
                ModuleStyles.CardChildrenGrid,
                styles.InputSection
              )}
            >
              <div className={styles.InputRow}>
                <span className={styles.Label}>
                  {i18n.taxAvailablePage.labels.declaring1}
                </span>
                <div className={styles.InputWrapper}>
                  <Controller
                    control={control}
                    name="declareAmount"
                    rules={{...commonRulesDeclareAmount, ...rulesDeclareAmount}}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NumericTextBox
                        className="no-negative-number"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        spinners={false}
                        min={0}
                        format={{
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: "EUR",
                        }}
                      />
                    )}
                  />
                </div>

                <ValidationMessages
                  messages={getValidationMessages(
                    "declareAmount",
                    errors,
                    errorMessages
                  )}
                />
              </div>
              <div className={styles.InputRow}>
                <span className={styles.Label}>
                  {i18n.taxAvailablePage.labels.declaring2}
                </span>

                <div className={styles.InputWrapper}>
                  <Controller
                    control={control}
                    name="optionalDeclareAmount"
                    rules={commonRulesDeclareAmount}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NumericTextBox
                        className="no-negative-number"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        spinners={false}
                        min={0}
                        format={{
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: "EUR",
                        }}
                      />
                    )}
                  />
                </div>
                <ValidationMessages
                  messages={getValidationMessages(
                    "optionalDeclareAmount",
                    errors,
                    errorMessages
                  )}
                />
              </div>{" "}
              <div className={styles.ValidateBloc}>
                <Button
                  className={classNames(
                    ButtonStyles.PrimaryAction,
                    styles.ButtonBase
                  )}
                >
                  {globali18n.labels.validate}
                </Button>
              </div>
            </div>
          </Card>
        </form>
        <Button
          onClick={openModal}
          className={classNames(ButtonStyles.Link, styles.ModalLink)}
        >
          {i18n.taxAvailablePage.link}
        </Button>
      </Container>
      <BackgroundImage />
    </>
  );
}
