import axios from 'axios';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';
import { apiUrls } from '../../../shared/apiUrls';
import { ValidatedPensionNeedModel, ValidatedPensionNeedPaginatedModel } from '../models/request/validationRequest.model';

export async function getLastValidatedRequest(customerid?: string): Promise<ValidatedPensionNeedModel | null> {
  const apiUrl = apiUrls(customerid).pensionNeed.validations;
  
  const response = await axios.get<ValidatedPensionNeedPaginatedModel>(
    apiUrl, {
      params: { limit: 1, sortBy: "CreatedAt Desc" },
      ...axiosRequestValidateStatusConfig
    }
  );

  if (response.status === 204, response.status === 404) {
    return null;
  }

  const data = response.data;

  return data?.results[0] || null;
}
