import { parse } from "query-string";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useLoader } from "../../../shared";
import { delay } from "../../../shared/utilities/delay";
import { useAppUrls } from "../../../shared/utilities/urls";
import { useAppDispatch, useAppStore } from "../../../store";
import { apiUrls } from "../../../shared/apiUrls";
import {
  setFluctuation,
  setHorizon,
  setMarketFluctuation,
  setLossOfValue,
  setLossReaction,
  setScenario,
  setObligation,
  setOpc,
  setOpciOrScpi,
  setFinancialSupport,
  setFinancialInstrumentsOrMarkets,
  setRiskProfileIdentifier,
} from "../investorProfileSlice";
import {
  Fluctuation,
  Horizon,
  LossOfValue,
  LossReaction,
  Scenario,
  TrueFalseDontKnow,
} from "../models/RiskProfileEnum";
import { setQuestionnaireRiskProfileSessionAnswers } from "../requests/setQuestionnaireRiskProfileSessionAnswers";
import { setPreferedRiskProfile } from "../requests/setPreferedRiskProfile";
import { useAnalytics } from "../../../shared/commands/useAnalytics";
import { i18n } from "../i18n";
import { startQuestionnaireSession } from "../requests/startQuestionnaireSession";
import { EProfileType } from "../models/ProfileTypeEnum";

export function useAnswerCommands() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showLoaderWhile } = useLoader();
  const store = useAppStore();
  const appUrls = useAppUrls();
  const { customerid } = useParams();
  const { answerInvestorProfileEvent } = useAnalytics();

  const riskProfileId =
    store.getState().investorProfile.riskProfile.riskProfileIdentifier;

  async function next(nextRoute: string) {
    // Otherwise, go to next step
    await showLoaderWhile(
      async () => {
        navigate(nextRoute);
      },
      {
        key: "investorProfile_next",
        value: `Delay before navigate next app route ${nextRoute}`,
      }
    );
  }
  async function getRiskProfileId() {
    await showLoaderWhile(
      async () => {
        const riskProfileId = await startQuestionnaireSession(
          customerid,
          EProfileType.Risk
        );
        dispatch(setRiskProfileIdentifier(riskProfileId.id));       
      },
      {
        key: "investorProfile_riskProfile_startQuestionnaireSession",
        value: `API POST - ${
          apiUrls(customerid).investorProfile.questionnaireSession
            .startQuestionnaireSession
        }`,
      }
    );
  }
  async function answerHorizon(value: Horizon) {
    getRiskProfileId();
    dispatch(setHorizon(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.horizon[value],
      "1/11",
      i18n.riskProfile.horizonPage.question,
      "risk_profile"
    );
    await next(appUrls.investorProfile.riskProfile.fluctuation);
  }

  async function answerFluctuation(value: Fluctuation) {
    dispatch(setFluctuation(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.fuctuation[value],
      "2/11",
      i18n.riskProfile.fluctuationPage.question,
      "risk_profile"
    );
    await next(appUrls.investorProfile.riskProfile.lossOfValue);
  }

  async function answerLossOfValue(value: LossOfValue) {
    dispatch(setLossOfValue(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.lossOfValuePage[value],
      "3/11",
      i18n.riskProfile.lossOfValuePage.question,
      "risk_profile"
    );
    await next(appUrls.investorProfile.riskProfile.lossReaction);
  }

  async function answerLossReaction(value: LossReaction) {
    await showLoaderWhile(
      async () => {
        dispatch(setLossReaction(value));
        answerInvestorProfileEvent(
          i18n.formatedValues.lossReactionPage[value],
          "4/11",
          i18n.riskProfile.lossReactionPage.question,
          "risk_profile"
        );
        const answers = store.getState().investorProfile.riskProfile;
        await setQuestionnaireRiskProfileSessionAnswers(
          customerid,
          riskProfileId!,
          answers
        );

        navigate(appUrls.investorProfile.riskProfile.scenario);
      },
      {
        key: "investorProfile_riskProfile_setQuestionnaireRiskProfileSessionAnswers",
        value: `API PUT - ${apiUrls(
          customerid
        ).investorProfile.questionnaireSession.setQuestionnaireSessionAnswers(
          riskProfileId!
        )}`,
      }
    );
  }

  async function answerScenario(value: Scenario, identifier: string) {
    await showLoaderWhile(
      async () => {
        dispatch(setScenario(value));
        answerInvestorProfileEvent(
          value,
          "5/11",
          i18n.riskProfile.scenarioPage.question,
          "risk_profile"
        );
        const answer = store.getState().investorProfile.riskProfile.scenario;

        await setPreferedRiskProfile(customerid, identifier, answer);

        await next(appUrls.investorProfile.riskProfile.summary);
      },
      {
        key: "investorProfile_setPreferedRiskProfile",
        value: `API POST - ${apiUrls(
          customerid
        ).investorProfile.riskProfile.setPreferedRiskProfile(identifier)}`,
      }
    );
  }

  async function answerMarketFluctuation(value: TrueFalseDontKnow) {
    dispatch(setMarketFluctuation(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.obligation[value],
      "6/11",
      i18n.financialProfile.marketFluctuationPage.question,
      "financial_profile"
    );
    await next(appUrls.investorProfile.financialProfile.obligation);
  }

  async function answerObligation(value: TrueFalseDontKnow) {
    dispatch(setObligation(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.obligation[value],
      "7/11",
      i18n.financialProfile.obligationPage.question,
      "financial_profile"
    );
    await next(appUrls.investorProfile.financialProfile.opc);
  }

  async function answerOpc(value: TrueFalseDontKnow) {
    dispatch(setOpc(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.obligation[value],
      "8/11",
      i18n.financialProfile.opcPage.question,
      "financial_profile"
    );
    await next(appUrls.investorProfile.financialProfile.opciOrScpi);
  }

  async function answerOpciOrScpi(value: TrueFalseDontKnow) {
    dispatch(setOpciOrScpi(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.obligation[value],
      "9/11",
      i18n.financialProfile.opciOrScpiPage.question,
      "financial_profile"
    );
    await next(appUrls.investorProfile.financialProfile.financialSupport);
  }

  async function answerFinancialSupport(value: TrueFalseDontKnow) {
    dispatch(setFinancialSupport(value));
    answerInvestorProfileEvent(
      i18n.formatedValues.obligation[value],
      "10/11",
      i18n.financialProfile.financialSupportPage.question,
      "financial_profile"
    );
    await next(
      appUrls.investorProfile.financialProfile.financialInstrumentsOrMarkets
    );
  }

  return {
    answerHorizon,
    answerFluctuation,
    answerLossOfValue,
    answerLossReaction,
    answerScenario,
    answerMarketFluctuation,
    answerObligation,
    answerOpc,
    answerOpciOrScpi,
    answerFinancialSupport,
  };
}
