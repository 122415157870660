import { ThemeProvider } from "../../shared";
import { ProgressRoute } from "../../shared/routing/ProgressRoute";
import { ModuleColor } from "../../styles/colors";

import { i18n } from "./i18n";
import * as pages from "./pages";
import { useResolver } from "./routing/resolver";
import { Route, Routes } from "react-router-dom";
import { globali18n } from "../../i18n";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { PageRoute } from "../../shared/routing/PageRoute";

export function PensionNeedModule() {
  const { loading } = useResolver();

  if (loading) {
    return null;
  }

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 6,
  };

  const housingProps = {
    ...moduleProps,
    subtitle: i18n.categories.housing,
    step: 1,
  };

  const transportProps = {
    ...moduleProps,
    subtitle: i18n.categories.transport,
    step: 2,
  };

  const supplyProps = {
    ...moduleProps,
    subtitle: i18n.categories.supply,
    step: 3,
  };

  const othersProps = {
    ...moduleProps,
    subtitle: i18n.categories.other,
    step: 4,
  };

  const healthProps = {
    ...moduleProps,
    subtitle: i18n.categories.health,
    step: 5,
  };

  const summaryProps = {
    ...moduleProps,
    step: 6,
    subtitle: i18n.categories.synthesis,
  };

  const {
    LandingPage,
    PersonasPage,
    HousingLocationTypePage,
    HousingCitySizePage,
    HousingTypePage,
    HousingSizePage,
    TransportTypesPage,
    TransportCarPage,
    TransportPublicPage,
    TransportMotorbikePage,
    TransportBikePage,
    SupplyRestaurantPage,
    SupplyHomePage,
    OtherTravelsPage,
    OtherSportsPage,
    OtherHobbiesPage,
    HealthMedicalPage,
    HealthUnexpectedPage,
    BirthdatePage,
    SummaryPage,
    HousingTransactionTypePage,
  } = pages;

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor.PensionNeed,
        backgroundColorLigther: ModuleColor.PensionNeedLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <AnalyticsComponent
              page_title={i18n.landingPage.pageTitle}
              page_path={"/pension-need"}
              page_type={"homepage"}
              simulator_screen={"Landing Page"}
              simulator_step={""}
              simulator_type={i18n.module}
            >
              <PageRoute
                pageTitle={i18n.landingPage.pageTitle}
                element={<LandingPage />}
              />
            </AnalyticsComponent>
          }
        />
        <Route
          path={`/personas`}
          element={
            <ProgressRoute
              {...moduleProps}
              showSteps={false}
              hideProgressBar={true}
              pageTitle={i18n.personaPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.personaPage.pageTitle}
                  page_path={"/pension-need/personas"}
                  page_type={"simulateur"}
                  simulator_screen={"personae"}
                  simulator_step={""}
                  simulator_type={i18n.module}
                >
                  <PersonasPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/housing/location-type`}
          element={
            <ProgressRoute
              {...housingProps}
              duration={2}
              pageTitle={i18n.housingLocationTypePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.housingLocationTypePage.pageTitle}
                  page_path={"/pension-need/housing/location-type"}
                  page_type={"simulateur"}
                  simulator_screen={"housing"}
                  simulator_step={"1/6"}
                  simulator_type={i18n.module}
                >
                  <HousingLocationTypePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/housing/city-size`}
          element={
            <ProgressRoute
              {...housingProps}
              pageTitle={i18n.housingCitySizePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.housingCitySizePage.pageTitle}
                  page_path={"/pension-need/housing/city-size"}
                  page_type={"simulateur"}
                  simulator_screen={"housing"}
                  simulator_step={"1/6"}
                  simulator_type={i18n.module}
                >
                  <HousingCitySizePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/housing/transaction-type`}
          element={
            <ProgressRoute
              {...housingProps}
              pageTitle={i18n.housingTransactionTypePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.housingTransactionTypePage.pageTitle}
                  page_path={"/pension-need/housing/transaction-type"}
                  page_type={"simulateur"}
                  simulator_screen={"housing"}
                  simulator_step={"1/6"}
                  simulator_type={i18n.module}
                >
                  <HousingTransactionTypePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/housing/type`}
          element={
            <ProgressRoute
              {...housingProps}
              pageTitle={i18n.housingTypePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.housingTypePage.pageTitle}
                  page_path={"/pension-need/housing/type"}
                  page_type={"simulateur"}
                  simulator_screen={"housing"}
                  simulator_step={"1/6"}
                  simulator_type={i18n.module}
                >
                  <HousingTypePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/housing/size`}
          element={
            <ProgressRoute
              {...housingProps}
              pageTitle={i18n.housingSizePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.housingSizePage.pageTitle}
                  page_path={"/pension-need/housing/size"}
                  page_type={"simulateur"}
                  simulator_screen={"housing"}
                  simulator_step={"1/6"}
                  simulator_type={i18n.module}
                >
                  <HousingSizePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/transport/types`}
          element={
            <ProgressRoute
              {...transportProps}
              pageTitle={i18n.transportTypesPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.transportTypesPage.pageTitle}
                  page_path={"/pension-need/transport/types"}
                  page_type={"simulateur"}
                  simulator_screen={"transport"}
                  simulator_step={"2/6"}
                  simulator_type={i18n.module}
                >
                  <TransportTypesPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/transport/car`}
          element={
            <ProgressRoute
              {...transportProps}
              pageTitle={i18n.transportCarPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.transportCarPage.pageTitle}
                  page_path={"/pension-need/transport/car"}
                  page_type={"simulateur"}
                  simulator_screen={"transport"}
                  simulator_step={"2/6"}
                  simulator_type={i18n.module}
                >
                  <TransportCarPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/transport/public`}
          element={
            <ProgressRoute
              {...transportProps}
              pageTitle={i18n.transportPublicPage.pageTitle}
              element={                
                <AnalyticsComponent
                  page_title={i18n.transportPublicPage.pageTitle}
                  page_path={"/pension-need/transport/public"}
                  page_type={"simulateur"}
                  simulator_screen={"transport"}
                  simulator_step={"2/6"}
                  simulator_type={i18n.module}
                >
                  <TransportPublicPage />
                </AnalyticsComponent>
            }
            />
          }
        />

        <Route
          path={`/transport/motorbike`}
          element={
            <ProgressRoute
              {...transportProps}
              pageTitle={i18n.transportMotorbikePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.transportMotorbikePage.pageTitle}
                  page_path={"/pension-need/transport/motorbike"}
                  page_type={"simulateur"}
                  simulator_screen={"transport"}
                  simulator_step={"2/6"}
                  simulator_type={i18n.module}
                >
                <TransportMotorbikePage />
                </AnalyticsComponent>
            }
            />
          }
        />

        <Route
          path={`/transport/bike`}
          element={
            <ProgressRoute
              {...transportProps}
              pageTitle={i18n.transportBikePage.pageTitle}
              element={
                <AnalyticsComponent
                page_title={i18n.transportBikePage.pageTitle}
                page_path={"/pension-need/transport/bike"}
                page_type={"simulateur"}
                simulator_screen={"transport"}
                simulator_step={"2/6"}
                simulator_type={i18n.module}
              >
              <TransportBikePage />
              </AnalyticsComponent>
            }
            />
          }
        />

        <Route
          path={`/supply/restaurant`}
          element={
            <ProgressRoute
              {...supplyProps}
              pageTitle={i18n.supplyRestaurantPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.supplyRestaurantPage.pageTitle}
                  page_path={"/pension-need/supply/restaurant"}
                  page_type={"simulateur"}
                  simulator_screen={"supply"}
                  simulator_step={"3/6"}
                  simulator_type={i18n.module}
                >
                  <SupplyRestaurantPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/supply/home`}
          element={
            <ProgressRoute
              {...supplyProps}
              pageTitle={i18n.supplyHomePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.supplyHomePage.pageTitle}
                  page_path={"/pension-need/supply/home"}
                  page_type={"simulateur"}
                  simulator_screen={"supply"}
                  simulator_step={"3/6"}
                  simulator_type={i18n.module}
                >
                  <SupplyHomePage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/others/travel`}
          element={
            <ProgressRoute
              {...othersProps}
              pageTitle={i18n.otherTravelsPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.otherTravelsPage.pageTitle}
                  page_path={"/pension-need/others/travel"}
                  page_type={"simulateur"}
                  simulator_screen={"others"}
                  simulator_step={"4/6"}
                  simulator_type={i18n.module}
                >
                  <OtherTravelsPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/others/sport`}
          element={
            <ProgressRoute
              {...othersProps}
              pageTitle={i18n.otherSportsPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.otherSportsPage.pageTitle}
                  page_path={"/pension-need/others/sport"}
                  page_type={"simulateur"}
                  simulator_screen={"others"}
                  simulator_step={"4/6"}
                  simulator_type={i18n.module}
                >
                  <OtherSportsPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/others/hobbies`}
          element={
            <ProgressRoute
              {...othersProps}
              pageTitle={i18n.otherHobbiesPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.otherHobbiesPage.pageTitle}
                  page_path={"/pension-need/others/hobbies"}
                  page_type={"simulateur"}
                  simulator_screen={"others"}
                  simulator_step={"4/6"}
                  simulator_type={i18n.module}
                >
                  <OtherHobbiesPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/health/medical`}
          element={
            <ProgressRoute
              {...healthProps}
              pageTitle={i18n.healthMedicalPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.healthMedicalPage.pageTitle}
                  page_path={"/pension-need/health/medical"}
                  page_type={"simulateur"}
                  simulator_screen={"health"}
                  simulator_step={"5/6"}
                  simulator_type={i18n.module}
                >
                  <HealthMedicalPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/health/unexpected`}
          element={
            <ProgressRoute
              {...healthProps}
              pageTitle={i18n.healthUnexpectedPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.healthUnexpectedPage.pageTitle}
                  page_path={"/pension-need/health/unexpected"}
                  page_type={"simulateur"}
                  simulator_screen={"health"}
                  simulator_step={"5/6"}
                  simulator_type={i18n.module}
                >
                  <HealthUnexpectedPage />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/birthdate`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={5}
              showSteps={false}
              pageTitle={i18n.birthdatePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.birthdatePage.pageTitle}
                  page_path={"/pension-need/birthdate"}
                  page_type={"simulateur"}
                  simulator_screen={"birthdate"}
                  simulator_step={"6/6"}
                  simulator_type={i18n.module}
                >
                  <BirthdatePage
                    moduleName="pensionNeed"
                    analyticStep={"6/6"}
                  />
                </AnalyticsComponent>
              }
            />
          }
        />

        <Route
          path={`/summary`}
          element={
            <ProgressRoute
              {...summaryProps}
              showSteps={false}
              subtitle={globali18n.labels.summary}
              showAmount={false}
              pageTitle={i18n.summaryPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.summaryPage.pageTitle}
                  page_path={"/pension-need/summary"}
                  page_type={"summary"}
                  simulator_screen={"summary"}
                  simulator_step={""}
                  simulator_type={i18n.module}
                >
                  <SummaryPage />
                </AnalyticsComponent>
              }
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
