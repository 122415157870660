import { Controller, useForm } from "react-hook-form";
import { globali18n } from "../../../../i18n";
import {
  Container,
  QuestionTitle,
  ValidationMessages,
} from "../../../../shared";
import { getValidationMessages } from "../../../../shared/utilities/getValidationMessages";
import { useAppSelector } from "../../../../store";
import { BackgroundImage } from "../../component/BackgroundImage/BackgroundImage";
import { QuestionCard } from "../../../../shared/components/QuestionCard/QuestionCard";
import { i18n } from "../../i18n";
import { selectBirthDate } from "../../legalPensionSlice";
import { ModuleStyles } from "../../styles/ModuleStyles";
import {
  maxAgeAtCareerStart,
  maxCareerStartYear,
  minAgeAtCareerStart,
} from "../../utilities/constants";
import { careerStartedBetweenAge } from "../../utilities/dateValidators";
import { useAnswerCommands } from "../../commands/answerCommands";
import { dateTimeFormat } from "../../../../shared/utilities/intl";
import InputMask from "react-input-mask";
import { isFuture } from "date-fns";

interface FormData {
  startDateOfActivity: string;
}

export function CareerStartPage() {
  const { answerStartDateOfActivity } = useAnswerCommands();

  const currentValue = useAppSelector(
    (s) => s.legalPension.answers.startDateOfActivity
  );
  const birthDate = useAppSelector(selectBirthDate);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: {
      startDateOfActivity:
        (currentValue && dateTimeFormat(new Date(currentValue))) || "",
    },
  });
  // new Date obj from "dd/mm/yyyy"
  const createDateObjectFromStringDateFormated = (dateString: string): Date => {
    return new Date(
      +dateString.split("/")[2],
      +dateString.split("/")[1] - 1,
      +dateString.split("/")[0]
    );
  };

  function onSubmit(value: FormData) {
    const selectedDate = createDateObjectFromStringDateFormated(value.startDateOfActivity);
    if(isFuture(selectedDate)) {
      setError("startDateOfActivity", { types: { max: true } })
    } else {
      const date = createDateObjectFromStringDateFormated(
        value.startDateOfActivity!
      );
      answerStartDateOfActivity(date);
    }
  }

  const rules = {
    required: true,
    max: maxCareerStartYear,
    validate: {
      careerStartedBetweenAge: careerStartedBetweenAge(
        minAgeAtCareerStart,
        maxAgeAtCareerStart,
        birthDate
      ),
    },
  };

  const errorMessages = {
    required: globali18n.errors.required,
    max: i18n.careerStartPage.errors.max,
    careerStartedBetweenAge: i18n.careerStartPage.errors.ageBetweenSince,
  };

  return (
    <Container className={ModuleStyles.PageGrid}>
      <QuestionTitle>{i18n.careerStartPage.question}</QuestionTitle>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <QuestionCard
          childrenClassName={ModuleStyles.CardChildrenGrid}
          autoFocusValidate={currentValue != null}
        >
          <Controller
            control={control}
            name="startDateOfActivity"
            rules={rules}
            render={({ field: { onChange, value, ref } }) => (
              <InputMask
                style={{ borderWidth: "1.5px" }}
                ref={ref}
                mask="99/99/9999"
                value={value}
                onChange={onChange}
                placeholder={dateTimeFormat(new Date())}
              />
            )}
          />
          <ValidationMessages
            messages={getValidationMessages(
              "startDateOfActivity",
              errors,
              errorMessages
            )}
          />
        </QuestionCard>
      </form>
      <BackgroundImage />
    </Container>
  );
}
