import { stormcloud } from "../../../../styles/colors";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { i18n } from "../../i18n";
import styles from "./InvestorProfileCard.module.scss";
import { EditIcon } from "../../../../shared";
import { ModuleStatus } from "../../models/ModuleStatus";
import { determineModuleBackgroundColor } from "../../utilities/determineModuleBackgroundColor";
import { assets as Assets } from "../../Assets";
import classNames from "classnames";
import { Scenario } from "../../../../modules/investor-profile/models/RiskProfileEnum";
import { InfoIcon } from "./assets";

interface ModuleCardProps {
  icon: any;
  unCompletedIcon: any;
  lockIcon: any;
  title: string;

  prefix?: string;
  amount?: number | null;
  status: ModuleStatus;

  unit?: string;
  color: string;

  isExpired: boolean | null | undefined;
  riskProfile: Scenario | null | undefined;

  hideAmount?: boolean;

  onClick?: () => void;
}

export function InvestorProfileCard(props: ModuleCardProps) {
  const backgroundColor = determineModuleBackgroundColor(
    props.status,
    props.color
  );

  const isAvailable = props.status === ModuleStatus.Available;
  const isCompleted = props.status === ModuleStatus.Completed;
  const isCompletedAndLocked = props.status === ModuleStatus.CompletedAndLocked;
  const isLocked = props.status === ModuleStatus.Locked;

  function click() {
    if (props.status === ModuleStatus.Locked || props.status === ModuleStatus.CompletedAndLocked) {
      return;
    }

    props.onClick?.();
  }

  const Icon = props.icon;
  const UnCompletedIcon = props.unCompletedIcon;
  const ModuleLockIcon = props.lockIcon;

  return (
    <button
      className={classNames({
        [styles.Grid]: !isLocked,
        [styles.LockedStyle]: isLocked
      })}
      style={{ ...backgroundColor }}
      onClick={click}
    >
      <div className={styles.Icon}>
        {isLocked ? (
          <SvgWrapperComponent ariaHidden={true}>
            <ModuleLockIcon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        ) : isAvailable || isCompletedAndLocked ? (
          <SvgWrapperComponent ariaHidden={true}>
            <UnCompletedIcon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        ) : (
          <SvgWrapperComponent ariaHidden={true}>
            <Icon className={styles.SvgIcon} />
          </SvgWrapperComponent>
        )}
      </div>
      <div
        className={classNames(styles.TitleAndIcon, {
          [styles.noAmountData]: (isCompleted || isCompletedAndLocked) && props.hideAmount,
        })}
      >
        <div className={styles.Title}>{props.title}</div>
        {isLocked && (
          <SvgWrapperComponent ariaHidden={true}>
            <Assets.LockIcon />
          </SvgWrapperComponent>
        )}
      </div>
      {props.isExpired ? (
        <ExpiredChip />
      ) : (
        (isCompleted || isCompletedAndLocked) && (
          <div>
            <span className={styles.Amount}>{i18n.scenario[props.riskProfile as Scenario]}</span>{" "}
            <span className={styles.Unit}>{props.unit}</span>
          </div>
        )
      )}
      {isAvailable && (
        <div className={styles.ToComplete}>{i18n.dashboardPage.toComplete}</div>
      )}
      {!isLocked && !isCompletedAndLocked && (
        <div className={styles.Corner}>
          <SvgWrapperComponent ariaHidden={true}>
            <EditIcon
              className={styles.EditIcon}
              filled={true}
              color={stormcloud}
            />
          </SvgWrapperComponent>
        </div>
      )}
    </button>
  );
}


function ExpiredChip() {
  return (
    <div className={styles.Expired}>
      <SvgWrapperComponent ariaHidden={true}>
            <InfoIcon />
          </SvgWrapperComponent>
      <span>Expiré</span>
    </div>
  )
}