import { useAppSelector } from "../../store";
import { useAnalytics } from "./useAnalytics";
import { PageViewModel } from "../../shared/models/PageView";

export function usePageViewEvent() {
  const { pageView } = useAnalytics();
  const isAuthenticated = useAppSelector(
    (s) => s.authentication.isAuthenticated
  );
  const customerId = useAppSelector((s) => s.authentication.customerId);

  function pageViewEvent(pageInformation: PageViewModel) {
    pageInformation.user_id = "";
    pageInformation.login = isAuthenticated;

    previousEvents(pageInformation);
  }

  function previousEvents(pageInformation: PageViewModel) {
    if (window.dataLayer) {
      if (window.dataLayer?.length > 2) {
        interface IObjectKeys {
          [key: string]: string | number | undefined;
        }
        const pageTitle = window.dataLayer[
          window.dataLayer.length - 2
        ] as IObjectKeys;
        if (pageTitle["page_path"] !== pageInformation.page_path) {
          pageView(pageInformation);
        }
      }
    }
  }

  return { pageViewEvent };
}
