import { Argument } from 'classnames';
import { FunctionComponent, PropsWithChildren, SVGProps } from 'react';
import { SvgWrapperComponent } from '../../../../shared/components/SvgWrapper/SvgWrapperComponent';
import { Card } from '../../../../shared';
import styles from './DeclarationCard.module.scss';

interface DeclarationCardProps extends PropsWithChildren<{}> {
  title: string;
  subTitle: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>

  className?: Argument;
}

export function DeclarationCard(props: DeclarationCardProps) {
  const Icon = props.icon;

  return (
    <Card className={styles.Card}>
      <div className={styles.TitleGrid}>
        <SvgWrapperComponent ariaHidden={true}><Icon className={styles.Icon} /></SvgWrapperComponent>
        <div className={styles.Title}>{props.title}</div>
        <div className={styles.Subtitle}>{props.subTitle}</div>
      </div>
      {props.children}
    </Card>
  );
}
