import {
  financialInstrumentsOrMarkets,
  FinancialInstrumentsOrMarkets,
} from "../../../models/RiskProfileEnum";
import { BackgroundImage } from "../../../component/BackgroundImage/BackgroundImage";
import { i18n } from "../../../i18n";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "../../../../../store";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setFinancialInstrumentsOrMarkets } from "../../../../../modules/investor-profile/investorProfileSlice";
import { useAppUrls } from "../../../../../shared/utilities/urls";
import {
  Button,
  ButtonStyles,
  Container,
  QuestionTitle,
  useLoader,
  ValidationMessages,
} from "../../../../../shared";
import styles from "./FinancialInstrumentsOrMarkets.module.scss";
import classNames from "classnames";
import { Styles } from "../../../../../modules/pension-need/styles/Styles";
import { globali18n } from "../../../../../i18n";
import { AnswerToggle } from "../../../../../modules/pension-need/components/AnswerToggle/AnswerToggle";
import { getValidationMessages } from "../../../../../shared/utilities/getValidationMessages";
import { setQuestionnaireFinancialProfileSessionAnswers } from "../../../../../modules/investor-profile/requests/setQuestionnaireFinancialProfileSessionAnswers";
import { apiUrls } from "../../../../../shared/apiUrls";
import { useAnalytics } from "../../../../../shared/commands/useAnalytics";
import { getQuestionnaireSessionFinancialProfile } from "../../../../../modules/investor-profile/requests/getQuestionnaireSessionFinancialProfile";
import { getQuestionnaireSessionRiskProfile } from "../../../../../modules/investor-profile/requests/getQuestionnaireSessionRiskProfile";
import { validateRiskProfile } from "../../../../../modules/investor-profile/requests/validateRiskProfile";
import { validateFinancialProfile } from "../../../../../modules/investor-profile/requests/validateFinancialProfile";

export function FinancialInstrumentsOrMarketsPage() {
  const selected = useAppSelector(
    (s) => s.investorProfile.financialProfile.financialInstrumentsOrMarkets!
  );
  const defaultValues = Object.fromEntries(
    financialInstrumentsOrMarkets.map((tt) => [tt, selected.includes(tt)])
  );

  const { control, handleSubmit, watch, formState, setValue } = useForm({
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const appUrls = useAppUrls();
  const store = useAppStore();
  const { customerid } = useParams();
  const { showLoaderWhile } = useLoader();
  const { answerInvestorProfileEvent } = useAnalytics();
  const financialProfileIdentifier =
    store.getState().investorProfile.financialProfile
      .financialProfileIdentifier;
  const riskProfileIdentifier =
    store.getState().investorProfile.riskProfile.riskProfileIdentifier;

  async function computeRequest(data: any) {
    const financialStore = store.getState().investorProfile.financialProfile;

    await showLoaderWhile(
      async () => {
        await setQuestionnaireFinancialProfileSessionAnswers(
          customerid,
          financialStore.financialProfileIdentifier as string,
          financialStore
        );

        const selected = Object.entries(data)
          .filter(([k, v]) => v)
          .map(([k, v]) => k as FinancialInstrumentsOrMarkets);
        answerInvestorProfileEvent(
          selected.toString().replace(",", " | "),
          "11/11",
          i18n.financialProfile.financialInstrumentsOrMarketsPage.question,
          "financial_profile"
        );

        const financialQuestionnaire =
          await getQuestionnaireSessionFinancialProfile(
            customerid,
            financialProfileIdentifier!
          );

        const riskProfile = await getQuestionnaireSessionRiskProfile(
          customerid,
          riskProfileIdentifier!
        );

        await validateRiskProfile(customerid, riskProfile.id!);
        await validateFinancialProfile(
          customerid,
          financialQuestionnaire.id!
        );
        navigate(appUrls.investorProfile.financialProfile.summary);
      },
      {
        key: "investorProfile_FinancialProfile_setQuestionnaireFinancialProfileSessionAnswers",
        value: `API PUT - ${apiUrls(
          customerid
        ).investorProfile.questionnaireSession.setQuestionnaireSessionAnswers(
          financialStore.financialProfileIdentifier!
        )}`,
      }
    );
  }

  function onSubmit(data: typeof defaultValues) {
    // Keep only the keys of the checked transport types
    const selected = Object.entries(data)
      .filter(([k, v]) => v)
      .map(([k, v]) => k as FinancialInstrumentsOrMarkets);

    dispatch(setFinancialInstrumentsOrMarkets(selected));

    computeRequest(data);
  }

  function atLeastOne(): boolean {
    const allValues = watch();
    const result = Object.values(allValues).some((tt) => tt);

    return result;
  }

  function onSelected(val: FinancialInstrumentsOrMarkets) {
    if (val === FinancialInstrumentsOrMarkets.IDontKnow) {
      setValue("ASVInEuro", false);
      setValue("Actions", false);
      setValue("Obligations", false);
      setValue("OPC", false);
      setValue("ASVUnitAccount", false);
      setValue("OPCI", false);
      setValue("SCPI", false);
      setValue("DontKnow", true);
    } else {
      setValue("DontKnow", false);
    }

    if (val === FinancialInstrumentsOrMarkets.NoneOfTheseAnswers) {
      setValue("ASVInEuro", false);
      setValue("Actions", false);
      setValue("Obligations", false);
      setValue("OPC", false);
      setValue("ASVUnitAccount", false);
      setValue("OPCI", false);
      setValue("SCPI", false);
      setValue("IDontKnow", false);
      setValue("NoneOfTheseAnswers", true);
    } else {
      setValue("NoneOfTheseAnswers", false);
    }
  }

  

  const rules = {
    validate: { atLeastOne: () => atLeastOne() },
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Container className={classNames(Styles.Background, styles.Container)}>
          <QuestionTitle>
            {i18n.financialProfile.financialInstrumentsOrMarketsPage.question}
          </QuestionTitle>
          <div className={styles.AnswerGrid}>
            {financialInstrumentsOrMarkets.map(
              (o: FinancialInstrumentsOrMarkets, i) => {
                return (
                  <Controller
                    key={o}
                    control={control}
                    name={o}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <AnswerToggle
                        childrenClassName={styles.AnswerToggleChildren}
                        onChange={(e) => {
                          onSelected(o);
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        value={value}
                      >
                        {
                          i18n.financialProfile
                            .financialInstrumentsOrMarketsPage.answers[o]
                        }
                      </AnswerToggle>
                    )}
                  />
                );
              }
            )}
            <ValidationMessages
              messages={getValidationMessages(
                FinancialInstrumentsOrMarkets.ASVInEuro,
                formState.errors,
                {
                  atLeastOne:
                    i18n.financialProfile.financialInstrumentsOrMarketsPage
                      .atLeastOne,
                }
              )}
            />
          </div>
          <div className={styles.ButtonContainer}>
            <Button
              disabled={!atLeastOne()}
              className={classNames(
                ButtonStyles.PrimaryAction,
                styles.ValidateButton
              )}
            >
              {globali18n.labels.finalize}
            </Button>
          </div>
        </Container>
      </form>
      {!location.pathname.includes("flow") && <BackgroundImage />}
    </>
  );
}
