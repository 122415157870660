import { MaritalStatus } from "./models/MaritalStatus";

export const i18n = {
  module: "Votre avantage fiscal",

  categories: {
    synthesis: "Synthèse",
  },

  errors: {
    minMaxAmount: "La valeur doit être comprise entre 0 € et 1 000 000 €."
  },

  advancedSummary: {
    description:
      "En investissant dans un plan d'épargne retraite vous pouvez déduire de vos revenus tout ou partie du montant versé.",
  },

  maritalStatus: {
    [MaritalStatus.Single]: "Célibataire",
    [MaritalStatus.Married]: "Marié(e)",
    [MaritalStatus.CivilPartnership]: "Pacsé(e)",
    [MaritalStatus.Divorced]: "Divorcé(e)",
    [MaritalStatus.Widower]: "Veuf(ve)",
  },

  landingPage: {
    pageTitle: "Votre avantage fiscal",
    title:
      "Vous pouvez réduire vos impôts dès aujourd’hui et préparer votre avenir\u00A0!",
    content: (
      <p>
        Découvrez si vous êtes éligible et simulez votre réduction d’impôts
        grâce au plan d’épargne retraite.
      </p>
    ),
  },

  taxAvailablePage: {
    pageTitle: "Votre avantage fiscal",
    question:
      "Quel est votre plafond de déductibilité des cotisations en épargne retraite ?",
    descriptionWarning:
      "Munissez-vous de votre dernier avis d’imposition.",
    labels: {
      declaring1: "Déclarant 1",
      declaring2: "Déclarant 2 (optionnel)",
    },
    link: "Où trouver votre plafond de déductibilité des cotisations en épargne retraite ?",
    modal: {
      title:
        "Quel est votre plafond de déductibilité des cotisations en épargne retraite ?",
      content:
        "Indiquez dans cette case, le montant figurant dans votre imposition année N dans la rubrique PLAFOND EPARGNE RETRAITE ligne « Plafond pour les cotisations versées en année N ». Ce montant est égal au plafond calculé sur les revenus n-1 augmenté du plafond ou de la fraction du plafond de déduction applicable et non utilisé des trois années précédentes ;",
    },
  },

  taxRate: {
    pageTitle: "Votre avantage fiscal",
    question: "Quel est votre taux marginal d’imposition ?",
    descriptionWarning:
      "Munissez-vous de votre dernier avis d’imposition.",
    tmiPourcentage: {
      0: "0%",
      11: "11%",
      30: "30%",
      41: "41%",
      45: "45%",
    },
    link: "Où trouver votre taux marginal d’imposition ?",
    modal: {
      title: "Quel est votre taux marginal d’imposition ?",
      content:
        "Le taux marginal correspond au taux de la tranche la plus élevée du barème de l'impôt sur le revenu auquel sont taxés pour l'année en cours les revenus déclarés de votre foyer fiscal.",
    },
  },

  summaryPage: {
    pageTitle: "Votre économie d'impôts",
    basic: {
      title: {
        notEligible: "Vous n'êtes pas éligible à une économie d'impôt.",
        eligible: "Bravo, vous êtes éligible à une économie d’impôt.",
      },
    },
    cardTitle:
      "Estimation de votre économie d’impôts annuelle grâce au Plan Epargne Retraite Individuel (PERIN)",
    cardNotEligibleTitle: "D'autres avantages vous attendent.",
    deductible:
      "Investissement maximum déductible de votre revenu individuel net imposable\u00A0:",
    investment: "Personnalisez votre investissement annuel",
    investmentMobileLabel: "Votre investissement\u00A0:",

    tmi: "Comment en bénéficier ?",
    amountPerTitle:
      "En investissant dans un Plan d'Épargne Retraite Individuel, votre économie d’impôt est de\u00A0:",
    blocDescriptionTitle:
      "Deux moments clés où vous pouvez bénéficier d’avantages fiscaux :",
    blocDescriptionBodyOne: "Lorsque vous versez des sommes sur votre PER individuel : Vos versements sont déductibles de votre revenu net global imposable (dans la limite des plafonds disponibles).",
    blocDescriptionBodyTwo: "Lorsque vous clôturez votre contrat : Cette économie d’impôt n’est pas une exonération, mais un différé. Vous serez imposé sur les sommes que vous aurez récupérées de votre PER individuel, en fonction de votre nouveau barème d’imposition à la retraite. Les plus-values générées sur votre PER individuel sont soumises selon votre choix, à un taux d’imposition de 30 % ou au barème de l’impôt sur le revenu (à demander au moment de la déclaration de revenus).",
    
    editTaxationTitle: "Modifiez votre taux d’imposition",
    redoTaxationQuestionnaire: "Modifier mes informations",
    info: (
      <>
        Allez plus loin pour affiner cette première estimation.
        <br />
        Construisez votre solution d’épargne personnalisée en complétant votre
        diagnostic retraite.
      </>
    ),
    estimateTaxSaving: "Estimation de votre économie d’impôt :",
    tellMeMore: "En savoir plus",
    weGoNext: "On passe à la suite",
    disclaimer:
      "Cette estimation est réalisée sur la base de votre propre déclaration et selon le régime fiscal en vigueur qui est susceptible d’évoluer dans le temps.",
    footer:
      "Les données utilisées dans cette partie sont à titre indicatif afin de vous aider à définir le montant de votre économie d’impôts grâce au PERIN. Simulation simplifiée et non engageante.",

    naturalText: {
      [MaritalStatus.Single]: "célibataire",
      [MaritalStatus.Married]: "marié(e)",
      [MaritalStatus.CivilPartnership]: "pacsé(e)",
      [MaritalStatus.Divorced]: "divorcé(e)",
      [MaritalStatus.Widower]: "veuf(ve)",
    },

    investmentChart: {
      label: "Votre économie d’impôts grâce au PERIN\u00A0:",
      legend: {
        effort: "Investissement net",
        savings: "Economie d'impôts grâce au PERIN",
      },
    },
   
  },
};

export type TaxationI18n = typeof i18n;
