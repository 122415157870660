import axios from "axios";
import { UserReferenceModel } from "../../shared/models/UserReferenceModel";
import { UserType } from "./getUserReferenceRequest";

interface BFFUserConfig {
  type: string;
  value: string;
}

export async function bffUserRequest(
  customerid?: string
): Promise<UserReferenceModel | null> {
  try {
    const response = await axios.get<BFFUserConfig[]>(`/bff/user`, {
      validateStatus: (status) => {
        return status <= 399 || status === 401;
      },
    });
    if (response.status === 401) {
      return null;
    }
    const bffUserLogout =
      response.data &&
      response.data.find((config) => config.type === "bff:logout_url")?.value;
    const data = {
      identifier: customerid || "",
      userType: customerid ? UserType.Advisor : UserType.User,
      bffUserLogout,
    };

    return data;
  } catch (err) {
    // User No authenticated
    return null;
  }
}
