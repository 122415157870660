import classNames, { Argument } from "classnames";
import styles from "./PageTitle.module.scss";

interface PageTitleProps {
  className?: Argument;
}


export function PageTitle(props: React.PropsWithChildren<PageTitleProps>) {
  return <h1 className={classNames(styles.PageTitle, props.className)}>{props.children}</h1>;
}
