import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import axios from 'axios';
import { AppointmentInputModel } from '../models/AppointmentInputModel';

export async function setAppointmentRequest(
  model: AppointmentInputModel
): Promise<AppointmentInputModel> {
  const response = await axios.post<AppointmentInputModel>(apiUrls().appointments.post, model);

  return response.data;
}
