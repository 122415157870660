import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { GetGamificationParams } from '../models/RecommendationModel';
import { axiosRequestValidateStatusConfig } from '../../../shared/axiosValidateStatusConfig';

export async function getParametersRequest(customerid?: string): Promise<GetGamificationParams | null> {
  const apiUrl = apiUrls(customerid).recommendation.parameters;
  const response = await axios.get<GetGamificationParams>(
    apiUrl, {...axiosRequestValidateStatusConfig}
  );

  if(response.status === 404) {
    return null;
  }

  return response.data;
}
