import { FooterLink, FooterLinkInterface } from "../../shared/models/TenantModel";
import { useGetTenantUrlsCommand } from "../requests/getTenantUrlsCommand";

export function useExternalUrls() {
  const { getTenantUrlsCommand } = useGetTenantUrlsCommand();
  async function externalUrls() {
    const links = await getTenantUrlsCommand();
    const urls = links.Urls as FooterLink;
    let url = {};

    Object.keys(urls).map((e) => {
      url = {
        ...url,
        [e]: urls[e as any],
      };
    });
    return url as FooterLinkInterface;
  }

  return { externalUrls };
}
