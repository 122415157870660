import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';
import { useLoader } from '../../../shared';
import { internalUrls, useAppUrls } from '../../../shared/utilities/urls';
import {  useAppDispatch, useAppStore } from '../../../store';
import { getTaxationRequest } from '../requests/getTaxationRequest';
import { setTaxationRequest } from '../requests/setTaxationRequest';
import { setResults, setTaxAvailable } from '../taxationSlice';

export function useUpdateTaxAvailableCommand() {
  const { showLoaderWhile } = useLoader();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const store = useAppStore();
  const appUrls = useAppUrls();
  const { customerid } = useParams();

  return async function setUpdateTaxAvailableCommand(declareAmount: number) {
    await showLoaderWhile(async () => {
        dispatch(setTaxAvailable({declareAmount, optionalDeclareAmount: 0}));
        const answers = store.getState().taxation.answers;
        // POST the answers on the backend
        await setTaxationRequest(answers, customerid);

        // GET the computed values and update the store
        const results = await getTaxationRequest(customerid);
        dispatch(setResults(results!));

        navigate(appUrls.taxation.summary);
    }, {key: "useSetTaxationCommand", value:`API POST- ${apiUrls(customerid).taxation.put}`});
  };  
}
