import { amount } from "../../shared/utilities/intl";
import { Products } from "./Products";
import { ExclusionReason } from "./models/exclusion-reason.enum";
import { ProductTypeIdentifier } from "./models/product-type-identifier.enum";
import { RiskProfile } from "./models/risk-profile.enum";

export const i18n = {
  recommendationProductsPage: {
    title: "Nos produits d’épargne retraite",
    subTitle: "Démarrez votre projet retraite",
    ctas: {
      report: "Voir le rapport complet",
      rendezVous: "Prendre rendez-vous avec nos experts",
      detail: "Voir le détail",
      goToTaxation: "Estimer votre avantage fiscal"
    }
  },
  recommendationPage: {
    title: "Votre solution d’épargne personnalisée",
    howIsCalculated: "Comment sont calculés vos versements\u00A0?",
    allScenario:
      "Tous les scénarios sont présentés hors frais, fiscalité et prélèvements sociaux.",
    taxSavingMoreInfoModal: {
      title: "Mon économie d’impôt",
      desc: "Le Plan d'Epargne Retraite Individuel (PER Individuel) est un produit d'épargne à long terme qui permet la constitution d'un complément de retraite par capitalisation. L'adhérent a la possibilité de bénéficier de versements volontaires déductibles du revenu imposable dans la limite des plafonds prévus.",
      content: {
        labels: {
          open: "Au premier versement\u00A0: ",
          monthly: "Chaque mois :",
          amountPER: "Montant du versement sur le PER individuel",
          taxSaving: "Économie d’impôt (*)",
          tmi: "TMI",
          taxAvailableCeiling: "Plafond disponible fiscal",
        },
        footnote:
          "(*) La déductibilité des revenus nets imposables peut être réalisée l’année qui suit les versements.",
      },
    },
    infoModal: {
      title: "Comment sont calculés vos versements\u00A0?",
      content: () => (
        <section>
          <p>
            Afin d’épargner pour votre retraite, notre proposition de solution
            idéale est d’effectuer un versement initial et de le compléter avec
            des versements récurrents :
          </p>
          <p>
            <ul>
              <li>
                Le <b>premier versement</b> correspond à 5% de votre salaire
                annuel net
              </li>
              <li>
                Les <b>versements mensuels</b> respectent votre capacité
                d’épargne (soit maximum 20% de votre salaire net mensuel, que
                vous pouvez ajuster) dont sont soustraits les versements
                récurrents déjà réalisés que vous avez renseignés au niveau de
                la déclaration de votre patrimoine (sur compte épargne,
                assurance vie, PEA, etc.).
              </li>
            </ul>
          </p>
          <p>
            Par ailleurs, nous vous recommandons de conserver une{" "}
            <b>épargne de précaution</b> (réserve d'argent mise de côté et
            immédiatement disponible pour faire face aux aléas de la vie. Par
            exemple : une voiture qui tombe en panne, des travaux imprévus,
            etc.). Il est conseillé de disposer d’une épargne de précaution de 4
            fois son salaire mensuel net et d’opter pour des solutions avec des
            fonds disponibles immédiatement (par exemple : Livret A).
          </p>
        </section>
      ),
    },
    appointmentGroup: {
      download: "Visualiser votre rapport complet",
      appointment: "Prendre un RDV avec nos experts",
    },
    ctas: {
      subscribe: "Souscrire avec nos experts",
      personalize: "Personnaliser votre recommandation",
    },
    footnote: (
      <div>
        La souscription aux produits doit respecter le montant minimum de
        versement de chaque produit.
        <br />
        L’investissement dans ces produits comporte un risque de perdre votre
        capital.
      </div>
    ),
    summary: {
      title: "Épargnez aujourd’hui pour demain",
      mainCardTitle: "Souscrivez à notre sélection de produits",
      youPay: (year: number) => `Vous versez jusqu’en ${year}`,
      youWillObtain: <span>Vous obtiendrez</span>,
      capital: "Soit un capital complémentaire estimé à",
      income: "Soit un revenu complémentaire",
      neutralPrevision: "Prévision neutre",
    },
    advancedSummary: {
      title: "Personnalisez votre recommandation",
      subtitle:
        "Notre sélection de produits pour réaliser votre projet retraite",
      gamifyComponent: {
        payoutAmountCard: {
          title: "Ajustez vos montants de versements",
          labels: {
            open: "Au premier versement\u00A0:",
            monthly: "Chaque mois :",
            recommended: "Montant préconisé :",
          },
        },
        modifyAgeCard: {
          title: "Modifiez votre âge de départ à la retraite",
          labels: {
            age: "Âge sélectionné :",
            selectOtherAge: "Sélectionnez un autre âge",
          },
          ctas: {
            reset: "Réinitialiser",
            updateRecommendation: "Mettre à jour ma recommandation",
          },
        },
        ctas: {
          updateButton: "Mettre à jour votre recommandation",
          resetLink: "Revenir aux montants préconisés",
        },
      },
    },
    coverage:
      "Voici une proposition de solution d’épargne adaptée à votre projet retraite.",
    takeAppointment: "Prenez RDV avec nos experts pour le concrétiser.",
    yourSolutionGroup: {
      title: "Votre complément de revenus :",
      revenueComplement: "Montant mensuel",
      capitalAtRetirement: "Capital pour la retraite",
    },
    modal: {
      title: "Comment est estimé votre revenu complémentaire ?",
      panTitle: "En savoir plus",
      content: (
        pessimistic: number,
        optimistic: number,
        neutral: number,
        projectionUrl: string
      ) => (
        <div>
          <p>
            Afin d’estimer la valeur de votre revenu complémentaire lors de
            votre départ à la retraite, nous utilisons des méthodes
            probabilistes de valorisation ou dévalorisation des différents
            actifs composant votre capital le jour où vous faites la simulation.
          </p>

          <p>
            Dans des conditions normales de marché et selon nos calculs, nous
            sommes capables d’estimer la valeur future de votre capital selon
            les 3 scénarios suivants :
          </p>
          <p>
            Le complément de revenus espéré est calculé sur base de votre
            capacité à épargner mensuellement jusqu’à votre départ à la
            retraite.
          </p>
          <p>
            En fonction de votre profil d’investisseur, votre complément de
            revenus pourrait être compris entre <b>{pessimistic}</b> €/mois
            (scénario défavorable), et <b>{optimistic}</b> €/mois (scénario
            favorable). Le montant présenté ici (<b>{neutral}</b> €/mois)
            correspond à un scénario intermédiaire dit “neutre”.
          </p>
        </div>
      ),
      panelContent: (
        <div>
          <p>
            <ul>
              <li>
                Un scénario « <b>neutre</b> » : le scénario que nous estimons le
                plus probable dans des conditions de marché habituelles. La
                valorisation de votre patrimoine a ainsi, selon nos calculs, 50
                % de chances de se trouver au-dessus du montant indiqué et 50 %
                de chances de se retrouver en dessous du montant indiqué.
              </li>
              <li>
                Un scénario « <b>défavorable</b> » : ce scénario correspond à
                des mouvements défavorables de marché. La valorisation de votre
                patrimoine a ainsi selon nos calculs 95% de chances de se
                trouver au-dessus du montant indiqué ; 5% de risques de se
                retrouver en dessous du montant indiqué.
              </li>
              <li>
                Un scenario « <b>favorable</b> » : ce scénario correspond à des
                mouvements favorables de marché. La valorisation de votre
                patrimoine a ainsi selon nos calculs 5% de chances de se trouver
                au-dessus du montant indiqué ; 95% de risques de se retrouver en
                dessous du montant indiqué.
              </li>
            </ul>
          </p>

          <p>
            Une analyse est réalisée pour déterminer les risques de perte et de
            rendement, pour chaque enveloppe fiscale (Plan Epargne Retraite,
            Assurance vie, etc.) et chaque bien immobilier.
          </p>
          <p>
            Cette projection des plus ou moins-values potentielles provient des
            données agrégées des supports d’investissement qui composent
            l’enveloppe fiscale (organisme de placement collectif en valeurs
            mobilières, fonds négocié en bourse, etc.). Le rendement des
            supports provient de 5 facteurs : la performance du marché
            monétaire, la performance provenant de l’indice de référence du
            fonds, la performance du marché immobilier, la gestion active et les
            frais.
          </p>

          <p>
            Tous les scénarios sont présentés hors frais, fiscalité et
            prélèvements sociaux.
          </p>

          <p>
            Les montants investis ne sont garantis ni à la hausse ni à la baisse
            car ils sont sujets aux fluctuations des marchés financiers et/ou du
            marché immobilier.
          </p>
          <p>
            Les simulations n’ont aucune valeur contractuelle et ne nous
            engagent aucunement dans la mesure où elles ne constituent pas une
            offre ferme de conclure un acte d’achat ou de vente.
          </p>
        </div>
      ),
    },
    riskProfile: "Basé sur un profil de risque",
    taxationSubCard: {
      title: "Votre économie d’impôts",
      totalTransaction: "Le premier mois",
      totalMonthlyTransaction: "Les mois suivants",
      tmi: "Basé sur un TMI de",
    },
    investmentSubCard: {
      title:
        "Investissez dans les produits BNP Paribas selon la répartition ci-dessous :",
      totalTransactionChart: {
        label: "1er versement",
      },
      totalMonthlyTransactionChart: {
        label: "Mensualités",
      },
      chartLegend: {
        [ProductTypeIdentifier.PEA]: "Plan Epargne Actions (PEA)",
        [ProductTypeIdentifier.PERIndividuel]: "Plan Epargne Retraite (PER)",
        [ProductTypeIdentifier.AssuranceVie]: "Assurance vie",
      },
    },
    imageGroup: {
      label: "Toute l’équipe est avec vous pour aller de l’avant.",
    },
    parametersCard: {
      title: "Personnalisez vos paramètres",
      prioritiesGroup: {
        taxReduction: "Réduire vos impôts dès aujourd’hui",
        savingAvailability: "Pouvoir récupérer votre capital",
        patrimonyTransmission: "Transmettre votre patrimoine",
      },
      errorsMessage: {
        tooLow: (amountMin: number) =>
          `La valeur doit être inférieure ou égale à ${amount(amountMin)}`,
        tooHigh: (amountMax: number) =>
          `La valeur doit être supérieure ou égale à ${amount(amountMax)}`,
        monthlyTooLow: (amountMin: number) =>
          `La valeur doit être égale à 0 sinon supérieure ou égale à ${amount(
            amountMin
          )}`,
        monthlyTooHigh: (amountMax: number) =>
          `La valeur doit être inférieure ou égale à ${amount(amountMax)}`,
      },
      atOpenning: "Votre premier versement",
      mensual: "Vos versements mensuels",
      retirementAge: "Votre âge de départ à la retraite",
      otherGroup: {
        riskProfile: "Votre profil de risque",
        ageAtRetirement: "Âge de départ à la retraite",
        liquidityAmountToInvest: "Votre versement initial",
        defaultValue: "Montant préconisé\u00A0:",
        maximum: "max",
      },
      updateButton: "Mettre à jour votre solution",
      resetLink: "Réinitialiser",
    },
    footerComponent: {
      label: "N’oubliez pas de visualiser votre rapport personnalisé",
      cta: "Visualiser votre rapport complet ",
    },
    productsTableComponent: {
      headers: {
        product: "Produits",
        openInvestment: "Premier versement\u00A0:",
        monthlyInvestment: "Versement mensuel\u00A0:",
      },
      detail: "Détail",
      footer: {
        label: "Soit des versements totaux de\u00A0:",
      },
    },
  },
  
  products: {    
    advertisingNature: "Communication à caractère publicitaire",
    excludeReason: {
      [ExclusionReason.MinimumGlobalPeriodicInvestmentAmount]:
        "Minimum non atteint",
      [ExclusionReason.MinimumInitialInvestmentAmount]:
        "Minimum non atteint",
      [ExclusionReason.MinimumPeriodicInvestmentAmount]:
        "Minimum non atteint",
      [ExclusionReason.NoInitialInvestment]: "Minimum non atteint",
      [ExclusionReason.InsufficientProductKnowledge]:
        "InsufficientProductKnowledge",
    },
    noAuthorize: "Cette enveloppe n'est pas adaptée à votre projet.",
    name: {
      [ProductTypeIdentifier.PEA]: "Plan d'Epargne en Actions (PEA)",
      [ProductTypeIdentifier.PERIndividuel]: "Plan d'Epargne Retraite (PER)",
      [ProductTypeIdentifier.AssuranceVie]: "Assurance vie",
    },
    desc: {
      [ProductTypeIdentifier.PEA]: "Diversification",
      [ProductTypeIdentifier.PERIndividuel]: "Défiscalisation",
      [ProductTypeIdentifier.AssuranceVie]: "Disponibilité du capital",
    },
    definition: {
      [ProductTypeIdentifier.PEA]: "Dynamisez votre portefeuille en investissant dans des valeurs mobilières françaises et européennes tout en bénéficiant d'un cadre fiscal avantageux (PEA)",
      [ProductTypeIdentifier.PERIndividuel]: "Préparez votre retraite simplement tout en réduisant vos impôts dès aujourd’hui avec le Plan d’Epargne Retraite (PER) !",
      [ProductTypeIdentifier.AssuranceVie]: "Un contrat d'assurance vie est un investissement sur le long terme. Il s'adapte à vos besoins et permet de faire fructifier son argent, afin d'en bénéficier au moment opportun. Il y a différentes raisons d'y souscrire, selon des objectifs distincts !",
    },
    link: {
      [ProductTypeIdentifier.PERIndividuel]:
        "En savoir plus sur l’économie d’impôt\u00A0?",
      [ProductTypeIdentifier.PEA]: "",
      [ProductTypeIdentifier.AssuranceVie]: "",
    },
    amountWarning: "Montant minimum non atteint",
  },
  riskProfiles: {
    [RiskProfile.Prudent]: "Prudent",
    [RiskProfile.Equilibre]: "Équilibré",
    [RiskProfile.Dynamique]: "Dynamique",
  },
  productSheetModal: {
    common: {
      labels: {
        advertisingNature: "Communication à caractère publicitaire",
        disclaimer: "Points d'attention",
      },
      links: {
        cost: "Frais",
        document: "Documentation",
      },
      ctas: {
        makeAnAppointment: "Prendre un RDV avec nos experts",
      },
    },
    product: Products,
  },
};

export type RecommendationI18nType = typeof i18n;
