import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { TmiConfig } from '../models/TmiConfig';

export async function getTmiConfigRequest(): Promise<TmiConfig[]> {
  const response = await axios.get<TmiConfigResponse>(apiUrls().tmiConfigs);
  const data = response.data!.results;

  // Order is not guaranteed by the backend
  // Sort TMIs in ascending order
  data.sort((a, b) => (a.tmi < b.tmi ? -1 : 1));

  return data;
}

//toDo test if correct
interface TmiConfigResponse {
  results: TmiConfig[];
}

