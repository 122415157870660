import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { useGlobalCommand } from '../../../shared/commands/useGlobalCommand';
import { useLoader, Module } from '../../../shared';
import {  useAppDispatch, useAppStore } from '../../../store';
import { setAnswers } from '../lifeProjectsSlice';
import { putProjectsRequest } from '../requests/putProjectsRequest';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../../shared/apiUrls';

export function useValidateCommand() {
  const dispatch = useAppDispatch();
  const { redirectAfterValidateCommand } = useGlobalCommand();
  const { showLoaderWhile } = useLoader();
  const { projectsValidateEvent } = useAnalytics();
  const store = useAppStore();
  const { customerid } = useParams()

  async function validateCommand() {
    await showLoaderWhile(async () => {
      const answers = store.getState().lifeProjects.answers;
      dispatch(setAnswers(answers));
      
      await putProjectsRequest(answers, customerid);
      projectsValidateEvent(answers);
      redirectAfterValidateCommand(Module.LifeProjects);
    }, {key: "lifeProjects_putProjectsRequest", value:`API PUT : ${apiUrls(customerid).lifeProjects.put}`});
  }

  return { validateCommand };
}
