import axios, { AxiosResponse } from 'axios';
import { apiUrls } from '../apiUrls';

export interface StepCompletion {
  stepName: string;
}

export async function defineStepCompletionRequest(
  stepName: string,
  customerid?: string,
): Promise<AxiosResponse<any>> {

  const apiUrl = apiUrls(customerid).completion.put(stepName);

  const response = await axios.put(apiUrl);

  const data = response.data;

  return data;
}
