import { useContext } from 'react';
import { ModalContext } from './ModalContext';

export function Modal() {
  const {
    content,
    show
  } = useContext(ModalContext);

  return (
    <>
      {show && content}
    </>
  );
}
