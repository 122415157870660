import { globali18n } from '../../../i18n';
import { setAmount } from '../../../shared/layouts/ProgressLayout/progressSlice';
import {  useAppDispatch, useAppStore } from '../../../store';

export function useUpgradeAmountCommand() {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  function upgradeAmountCommand() {
    const answers = store.getState().lifeProjects.answers;

    const total =
      answers.realEstateAssets +
        answers.pleasurePurchases +
        answers.philanthropy +
        answers.other +
        answers.family +
        answers.anticipation || 0;

    dispatch(setAmount({ amount: total, unit: globali18n.labels.euros }));
  }

  return { upgradeAmountCommand };
}
