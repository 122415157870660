import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { globali18n } from '../../../i18n';
import { Button, ButtonStyles, ModalOutcome, useModal } from '../../';
import { StyledKendoModal } from '../KendoModal/KendoModal';
import styles from './ConfirmModal.module.scss';

interface ConfirmModalProps {
  title: string;
  body: JSX.Element;

  okText?: string;
  cancelText?: string;
}

function ConfirmModal(props: ConfirmModalProps) {
  const { hideModal } = useModal();

  return (
    <>
      <StyledKendoModal
        className={styles.Dialog}
        title={props.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'750px'}
      >
        <div className={styles.ContentGrid} id={"modal-content"} tabIndex={-1}>{props.body}</div>
        <DialogActionsBar>
          <Button
            className={ButtonStyles.SecondaryAction}
            onClick={() => hideModal(ModalOutcome.Cancel)}
          >
            {props.cancelText ?? globali18n.labels.cancel}
          </Button>
          <Button className={ButtonStyles.PrimaryAction} onClick={() => hideModal(ModalOutcome.Ok)}>
            {props.okText ?? 'Ok'}
          </Button>
        </DialogActionsBar>
      </StyledKendoModal>
    </>
  );
}

export function useConfirmModal() {
  const { showModal } = useModal();

  function showConfirm(title: string, content: JSX.Element, okText?: string, cancelText?: string) {
    return showModal(
      <ConfirmModal title={title} body={content} okText={okText} cancelText={cancelText} />
    );
  }

  return { showConfirm };
}
