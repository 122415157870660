import { AnalyticsComponent } from '@ifs/libs/src/shared/components/datalayer/AnalyticsComponent';
import { PageRoute } from '@ifs/libs/src/shared/routing/PageRoute';
import { Route, Routes } from 'react-router-dom';
import { i18n } from './i18n';
import { ProfilePage } from './pages';


export function ProfileModule() {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <PageRoute
            pageTitle={i18n.profilePage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={i18n.profilePage.pageTitle}
                page_path={'/profile'}
                page_type={'profile'}
                simulator_screen={'profile'}
                simulator_step={''}
                simulator_type={""}
              >
                <ProfilePage />
              </AnalyticsComponent>
            }
          />
        }
      />
    </Routes>
  );
}
