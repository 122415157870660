import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Input,
  InputHandle,
  NumericTextBox,
  NumericTextBoxHandle,
  Switch,
} from "@progress/kendo-react-inputs";
import classNames from "classnames";
import { globali18n } from "../../../../i18n";
import { i18n } from "../../../../modules/patrimony/i18n";
import { ModalMode } from "../../../../modules/patrimony/models/ModalMode";
import { Controller, useForm } from "react-hook-form";
import {
  ButtonStyles,
  ModalOutcome,
  StyledKendoModal,
  useModal,
  ValidationMessages,
  Button,
} from "../../../../shared";
import { getValidationMessages } from "../../../../shared/utilities/getValidationMessages";
import styles from "./RealEstateModal.module.scss";
import { useRef } from "react";
import { getFormConfig } from "../SavingsModal/formConfig";
import { GetRealEstateResponseModel } from "../../../../modules/patrimony/models/RealEstateModel";

interface FormData {
  identifier?: number;
  name: string;
  currentAmount: number;
  isMainResidence: boolean;
}

export interface RealEstateModalProps {
  mode: ModalMode;
  title: string;
  realEstate: Partial<GetRealEstateResponseModel>;
}

export function RealEstateModal(props: RealEstateModalProps) {
  const { hideModal } = useModal();
  const realEstateNameInput = useRef<InputHandle>(null);
  const currentAmountInput = useRef<NumericTextBoxHandle>(null);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: {...props.realEstate, isMainResidence: props?.realEstate!.isMainResidence || false},
  });

  function submit() {
    hideModal(ModalOutcome.Ok, getValues());
    focusButton();
  }

  const t = i18n.realEstateModal;

  const config = getFormConfig();

  const switchControl = {
    onLabel: "",
    offLabel: "",
  };

  function increase() {
    document.getElementById("increase-button")!.focus();
    const current = getValues().currentAmount ?? 0;

    setValue("currentAmount", current + 5000);
  }

  function decrease() {
    document.getElementById("decrease-button")!.focus();
    const current = getValues().currentAmount ?? 0;

    if (current != 0) {
      setValue("currentAmount", current - 5000);
    }

    if (current <= 4999) {
      setValue("currentAmount", 0);
    }
  }

  function focusButton() {
    document.getElementById("add-real-estate-button")!.focus();
  }

  return (
    <StyledKendoModal
      onClose={() => {
        hideModal(ModalOutcome.Cancel, null);
        focusButton();
      }}
      title={""}
      width={"700px"}
    >
      <form noValidate>
        <div className={styles.ContentGrid}>
          <div className={styles.ModalTitle}>{t.modalTitle}</div>
          <hr />
          <div className={styles.SectionHeading}>{t.description}</div>
          <div className={styles.InputGroup}>
            <span>
              <Controller
                control={control}
                name="name"
                rules={config.name.rules}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    ref={realEstateNameInput}
                    placeholder={t.placeholder}
                    className={styles.Input}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    value={value}
                    maxLength={200}
                  />
                )}
              />
              <div className={styles.ValidationMessageError}>
                <ValidationMessages
                  messages={getValidationMessages(
                    "name",
                    errors,
                    config.name.errorMessages
                  )}
                />
              </div>
            </span>

            <Controller
              control={control}
              name="isMainResidence"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className={styles.SwitchContainer}>
                  <div className={styles.SwitchGroup}>
                    <Switch
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      checked={value}
                      {...switchControl}
                      className={styles.Switch}
                    />
                    <div
                      className={classNames({
                        [styles.SwitchLabel]: !value,
                        [styles.SwitchLabelActive]: value,
                      })}
                    >
                      {t.isMainResidenceSwitch}
                    </div>
                  </div>

                  <div className={styles.ResidenceText}>
                    {t.isMainResidenceText}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        <div className={styles.ContentGrid}>
          <div className={styles.SectionHeading}>{t.estimation}</div>

          <div className={styles.InputRow}>
            <Button
              className={classNames(ButtonStyles.Round, styles.NumericAction)}
              onClick={() => decrease()}
              type="button"
              id="decrease-button"
            >
              -
            </Button>
            <Controller
              control={control}
              name="currentAmount"
              rules={config.currentAmount.rules}
              render={({ field: { onChange, onBlur, value } }) => (
                <NumericTextBox
                  ref={currentAmountInput}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  value={value}
                  spinners={false}
                  format={{ maximumFractionDigits: 0 }}
                  defaultValue={0}
                />
              )}
            />
            <div className={styles.EuroLabel}>{"€"}</div>
            <Button
              className={classNames(ButtonStyles.Round, styles.NumericAction)}
              onClick={() => increase()}
              type="button"
              id="increase-button"
            >
              +
            </Button>
          </div>
          <ValidationMessages
            messages={getValidationMessages(
              "currentAmount",
              errors,
              config.currentAmount.errorMessages
            )}
          />
        </div>
        <DialogActionsBar>
          {props.mode === ModalMode.New ? (
            <div className={styles.ButtonBox}>
              <Button
                className={ButtonStyles.Unfill}
                onClick={() => {
                  hideModal(ModalOutcome.Cancel);
                  focusButton();
                }}
              >
                {globali18n.labels.cancel}
              </Button>
              <Button
                className={ButtonStyles.PrimaryAction}
                onClick={handleSubmit(submit)}
              >
                {globali18n.labels.add}
              </Button>
            </div>
          ) : (
            <div className={styles.ButtonBox}>
              <Button
                className={ButtonStyles.Unfill}
                onClick={() => {
                  hideModal(ModalOutcome.Other);
                  focusButton();
                }}
              >
                {globali18n.labels.delete}
              </Button>

              <Button
                className={ButtonStyles.Unfill}
                onClick={() => {
                  hideModal(ModalOutcome.Cancel);
                  focusButton();
                }}
              >
                {globali18n.labels.cancel}
              </Button>

              <Button
                className={ButtonStyles.PrimaryAction}
                onClick={handleSubmit(submit)}
              >
                {globali18n.labels.validate}
              </Button>
            </div>
          )}
        </DialogActionsBar>
      </form>
    </StyledKendoModal>
  );
}
