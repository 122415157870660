import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "../../shared/components/Theme/ThemeProvider";
import { ProgressRoute } from "../../shared/routing/ProgressRoute";
import { ModuleColor } from "../../styles/colors";
import { i18n } from "./i18n";
import { useResolver } from "./routing/resolver";
import * as pages from "./pages";
import { globali18n } from "../../i18n";
import { Module } from "../../shared/models/Module";
import { AnalyticsComponent } from "../../shared/components/datalayer/AnalyticsComponent";
import { PageRoute } from "../../shared/routing/PageRoute";

export function TaxationModule() {
  const { loading } = useResolver();

  const moduleProps = {
    exact: true,
    title: i18n.module,
    maxStep: 3,
  };

  const summaryProps = {
    ...moduleProps,
    step: 3,
    subtitle: i18n.categories.synthesis,
  };

  if (loading) {
    return null;
  }

  const {
    LandingPage,
    TaxAvailablePage,
    TaxRatePage
  } = pages;

  return (
    <ThemeProvider
      theme={{
        backgroundColor: ModuleColor[Module.Taxation],
        backgroundColorLigther: ModuleColor.TaxationLighter,
      }}
    >
      <Routes>
        <Route
          path={`/`}
          element={
            <AnalyticsComponent
              page_title={i18n.landingPage.pageTitle}
              page_path={"/taxation"}
              page_type={"homepage"}
              simulator_screen={"Landing Page"}
              simulator_step={""}
              simulator_type={i18n.module}
            >
              <PageRoute
                pageTitle={i18n.landingPage.pageTitle}
                element={<LandingPage />}
              />
            </AnalyticsComponent>
          }
        />

      <Route
          path={`/tax-available`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={1}
              pageTitle={i18n.taxAvailablePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.taxAvailablePage.pageTitle}
                  page_path={"/taxation/tax-available"}
                  page_type={"simulateur"}
                  simulator_screen={"tax-available"}
                  simulator_step={"1/3"}
                  simulator_type={i18n.module}
                >
                  <TaxAvailablePage />
                </AnalyticsComponent>
              }
            />
          }
        />
         <Route
          path={`/tax-rate`}
          element={
            <ProgressRoute
              {...moduleProps}
              step={2}
              pageTitle={i18n.taxAvailablePage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.taxAvailablePage.pageTitle}
                  page_path={"/taxation/tax-rate"}
                  page_type={"simulateur"}
                  simulator_screen={"tax-rate"}
                  simulator_step={"2/3"}
                  simulator_type={i18n.module}
                >
                  <TaxRatePage />
                </AnalyticsComponent>
              }
            />
          }
        /> 
        
        <Route
          path={`/summary`}
          element={
            <ProgressRoute
              {...summaryProps}
              step={3}
              subtitle={globali18n.labels.summary}
              pageTitle={i18n.summaryPage.pageTitle}
              element={
                <AnalyticsComponent
                  page_title={i18n.summaryPage.pageTitle}
                  page_path={"/taxation/summary"}
                  page_type={"summary"}
                  simulator_screen={"summary"}
                  simulator_step={"3/3"}
                  simulator_type={i18n.module}
                >
                  <pages.SummaryPage />
                </AnalyticsComponent>
              }
            />
          }
        />        
      </Routes>
    </ThemeProvider>
  );
}
