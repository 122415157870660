import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";
import { GetFinancialProfiles } from "../models/FinancialProfileModel";

export async function getSearchFinancialProfile(customerId: string | undefined): Promise<GetFinancialProfiles> {
  const apiUrl =
    apiUrls(customerId).investorProfile.financialProfile.searchFinancialProfile;
  const response = await axios.get<GetFinancialProfiles>(apiUrl, {params: { limit: 1, sortBy: "ValidatedAt Desc, CreatedAt Desc" }});

  const data = response.data;

  return data;
}
