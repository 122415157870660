import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  internalUrls
} from '@ifs/libs/src/shared/utilities/urls';
import { Module, useGetDashboardModel, useGlobalCommand } from '@ifs/libs';
import { useDetermineModuleStatus } from '@ifs/libs/src/modules/dashboard/utilities/determineModuleStatuses';
import { ModuleStatus } from '@ifs/libs/src/modules/dashboard/models/ModuleStatus';
import { useAppSelector } from 'store';
import { UserType } from '@ifs/libs/src/shared/requests/getUserReferenceRequest';

export function ModuleGuardedRoute({ children }: any) {
  const navigate = useNavigate();
  const userType = useAppSelector(
    (s) => s.authentication.userType
  );
  const { pathname } = useLocation();
  const { customerid } = useParams();
  const { determineModuleStatus } = useDetermineModuleStatus();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const { getDashboardModelCommand } = useGetDashboardModel();

  const [renderChildren, setRenderChildren] = useState(false);
  async function checkCanAccessToModule() {
    const dashboard = await getDashboardModelCommand(false, customerid);
    const statuses = determineModuleStatus(dashboard);
    // Need to only get base uri => "/legal-pension/children" => legal-pension
    const path = (pathname || '').split('/').filter((path) => path)[0];
    // Get module to determine is user can access to this url
    const module = getModule(path);

    // CASE 1: Route is no a module route, redirect to Dashboard
    if(!module) {        
      navigate(internalUrls.dashboard);
      return;
    }
    
    // Get Module status
    const status = statuses[module];

    // CASE 2: Route module is not accessible, redirect to Dashboard    
    if (status === ModuleStatus.CompletedAndLocked || status === ModuleStatus.Locked || status === ModuleStatus.DashboardUnavailableAndRouteGuardLocked) {
        navigate(internalUrls.dashboard);
        return;
    } 

     // CASE *: Special case link to the particular tenant that have a recommendation static
     if(isTenantRecommendationModeStatic()) {
      if(module === Module.Taxation) {
        // Prospect user cannot have acces to this module
        if(userType === UserType.Prospect) {
          navigate(internalUrls.dashboard);
          return;
        }
      }
    }
        
    // Case 3: Module is completed
    setRenderChildren(true);
    return;

    function getModule(path: string): Module | null {
      switch (path) {
        case 'pension-need':
          return Module.PensionNeed;
        case 'legal-pension':
          return Module.LegalPension;
        case 'taxation':
          return Module.Taxation;
        case 'life-projects':
          return Module.LifeProjects;
        case 'patrimony':
          return Module.Patrimony;
        case 'investor-profile':
          return Module.InvestorProfile;
        default:
          return null;
      }
    }
  }

  useEffect(() => {
    checkCanAccessToModule();
  }, [pathname]);

  if (renderChildren) {
    return children;
  }

  return null;
}
