import { ModalOutcome, StyledKendoModal, useModal } from '../../../../../../shared';
import { i18n } from '../../../../i18n';

export function CivilServantModal() {
  const { hideModal } = useModal();

  return (
    <>
      <StyledKendoModal
        title={i18n.currentRevenuePage.civilServantModal.title}
        onClose={() => hideModal(ModalOutcome.Cancel, null)}
        width={'500px'}
      >
        <div id={"modal-content"} tabIndex={-1}>
          {i18n.currentRevenuePage.civilServantModal.content}

        </div>
      </StyledKendoModal>
    </>
  );
}
