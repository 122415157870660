import styles from './ValidationMessages.module.scss';

interface ValidationMessagesProps {
  messages: string[];
}

export function ValidationMessages(props: ValidationMessagesProps) {
  if (props.messages.length === 0) {
    return null;
  }

  return (
    <span className={styles.Message}>
      {props.messages.map((m) => (
        <span key={m}>{m}</span>
      ))}
    </span>
  );
}
