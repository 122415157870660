import { TransportType } from './models/transport/transport-type.enum';
import { Persona } from './pages/personas/PersonaEnum';

export interface PensionNeedPageTranslation {
  pageTitle: string;
  question: string;
  answer1: string;
  answer2: string;
  answer3?: string;
  answer4?: string;
  definedValue?: string;
  definedValueModal?: {
    title: string;
    content?: JSX.Element;
  };
}

export const i18n = {
  module: 'Vos besoins à la retraite',

  categories: {
    housing: 'Logement',
    transport: 'Transports',
    supply: 'Alimentation',
    other: 'Loisirs',
    health: 'Impondérables',
    synthesis: 'Synthèse'
  },

  transportType: {
    [TransportType.Car]: 'La voiture',
    [TransportType.Bike]: 'Le vélo / la trottinette',
    [TransportType.PublicTransportation]: 'Les transports en commun',
    [TransportType.Motorbike]: 'La moto'
  },

  landingPage: {
    pageTitle: 'Vos besoins à la retraite',
    title: 'Estimez vos dépenses à la retraite en quelques clics\u00A0!',
    content: (
      <p>
        Laissez-vous inspirer par nos propositions de styles de vie ou répondez à notre
        questionnaire détaillé.
      </p>
    ),
    info: ' Les données utilisées dans cette partie sont à titre indicatif afin de vous aider à définir le montant de vos besoins à la retraite. Simulation simplifiée et non engageante.'
  },
  personaPage: {
    pageTitle: 'Personas',
    question: 'Choisissez votre style de vie idéal à la retraite',
    description:
      'Laissez-vous inspirer par un profil qui vous correspond pour estimer vos dépenses à la retraite',
    customProfileText:
      'Si vous ne trouvez pas un style de vie qui vous correspond, définissez vos besoins grâce à notre questionnaire.',
    title: {
      [Persona.Family]: 'Zen',
      [Persona.Hyperactive]: 'Dynamique',
      [Persona.Globetrotter]: 'Voyageur'
    },
    alts: {
  [Persona.Family]: 'Famille',
      [Persona.Hyperactive]: 'Hyper active',
      [Persona.Globetrotter]: 'Globe Trotter'
    },
    buttons: {
      customProfile: 'Définir un style de vie personnalisé',
      selectProfile: 'Sélectionner'
    }
  },

  birthdatePage: {
    pageTitle: 'Date de naissance'
  },
  housingLocationTypePage: {
    pageTitle: 'Lieu de résidence',
    question: 'Demain, votre coin de paradis sera\u00A0:',
    answer1: 'Au vert, à la campagne',
    answer2: 'À la ville, à portée de tout',
    answer3: 'À la montagne, en altitude',
    answer4: 'À la mer, au grand large',
    definedValue: 'Vous connaissez votre futur budget logement\u00A0?',
    definedValueModal: {
      title: 'Budget logement',
      content: (
        <>
          <b>Renseignez l'ensemble des dépenses liées à votre futur logement&nbsp;:</b> mensualité
          de crédit, loyer, prime d'assurance, taxes foncières et habitation, électricité, gaz, etc.
          que vous soyez locataire ou propriétaire.
        </>
      )
    }
  },
  housingTransactionTypePage: {
    pageTitle: 'Propriété ou location',
    question: 'Propriétaire ou locataire\u00A0?',
    answer1: 'Propriétaire sans prêt à rembourser',
    answer2: 'Propriétaire avec prêt à rembourser',
    answer3: 'Locataire'
  },
  housingCitySizePage: {
    pageTitle: 'Taille de la ville',
    question: 'Quel type de ville pour votre retraite citadine\u00A0?',
    answer1: 'Petite',
    answer2: 'Moyenne (Angers, Orléans, La Rochelle, etc.)',
    answer3: 'Grande (Marseille, Lille, Lyon, etc.)',
    answer4: 'En Île-de-France'
  },
  housingTypePage: {
    pageTitle: 'Type de logement',
    question: 'Dans quel type de logement\u00A0?',
    answer1: 'Une maison',
    answer2: 'Un appartement'
  },
  housingSizePage: {
    pageTitle: 'Taille du logement',
    question: 'Petit cocon ou grand espace\u00A0?',
    answer1: '< 60 m²',
    answer2: '60-120 m²',
    answer3: '> 120 m²'
  },
  transportTypesPage: {
    pageTitle: 'Modes de transport',
    question: 'Comment vous déplacerez-vous\u00A0?',
    description: (
      <>
        <p>
          Qui sait&nbsp;? Peut-être qu'un jour nous serons tous en skateboard volant. En attendant,
          basez-vous sur vos habitudes et vos souhaits.
        </p>
      </>
    ),
    atLeastOne: 'Veuillez choisir au moins un type de transport'
  },
  transportCarPage: {
    pageTitle: 'Voiture',
    question: 'Quelle voiture pour vos déplacements\u00A0?',
    'answer.info':
      'Le montant inclut l’ensemble des dépenses liées à votre voiture (crédit, assurance, parking, entretien, etc.)',
    answer1: 'Citadine',
    answer2: 'Moyenne gamme (berline, compact SUV, etc.)',
    answer3: 'Haut de gamme (sport, luxe, etc.)',
    definedValue: 'Vous connaissez votre futur budget voiture\u00A0?',
    definedValueModal: {
      title: 'Budget voiture',
      content: (
        <>
          <b>Renseignez l'ensemble des dépenses liées à votre voiture&nbsp;:</b> prime d'assurance,
          entretien, carburant, etc.
        </>
      )
    }
  },
  transportPublicPage: {
    pageTitle: 'Transports en commun',
    question: 'Votre usage des transports en commun\u00A0?',
    answer1: 'Très occasionnellement',
    answer2: 'Quelques fois par semaine',
    answer3: 'Plusieurs fois par jour',
    definedValue: 'Vous connaissez votre futur budget transports en commun\u00A0?',
    definedValueModal: {
      title: 'Budget transport en commun',
      content: (
        <>
          <b>
            Renseignez l'ensemble des dépenses liées à votre usage des transports en commun&nbsp;:
          </b>{' '}
          abonnement, tickets unitaires etc.
        </>
      )
    }
  },
  transportMotorbikePage: {
    pageTitle: 'Moto',
    question: 'Vous chevaucherez votre moto…',
    answer1: 'Uniquement quand il fait beau',
    answer2: 'Le week-end seulement',
    answer3: 'Tous les jours',
    definedValue: 'Vous connaissez votre futur budget moto\u00A0?',
    definedValueModal: {
      title: 'Budget moto',
      content: (
        <>
          <b>Renseignez l'ensemble des dépenses liées à votre deux-roues&nbsp;:</b> prime
          d'assurance, entretien, carburant, etc.
        </>
      )
    }
  },
  transportBikePage: {
    pageTitle: 'Vélo',
    question: 'Quel style de vélo / trottinette\u00A0?',
    answer1: 'Traditionnel',
    answer2: 'En libre-service',
    answer3: 'Électrique',
    definedValue: 'Vous connaissez votre futur budget vélo / trottinette\u00A0?',
    definedValueModal: {
      title: 'Budget vélo / trottinette',
      content: (
        <>
          <b>Renseignez l'ensemble des dépenses liées à votre vélo / trottinette&nbsp;:</b> entretien, abonnement,
          équipement, etc.
        </>
      )
    }
  },
  supplyRestaurantPage: {
    pageTitle: 'Restaurant',
    question: 'Les restaurants et livraisons à domicile, ce sera\u00A0:',
    answer1: 'Pour les très grandes occasions\u00A0: 1 fois par an',
    answer2: 'Régulièrement\u00A0: 1 fois par mois',
    answer3: 'La sortie de la semaine\u00A0: 1 fois par semaine',
    definedValue: 'Vous connaissez votre futur budget restauration\u00A0?',
    definedValueModal: {
      title: 'Budget restauration'
    }
  },
  supplyHomePage: {
    pageTitle: 'Cuisine à la maison',
    question: 'En cuisine, à la maison\u00A0:',
    answer1: 'Vous ferez attention aux quantités',
    answer2: 'Vous mettrez les petits plats dans les grands',
    answer3: 'Vous recevrez très souvent car vous aimez faire plaisir',
    definedValue: 'Vous connaissez votre futur budget alimentation\u00A0?',
    definedValueModal: {
      title: 'Budget cuisine à la maison'
    }
  },
  otherTravelsPage: {
    pageTitle: 'Voyages',
    question: 'Vous plierez souvent bagage\u00A0?',
    answer1: 'Home sweet home\u00A0: vous serez casaniers (4 voyages par an maximum)',
    answer2: 'Enfin du temps pour voyager régulièrement\u00A0! (6 voyages par an)',
    answer3: 'Parcourir le monde, c’est votre truc\u00A0! (8 voyages par an ou plus)',
    definedValue: 'Vous connaissez votre futur budget voyages\u00A0?',
    definedValueModal: {
      title: 'Budget voyages'
    }
  },
  otherSportsPage: {
    pageTitle: 'Sports',
    question: 'Farniente ou sport à gogo\u00A0?',
    answer1: 'Le sport : très peu pour vous\u00A0!',
    answer2: 'Vous prolongerez vos abonnements à vos clubs de tennis ou de gym',
    answer3: 'Toujours en action\u00A0: équitation, golf, ski, plongée, etc.\u00A0!',
    definedValue: 'Vous connaissez votre futur budget sport\u00A0?',
    definedValueModal: {
      title: 'Budget sport'
    }
  },
  otherHobbiesPage: {
    pageTitle: 'Hobbies',
    question: 'Le temps libre pour vos hobbies sera consacré à…',
    answer1: 'Des loisirs simples\u00A0: potager, bricolage, randonnée, etc.',
    answer2: 'Des sorties tous azimuts\u00A0: cinéma, spectacles, expositions, etc.',
    answer3: 'Des loisirs trois étoiles\u00A0: à vous les casinos à Las Vegas\u00A0!',
    definedValue: 'Vous connaissez votre futur budget loisirs\u00A0?',
    definedValueModal: {
      title: 'Budget loisirs'
    }
  },
  healthMedicalPage: {
    pageTitle: 'Santé',
    question: 'Et pour votre santé\u00A0?',
    answer1: 'Vos dépenses se borneront au coût de votre mutuelle complémentaire',
    answer2: 'Vous consulterez fréquemment des spécialistes',
    answer3: 'Votre santé n’a pas de prix',
    definedValue: 'Vous connaissez votre futur budget santé\u00A0?',
    definedValueModal: {
      title: 'Budget santé'
    }
  },
  healthUnexpectedPage: {
    pageTitle: 'Épargne de précaution',
    question: 'Enfin, dernier poste de votre budget\u00A0: Une poire pour la soif\u00A0?',
    answer1: 'Vous n’aurez pas d’argent disponible de côté',
    answer2: 'Vous serez prévoyant avec une réserve spéciale',
    answer3: 'Vous voudrez faire face à tous les imprévus',
    definedValue: 'Vous connaissez votre futur budget de précaution\u00A0?',
    definedValueModal: {
      title: 'Budget de précaution'
    }
  },

  summaryPage: {
    pageTitle: 'Projet retraite',
    title: 'Grâce à vos réponses, notre simulateur estime vos besoins futurs à\u00A0:',
    restart: 'Recommencer le questionnaire',
    footer:
      'Notre estimation se base sur des données indicatives pour vous aider à calculer vos dépenses futures. Simulation simplifiée et non engageante.',
    information: 'Il s’agit d’une évaluation indicative que vous pouvez modifier.',
    editChoise: 'Recommencer',
    modal: {
      housing: {
        title: 'Estimation du budget logement',
        content: (
          <>
            <p>
              Le budget logement comporte une estimation intégrant l'inflation des postes de
              dépenses suivants:
            </p>
            <p>
              <ul>
                <li>Mensualité de crédit, loyer</li>
                <li>Prime d'assurance</li>
                <li>Taxes foncière et habitation</li>
                <li>Abonnement internet, téléphone et multimédias</li>
                <li>Electricité, gaz, etc. que vous soyez locataire ou propriétaire.</li>
              </ul>
            </p>
          </>
        )
      },
      transport: {
        title: 'Estimation du budget transports',
        content: (
          <>
            <p>
              Le budget transports comporte une estimation intégrant l'inflation des postes de
              dépenses suivants:
            </p>
            <p>
              <ul>
                <li>Achat ou amortissement de votre moyen de transport</li>
                <li>Prime d'assurance</li>
                <li>Entretien, réparations</li>
                <li>Equipement</li>
                <li>Carburant</li>
                <li>Abonnement</li>
                <li>Tickets unitaires</li>
                <li>Taxis, VTC</li>
              </ul>
            </p>
          </>
        )
      },
      supply: {
        title: 'Estimation du budget alimentation',
        content: (
          <>
            <p>
              Le budget alimentation comporte une estimation intégrant l'inflation des postes de
              dépenses suivants:
            </p>
            <p>
              <ul>
                <li>Restaurants</li>
                <li>Courses alimentaires</li>
                <li>Boissons, Alcool</li>
                <li>Cantine pour les enfants</li>
                <li>Marché bio</li>
              </ul>
            </p>
          </>
        )
      },
      hobbies: {
        title: 'Estimation du budget loisirs',
        content: (
          <>
            <p>
              Le budget loisirs comporte une estimation intégrant l'inflation des postes de dépenses
              suivants:
            </p>
            <p>
              <ul>
                <li>Voyages (déplacement en train, avion, hôtel, location de vacances)</li>
                <li>Activités sportives (abonnement, matériel)</li>
                <li>Sorties culturelles (cinéma, théâtre, concert)</li>
                <li>Shopping (vêtements)</li>
              </ul>
            </p>
          </>
        )
      },
      health: {
        title: 'Estimation du budget impondérables',
        content: (
          <>
            <p>
              Le budget impondérables comporte une estimation intégrant l'inflation des postes de
              dépenses suivants:
            </p>
            <p>
              <ul>
                <li>Impôts</li>
                <li>Frais médicaux (mutuelle santé, optique, dentiste, spécialistes)</li>
                <li>Budget de précaution</li>
              </ul>
            </p>
          </>
        )
      }
    }
  },
  createPensionNeedPage: {
    or: 'Ou'
  }
};
