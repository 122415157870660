import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProgressState {
  title: string | null;
  subtitle: string | null;
  step: number | null;
  maxStep: number | null;
  showSteps: boolean | null;
  hideProgressBar: boolean | null;
  amount: number | null;
  unit: string | null;
  showAmount: boolean | null;
  duration: number | null;
}

const initialState: ProgressState = {
  title: null,
  subtitle: null,
  step: null,
  maxStep: null,
  showSteps: null,
  hideProgressBar: null,
  amount: null,
  unit: null,
  duration: null,
  showAmount: null
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState: initialState,
  reducers: {
    setProgressInfo: (state, action: PayloadAction<Partial<ProgressState>>) => {
      state.title = action.payload.title ?? null;
      state.subtitle = action.payload.subtitle ?? null;
      state.step = action.payload.step ?? null;
      state.maxStep = action.payload.maxStep ?? null;
      state.showSteps = action.payload.showSteps ?? null;
      state.hideProgressBar = action.payload.hideProgressBar ?? null;
      state.duration = action.payload.duration ?? null;
      state.showAmount = action.payload.showAmount ?? null;
    },
    setAmount: (state, action: PayloadAction<{ amount: number | null; unit: string | null }>) => {
      state.amount = action.payload.amount;
      state.unit = action.payload.unit;
    },
    resetProgress: () => {
      return { ...initialState };
    }
  }
});

export const { setProgressInfo, resetProgress, setAmount } = progressSlice.actions;
export const progressReducer = progressSlice.reducer;
