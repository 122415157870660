import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function ErrorBoundaryFallbackComponent(props: PropsWithChildren<ErrorBoundaryFallbackComponentProps>) {
    const location = useLocation();
    const [previousLocationPathName, setPreviousLocationPathName] = useState('');
  
    // Reset error-boundary when user instanciate a navigation 
    // If We don't reset the error boundary, the navigation ended correctly but the wiew will stay on the ErrorFallbackComponent
    useEffect(() => {
      if (location.pathname && previousLocationPathName) {
        props.resetErrorBoundary();
      }
      setPreviousLocationPathName(location.pathname);
    }, [location]);
  
    return (
     <>
      {props.children}
     </>
    )
  }

  type ErrorBoundaryFallbackComponentProps = {
    resetErrorBoundary: (...args: Array<unknown>) => void;
  }