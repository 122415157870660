import { apiUrls } from '../../../shared/apiUrls';
import { useAnalytics } from '../../../shared/commands/useAnalytics';
import { useGlobalCommand } from '../../../shared/commands/useGlobalCommand';
import { useLoader } from '../../../shared/components/Loader/useLoader';
import { Module } from '../../../shared/models/Module';
import { useAppStore } from '../../../store';
import { PensionNeedValidationsBody } from '../models/request/validationRequest.model';
import { validateRequest } from '../requests/validateRequest';
import { EGamificationType } from "../enum";

export function useValidateCommand() {
  const { showLoaderWhile } = useLoader();
  const { redirectAfterValidateCommand } = useGlobalCommand();
  const { needsValidateEvent } = useAnalytics();
  const store = useAppStore();
  
  async function validateCommand(customerid?: string) {
    await showLoaderWhile(async () => {
      const { computationId, gamifiedValues } = store.getState().pensionNeed;
      // Remove the weight property from gamifiedValues state model
      const gamifiedValue = gamifiedValues ? gamifiedValues?.map((val)=> { return {categoryId: val.categoryId, value: val.value}}) : null;
      const payload : PensionNeedValidationsBody = {
        $type: EGamificationType.Detailed,
        computationId: computationId || '',
        gamifiedValue
      }

      await validateRequest(payload!, customerid);
      needsValidateEvent(gamifiedValues!);
      await redirectAfterValidateCommand(Module.PensionNeed);
    }, {key: "pensionNeed_useValidateCommand", value:`API POST - ${apiUrls(customerid).pensionNeed.validations}`});
  }

  return { validateCommand };
}
