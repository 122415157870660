import { useEffect, useState } from "react";
import { apiUrls } from "../../../shared/apiUrls";
import { useLoader } from "../../../shared";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  resetDashboard,
  setDashboardPresented,
  setExtraMetadata,
  setModel,
} from "../dashboardSlice";
import { getDashboardRequest } from "../requests/getDashboardRequest";
import { getTaxationRequest } from "../../../modules/taxation/requests/getTaxationRequest";

export function useResolver(customerid?: string) {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const dashboardPrevented = useAppSelector((s) => s.dashboard.presented)!;
  
  async function setup() {
    showLoaderWhile(
      async () => {
        // Load the dashboard data
        const model = await getDashboardRequest(customerid);
        dispatch(setModel(model));
        dispatch(setDashboardPresented(dashboardPrevented));

        // toDo (Next release) ask Back to return taxation deduction on dashboard model
        const taxationResult = await getTaxationRequest(customerid);
        dispatch(setExtraMetadata({householdMaximumTaxDeduction: taxationResult?.householdMaximumTaxDeduction || null}));

        setLoading(false);
      },
      {
        key: "resolverDashboard",
        value: `API GET - ${apiUrls(customerid).dashboard}`,
      }
    );
  }

  async function cleanup() {
    dispatch(resetDashboard());

    //Do not cleanup the dashboardPresented
    dispatch(setDashboardPresented(dashboardPrevented));
  }

  useEffect(() => {
    setup();
    return () => {
      //ToDo test to check if still needed
      cleanup();
      // This seems to be required in order to prevent repeated crashes during development
      // The hot reload of CRA runs the effect cleanup routines of the 'old'
      // components but transfers their state to the 'new' ones
      // Which leads to components erroneously thinking that their data are loaded
      // (loading is false, but slice has been reset)
      setLoading(true);
    };
  }, []);

  return { loading };
}
