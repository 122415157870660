import { Container, PageTitle } from '@ifs/libs';
import { i18n } from '../../i18n';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { BackgroundImage } from 'modules/registration/components/BackgroundImage/BackgroundImage';
import { useEffect } from 'react';
import { useAuthenticateSSOCommand } from '@ifs/libs/src/shared';

export function AuthenticatePage() {
  const { redirectToAuthenticateSSO } = useAuthenticateSSOCommand();

  useEffect(() => {
    redirectToAuthenticateSSO(`${window.location.origin}/check-customer`);
  }, []);

  return (
    <Container className={ModuleStyles.Grid}>
      <BackgroundImage />
    </Container>
  );
}