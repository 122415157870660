import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import { AccountActivationForm } from 'modules/registration/components/AccountActivationForm/AccountActivationForm';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { Container } from '@ifs/libs';


export function AccountActivationPage() {
  const searchParams = useSearchParams();
  const email = searchParams.get('email') || "";
  const sessionId = searchParams.get('sessionId')|| "";

  return (
    <Container className={ModuleStyles.Grid}>
        <AccountActivationForm sessionId={sessionId} email={email} />
    </Container>
  );
}
