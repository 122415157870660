import classNames from "classnames";
import { i18n } from "../../../../modules/recommendation/i18n";
import {
  RecommendationOutputModel
} from "../../../../modules/recommendation/models/RecommendationModel";
import { useEffect, useState } from "react";
import styles from "./ProductsTable.module.scss";
import {
  Button,
  ButtonStyles,
  ModalOutcome,
  SvgWrapperComponent,
  useModal,
} from "../../../../shared";
import { amount as formatAmount } from "../../../../shared/utilities/intl";
import { ReactComponent as LockIcon } from "../../pages/RecommendationPage/images/lock.svg";
import { EyeIcon } from "../../../../shared/images";
import { TaxSavingMoreInfoModal } from "./components/TaxSavingMoreInfoModal/TaxSavingMoreInfoModal";
import { globali18n } from "../../../../i18n";
import { keyDownEvent } from "../../../../shared/utilities/keyDownEvent";
import {
  getFullLockCell,
  getPartialLock,
} from "../../../../modules/recommendation/utilities/productCellGenerators";
import { InfoModal } from "./components/InfoModal/InfoModal";
import { defaultData } from "../../../../modules/recommendation/Envelop";
import {
  getProducts,
  ProductInterface,
} from "../../../../modules/recommendation/requests/getProducts";
import { AmountContainer } from "./components/AmountContainer";
import { InvestmentScope } from "../../../../modules/recommendation/models/investment-scope.enum";
import { ExclusionReason } from "../../../../modules/recommendation/models/exclusion-reason.enum";
import { ProductTypeIdentifier } from "../../../../modules/recommendation/models/product-type-identifier.enum";
import { useProductHelperService } from "../../../../modules/recommendation/services/useProductHelper.service";

export interface ProductsTableProps {
  model: RecommendationOutputModel;
  isFullSize?: boolean;
}

export function ProductsTable(props: ProductsTableProps) {
  const { showModal } = useModal();
  const { openProductSheetModal } = useProductHelperService();
  const t = i18n.recommendationPage.productsTableComponent;
  const productsI18n = i18n.products;
  const [productsTable, setProductTable] = useState<
    RecommendationProductInformation[]
  >([]);
  const [totalTransaction, setTotalTransaction] = useState<number>(0);
  const [totalMonthlyTransaction, setTotalMonthlyTransaction] =
    useState<number>(0);
  const [productInformations, setProductInformations] =
    useState<ProductInterface[]>();

  useEffect(() => {
    if (!props || !props.model) return;
    generateProductsTable();
    calculateTotalAmount();
  }, [props.model]);

  async function generateProductsTable() {
    const products = await getProductsLink();
    const mapProducts = products.map((product) => { return {
      productTypeIdentifier: product.productTypeIdentifier as ProductTypeIdentifier,
      ...defaultData
    }})
    createRecommendationProductsTable(mapProducts);
  }

  async function getProductsLink(): Promise<ProductInterface[]> {
    const products = await getProducts();
    setProductInformations(products);
    return products;
  }

  async function displayModal() {
    const result = await showModal(
      <TaxSavingMoreInfoModal model={props.model} />
    );
    if (result.outcome === 1) {
      // The user cancelled the modal, stay on the current page
      document.getElementById("know-more-product-per")!.focus();
      return;
    }
  }

  function calculateTotalAmount(): void {
    const productTypePurchaseTransactions = props.model.transactions.productTypeTransactions;
    let totAmount = 0;
    let totMonthlyAmount = 0;

    for (const purchaseTransaction of productTypePurchaseTransactions) {
      totAmount += purchaseTransaction.transactionAmount;
      totMonthlyAmount += purchaseTransaction.monthlyTransactionAmount;
    }
    setTotalTransaction(totAmount);
    setTotalMonthlyTransaction(totMonthlyAmount);
  }

  const hasInvesmentCellExclude =
    props.model.excludedProductInvestment.excludedScopes.find(
      (e) => e.investmentScope === InvestmentScope.InitialInvestment
    );
  const hasMonthlyCellExclude =
    props.model.excludedProductInvestment.excludedScopes.find(
      (e) => e.investmentScope === InvestmentScope.PeriodicInvestment
    );

  function createRecommendationProductsTable(productsList: RecommendationProductInformation[]) {
    let tempListOfProducts = productsList;

    tempListOfProducts.map((e) => {
      const isExcludedProductTypes =
        props.model.excludedProductInvestment.excludedProductTypes.find(
          (z) => z.productTypeId === e.productTypeIdentifier
        );
      const isExcludedScopedInvestments =
        props.model.excludedProductInvestment.excludedScopedInvestments.find(
          (z) => z.productTypeId === e.productTypeIdentifier
        );
      const isPresent = props.model.transactions.productTypeTransactions.find(
        (z) => z.productTypeId === e.productTypeIdentifier
      );

      if (
        !isExcludedProductTypes &&
        !isExcludedScopedInvestments &&
        !isPresent
      ) {
        e.fullExclude = {
          reason: ExclusionReason.InsufficientProductKnowledge,
          isExclude: true,
        };
      } else {
        e.fullExclude = getFullLockCell(
          e.productTypeIdentifier as ProductTypeIdentifier,
          props.model.excludedProductInvestment
        );
      }

      e.parcialExclude = getPartialLock(
        e.productTypeIdentifier as ProductTypeIdentifier,
        props.model.excludedProductInvestment
      ) as any;
    });
    setProductTable(tempListOfProducts);
  }

  function excludeMessage(identifier: ProductTypeIdentifier) {
    const findElement =
      props.model.excludedProductInvestment.excludedProductTypes.find(
        (e) => e.productTypeId === identifier
      );

    if (
      findElement!.exclusionReason ===
      ExclusionReason.InsufficientProductKnowledge
    ) {
      return "Enveloppe non adaptée à votre profil investisseur.";
    } else {
      return "Enveloppe non adaptée à votre projet.";
    }
  }

  async function openProductModal( productTypeIdentifier: ProductTypeIdentifier) {
    const information = productInformations!.filter(
      (e) => e.productTypeIdentifier === productTypeIdentifier
    )[0];
    const isProductTypeExclude = productsTable.find(
      (e) => e.productTypeIdentifier === productTypeIdentifier
    );
    
    openProductSheetModal(information, isProductTypeExclude);
  }

  async function handleDisplayModal() {
    const result = await showModal(<InfoModal />);

    if (result.outcome === ModalOutcome.Cancel) {
      // The user cancelled the modal, stay on the current page
      document.getElementById("how-is-calculated-action")!.focus();
      return;
    }
  }

  return (
    <>
      <section className={styles.Container}>
        <h3 className={styles.CardTitle}>
          {i18n.recommendationPage.summary.mainCardTitle}
        </h3>
        <div className={styles.Header}>
          <span className={styles.Label}></span>
          <span className={styles.RightLable}>{t.headers.openInvestment}</span>
          <span className={styles.RightLable}>
            {t.headers.monthlyInvestment}
          </span>
        </div>

        {productsTable.map((product, index) => (
          <div className={styles.Container} key={product.productTypeIdentifier}>
            <section key={index} className={styles.RecommendationProduct}>
              <div
                className={classNames(
                  styles.ProductLineInformations,
                  styles.CommonInformation
                )}
              >
                <div className={styles.ProductName}>
                  {productsI18n.name[product.productTypeIdentifier]}
                </div>

                <div className={styles.ProductDesc}>
                  <span>{productsI18n.desc[product.productTypeIdentifier]}</span>
                  <button
                    key={index}
                    className={styles.Icon}
                    id={product.productTypeIdentifier + "-modal"}
                    onClick={() => {
                      openProductModal(product.productTypeIdentifier);
                    }}
                    onKeyDown={(e) =>
                      keyDownEvent(e, () =>
                        openProductModal(product.productTypeIdentifier)
                      )
                    }
                  >
                    <div className={styles.DetailIcon}>
                      <SvgWrapperComponent
                        ariaLabel={globali18n.ariaLabel.furtherInformation}
                      >
                        <EyeIcon />
                      </SvgWrapperComponent>
                      <span>{t.detail}</span>
                    </div>
                  </button>
                </div>
              </div>
              {!product.fullExclude.isExclude && (
                <div
                  className={classNames({
                    [styles.FlexFull]: props.isFullSize !== undefined,
                    [styles.Flex]: props.isFullSize === undefined,
                  })}
                >
                  <AmountContainer
                    product={product}
                    title={t.headers.openInvestment}
                    model={props.model}
                    type="initial"
                    minimum={hasMonthlyCellExclude}
                  />

                  <AmountContainer
                    product={product}
                    title={t.headers.monthlyInvestment}
                    model={props.model}
                    type="monthly"
                    minimum={hasMonthlyCellExclude}
                  />
                </div>
              )}

              {!product.fullExclude.isExclude &&
                product.productTypeIdentifier ===
                  ProductTypeIdentifier.PERIndividuel && (
                  <Button
                    className={classNames(
                      styles.ModalLink,
                      styles.ModalLinkContainer,
                      ButtonStyles.Link
                    )}
                    onClick={displayModal}
                    id={"know-more-product-per"}
                  >
                    {productsI18n.link[ProductTypeIdentifier.PERIndividuel]}
                  </Button>
                )}

              {product.fullExclude.isExclude && (
                <>
                  <div className={styles.FlexLock}>
                    <SvgWrapperComponent ariaHidden={true}>
                      <LockIcon />
                    </SvgWrapperComponent>

                    <div className={styles.NoAccessEnveloppeDetail}>
                      {excludeMessage(product.productTypeIdentifier)}
                    </div>
                  </div>
                </>
              )}
            </section>
            {index !== productsTable.length - 1 && (
              <hr className={styles.Divider} />
            )}
          </div>
        ))}
      </section>

      <div className={styles.Footer}>
        <div className={styles.FooterValues}>
          <span className={styles.Title}>{t.footer.label}</span>

          <div className={styles.ValueContainerTotal}>
            <div className={classNames(styles.ProductLineInformations)}>
              <div
                className={classNames({
                  [styles.TotalAmount]: !hasInvesmentCellExclude,
                  [styles.TotalAmountWarning]: hasInvesmentCellExclude,
                })}
              >
                {hasInvesmentCellExclude
                  ? productsI18n.excludeReason.MinimumInitialInvestmentAmount
                  : formatAmount(totalTransaction)}
              </div>
            </div>

            <div className={styles.ProductLineTotalInformations}>
              <div
                className={classNames({
                  [styles.TotalAmount]: !hasMonthlyCellExclude,
                  [styles.TotalAmountWarning]: hasMonthlyCellExclude,
                })}
              >
                {hasMonthlyCellExclude
                  ? productsI18n.excludeReason.MinimumPeriodicInvestmentAmount
                  : formatAmount(totalMonthlyTransaction)}
              </div>
            </div>
          </div>
        </div>

        <Button
          className={classNames(styles.ModalLink, ButtonStyles.Link)}
          onClick={handleDisplayModal}
          id={"how-is-calculated-action"}
        >
          {i18n.recommendationPage.howIsCalculated}
        </Button>
      </div>
    </>
  );
}

export type RecommendationProductInformation = {
  productTypeIdentifier: ProductTypeIdentifier;
  fullExclude: {
    reason: ExclusionReason | null;
    isExclude: boolean;
  };
  parcialExclude: {
    initialLock: {
      reason: ExclusionReason | null;
      isExclude: boolean;
    };
    monthlyLock: {
      reason: ExclusionReason | null;
      isExclude: boolean;
    };
  };
};
