import classNames from "classnames";
import { ButtonStyles, Container, useLoader, Button } from "../../../../shared";
import { PersonaCard } from "./component/PersonaCard";
import styles from "./PersonasPage.module.scss";
import { usePersonaBackend } from "../../commands/computePersonaBackend";
import { useNavigate, useParams } from "react-router-dom";
import { Persona } from "./PersonaEnum";
import { i18n } from "../../i18n";
import { birthdateRequirement } from "../../../../modules/pension-need/commands/birthdateRequirementCommand";
import { useAppDispatch } from "../../../../store";
import { setBirthdateValidationType, setPersona } from "../../pensionNeedSlice";
import { useAppUrls } from "../../../../shared/utilities/urls";
import {
  AdvisorCustomerDetail,
  CustomerDetail,
} from "../../../../modules/pension-need/models/request/getBirthdateRequest.model";
import { useAnswerCommands } from "../../../../modules/legal-pension/commands/answerCommands";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { PersonaCustomIcon } from "./images";
import { apiUrls } from "../../../../shared/apiUrls";
import { SvgWrapperComponent } from "../../../../shared/components/SvgWrapper/SvgWrapperComponent";
import { BackgroundImage } from "../../../../shared/components/BackgroundImage/BackgroundImage";

export function PersonasPage() {
  const navigate = useNavigate();
  const { computePersonaBackendCommand } = usePersonaBackend();
  const { answerBirthdate } = useAnswerCommands();
  const { showLoaderWhile } = useLoader();
  const { personaType } = useAnalytics();
  const dispatch = useAppDispatch();
  const { customerid } = useParams();
  const appUrl = useAppUrls();

  const t = i18n.personaPage;

  async function choosePersona(type: Persona): Promise<void> {
    personaType(type);

    await showLoaderWhile(
      async () => {
        const { birthdateRequirementCommand } = birthdateRequirement();
        const results = await birthdateRequirementCommand(customerid);
        dispatch(setBirthdateValidationType("intermediate"));
        dispatch(setPersona(type));
        const birthDate = results
          ? (results as CustomerDetail).birthDate ||
            (results as AdvisorCustomerDetail)?.information?.birthDate
          : null;

        if (!birthDate) {
          return navigate(appUrl.pensionNeed.birthdate);
        }

        await answerBirthdate(new Date(birthDate), true, "6/7", true);

        await computePersonaBackendCommand(type, customerid);
        navigate(appUrl.pensionNeed.summary);
      },
      {
        key: "pensionNeed_choosePersona",
        value: `API GET - ${apiUrls(customerid).pensionNeed.customer}`,
      }
    );
  }

  return (
    <div className={styles.Root}>
      <Container className={styles.Grid}>
        <div className={styles.Question}>{t.question}</div>
        <div className={styles.PersonPageInfoContainer}>
          <div className={styles.CardBox}>
            <PersonaCard
              key={Math.random()}
              persona={Persona.Family}
              className={styles.PersonaCard}
              onClick={() => choosePersona(Persona.Family)}
            />
            <PersonaCard
              key={Math.random()}
              persona={Persona.Hyperactive}
              className={styles.PersonaCard}
              onClick={() => choosePersona(Persona.Hyperactive)}
            />
            <PersonaCard
              key={Math.random()}
              persona={Persona.Globetrotter}
              className={styles.PersonaCard}
              onClick={() => choosePersona(Persona.Globetrotter)}
            />
          </div>
          <div className={styles.PersonaCustomBox}>
            <SvgWrapperComponent ariaHidden={true}>
              <PersonaCustomIcon />
            </SvgWrapperComponent>
            <div className={styles.Text}>{t.customProfileText}</div>
            <Button
              className={classNames([
                ButtonStyles.PersonaCustom,
                styles.SelectButton,
              ])}
              onClick={() => {
                personaType("custom");
                navigate(appUrl.pensionNeed.housing.locationType);
              }}
            >
              {t.buttons.customProfile}
            </Button>
          </div>
        </div>
      </Container>
      <BackgroundImage />
    </div>
  );
}
