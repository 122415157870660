import { useLoader } from '@ifs/libs';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { useNavigate } from 'react-router-dom';
import {
  ValidateInitializedAccountParams,
  ValidateInitializedAccountPayload,
  validateInitializedAccountRequest
} from '../requests/validateInitializedAccount.request';

export function useValidateInitializedAccount() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();

  function validateInitializedAccountCommand(
    params: ValidateInitializedAccountParams,
    payload: ValidateInitializedAccountPayload
  ) {
    showLoaderWhile(
      async () => {
        await validateInitializedAccountRequest(params, payload);
        navigate({
          pathname: internalUrls.authentication.login,
          search: new URLSearchParams({ accountActivated: 'true' }).toString()
        });
      },
      {
        key: 'useValidateInitializedAccount',
        value: `API POST - /api/auth/validate_initialized_account}`
      }
    );
  }

  return { validateInitializedAccountCommand };
}
