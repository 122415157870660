import { Slider, SliderChangeEvent } from "@progress/kendo-react-inputs";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { globali18n } from "../../../../i18n";
import {
  Card,
  Container,
  ButtonStyles,
  Button,
  SvgWrapperComponent,
  EditIcon,
} from "../../../../shared";
import { amount } from "../../../../shared/utilities/intl";
import { useAppSelector } from "../../../../store";
import { useValidateCommand } from "../../commands/validateCommand";
import { Footer } from "../../component/Footer/Footer";
import { i18n } from "../../i18n";
import styles from "./SummaryPage.module.scss";
import styled from "styled-components";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { BackgroundImage } from "../../component/BackgroundImage/BackgroundImage";
import { useAppUrls } from "../../../../shared/utilities/urls";
import { Link } from "react-router-dom";
import { useUpdateTaxAvailableCommand } from "../../commands/updateTaxAvailableCommand";

const investmentMin = 0;
const investmentMax = 1;
const investmentStep = 0.1;

const StyledBasicSlider = styled<any>(Slider)`
  .k-label {
    position: relative;
    top: -25px;
    left: -10px;
  }

  .k-slider-track {
    .k-slider-selection {
      background-color: var(--secondary-ruddy_brown);
    }
    .k-draghandle {
      background-color: var(--secondary-ruddy_brown);
    }
  }
`;

export function SummaryPage() {
  const appUrls = useAppUrls();
  const validateCommand = useValidateCommand();
  const updateTaxAvailableCommand = useUpdateTaxAvailableCommand();
  const results = useAppSelector((s) => s.taxation.results)!;
  const tmiConfigs = useAppSelector((s) => s.taxation.tmiConfigs)!;
  const { answerTaxationEvent, keepDoing } = useAnalytics();

  // HACK: Should be rounded on the backend side
  const availableFiscalDeductionAmountRounded = Math.floor(
    results?.householdMaximumTaxDeduction
  );

  const selectedTmiIndex = tmiConfigs.findIndex(
    (tc) => tc.tmi === results.selectedTmi
  );

  const [investmentPercentage, setInvestmentPercentage] = useState(1);
  const [investment, setInvestment] = useState<number>(0);
  const [savings, setSavings] = useState<number>(0);

  useEffect(() => {
    setInvestment(
      Math.floor(investmentPercentage * results.householdMaximumTaxDeduction)
    );
  }, [investmentPercentage]);

  useEffect(() => {
    setSavings(Math.floor(tmiConfigs[selectedTmiIndex].tmi * investment));
  }, [investment, selectedTmiIndex]);

  function onInvestmentPercentageChange(e: SliderChangeEvent) {
    const closest = Math.round(e.value * 10) / 10;
    setInvestmentPercentage(closest);
  }

  async function validate() {
    keepDoing("taxation");
    let visualData = {
      availableFiscalDeductionAmount: results.householdMaximumTaxDeduction,
      investmentRate: investmentPercentage,
      investment: investment,
      savingIncomePerin: availableFiscalDeductionAmountRounded,
      netSaving: investment,
      tmiRate: selectedTmiIndex,
      taxationDefault: true, // TO REMOVE
    };
    // Wait testing if we need to update the taxation value due to the user investment update
    if(false && availableFiscalDeductionAmountRounded !== investment) {
      await updateTaxAvailableCommand(investment);
    }
    answerTaxationEvent(visualData);
    await validateCommand();
  }

  return (
    <>
      <Container
        className={classNames(styles.Container, styles.TaxationSummary)}
      >
        <div className={styles.Description}>
          {i18n.advancedSummary.description}
        </div>
        <div className={styles.MainContent}>
          <Card className={classNames(styles.CardInLine, styles.Spacing)}>
            <div className={styles.LabelDeductible}>
              {i18n.summaryPage.deductible}
            </div>
            <div className={styles.FigureDeductible}>
              {amount(availableFiscalDeductionAmountRounded)}
            </div>
          </Card>
          <Card className={classNames(styles.Card, styles.Spacing)}>
            <div className={styles.LabelSimulate}>
              {i18n.summaryPage.investment}
            </div>
            <div className={styles.InLine}>
              <StyledBasicSlider
                min={investmentMin}
                max={investmentMax}
                step={investmentStep}
                value={investmentPercentage}
                className={styles.Slider}
                onChange={onInvestmentPercentageChange}
              ></StyledBasicSlider>
              <div className={styles.FigureInvestment}>
                {amount(investment)}
              </div>
            </div>
          </Card>
          <div className={styles.DescriptionBloc}>
            <span className={styles.DescriptionTitle}>
              {i18n.summaryPage.blocDescriptionTitle}
            </span>
            <ol>
              <li className={styles.DescriptionBody}>
                {i18n.summaryPage.blocDescriptionBodyOne}
              </li>
              <li className={styles.DescriptionBody}>
                {i18n.summaryPage.blocDescriptionBodyTwo}
              </li>
            </ol>
          </div>
        </div>
        <div className={styles.Right}>
          <div className={styles.AmountBloc}>
            <div>{i18n.summaryPage.amountPerTitle}</div>
            <div className={styles.DeductibleAmount}>{savings}€</div>

            <div className={styles.Disclaimer}>
              {i18n.summaryPage.disclaimer}
            </div>

            <Button
              onClick={validate}
              className={classNames(ButtonStyles.PrimaryAction)}
            >
              {globali18n.labels.validateModule}
            </Button>

            <Link
              className={styles.EditLink}
              title="redoTaxationQuestionnaire"
              to={{ pathname: appUrls.taxation.taxAvailable }}
            >
              <SvgWrapperComponent ariaHidden={true}>
                <EditIcon
                  className={styles.EditIcon}
                  filled={true}
                  color={"#FFFFFF"}
                />
              </SvgWrapperComponent>
              <span 
                  className={styles.EditLabel}>{i18n.summaryPage.redoTaxationQuestionnaire}</span>
            </Link>
          </div>          
        </div>
      </Container>
      <BackgroundImage isSummaryPage={true}/>

      <Footer>{i18n.summaryPage.footer}</Footer>
    </>
  );
}
