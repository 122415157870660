import axios from "axios";
import { apiUrls } from "../../../shared/apiUrls";

export async function setQuestionnaireRiskProfileSessionAnswers(
  customerId: string | undefined,
  riskProfileIdentifier: string,
  answers: any
) {
  const apiUrl = apiUrls(
    customerId
  ).investorProfile.questionnaireSession.setQuestionnaireSessionAnswers(
    riskProfileIdentifier
  );
  const response = await axios.put(apiUrl, [
    {
      questionTextId: "QR_RiskReturn",
      answerTextId: answers.horizon,
    },
    {
      questionTextId: "QR_VolatilityTolerance",
      answerTextId: answers.fluctuation,
    },
    {
      questionTextId: "QR_HistoricalLoss",
      answerTextId: answers.lossOfValue,
    },
    {
      questionTextId: "QR_LossReaction",
      answerTextId: answers.lossReaction,
    },
  ]);

  const data = response.data;

  return data;
}
