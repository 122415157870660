import { InputGroup, TextInput, ValidationMessages } from '@ifs/libs';
import styles from './UserInformationFormComponent.module.scss';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import { Control, Controller } from 'react-hook-form';
import { i18n } from '../../i18n';
import { FormData } from '../../pages/AppointmentPage/AppointmentPage';
import { inputMaxLength } from '../../../../shared/utilities/validation';
import classNames from 'classnames';
interface UserInformationFormComponentProps {
  control: Control<FormData, any>;
  errors: { [key: string]: any };
  formConfig: any;
}
const t = i18n.appointmentPage;
export function UserInformationFormComponent(props: UserInformationFormComponentProps) {
  const control = props.control;
  const errors = props.errors;
  const formConfig = props.formConfig;
  return (
    <div className={styles.FormContainer}>
      <InputGroup className={styles.InputGroup}>
        <label className={styles.Label}>{t.firstName.label}</label>
        <Controller
          control={control}
          name="firstName"
          rules={formConfig.firstName.rules}
          render={({ field: { onChange, value } }) => (
            <TextInput
              required
              aria-required={'true'}
              type="text"
              id="firstName"
              maxLength={inputMaxLength}
              register={formConfig.firstName.field}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <ValidationMessages
          messages={getValidationMessages('firstName', errors, formConfig.firstName.errorMessages)}
        />
      </InputGroup>
      <InputGroup className={classNames(styles.InputGroup, styles.Margin)}>
        <label className={styles.Label}>{t.lastName.label}</label>
        <Controller
          control={control}
          name="lastName"
          rules={formConfig.lastName.rules}
          render={({ field: { onChange, value } }) => (
            <TextInput
              required
              aria-required={'true'}
              type="text"
              id="lastName"
              maxLength={inputMaxLength}
              register={formConfig.lastName.field}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <ValidationMessages
          messages={getValidationMessages('lastName', errors, formConfig.lastName.errorMessages)}
        />
      </InputGroup>
      <InputGroup className={styles.InputGroup}>
        <label className={styles.Label}>{t.email.label}</label>
        <Controller
          control={control}
          name="email"
          rules={formConfig.email.rules}
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="email"
              required
              aria-required={'true'}
              id="email"
              register={formConfig.email.field}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <ValidationMessages
          messages={getValidationMessages('email', errors, formConfig.email.errorMessages)}
        />
      </InputGroup>
    </div>
  );
}

