import { ExclusionReason } from "./models/exclusion-reason.enum";
import { ProductTypeIdentifier } from "./models/product-type-identifier.enum";

export const defaultData = {
  fullExclude: {
    reason: null,
    isExclude: false,
  },
  parcialExclude: {
    initialLock: {
      reason: null,
      isExclude: false,
    },
    monthlyLock: {
      reason: null,
      isExclude: false,
    },
  },
};