import { useEffect, useState } from "react";
import { useExternalUrls } from "../../../../../../shared/utilities/externalUrls";
import { AdditionalRetirementIncomeDetailModel } from "../../../../../../modules/recommendation/models/RecommendationModel";
import {
  ModalOutcome,
  StyledKendoModal,
  useModal,
} from "../../../../../../shared";
import { i18n } from "../../../../i18n";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styles from "./OtherStatusModal.module.scss";

interface Props {
  model: AdditionalRetirementIncomeDetailModel;
}

export function OtherStatusModal(props: Props) {
  const { hideModal } = useModal();
  const [projectionLink, setProjectionLink] = useState<string>("");
  const { externalUrls } = useExternalUrls();
  useEffect(() => {
    getLink();
  }, []);

  async function getLink() {
    const urls = await externalUrls();
    setProjectionLink(urls.ProjectionExplanationPage);
  }

  return (
    <StyledKendoModal
      className={styles.Dialog}
      title={i18n.recommendationPage.modal.title}
      onClose={() => hideModal(ModalOutcome.Cancel, null)}
      width={"500px"}
    >
      <div id={"modal-content"}>
        {i18n.recommendationPage.modal.content(
          props.model.pessimistic.monthlyAmount,
          props.model.optimistic.monthlyAmount,
          props.model.neutral.monthlyAmount,
          projectionLink
        )}

        <PanelBar className={styles.PatrimonyPanelBarModal}>
          <PanelBarItem title={i18n.recommendationPage.modal.panTitle}>
            {i18n.recommendationPage.modal.panelContent}
          </PanelBarItem>
        </PanelBar>
      </div>
    </StyledKendoModal>
  );
}
