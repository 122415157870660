import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  internalUrls,
  prospectEntryUrls,
  reportEntryUrl,
  reportUrl
} from '@ifs/libs/src/shared/utilities/urls';
import { useGlobalCommand as useGlobalCommandLibs } from '@ifs/libs';
import { useGlobalCommand } from '../../shared/commands/useGlobalCommand';
import { useAppSelector } from '../../store';

export function GuardedRoute({ children }: any) {
  const isAuthenticated = useAppSelector((s) => s.authentication.isAuthenticated);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { generateProspectCommand } = useGlobalCommand();
  const { isAuthenticationModeSSO } = useGlobalCommandLibs();

  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [renderChildren, setRenderChildren] = useState(false);

  async function handleSecurity() {
    // Case 1: Redirect authenticated users from the report entry point to the report itself
    if (isAuthenticated && pathname === reportEntryUrl) {
      setRedirectTo(reportUrl);
      return;
    }

    // Case 2: Let authenticated users continue
    if (isAuthenticated) {
      setRenderChildren(true);
      return;
    }

    // Case 3: Redirect unauthenticated users going to the report to the login page with a return URL
    if (!isAuthenticated && pathname === reportEntryUrl) {
      navigate({
        pathname: internalUrls.authentication.login,
        search: new URLSearchParams({ returnUrl: reportUrl }).toString()
      });
      return;
    }

    // Case 4: Generate a prospect for unauthenticated users coming through one of the entry URLs
    // Prospect user doesnt exist on SSO Tenant
    if (!isAuthenticated && prospectEntryUrls.includes(pathname) && !isAuthenticationModeSSO()) {
      await generateProspectCommand();
      setRenderChildren(true);
      return;
    }

    // Case 5: Redirect all unauthenticated users to the login page
    if (!isAuthenticated) {
      navigate(internalUrls.authentication.login);
      return;
    }
  }

  useEffect(() => {
    handleSecurity();
  }, [pathname]);

  if (redirectTo !== null) {
    window.location.href = reportUrl;
    return;
  }

  if (renderChildren) {
    return children;
  }

  return null;
}
