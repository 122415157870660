import { useAppDispatch } from "../../../store";
import { hideLoader, showLoader } from "./loaderSlice";

export function useLoader() {
    const dispatch = useAppDispatch();
  
    async function showLoaderWhile<T>(action: () => Promise<T>, request: LoaderRequest = {key: "", value : ""}): Promise<T> {
      dispatch(showLoader(request));
      try {
        const result = await action();
        return result;
      } finally {
        dispatch(hideLoader(request));
      }
    }
  
    return { showLoaderWhile };
  }
    
  type LoaderRequest = {
    key: string,
    value: string,
  }