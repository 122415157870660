import { PropsWithChildren, useEffect } from "react";
import { AnyAction } from "redux";
import { ProgressLayout } from "../../shared/layouts/ProgressLayout/ProgressLayout";
import { useAppDispatch } from "../../store";
import { setProgressInfo } from "../layouts/ProgressLayout/progressSlice";
import { PageRoute, PageRouteProps } from "./PageRoute";

interface ProgressRouteProps extends PageRouteProps {
  title?: string;
  subtitle?: string;
  step?: number;
  maxStep?: number;
  showSteps?: boolean;
  hideProgressBar?: boolean;
  showAmount?: boolean;
  duration?: number;
}

function RouteDispatcher(props: PropsWithChildren<{ action: AnyAction }>) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(props.action);
  }, [props.action]);

  return <>{props.children}</>;
}

export function ProgressRoute({
  element,
  ...rest
}: ProgressRouteProps) {
  function render() {
    const action = setProgressInfo({
      title: rest.title,
      subtitle: rest.subtitle,
      step: rest.step,
      maxStep: rest.maxStep,
      showSteps: rest.showSteps,
      hideProgressBar: rest.hideProgressBar,
      showAmount: rest.showAmount,
      duration: rest.duration,
    });

    if (element) {
      return (
        <RouteDispatcher action={action}>
          <ProgressLayout>
            {element}
          </ProgressLayout>
        </RouteDispatcher>
      );
    }
    return <></>;
  }

  const Comp = render;

  return <PageRoute {...rest} element={<Comp />} />;
}
