import {
  LiquidityParameterModel,
  ParametersInputModel,
  ParametersOutputModel,
} from "../models/RecommendationModel";


export function outputToInputParameters(
  outputParameters: ParametersOutputModel,
  retirementScenario: string
) {
  // The backend cannot accept a null liquidity amount as preference (bug?)
  const liquidity = liquidityAmountToInvest(outputParameters);
  const input: ParametersInputModel = {
    recurringInvestmentAmount:null,
    initialInvestmentAmount: liquidity,
    retirementScenario: retirementScenario,
    overrideGamificationParameters: false,
  };

  return input;
}

function isCustomLiquidityAmount(model: LiquidityParameterModel) {
  return (
    model.investmentAmount !== null &&
    model.investmentAmount !== model.defaultValue
  );
}

function liquidityAmountToInvest(
  outputParameters: ParametersOutputModel
): number {
  const parameter = outputParameters.initialInvestmentAmount;
  
  if (isCustomLiquidityAmount(parameter!)) {
    return parameter!.investmentAmount;
  }

  return parameter!.defaultValue;
}
