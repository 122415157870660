import axios from 'axios';
import { apiUrls } from '../../../shared/apiUrls';
import { PensionNeedValidationsBody, ValidatedPensionNeedModel } from '../models/request/validationRequest.model';

export async function validateRequest(
  payload: PensionNeedValidationsBody,
  customerid?: string
): Promise<ValidatedPensionNeedModel> {
  
  const apiUrl = apiUrls(customerid).pensionNeed.validations;

  const response = await axios.post<ValidatedPensionNeedModel>(apiUrl, payload);
  return response.data;
}