export const minAge = 18;
export const maxAge = 67;

export const minAgeAtCareerStart = 16;
export const maxAgeAtCareerStart = 67;

export const minNumberOfChildren = 0;
export const maxNumberOfChildren = 20;

export const maxCareerStartYear = new Date().getFullYear();

export const minSalaryAmount = 1;
export const maxMonthlySalaryAmount = 30000;
export const maxYearlySalaryAmount = 360000;

export const monthlySalaryStep = 100;
export const yearlySalaryStep = 1000;
