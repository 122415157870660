import classNames, { Argument } from 'classnames';
import { useEffect, useState } from 'react';
import styles from './ButtonGroup.module.scss';
import { useTheme } from '../Theme/ThemeContext';

export interface ButtonGroupOption {
  label: string;
  value: any;
}

export interface ButtonGroupProps {
  options: ButtonGroupOption[];
  value?: any;
  onChange?: (value: any) => void;
  className?: Argument;
}

export function ButtonGroup(props: ButtonGroupProps) {
  const [value, setValue] = useState();
  const { backgroundColor: themeBackgroundColor } = useTheme();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function click(value: any) {
    setValue(value);
    props.onChange?.(value);
  }

  const style = { '--themeBackgroundColor': themeBackgroundColor } as React.CSSProperties;

  return (
    <div className={classNames(styles.Group, props.className)} role={"radiogroup"}>
      {props.options.map((o, i) => (
        <button
          type="button"
          key={o.value}
          style={style}
          role={"radio"}
          aria-checked={value === o.value}
          className={classNames(styles.Button, { [styles.Selected]: value === o.value })}
          onClick={() => click(o.value)}
        >
          {o.label}
        </button>
      ))}
    </div>
  );
}
