import { Checkbox } from "@progress/kendo-react-inputs";
import classNames, { Argument } from "classnames";
import { PropsWithChildren, useEffect } from "react";
import styled from "styled-components";

interface StyledKendoCheckboxProps extends PropsWithChildren<{}> {
  className?: Argument;
  value?: boolean;
  onChange: (e: any) => void;
  id?: string;
  checked?: boolean;
  onBlur?: (e: any) => void;
  ref?: any;
  name?: string;
}

const StyledCheckbox = styled(Checkbox)`
.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: var(--secondary-ruddy_brown);
  border-color: var(--secondary-ruddy_brown);
}
`;

export function StyledKendoCheckbox(props: StyledKendoCheckboxProps) {
  return (
    <StyledCheckbox
      className={classNames(props.className)}
      checked={props.checked}
      onBlur={props.onBlur}
      ref={props.ref}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      role="checkbox"
      aria-label={props.name}
    >
      {props.children}
    </StyledCheckbox>
  );
}
