import reportWebVitals from "./reportWebVitals";

export {
  taxationReducer,
  resetTaxation,
} from "./modules/taxation/taxationSlice";

export {
  legalPensionReducer,
  resetLegalPension,
} from "./modules/legal-pension/legalPensionSlice";
export {
  pensionNeedReducer,
  resetPensionNeed,
} from "./modules/pension-need/pensionNeedSlice";
export {
  dashboardReducer,
  resetDashboard,
} from "./modules/dashboard/dashboardSlice";

export {
  patrimonyReducer,
  resetPatrimony,
} from "./modules/patrimony/patrimonySlice";

export {
  lifeProjectsReducer,
  resetLifeProjects,
} from "./modules/life-projects/lifeProjectsSlice";

export {
  recommendationReducer,
  resetRecommendation,
} from "./modules/recommendation/recommendationSlice";

export {
  tenantReducer,
  resetTenant,
  setTenantState,
} from "./shared/slices/tenantSlice";

export {
  loaderReducer,
  resetLoader,
} from "./shared/components/Loader/loaderSlice";

export {
  progressReducer,
  resetProgress,
} from "./shared/layouts/ProgressLayout/progressSlice";

export {
  investorProfileReducer,
  resetInvestorProfile,
} from "./modules/investor-profile/investorProfileSlice";

export { useGlobalCommand } from "./shared/commands/useGlobalCommand";

export { use401Interceptor } from "./shared/routing/use401Interceptor";

export {
  Button,
  ButtonStyles,
  ButtonGroup,
  CheckboxGroup,
  useConfirmModal,
  Container,
  ValidationMessages,
  ModalOutcome,
  useModal,
  useLoader,
  useLogoutCommand,
  PageTitle,
  useInfoModal,
  Loader,
  Module,
  ModalProvider,
  SalaryPeriodicity,
  ScrollToTop,
  Header,
  Footer,
  EmailInputGroup,
  InputGroup,
  TextInputPassword,
  TextInput
} from "./shared";


export {
  TaxationModule,
  LegalPensionModule,
  PensionNeedModule,
  DashboardModule,
  LifeProjectsModule,
  PatrimonyModule,
  RecommendationModule,
  InvestorProfileModule,
} from "./modules";

export { PageNotFound } from "./shared/pages/PageNotFound/PageNotFound";
export { TechnicalErrorPage } from "./shared/pages/TechnicalError/TechnicalErrorPage";
export { ErrorBoundaryFallbackComponent } from "./shared/components/ErrorBoundaryFallback/ErrorBoundaryFallbackComponent";

export type { ProgressState } from "./shared/layouts/ProgressLayout/progressSlice";
export type { PensionNeedState } from "./modules/pension-need/pensionNeedSlice";
export type { LegalPensionState } from "./modules/legal-pension/legalPensionSlice";
export type { DashboardState } from "./modules/dashboard/dashboardSlice";
export type { LifeProjectsState } from "./modules/life-projects/lifeProjectsSlice";
export type { PatrimonyState } from "./modules/patrimony/patrimonySlice";
export type { RecommendationState } from "./modules/recommendation/recommendationSlice";
export type { LoaderState } from "./shared/components/Loader/loaderSlice";
export type { TaxationState } from "./modules/taxation/taxationSlice";
export type { ButtonGroupOption } from "./shared";

export { usePageViewEvent } from "./shared/commands/usePageViewEvent";
export { useGetDashboardModel } from "./shared/commands/useGetDashboardModel.command";
export type {
  AnalyticsComponentProps,
  PageViewModel,
} from "./shared/models/PageView";

export { useAuthEvent } from "./shared/commands/useAuthEvents";

export { useAnalytics } from "./shared/commands/useAnalytics";
export { CookiesPage } from "./modules/cookies/pages/Cookies";
export { SvgWrapperComponent } from "./shared";
export { assets as Assets } from './shared/components/BackgroundImage/Assets';
export default reportWebVitals 