import { useNavigate } from "react-router-dom";
import { ModalOutcome, useModal } from "../../../shared";
import { useAppUrls } from "../../../shared/utilities/urls";
import {
    ExpiredInvestorProfile,
    ExpiredProfileInfoModal
} from "../components/TaxSavings/components/ExpiredProfileInfoModal/ExpiredProfileInfoModal";


export function useRenderExpiredInvestorProfileModal() {
    const navigate = useNavigate();
    const { showModal } = useModal();
    const appUrls = useAppUrls();

    async function renderExpiredInvestorProfileModal() {
        const result = await showModal<ExpiredInvestorProfile>(<ExpiredProfileInfoModal/>);

      if (result.outcome === ModalOutcome.Cancel) {
            return;
        }

        if (result.value === ExpiredInvestorProfile.GoToInvestorProfile) {
            navigate(appUrls.investorProfile.landing);
        } else {
            return;
        }
    }

    return { renderExpiredInvestorProfileModal };
}
