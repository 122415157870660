import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Controller, useForm } from "react-hook-form";
import { globali18n } from "../../../../i18n";
import {
  Container,
  FrequencySwitch,
  QuestionTitle,
  useModal,
  ValidationMessages,
  Button
} from "../../../../shared";
import { ButtonStyles } from "../../../../shared/components/Button/ButtonStyles";
import { SalaryPeriodicity } from "../../../../shared/models/SalaryPeriodicity";
import { getValidationMessages } from "../../../../shared/utilities/getValidationMessages";
import { useAppSelector } from "../../../../store";
import { BackgroundImage } from "../../component/BackgroundImage/BackgroundImage";
import { QuestionCard } from "../../../../shared/components/QuestionCard/QuestionCard";
import { i18n } from "../../i18n";
import { ModuleStyles } from "../../styles/ModuleStyles";
import {
  maxMonthlySalaryAmount,
  maxYearlySalaryAmount,
  minSalaryAmount,
  monthlySalaryStep,
  yearlySalaryStep,
} from "../../utilities/constants";
import { CivilServantModal } from "./components/CivilServantModal.tsx/CivilServantModal";
import styles from "./CurrentRevenuePage.module.scss";
import { useAnswerCommands } from "../../commands/answerCommands";

interface FormData {
  amount: number;
  frequency: SalaryPeriodicity;
}

export function CurrentRevenuePage() {
  const { answerSalary } = useAnswerCommands();
  const { showModal } = useModal();

  const currentValue = useAppSelector((s) => ({
    amount: s.legalPension.answers.salary.amount,
    frequency: s.legalPension.answers.salary.frequency,
  }));

  const {
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: currentValue,
  });

  function onSubmit(value: FormData) {
    answerSalary(value);
  }

  const errorMessages = {
    required: globali18n.errors.required,
    max: i18n.currentRevenuePage.errors.max,
    min: globali18n.errors.min(minSalaryAmount),
  };

  function step(): number {
    const frequency = getValues().frequency;
    const step =
      frequency === SalaryPeriodicity.Monthly
        ? monthlySalaryStep
        : yearlySalaryStep;

    return step;
  }

  function increase() {
    const current = getValues().amount ?? 0;

    setValue("amount", current + step(), { shouldValidate: true });
  }

  function decrease() {
    const current = getValues().amount ?? 0;

    if (current - step() >= 0) {
      setValue("amount", current - step(), { shouldValidate: true });
    }
  }

  function validateMax(amount: number) {
    const max =
      getValues().frequency === SalaryPeriodicity.Monthly
        ? maxMonthlySalaryAmount
        : maxYearlySalaryAmount;
    const isValid = amount <= max;

    return isValid;
  }

  async function tellMeMore() {
    await showModal(<CivilServantModal />);
  }

  const rules = {
    required: true,
    min: minSalaryAmount,
    validate: {
      max: validateMax,
    },
  };

  return (
    <Container className={ModuleStyles.PageGrid}>
      <QuestionTitle>{i18n.currentRevenuePage.question}</QuestionTitle>

      <form
        className={styles.Form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <QuestionCard
          childrenClassName={ModuleStyles.CardChildrenGrid}
          autoFocusValidate={currentValue != null}
        >
          <FrequencySwitch control={control} name="frequency" />
          <div className={ModuleStyles.InputRow}>
            <Button
              className={ButtonStyles.Round}
              onClick={decrease}
              type="button"
            >
              -
            </Button>
            <Controller
              control={control}
              name="amount"
              rules={rules}
              render={({ field: { onChange, onBlur, value } }) => (
                <NumericTextBox
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  spinners={false}
                  format={{ maximumFractionDigits: 0 }}
                />
              )}
            />{" "}
            {globali18n.labels.euros}
            <Button
              className={ButtonStyles.Round}
              onClick={increase}
              type="button"
            >
              +
            </Button>
          </div>
          <ValidationMessages
            messages={getValidationMessages("amount", errors, errorMessages)}
          />
          <div className={styles.CivilServant}>
            {i18n.currentRevenuePage.civilServant}{" "}
            <button type="button" onClick={tellMeMore} className={styles.TellMeMore}>
              {i18n.currentRevenuePage.tellMeMore}
            </button>
          </div>
        </QuestionCard>
      </form>
      <BackgroundImage />
    </Container>
  );
}
