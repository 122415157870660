import BackgroundPensionNeedLandingImage from "./images/BackgroundPensionNeedLandingImage.svg";
import BackgroundLegalPensionLandingImage from "./images/BackgroundLegalPensionLandingImage.svg";
import BackgroundTaxationLandingImage from "./images/BackgroundTaxationLandingImage.svg";
import BackgroundPatrimonyLandingImage from './images/BackgroundPatrimonyLandingImage.svg';
import BackgroundLifeProjectLandingImage from './images/BackgroundLifeProjectLandingImage.svg';
import BackgroundInvestorProfileLandingImage from './images/BackgroundInvestorProfileLandingImage.svg';
import BackgroundDiagnosticLandingImage from './images/BackgroundDiagnosticLandingImage.svg'

export const assets = {
  BackgroundPensionNeedLandingImage: BackgroundPensionNeedLandingImage,
  BackgroundLegalPensionLandingImage: BackgroundLegalPensionLandingImage,
  BackgroundTaxationLandingImage: BackgroundTaxationLandingImage,
  BackgroundPatrimonyLandingImage: BackgroundPatrimonyLandingImage,
  BackgroundLifeProjectLandingImage: BackgroundLifeProjectLandingImage,
  BackgroundInvestorProfileLandingImage: BackgroundInvestorProfileLandingImage,
  BackgroundDiagnosticLandingImage: BackgroundDiagnosticLandingImage
};
