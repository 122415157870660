import { PasswordErrors } from '../shared/models/PasswordErrors';
import { Module } from '@ifs/libs';

export const i18n = {
  moduleNames: {
    [Module.LegalPension]: 'Votre retraite légale',
    [Module.PensionNeed]: 'Vos besoins à la retraite',
    [Module.Patrimony]: 'Votre patrimoine',
    [Module.LifeProjects]: 'Vos projets de vie',
    [Module.Taxation]: 'Votre avantage fiscal'
  },
  skipLink: {
    caption: 'Aller au contenu principal'
  },
  header: {
    dashboard: 'Tableau de bord',
    logIn: 'Connexion',
    register: 'Inscription',
    profile: 'Profil',
    rendezVous: "Prendre un rendez-vous",
    logOut: 'Déconnexion'
  },
  footer: {
    aboutUs: 'À propos',
    faq: 'FAQ',
    partners: 'Nos partenaires',
    termsOfUse: 'CGU',
    cookies: "Politique d'utilisation des cookies",
    privacy: 'Politique de protection des données',
    a11y: 'Accessibilité\xa0: partiellement conforme',
    logo: 'BNP Paribas logo'
  },
  pageNotFound: {
    title: 'Page introuvable'
  },
  emailInputGroup: {
    label: 'Adresse e-mail'
  },
  passwordConfirmInputGroup: {
    errors: {
      mismatch: 'Les mots de passe ne sont pas identiques.',
      [PasswordErrors.minUppercase]: 'Au moins 1 majuscule',
      [PasswordErrors.minLowercase]: 'Au moins 1 minuscule',
      [PasswordErrors.minNumber]: 'Au moins 1 nombre',
      [PasswordErrors.minSymbol]: 'Au moins 1 caractère spécial',
      [PasswordErrors.minLength]: 'Au moins 8 caractères',
      [PasswordErrors.noOther]: "Pas d'autre type de caractères"
    }
  },
  landingPage: {
    start: "C'est parti\u00A0!"
  },
  internalErrorModal: {
    title: 'Erreur technique',
    description:
      'Suite à un problème technique inattendu, le service peut être interrompu ou perturbé. Nous vous invitons à relancer la page en cours ou renouveler votre visite dans quelques instants. Veuillez-nous excuser.',
    continue: 'Continuer'
  }
};
