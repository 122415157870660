import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared';
import { useAppDispatch } from '../../../store';
import { deleteAccount, SavingAccountType } from '../patrimonySlice';
import { deleteSavingAccountRequest } from '../requests/deleteSavingAccountRequest';

export function useDeleteAccountCommand() {
  const dispatch = useAppDispatch();
  const { showLoaderWhile } = useLoader();
  const { customerid } = useParams();

  async function deleteAccountCommand(
    type: SavingAccountType,
    position: number,
    savingAccountId: string
  ) {
    await showLoaderWhile(async () => {
      // Wait backend delete Ok before update Account list localy
      await deleteSavingAccountRequest(savingAccountId, customerid);
      dispatch(deleteAccount({ type, position }));
    }, {key: "useDeleteAccountCommand", value:`Delay before dispatch deleteAccount on deleteAccount.ts file`});
  }

  return { deleteAccountCommand };
}
