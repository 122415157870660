import classNames, { Argument } from "classnames";
import { i18n } from "../../../../modules/patrimony/i18n";
import { HouseIcon } from "../../../../modules/patrimony/pages/DeclarationPage/images";
import {
  RealEstateType,
  setTransferAmount,
} from "../../../../modules/patrimony/patrimonySlice";
import {
  ButtonStyles,
  ModalOutcome,
  useModal,
  Button,
} from "../../../../shared";
import { amount } from "../../../../shared/utilities/intl";
import { useAppDispatch } from "../../../../store";
import { DeclarationCard } from "../DeclarationCard/DeclarationCard";
import { RealEstateItem } from "../RealEstateItem/RealEstateItem";
import styles from "./RealEstateCard.module.scss";
import { RealEstateModal } from "../RealEstateModal/RealEstateModal";
import { ModalMode } from "../../../../modules/patrimony/models/ModalMode";
import { useAddRealEstateCommand } from "../../../../modules/patrimony/commands/addRealEstate";
import { useEditRealEstateCommand } from "../../../../modules/patrimony/commands/editRealEstate";
import { useDeleteRealEstateCommand } from "../../../../modules/patrimony/commands/deleteRealEstate";
import { sumRealEstate } from "../../../../modules/patrimony/utilities/sumCurrentAmounts";
import { useAnalytics } from "../../../../shared/commands/useAnalytics";
import { GetRealEstateResponseModel } from "../../../../modules/patrimony/models/RealEstateModel";

interface RealEstateCardProps {
  realEstateType: RealEstateType;
  items: GetRealEstateResponseModel[];
  className?: Argument;
}

export function RealEstateCard(props: RealEstateCardProps) {
  const t = i18n.declarationPage.realEstateCard;
  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const sum = sumRealEstate(props.items);

  const { addRealEstateCommand } = useAddRealEstateCommand();
  const { editRealEstateCommand } = useEditRealEstateCommand();
  const { deleteRealEstateCommand } = useDeleteRealEstateCommand();
  const { addRealEstatePatrimony } = useAnalytics();

  async function add() {
    addRealEstatePatrimony();
    const nextRealEstate: Partial<GetRealEstateResponseModel> = {};
    const result = await showModal<GetRealEstateResponseModel>(
      <RealEstateModal
        mode={ModalMode.New}
        realEstate={nextRealEstate}
        title="Ajouter un bien"
      />
    );

    const value = result.value ?? 0;
    if (result.outcome === ModalOutcome.Ok) {
      await addRealEstateCommand(props.realEstateType, result.value!);
      dispatch(setTransferAmount());
    }
  }

  async function edit(position: number, realEstate: GetRealEstateResponseModel) {
    const result = await showModal<GetRealEstateResponseModel>(
      <RealEstateModal
        mode={ModalMode.Edit}
        title={""}
        realEstate={realEstate}
      />
    );

    if (result.outcome === ModalOutcome.Ok) {
      await editRealEstateCommand(
        props.realEstateType,
        position,
        result.value!
      );
      dispatch(setTransferAmount());
    }

    // Delete button
    if (result.outcome === ModalOutcome.Other) {
      await deleteRealEstateCommand(props.realEstateType, position, realEstate.id);
      dispatch(setTransferAmount());
    }
  }

  async function toggleSwitch(
    position: number,
    realEstate: GetRealEstateResponseModel
  ) {
    await editRealEstateCommand(props.realEstateType, position, realEstate);
  }

  return (
    <DeclarationCard title={t.title} subTitle={t.subTitle} icon={HouseIcon}>
      {props.items.map((r, i) => (
        <div key={i}>
          <RealEstateItem
            realEstate={r}
            onEdit={() => edit(i, r)}
            toggleSwitch={(i, e) => toggleSwitch(i, e)}
            position={i}
          />
          <hr className={styles.Divider} />
        </div>
      ))}
      <Button
        id="add-real-estate-button"
        className={classNames(ButtonStyles.PatrimonyAdd, styles.AddButton)}
        onClick={add}
      >
        {t.addRealEstate}
      </Button>
      <div className={styles.TotalGroup}>
        <div className={styles.TotalLabel}>{t.total}</div>
        <div className={styles.TotalAmount}>{amount(sum)}</div>
      </div>
    </DeclarationCard>
  );
}
