import { Horizon } from '../../../models/RiskProfileEnum';
import { createEnumPage } from '../../../../../shared/pages/createEnumPage';
import { useAnswerCommands } from '../../../commands/answerCommands';
import { BackgroundImage } from '../../../component/BackgroundImage/BackgroundImage';
import { i18n } from '../../../i18n';

export const InvestmentHorizon = createEnumPage<Horizon>({
  selector: (s) => s.investorProfile.riskProfile.horizon,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  commandInjector: () => useAnswerCommands().answerHorizon,
  values: [
    {
      label: i18n.riskProfile.horizonPage.answer1,
      value: Horizon.LowEfficiency
    },
    {
      label: i18n.riskProfile.horizonPage.answer2,
      value: Horizon.LimitedRiskOfLoss
    },
    {
      label: i18n.riskProfile.horizonPage.answer3,
      value: Horizon.HigherRiskOfLoss
    },
    {
      label: i18n.riskProfile.horizonPage.answer4,
      value: Horizon.SubstantialRiskOfLoss
    }
  ],
  question: i18n.riskProfile.horizonPage.question,
  backgroundImage: BackgroundImage
});