import { PropsWithChildren, useEffect } from 'react';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { globali18n } from '../../../i18n';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store';
import styles from './ProgressLayout.module.scss';
import { ButtonStyles } from '../../components/Button/ButtonStyles';
import classNames from 'classnames';
import { useTheme } from '../../components/Theme/ThemeContext';
import { resetProgress } from './progressSlice';
import { number } from '../../../shared/utilities/intl';
import { scrollTopCommand } from '../../../shared/commands/scrollTop';
import {
  Button,
  Container, PageTitle
} from '../../'
export function ProgressLayout(props: PropsWithChildren<{}>) {
  const navigate = useNavigate();
  const {
    duration,
    maxStep,
    step,
    subtitle,
    title,
    amount,
    showSteps,
    hideProgressBar,
    showAmount,
    unit
  } = useAppSelector((s) => s.progress);
  const dispatch = useAppDispatch();
  const { backgroundColor, backgroundColorLigther } = useTheme();

  function back() {
    navigate(-1);
    scrollTopCommand();
  }

  useEffect(() => {
     return () => {
      dispatch(resetProgress());
    };
  }, []);

  const canShowSteps = step !== null && maxStep !== null && showSteps !== false;
  const canShowAmount = showAmount !== false && amount !== null && !duration;

  // The progress is always 1 behind the current step since it measures "finished work"
  // To display a full bar on the summary and stay coherent with this rule, the summary is above the max step (ex: 6/5)
  const progressStep = Math.max(step ?? 0, 0);
  const progress = (progressStep / (maxStep ?? 1)) * 100;

  return (
    <div className={styles.Grid}>
      <Container className={styles.Container}>
       {!hideProgressBar && <ProgressBar
          animation={true}
          value={progress}
          className={styles.ProgressBar}
          progressStyle={{ backgroundColor }}
          emptyStyle={{ backgroundColor: backgroundColorLigther }}
          labelVisible={false}
        />}
        <Button onClick={back} className={classNames(styles.Back, ButtonStyles.Back)}>
          &lsaquo; {globali18n.labels.back}
        </Button>
        {canShowAmount && (
          <div className={styles.Amount}>
            {number(amount)} {unit}
          </div>
        )}
        {duration != null && (
          <div className={styles.Duration}>{globali18n.labels.estimatedTime(duration)}</div>
        )}
        <PageTitle className={styles.PageTitle}>{title}</PageTitle>
        {(canShowSteps || subtitle) && (
          <div className={styles.Steps}>
            {canShowSteps && (
              <>
                ({step}/{maxStep})
              </>
            )}{' '}
            {subtitle && <span className={styles.Category}>{subtitle}</span>}
          </div>
        )}
      </Container>
      {props.children}
    </div>
  );
}
