import { Button, ButtonStyles, Container, PageTitle } from '@ifs/libs';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import styles from "./AccoutActivationResendEmailPage.module.scss";
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { i18n } from '../../i18n';
import classNames from 'classnames';
import { useResendInitializedAccountValidationEmail } from 'modules/registration/commands/resendInitializedAccountValidationEmail.command';

export function AccoutActivationResendEmailPage() {
  const searchParams = useSearchParams();
  const email = searchParams.get('email') || "";
  const { resendInitializedAccountValidationEmailCommand } = useResendInitializedAccountValidationEmail();

  async function resendAccountActivationLink() {
    resendInitializedAccountValidationEmailCommand(email!);
  }

  return (
    <Container className={ModuleStyles.Grid}>
        <PageTitle>{i18n.accountActivationPage.linkExpired.title}</PageTitle>
        <h2 className={ModuleStyles.Subtitle}>{i18n.accountActivationPage.linkExpired.subtitle}</h2>

        <Button
          className={classNames(ButtonStyles.PrimaryAction, styles.Button, styles.ValidateButton)}
          onClick={resendAccountActivationLink}
        >
          {i18n.accountActivationPage.linkExpired.cta}
        </Button>     

    </Container>
  );
}
