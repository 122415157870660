import { globali18n } from '@ifs/libs/src/i18n';
import { i18n } from 'modules/appointment/i18n';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { emailRegex, nameRegex } from '../../../../shared/utilities/validation';

export function getFormConfig() {
  const formConfig = {
    firstName: {
      rules: {
        required: false,
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : nameRegex.test(v))
        }
      },
      errorMessages: {
        required: globali18n.errors.required,
        format: globali18n.errors.pattern
      }
    },
    lastName: {
     rules: {
        required: false,
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : nameRegex.test(v))
        }
      },
      errorMessages: {
        required: globali18n.errors.required,
        format: globali18n.errors.pattern
      }
    },
    email: {
     rules: {
        required: false,
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : emailRegex.test(v))
        }
      },
      errorMessages: {
        required: globali18n.errors.required,
        format: globali18n.errors.pattern
      }
    },
    phoneNumber: {
      rules: {
        required: true,
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : isValidPhoneNumber(v))
        }
      },
      errorMessages: {
        required: globali18n.errors.required,
        format: i18n.appointmentPage.phoneNumber.errors.format
      }
    },
    desiredDate: {
      rules: { required: true },
      errorMessages: {
        required: globali18n.errors.required
      }
    },
    desiredTimeSlot: {
      rules: { required: true },
      errorMessages: {
        required: globali18n.errors.required
      }
    },
    zipCode: {
      rules: { required: true },
      errorMessages: {
        required: globali18n.errors.required
      }
    }
  };

  return formConfig;
}
