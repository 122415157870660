import { useNavigate } from "react-router";
import { Module } from "../../../shared/models/Module";
import { useAppUrls } from "../../../shared/utilities/urls";
import { useAppStore } from "../../../store";
import { ModuleStatus } from "../models/ModuleStatus";
import { useDetermineModuleStatus } from "../utilities/determineModuleStatuses";

export function useGoToModule() {
  const navigate = useNavigate();
  const { determineModuleStatus } = useDetermineModuleStatus();
  const store = useAppStore();
  const appUrls = useAppUrls();

  // Table that summarizes where the user must be directed depending on the status of the module
  const moduleRoutes = {
    // If the pension need module is completed, go to summary (possibility to retake the module from there)
    [Module.PensionNeed]: {
      [ModuleStatus.Available]: appUrls.pensionNeed.landing,
      [ModuleStatus.Completed]: appUrls.pensionNeed.summary,
    },
    // If the legal pension module is completed, go to summary (possibility to modify the answers from there)
    [Module.LegalPension]: {
      [ModuleStatus.Available]: appUrls.legalPension.landing,
      [ModuleStatus.Completed]: appUrls.legalPension.summary,
    },
    // If the taxation module is completed, go to summary (possibility to modify the answers from there)
    [Module.Taxation]: {
      [ModuleStatus.Available]: appUrls.taxation.landing,
      [ModuleStatus.Completed]: appUrls.taxation.summary,
    },
    // If the life projects module is completed, skip the landing page
    [Module.Patrimony]: {
      [ModuleStatus.Available]: appUrls.patrimony.landing,
      [ModuleStatus.Completed]: appUrls.patrimony.declaration,
    },
    // If the life projects module is completed, skip the landing page
    [Module.LifeProjects]: {
      [ModuleStatus.Available]: appUrls.lifeProjects.landing,
      [ModuleStatus.Completed]: appUrls.lifeProjects.projects,
    },
    // If the life projects module is completed, skip the landing page
    [Module.InvestorProfile]: {
      [ModuleStatus.Available]: appUrls.investorProfile.landing,
      [ModuleStatus.Completed]:
        appUrls.investorProfile.financialProfile.summary,
    },
  };

  function goToModuleCommand(module: Module) {
    const model = store.getState().dashboard.model!;
    const statuses = determineModuleStatus(model);
    const status = statuses[module];

    if (status === ModuleStatus.Locked || status === ModuleStatus.CompletedAndLocked) {
      return;
    }

    const route =
      status === ModuleStatus.DashboardUnavailable ||  status === ModuleStatus.DashboardUnavailableAndRouteGuardLocked
        ? appUrls.dashboard
        : moduleRoutes[module][status];
    navigate(route);
  }

  return { goToModuleCommand, moduleRoutes };
}
