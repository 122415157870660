import { getBirthdateRequest } from '../requests/getBirthdateRequest';

export function birthdateRequirement() {
  async function birthdateRequirementCommand(customerid?: string) {
    const results = await getBirthdateRequest(customerid);
    return results;
  }

  return { birthdateRequirementCommand };
}
