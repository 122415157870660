import styles from './SetCustomerPasswordForm.module.scss';
import { i18n } from '../../i18n';
import { useForm } from 'react-hook-form';
import { ModuleStyles } from 'modules/registration/styles/ModuleStyles';
import {
  Button,
  ButtonStyles,
  Container,
  InputGroup,
  TextInputPassword,
  useInfoModal,
  ValidationMessages
} from '@ifs/libs';
import { PasswordConfirmInputGroup } from 'shared/components';
import classNames from 'classnames';
import { getFormConfig } from './formConfig';
import { hasErrors } from 'shared/utilities/hasErrors';
import { useChangePassword } from 'modules/profile/commands/changePassword.command';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';

interface FormData {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
}

export function SetCustomerPasswordForm(props: SetCustomerPasswordFormProps) {
  const { register, handleSubmit, watch, trigger, formState, setError } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {},
    mode: 'all'
  });
  const { changePasswordCommand } = useChangePassword();
  const { showInfoModal } = useInfoModal();
  const errors = formState.errors;
  const isSubmitted = formState.isSubmitted;
  const formConfig = getFormConfig(register, watch);

  async function onSubmit(data: FormData) {
    const model = {
      currentPassword: data.currentPassword,
      newPassword: data.password
    };
    try {
      await changePasswordCommand(model);
      showInfoModal(
        i18n.passwordUpdateModal.title,
        i18n.passwordUpdateModal.body,
        false,
        'submit-set-informations'
      );
      props.switchTab();
    } catch (err) {
      const error = err as any;
      if (error.response?.data.error === 'IncorrectUserPassword') {
        setError('currentPassword', { types: { incorrect: true } });
      }
    }
  }

  return (
    <Container className={classNames(ModuleStyles.Grid, styles.Container)}>
      <h2 className={classNames(ModuleStyles.Subtitle, styles.Subtitle)}>
        {i18n.setCustomerPasswordForm.title}
      </h2>

      <form
        className={classNames(ModuleStyles.FormGridResponsive, styles.FormGridResponsive)}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputGroup
          isInvalid={hasErrors('currentPassword', errors)}
          isValid={isSubmitted && !hasErrors('currentPassword', errors)}
        >
          <label htmlFor={'currentPassword'}>
            {i18n.setCustomerPasswordForm.labels.currentPassword}
          </label>
          <TextInputPassword
            inputError={hasErrors('currentPassword', errors)}
            id="currentPassword"
            register={formConfig.currentPassword.field}
            required
            aria-required={"true"}
          />
          <ValidationMessages
            messages={getValidationMessages(
              'currentPassword',
              errors,
              formConfig.currentPassword.errors
            )}
          />
        </InputGroup>

        <PasswordConfirmInputGroup
          passwordName={'password'}
          passwordConfirmName={'passwordConfirm'}
          customPasswordNameWording={i18n.setCustomerPasswordForm.labels.newPassword}
          customPasswordConfirmNameWording={i18n.setCustomerPasswordForm.labels.confirmNewPassword}
          isSubmitted={formState.isSubmitted}
          register={register}
          watch={watch}
          trigger={trigger}
          errors={errors}
        />
        <div className={styles.ButtonContainer}>
          <Button
            className={classNames(
              ButtonStyles.SecondaryAction,
              styles.Button,
              styles.ValidateButton
            )}
            id="submit-set-informations"
          >
            {i18n.setCustomerPasswordForm.labels.submit}
          </Button>
        </div>
      </form>
    </Container>
  );
}

interface SetCustomerPasswordFormProps {
  switchTab: () => void;
}
