import classNames, { Argument } from "classnames";
import { i18n } from "../../i18n";
import { ButtonStyles, Button } from "../../";
import styles from "./LandingCard.module.scss";
import { assets as LandingAssets } from "./Assets";
import { ModuleName } from "./ModuleName";

export interface LandingCardProps {
  title: string;
  content: JSX.Element;
  onStart: () => void;
  gridClassName: Argument;
  info?: string;
  module?: ModuleName;
  dashboard?: boolean;
}

export function LandingCard(props: LandingCardProps) {
  let asset = {
    [ModuleName.PensionNeed]: LandingAssets.BackgroundPensionNeedLandingImage,
    [ModuleName.LegalPension]: LandingAssets.BackgroundLegalPensionLandingImage,
    [ModuleName.Taxation]: LandingAssets.BackgroundTaxationLandingImage,
    [ModuleName.Patrimony]: LandingAssets.BackgroundPatrimonyLandingImage,
    [ModuleName.LifeProject]: LandingAssets.BackgroundLifeProjectLandingImage,
    [ModuleName.InvestorProfile]:
      LandingAssets.BackgroundInvestorProfileLandingImage,
  };

  let inLineStyles = {};
  if (props.module !== undefined) {
    inLineStyles = {
      background: `url(${asset[props.module]}) no-repeat center center`,
      backgroundSize: "cover",
      borderRadius: '8px'
    };
  }
  if (props.dashboard) {
    inLineStyles = {
      background: `url(${LandingAssets.BackgroundDiagnosticLandingImage}) no-repeat center center`,
      backgroundSize: "cover",
      borderRadius: '8px'
    };
  }
  return (
    <>
      <div
        className={classNames(styles.Grid, props.gridClassName)}
        style={inLineStyles}
      >
        <section className={styles.Card}>
          <div>
            <h1 className={styles.CardTitle}>{props.title}</h1>
            {props.content}
          </div>
          <Button
            onClick={props.onStart}
            className={classNames(ButtonStyles.PrimaryAction, styles.BaseButton, {
              [styles.Button]: !props.dashboard,
            })}
          >
            {!props.dashboard
              ? i18n.landingPage.start
              : i18n.landingPage.goToDiagnostic}
          </Button>
          {props.info && <div className={styles.Info}>{props.info}</div>}
        </section>
      </div>
    </>
  );
}
