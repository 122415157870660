import { useNavigate } from "react-router-dom";
import { LandingCard, Interlayer } from "../../../../../shared";
import { useAppUrls } from "../../../../../shared/utilities/urls";
import { i18n } from "../../../i18n";
import styles from "./LandingPage.module.scss";
import { Footer } from "../../../../../modules/pension-need/components/Footer/Footer";
import { Module } from "../../../../../shared/models/Module";
import { ModuleName } from "../../../../../shared/components/LandingCard/ModuleName";
import { useAnalytics } from "../../../../../shared/commands/useAnalytics";

export function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { landingCTAEvent } = useAnalytics();
  function onStart() {
    landingCTAEvent("pension_need");
    navigate(appUrls.pensionNeed.persona);
  }

  return (
    <Interlayer className={styles.Interlayer} active={Module.PensionNeed}>
      <LandingCard
        module={ModuleName.PensionNeed}
        gridClassName={styles.Grid}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content}
        onStart={onStart}
      />
      <Footer>{i18n.landingPage.info}</Footer>
    </Interlayer>
  );
}
