import { useAppDispatch } from "../../../store";
import { setAnswers } from "../lifeProjectsSlice";
import { getProjectsRequest } from "../requests/getProjectsRequest";

export function userestoreLifeProjects() {
    const dispatch = useAppDispatch();
  async function restoreLifeProjects(customerid?: string) {
    const data = await getProjectsRequest(customerid);
    if(data) {
        dispatch(setAnswers(data));
    }
  }

  return { restoreLifeProjects };
}
