import { useAppDispatch } from "../../../store";
import { parse } from "query-string";
import { useNavigate, useLocation } from "react-router";
import {
  setTaxAvailable,
  setTaxRate,
} from "../taxationSlice";
import { useAppUrls } from "../../../shared/utilities/urls";
import { useSetTaxationCommand } from "./setTaxationCommand";
import { SalaryPeriodicity, useLoader } from "../../../shared";
import { TaxAvailableInputModel } from "../models/TaxationComputationInputModel";

interface FormData {
  periodicityIndividual: SalaryPeriodicity;
  amountIndividual: number;
  periodicity: SalaryPeriodicity;
  amount: number;
}

export function useAnswerCommands() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const setTaxationCommand = useSetTaxationCommand();
  const { showLoaderWhile } = useLoader();
  const appUrls = useAppUrls();

  async function next(nextRoute: string) {
    // We are coming from the summary and going back to it
    if (parse(search).summary === "true") {
      await setTaxationCommand();
      return;
    }

    // Otherwise, go to next step
    await showLoaderWhile(
      async () => {
        navigate(nextRoute);
      },
      {
        key: "taxation_next",
        value: `Handle navigate to next application route ${nextRoute}`,
      }
    );
  }

  async function answerTaxAvailables(value: TaxAvailableInputModel) {
    dispatch(setTaxAvailable(value));
    // answerTaxationEvent(value);
    await next(appUrls.taxation.taxRate);
  }

  async function answerTaxRate(value: number) {
    dispatch(setTaxRate(value));
    // answerTaxationEvent(value);
    await setTaxationCommand();    
  }

  return {
    answerTaxAvailables,
    answerTaxRate,
  };
}
