import { BackgroundImage } from "./components/BackgroundImage/images";
import {
  RecommendationTopLeftImage,
  RecommendationBottomLeftImage,
  RecommendationMiddleImage,
} from "./components/MainCard/images";

import { TrophyImage } from "./components/MainCard/images";
import { BuildingImage } from "./components/MainCard/images";
import { assets as Assets } from "../../shared/components/BackgroundImage/Assets";

export const assets = {
  Trophy: TrophyImage,
  Building: BuildingImage,
  TopLeftImage: RecommendationTopLeftImage,
  BottomLeftImage: RecommendationBottomLeftImage,
  BackgroundImage: BackgroundImage,
  MiddleImage: RecommendationMiddleImage,
  ProductSheetModalFooterImage: RecommendationMiddleImage,
  RecoStaticRightImage: Assets.MeetingImage,
  RecoStaticLeftImage: Assets.LifeProjectImage
};
