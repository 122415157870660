import { useEffect } from 'react';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { PasswordResetPage } from './pages/PasswordResetPage/PasswordResetPage';
import { PasswordResetEmailPage } from './pages/PasswordResetEmailPage/PasswordResetEmailPage';
import { PasswordResetResetPage } from './pages/PasswordResetResetPage/PasswordResetResetPage';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { AnalyticsComponent } from '@ifs/libs/src/shared/components/datalayer/AnalyticsComponent';
import { i18n } from './i18n';
import { PageRoute } from '@ifs/libs/src/shared/routing/PageRoute';
import { AuthenticatePage } from './pages/AuthenticatePage/AuthenticatePage';


const t = i18n;

export function AuthenticationModule() {
  useEffect(() => {}, []);

  return (
    <Routes>
      <Route
        path={`/authenticate`}
        element={
          <PageRoute
            pageTitle={t.loginPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={t.loginPage.pageTitle}
                page_path={'/auth/authenticate'}
                page_type={'login'}
                simulator_screen={'login'}
                simulator_step={''}
                simulator_type={"login"}
              >
                <AuthenticatePage/>
              </AnalyticsComponent>
            }
          />
        }
      />
      <Route
        path={`/login`}
        element={
          <PageRoute
            pageTitle={t.loginPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={t.loginPage.pageTitle}
                page_path={'/auth/login'}
                page_type={'login'}
                simulator_screen={'login'}
                simulator_step={''}
                simulator_type={"login"}
              >
                <LoginPage />
              </AnalyticsComponent>
            }
          />
        }
      />
      <Route
        path={`/password-reset`}
        element={
          <PageRoute
            pageTitle={t.loginPage.labels.passwordReset}
            element={
              <AnalyticsComponent
                page_title={t.loginPage.labels.passwordReset}
                page_path={'/auth/password-reset'}
                page_type={'password-reset'}
                simulator_screen={'password-reset'}
                simulator_step={''}
                simulator_type={"password-reset"}
              >
                <PasswordResetPage />
              </AnalyticsComponent>
            }
          />
        }
      />
      <Route
        path={`/password-reset/email`}
        element={
          <PageRoute
            pageTitle={t.passwordResetEmailPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={t.passwordResetEmailPage.pageTitle}
                page_path={'/auth/password-reset/email'}
                page_type={'password-reset'}
                simulator_screen={'password-reset'}
                simulator_step={''}
                simulator_type={"password-reset"}
              >
                <PasswordResetEmailPage />
              </AnalyticsComponent>
            }
          />
        }
      />
      <Route
        path={`/password-reset/reset`}
        element={
          <PageRoute
            pageTitle={t.passwordResetResetPage.pageTitle}
            element={
              <AnalyticsComponent
                page_title={t.passwordResetResetPage.pageTitle}
                page_path={'/auth/password-reset/reset'}
                page_type={'password-reset'}
                simulator_screen={'password-reset'}
                simulator_step={''}
                simulator_type={"password-reset"}
              >
                <PasswordResetResetPage />
              </AnalyticsComponent>
            }
          />
        }
      />
      <Route path={`/logout`} element={<PageRoute pageTitle={t.loginPage.logout} element={<></>} />} />
    </Routes>
  );
}
