import { Module } from "../shared/models/Module";

// Primary
export const hubble = "var(--primary-hubble)";
export const hubble_lighter = "#b9d0cc";
export const wintergreen = 'var(--primary-wintergreen)';
export const wintergreen_lighter = '#b9d0cc'; // scale-color($color: $wintergreen, $lightness: 60%);
export const keppel = 'var(--primary-keppel)';
export const keppel_lighter = '#b2dedb'; // scale-color($color: $keppel, $lightness: 60%);
export const green_sheen = 'var(--primary-green_sheen)';
export const green_sheen_lighter = '#c5e2d4'; // scale-color($color: $green_sheen, $lightness: 60%);
export const sage = 'var(--primary-sage)';
export const sage_lighter = '#dce5cf'; // scale-color($color: $sage, $lightness: 60%);
export const burlywood = 'var(--primary-burlywood)';
export const burlywood_lighter = '#f0e4c7'; // scale-color($color: $burlywood, $lightness: 60%);
export const transparent = "transparent";


// Secondary
export const ruddy_brown = 'var(--secondary-ruddy_brown)';
export const copper = 'var(--secondary-copper)';
export const danger_red = 'var(--danger-red)';
export const valid = '#007BBD';

// UI
export const stormcloud = '#515e63';
export const auro_metal_saurus = '#747e82'; // gZVy6iQ9kxc
export const gainsboro = '#dcdfe0';
export const platinum = '#e9ece6';
export const isabelline = '#f5f6ed';
export const white = 'white';
export const legal_pension_summary_last_salary_legend = 'var(--legal-pension-summary-last-salary-legend)';
export const legal_pension_summary_pension_amount_legend = 'var(--legal-pension-summary-pension-amount-legend)';
export const legal_pension_summary_loss_amount_legend = 'var(--legal-pension-summary-loss-amount-legend)';
export const yellow_20 = "#FBEDD0";
export const yellow_40 = "#F7D99D";


export const ModuleColor = {
  [Module.PensionNeed]: wintergreen,
  PensionNeedLighter: wintergreen_lighter,

  [Module.LegalPension]: keppel,
  LegalPensionLighter: keppel_lighter,

  [Module.Taxation]: burlywood,
  TaxationLighter: burlywood_lighter,

  [Module.Patrimony]: green_sheen,
  PatrimonyLighter: green_sheen_lighter,
  
  [Module.LifeProjects]: sage,
  LifeProjectsLighter: sage_lighter,

  [Module.InvestorProfile]: hubble,
  InvestorProfileLighter: hubble_lighter,

  Recommendation: wintergreen,

  Disabled: auro_metal_saurus,
};
