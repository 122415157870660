import { ModuleColor } from "../../../../../styles/colors";
import { assets as Assets } from "../../../../../modules/dashboard/Assets";
import { Module } from "../../../../../shared";
const barChartWidth = 40;

interface BarChartProps {
    module: Module;
    chartData: {
      y: number;
      height: number;
      x: number;
    };
  }
  
export function BarChartItem({ module, chartData }: BarChartProps) {
    const AssetList = {
      [Module.LegalPension]: (
        <Assets.LegalPensionIcon width="25px" x="68" y={chartData.y - 5} />
      ),
      [Module.Patrimony]: (
        <Assets.PatrimonyIcon width="25px" x="68" y={chartData.y} />
      ),
      [Module.PensionNeed]: (
        <Assets.PensionNeedIcon width="25px" x="7" y={chartData.y} />
      ),
      [Module.LifeProjects]: (
        <Assets.LifeProjectsIcon width="25px" x="7" y={chartData.y} />
      ),
      [Module.InvestorProfile]: <div></div>,
      [Module.Taxation]: <div></div>,
    };
  
    const AssetIcon = AssetList[module];
  
    const color = ModuleColor[module];
  
    return (
      <>
        <rect
          x={chartData.x}
          y={chartData.y}
          width={barChartWidth}
          height={chartData.height + "%"}
          fill={color}
        />
        {chartData.height >= 20 && AssetIcon}
      </>
    );
  }
  