import { useAppSelector } from "../../../../store";
import { i18n } from "../../../../modules/dashboard/i18n";
import { Interlayer, LandingCard } from "../../../../shared";
import styles from "../../../../styles/shared/LandingPage.module.scss";
import { computerNeedsRevenues } from "../DashboardPage/calculations";
import { useNavigate } from "react-router-dom";
import { useAppUrls } from "../../../../shared/utilities/urls";

export default function LandingPage() {
  const navigate = useNavigate();
  const appUrls = useAppUrls();

  function onStart() {
    navigate(appUrls.dashboard);
  }

  const model = useAppSelector((s) => s.dashboard.model)!;
  const needsRevenues = computerNeedsRevenues(model);

  return (
    <Interlayer
      className={styles.Interlayer}
      hideBottomObjective={true}
      noGoal={true}
    >
      <LandingCard
        dashboard={true}
        gridClassName={styles.Grid}
        title={i18n.landingPage.title}
        content={i18n.landingPage.content(
          needsRevenues.revenues - needsRevenues.needs
        )}
        onStart={onStart}
      />
    </Interlayer>
  );
}
