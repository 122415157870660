import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoader } from '../../../shared/';
import { useAppDispatch, useAppStore } from '../../../store';
import { getTaxationRequest } from '../requests/getTaxationRequest';
import { getTmiConfigRequest } from '../requests/getTmiConfigRequest';
import { resetTaxation, setAnswers, setResults, setTmiConfigs } from '../taxationSlice';
import { answersToResults as resultsToAnswers } from '../utilities/outputToInput';

export function useResolver() {
  const { showLoaderWhile } = useLoader();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const store = useAppStore();
  
  const { customerid } = useParams();
  function setup() {
    showLoaderWhile(async () => {
      // Module initialized, do nothing
      if (store.getState().taxation.tmiConfigs) {
        setLoading(false);
        return;
      }

      // The TMI config is used to check if the module is initialized
      const tmiConfigs = await getTmiConfigRequest();
      dispatch(setTmiConfigs(tmiConfigs));

      const results = await getTaxationRequest(customerid);
      // If there are results saved, we can extract and restore the user answers
      if (results) {
        const answers = resultsToAnswers(results);
        dispatch(setAnswers(answers));
        dispatch(setResults(results));
      }

      setLoading(false);
    }, {key: "taxationResolver_setup ", value:`Handle taxation initialization}`});
  }

  function cleanup() {
    dispatch(resetTaxation());
  }

  useEffect(() => {
    setup();
    return () => {
      cleanup();
    };
  }, []);

  return { loading };
}
