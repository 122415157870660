import { useNavigate } from 'react-router';
import { useGlobalCommand } from 'shared/commands/useGlobalCommand';
import { useAuthEvent, useLoader } from '@ifs/libs';
import { useSearchParams } from 'shared/utilities/useSearchParams';
import { LoginModel, LoginOutcome, loginRequest } from '../requests/loginRequest';
import { internalUrls, reportUrl } from '@ifs/libs/src/shared/utilities/urls';

export function useLoginCommand() {
  const { showLoaderWhile } = useLoader();
  const { resetAllCommand, checkAuthenticationCommand } = useGlobalCommand();
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const { authEvent } = useAuthEvent();

  async function loginCommand(model: LoginModel): Promise<LoginOutcome> {
    return await showLoaderWhile(async () => {
      const result = await loginRequest(model);

      if (result !== LoginOutcome.Success) {
        return result;
      }

      authEvent("login");

      await resetAllCommand();
      await checkAuthenticationCommand();

      // Currently only the report page has a return URL mechanism
      if (searchParams.get('returnUrl') === reportUrl) {
        window.location.href = reportUrl;
      }

      // Registered users always start with the dashboard
      navigate(internalUrls.dashboard);

      return LoginOutcome.Success;
    }, {key: "useLoginCommand", value:`API POST - /api/auth/login`});
  }

  return { loginCommand };
}
