import { useEffect, useState } from "react";
import styles from "./PatrimonyChart.module.scss";
import { useAppSelector } from "../../../../../../store";
import { i18n } from "../../../../../../modules/patrimony/i18n";
import {
  ScenarioProjectionType,
  SimulationPatrimonyModel,
} from "../../../../../../modules/patrimony/models/SynthesisModel";
import { retirementAge } from "../../../../../../modules/recommendation/utilities/format";
import { amount } from "../../../../../../shared/utilities/intl";
import { PatrimonyBarChartPerformance } from "../PatrimonyBarChartPerformance";

type PatrimonyChartItem = {
  label: string;
  totValue: number;
  realEstateValue: number;
  savingsAccountValue: number;
};

export function PatrimonyChart() {
  const model = useAppSelector((s) => s.patrimony.synthesis)!;
  const [patrimonyItem, setPatrimonyItem] = useState<PatrimonyChartItem[]>([]);
  const [maximumPatrimonyPerformance, setMaximumPatrimonyPerformance] = useState<number>(0);

  const t = i18n.summaryPage;

  useEffect(() => {
    if(!model) return;
    getPatrimonyPerformanceItem();
  }, [model]);

  function getSimulationProjection(
    type: ScenarioProjectionType
  ): SimulationPatrimonyModel {
    return model.simulations.find((sim) => sim.scenario === type)!;
  }

  function getPatrimonyPerformanceItem(): void {
    const patrimonyItem: PatrimonyChartItem[] = [];
    // Add Current Patrimony item
    const totCurrentPatrimony = model.currentPatrimony.savingsAccountValue + model.currentPatrimony.realEstateValue;
    patrimonyItem.push({
        savingsAccountValue: model.currentPatrimony.savingsAccountValue,
        realEstateValue: model.currentPatrimony.realEstateValue,
        totValue: totCurrentPatrimony,
        label: t.today
    });
    // Add Pessimistic scenario
    patrimonyItem.push(addProjectionPatrimony(ScenarioProjectionType.Pessimistic, t.scenario.pessimistic));
    // Add Neutral scenario
    patrimonyItem.push(addProjectionPatrimony(ScenarioProjectionType.Neutral, t.scenario.neutral));
    // Add Optimistic scenario
    patrimonyItem.push(addProjectionPatrimony(ScenarioProjectionType.Optimistic, t.scenario.optimistic)); 
    // Calculate Max performance
    const maxPerf = Math.max(...patrimonyItem.map((perf)=> perf.totValue));
    setMaximumPatrimonyPerformance(maxPerf);
    setPatrimonyItem(patrimonyItem);
  }

  function addProjectionPatrimony(projectionType: ScenarioProjectionType, label: string): PatrimonyChartItem {
    const projection = getSimulationProjection(projectionType);
    const totValue = projection.capitalAmountAtRetirement.savingsAccountValue + projection.capitalAmountAtRetirement.realEstateValue;
    return {
      label: `${label} ${t.at} ${retirementAge(model.retirementAge)}`,
      savingsAccountValue: projection.capitalAmountAtRetirement.savingsAccountValue,
      realEstateValue: projection.capitalAmountAtRetirement.realEstateValue,
      totValue
    }
  }

  return (
    <section className={styles.PatrimonyChartContainer}>
        {patrimonyItem.map((element: PatrimonyChartItem, index) => (
            <div key={`patrimony-item-${index}`} className={styles.PatrimonyItem}>
                <div className={styles.PatrimonyItemLabels}>
                    <span> {element.label} : </span>
                    <span className={styles.AmountValue}> {amount(element.totValue)} </span>
                </div>
                <PatrimonyBarChartPerformance className={styles.PatrimonyBarChart} maximumTotalValue={maximumPatrimonyPerformance} scenarioRealEstateValue={element.realEstateValue} scenarioTotalValue={element.totValue}/>
            </div>
          
        ))}
    </section>
  )
}
