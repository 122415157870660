import React, { useEffect, useState } from 'react';
import { Provider as ReactReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { RegistrationModule } from './modules/registration/RegistrationModule';
import { AuthenticationModule } from './modules/authentication/AuthenticationModule';
import { AppointmentModule } from 'modules/appointment/AppointmentModule';

import { store, useAppDispatch } from './store';
import { GuardedRoute } from './shared/routing/GuardedRoute';
import { useGlobalCommand } from './shared/commands/useGlobalCommand';

import {
  IntlProvider as KendoIntlProvider,
  LocalizationProvider as KendoLocalizationProvider
} from '@progress/kendo-react-intl';
import {
  load as kendoIntlLoad,
  loadMessages as kendoLocalizationLoadMessages
} from '@progress/kendo-react-intl';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frCurrencies from "cldr-numbers-full/main/fr/currencies.json";
import dateFields from 'cldr-dates-full/main/fr/dateFields.json';
import caGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import styles from './App.module.scss';
import { ProfileModule } from 'modules/profile/ProfileModule';
import { Drawer } from './shared/components/Drawer/Drawer';

import {
  TaxationModule,
  PensionNeedModule,
  LegalPensionModule,
  PageNotFound,
  DashboardModule,
  PatrimonyModule,
  LifeProjectsModule,
  RecommendationModule,
  resetLoader,
  Loader,
  ModalProvider as LibsModalProvider,
  TechnicalErrorPage,
  Header,
  Footer,
  CookiesPage,
  use401Interceptor,
  ErrorBoundaryFallbackComponent,
  InvestorProfileModule,
  ScrollToTop
} from '@ifs/libs';
import { useAppResolver } from 'shared/resolvers/app.resolver';
import { PageRoute } from '@ifs/libs/src/shared/routing/PageRoute';
import { ModuleGuardedRoute } from 'shared/routing/ModuleGuardedRoute';
import { PublicGuardedRoute } from 'shared/routing/PublicGuardedRoute';
import { CheckCustomerPage } from 'shared/pages/CheckCustomer/CheckCustomerPage';

const locale = 'fr';

// Required for NumericTextBox
kendoIntlLoad(frNumbers, frCurrencies);
// Required for DatePicker
kendoIntlLoad(dateFields, caGregorian, weekData);
kendoLocalizationLoadMessages({ datepicker: { toggleCalendar: 'Ouvrir le calendrier' } }, locale);

export function preventTab(e: any) {
  e = e || window.event;
  if (e.keyCode === 9) {
    // If tab key is pressed
    e.preventDefault(); // Stop event from its action
  }
}
export function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Your event listener

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div className={styles.App}>
      <ReactReduxProvider store={store}>
        <KendoIntlProvider locale={locale}>
          <KendoLocalizationProvider language={locale}>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorBoundaryFallbackComponent resetErrorBoundary={resetErrorBoundary}>
                  <Drawer open={drawerOpen} onOverlayClick={toggleDrawer}>
                    <Header onMenuClick={toggleDrawer} />
                    <Main>
                      <TechnicalErrorPage error={error} />
                    </Main>
                    <Footer />
                  </Drawer>
                </ErrorBoundaryFallbackComponent>
              )}
            >
              <LibsModalProvider>
                <Loader />
                <TenantContainer>
                  <Routing />
                </TenantContainer>
              </LibsModalProvider>
            </ErrorBoundary>
          </KendoLocalizationProvider>
        </KendoIntlProvider>
      </ReactReduxProvider>
    </div>
  );
}

function Routing() {
  const { checkAuthenticationCommand } = useGlobalCommand();
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();
  use401Interceptor();

  setTimeout(() => {
    const headImage = document.getElementById('ApplicationHeaderComponent');
    headImage?.focus();
  }, 10);

  async function checkAuthentication() {
    // Ensure the loader counter is reset, in case the page was refreshed during a request
    dispatch(resetLoader());

    await checkAuthenticationCommand();
    setLoading(false);
  }

  useEffect(() => {
    checkAuthentication();
  }, []);

  if (loading) {
    return null;
  }

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <>
      <ScrollToTop />
      <Drawer open={drawerOpen} onOverlayClick={toggleDrawer}>
        <Header onMenuClick={toggleDrawer} />
        <Main>
          <Routes>
            <Route
              path="/cookies"
              element={<PageRoute pageTitle={"Cookies"} element={<CookiesPage />} />}
            />
            <Route path="/register/*" element={<PublicGuardedRoute><RegistrationModule /></PublicGuardedRoute>} />
            <Route path="/auth/*" element={<PublicGuardedRoute><AuthenticationModule /></PublicGuardedRoute>} />
            <Route path="/check-customer" element={<CheckCustomerPage/>} />
            <Route
              path="/pension-need/*"
              element={
                <GuardedRoute>
                  <ModuleGuardedRoute>
                    <PensionNeedModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />
            <Route
              path="/legal-pension/*"
              element={
                <GuardedRoute>
                  <ModuleGuardedRoute>
                    <LegalPensionModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />

            <Route
              path="/taxation/*"
              element={
                <GuardedRoute>
                  <ModuleGuardedRoute>
                    <TaxationModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />

            <Route
              path="/life-projects/*"
              element={
                <GuardedRoute>                  
                  <ModuleGuardedRoute>
                    <LifeProjectsModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />
            <Route
              path="/patrimony/*"
              element={
                <GuardedRoute>                  
                  <ModuleGuardedRoute>
                    <PatrimonyModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />
            <Route
              path="/investor-profile/*"
              element={
                <GuardedRoute>                  
                  <ModuleGuardedRoute>
                    <InvestorProfileModule />
                  </ModuleGuardedRoute>
                </GuardedRoute>
              }
            />
            <Route
              path="/recommendation/*"
              element={
                <GuardedRoute>
                  <RecommendationModule />
                </GuardedRoute>
              }
            />

            <Route
              path="/*"
              element={
                <GuardedRoute>
                  <DashboardModule />
                </GuardedRoute>
              }
            />

            <Route
              path="/appointment/*"
              element={
                <GuardedRoute>
                  <AppointmentModule />
                </GuardedRoute>
              }
            />

            <Route
              path="/profile/*"
              element={
                <GuardedRoute>
                  <ProfileModule />
                </GuardedRoute>
              }
            />
            <Route path="/bff/login" element={<></>} />
            <Route path="/bff/logout" element={<></>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Main>
        <Footer />
      </Drawer>
    </>
  );
}

function TenantContainer(props: React.PropsWithChildren<any>) {
  const { loading } = useAppResolver();
  if (loading) {
    return null;
  }

  return <>{props.children}</>;
}

function Main(props: React.PropsWithChildren<any>) {
  return (
    <main id="main" className={styles.Main}>
      {props.children}
    </main>
  );
}
